<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Statement Generator</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <b-form>
      <app-timeline>
        <validation-observer ref="simpleRules">
          <app-timeline-item class="pb-1">
            <b-row class="mt-1">
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Statement</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Statement"
                    rules="required"
                  >
                    <v-select
                      id="statement_id"
                      v-model="statement_id"
                      :clearable="false"
                      placeholder="Select"
                      :options="statementTypes"
                      :reduce="item => item.id"
                      @input="statementSelected"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-show="showBalanceSheetDate"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  id="date"
                >
                  <label>Date</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Date"
                    :rules="showBalanceSheetDate ? 'required' : ''"
                  >
                    <flat-pickr
                      v-model="date"
                      class="form-control"
                      :config="$store.state.config"
                      placeholder="MM-DD-YYYY"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-show="!showBalanceSheetDate"
                cols="12"
                md="6"
                lg="3"
              >
                <b-form-group
                  id="from_date"
                >
                  <label>From Date</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="From Date"
                    :rules="showBalanceSheetDate ? '' : 'required'"
                  >
                    <flat-pickr
                      v-model="from_date"
                      class="form-control"
                      :config="startDateConfig"
                      placeholder="MM-DD-YYYY"
                      @on-change="fromDateChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-show="!showBalanceSheetDate"
                cols="12"
                md="6"
                lg="3"
              >
                <b-form-group
                  id="to_date"
                >
                  <label>To Date</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="To Date"
                    :rules="showBalanceSheetDate ? '' : 'required'"
                  >
                    <flat-pickr
                      v-model="to_date"
                      class="form-control"
                      :config="endDateConfig"
                      placeholder="MM-DD-YYYY"
                      @on-change="toDateChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6 ml-auto"
                lg="2"
                class="text-right mt-md-2"
              >
                <b-button
                  variant="primary"
                  type="button"
                  @click="generateStatement"
                >
                  Generate
                </b-button>
              </b-col>
            </b-row>
          </app-timeline-item>
        </validation-observer>

        <app-timeline-item
          v-show="showBalanceSheet"
          variant="info"
          class="pb-1"
        >
          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6>Balance Sheet</h6>
          </div>
          <balance-sheet :add-balance-statement-parent="balanceStatement" />
        </app-timeline-item>

        <app-timeline-item
          v-show="showIncomeStatement"
          variant="info"
          class="pb-1"
        >
          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6>Income Statement</h6>
          </div>
          <income-statement :add-statement-parent="addStatement" />
        </app-timeline-item>

        <app-timeline-item
          v-show="showPLStatement"
          variant="info"
          class="pb-1"
        >
          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6>Profit & Loss Statement</h6>
          </div>
          <ProfitAndLoss :add-p-l-statement-parent="addPLStatement" />
        </app-timeline-item>

      </app-timeline>
    </b-form>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import moment from 'moment'
import IncomeStatementJS from '@/js/incomeStatement'
import BalancesheetStatementJs from '@/js/balancesheetStatement'
import PLStatement from '@/js/plStatement'
import LIST from '@/js/lists'
import IncomeStatement from './IncomeStatement.vue'
import BalanceSheet from './BalanceSheet.vue'
import ProfitAndLoss from './ProfitAndLoss.vue'

export default {
  name: 'StatementNew',
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BButton,
    flatPickr,
    vSelect,
    IncomeStatement,
    BalanceSheet,
    ProfitAndLoss,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      statement_id: null,
      showBalanceSheet: false,
      showIncomeStatement: false,
      showPLStatement: false,
      showBalanceSheetDate: false,
      addStatement: {},
      addPLStatement: {},
      balanceStatement: {
        assets: {},
        liabilities: {},
      },
      date: null,
      from_date: null,
      to_date: null,
      statementTypes: [],
      startDateConfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'm-d-Y',
        altInput: true,
        dateFormat: 'm-d-Y',
        minDate: null,
      },
      endDateConfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'm-d-Y',
        altInput: true,
        dateFormat: 'm-d-Y',
        minDate: null,
      },
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getStatementTypes(this)
      },
    },
  },
  mounted() {
  },
  created() {
    LIST.getStatementTypes(this)
  },
  destroyed() {
  },
  methods: {
    generateStatement() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          this.statementTypes.forEach(statementType => {
            if (statementType.id === this.statement_id) {
              if (statementType.label === 'Balance Sheet') {
                BalancesheetStatementJs.getBalanceSheetDetails(this, this.date)
              } else if (statementType.label === 'Income Statement') {
                IncomeStatementJS.getIncomeStatementDetails(this, this.from_date, this.to_date)
              } else if (statementType.label === 'P&L Statement') {
                PLStatement.getPLStatementDetails(this, this.from_date, this.to_date)
              }
            }
          })
        }
      })
    },
    fromDateChanged() {
      this.$set(this.endDateConfig, 'minDate', new Date(moment(this.from_date, 'MM-DD-YYYY').format('MM-DD-YYYY')))
    },
    toDateChanged() {
      this.$set(this.startDateConfig, 'maxDate', new Date(moment(this.to_date, 'MM-DD-YYYY').format('MM-DD-YYYY')))
    },
    setStatementTypes(statementTypes) {
      this.statementTypes = statementTypes
    },
    statementSelected() {
      this.statementTypes.forEach(statementType => {
        if (statementType.id === this.statement_id) {
          if (statementType.label === 'Balance Sheet') {
            this.showBalanceSheetDate = true
          } else if (statementType.label === 'Income Statement') {
            this.showBalanceSheetDate = false
          } else if (statementType.label === 'P&L Statement') {
            this.showBalanceSheetDate = false
          }
        }
      })
    },
    setStatement(addStatement) {
      this.showBalanceSheet = false
      this.showIncomeStatement = false
      this.showPLStatement = false
      this.statementTypes.forEach(statementType => {
        if (statementType.id === this.statement_id) {
          if (statementType.label === 'Balance Sheet') {
            this.showBalanceSheet = true
            this.balanceStatement = addStatement
          } else if (statementType.label === 'Income Statement') {
            this.showIncomeStatement = true
            this.addStatement = addStatement
          } else if (statementType.label === 'P&L Statement') {
            this.addStatement = addStatement
            this.showPLStatement = true
            this.addPLStatement = addStatement
          }
        }
      })
    },
    back() {
      this.$router.push('/financial-statements/statements').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.repeater-form {
  transition: .35s height;
}
</style>
