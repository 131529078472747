<template>
  <b-card
    class="mb-0"
  >
    <div class="mb-2">
      <b-row>
        <b-col
          cols="12"
          md="5"
        >
          <h4 class="card-title mb-0 pt-50">
            Applied for {{ title }} ({{ job_id }})</h4>
        </b-col>
        <b-col
          cols="12"
          md="7 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                @keyup.enter="updateSearchQuery"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="updateSearchQuery"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="14"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button
              variant="primary"
              class="ml-2 min-w-130"
              @click="applyCandidate()"
            >
              Apply New
            </b-button>
            <b-button
              variant="outline-primary"
              class="ml-2 w-20"
              @click="back"
            >
              Back
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-modal
        ref="add_candidate"
        cancel-variant="outline-secondary"
        ok-title="Add"
        cancel-title="Close"
        centered
        size="lg"
        title="Apply New for "
        :no-close-on-backdrop="true"
        @hide="closingApplyPopup"
      >
        <validation-observer
          ref="newCandidate"
          tag="form"
        >
          <b-form>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label>First Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="First Name"
                    rules="required|min:2"
                  >
                    <b-form-input
                      v-model="addCandidate.first_name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label>Last Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Last Name"
                    rules="required|min:2"
                  >
                    <b-form-input
                      v-model="addCandidate.last_name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label>Email</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      v-model="addCandidate.email_address"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label>Phone</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Phone"
                    rules="required|integer|digits:10"
                  >
                    <b-form-input
                      v-model="addCandidate.phone_number"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <label>Resume</label>
                  <input
                    ref="myFileInput"
                    type="file"
                    placeholder="Choose a file or drop it here..."
                    class="form-control text-left"
                    @change="attahamentFileSelected"
                  >
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                class="mt-2"
              >
                <b-form-checkbox
                  id="profile_invitation"
                  v-model="addCandidate.profile_invitation"
                >
                  Send Profile Invitation
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <template #modal-footer>
          <div class="d-flex justify-content-end w-100">
            <div />
            <b-button
              variant="primary"
              class="ml-1 px-1"
              @click="submitForm"
            >
              Add
            </b-button>
          </div>
        </template>
      </b-modal>
      <hr>
    </div>

    <vue-good-table
      :columns="columns"
      :rows="candidatesResponse.job_candidates"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Status -->
        <span v-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span
          v-else-if="props.column.field === 'actions'"
          class="btn-action"
        >
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none pl-50"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item :to="{ name: 'human-resources-candidates-edit', params: { id: props.row.id } }">
                <feather-icon icon="Edit2Icon" />
                <span class="ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="confirmDeleteRecord(props.row)">
                <feather-icon icon="TrashIcon" />
                <span class="ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
            <b-link
              v-if="props.row.status.toLowerCase() === 'active'"
              :to="{ name: 'human-resources-candidates-view', params: { id: props.row.id } }"
            >
              <b-badge
                pill
                variant="light-primary"
              ><feather-icon icon="EyeIcon" />
              </b-badge>
            </b-link>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
      >
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="candidatesResponse.count"
            first-number
            last-number
            align="right"
            :per-page="1"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </template>
    </vue-good-table>
    <hr>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BBadge, BPagination, BFormCheckbox, BModal, VBModal, BForm, BFormGroup, BInputGroup, BFormInput, BInputGroupAppend, BButton, BLink, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import { VueGoodTable } from 'vue-good-table'
// import vSelect from 'vue-select'
import store from '@/store/index'
// import Constants from '@/js/constant'
// import moment from 'moment'
import Candidates from '@/js/candidates'

export default {
  name: 'ExistingCandidates',
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BCard,
    BRow,
    BLink,
    BFormCheckbox,
    BCol,
    BModal,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    // vSelect,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      required,
      candidatesResponse: { prospects: [], count: 1 },
      searchQuery: '',
      selected_prospects: [],
      jobId: null,
      job_id: '',
      title: '',
      types: [],
      employers: [],
      employer_type_id: null,
      employer_id: null,
      pageLength: 10,
      dir: false,
      count: 1,
      columns: [
        {
          label: '#',
          field: 'index',
          width: '100px',
          sortable: false,
        },
        {
          label: 'Name',
          field: 'prospect_name',
          sortable: false,
        },
        {
          label: 'Email',
          field: 'email_address',
          sortable: false,
        },
        {
          label: 'Phone',
          field: 'phone_number',
          sortable: false,
        },
        {
          label: 'Submitted Date',
          field: 'submitted_date',
          sortable: false,
        },
        {
          label: 'Status',
          field: 'status',
          width: '120px',
          sortable: false,
        },
        {
          label: 'Current State',
          field: 'current_activity',
          sortable: false,
        },
        {
          label: 'Last State',
          field: 'last_activity',
          sortable: false,
        },
        {
          label: 'Actions',
          field: 'actions',
          width: '100px',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Active',
        2: 'Inactive',
        3: 'Terminated',
      },
      {
        1: 'light-success',
        2: 'light-danger',
        3: 'light-info',
      }],
      addCandidate: {
        first_name: '',
        last_name: '',
        email_address: '',
        phone_number: '',
        file_name: '',
        document: null,
        profile_invitation: false,
      },
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.innerpage
      },
      set(val) {
        this.$store.state.query.innerpage = val
        Candidates.getCandidates(this, this.$route.params.job_id, val)
      },
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Active      : 'light-success',
        Inactive    : 'light-danger',
        Terminated  : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.jobId = this.$route.params.job_id
    this.title = this.$route.query.name
    this.job_id = this.$route.query.job_id
    this.searchQuery = this.$route.query.innerQuery
    Candidates.getCandidates(this, this.$route.params.job_id, this.$store.state.query.innerpage)
  },
  methods: {
    submitForm() {
      this.$refs.newCandidate.validate().then(result => {
        if (result) {
          Candidates.addCandidate(this, this.$route.params.job_id, this.addCandidate)
        }
      })
    },
    applyCandidate() {
      this.$refs.add_candidate.show()
    },
    closingApplyPopup() {
      this.addCandidate = {
        first_name: '',
        last_name: '',
        email_address: '',
        phone_number: '',
        file_name: '',
        document: null,
        profile_invitation: false,
      }
    },
    attahamentFileSelected(obj) {
      const dis = this
      const fileReader = new FileReader()
      fileReader.onload = fileLoadEvent => {
        dis.addCandidate.document = fileLoadEvent.target.result
      }
      fileReader.readAsDataURL(obj.target.files[0])
      this.url = URL.createObjectURL(obj.target.files[0])
      this.documentName = obj.target.files[0].name
      this.addCandidate.file_name = obj.target.files[0].name
    },
    candidateAdded(data) {
      this.searchQuery = ''
      this.$route.query.innerQuery = ''
      this.currentPage = 1
      Candidates.getCandidates(this, this.$route.params.job_id, 1)
      this.$refs.add_candidate.hide()
      this.closingApplyPopup()
      this.$refs.showLoading = false
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    candidateNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    confirmDeleteRecord(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${obj.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          Candidates.delete(this, obj.id)
        }
      })
    },
    candidateDeleted() {
      Candidates.getCandidates(this, this.$route.params.job_id, this.$store.state.query.innerpage)
      this.$swal({
        icon: 'success',
        title: 'Candidate Deleted!',
        text: 'Your Candidate has been deleted',
      })
    },
    candidateNotDeleted() {
      this.$swal({
        icon: 'warning',
        title: 'Candidate Not Deleted!',
        text: 'Your Candidate has been not deleted',
      })
    },
    updateSearchQuery() {
      this.$route.query.innerQuery = this.searchQuery
      this.currentPage = 1
      Candidates.getCandidates(this, this.$route.params.job_id, 1)
    },
    setCandidates(candidatesResponse) {
      this.candidatesResponse = candidatesResponse
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
