import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.hrurl
const workforceComment = `${HOST}workforce-comment`

export default {
  getComments(context1, id) {
    const context = context1
    axios.get(`${workforceComment}/${id}`).then(response => {
      context.setParentComments(response.data)
    }, () => {
    })
  },
  getCommentsForJobUser(context1, id) {
    const context = context1
    axios.get(`${workforceComment}/${id}`).then(response => {
      context.setParentComments(response.data, id)
    }, () => {
    })
  },
  addComment(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${workforceComment}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.setParentComments(response.data, id)
    }, error => {
      context.$vs.loading.close()
      context.commentNotAdded(error.response.data.return_message)
    })
  },

}
