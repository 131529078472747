import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.url
const esignaturetemplate = `${HOST}esignature-template`

export default {
  getTemplates(context1) {
    const context = context1
    const params = { params: { query: context.searchQuery, page: context.currentPage - 1 } }
    axios.get(esignaturetemplate, params).then(response => {
      context.setTemplates(response.data)
    }, () => {
    })
  },
  addTemplate(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(esignaturetemplate, body).then(response => {
      context.$vs.loading.close()
      context.templateAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.templateNotAdded(error.response.data.return_message)
    })
  },
  updateTemplate(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${esignaturetemplate}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.templateUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.templateNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${esignaturetemplate}/${id}`).then(response => {
      context.$vs.loading.close()
      context.templateDeleted(response.data)
    }, error => {
      context.$vs.loading.close()
      context.templateNotDeleted(error.response.data.return_message)
    })
  },
  getTemplateById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${esignaturetemplate}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setTemplate(response.data)
    }, error => {
      context.$vs.loading.close()
      context.templateNotFound(error.response.data.return_message)
    })
  },

}
