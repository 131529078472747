import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.faurl
const alerts = `${HOST}alerts`

export default {
  getAlertsCount(context1) {
    const context = context1
    axios.get(alerts).then(response => {
      context.setAlertsCount(response.data)
    }, () => {
    })
  },
}
