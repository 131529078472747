import axios from 'axios'
import Constants from '@/js/constant'
import Lists from '@/js/lists'

const HOST = Constants.hosts.adurl
const qboCategory = `${HOST}qbo-category`

export default {
  getQboCategories(context1) {
    const context = context1
    context.$vs.loading()
    let params = { query: context.searchQuery, page: context.currentPage - 1 }
    if (params !== {}) {
      params = { params: { query: context.searchQuery, page: context.currentPage - 1 } }
    } else {
      params = { params: {} }
    }
    axios.get(qboCategory, params).then(response => {
      context.$vs.loading.close()
      context.isLoading = false
      context.setQboCategories(response.data)
    }, () => {
    })
  },
  addQboCategory(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(qboCategory, body).then(response => {
      context.$vs.loading.close()
      context.isLoading = false
      context.qboCategoryAdded(response.data)
      Lists.getAllList(context)
    }, error => {
      context.$vs.loading.close()
      context.isLoading = false
      context.qboCategoryNotAdded(error.response.data.return_message)
    })
  },
  updateQboCategory(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${qboCategory}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.isLoading = false
      context.qboCategoryUpdated(response.data)
      Lists.getAllList(context)
    }, error => {
      context.$vs.loading.close()
      context.isLoading = false
      context.qboCategoryNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${qboCategory}/${id}`).then(response => {
      context.$vs.loading.close()
      context.isLoading = false
      context.qboCategoryDeleted(response.data)
      Lists.getAllList(context)
    }, error => {
      context.$vs.loading.close()
      context.isLoading = false
      context.qboCategoryNotDeleted(error.response.data.return_message)
    })
  },
  getQboCategoryById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${qboCategory}/${id}`).then(response => {
      context.$vs.loading.close()
      context.isLoading = false
      context.setQboCategory(response.data)
    }, error => {
      context.$vs.loading.close()
      context.isLoading = false
      context.qboCategoryNotFound(error.response.data.return_message)
    })
  },
}
