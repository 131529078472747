import axios from 'axios'

const financialStatement = 'financial-statement'

export default {
  getProfitRate(context1) {
    const context = context1
    context.$vs.loading()
    const params = { params: { page: context.$store.state.query.page - 1, query: context.$store.state.query.query } }
    axios.get(`${financialStatement}/profit_rate`, params).then(response => {
      context.$vs.loading.close()
      context.setReport(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getProfitAmount(context1, startDate, endDate) {
    const context = context1
    context.$vs.loading()
    const params = {
      params: {
        page: context.$store.state.query.page - 1, query: context.$store.state.query.query, status_id: context.$store.state.query.status_id, start_date: startDate, end_date: endDate,
      },
    }
    if (startDate !== null && startDate !== '') {
      params.params.start_date = startDate
    }
    if (endDate !== null && endDate !== '') {
      params.params.end_date = endDate
    }
    axios.get(`${financialStatement}/profit_amount`, params).then(response => {
      context.$vs.loading.close()
      context.setReport(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getAccountReceivables(context1, Year) {
    const context = context1
    context.$vs.loading()
    const params = {
      params: {
        page: context.$store.state.query.page - 1, type: 'receivable', year: Year, status_id: context.status_id,
      },
    }
    axios.get(`${financialStatement}/account`, params).then(response => {
      context.$vs.loading.close()
      context.setAccountReceivables(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getAccountPayables(context1, Year) {
    const context = context1
    context.$vs.loading()
    const params = {
      params: {
        page: context.$store.state.query.page - 1, type: 'payables', year: Year, status_id: context.status_id,
      },
    }
    axios.get(`${financialStatement}/account`, params).then(response => {
      context.$vs.loading.close()
      context.setAccountPayables(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getOutstandingReceivables(context1) {
    const context = context1
    context.$vs.loading()
    const params = {
      params: {
        page: context.$store.state.query.page - 1, type: 'receivable', status_id: context.status_id,
      },
    }
    axios.get(`${financialStatement}/outstanding`, params).then(response => {
      context.$vs.loading.close()
      context.setOutstandingReceivables(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getOutstandingPayables(context1) {
    const context = context1
    context.$vs.loading()
    const params = {
      params: {
        page: context.$store.state.query.page - 1, type: 'payables', status_id: context.status_id,
      },
    }
    axios.get(`${financialStatement}/outstanding`, params).then(response => {
      context.$vs.loading.close()
      context.setOutstandingPayables(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
}
