import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.hrurl
const employee = `${HOST}employee`

export default {
  getEmployees(context1) {
    const context = context1
    const params = { params: { status_id: context.$store.state.query.status_id, query: context.$store.state.query.query, page: context.$store.state.query.page - 1 } }
    axios.get(employee, params).then(response => {
      context.setEmployees(response.data)
    }, () => {
    })
  },
  addEmployee(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(employee, body).then(response => {
      context.$vs.loading.close()
      context.employeeAdded(response.data)
      context.$store.state.lists.commonLists.lists.employees = response.data.employees
      context.$store.state.lists.commonLists.lists['employees-all'] = response.data.full_list
    }, error => {
      context.$vs.loading.close()
      context.employeeNotAdded(error.response.data.return_message)
    })
  },
  updateEmployee(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${employee}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.employeeUpdated(response.data)
      context.$store.state.lists.commonLists.lists.employees = response.data.employees
      context.$store.state.lists.commonLists.lists['employees-all'] = response.data.full_list
    }, error => {
      context.$vs.loading.close()
      context.employeeNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${employee}/${id}`).then(response => {
      context.$vs.loading.close()
      context.employeeDeleted(response.data)
      context.$store.state.lists.commonLists.lists.employees = response.data.employees
      context.$store.state.lists.commonLists.lists['employees-all'] = response.data.full_list
    }, error => {
      context.$vs.loading.close()
      context.employeeNotDeleted(error.response.data.return_message)
    })
  },
  getEmployeeById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${employee}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setEmployee(response.data)
    }, error => {
      context.$vs.loading.close()
      context.employeeNotFound(error.response.data.return_message)
    })
  },

}
