<template>
  <div class="ex-large dashboard-response">
    <b-row class="match-height">
      <b-col
        v-show="$store.state.roles.is_jobs_exist"
        xl="2"
        lg="3"
        md="4"
        sm="6"
      >
        <b-link
          :to="{ name: 'human-resources-prospects-admin-jobs' }"
          class="text-secondary"
        >
          <statistic-card-vertical
            :statistic="counts.jobs_count"
            icon="SearchIcon"
            statistic-title="Jobs"
            color="primary"
          />
        </b-link>
      </b-col>
      <b-col
        v-show="$store.state.roles.is_jobs_exist"
        xl="2"
        lg="3"
        md="4"
        sm="6"
      >
        <b-link
          :to="{ name: 'human-resources-prospects-admin-jobs', query: {'advertised_jobs': true} }"
          class="text-secondary"
        >
          <statistic-card-vertical
            :statistic="counts.advertised_jobs"
            icon="MonitorIcon"
            statistic-title="Advertised Jobs"
            color="success"
          />
        </b-link>
      </b-col>
      <b-col
        v-show="$store.state.roles.is_jobs_exist"
        xl="2"
        lg="3"
        md="4"
        sm="6"
      >
        <!-- <b-link
          :to="{ name: 'human-resources-prospects-admin-jobs', query: {'advertised_jobs': true} }"
          class="text-secondary"
        /> -->
        <statistic-card-vertical
          :statistic="counts.advertised_running_out_jobs"
          icon="MonitorIcon"
          statistic-title="Advertised Closed Jobs"
          color="danger"
        />
      </b-col>
      <b-col
        v-show="$store.state.roles.is_employee_exist"
        xl="2"
        md="4"
        lg="3"
        sm="6"
      >
        <b-link
          class="text-secondary"
          :to="{ name: 'human-resources-employees' }"
        >
          <statistic-card-vertical
            :key="counts.employees_count"
            :statistic="counts.employees_count"
            icon="UsersIcon"
            statistic-title="Employees"
            color="primary"
          />
        </b-link>
      </b-col>
      <b-col
        v-show="$store.state.roles.is_prospect_exist"
        xl="2"
        md="4"
        lg="3"
        sm="6"
      >
        <b-link
          :to="{ name: 'human-resources-prospects-admin-all-prospects' }"
          class="text-secondary"
        >
          <statistic-card-vertical
            :statistic="counts.prospects_count"
            icon="UsersIcon"
            statistic-title="Prospects"
            color="warning"
          />
        </b-link>
      </b-col>
      <b-col
        v-show="$store.state.roles.is_facility_exist"
        xl="2"
        md="4"
        lg="3"
        sm="6"
      >
        <b-link
          :to="{ name: 'human-resources-vendors' }"
          class="text-secondary"
        >
          <statistic-card-vertical
            :statistic="counts.vendors"
            icon="UsersIcon"
            statistic-title="Vendors"
            color="info"
          />
        </b-link>
      </b-col>
      <b-col
        v-show="$store.state.roles.is_facility_exist"
        xl="2"
        md="4"
        lg="3"
        sm="6"
      >
        <b-link
          :to="{ name: 'human-resources-facilities' }"
          class="text-secondary"
        >
          <statistic-card-vertical
            :statistic="counts.facilities_count"
            icon="UsersIcon"
            statistic-title="Facilities"
            color="primary"
          />
        </b-link>
      </b-col>
      <b-col
        v-show="$store.state.roles.is_receivable_invoice_exist"
        xl="2"
        md="4"
        lg="3"
        sm="6"
      >
        <b-link class="text-secondary">
          <statistic-card-vertical
            :statistic="counts.receivable_pending_count"
            icon="FileTextIcon"
            statistic-title="Receivable Invoices Pending"
            color="info"
          />
        </b-link>
      </b-col>
      <b-col
        v-show="$store.state.roles.is_timesheets_exist"
        xl="2"
        md="4"
        lg="3"
        sm="6"
      >
        <b-link class="text-secondary">
          <statistic-card-vertical
            :statistic="counts.timesheets_waiting_count"
            icon="ClockIcon"
            statistic-title="Timesheets Waiting"
            color="warning"
          />
        </b-link>
      </b-col>
      <b-col
        v-show="$store.state.roles.is_timesheets_exist"
        xl="2"
        md="4"
        lg="3"
        sm="6"
      >
        <b-link class="text-secondary">
          <statistic-card-vertical
            :statistic="counts.timesheets_received_count"
            icon="ClockIcon"
            statistic-title="Timesheets Received"
            color="success"
          />
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BLink } from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import List from '@/js/lists'
import User from '@/js/user'

export default {
  name: 'AppDashboard',
  components: {
    BRow,
    BCol,
    BLink,
    StatisticCardVertical,
  },
  data() {
    return {
      user: User,
    }
  },
  computed: {
    counts: {
      get() {
        return this.$store.state.counts
      },
      set(val) {
        this.$store.state.counts = val
      },
    },
  },
  created() {
    List.getDashboardCount(this)
  },
  methods: {
    setCounts(counts) {
      this.counts = counts
      const topCounts = {
        alerts: counts.alerts_count,
        registrants: counts.prospects_registrant,
        not_registrants: counts.prospects_not_registrant,
        applied_prospects: counts.prospects_applied_jobs,
        eligible_prospects: counts.prospects_new_jobs,
        eligible_registrants: counts.registrants_new_jobs,
        urgent_jobs: counts.urgent_jobs,
      }
      this.$store.state.top_counts.alerts = counts.alerts_count
      this.$store.state.top_counts.registrants = counts.prospects_registrant
      this.$store.state.top_counts.not_registrants = counts.prospects_not_registrant
      this.$store.state.top_counts.applied_prospects = counts.prospects_applied_jobs
      this.$store.state.top_counts.eligible_prospects = counts.prospects_new_jobs
      this.$store.state.top_counts.eligible_registrants = counts.registrants_new_jobs
      this.$store.state.top_counts.urgent_jobs = counts.urgent_jobs
      this.$store.commit('ALERTS_COUNT', topCounts)
    },
  },
}
</script>

<style>

</style>
