<template>
  <b-card
    class="mb-0"
  >
    <div class="mb-2">
      <b-row>
        <b-col
          cols="12"
          md="3"
        >
          <h4 class="card-title mb-0 pt-50">
            Reconcile An Account</h4>
        </b-col>
        <b-col
          cols="12"
          md="8 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              variant="outline-primary"
              class="ml-1"
              @click="back()"
            >
              Back
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <hr>
    <b-form>
      <b-row>
        <b-col cols="12">
          <div class="d-md-flex align-items-top justify-content-between">
            <b-form-group class="w-50">
              <label>Account</label>
              <span
                class="form-control"
                v-text="bankTransactionsResponse.account_number"
              />
            </b-form-group>
            <b-form-group class="w-50 ml-md-1">
              <label>Statement Start Date</label>
              <flat-pickr
                id="statement_start_date"
                v-model="statement_start_date"
                class="form-control"
                :config="$store.state.config"
                placeholder="MM-DD-YYYY"
              />
            </b-form-group>
            <b-form-group class="w-50 ml-md-1">
              <label>Statement End Date</label>
              <flat-pickr
                id="statement_end_date"
                v-model="statement_end_date"
                class="form-control"
                :config="$store.state.config"
                placeholder="MM-DD-YYYY"
              />
            </b-form-group>
            <b-form-group class="w-50 ml-md-1">
              <label>Type</label>
              <v-select
                id="type"
                v-model="register_payment_type_id"
                :options="registerpaymentTypes"
                :reduce="item => item.id"
                :clearable="true"
                placeholder="Select"
                @input="paymentTypeSelected"
              />
            </b-form-group>
            <div class="min-w-235 text-right mt-2 ml-1">
              <b-button
                variant="primary"
                class="min-w-150 px-50"
                @click.prevent="getTransactions"
              >
                Start Reconciling
              </b-button>
              <b-button
                variant="primary"
                class="ml-1 px-75"
                @click.prevent="matchTtransactionsClick"
              >
                Match
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <div class="d-flex align-items-top justify-content-between">
            <div class="w-50 border">
              <div class="d-flex align-items-center justify-content-between my-1 mx-1">
                <b-form-group
                  label="Cr/Dr"
                  label-cols-md="4"
                  class="text-right w-50 mb-0 mr-2"
                >
                  <v-select
                    id="cr_dr"
                    v-model="register_cr_dr"
                    :options="creditDebit"
                    :reduce="item => item.id"
                    :clearable="true"
                    placeholder="Select"
                    @input="updateCrDr('register')"
                  />
                </b-form-group>
                <b-input-group>
                  <b-form-input
                    v-model="registerSearchAmount"
                    placeholder="Search"
                    @blur="registerSearch"
                    @keyup.enter="registerSearch"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                    >
                      <feather-icon
                        icon="SearchIcon"
                        size="14"
                      />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div class="d-flex align-items-center justify-content-between my-1 mx-1">
                <div class="min-w-200">
                  <h5 class="mb-0">
                    Register
                  </h5>
                </div>
                <div class="min-w-150 text-right ml-1">
                  <span class="font-medium-1 font-weight-bold">
                    $ {{ registerSelectedAmount }}
                  </span>
                </div>
              </div>
              <vue-good-table
                :columns="columns"
                :rows="paymentDetails.payment_details"
                max-height="500px"
                :rtl="direction"
                :pagination-options="{
                  enabled: false,
                }"
                :fixed-header="true"
              >

                <template
                  slot="table-row"
                  slot-scope="props"
                >

                  <!-- Column: Currency -->
                  <span v-if="props.column.field === 'Currency'">
                    <span>{{ props.row.amount| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
                  </span>
                  <!-- Column: Details -->
                  <span v-if="props.column.field === 'CellRendererCheckbox'">
                    <b-form-checkbox
                      v-model="props.row.selected"
                      :disabled="isMatched(props.row.index) ? true : false"
                      @change="registerCheckChanged(props.row)"
                    />
                  </span>

                  <!-- Column: Common -->
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </vue-good-table>
            </div>
            <div class="w-50 border">
              <div class="d-flex align-items-center justify-content-between my-1 mx-1">
                <b-form-group
                  label="Cr/Dr"
                  label-cols-md="4"
                  class="text-right w-50 mb-0 mr-2"
                >
                  <v-select
                    id="cr_dr"
                    v-model="bank_cr_dr"
                    :options="creditDebit"
                    :reduce="item => item.id"
                    :clearable="true"
                    placeholder="Select"
                    @input="updateCrDr('bank')"
                  />
                </b-form-group>
                <b-input-group>
                  <b-form-input
                    v-model="bankSearchAmount"
                    placeholder="Search"
                    @blur="bankSearch"
                    @keyup.enter="bankSearch"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                    >
                      <feather-icon
                        icon="SearchIcon"
                        size="14"
                      />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div class="d-flex align-items-center justify-content-between my-1 mx-1">
                <div class="min-w-200">
                  <h5 class="mb-0">
                    {{ bankTransactionsResponse.bank_name }}</h5>
                </div>
                <div class="min-w-150 text-right ml-1">
                  <span class="font-medium-1 font-weight-bold">
                    $ {{ bankSelectedAmount }}
                  </span>
                </div>
              </div>
              <vue-good-table
                :columns="columns2"
                :rows="bankTransactionsResponse.bank_transactions"
                max-height="500px"
                :rtl="direction"
                :pagination-options="{
                  enabled: false,
                }"
                :fixed-header="true"
              >

                <template
                  slot="table-row"
                  slot-scope="props"
                >

                  <!-- Column: Currency -->
                  <span v-if="props.column.field === 'Currency'">
                    <span>{{ props.row.amount| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
                  </span>

                  <!-- Column: Details -->
                  <span v-if="props.column.field === 'CellRendererCheckbox'">
                    <b-form-checkbox
                      v-model="props.row.selected"
                      :disabled="isBankMatched(props.row.index) ? true : false"
                      @change="bankCheckChanged(props.row)"
                    />
                  </span>

                  <!-- Column: Common -->
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </vue-good-table>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="12">
          <vue-good-table
            :columns="columns4"
            :rows="matched.transactions"
            :rtl="direction"
            :pagination-options="{
              enabled: false,
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Details -->
              <span v-if="props.column.field === 'transactions'">
                <vue-good-table
                  :columns="columns3"
                  :rows="props.row.transactions"
                  :rtl="direction"
                  :pagination-options="{
                    enabled: false,
                  }"
                >

                  <template
                    slot="table-row"
                    slot-scope="props"
                  >

                    <!-- Column: Currency -->
                    <span v-if="props.column.field === 'Currency'">
                      <span>{{ props.row.amount| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </template>
                </vue-good-table>
              </span>

              <span v-if="props.column.field === 'cellRendererUnMatchButton'">
                <b-button
                  variant="outline-primary"
                  type="button"
                  class="py-50 px-1"
                  @click="unMatchRecords(props.row)"
                >
                  Unmatch
                </b-button>
              </span>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="text-right"
        >
          <b-button
            variant="primary"
            type="button"
            @click.prevent="reconcile"
          >
            Reconcile
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BForm, BRow, BCol, BFormGroup, BInputGroup, BFormInput, BInputGroupAppend, BFormCheckbox, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import BankTransaction from '@/js/bankTransactions'
import moment from 'moment'
import Constants from '@/js/constant'
import Reconcile from '@/js/reconcile'
import LIST from '@/js/lists'

export default {
  name: 'ReconcileAnAccount',
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    VueGoodTable,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormCheckbox,
    BButton,
    flatPickr,
    vSelect,
  },
  data() {
    return {
      searchQuery: '',
      bank_cr_dr: null,
      register_cr_dr: null,
      bankSearchAmount: '',
      registerSearchAmount: '',
      pageLength: 10,
      register_payment_type_id: null,
      statement_start_date: null,
      statement_end_date: null,
      creditDebit: [],
      selectedRegisterTransactionIds: [],
      selectedBankTransactionIds: [],
      matchedRegisterIds: [],
      matchedBankIds: [],
      registerpaymentTypes: [],
      registerSelectedAmount: Number(0),
      bankSelectedAmount: Number(0),
      matched: { transactions: [] },
      paymentDetails: { payment_details: [] },
      bankTransactionsResponse: {
        bank_transactions: [],
      },
      columns: [
        {
          label: '',
          field: 'CellRendererCheckbox',
          width: '50px',
          sortable: false,
        },
        {
          label: 'Date',
          field: 'date',
          width: '120px',
          sortable: false,
        },
        {
          label: 'Amount',
          field: 'Currency',
          sortable: false,
        },
        {
          label: 'P-type',
          field: 'payment_type',
          sortable: false,
        },
        {
          label: 'Payee/Payer',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Invoice Id',
          field: 'invoice_id',
          sortable: false,
        },
        {
          label: 'Ref No',
          field: 'ref',
          sortable: false,
        },
        {
          label: 'Type',
          field: 'type',
          sortable: false,
        },
      ],
      columns2: [
        {
          label: '',
          field: 'CellRendererCheckbox',
          width: '50px',
          sortable: false,
        },
        {
          label: 'Date',
          field: 'date',
          width: '120px',
          sortable: false,
        },
        {
          label: 'Type',
          field: 'type',
          sortable: false,
        },
        {
          label: 'Amount',
          field: 'Currency',
          sortable: false,
        },
        {
          label: 'Transaction Name',
          field: 'transaction_name',
          sortable: false,
        },
        {
          label: 'Ref No',
          field: 'ref',
          sortable: false,
        },
      ],
      columns4: [
        {
          label: 'Transactions',
          field: 'transactions',
        },
        {
          label: 'Action',
          field: 'cellRendererUnMatchButton',
          width: '120px',
          sortable: false,
        },
      ],
      columns3: [
        {
          label: 'Trans Type',
          field: 'transaction_type',
        },
        {
          label: 'Date',
          field: 'date',
        },
        {
          label: 'Type',
          field: 'type',
          sortable: false,
        },
        {
          label: 'Payee/Payer',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Ref No',
          field: 'ref',
          sortable: false,
        },
        {
          label: 'Amount',
          field: 'Currency',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
        BankTransaction.getReconcileHistoriesBybankId(this)
      },
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        return true
      }
      return false
    },
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getRegisterpaymentTypes(this)
      },
    },
  },
  created() {
    LIST.getRegisterpaymentTypes(this)
    this.dateChanged()
    this.bankTransactionsResponse.account_number = this.$route.query.account_number
    this.bankTransactionsResponse.bank_name = this.$route.query.bank_name
    Constants.getCreditDebitTypes(this)
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    setRegisterpaymentTypes(registerpaymentTypes) {
      this.registerpaymentTypes = registerpaymentTypes
    },
    paymentTypeSelected() {
      if (this.statement_start_date === null) {
        return
      }
      if (this.statement_end_date === null) {
        return
      }
      BankTransaction.getPaymentDetailsBYEndDate(this, moment(this.statement_start_date, 'MM-DD-YYYY').format('MM-DD-YYYY'), moment(this.statement_end_date, 'MM-DD-YYYY').format('MM-DD-YYYY'), this.$route.params.id, this.register_payment_type_id)
    },
    updateCrDr(type) {
      if (type === 'bank') {
        if (this.bank_cr_dr === null) {
          this.bankClear()
        } else {
          this.bankSearchCRDR()
        }
      } else if (this.register_cr_dr === null) {
        this.registerClear()
      } else {
        this.registerSearchCRDR()
      }
    },
    reconcile() {
      const matchRequest = { reconciles: [] }
      matchRequest.start_date = Constants.getEprmMonthDateFromString(moment(this.statement_start_date, 'MM-DD-yyyy'))
      matchRequest.end_date = Constants.getEprmMonthDateFromString(moment(this.statement_end_date, 'MM-DD-yyyy'))
      for (let i = 0; i < this.matched.transactions.length; i += 1) {
        const match = { bank_ids: [], payment_detail_ids: [] }
        for (let j = 0; j < this.matched.transactions[i].transactions.length; j += 1) {
          if (this.matched.transactions[i].transactions[j].transaction_type === 'Bank') {
            match.bank_ids.push(this.matched.transactions[i].transactions[j].id)
          } else {
            match.payment_detail_ids.push(this.matched.transactions[i].transactions[j].id)
          }
        }
        matchRequest.reconciles.push(match)
      }
      if (matchRequest.reconciles.length === 0) {
        this.$swal({
          title: 'Reconcile!',
          text: 'Please match atleast one transaction',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      this.$vs.loading()
      Reconcile.updateReconcileByBankId(this, this.$route.params.id, matchRequest)
    },
    registerClear() {
      this.paymentDetails.payment_details = []
      this.registerSearch()
    },
    registerSearch() {
      this.paymentDetails.payment_details = []
      for (let i = 0; i < this.paymentOriginalDetails.payment_details.length; i += 1) {
        if (this.paymentOriginalDetails.payment_details[i].name.toLowerCase().includes(!Number.isNaN(this.registerSearchAmount) ? this.registerSearchAmount : this.registerSearchAmount.toLowerCase()) || this.paymentOriginalDetails.payment_details[i].payment_type.toLowerCase().includes(!Number.isNaN(this.registerSearchAmount) ? this.registerSearchAmount : this.registerSearchAmount.toLowerCase()) || Number(this.paymentOriginalDetails.payment_details[i].amount) === Number(this.registerSearchAmount.toString().replace(/\D/g, '')) || this.paymentOriginalDetails.payment_details[i].ref.toLowerCase().includes(!Number.isNaN(this.registerSearchAmount) ? this.registerSearchAmount : this.registerSearchAmount.toLowerCase())) {
          if (this.register_cr_dr !== null) {
            if (this.register_cr_dr === 'credit') {
              if (this.paymentOriginalDetails.payment_details[i].type.toLowerCase() === 'Income'.toLowerCase()) {
                this.paymentDetails.payment_details.push(this.paymentOriginalDetails.payment_details[i])
              }
            } else if (this.paymentOriginalDetails.payment_details[i].type.toLowerCase() !== 'Income'.toLowerCase()) {
              this.paymentDetails.payment_details.push(this.paymentOriginalDetails.payment_details[i])
            }
          } else {
            this.paymentDetails.payment_details.push(this.paymentOriginalDetails.payment_details[i])
          }
        }
      }
    },
    registerSearchCRDR() {
      this.paymentDetails.payment_details = []
      for (let i = 0; i < this.paymentOriginalDetails.payment_details.length; i += 1) {
        if (this.register_cr_dr === 'credit') {
          if (this.paymentOriginalDetails.payment_details[i].type.toLowerCase() === 'Income'.toLowerCase()) {
            if (this.paymentOriginalDetails.payment_details[i].name.toLowerCase().includes(!Number.isNaN(this.registerSearchAmount) ? this.registerSearchAmount : this.registerSearchAmount.toLowerCase()) || this.paymentOriginalDetails.payment_details[i].payment_type.toLowerCase().includes(!Number.isNaN(this.registerSearchAmount) ? this.registerSearchAmount : this.registerSearchAmount.toLowerCase()) || Number(this.paymentOriginalDetails.payment_details[i].amount) === Number(this.registerSearchAmount.toString().replace(/\D/g, '')) || this.paymentOriginalDetails.payment_details[i].ref.toLowerCase().includes(!Number.isNaN(this.registerSearchAmount) ? this.registerSearchAmount : this.registerSearchAmount.toLowerCase())) {
              this.paymentDetails.payment_details.push(this.paymentOriginalDetails.payment_details[i])
            }
          }
        } else if (this.paymentOriginalDetails.payment_details[i].type.toLowerCase() !== 'Income'.toLowerCase()) {
          if (this.paymentOriginalDetails.payment_details[i].name.toLowerCase().includes(!Number.isNaN(this.registerSearchAmount) ? this.registerSearchAmount : this.registerSearchAmount.toLowerCase()) || this.paymentOriginalDetails.payment_details[i].payment_type.toLowerCase().includes(!Number.isNaN(this.registerSearchAmount) ? this.registerSearchAmount : this.registerSearchAmount.toLowerCase()) || Number(this.paymentOriginalDetails.payment_details[i].amount) === Number(this.registerSearchAmount.toString().replace(/\D/g, '')) || this.paymentOriginalDetails.payment_details[i].ref.toLowerCase().includes(!Number.isNaN(this.registerSearchAmount) ? this.registerSearchAmount : this.registerSearchAmount.toLowerCase())) {
            this.paymentDetails.payment_details.push(this.paymentOriginalDetails.payment_details[i])
          }
        }
      }
    },
    bankSearchCRDR() {
      this.bankTransactionsResponse.bank_transactions = []
      for (let i = 0; i < this.bankTransactionsOriginalResponse.bank_transactions.length; i += 1) {
        if (this.bankTransactionsOriginalResponse.bank_transactions[i].type.toLowerCase().includes(this.bank_cr_dr.toLowerCase())) {
          if (this.bankTransactionsOriginalResponse.bank_transactions[i].transaction_name.toLowerCase().includes(Number.isNaN(this.bankSearchAmount) ? this.bankSearchAmount.toLowerCase() : this.bankSearchAmount) || this.bankTransactionsOriginalResponse.bank_transactions[i].type.toLowerCase().includes(Number.isNaN(this.bankSearchAmount) ? this.bankSearchAmount.toLowerCase() : this.bankSearchAmount) || Number(this.bankTransactionsOriginalResponse.bank_transactions[i].amount) === Number(this.bankSearchAmount.toString().replace(/\D/g, '')) || this.bankTransactionsOriginalResponse.bank_transactions[i].ref.toLowerCase().includes(Number.isNaN(this.bankSearchAmount) ? this.bankSearchAmount.toLowerCase() : this.bankSearchAmount)) {
            this.bankTransactionsResponse.bank_transactions.push(this.bankTransactionsOriginalResponse.bank_transactions[i])
          }
        }
      }
    },
    bankClear() {
      this.bankTransactionsResponse.bank_transactions = []
      this.bankSearch()
    },
    bankSearch() {
      this.bankTransactionsResponse.bank_transactions = []
      for (let i = 0; i < this.bankTransactionsOriginalResponse.bank_transactions.length; i += 1) {
        if (this.bankTransactionsOriginalResponse.bank_transactions[i].transaction_name.toLowerCase().includes(Number.isNaN(this.bankSearchAmount) ? this.bankSearchAmount.toLowerCase() : this.bankSearchAmount) || this.bankTransactionsOriginalResponse.bank_transactions[i].type.toLowerCase().includes(Number.isNaN(this.bankSearchAmount) ? this.bankSearchAmount.toLowerCase() : this.bankSearchAmount) || Number(this.bankTransactionsOriginalResponse.bank_transactions[i].amount) === Number(this.bankSearchAmount.toString().replace(/\D/g, '')) || this.bankTransactionsOriginalResponse.bank_transactions[i].ref.toLowerCase().includes(Number.isNaN(this.bankSearchAmount) ? this.bankSearchAmount.toLowerCase() : this.bankSearchAmount)) {
          if (this.bank_cr_dr !== null) {
            if (this.bankTransactionsOriginalResponse.bank_transactions[i].type.toLowerCase().includes(this.bank_cr_dr.toLowerCase())) {
              this.bankTransactionsResponse.bank_transactions.push(this.bankTransactionsOriginalResponse.bank_transactions[i])
            }
          } else {
            this.bankTransactionsResponse.bank_transactions.push(this.bankTransactionsOriginalResponse.bank_transactions[i])
          }
        }
      }
    },
    onColumnResized(params) {
      params.api.resetRowHeights()
    },
    onColumnVisible(params) {
      params.api.resetRowHeights()
    },
    setBankTransactions(transactionsResponse) {
      this.$vs.loading.close()
      this.bankTransactionsResponse = transactionsResponse
      for (let i = 0; i < transactionsResponse.bank_transactions.length; i += 1) {
        this.bankTransactionsOriginalResponse.bank_transactions.push(transactionsResponse.bank_transactions[i])
      }
    },
    setPaymentDetails(paymentDetails) {
      this.$vs.loading.close()
      this.paymentDetails = paymentDetails
      for (let i = 0; i < paymentDetails.payment_details.length; i += 1) {
        this.paymentOriginalDetails.payment_details.push(paymentDetails.payment_details[i])
      }
    },
    dateChanged() {
      if (this.statement_start_date !== null) {
        this.disabledEndDates.to = new Date(this.statement_start_date)
      }
      if (this.statement_end_date !== null) {
        this.disabledStartDates.from = new Date(this.statement_end_date)
      }
    },
    matchTtransactionsClick() {
      if (this.selectedBankTransactionIds.length === 0 && this.selectedRegisterTransactionIds.length === 0) {
        return
      }
      if (this.selectedBankTransactionIds.length === 0) {
        this.$swal({
          title: 'Matching!',
          text: ' Please select bank transaction!',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.selectedRegisterTransactionIds.length === 0) {
        this.$swal({
          title: 'Matching!',
          text: ' Please select register transaction!',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.registerSelectedAmount !== this.bankSelectedAmount) {
        this.$swal({
          title: 'Matching!',
          text: ' Amounts are mismatch!',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      this.matchTtransactions()
    },
    isMatched(index) {
      return this.matchedRegisterIds.includes(index)
    },
    isBankMatched(index) {
      return this.matchedBankIds.includes(index)
    },
    unMatchRecords(data) {
      for (let i = 0; i < data.transactions.length; i += 1) {
        if (data.transactions[i].transaction_type === 'Bank') {
          if (this.matchedBankIds.includes(data.transactions[i].index)) {
            this.matchedBankIds.splice(this.matchedBankIds.indexOf(data.transactions[i].index), 1)
          }
        } else if (this.matchedRegisterIds.includes(data.transactions[i].index)) {
          this.matchedRegisterIds.splice(this.matchedRegisterIds.indexOf(data.transactions[i].index), 1)
        }
      }
      this.bankClear()
      this.registerClear()
      this.matched.transactions.splice(data.index, 1)
    },
    matchTtransactions() {
      const transactionsList = []
      for (let i = 0; i < this.selectedBankTransactionIds.length; i += 1) {
        const bankTransaction = this.bankTransactionsOriginalResponse.bank_transactions[this.selectedBankTransactionIds[i] - 1]
        bankTransaction.transaction_type = 'Bank'
        transactionsList.push(bankTransaction)
        this.bankTransactionsOriginalResponse.bank_transactions[this.selectedBankTransactionIds[i] - 1].selected = false
      }
      for (let i = 0; i < this.selectedRegisterTransactionIds.length; i += 1) {
        const registerTransaction = this.paymentOriginalDetails.payment_details[this.selectedRegisterTransactionIds[i] - 1]
        registerTransaction.transaction_type = 'Register'
        transactionsList.push(registerTransaction)
        this.paymentOriginalDetails.payment_details[this.selectedRegisterTransactionIds[i] - 1].selected = false
      }
      const matchedTransaction = {
        transactions: transactionsList,
        index: this.matched.transactions.length,
      }
      this.matched.transactions.push(matchedTransaction)
      this.matchedRegisterIds = [...this.matchedRegisterIds, this.selectedRegisterTransactionIds]
      this.matchedBankIds = [...this.matchedBankIds, this.selectedBankTransactionIds]
      this.selectedBankTransactionIds = []
      this.selectedRegisterTransactionIds = []
      this.registerSelectedAmount = 0
      this.bankSelectedAmount = 0
    },
    getTransactions() {
      if (this.statement_start_date === null) {
        this.$swal({
          title: 'Start date',
          text: ' Please select start date',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.statement_end_date === null) {
        this.$swal({
          title: 'End date',
          text: ' Please select end date',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      this.selectedBankTransactionIds = []
      this.selectedRegisterTransactionIds = []
      this.matchedRegisterIds = []
      this.matchedBankIds = []
      this.registerSelectedAmount = 0
      this.bankSelectedAmount = 0
      this.matched.transactions = []
      this.bankTransactionsOriginalResponse = { bank_transactions: [] }
      this.paymentOriginalDetails = { payment_details: [] }
      this.bankTransactionsResponse = { bank_transactions: [] }
      this.paymentDetails = { payment_details: [] }
      this.$vs.loading()
      BankTransaction.getBankTransactionsByBankIdAndEndDate(this, this.$route.params.id, moment(this.statement_start_date, 'MM-DD-YYYY').format('MM-DD-YYYY'), moment(this.statement_end_date, 'MM-DD-YYYY').format('MM-DD-YYYY'))
      BankTransaction.getPaymentDetailsBYEndDate(this, moment(this.statement_start_date, 'MM-DD-YYYY').format('MM-DD-YYYY'), moment(this.statement_end_date, 'MM-DD-YYYY').format('MM-DD-YYYY'), this.$route.params.id, this.register_payment_type_id)
    },
    registerCheckChanged(data) {
      if (data.selected) {
        this.registerSelectedAmount = (Number(this.registerSelectedAmount) + Number(data.amount)).toFixed(2)
        if (!this.selectedRegisterTransactionIds.includes(data.index)) {
          this.selectedRegisterTransactionIds.push(data.index)
        }
      } else {
        this.registerSelectedAmount = (Number(this.registerSelectedAmount) - Number(data.amount)).toFixed(2)
        if (this.selectedRegisterTransactionIds.includes(data.index)) {
          this.selectedRegisterTransactionIds.splice(this.selectedRegisterTransactionIds.indexOf(data.index), 1)
        }
      }
    },
    bankCheckChanged(data) {
      if (data.selected) {
        this.bankSelectedAmount = (Number(this.bankSelectedAmount) + Number(data.amount)).toFixed(2)
        if (!this.selectedBankTransactionIds.includes(data.index)) {
          this.selectedBankTransactionIds.push(data.index)
        }
      } else {
        this.bankSelectedAmount = (Number(this.bankSelectedAmount) - Number(data.amount)).toFixed(2)
        if (this.selectedBankTransactionIds.includes(data.index)) {
          this.selectedBankTransactionIds.splice(this.selectedBankTransactionIds.indexOf(data.index), 1)
        }
      }
    },
    monthChanged() {
      this.currentPage = 1
    },
    setReconcileCreated(data) {
      this.$vs.loading.close()
      this.back()
      this.$swal({
        title: 'Reconcile',
        text: data.return_message,
        accept: null,
      })
    },
    setReconcileNOtCreated(message) {
      this.$vs.loading.close()
      this.$swal({
        title: 'Reconcile!',
        text: message,
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
