<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Bank Transaction Details</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <app-timeline>
      <app-timeline-item class="pb-1">
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Bank Name</label>
              <span
                class="form-control"
                v-text="bankTransaction.bank_name"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Transaction Amount</label>
              <span class="form-control">{{ bankTransaction.amount| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Transaction Date</label>
              <span
                class="form-control"
                v-text="bankTransaction.date"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Payment Channel</label>
              <span
                class="form-control"
                v-text="bankTransaction.channel"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Category</label>
              <span
                class="form-control"
                v-text="bankTransaction.category"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Credit / Debit</label>
              <span
                class="form-control"
                v-text="bankTransaction.type"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Transaction Id</label>
              <span
                class="form-control h-auto"
                v-text="bankTransaction.transaction_id"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Transaction Name</label>
              <span
                class="form-control h-auto"
                v-text="bankTransaction.name"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Ref #</label>
              <span
                class="form-control"
                v-text="bankTransaction.ref"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>QBO Category</label>
              <span
                class="form-control"
                v-text="bankTransaction.qbo_category"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-button
              v-b-modal.qboCategory
              variant="outline-primary"
              type="button"
              class="mt-2"
            >
              <spen
                v-if="(bankTransaction.qbo_category_id !== null)"
              >
                Update QBO
              </spen>
              <spen
                v-if="(bankTransaction.qbo_category_id === null)"
              >
                Assign QBO
              </spen>
            </b-button>
            <b-modal
              id="qboCategory"
              ref="qboCategory"
              modal-class="modal-primary"
              centered
              size="lg"
              title="Assign QBO Category and Reconcile"
              ok-only
              ok-title="Accept"
              :no-close-on-backdrop="true"
            >
              <b-form>
                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Transaction Amount">
                      <span class="form-control">{{ bankTransaction.amount| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Transaction Date">
                      <span
                        class="form-control"
                        v-text="bankTransaction.date"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Transaction Name">
                      <span
                        class="form-control height-auto min-h-38"
                        v-text="bankTransaction.name"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="CR/DB">
                      <span
                        class="form-control"
                        v-text="bankTransaction.type"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="QBO Category">
                      <v-select
                        v-model="qbo_category_id"
                        :reduce="item => item.id"
                        :options="qboCategories"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-alert
                  variant="primary"
                  show
                  class="mb-0"
                >
                  <div class="alert-body">
                    <span>By assigning the QBO category id automatically reconcile the transaction.</span>
                  </div>
                </b-alert>
              </b-form>
              <template #modal-footer>
                <div class="d-flex justify-content-between w-100">
                  <b-button
                    variant="outline-primary"
                    class="px-1"
                    @click="close()"
                  >
                    Cancel
                  </b-button>
                  <b-button
                    variant="primary"
                    class="ml-1 px-1"
                    @click="openConfirm()"
                  >
                    Save
                  </b-button>
                </div>
              </template>
            </b-modal>
          </b-col>
        </b-row>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard, BForm, BRow, BCol, BFormGroup, BButton, BModal, VBModal, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import BankTransactions from '@/js/bankTransactions'
import List from '@/js/lists'

export default {
  name: 'BankTransactionView',
  components: {
    BCard,
    BForm,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BModal,
    BAlert,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      bankTransaction: {},
      qbo_category_id: null,
      qboCategories: [],
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        List.getQboCategories(this)
      },
    },
  },
  mounted() {
  },
  created() {
    List.getQboCategories(this)
    BankTransactions.getBankTransactionById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    close() {
      this.qbo_category_id = null
      this.qboCategory = false
    },
    back() {
      this.$router.go(-1)
    },
    setBankTransaction(bankTransaction) {
      this.bankTransaction = bankTransaction
      this.qbo_category_id = this.bankTransaction.qbo_category_id
    },
    openConfirm() {
      if (this.qbo_category_id === null) {
        return
      }
      this.$swal({
        title: 'Confirm',
        text: 'Do you really want to update the QBO category id?',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.acceptAlert()
        }
      })
    },
    setQboCategories(qboCategories) {
      this.qboCategories = qboCategories
    },
    acceptAlert() {
      const request = {
        bankTransaction_id: this.qboBankTransactionId,
      }
      BankTransactions.applyQboCategoryIdTobankTransaction(this, this.$route.params.id, this.qbo_category_id, request)
    },
    setQboCategoryIdUpdatedSuccessfully(message) {
      this.close()
      this.$refs.qboCategory.hide()
      BankTransactions.getBankTransactionById(this, this.$route.params.id)
      this.$vs.notify({
        color: 'success',
        title: 'Qbo',
        text: message,
      })
    },
    setQboCategoryIdUpdatedFailure(message) {
      this.close()
      this.$vs.loading.close()
      this.$vs.notify({
        color: 'warning',
        title: 'Qbo',
        text: message,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
