import Dashboard from '@/views/financial-statements/Dashboard.vue'
import ProfitRatePerResource from '@/views/financial-statements/profit-rate-per-resource/ProfitRatePerResource.vue'
import ProfitAmountsPerResource from '@/views/financial-statements/profit-amounts-per-resource/ProfitAmountsPerResource.vue'
import Statements from '@/views/financial-statements/statements/Statements.vue'
import StatementsNew from '@/views/financial-statements/statements/actions/New.vue'
import AccountReceivables from '@/views/financial-statements/account-receivables/AccountReceivables.vue'
import AccountPayables from '@/views/financial-statements/account-payables/AccountPayables.vue'
import OutstandingAccountReceivables from '@/views/financial-statements/outstanding-account-receivables/OutstandingAccountReceivables.vue'
import OutstandingAccountPayables from '@/views/financial-statements/outstanding-account-payables/OutstandingAccountPayables.vue'
import Alerts from '@/views/financial-statements/alerts/Alerts.vue'
import TimesheetAlerts from '@/views/financial-statements/timesheet-alerts/TimesheetAlerts.vue'
import InvoiceAlerts from '@/views/financial-statements/invoice-alerts/InvoiceAlerts.vue'
import InvoiceDueAlerts from '@/views/financial-statements/invoice-alerts/InvoiceDueAlerts.vue'
import JobAlerts from '@/views/financial-statements/job-alerts/JobAlerts.vue'
import DocumentExpiredAlerts from '@/views/financial-statements/document-alerts/DocumentExpiredAlerts.vue'
import DocumentExpireSoonAlerts from '@/views/financial-statements/document-alerts/DocumentExpireSoonAlerts.vue'
import DocumentNotExistAlerts from '@/views/financial-statements/document-alerts/DocumentNotExistAlerts.vue'
import Reports from '@/views/financial-statements/reports/Reports.vue'
import AccountReceivableReport from '@/views/financial-statements/reports/actions/AccountReceivableReport.vue'
import AccountPayableReport from '@/views/financial-statements/reports/actions/AccountPayableReport.vue'
import PayRollReport from '@/views/financial-statements/reports/actions/PayRollReport.vue'
import OperatingExpenses from '@/views/financial-statements/reports/actions/OperatingExpenses.vue'
import DocumentTrackerNew from '@/views/documents/document-tracker/actions/New.vue'

export default [
  // *===============================================---*
  // *--------- Financial Statements -------------------------------*
  // *===============================================---*
  // *------------ Dashobard --------------------*
  {
    path: '/financial-statements/dashboard',
    name: 'financial-statements-dashboard',
    component: Dashboard,
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
  // *------------ Profit Rate Per Resource --------------------*
  {
    path: '/financial-statements/profit-rate-per-resource',
    name: 'financial-statements-profit-rate-per-resource',
    component: ProfitRatePerResource,
    meta: {
      navActiveLink: 'financial-statements-profit-rate-per-resource',
    },
  },
  // *------------ Profit Amounts Per Resource --------------------*
  {
    path: '/financial-statements/profit-amounts-per-resource',
    name: 'financial-statements-profit-amounts-per-resource',
    component: ProfitAmountsPerResource,
    meta: {
      navActiveLink: 'financial-statements-profit-amounts-per-resource',
    },
  },
  // *------------ Statements --------------------*
  {
    path: '/financial-statements/statements',
    name: 'financial-statements-statements',
    component: Statements,
    meta: {
      navActiveLink: 'financial-statements-statements',
    },
  },
  {
    path: '/financial-statements/statements/new',
    name: 'financial-statements-statements-new',
    component: StatementsNew,
    meta: {
      navActiveLink: 'financial-statements-statements',
    },
  },
  // *------------ Account Receivables --------------------*
  {
    path: '/financial-statements/account-receivables',
    name: 'financial-statements-account-receivables',
    component: AccountReceivables,
    meta: {
      navActiveLink: 'financial-statements-dashboard',
    },
  },
  // *------------ Account Payables --------------------*
  {
    path: '/financial-statements/account-payables',
    name: 'financial-statements-account-payables',
    component: AccountPayables,
    meta: {
      navActiveLink: 'financial-statements-dashboard',
    },
  },
  // *------------ Outstanding Account Receivables --------------------*
  {
    path: '/financial-statements/outstanding-account-receivables',
    name: 'financial-statements-outstanding-account-receivables',
    component: OutstandingAccountReceivables,
    meta: {
      navActiveLink: 'financial-statements-dashboard',
    },
  },
  // *------------ Outstanding Account Payables --------------------*
  {
    path: '/financial-statements/outstanding-account-payables',
    name: 'financial-statements-outstanding-account-payables',
    component: OutstandingAccountPayables,
    meta: {
      navActiveLink: 'financial-statements-dashboard',
    },
  },
  // *------------ Alerts --------------------*
  {
    path: '/financial-statements/alerts',
    name: 'financial-statements-alerts',
    component: Alerts,
    meta: {
      navActiveLink: 'financial-statements-alerts',
    },
  },
  // *------------ Timesheet Alerts --------------------*
  {
    path: '/financial-statements/timesheet-alerts',
    name: 'financial-statements-timesheet-alerts',
    component: TimesheetAlerts,
    meta: {
      navActiveLink: 'financial-statements-alerts',
    },
  },
  // *------------ Invoice Alerts --------------------*
  {
    path: '/financial-statements/invoice-alerts',
    name: 'financial-statements-invoice-alerts',
    component: InvoiceAlerts,
    meta: {
      navActiveLink: 'financial-statements-alerts',
    },
  },
  {
    path: '/financial-statements/invoice-due-alerts',
    name: 'financial-statements-invoice-due-alerts',
    component: InvoiceDueAlerts,
    meta: {
      navActiveLink: 'financial-statements-alerts',
    },
  },
  // *------------ Job Alerts --------------------*
  {
    path: '/financial-statements/job-alerts',
    name: 'financial-statements-job-alerts',
    component: JobAlerts,
    meta: {
      navActiveLink: 'financial-statements-alerts',
    },
  },
  // *------------ Document Alerts --------------------*
  {
    path: '/financial-statements/document-expired-alerts',
    name: 'financial-statements-document-expired-alerts',
    component: DocumentExpiredAlerts,
    meta: {
      navActiveLink: 'financial-statements-alerts',
    },
  },
  {
    path: '/financial-statements/document-expire-soon-alerts',
    name: 'financial-statements-document-expire-soon-alerts',
    component: DocumentExpireSoonAlerts,
    meta: {
      navActiveLink: 'financial-statements-alerts',
    },
  },
  // *------------ Document Not Exist Alerts --------------------*
  {
    path: '/financial-statements/document-not-exist-alerts',
    name: 'financial-statements-document-not-exist-alerts',
    component: DocumentNotExistAlerts,
    meta: {
      navActiveLink: 'financial-statements-alerts',
    },
  },
  {
    path: '/financial-statements/document-not-exist-alerts/document-tracker/new',
    name: 'financial-statements-document-tracker-new',
    component: DocumentTrackerNew,
    meta: {
      navActiveLink: 'document-tracker',
    },
  },
  // *------------ Reports --------------------*
  {
    path: '/financial-statements/reports',
    name: 'financial-statements-reports',
    component: Reports,
    meta: {
      pageTitle: 'Reports',
    },
  },
  // *------------ Account Receivable Report --------------------*
  {
    path: '/financial-statements/reports/account-receivable-report',
    name: 'financial-statements-reports-account-receivable-report',
    component: AccountReceivableReport,
    meta: {
      navActiveLink: 'financial-statements-reports',
    },
  },
  // *------------ Account Payable Report --------------------*
  {
    path: '/financial-statements/reports/account-payable-report',
    name: 'financial-statements-reports-account-payable-report',
    component: AccountPayableReport,
    meta: {
      navActiveLink: 'financial-statements-reports',
    },
  },
  // *------------ PayRoll Report --------------------*
  {
    path: '/financial-statements/reports/payroll-report',
    name: 'financial-statements-reports-account-payroll-report',
    component: PayRollReport,
    meta: {
      navActiveLink: 'financial-statements-reports',
    },
  },
  // *------------ Operating Expenses --------------------*
  {
    path: '/financial-statements/reports/account-operating-expenses',
    name: 'financial-statements-reports-account-operating-expenses',
    component: OperatingExpenses,
    meta: {
      navActiveLink: 'financial-statements-reports',
    },
  },
]
