import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.url
const profile = `${HOST}profile`

export default {
  logout(token) {
    axios.delete(`${profile}/logout`, { headers: { Authorization: `Bearer ${token}` } }).then(() => {
    }, () => {
    })
  },
}
