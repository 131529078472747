<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          PO</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="primary"
            type="button"
            class="mr-2"
            @click="start"
            v-show="showStart"
          >
            Start
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <b-form >
      <b-row class="mt-1">
        <b-col
          cols="12"
          md="10 ml-auto mr-auto"
        >
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <div
                  class="msa-form"
                >
                  <div class="mb-4">
                    <b-img
                      fluid
                      :src="signatureData.logo_path"
                      alt="Company Logo"
                    />
                  </div>
                  <h4 class="text-center mb-2">EXHIBIT A ATTACHED TO CONTRACTOR AGREEMENT</h4>
                  <h2 class="text-underline text-center mb-1">PURCHASE ORDER</h2>
                  <span v-html="signatureData.content"></span>
                  <b-row>
                    <b-col cols="6">
                      <h5>{{ signatureData.company_name }}</h5>
                      <b-button v-show="!signatureDone.includes('btn1')" ref="sign1" :class="this.currnetBtn === 'btn1' ? 'btn-relif-warning' : 'btn-outline-warning'" @click="showSignturePopup" variant="flat-primary">
                        Signature
                      </b-button>
                      <div class="mt-2 mb-50">
                        <span @click="showSignturePopup" v-show="signatureDone.includes('btn1')" class="signature-text" :style="'font-family:' + signatureRequest.user_signature_font ">{{ signatureRequest.user_signature }}</span>
                        <span class="d-block signature-line">_________________________________</span>
                        <span class="small d-block">(Signature)</span>
                      </div>
                      <h5>Print Name: {{ signatureData.company_user_name }}</h5>
                      <h5>Title: {{ signatureData.company_user_title }}</h5>
                      <h5>Email: {{ signatureData.company_user_email }}</h5>
                      <h5>FED ID: {{ signatureData.company_federal_tax_id }}</h5>
                      <h5>Date: {{ signed_date }}</h5>
                    </b-col>
                    <b-col cols="6">
                      <h5>Contractor: {{ signatureData.data.employer_company_name }}</h5>
                      <div class="mt-2 mb-50">
                        <span class="d-block signature-text" :style="'font-family:' + signatureData.signature_font ">{{ signatureData.signature }}</span>
                        <span class="d-block signature-line">_________________________________</span>
                        <span class="small d-block">(Signature)</span>
                      </div>
                      <h5>Print Name: {{ signatureData.data.name }}</h5>
                      <div style="display: flex;">
                        <h5 style="line-height: 24px; margin-bottom: 0;">Title: {{ signatureData.data.title }}</h5>
                      </div>
                      <h5>Email: {{ signatureData.data.email }}</h5>
                      <h5>FED ID: {{ signatureData.data.federal_tax_id }}</h5>
                      <h5>Date: {{ signatureData.signed_date }}</h5>
                    </b-col>
                  </b-row>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-modal
        ref="signature"
        cancel-variant="outline-secondary"
        ok-title="Save"
        cancel-title="Close"
        size="lg"
        centered
        title="Add Signature"
      >
        <validation-observer
            ref="font"
            tag="form"
          >
          <b-form>
            <b-row>
              <b-col cols="6">
                <b-form-group>
                  <label>Name</label>
                  <validation-provider
                      v-slot="{ errors }"
                      name="Name"
                      rules="required"
                    >
                    <b-form-input
                      v-model="signatureRequest.user_signature"
                      placeholder="Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group>
                  <label>Font Type</label>
                  <validation-provider
                      v-slot="{ errors }"
                      name="Font Type"
                      rules="required"
                    >
                    <v-select
                      v-model="signatureRequest.user_signature_font"
                      :options="fontTypes"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-card-text class="mt-1 mb-0">
                  <span class="font-large-3" :style="'font-family:' + signatureRequest.user_signature_font ">{{ signatureRequest.user_signature }}</span>
                </b-card-text>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <template #modal-footer>
          <div class="d-flex justify-content-between w-100">
            <b-button
              variant="outline-primary"
              class="px-1"
              @click="closeFontPopup()"
            >
              Cancel
            </b-button>
            <b-button
              variant="primary"
              class="ml-1 px-1"
              @click="save()"
            >
              Save
            </b-button>
          </div>
        </template>
      </b-modal>
      <hr>
      <b-row class="ml-2">
        <b-col class="text-right">
          <b-button
            v-show="btns.indexOf(this.currnetBtn) !== btns.length - 1 && btns.length !== signatureDone.length"
            variant="primary"
            type="button"
            class="mr-2"
            @click="next"
          >
            Next
          </b-button>
          <b-button
            variant="primary"
            type="button"
            v-show="signatureDone.length === btns.length"
            @click="submitSignature"
          >
            Finish
          </b-button>
        </b-col>
      </b-row>
      <div v-show="showPopup" class="bottom-scroll-area">
        <div class="bottom-scroll">
          <div class="bottom-scroll-dailog">
            <div class="bottom-content">
              <b-card>
                <b-card-body>
                  <p>Esign consignment</p>
                  <b-form-group>
                    <b-form-checkbox
                      id="yes"
                      v-model="accept_consignment"
                      name="yes"
                    >
                      Accept consignment
                    </b-form-checkbox>
                  </b-form-group>
                  <b-row class="ml-2">
                    <b-col class="text-right">
                      <b-button
                        variant="secondary"
                        type="button"
                        class="mr-2"
                        @click="cancelConsignment"
                      >
                        Cancel
                      </b-button>
                      <b-button
                        variant="primary"
                        class="mr-2"
                        @click="acceptConsignment"
                      >
                        OK
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BModal, VBModal, BFormGroup, BFormInput, BCardText, BButton, BImg, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import Esignatures from '@/js/esignatures'
import moment from 'moment'
import Constants from '@/js/constant'

export default {
  name: 'PurchaseOrder',
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BForm,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BFormGroup,
    BFormInput,
    BImg,
    BButton,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      showPopup: true,
      showStart: true,
      fontSelected: false,
      currnetBtn: null,
      signatureDone: [],
      signed_date: null,
      btns: ['btn1'],
      signatureRequest: {
        ip: null,
      },
      signatureData: {
        data: {},
      },
      consignmentRequest: {
        ip: null,
        date: null,
        time: null,
      },
      fontTypes: [{ label: 'Bogoritmaa', id: 'Bogoritmaa' }, { label: 'Tyloos', id: 'Tyloos' }, { label: 'Shanghai', id: 'Shanghai' }],
      value: '',
    }
  },
  watch: {
  },
  mounted() {
  },
  created() {
    Constants.getIpAddress(this)
    this.signed_date = Constants.getEprmMonthDateFromString(moment().format('MM-DD-YYYY'))
    Esignatures.getESignatureById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    submitSignature() {
      if (this.signatureDone.length === this.btns.length) {
        this.signatureRequest.signed_date = Constants.getEprmMonthDateFromString(moment().format('MM-DD-YYYY'))
        this.signatureRequest.signed_time = moment().format('hh:mm a')
        Esignatures.submitEsign(this, this.$route.params.id, this.signatureRequest)
      }
    },
    showSignturePopup() {
      this.$refs.signature.show()
      this.currnetBtn = 'btn1'
    },
    showSignturePopup1() {
      this.currnetBtn = 'btn2'
      if (!this.fontSelected) {
        this.$refs.signature.show()
      } else if (!this.signatureDone.includes(this.currnetBtn)) {
        this.signatureDone = [...this.signatureDone, this.currnetBtn]
      }
    },
    setEsignSuccess(data) {
      this.$router.go(-1)
      this.$swal({
        icon: 'success',
        title: 'Sign Completed!',
        text: data.return_message,
      })
    },
    setEsignFailure(message) {
      this.$swal({
        icon: 'warning',
        title: 'Consign!',
        text: message,
      })
    },
    closeFontPopup() {
      this.font = null
      this.name = ''
      this.$refs.signature.hide()
    },
    save() {
      this.$refs.font.validate().then(result => {
        if (result) {
          this.signature_name = this.name
          this.signature_font = this.font
          if (this.currnetBtn === 'btn1') {
            if (!this.signatureDone.includes(this.currnetBtn)) {
              this.signatureDone = [...this.signatureDone, this.currnetBtn]
            }
          }
          if (this.currnetBtn === 'btn2') {
            if (!this.signatureDone.includes(this.currnetBtn)) {
              this.signatureDone = [...this.signatureDone, this.currnetBtn]
            }
          }
          this.fontSelected = true
          this.$refs.signature.hide()
        }
      })
    },
    cancelConsignment() {
      this.$swal({
        title: 'Warning!',
        text: 'Are you sure want to cancel PO',
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$router.go(-1)
        }
      })
    },
    acceptConsignment() {
      if (!this.accept_consignment) {
        this.$swal({
          title: 'Info!',
          text: 'Please accept consignment',
          icon: 'info',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      this.consignmentRequest.date = Constants.getEprmMonthDateFromString(moment().format('MM-DD-YYYY'))
      this.consignmentRequest.time = moment().format('hh:mm a')
      Esignatures.submitEsignConsignment(this, this.$route.params.id, this.consignmentRequest)
    },
    setConsignSuccess() {
      this.showPopup = false
      this.isDisabled = false
    },
    setConsignFailure(message) {
      this.$swal({
        icon: 'warning',
        title: 'Consign!',
        text: message,
      })
      this.$router.go(-1)
    },
    setIpAddress(ipAddress) {
      this.consignmentRequest.ip = ipAddress
      this.signatureRequest.ip = ipAddress
    },
    setESignature(data) {
      this.signatureData = data
      this.signed_date = moment().format('MM-DD-YYYY')
    },
    start() {
      this.currnetBtn = this.btns.indexOf(0)
      this.showStart = false
      this.$refs.sign1.focus()
    },
    next() {
      this.currnetBtn = this.btns[this.btns.indexOf(this.currnetBtn) + 1]
      this.$refs.sign2.focus()
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@font-face {
    font-family: 'Bogoritmaa';
    src: url('../../../../../fonts/Bogoritmaa.ttf') format('truetype'),
}
@font-face {
    font-family: 'Tyloos';
    src: url('../../../../../fonts/Tyloos.ttf') format('truetype'),
}
@font-face {
    font-family: 'Shanghai';
    src: url('../../../../../fonts/Shanghai.ttf') format('truetype'),
}
focused {
  filter: brightness(1.5);
}
</style>
