<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Verification Letter View</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>

    <app-timeline>
      <app-timeline-item class="pb-1">
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Title</label>
              <span
                class="form-control"
                v-text="verificationLetter.data.title"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>First Name</label>
              <span
                class="form-control"
                v-text="verificationLetter.data.first_name"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Last Name</label>
              <span
                class="form-control"
                v-text="verificationLetter.data.last_name"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Job Title</label>
              <span
                class="form-control h-auto"
                v-text="verificationLetter.data.job_title"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Address</label>
              <span
                class="form-control h-auto"
                v-text="verificationLetter.data.address"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Salary</label>
              <b-input-group
                prepend="$"
              >
                <span
                  class="form-control"
                  v-text="verificationLetter.data.salary"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
          variant="success"
          class="pb-1"
        >
          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6>Status History</h6>
          </div>
          <b-row class="mt-1">
            <b-col cols="12">
              <div class="table-responsive-sm">
                <table class="table b-table table-th-pd table-td-pd">
                  <thead>
                    <tr>
                      <th>Old Status</th>
                      <th>New Status</th>
                      <th>Date</th>
                      <th>Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(status, index) in verificationLetter.status_history"
                      :key="index"
                    >
                      <td>{{ status.old_status }}</td>
                      <td>{{ status.new_status }}</td>
                      <td>{{ status.date }}</td>
                      <td>{{ status.time }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
          </b-row>
        </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BInputGroup, BButton,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Esignatures from '@/js/esignatures'

export default {
  name: 'verificationLetterView',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BButton,
  },
  directives: {
  },
  setup() {
  },
  data() {
    return {
      verificationLetter: {},
    }
  },
  mounted() {
  },
  created() {
    Esignatures.getESignatureViewById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    setESignature(esignature) {
      this.verificationLetter = esignature
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
