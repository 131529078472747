<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          View Assign Jobs Criteria</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>

    <app-timeline>
      <app-timeline-item class="pb-1">
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Role</label>
              <span
                class="form-control"
                v-text="assignJob.role"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>User</label>
              <span
                class="form-control h-auto"
                v-text="assignJob.name"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="info"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Criteria</h6>
        </div>
        <b-row
          v-for="jobsCriteria in assignJob.job_criterias"
          :key="jobsCriteria"
        >
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Facility</label>
              <span
                class="form-control"
                v-text="jobsCriteria.facility"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Main Skill</label>
              <span
                class="form-control"
                v-text="jobsCriteria.skill"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>State</label>
              <span
                class="form-control"
                v-text="jobsCriteria.state"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>City</label>
              <span
                class="form-control"
                v-text="jobsCriteria.city"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div
          v-if="assignJob.job_criterias.length == 0"
          class="text-center"
        >
          <p class="mb-0">
            No criteria available
          </p>
        </div>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BButton,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import AssignJobCriteria from '@/js/assignjobcriteria'

export default {
  name: 'AssignJobsView',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BFormGroup,
    BButton,
  },
  directives: {
  },
  setup() {
  },
  data() {
    return {
      assignJob: {
        job_criterias: [],
      },
    }
  },
  mounted() {
  },
  created() {
    AssignJobCriteria.getAssignedUserJobCriteriaByUserId(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    setCriteria(assignJob) {
      this.assignJob = assignJob
    },
    back() {
      this.$router.push('/administration/assign-jobs').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
</style>
