import Constants from '@/js/constant'
import axios from 'axios'

const HOST = Constants.hosts.hrurl
const prospectMatcher = `${HOST}prospect-matcher`

export default {
  getSkills(context, query) {
    axios.get(`${prospectMatcher}/skills${query}`).then(response => {
      context.setSkillsTree(response.data)
    })
  },
  getStatesBySkill(context, skillId) {
    axios.get(`${prospectMatcher}/by-skills/${skillId}`).then(response => {
      context.setSkillsTree(response.data)
    })
  },
  getSkillsBystateId(context, stateId) {
    axios.get(`${prospectMatcher}/by-states/${stateId}`).then(response => {
      context.setSkillsTree(response.data)
    })
  },
  getJobsBySkill(context, query, node) {
    axios.get(`${prospectMatcher}/jobs${query}`).then(response => {
      context.setChildTree(response.data, node)
    })
  },
  getStatesByJobId(context, query, node) {
    axios.get(`${prospectMatcher}/states${query}`).then(response => {
      context.setChildTree(response.data, node)
    })
  },
  getCitiesByStateId(context, query, node) {
    axios.get(`${prospectMatcher}/cities${query}`).then(response => {
      context.setChildTree(response.data, node)
    })
  },
  getProspectsCities(context, query, node) {
    axios.get(`${prospectMatcher}/by-city${query}`).then(response => {
      context.setChildTree(response.data, node)
    })
  },
  sendMailsToProspects(context, jobId, body) {
    context.$vs.loading()
    axios.post(`${prospectMatcher}/${jobId}/send-email`, body).then(response => {
      context.$vs.loading.close()
      context.mailSent(response.data)
    }, error => {
      context.$vs.loading.close()
      context.jobmailNotSent(error.response.data)
    })
  },
  sendRtrMailsToProspects(context, jobId, body) {
    context.$vs.loading()
    axios.post(`${prospectMatcher}/${jobId}/send-rtr`, body).then(response => {
      context.$vs.loading.close()
      context.rtrMailSent(response.data)
    }, error => {
      context.$vs.loading.close()
      context.mailNotSent(error.response.data.return_message)
    })
  },
  getProspects(context, query, page, searchQuery) {
    context.$vs.loading()
    axios.get(`${prospectMatcher}/prospects${query}&page=${page}&query=${searchQuery}`).then(response => {
      context.$vs.loading.close()
      context.setProspects(response.data)
    }, () => {
      context.$vs.loading()
    })
  },
  getProspectsWithoutLoading(context, query, page, searchQuery) {
    axios.get(`${prospectMatcher}/prospects${query}&page=${page}&query=${searchQuery}`).then(response => {
      context.setProspects(response.data)
    }, () => {
    })
  },
  getJobProspects(context, query, page, searchQuery) {
    context.$vs.loading()
    axios.get(`${prospectMatcher}/job-prospects${query}&page=${page}&query=${searchQuery}`).then(response => {
      context.$vs.loading.close()
      context.setProspects(response.data)
    }, () => {
      context.$vs.loading()
    })
  },
}
