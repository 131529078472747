import Constants from '@/js/constant'
import axios from 'axios'

const LIST = 'list'
const HOST = Constants.hosts.url
const HRHOST = Constants.hosts.hrurl
const FAHOST = Constants.hosts.faurl
const ADHOST = Constants.hosts.adurl
const EMPLOYEE_LIST = `${HRHOST}employee`
const INTERVIEW = `${HRHOST}interview`
const INVOICE_LIST = `${FAHOST}invoice`
const TECHNOLOGIES_LIST = `${ADHOST}technologies`
const TIMESHEET_EMPLOYER_INVOICE_LIST = `${FAHOST}timesheets/invoice/employer/fulllist`
const TIMESHEET_SUPPLIERER_INVOICE_LIST = `${FAHOST}timesheets/invoice/supplier/fulllist`
const TIMESHEET_REFERRER_INVOICE_LIST = `${FAHOST}timesheets/invoice/referrer/fulllist`
const TIMESHEET_SUBVENDOR_INVOICE_LIST = `${FAHOST}timesheets/invoice/subfacility/fulllist`
const TIMESHEET_VENDOR_INVOICE_AMOUNT = `${FAHOST}timesheets/invoice/facility/amount`
const TIMESHEET_INVOICE_AMOUNT = `${FAHOST}timesheets/invoice/amount`
const DASHBOARD_COUNT = `${HOST}dashboard`

export default {
  async getAllListsByToken(context, token) {
    axios.get(`${LIST}/hr-lists`, { headers: { Authorization: `Bearer ${token}` } }).then(response => {
      const mergedObj = Object.assign(response.data.response, context.$store.state.lists.hrLists.response)
      const data = {
        response: mergedObj,
      }
      context.$store.commit('HR_LISTS', data)

      axios.get(`${LIST}/cities`, { headers: { Authorization: `Bearer ${token}` } }).then(response1 => {
        context.$store.commit('CITIES', response1.data.cities)
      }, () => {
      })
    }, () => {
    })
    axios.get(`${LIST}/hr-partners-lists`, { headers: { Authorization: `Bearer ${token}` } }).then(response => {
      const mergedObj = Object.assign(response.data.response, context.$store.state.lists.hrLists.response)
      const data = {
        response: mergedObj,
      }
      context.$store.commit('HR_LISTS', data)

      axios.get(`${LIST}/lists`, { headers: { Authorization: `Bearer ${token}` } }).then(response1 => {
        context.$store.commit('MODULE_LISTS', response1.data)
      }, () => {
      })
    }, () => {
    })
    axios.get(`${LIST}/all`, { headers: { Authorization: `Bearer ${token}` } }).then(response => {
      let COMMON_LISTS = response.data
      COMMON_LISTS.all_roles = context.$store.state.lists.commonLists.all_roles
      COMMON_LISTS['interview-employees'] = context.$store.state.lists.commonLists['interview-employees']
      let mergedlists = Object.assign(response.data.lists, context.$store.state.lists.commonLists.lists)
      COMMON_LISTS.lists = mergedlists
      context.$store.commit('COMMON_LISTS', COMMON_LISTS)

      axios.get(`${LIST}/all-parents`, { headers: { Authorization: `Bearer ${token}` } }).then(response1 => {
        COMMON_LISTS = context.$store.state.lists.commonLists
        COMMON_LISTS.all_roles = response1.data.all_roles
        COMMON_LISTS['interview-employees'] = response1.data['interview-employees']
        mergedlists = Object.assign(response1.data.lists, context.$store.state.lists.commonLists.lists)
        COMMON_LISTS.lists = mergedlists
        context.$store.commit('COMMON_LISTS', COMMON_LISTS)
      }, () => {
      })
    }, () => {
    })
    axios.get(`${LIST}/ad-lists`, { headers: { Authorization: `Bearer ${token}` } }).then(response => {
      context.$store.commit('AD_LISTS', response.data)
    }, () => {
    })
    axios.get(`${LIST}/fa-lists`, { headers: { Authorization: `Bearer ${token}` } }).then(response => {
      context.$store.commit('FA_LISTS', response.data)
    }, () => {
    })
  },
  getAllList(context) {
    const context1 = context
    context1.$store.state.lists.commonLists = {}
    axios.get(`${LIST}/hr-lists`).then(response => {
      let mergedObj = Object.assign(response.data.response, context.$store.state.lists.hrLists.response)
      let data = {
        response: mergedObj,
      }
      context.$store.commit('HR_LISTS', data)

      axios.get(`${LIST}/hr-partners-lists`).then(response1 => {
        mergedObj = Object.assign(response1.data.response, context.$store.state.lists.hrLists.response)
        data = {
          response1: mergedObj,
        }
        context.$store.commit('HR_LISTS', data)
      }, () => {
      })
    }, () => {
    })
    axios.get(`${LIST}/all`).then(response => {
      let COMMON_LISTS = response.data
      COMMON_LISTS.all_roles = context.$store.state.lists.commonLists.all_roles
      COMMON_LISTS['interview-employees'] = context.$store.state.lists.commonLists['interview-employees']
      let mergedlists = Object.assign(response.data.lists, context.$store.state.lists.commonLists.lists)
      COMMON_LISTS.lists = mergedlists
      context.$store.commit('COMMON_LISTS', COMMON_LISTS)

      axios.get(`${LIST}/all-parents`).then(response1 => {
        COMMON_LISTS = context.$store.state.lists.commonLists
        COMMON_LISTS.all_roles = response1.data.all_roles
        COMMON_LISTS['interview-employees'] = response1.data['interview-employees']
        mergedlists = Object.assign(response1.data.lists, context.$store.state.lists.commonLists.lists)
        COMMON_LISTS.lists = mergedlists
        context.$store.commit('COMMON_LISTS', COMMON_LISTS)
      }, () => {
      })
    }, () => {
    })
  },
  getCommonList(context) {
    axios.get(`${LIST}/all`).then(response => {
      const COMMON_LISTS = response.data
      COMMON_LISTS.all_roles = context.$store.state.lists.commonLists.all_roles
      COMMON_LISTS['interview-employees'] = context.$store.state.lists.commonLists['interview-employees']
      const mergedlists = Object.assign(response.data.lists, context.$store.state.lists.commonLists.lists)
      COMMON_LISTS.lists = mergedlists
      context.$store.commit('COMMON_LISTS', COMMON_LISTS)
    }, () => {
    })
    axios.get(`${LIST}/all-parents`).then(response1 => {
      const COMMON_LISTS = context.$store.state.lists.commonLists
      COMMON_LISTS.all_roles = response1.data.all_roles
      COMMON_LISTS['interview-employees'] = response1.data['interview-employees']
      const mergedlists = Object.assign(response1.data.lists, context.$store.state.lists.commonLists.lists)
      COMMON_LISTS.lists = mergedlists
      context.$store.commit('COMMON_LISTS', COMMON_LISTS)
    }, () => {
    })
  },
  async getAll(context) {
    axios.get(`${LIST}/hr-lists`).then(response => {
      const mergedObj = Object.assign(response.data.response, context.$store.state.lists.hrLists.response)
      const data = {
        response: mergedObj,
      }
      context.$store.commit('HR_LISTS', data)

      axios.get(`${LIST}/lists`).then(response1 => {
        context.$store.commit('MODULE_LISTS', response1.data)
      }, () => {
      })
    }, () => {
    })
    axios.get(`${LIST}/hr-partners-lists`).then(response => {
      const mergedObj = Object.assign(response.data.response, context.$store.state.lists.hrLists.response)
      const data = {
        response: mergedObj,
      }
      context.$store.commit('HR_LISTS', data)

      axios.get(`${LIST}/cities`).then(response1 => {
        context.$store.commit('CITIES', response1.data.cities)
      }, () => {
      })
    }, () => {
    })
    axios.get(`${LIST}/all`).then(response => {
      let COMMON_LISTS = response.data
      COMMON_LISTS.all_roles = context.$store.state.lists.commonLists.all_roles
      COMMON_LISTS['interview-employees'] = context.$store.state.lists.commonLists['interview-employees']
      let mergedlists = Object.assign(response.data.lists, context.$store.state.lists.commonLists.lists)
      COMMON_LISTS.lists = mergedlists
      context.$store.commit('COMMON_LISTS', COMMON_LISTS)

      axios.get(`${LIST}/all-parents`).then(response1 => {
        COMMON_LISTS = context.$store.state.lists.commonLists
        COMMON_LISTS.all_roles = response1.data.all_roles
        COMMON_LISTS['interview-employees'] = response1.data['interview-employees']
        mergedlists = Object.assign(response1.data.lists, context.$store.state.lists.commonLists.lists)
        COMMON_LISTS.lists = mergedlists
        context.$store.commit('COMMON_LISTS', COMMON_LISTS)
      }, () => {
      })
    }, () => {
    })
    axios.get(`${LIST}/ad-lists`).then(response => {
      context.$store.commit('AD_LISTS', response.data)
    }, () => {
    })
    axios.get(`${LIST}/fa-lists`).then(response => {
      context.$store.commit('FA_LISTS', response.data)
    }, () => {
    })
  },
  getFABusinessPartners(context) {
    if (context.$store.state.lists.commonLists.fa_business_partners === undefined) {
      context.setPartners([])
      return
    }
    context.setPartners(context.$store.state.lists.commonLists.fa_business_partners)
  },
  getHRWorkforcePartners(context) {
    if (context.$store.state.lists.commonLists.hr_work_force === undefined) {
      context.setEmployeeTypes([])
      return
    }
    context.setEmployeeTypes(context.$store.state.lists.commonLists.hr_work_force)
  },
  getHRExceptionParentTypes(context) {
    if (context.$store.state.lists.commonLists.hr_exception_parent_types === undefined) {
      context.setPartners([])
      return
    }
    context.setPartners(context.$store.state.lists.commonLists.hr_exception_parent_types)
  },
  getESignatureTemplateTypes(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setTemplateTypes([])
      return
    }
    context.setTemplateTypes(context.$store.state.lists.commonLists.lists.template_types)
  },
  getESignatureStatus(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setSignatureStatus([])
      return
    }
    context.setSignatureStatus(context.$store.state.lists.commonLists.lists.signature_status)
  },
  getJobBoardTypes(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setJobBoardTypes([])
      return
    }
    context.setJobBoardTypes(context.$store.state.lists.commonLists.lists.job_board_types)
  },
  getQuickProspectStatus(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setQuickProspectStatus([])
      return
    }
    context.setQuickProspectStatus(context.$store.state.lists.commonLists.lists.quick_prospect_status)
  },
  getHRESignParentTypes(context) {
    if (context.$store.state.lists.commonLists.hr_esign_parent_types === undefined) {
      context.setPartners([])
      return
    }
    context.setPartners(context.$store.state.lists.commonLists.hr_esign_parent_types)
  },
  getHRDocumentParentTypes(context) {
    if (context.$store.state.lists.commonLists.hr_document_parent_types === undefined) {
      context.setPartners([])
      return
    }
    context.setPartners(context.$store.state.lists.commonLists.hr_document_parent_types)
  },
  getHRBusinessPartners(context) {
    if (context.$store.state.lists.commonLists.hr_business_partners === undefined) {
      context.setPartners([])
      return
    }
    context.setPartners(context.$store.state.lists.commonLists.hr_business_partners)
  },
  getHRBusinessPartnerParentTypes(context) {
    if (context.$store.state.lists.commonLists.hr_business_partner_parent_types === undefined) {
      context.setPartners([])
      return
    }
    context.setPartners(context.$store.state.lists.commonLists.hr_business_partner_parent_types)
  },
  getMassCommunicationParentTypes(context) {
    if (context.$store.state.lists.commonLists.mass_communication_parent_types === undefined) {
      context.setParentTypes([])
      return
    }
    context.setParentTypes(context.$store.state.lists.commonLists.mass_communication_parent_types)
  },
  getUserDefinedFieldsDocuments(context) {
    if (context.$store.state.lists.adLists.response === undefined) {
      context.setUserDefinedFields(JSON.parse(JSON.stringify([])))
      return
    }
    context.setUserDefinedFields(JSON.parse(JSON.stringify(context.$store.state.lists.adLists.response.document['user-defined-fields'])))
  },
  getUserDefinedFieldsInterview(context) {
    if (context.$store.state.lists.adLists.response === undefined) {
      context.setUserDefinedFields(JSON.parse(JSON.stringify([])))
      return
    }
    context.setUserDefinedFields(JSON.parse(JSON.stringify(context.$store.state.lists.adLists.response.interviewer['user-defined-fields'])))
  },
  getUserDefinedFieldsInvoiceInvitations(context) {
    if (context.$store.state.lists.adLists.response === undefined) {
      context.setUserDefinedFields(JSON.parse(JSON.stringify([])))
      return
    }
    context.setUserDefinedFields(JSON.parse(JSON.stringify(context.$store.state.lists.adLists.response['invoice-invitation']['user-defined-fields'])))
  },
  getUserDefinedFieldsJobInvitations(context) {
    if (context.$store.state.lists.adLists.response === undefined) {
      context.setUserDefinedFields(JSON.parse(JSON.stringify([])))
      return
    }
    context.setUserDefinedFields(JSON.parse(JSON.stringify(context.$store.state.lists.adLists.response['job-invitation']['user-defined-fields'])))
  },
  getUserDefinedFieldsSkillsTechnologies(context) {
    if (context.$store.state.lists.adLists.response === undefined) {
      context.setUserDefinedFields(JSON.parse(JSON.stringify([])))
      return
    }
    context.setUserDefinedFields(JSON.parse(JSON.stringify(context.$store.state.lists.adLists.response['skill-technology']['user-defined-fields'])))
  },
  getUserDefinedFieldsTimesheetInvitations(context) {
    if (context.$store.state.lists.adLists.response === undefined) {
      context.setUserDefinedFields(JSON.parse(JSON.stringify([])))
      return
    }
    context.setUserDefinedFields(JSON.parse(JSON.stringify(context.$store.state.lists.adLists.response['timesheet-invitation']['user-defined-fields'])))
  },
  getUserDefinedFieldsProfileInvitations(context) {
    if (context.$store.state.lists.adLists.response === undefined) {
      context.setUserDefinedFields(JSON.parse(JSON.stringify([])))
      return
    }
    context.setUserDefinedFields(JSON.parse(JSON.stringify(context.$store.state.lists.adLists.response['profile-invitation']['user-defined-fields'])))
  },
  getUserDefinedFieldsExpenses(context) {
    if (context.$store.state.lists.faLists.response === undefined) {
      context.setUserDefinedFields(JSON.parse(JSON.stringify([])))
      return
    }
    context.setUserDefinedFields(JSON.parse(JSON.stringify(context.$store.state.lists.faLists.response.expenses['user-defined-fields'])))
  },
  getUserDefinedFieldsInvoiceFacility(context) {
    if (context.$store.state.lists.faLists.response === undefined) {
      context.setUserDefinedFields(JSON.parse(JSON.stringify([])))
      return
    }
    context.setUserDefinedFields(JSON.parse(JSON.stringify(context.$store.state.lists.faLists.response['invoice-vendor']['user-defined-fields'])))
  },
  getUserDefinedFieldsInvoiceEmployer(context) {
    if (context.$store.state.lists.faLists.response === undefined) {
      context.setUserDefinedFields(JSON.parse(JSON.stringify([])))
      return
    }
    context.setUserDefinedFields(JSON.parse(JSON.stringify(context.$store.state.lists.faLists.response['invoice-employer']['user-defined-fields'])))
  },
  getUserDefinedFieldsInvoiceReferrer(context) {
    if (context.$store.state.lists.faLists.response === undefined) {
      context.setUserDefinedFields(JSON.parse(JSON.stringify([])))
      return
    }
    context.setUserDefinedFields(JSON.parse(JSON.stringify(context.$store.state.lists.faLists.response['invoice-referrer']['user-defined-fields'])))
  },
  getUserDefinedFieldsInvoiceSubFacilities(context) {
    if (context.$store.state.lists.faLists.response === undefined) {
      context.setUserDefinedFields(JSON.parse(JSON.stringify([])))
      return
    }
    context.setUserDefinedFields(JSON.parse(JSON.stringify(context.$store.state.lists.faLists.response['invoice-subfacility']['user-defined-fields'])))
  },
  getUserDefinedFieldsInvoiceSupplier(context) {
    if (context.$store.state.lists.faLists.response === undefined) {
      context.setUserDefinedFields(JSON.parse(JSON.stringify([])))
      return
    }
    context.setUserDefinedFields(JSON.parse(JSON.stringify(context.$store.state.lists.faLists.response['invoice-supplier']['user-defined-fields'])))
  },
  getUserDefinedFieldsTimesheets(context) {
    if (context.$store.state.lists.faLists.response === undefined) {
      context.setUserDefinedFields(JSON.parse(JSON.stringify([])))
      return
    }
    context.setUserDefinedFields(JSON.parse(JSON.stringify(context.$store.state.lists.faLists.response.timesheets['user-defined-fields'])))
  },
  getUserDefinedFieldsCandidate(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setUserDefinedFieldTypes(JSON.parse(JSON.stringify([])))
      return
    }
    context.setUserDefinedFields(JSON.parse(JSON.stringify(context.$store.state.lists.hrLists.response.candidate['user-defined-fields'])))
  },
  getUserDefinedFieldsEmployee(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setUserDefinedFields(JSON.parse(JSON.stringify([])))
      return
    }
    context.setUserDefinedFields(JSON.parse(JSON.stringify(context.$store.state.lists.hrLists.response.employees['user-defined-fields'])))
  },
  getUserDefinedFieldsEmployer(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setUserDefinedFields(JSON.parse(JSON.stringify([])))
      return
    }
    context.setUserDefinedFields(JSON.parse(JSON.stringify(context.$store.state.lists.hrLists.response.employer['user-defined-fields'])))
  },
  getUserDefinedFieldsEndClient(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setUserDefinedFields(JSON.parse(JSON.stringify([])))
      return
    }
    context.setUserDefinedFields(JSON.parse(JSON.stringify(context.$store.state.lists.hrLists.response['end-client']['user-defined-fields'])))
  },
  getUserDefinedFieldsInterviews(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setUserDefinedFields(JSON.parse(JSON.stringify([])))
      return
    }
    context.setUserDefinedFields(JSON.parse(JSON.stringify(context.$store.state.lists.hrLists.response.interview['user-defined-fields'])))
  },
  getUserDefinedFieldsOnboarding(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setUserDefinedFields(JSON.parse(JSON.stringify([])))
      return
    }
    context.setUserDefinedFields(JSON.parse(JSON.stringify(context.$store.state.lists.hrLists.response.onboarding['user-defined-fields'])))
  },
  getUserDefinedFieldsReferrer(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setUserDefinedFields(JSON.parse(JSON.stringify([])))
      return
    }
    context.setUserDefinedFields(JSON.parse(JSON.stringify(context.$store.state.lists.hrLists.response.referrer['user-defined-fields'])))
  },
  getUserDefinedFieldsJobs(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setUserDefinedFields(JSON.parse(JSON.stringify([])))
      return
    }
    context.setUserDefinedFields(JSON.parse(JSON.stringify(context.$store.state.lists.hrLists.response.job['user-defined-fields'])))
  },
  getUserDefinedFieldsSubfacility(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setUserDefinedFields(JSON.parse(JSON.stringify([])))
      return
    }
    context.setUserDefinedFields(JSON.parse(JSON.stringify(context.$store.state.lists.hrLists.response['sub-facility']['user-defined-fields'])))
  },
  getUserDefinedFieldsSuppliers(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setUserDefinedFields(JSON.parse(JSON.stringify([])))
      return
    }
    context.setUserDefinedFields(JSON.parse(JSON.stringify(context.$store.state.lists.hrLists.response.supplier['user-defined-fields'])))
  },
  getUserDefinedFieldsVendors(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setUserDefinedFields(JSON.parse(JSON.stringify([])))
      return
    }
    context.setUserDefinedFields(JSON.parse(JSON.stringify(context.$store.state.lists.hrLists.response.vendor['user-defined-fields'])))
  },
  getUserDefinedFieldsFacilities(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setUserDefinedFields(JSON.parse(JSON.stringify([])))
      return
    }
    context.setUserDefinedFields(JSON.parse(JSON.stringify(context.$store.state.lists.hrLists.response.facility['user-defined-fields'])))
  },
  getUserDefinedFieldsExceptions(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setUserDefinedFields(JSON.parse(JSON.stringify([])))
      return
    }
    context.setUserDefinedFields(JSON.parse(JSON.stringify(context.$store.state.lists.hrLists.response.exception['user-defined-fields'])))
  },
  getUserDefinedFieldsRoleUsers(context) {
    if (context.$store.state.lists.moduleLists.response === undefined) {
      context.setUserDefinedFields(JSON.parse(JSON.stringify([])))
      return
    }
    context.setUserDefinedFields(JSON.parse(JSON.stringify(context.$store.state.lists.moduleLists.response['role-user']['user-defined-fields'])))
  },
  getUserDefinedFieldsTasks(context) {
    if (context.$store.state.lists.moduleLists.response === undefined) {
      context.setUserDefinedFields(JSON.parse(JSON.stringify([])))
      return
    }
    context.setUserDefinedFields(JSON.parse(JSON.stringify(context.$store.state.lists.moduleLists.response.task['user-defined-fields'])))
  },
  getEmployeesDocumentNameFullList(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setDocumentNames([])
      return
    }
    context.setDocumentNames(context.$store.state.lists.hrLists.response.employees.lists['document-names'])
  },
  getProspectsDocumentNameFullList(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setDocumentNames([])
      return
    }
    context.setDocumentNames(context.$store.state.lists.hrLists.response.prospects.lists['document-names'])
  },
  getConsultantsDocumentNameFullList(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setDocumentNames([])
      return
    }
    context.setDocumentNames(context.$store.state.lists.hrLists.response.consultants.lists['document-names'])
  },
  getEmployersDocumentNameFullList(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setDocumentNames([])
      return
    }
    context.setDocumentNames(context.$store.state.lists.hrLists.response.employer.lists['document-names'])
  },
  getEndClientsDocumentNameFullList(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setDocumentNames([])
      return
    }
    context.setDocumentNames(context.$store.state.lists.hrLists.response['end-client'].lists['document-names'])
  },
  getOnboardingDocumentNameFullList(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setDocumentNames([])
      return
    }
    context.setDocumentNames(context.$store.state.lists.hrLists.response.onboarding.lists['document-names'])
  },
  getReferrerDocumentNameFullList(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setDocumentNames([])
      return
    }
    context.setDocumentNames(context.$store.state.lists.hrLists.response.referrer.lists['document-names'])
  },
  getSubFacilityDocumentNameFullList(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setDocumentNames([])
      return
    }
    context.setDocumentNames(context.$store.state.lists.hrLists.response['sub-facility'].lists['document-names'])
  },
  getSupplierDocumentNameFullList(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setDocumentNames([])
      return
    }
    context.setDocumentNames(context.$store.state.lists.hrLists.response.supplier.lists['document-names'])
  },
  getFacilityDocumentNameFullList(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setDocumentNames([])
      return
    }
    context.setDocumentNames(context.$store.state.lists.hrLists.response.facility.lists['document-names'])
  },
  getInvoiceReferrerDocumentNameFullList(context) {
    if (context.$store.state.lists.faLists.response === undefined) {
      context.setDocumentNames([])
      return
    }
    context.setDocumentNames(context.$store.state.lists.faLists.response['invoice-referrer'].lists['document-names'])
  },
  getInvoiceSupplierDocumentNameFullList(context) {
    if (context.$store.state.lists.faLists.response === undefined) {
      context.setDocumentNames([])
      return
    }
    context.setDocumentNames(context.$store.state.lists.faLists.response['invoice-supplier'].lists['document-names'])
  },
  getInvoiceEmployerDocumentNameFullList(context) {
    if (context.$store.state.lists.faLists.response === undefined) {
      context.setDocumentNames([])
      return
    }
    context.setDocumentNames(context.$store.state.lists.faLists.response['invoice-employer'].lists['document-names'])
  },
  getInvoiceVendorDocumentNameFullList(context) {
    if (context.$store.state.lists.faLists.response === undefined) {
      context.setDocumentNames([])
      return
    }
    context.setDocumentNames(context.$store.state.lists.faLists.response['invoice-vendor'].lists['document-names'])
  },
  getInvoiceSubfacilityDocumentNameFullList(context) {
    if (context.$store.state.lists.faLists.response === undefined) {
      context.setDocumentNames([])
      return
    }
    context.setDocumentNames(context.$store.state.lists.faLists.response['invoice-subfacility'].lists['document-names'])
  },
  getTimesheetsDocumentNameFullList(context) {
    if (context.$store.state.lists.faLists.response === undefined) {
      context.setDocumentNames([])
      return
    }
    context.setDocumentNames(context.$store.state.lists.faLists.response.timesheets.lists['document-names'])
  },
  getH1bDocumentNameFullList(context) {
    if (context.$store.state.lists.moduleLists.response === undefined) {
      context.setDocumentNames([])
      return
    }
    context.setDocumentNames(context.$store.state.lists.moduleLists.response.h1b.lists['document-names'])
  },
  getInterviewDocumentNameFullList(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setDocumentNames([])
      return
    }
    context.setDocumentNames(context.$store.state.lists.hrLists.response.interview.lists['document-names'])
  },
  getDoucumentDocumentNameFullList(context) {
    if (context.$store.state.lists.adLists.response === undefined) {
      context.setDocumentNames([])
      return
    }
    context.setDocumentNames(context.$store.state.lists.adLists.response.document.lists['document-names'])
  },
  getExpensesDocumentNameFullList(context) {
    if (context.$store.state.lists.faLists.response === undefined) {
      context.setDocumentNames([])
      return
    }
    context.setDocumentNames(context.$store.state.lists.faLists.response.expenses.lists['document-names'])
  },
  getInerviewerStatusFullList(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setStatus([])
      return
    }
    context.setStatus(context.$store.state.lists.hrLists.response.employees.lists.status)
  },
  getInvoiceInvitationStatusFullList(context) {
    if (context.$store.state.lists.adLists.response === undefined) {
      context.setStatus([])
      return
    }
    context.setStatus(context.$store.state.lists.adLists.response['invoice-invitation'].lists.status)
  },
  getJobInvitationStatusFullList(context) {
    if (context.$store.state.lists.adLists.response === undefined) {
      context.setStatus([])
      return
    }
    context.setStatus(context.$store.state.lists.adLists.response['job-invitation'].lists.status)
  },
  getTimesheetInvitationStatusFullList(context) {
    if (context.$store.state.lists.adLists.response === undefined) {
      context.setStatus([])
      return
    }
    context.setStatus(context.$store.state.lists.adLists.response['timesheet-invitation'].lists.status)
  },
  getProfileInvitationStatusFullList(context) {
    if (context.$store.state.lists.adLists.response === undefined) {
      context.setStatus([])
      return
    }
    context.setStatus(context.$store.state.lists.adLists.response['profile-invitation'].lists.status)
  },
  getInvoiceEmployersStatusFullList(context) {
    if (context.$store.state.lists.faLists.response === undefined) {
      context.setStatus([])
      return
    }
    context.setStatus(context.$store.state.lists.faLists.response['invoice-employer'].lists.status)
  },
  getInvoiceReferrerStatusFullList(context) {
    if (context.$store.state.lists.faLists.response === undefined) {
      context.setStatus([])
      return
    }
    context.setStatus(context.$store.state.lists.faLists.response['invoice-referrer'].lists.status)
  },
  getInvoiceSubfacilityStatusFullList(context) {
    if (context.$store.state.lists.faLists.response === undefined) {
      context.setStatus([])
      return
    }
    context.setStatus(context.$store.state.lists.faLists.response['invoice-subfacility'].lists.status)
  },
  getInvoiceSupplierStatusFullList(context) {
    if (context.$store.state.lists.faLists.response === undefined) {
      context.setStatus([])
      return
    }
    context.setStatus(context.$store.state.lists.faLists.response['invoice-supplier'].lists.status)
  },
  getInvoiceVendorStatusFullList(context) {
    if (context.$store.state.lists.faLists.response === undefined) {
      context.setStatus([])
      return
    }
    context.setStatus(context.$store.state.lists.faLists.response['invoice-vendor'].lists.status)
  },
  getJobStatusFullList(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setStatus([])
      return
    }
    context.setStatus(context.$store.state.lists.hrLists.response.job.lists.status)
  },
  getTimesheetsStatusFullList(context) {
    if (context.$store.state.lists.faLists.response === undefined) {
      context.setStatus([])
      return
    }
    context.setStatus(context.$store.state.lists.faLists.response.timesheets.lists.status)
  },
  getOnboardingStatusFullList(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setStatus([])
      return
    }
    context.setStatus(context.$store.state.lists.hrLists.response.onboarding.lists.status)
  },
  getEmployeeStatusFullList(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setStatus([])
      return
    }
    if (context.$store.state.lists.hrLists.response.employees === undefined) {
      context.setStatus([])
      return
    }
    context.setStatus(context.$store.state.lists.hrLists.response.employees.lists.status)
  },
  getProspectStatusFullList(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setStatus([])
      return
    }
    context.setStatus(context.$store.state.lists.hrLists.response.prospects.lists.status)
  },
  getInterviewStatusFullList(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setStatus([])
      return
    }
    context.setStatus(context.$store.state.lists.hrLists.response.interview.lists.status)
  },
  getConsultantStatusFullList(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setStatus([])
      return
    }
    context.setStatus(context.$store.state.lists.hrLists.response.consultants.lists.status)
  },
  getEmployerStatusFullList(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setStatus([])
      return
    }
    context.setStatus(context.$store.state.lists.hrLists.response.employer.lists.status)
  },
  getEndClientStatusFullList(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setStatus([])
      return
    }
    context.setStatus(context.$store.state.lists.hrLists.response['end-client'].lists.status)
  },
  getReferrerStatusFullList(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setStatus([])
      return
    }
    context.setStatus(context.$store.state.lists.hrLists.response.referrer.lists.status)
  },
  getSubFacilityStatusFullList(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setStatus([])
      return
    }
    context.setStatus(context.$store.state.lists.hrLists.response['sub-facility'].lists.status)
  },
  getFacilityStatusFullList(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setStatus([])
      return
    }
    context.setStatus(context.$store.state.lists.hrLists.response.facility.lists.status)
  },
  getVendorStatusFullList(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setStatus([])
      return
    }
    context.setStatus(context.$store.state.lists.hrLists.response.vendor.lists.status)
  },
  getSupplierStatusFullList(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setStatus([])
      return
    }
    context.setStatus(context.$store.state.lists.hrLists.response.supplier.lists.status)
  },
  getTaskStatusFullList(context) {
    if (context.$store.state.lists.moduleLists.response === undefined) {
      context.setStatus([])
      return
    }
    context.setStatus(context.$store.state.lists.moduleLists.response.task.lists.status)
  },
  getRegisterpaymentTypes(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setStatus([])
      return
    }
    context.setRegisterpaymentTypes(context.$store.state.lists.commonLists.lists.register_payment_types)
  },
  getReferrerTypes(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setReferrerTypes([])
      return
    }
    context.setReferrerTypes(context.$store.state.lists.commonLists.lists['referrer-types'])
  },
  getQuickProspectSources(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setQuickProspectSources([])
      return
    }
    context.setQuickProspectSources(context.$store.state.lists.commonLists.lists['quick-prospect-sources'])
  },
  getEmployees(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setEmployees([])
      return
    }
    context.setEmployees(context.$store.state.lists.commonLists.lists.employees)
  },
  getEmployeePayrollStatus(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setEmployeePayrollStatus([])
      return
    }
    context.setEmployeePayrollStatus(context.$store.state.lists.commonLists.lists['payroll-status'])
  },
  getEmployeeSalaryTypes(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setEmployeeSalaryTypes([])
      return
    }
    context.setEmployeeSalaryTypes(context.$store.state.lists.commonLists.lists['salary-types'])
  },
  getQboTypes(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setQboTypes([])
      return
    }
    context.setQboTypes(context.$store.state.lists.commonLists.lists['qbo-types'])
  },
  getQboCategories(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setQboCategories([])
      return
    }
    context.setQboCategories(context.$store.state.lists.commonLists.lists['qbo-categories'])
  },
  getInvoiceInvitationEmployerTypesFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setTypes([])
      return
    }
    context.setTypes(context.$store.state.lists.commonLists.invitation_parent_types)
  },
  getLocations(context, stateId) {
    if (context.$store.state.lists.cities === undefined) {
      context.setLocations([])
      return
    }
    context.setLocations(context.$store.state.lists.cities[stateId])
  },
  getStatesFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setStates([])
      return
    }
    context.setStates(context.$store.state.lists.commonLists.lists.states)
  },
  getVisaFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setVisaTypes([])
      return
    }
    context.setVisaTypes(context.$store.state.lists.commonLists.lists['visa-types'])
  },
  getProcessReasons(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setProcessReasons([])
      return
    }
    context.setProcessReasons(context.$store.state.lists.commonLists.lists['process-reason'])
  },
  getRollUsers(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setRollUsers([])
      return
    }
    context.setRollUsers(context.$store.state.lists.commonLists.lists['roll-users'])
  },
  getJobRollUsers(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setRollUsers([])
      return
    }
    context.setRollUsers(context.$store.state.lists.commonLists.lists['job-users'])
  },
  getWorkingStatus(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setWorkingStatus([])
      return
    }
    context.setWorkingStatus(context.$store.state.lists.commonLists.lists['working-status'])
  },
  getStatementTypes(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setStatementTypes([])
      return
    }
    context.setStatementTypes(context.$store.state.lists.commonLists.lists['statement-types'])
  },
  getPayrollTypes(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setPayrollTypes([])
      return
    }
    context.setPayrollTypes(context.$store.state.lists.commonLists['payroll-types'])
  },
  getPtaskResults(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setPtaskResults([])
      return
    }
    context.setPtaskResults(context.$store.state.lists.commonLists.lists['ptask-results'])
  },
  getTechnologies(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setTechnologies([])
      return
    }
    context.setTechnologies(context.$store.state.lists.commonLists.lists.technologies)
  },
  getH1UserStatus(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setStatus([])
      return
    }
    context.setStatus(context.$store.state.lists.commonLists.lists['h1user-status'])
  },
  getPtaskStatus(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setPtaskStatus([])
      return
    }
    context.setPtaskStatus(context.$store.state.lists.commonLists.lists['ptask-status'])
  },
  getFrequencyFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setFrequencies([])
      return
    }
    context.setFrequencies(context.$store.state.lists.commonLists.frequencies)
  },
  getINterviewerDurationFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setDuration([])
      return
    }
    context.setDuration(context.$store.state.lists.commonLists.lists.durations)
  },
  getWeekDaysFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setWeekDays([])
      return
    }
    context.setWeekDays(context.$store.state.lists.commonLists.lists.days)
  },
  getUserDefinedFieldTypes(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setUserDefinedFieldTypes([])
      return
    }
    context.setUserDefinedFieldTypes(context.$store.state.lists.commonLists.lists['field-types'])
  },
  getParentTypes(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setParentTypes([])
      return
    }
    context.setParentTypes(context.$store.state.lists.commonLists.lists['parent-types'])
  },
  getRemindersList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setRemindersList([])
      return
    }
    context.setRemindersList(context.$store.state.lists.commonLists.lists.reminders)
  },
  getCriterias(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setCriterias([])
      return
    }
    context.setCriterias(context.$store.state.lists.commonLists.lists.criterias)
  },
  getReminderTypesList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setReminderTypesList([])
      return
    }
    context.setReminderTypesList(context.$store.state.lists.commonLists.lists['reminder-types'])
  },
  getInterviewersList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setInterviewersList([])
      return
    }
    context.setInterviewersList(context.$store.state.lists.commonLists.lists.interviewers)
  },
  getUserTypes(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setUserTypes([])
      return
    }
    context.setUserTypes(context.$store.state.lists.commonLists.lists['user-types'])
  },
  getPTaskFieldTypes(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setPTaskFieldTypes([])
      return
    }
    context.setPTaskFieldTypes(context.$store.state.lists.commonLists.lists['ptask-field-types'])
  },
  getHolidaysFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setHolidays([])
      return
    }
    context.setHolidays(context.$store.state.lists.commonLists.lists.holidays)
  },
  getCommunicationTypesFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setCommunicationTypes([])
      return
    }
    context.setCommunicationTypes(context.$store.state.lists.commonLists.lists['communication-types'])
  },
  getSubmittedProspectStatus(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setStatus([])
      return
    }
    context.setStatus(context.$store.state.lists.commonLists.lists.submitted_prospect_status)
  },
  getGenderFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setGenders([])
      return
    }
    context.setGenders(context.$store.state.lists.commonLists.lists.genders)
  },
  getSkillsFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setSkills([])
      return
    }
    context.setSkills(context.$store.state.lists.commonLists.lists.skills)
  },
  getSecondarySkillsByMainSkillId(context, id) {
    if (context.$store.state.lists.commonLists === undefined) {
      context.setSecondarySkills([])
      return
    }
    context.setSecondarySkills(context.$store.state.lists.commonLists.secondary_skills[id])
  },
  getRolesByUserTypeId(context, id) {
    if (context.$store.state.lists.commonLists === undefined) {
      context.setRoles([])
      return
    }
    context.setRoles(context.$store.state.lists.commonLists.rolesBy_user_type[id])
  },
  getCompensationFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setSalaryTypes([])
      return
    }
    context.setSalaryTypes(context.$store.state.lists.commonLists.lists['compensation-types'])
  },
  getEndClientsFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setEndClients([])
      return
    }
    context.setEndClients(context.$store.state.lists.commonLists.lists['end-clients'])
  },
  getEmployersFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setEmployers([])
      return
    }
    context.setEmployers(context.$store.state.lists.commonLists.lists.employers)
  },
  getSuppliersFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setSuppliers([])
      return
    }
    context.setSuppliers(context.$store.state.lists.commonLists.lists.suppliers)
  },
  getReferrersFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setReferrers([])
      return
    }
    context.setReferrers(context.$store.state.lists.commonLists.lists.referrers)
  },
  getFacilitiesFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setFacilities([])
      return
    }
    context.setFacilities(context.$store.state.lists.commonLists.lists['facilities-all'])
  },
  getSubFacilitiesFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setSubFacilities([])
      return
    }
    context.setSubFacilities(context.$store.state.lists.commonLists.lists['sub-facilities'])
  },
  getContactTypesFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setContactTypes([])
      return
    }
    context.setContactTypes(context.$store.state.lists.commonLists.lists['contact-types'])
  },
  getPaymentTerms(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setPaymentTerms([])
      return
    }
    context.setPaymentTerms(context.$store.state.lists.commonLists.lists.payment_terms)
  },
  getEmployeeConsultantFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setEmployeeConsultants([])
      return
    }
    context.setEmployeeConsultants(context.$store.state.lists.commonLists.lists['employee-consultants'])
  },
  getOnboardingItemsFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setOnboardingItems([])
      return
    }
    context.setOnboardingItems(context.$store.state.lists.commonLists.lists['onboarding-items'])
  },
  getInterviewEmployeesFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setInterviewEmployees([])
      return
    }
    context.setInterviewEmployees(context.$store.state.lists.commonLists['interview-employees'])
  },
  getPositionTypeFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setPositionTypes([])
      return
    }
    context.setPositionTypes(context.$store.state.lists.commonLists.lists['position-types'])
  },
  getDurationFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setDuration([])
      return
    }
    context.setDuration(context.$store.state.lists.commonLists.lists['position-durations'])
  },
  getTimeSheetFrequencyFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setTimeSheetFrequencies([])
      return
    }
    context.setTimeSheetFrequencies(context.$store.state.lists.commonLists.lists['timesheet-frequency'])
  },
  getEmployeeFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setEmployees([])
      return
    }
    context.setEmployees(context.$store.state.lists.commonLists.lists['employees-all'])
  },
  getJobInvitationEmployerTypesFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setTypes([])
      return
    }
    context.setTypes(context.$store.state.lists.commonLists.invitation_parent_types)
  },
  getPaymentTypes(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setPaymentTypes([])
      return
    }
    context.setPaymentTypes(context.$store.state.lists.commonLists.lists['payment-types'])
  },
  getSourceTypes(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setSourceTypes([])
      return
    }
    context.setSourceTypes(context.$store.state.lists.commonLists.lists.source_types)
  },
  getTechnologiesFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setTechnologies([])
      return
    }
    context.setTechnologies(context.$store.state.lists.commonLists.lists.technologies)
  },
  getExpensesTypes(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setExpensesTypes([])
      return
    }
    context.setExpensesTypes(context.$store.state.lists.commonLists.lists['expenses-types'])
  },
  getJobFacilities(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setFacilities([])
      return
    }
    context.setFacilities(context.$store.state.lists.commonLists.lists.job_facilities)
  },
  getVendors(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setVendors([])
      return
    }
    context.setVendors(context.$store.state.lists.commonLists.lists.vendors)
  },
  getFacilities(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setFacilities([])
      return
    }
    context.setFacilities(context.$store.state.lists.commonLists.lists.facilities)
  },
  getFacilityJobStatus(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setFacilityJobStatus([])
      return
    }
    context.setFacilityJobStatus(context.$store.state.lists.commonLists.lists['facility-job-status'])
  },
  getJobCities(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setCities([])
      return
    }
    context.setCities(context.$store.state.lists.commonLists.lists.job_cities)
  },
  getJobStates(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setStates([])
      return
    }
    context.setStates(context.$store.state.lists.commonLists.lists.job_states)
  },
  getInvitationEmployeeConsultantFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setEmployeeConsultants([])
      return
    }
    context.setEmployeeConsultants(context.$store.state.lists.commonLists['employee-consultant-invitations'])
  },
  getMainSkills(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setMainSkills([])
      return
    }
    context.setMainSkills(context.$store.state.lists.commonLists.lists.skills)
  },
  getJobMainSkills(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setMainSkills([])
      return
    }
    context.setMainSkills(context.$store.state.lists.commonLists.lists.job_skills)
  },
  getProfileInvitationUsersFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setEmployeeConsultants([])
      return
    }
    context.setEmployeeConsultants(context.$store.state.lists.commonLists['profile-invitation-users'])
  },
  getInvitationEmployersFullList(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setEmployers([])
      return
    }
    context.setEmployers(context.$store.state.lists.hrLists.response.employer.invitations)
  },
  getInvitationSubFacilitiesFullList(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setSubFacilities([])
      return
    }
    context.setSubFacilities(context.$store.state.lists.hrLists.response['sub-facility'].invitations)
  },
  getInvitationSuppliersFullList(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setSuppliers([])
      return
    }
    context.setSuppliers(context.$store.state.lists.hrLists.response.supplier.invitations)
  },
  getInvitationReferrersFullList(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setReferrers([])
      return
    }
    context.setReferrers(context.$store.state.lists.hrLists.response.referrer.invitations)
  },
  getVendorInvoiceFrequency(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setVendorInvoiceFrequency([])
      return
    }
    context.setVendorInvoiceFrequency(context.$store.state.lists.commonLists.lists.vendor_invoice_frequencies)
  },
  getCompanyFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setCompanies([])
      return
    }
    context.setCompanies(context.$store.state.lists.commonLists.lists.companies)
  },
  getCompanyStatusFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setStatus([])
      return
    }
    context.setStatus(context.$store.state.lists.commonLists.lists.company_status)
  },
  getMassCommunicationEmployees(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setEmployees([])
      return
    }
    context.setEmployees(context.$store.state.lists.hrLists.response.employees.mass_communications)
  },
  getMassCommunicationConsultants(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setEmployees([])
      return
    }
    context.setEmployees(context.$store.state.lists.hrLists.response.consultants.mass_communications)
  },
  getMassCommunicationProspects(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setEmployees([])
      return
    }
    context.setEmployees(context.$store.state.lists.hrLists.response.prospects.mass_communications)
  },
  getMassCommunicationEndClients(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setEmployees([])
      return
    }
    context.setEmployees(context.$store.state.lists.hrLists.response['end-client'].mass_communications)
  },
  getMassCommunicationEmployers(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setEmployees([])
      return
    }
    context.setEmployees(context.$store.state.lists.hrLists.response.employer.mass_communications)
  },
  getMassCommunicationVendors(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setEmployees([])
      return
    }
    context.setEmployees(context.$store.state.lists.hrLists.response.vendor.mass_communications)
  },
  getMassCommunicationSubVendors(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setEmployees([])
      return
    }
    context.setEmployees(context.$store.state.lists.hrLists.response['sub-vendor'].mass_communications)
  },
  getMassCommunicationSuppliers(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setEmployees([])
      return
    }
    context.setEmployees(context.$store.state.lists.hrLists.response.supplier.mass_communications)
  },
  getMassCommunicationReferrers(context) {
    if (context.$store.state.lists.hrLists.response === undefined) {
      context.setEmployees([])
      return
    }
    context.setEmployees(context.$store.state.lists.hrLists.response.referrer.mass_communications)
  },
  getRolesFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setRoles([])
      return
    }
    context.setRoles(JSON.parse(JSON.stringify(context.$store.state.lists.commonLists.lists.job_assign_roles)))
  },
  getReportingRolesFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setRoles({})
      return
    }
    context.setRoles(JSON.parse(JSON.stringify(context.$store.state.lists.commonLists.lists.reporting_roles)))
  },
  getAllRolesFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setRoles([])
      return
    }
    context.setRoles(JSON.parse(JSON.stringify(context.$store.state.lists.commonLists.lists.job_assign_roles)))
  },
  getReportingRoles(context) {
    axios.get(`${LIST}/reporting-roles`).then(response => {
      context.setReportingRoles(response.data)
    }, () => {
    })
  },
  getAssignJobRoles(context) {
    axios.get(`${LIST}/assign-rolls`).then(response => {
      context.setRoles(response.data)
    }, () => {
    })
  },
  getSkillsByUser(context, userId) {
    axios.get(`${LIST}/${userId}/skills`).then(response => {
      context.setSkills(response.data)
    }, () => {
    })
  },
  getFacilitiesByUser(context, userId) {
    axios.get(`${LIST}/${userId}/facilities`).then(response => {
      context.setFacilities(response.data)
    }, () => {
    })
  },
  getModulesByRole(context, roleId) {
    axios.get(`${LIST}/${roleId}/modules`).then(response => {
      context.setAllRoles(response.data)
    }, () => {
    })
  },
  getStatesByUser(context, userId) {
    axios.get(`${LIST}/${userId}/states`).then(response => {
      context.setStates(response.data)
    }, () => {
    })
  },
  getCitiesByUserAndState(context, userId, stateId, index) {
    axios.get(`${LIST}/${userId}/${stateId}/cities`).then(response => {
      context.setCities(response.data, index)
    }, () => {
    })
  },
  getReportingUsers(context, roleId) {
    axios.get(`${LIST}/${roleId}/reporting-users`).then(response => {
      context.setReportingUsers(response.data)
    }, () => {
    })
  },
  getUsersByRole(context, roleId) {
    axios.get(`${LIST}/${roleId}/users`).then(response => {
      context.setRoleUsers(response.data)
    }, () => {
    })
  },
  getAllRolesNyUserTypeId(context) {
    if (context.$store.state.lists.commonLists.all_roles === undefined) {
      context.setAllRoles({})
      return
    }
    context.setAllRoles(JSON.parse(JSON.stringify(context.$store.state.lists.commonLists.all_roles)))
  },
  getTechnologiesListByJobId(context, jobId) {
    axios.get(`${TECHNOLOGIES_LIST}/${jobId}`).then(response => {
      context.setTechnologies(response.data)
    })
  },
  getEmployeeInvoicesFullList(context, id) {
    axios.get(`${EMPLOYEE_LIST}/invoice/employer/${id}/fulllist`).then(response => {
      context.setEmployees(response.data)
    })
  },
  getSubFacilityInvoicesFullList(context, id) {
    axios.get(`${EMPLOYEE_LIST}/invoice/subfacility/${id}/fulllist`).then(response => {
      context.setEmployees(response.data)
    })
  },
  getSupplierInvoicesFullList(context, id) {
    axios.get(`${EMPLOYEE_LIST}/invoice/supplier/${id}/fulllist`).then(response => {
      context.setEmployees(response.data)
    })
  },
  getReferrerInvoicesFullList(context, id) {
    axios.get(`${EMPLOYEE_LIST}/invoice/referrer/${id}/fulllist`).then(response => {
      context.setEmployees(response.data)
    })
  },
  getFacilityInvoicesFullList(context, id) {
    axios.get(`${EMPLOYEE_LIST}/invoice/facility/${id}/fulllist`).then(response => {
      context.setEmployees(response.data)
    })
  },
  getCorrectedInvoices(context, employeeId, userId, parentTypeId, Month, Year) {
    const params = {
      params: {
        parent_id: userId, employee_id: employeeId, parent_type_id: parentTypeId, date: 1, month: Month, year: Year,
      },
    }
    axios.get(`${INVOICE_LIST}/corrected`, params).then(response => {
      context.setCorrectedInvoices(response.data)
    })
  },
  getEmployerTimesheetFullList(context, employeeId, Month) {
    const params = {
      employee_id: employeeId, month: Month,
    }
    axios.post(TIMESHEET_EMPLOYER_INVOICE_LIST, params).then(response => {
      context.setEmployerTimeSheets(response.data)
    })
  },
  getSubFacilityTimesheetFullList(context, employeeId, Month) {
    const params = {
      employee_id: employeeId, month: Month,
    }
    axios.post(TIMESHEET_SUBVENDOR_INVOICE_LIST, params).then(response => {
      context.setSubFacilityTimeSheets(response.data)
    })
  },
  getSupplierTimesheetFullList(context, employeeId, Month) {
    const params = {
      employee_id: employeeId, month: Month,
    }
    axios.post(TIMESHEET_SUPPLIERER_INVOICE_LIST, params).then(response => {
      context.setSupplierTimeSheets(response.data)
    })
  },
  getReferrerTimesheetFullList(context, employeeId, Month) {
    const params = {
      employee_id: employeeId, month: Month,
    }
    axios.post(TIMESHEET_REFERRER_INVOICE_LIST, params).then(response => {
      context.setReferrerTimeSheets(response.data)
    })
  },
  getFacilityAmount(context, employeeIds, Month) {
    const params = {
      employee_ids: employeeIds, month: Month,
    }
    axios.post(TIMESHEET_VENDOR_INVOICE_AMOUNT, params).then(response => {
      context.setFacilityAmount(response.data)
    })
  },
  getInvoiceAmount(context, employeeIds, Month, parentTypeId) {
    const params = {
      employee_ids: employeeIds, month: Month, parent_type_id: parentTypeId,
    }
    axios.post(TIMESHEET_INVOICE_AMOUNT, params).then(response => {
      context.setFacilityAmount(response.data)
    })
  },
  getSubFacilityCorrectedInvoices(context, parentId, Month, Year) {
    const params = {
      params: {
        parent_id: parentId, date: 1, month: Month, year: Year,
      },
    }
    axios.get(`${INVOICE_LIST}/subfacility/corrected`, params).then(response => {
      context.setCorrectedInvoices(response.data)
    })
  },
  getReferrerCorrectedInvoices(context, parentId, Month, Year) {
    const params = {
      params: {
        parent_id: parentId, date: 1, month: Month, year: Year,
      },
    }
    axios.get(`${INVOICE_LIST}/referrer/corrected`, params).then(response => {
      context.setCorrectedInvoices(response.data)
    })
  },
  getSupplierCorrectedInvoices(context, parentId, Month, Year) {
    const params = {
      params: {
        parent_id: parentId, date: 1, month: Month, year: Year,
      },
    }
    axios.get(`${INVOICE_LIST}/supplier/corrected`, params).then(response => {
      context.setCorrectedInvoices(response.data)
    })
  },
  getFacilityCorrectedInvoices(context, parentId, Month, Year) {
    const params = {
      params: {
        parent_id: parentId, date: 1, month: Month, year: Year,
      },
    }
    axios.get(`${INVOICE_LIST}/facility/corrected`, params).then(response => {
      context.setCorrectedInvoices(response.data)
    })
  },
  getDashboardCount(context1) {
    const context = context1
    axios.get(DASHBOARD_COUNT).then(response => {
      const topCounts = {
        alerts: response.data.alerts_count,
        registrants: response.data.prospects_registrant,
        not_registrants: response.data.prospects_not_registrant,
        applied_prospects: response.data.prospects_applied_jobs,
        eligible_prospects: response.data.prospects_new_jobs,
        eligible_registrants: response.data.registrants_new_jobs,
        urgent_jobs: response.data.urgent_jobs,
      }
      context.$store.state.top_counts.alerts = response.data.alerts_count
      context.$store.state.top_counts.registrants = response.data.prospects_registrant
      context.$store.state.top_counts.not_registrants = response.data.prospects_not_registrant
      context.$store.state.top_counts.applied_prospects = response.data.prospects_applied_jobs
      context.$store.state.top_counts.eligible_prospects = response.data.prospects_new_jobs
      context.$store.state.top_counts.eligible_registrants = response.data.registrants_new_jobs
      context.$store.state.top_counts.urgent_jobs = response.data.urgent_jobs
      context.$store.commit('ALERTS_COUNT', topCounts)
      if (typeof context.setCounts === 'function') {
        context.setCounts(response.data)
      }
    })
  },
  getDashboardRoles(context) {
    axios.get(`${DASHBOARD_COUNT}/roles`).then(response => {
      localStorage.setItem('user_roles', Constants.convertObjectToString(response.data))
      window.localStorage.setItem('user_roles', Constants.convertObjectToString(response.data))
      context.setRoles(response.data)
    }, () => {
    })
  },
  getEmployeeListByParentTypeAndParentId(context, parentType, parentId) {
    axios.get(`${INTERVIEW}/${parentType}/${parentId}/employees/fulllist`).then(response => {
      context.setInterviewEmployees(response.data)
    }, () => {
    })
  },
  getBalance(context) {
    axios.get(`${LIST}/balance`).then(response => {
      context.setBalance(response.data)
    }, () => {
    })
  },
  getEmployeeListByParentType(context, parentType) {
    axios.get(`${INTERVIEW}/${parentType}/employees/fulllist`).then(response => {
      context.setInterviewEmployees(response.data)
    }, () => {
    })
  },
  getDocumentTypes(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setDocumentTypes([])
      return
    }
    context.setDocumentTypes(context.$store.state.lists.commonLists.lists.document_types)
  },
  getDocumentNames(context, documentTypeId, index) {
    if (context.$store.state.lists.commonLists.document_names === undefined) {
      context.setDocumentNames([], index)
      return
    }
    context.setDocumentNames(context.$store.state.lists.commonLists.document_names[documentTypeId], index)
  },
  getDocumentIssuers(context, documentNameTypeId, index) {
    if (context.$store.state.lists.commonLists.issuers === undefined) {
      context.setDocumentIssuers([], index)
      return
    }
    context.setDocumentIssuers(context.$store.state.lists.commonLists.issuers[documentNameTypeId], index)
  },
  getDocumentIssuersByDocumentNameTypeId(context, documentNameTypeId) {
    if (context.$store.state.lists.commonLists.issuers === undefined) {
      context.setDocumentIssuers([])
      return
    }
    context.setDocumentIssuers(context.$store.state.lists.commonLists.issuers[documentNameTypeId])
  },
  getDocumentReminders(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setDocumentReminders([])
      return
    }
    context.setDocumentReminders(context.$store.state.lists.commonLists.lists.document_reminders)
  },
  getDocumentVersions(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setDocumentVersions([])
      return
    }
    context.setDocumentVersions(context.$store.state.lists.commonLists.lists.versions)
  },
  wait(ms) {
    const start = new Date().getTime()
    let end = start
    while (end < start + ms) {
      end = new Date().getTime()
    }
  },
}
