<template>
  <b-card
    class="mb-0"
  >
    <div class="mb-1">
      <h4 class="card-title mb-0 pt-50">
        Closed Jobs</h4>
    </div>
    <div>
      <vue-good-table
        :columns="columns"
        :rows="jobResponse.closed_jobs"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          perPage:pageLength,
          enabled: true,
          mode: 'pages',
          setCurrentPage: currentPage,
        }"
        @on-page-change="onPageChange"
        @on-per-page-change="onPerPageChange"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Job Class -->
          <span v-if="props.column.fieldType === 'cellRendererJobClass'">
            <span
              v-b-tooltip.hover
              :title='props.row.skill_description'
              v-b-tooltip.hover.v-primary
            >{{ props.row.skill }}</span>
          </span>

          <!-- Column: Update Button -->
          <span v-if="props.column.field === 'cellRendererUpdateBtn'">
            <b-button
              variant="outline-primary"
              class="px-50 py-50"
              @click="updateIndeedJob(props)"
            >
              Update
            </b-button>
          </span>

          <!-- Column: Update Button -->
          <span v-if="props.column.fieldType === 'cellRendererIndeed'">
            <img
              class="ml-50 img-fluid"
              width="24"
              height="24"
              @click="openIndeedJob(props.row)"
              :src="require('@/assets/images/icons/indeed_logo.png')"
              alt="image slot"
            >
          </span>

          <!-- Column: Common -->
          <span v-else>
            <span v-if="props.column.fieldType !== 'cellRendererJobClass' && props.column.fieldType !== 'cellRendererIndeed'">
              {{ props.formattedRow[props.column.field] }}
            </span>
          </span>
        </template>

      </vue-good-table>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, VBModal, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Jobs from '@/js/jobs'

export default {
  name: 'JobRequests',
  components: {
    BCard,
    VueGoodTable,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      searchQuery: '',
      dir: false,
      groupJobIds: {},
      activeTab: 'contract',
      type: 'contract',
      sortingHistory: [],
      jobResponse: {
        closed_jobs: [],
        count: 1,
      },
      columns: [
        {
          label: '#',
          field: 'index',
          width: '90px',
          sortable: false,
        },
        {
          label: 'Skills',
          field: 'skill',
          fieldType: 'cellRendererJobClass',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Job Class',
          },
        },
        {
          label: 'Location',
          field: 'city',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Location',
          },
        },
        {
          label: 'Indeed',
          field: 'indeed_path',
          fieldType: 'cellRendererIndeed',
          sortable: false,
        },
        {
          label: 'Update',
          field: 'cellRendererUpdateBtn',
          width: '100px',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    pageLength: {
      get() {
        return this.$store.state.query.closedperPage
      },
      set(val) {
        this.$store.state.query.closedperPage = val
      },
    },
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
      },
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
  },
  created() {
    if (this.$store.state.closedJobs.isJobsLoaded) {
      this.jobResponse = this.$store.state.closedJobs.jobs
    } else {
      Jobs.getClosedIndeedJobs(this)
    }
  },
  methods: {
    onPageChange(page) {
      this.currentPage = page.currentPage
      this.pageLength = page.currentPerPage
    },
    onPerPageChange(perPage) {
      this.currentPage = perPage.currentPage
      this.pageLength = perPage.currentPerPage
    },
    openIndeedJob(groupJob) {
      window.open(groupJob.indeed_path, '_blank')
    },
    updateIndeedJob(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to close this ${obj.row.skill} ${obj.row.location}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, close it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          Jobs.closeIndeedJob(this, obj.row.id, obj.index)
        }
      })
    },
    jobClosed(data, index) {
      this.jobResponse.closed_jobs.splice(index, 1)
      this.$store.state.closedJobs.jobs = this.jobResponse
      this.$swal({
        icon: 'success',
        title: 'Job Closed!',
        text: data.return_message,
      })
    },
    jobNotClosed() {
      this.$swal({
        icon: 'warning',
        title: 'Job Not Closed!',
        text: 'Your Job has been not closed',
      })
    },
    setJobs(jobResponse) {
      this.jobResponse = jobResponse
      this.$store.state.closedJobs.isJobsLoaded = true
      this.$store.state.closedJobs.jobs = jobResponse
    },
    captureSortHistory(sortEvent) {
      this.sortingHistory.push({
        field: sortEvent[0].field,
        type: sortEvent[0].type,
      })
    },
    updateSearchQuery() {
      this.$store.state.query.query = this.searchQuery
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
