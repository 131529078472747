<!-- =========================================================================================
    File Name: ResetPassword.vue
    Description: Reset Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div
          slot="no-body"
          class="full-page-bg-color"
        />
      </vx-card>
    </div>
  </div>
</template>

<script>
import User from '@/js/user'

export default {
  data() {
    return {
    }
  },
  created() {
    this.$vs.loading()
    User.getProfileFromToken(this, this.$route.params.token)
  },
  methods: {
  },
}
</script>
