var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('h4',{staticClass:"card-title mb-0 pt-50"},[_vm._v(" Edit Role User")])]),_c('b-col',{attrs:{"cols":"12","md":"6 ml-auto"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.back}},[_vm._v(" Back ")])],1)])],1),_c('hr'),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('app-timeline',[_c('app-timeline-item',{staticClass:"pb-1"},[_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Name")]),_c('span',{staticClass:"form-control",domProps:{"textContent":_vm._s(_vm.addRoleuser.name)}})])],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Email")]),_c('span',{staticClass:"form-control h-auto",domProps:{"textContent":_vm._s(_vm.addRoleuser.email)}})])],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("User Type")]),_c('span',{staticClass:"form-control",domProps:{"textContent":_vm._s(_vm.addRoleuser.type)}})])],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Role")]),_c('span',{staticClass:"form-control",domProps:{"textContent":_vm._s(_vm.addRoleuser.role)}})])],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Report to User")]),_c('validation-provider',{attrs:{"name":"Report to User","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"reporting_user_id","options":_vm.reportUsers,"reduce":function (item) { return item.id; },"clearable":false,"placeholder":"Select"},model:{value:(_vm.addRoleuser.reporting_user_id),callback:function ($$v) {_vm.$set(_vm.addRoleuser, "reporting_user_id", $$v)},expression:"addRoleuser.reporting_user_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),(_vm.addRoleuser.type.toLowerCase() === 'admin')?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-alert',{attrs:{"variant":"primary","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_c('strong',[_vm._v("All Roles Selected")])])])])],1)],1):_vm._e()],1),_c('app-timeline-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.addRoleuser.user_defined_fields.length !== 0),expression:"addRoleuser.user_defined_fields.length !== 0"}],staticClass:"pb-1",attrs:{"variant":"success"}},[_c('div',{staticClass:"d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"},[_c('h6',[_vm._v("User Defined Fields")])]),_c('b-row',{staticClass:"mt-1"},_vm._l((_vm.addRoleuser.user_defined_fields),function(field){return _c('b-col',{key:field.id,attrs:{"id":field.id,"cols":"12","md":"6","lg":"4"}},[(field.type.toLowerCase() === 'input')?_c('b-form-group',[_c('label',[_vm._v(_vm._s(field.name))]),_c('validation-provider',{attrs:{"name":field.name,"rules":field.required ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(field.type.toLowerCase() === 'dropdown')?_c('b-form-group',[_c('label',[_vm._v(_vm._s(field.name))]),_c('validation-provider',{attrs:{"name":field.name,"rules":field.required ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":field.options,"label":"option","reduce":function (item) { return item.option; },"clearable":!field.required,"placeholder":"Select"},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(field.type.toLowerCase() === 'textarea')?_c('b-form-group',[_c('label',[_vm._v(_vm._s(field.name))]),_c('validation-provider',{attrs:{"name":field.name,"rules":field.required ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"rows":"2"},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1)}),1)],1)],1),_c('hr'),_c('b-row',{staticClass:"ml-2"},[_c('b-col',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"primary","type":"button"},on:{"click":_vm.submitForm}},[_vm._v(" Update Role User ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }