<template>
  <b-card
    class="mb-0"
  >
    <div class="mb-2">
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-2"
        >
          <h4 class="card-title mb-0 pt-50">
            Documents Expired Alerts</h4>
        </b-col>
        <b-col
          cols="12"
          md="8 ml-auto"
          class="col-xxl-6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group class="d-none">
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                @keyup.enter="updateSearchQuery"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="updateSearchQuery"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="14"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button
              variant="outline-primary"
              class="ml-2"
              @click="back"
            >
              Back
            </b-button>
          </div>
        </b-col>
      </b-row>

    </div>

    <vue-good-table
      :columns="columns"
      :rows="documentAlertResponse.parent_documents"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Action -->
        <span
          v-if="props.column.field === 'action'"
          class="ml-0"
        >
          <b-link
            id="view"
            :to="{ name: 'documents-document-tracker-edit', params: { id: props.row.parent_document_id } }"
          >
            <b-badge
              pill
              variant="light-primary"
            ><feather-icon icon="EyeIcon" />
            </b-badge>
          </b-link>
          <span>
            <b-button
              variant="outline-primary"
              class="btn-sm ml-75 px-50"
              @click="confirmIgnoreRecord(props.row)"
            >
              Ignore
            </b-button>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
      >
        <div class="d-flex justify-content-end flex-wrap">
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="documentAlertResponse.count"
              first-number
              last-number
              align="right"
              :per-page="1"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BLink, BBadge,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import DocumentAlerts from '@/js/documentalerts'

export default {
  name: 'JobAlerts',
  components: {
    BBadge,
    BCard,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BLink,
    BPagination,
    VueGoodTable,
  },
  data() {
    return {
      searchQuery: '',
      pageLength: 10,
      dir: false,
      documentAlertResponse: {
        parent_documents: [],
        count: 1,
      },
      columns: [
        {
          label: '#',
          field: 'index',
          sortable: false,
        },
        {
          label: 'Parent Type',
          field: 'parent_type',
          sortable: false,
        },
        {
          label: 'Name',
          field: 'parent',
          sortable: false,
        },
        {
          label: 'Document Type',
          field: 'document_type',
          sortable: false,
        },
        {
          label: 'Issuer',
          field: 'issuer',
          sortable: false,
        },
        {
          label: 'Expired Date',
          field: 'expire_date',
          width: '120px',
          sortable: false,
        },
        {
          label: 'Days Over',
          field: 'due_days',
          width: '110px',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          width: '120px',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
        DocumentAlerts.getDocumentExpiredAlerts(this)
      },
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Active      : 'light-success',
        Inactive    : 'light-danger',
        Terminated  : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.documentAlertResponse.count = this.$store.state.query.page
    this.searchQuery = this.$store.state.query.query
    DocumentAlerts.getDocumentExpiredAlerts(this)
  },
  methods: {
    confirmIgnoreRecord(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to ignore this ${obj.document_type}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, ignore it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          DocumentAlerts.ignoreAlert(this, obj.id)
        }
      })
    },
    alertIgnored(data) {
      DocumentAlerts.getDocumentExpiredAlerts(this)
      this.$swal({
        icon: 'success',
        title: 'Alert Ignored!',
        text: data.return_message,
      })
    },
    alertNotIgnored(message) {
      this.$swal({
        icon: 'warning',
        title: 'Alert Not Ignored!',
        text: message,
      })
    },
    updateSearchQuery() {
      this.$store.state.query.query = this.searchQuery
      this.currentPage = 1
    },
    setDocumentAlerts(documentAlertResponse) {
      this.documentAlertResponse = documentAlertResponse
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
