<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Edit Employee Salary</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <b-row class="mt-1">
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Employee</label>
                  <span
                    class="form-control text-left"
                    v-text="addSalary.employee"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Start Date</label>
                  <span
                    class="form-control text-left"
                    v-text="addSalary.start_date"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>End Date</label>
                  <span
                    class="form-control text-left"
                    v-text="addSalary.end_date"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>PayRoll Details</h6>
            </div>
            <h5 class="mt-50">
              {{ addSalary.payroll_type }}
            </h5>
            <div class="mt-1 ml-2">
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                  lg="4"
                >
                  <b-form-group>
                    <label>Amount</label>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Amount"
                      rules="required"
                    >
                      <b-input-group
                        prepend="$"
                      >
                        <money
                          id="Amount"
                          v-model="addSalary.amount"
                          :min="0"
                          :step="0.5"
                          v-bind="money"
                          class="form-control"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                  lg="4"
                >
                  <b-form-group>
                    <label>Status</label>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Salary Status"
                      rules="required"
                    >
                      <v-select
                        id="salary_status_id"
                        v-model="addSalary.status_id"
                        :clearable="false"
                        placeholder="Select"
                        :options="status"
                        :reduce="item => item.id"
                        @input="statusChanged"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                  lg="4"
                >
                  <b-form-group
                    id="paid_date"
                  >
                    <label>Paid Date</label>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Paid Date"
                      :rules="paidDateRequired ? 'required' : ''"
                    >
                      <b-input-group>
                        <flat-pickr
                          v-model="paid_date"
                          class="form-control"
                          :config="$store.state.notGraterThanToday"
                          placeholder="MM-DD-YYYY"
                        />
                        <b-input-group-append>
                          <b-button
                            class="px-1 outline-grey"
                            variant="outline-secondary"
                            @click="removeEmployerTaxDate"
                          >
                            <feather-icon icon="XIcon" />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row>
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Update Salary
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import LIST from '@/js/lists'
import Salaries from '@/js/employeesalaries'
import Constants from '@/js/constant'

export default {
  name: 'EmployeeSalaryEdit',
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    flatPickr,
    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      addSalary: {
        payroll_type: '',
        employee_id: null,
        start_date: null,
        end_date: null,
        amount: 0.0,
        status_id: null,
        paid_date: null,
      },
      paidDateRequired: false,
      paid_date: null,
      status: [],
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getEmployeePayrollStatus(this)
      },
    },
  },
  mounted() {
  },
  created() {
    Salaries.getSalaryById(this, this.$route.params.id)
    LIST.getEmployeePayrollStatus(this)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          this.showLoading = true
          if (this.paid_date !== null) {
            this.addSalary.paid_date = Constants.getEprmMonthDateFromString(this.paid_date)
          } else {
            this.addSalary.paid_date = null
          }
          Salaries.updateSalary(this, this.$route.params.id, this.addSalary)
        }
      })
    },
    statusChanged() {
      this.paidDateRequired = false
      const statusSelected = this.status.filter(value => value.id === this.addSalary.status_id)
      if (statusSelected.length > 0) {
        if (statusSelected[0].label === 'Paid') {
          this.paidDateRequired = true
        }
      }
    },
    setEmployeePayrollStatus(status) {
      this.status = status
    },
    setSalary(salaryResponse) {
      this.addSalary = salaryResponse
      this.paid_date = salaryResponse.paid_date
      this.statusChanged()
    },
    removeEmployerTaxDate() {
      this.paid_date = null
    },
    salaryUpdated(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    salaryNotUpdated(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    back() {
      this.$router.push('/finance-and-accounting/salaries').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.repeater-form {
  transition: .35s height;
}
</style>
