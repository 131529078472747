<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Edit Vendor</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form class="ex-large">
        <app-timeline>
          <app-timeline-item class="pb-1">
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="addVendor.name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Status</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Status"
                    rules="required"
                  >
                    <v-select
                      id="status"
                      v-model="addVendor.status_id"
                      :options="status"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Payment Terms</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Payment Terms"
                    rules="required"
                  >
                    <v-select
                      id="payment_terms"
                      v-model="addVendor.payment_terms_id"
                      :options="paymentTerms"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Invoice Frequency</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Invoice Frequency"
                    rules="required"
                  >
                    <v-select
                      id="status"
                      v-model="addVendor.invoice_frequency_id"
                      :options="invoiceFrequencies"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Discount</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Discount"
                    rules="required"
                  >
                    <b-input-group
                      prepend="%"
                    >
                      <money
                        id="vendor_discount"
                        v-model="addVendor.discount"
                        :min="0"
                        :step="0.5"
                        v-bind="money"
                        class="form-control"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Contact Information</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Contact Email</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Contact Email"
                    rules="required"
                  >
                    <b-form-input
                      id="email"
                      v-model="addVendor.email_address"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Contact Phone</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Contact Phone"
                    rules="required|integer|digits:10"
                  >
                    <b-form-input
                      id="phone"
                      v-model="addVendor.phone_number"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Address 1</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name=" Address 1"
                    rules="required"
                  >
                    <b-form-input
                      id="address_address1"
                      v-model="addVendor.address.address1"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Address 2</label>
                  <b-form-input
                    id="address_address"
                    v-model="addVendor.address.address2"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>City</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name=" City"
                    rules="required"
                  >
                    <b-form-input
                      id="address_city"
                      v-model="addVendor.address.city"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>State</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name=" State"
                    rules="required"
                  >
                    <v-select
                      id="address_state_id"
                      v-model="addVendor.address.state_id"
                      :options="states"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Zip Code</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name=" Zip Code"
                    rules="required|integer|digits:5"
                  >
                    <b-form-input
                      id="address_zip_id"
                      v-model="addVendor.address.zip_id"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="success"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Documents</h6>
            </div>
            <b-row class="mt-1">
              <b-col cols="12">
                <div class="table-responsive">
                  <table class="table b-table table-th-pd table-td-pd">
                    <thead>
                      <tr>
                        <th>Document Type</th>
                        <th>Issuer</th>
                        <th>Name</th>
                        <th>Version</th>
                        <th class="th-minw-125">Upload Date</th>
                        <th class="th-minw-110">Exp Date</th>
                        <th>Uploaded By</th>
                        <th>Reminder</th>
                        <th>Document</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(priviousVersion, index) in addVendor.documents"
                        :key="index"
                      >
                        <td>{{ priviousVersion.document_type }}</td>
                        <td>{{ priviousVersion.issuer }}</td>
                        <td>{{ priviousVersion.document_name }}</td>
                        <td>{{ priviousVersion.version }}</td>
                        <td>{{ priviousVersion.uploaded_date }}</td>
                        <td>{{ priviousVersion.exp_date }}</td>
                        <td>{{ priviousVersion.uploaded_by }}</td>
                        <td>{{ priviousVersion.reminder }}</td>
                        <td>
                          <b-link
                            target="_blank"
                            :href="priviousVersion.document_path"
                          >
                            <b-badge
                              variant="primary"
                              class="mr-1 mb-50"
                            >
                              <span>{{ priviousVersion.document }}</span>
                            </b-badge>
                          </b-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
            <div
              v-if="addVendor.documents.length == 0"
              class="text-center"
            >
              <p> No Documents available </p>
            </div>
          </app-timeline-item>
          <app-timeline-item
            variant="primary"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Comments</h6>
            </div>
            <b-row>
              <b-col
                v-for="(comment, index) in addVendor.comments"
                :key="index"
                cols="12"
                md="6"
                lg="6"
                xl="4"
              >
                <div class="d-flex">
                  <div class="left w-100">
                    <b-form-group>
                      <label>Comment</label>
                      <b-form-textarea
                        v-model="comment.comment"
                        rows="2"
                      />
                    </b-form-group>
                  </div>
                  <div class="right mt-1 ml-1">
                    <b-button
                      v-show="index === 0"
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      @click="addComment"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                    <b-button
                      v-show="index !== 0"
                      variant="outline-danger"
                      class="btn-icon rounded-circle"
                      @click="removeComment(index)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            v-show="addVendor.user_defined_fields.length !== 0"
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>User Defined Fields</h6>
            </div>
            <b-row>
              <b-col
                v-for="field in addVendor.user_defined_fields"
                :id="field.id"
                :key="field.id"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group v-if="field.type.toLowerCase() === 'input'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-input
                      v-model="field.value"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'dropdown'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <v-select
                      v-model="field.value"
                      :options="field.options"
                      label="option"
                      :reduce="item => item.option"
                      :clearable="!field.required"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'textarea'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-textarea
                      v-model="field.value"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="mt-1">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Update Vendor
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BInputGroup, BFormInput, BButton, BFormTextarea, BLink, BBadge,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import Vendor from '@/js/vendors'
import LIST from '@/js/lists'
import moment from 'moment'
import Constants from '@/js/constant'
// import currency from 'v-currency-field'

export default {
  name: 'VendorEdit',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BLink,
    BBadge,
    vSelect,
    BButton,
    // currency,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      visa_expiry_date: null,
      addVendor: {
        name: '',
        status_id: null,
        invoice_frequency_id: null,
        discount: '',
        email_address: '',
        phone_number: '',
        address: {
          address1: null,
          address2: null,
          city: null,
          zip_id: null,
          state_id: null,
        },
        comments: [],
        user_defined_fields: [],
      },
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      url: null,
      companies: [],
      states: [],
      status: [],
      paymentTerms: [],
      invoiceFrequencies: [],
      formstate: {},
      submitted: false,
    }
  },
  watch: {
    '$store.state.lists.commonLists.lists.payment_terms': {
      immediate: true,
      handler() {
        LIST.getPaymentTerms(this)
      },
    },
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getStatesFullList(this)
        LIST.getVendorInvoiceFrequency(this)
      },
    },
    '$store.state.lists.hrLists.response.vendor.lists.status': {
      immediate: true,
      handler() {
        LIST.getVendorStatusFullList(this)
        LIST.getUserDefinedFieldsVendors(this)
      },
    },
  },
  mounted() {
  },
  created() {
    this.addComment()
    LIST.getStatesFullList(this)
    LIST.getVendorStatusFullList(this)
    LIST.getPaymentTerms(this)
    LIST.getUserDefinedFieldsVendors(this)
    LIST.getVendorInvoiceFrequency(this)
    Vendor.getVendorById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          this.showLoading = true
          Vendor.updateVendor(this, this.$route.params.id, this.addVendor)
        }
      })
    },
    setUserDefinedFields(userDefinedFields) {
      this.addVendor.user_defined_fields = userDefinedFields
    },
    setCompanies(companies) {
      this.companies = companies
    },
    setStatus(status) {
      this.status = status
    },
    setPaymentTerms(paymentTerms) {
      this.paymentTerms = paymentTerms
    },
    setVendorInvoiceFrequency(invoiceFrequencies) {
      this.invoiceFrequencies = invoiceFrequencies
    },
    setStates(states) {
      this.states = states
    },
    vendorUpdated(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        html: Constants.getParentSuccessMessage(data.return_message),
        icon: 'success',
        showCancelButton: true,
        confirmButtonText: Constants.getUploadButtonText(),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$router.push(`/documents/document-tracker/new?parent_id=${data.id}&parent_type_id=${data.parent_type_id}`).catch(() => {})
        }
      })
    },
    vendorNotUpdated(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    addComment() {
      const comment = {
        comment: '',
        date: Constants.getEprmMonthDateFromString(moment()),
        time: moment().format('hh:mm a'),
      }
      this.addVendor.comments.push(comment)
    },
    removeComment(index) {
      this.addVendor.comments.splice(index, 1)
    },
    setVendor(vendor) {
      this.addVendor = vendor
      if (this.addVendor.comments.length === 0) {
        this.addComment()
      }
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.repeater-form {
  transition: .35s height;
}
</style>
