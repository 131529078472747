<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Clone Job Posting</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Job Title</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Job Title"
                    rules="required"
                  >
                    <b-form-input
                      v-model="addJob.job_title"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Job-Id / Ref-No</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Job-Id / Ref-No"
                    rules="required"
                  >
                    <b-form-input
                      id="job_id"
                      v-model="addJob.job_id"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Position Type</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Position Type"
                    rules="required"
                  >
                    <v-select
                      v-model="addJob.position_type_id"
                      :options="types"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>City, Zip</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="City, Zip"
                    rules="required"
                  >
                    <v-select
                      id="location"
                      v-model="addJob.city_id"
                      :options="locations"
                      :reduce="item => item.id"
                      :clearable="true"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Visa Required</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Visa Required"
                    rules="required"
                  >
                    <v-select
                      v-model="addJob.visa_type_ids"
                      :options="visaTypes"
                      :reduce="item => item.id"
                      multiple
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Facility / Client</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Facility / Client"
                    rules="required"
                  >
                    <v-select
                      v-model="addJob.facility_id"
                      :options="facilities"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Facility Rate</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Facility Rate"
                    rules="required"
                  >
                    <b-input-group
                      prepend="$"
                    >
                      <money
                        v-model="addJob.facility_bill_rate"
                        :min="0"
                        :step="0.5"
                        v-bind="money"
                        class="form-control"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Status</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Status"
                    rules="required"
                  >
                    <v-select
                      v-model="addJob.status_id"
                      :options="status"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Bill Rate</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Bill Rate"
                    rules="required"
                  >
                    <b-input-group
                      prepend="$"
                    >
                      <money
                        v-model="addJob.contract_bill_rate"
                        :min="0"
                        :step="0.5"
                        v-bind="money"
                        class="form-control"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Main Skill</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Main Skill"
                    rules="required"
                  >
                    <v-select
                      v-model="addJob.skill_ids"
                      :options="skills"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                      multiple
                      @input="skillChanged(addJob.skill_ids)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  id="start_date"
                >
                  <label>Start Date</label>
                  <b-input-group>
                    <flat-pickr
                      v-model="start_date"
                      class="form-control"
                      :config="start_date_config"
                      placeholder="MM-DD-YYYY"
                      @input="startDateChanged()"
                    />
                    <b-input-group-append>
                      <b-button
                        class="px-1 outline-grey"
                        variant="outline-secondary"
                        @click="removeStartDate"
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  id="end_date"
                >
                  <label>End Date</label>
                  <b-input-group>
                    <flat-pickr
                      v-model="end_date"
                      class="form-control"
                      :config="end_date_config"
                      placeholder="MM-DD-YYYY"
                      @input="endDateChanged()"
                    />
                    <b-input-group-append>
                      <b-button
                        class="px-1 outline-grey"
                        variant="outline-secondary"
                        @click="removeEndDate"
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Experience</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Experience"
                    rules="required"
                  >
                    <b-input-group append="Years">
                      <b-form-input
                        v-model="addJob.experience_years"
                        type="number"
                        :state="errors.length > 0 ? false:null"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Duration</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Duration"
                    rules="required"
                  >
                    <v-select
                      v-model="addJob.duration_id"
                      :options="durations"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Immediate</label>
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="immediate"
                      plain
                      name="some-radios3"
                      value="Yes"
                      class="mt-50"
                    >
                      Yes
                    </b-form-radio>
                    <b-form-radio
                      v-model="immediate"
                      plain
                      name="some-radios3"
                      value="No"
                      class="mt-50"
                    >
                      No
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Assigned Users</label>
                  <v-select
                    id="assigned_users"
                    v-model="addJob.assigned_user_ids"
                    multiple
                    :options="roleUsers"
                    :reduce="item => item.id"
                    :clearable="false"
                    placeholder="Select"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Co-Ordinator</label>
                  <b-form-input
                    id="coordinator"
                    v-model="addJob.coordinator"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Shift Days</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Shift Days"
                    rules="required|integer|between:0,7"
                  >
                    <b-input-group append="Days">
                      <b-form-input
                        id="shift_days"
                        v-model="addJob.shift_days"
                        type="number"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Shift Hours</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Shift Hours"
                    rules="required|integer|between:0,24"
                  >
                    <b-input-group append="Hours">
                      <b-form-input
                        id="shift_hours"
                        v-model="addJob.shift_hours"
                        type="number"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-for="(job_start_time, index) in addJob.job_start_times"
                :key="index"
                cols="12"
                md="6"
                lg="4"
              >
                <div class="d-flex">
                  <div class="left w-100">
                    <b-form-group>
                      <label>Shift Start Time</label>
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="''"
                        :name="'Shift Start Time' + index"
                      >
                        <flat-pickr
                          v-model="job_start_time.time"
                          class="form-control"
                          :config="shiftTimeConfig"
                          :placeholder="'00:00'"
                        />
                        <small class="text-danger">{{ (errors[0] !== undefined && errors[0] !== null) ? errors[0].replace('Shift Start Time' + index, 'Shift Start Time') : errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="right mt-2 ml-1">
                    <b-button
                      variant="outline-danger"
                      class="btn-icon rounded-circle"
                      @click="removeShiftTime(index)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </div>
                </div>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-button
                  variant="outline-primary"
                  type="button"
                  class="mt-2 mb-1"
                  @click="addShiftTime"
                >
                  Add Shift Time
                </b-button>
              </b-col>
              <b-col cols="12">
                <b-form-group>
                  <label>Description</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Description"
                    rules="required"
                  >
                    <quill-editor
                      id="description"
                      v-model="addJob.description"
                      :options="snowOption"
                      class="job-description-view"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Secondary Skills</h6>
            </div>
            <b-row>
              <b-col cols="12">
                <b-row>
                  <b-col
                    v-for="option in technologies"
                    :key="option"
                    :value="option"
                    cols="2"
                    class="mb-1"
                  >
                    <b-form-checkbox
                      :checked="technologySelected(option.id)"
                      @change="checked(option.id)"
                    >
                      {{ option.label }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="success"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Workflow</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Follow Workflow</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Follow Workflow"
                    rules="required"
                  >
                    <v-select
                      id="follow_process"
                      v-model="addJob.process_id"
                      :options="processes"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                      class="custom-word-select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="warning"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Required Criteria</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
              >
                <b-form-group>
                  <div class="demo-inline-spacing">
                    <div
                      v-for="criteria in criterias"
                      :id="criteria.id"
                      :key="criteria.id"
                    >
                      <b-form-checkbox
                        :checked="criteriaSelected(criteria.id)"
                        @change="criteriaChecked(criteria.id)"
                      >
                        {{ criteria.label }}
                      </b-form-checkbox>
                    </div>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-for="(criteria, index) in addJob.criterias"
                :id="criteria.id"
                :key="criteria.id"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="'Criteria' + index"
                    :rules="'required'"
                  >
                    <div class="d-flex">
                      <div class="left w-100">
                        <b-form-input
                          :id="'criteria_name' + index"
                          v-model="criteria.name"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ (errors[0] !== null && errors[0] !== undefined) ? errors[0].replace('Criteria' + index, 'Criteria') : errors[0] }}</small>
                      </div>
                      <div class="right ml-50">
                        <b-button
                          variant="outline-danger"
                          class="btn-icon rounded-circle"
                          @click="removeCriteria(index)"
                        >
                          <feather-icon icon="MinusIcon" />
                        </b-button>
                      </div>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button
                  variant="outline-primary"
                  type="button"
                  class="mt-50"
                  @click="addCriteria"
                >
                  Add
                </b-button>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="success"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Tasks</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="12 ml-auto"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-button
                    variant="outline-primary"
                    :to="{ name: 'new-create-task', params: { id: $route.params.id } }"
                    class="px-1 py-50"
                  >
                    Create Task
                  </b-button>
                </div>
              </b-col>
              <b-col
                cols="12"
                class="mt-1"
              >
                <div class="table-responsive-sm">
                  <table class="table b-table custom-reports-table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th
                          class="min-w-120"
                        >Created Date</th>
                        <th
                          class="min-w-120"
                        >Due Date</th>
                        <th>Status</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="task in addJob.tasks"
                        :key="task"
                        :value="task"
                      >
                        <td>{{ task.name }}</td>
                        <td>{{ task.created_date }}</td>
                        <td>{{ task.due_date }}</td>
                        <td>{{ task.status }}</td>
                        <td>{{ task.description }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            v-show="addJob.user_defined_fields.length !== 0"
            variant="warning"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>User Defined Fields</h6>
            </div>
            <b-row>
              <b-col
                v-for="field in addJob.user_defined_fields"
                :id="field.id"
                :key="field.id"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group v-if="field.type.toLowerCase() === 'input'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-input
                      v-model="field.value"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'dropdown'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <v-select
                      v-model="field.value"
                      :options="field.options"
                      label="option"
                      :reduce="item => item.option"
                      :clearable="!field.required"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'textarea'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-textarea
                      v-model="field.value"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="mt-1">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Clone Post
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BInputGroup, BInputGroupAppend, BButton, BFormCheckbox, BFormRadio,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import {
  required,
} from '@validations'
import moment from 'moment'
import Job from '@/js/jobs'
import Technology from '@/js/skillstechnologies'
import LIST from '@/js/lists'
import Process from '@/js/process'
import Constants from '@/js/constant'

export default {
  name: 'JobClone',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BFormRadio,
    flatPickr,
    quillEditor,
    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      task_data: {
        title: '',
        vedor_name: '',
      },
      addJob: {
        job_title: '',
        job_id: '',
        city_id: null,
        assigned_user_ids: [],
        coordinator: '',
        immediate_need: false,
        position_type_id: null,
        visa_type_ids: null,
        facility_id: null,
        facility_bill_rate: '',
        description: '',
        status_id: null,
        contract_bill_rate: '',
        skill_ids: null,
        start_date: null,
        end_date: null,
        shift_days: 0,
        shift_hours: 0,
        shift_start_time: null,
        experience_years: null,
        duration_id: null,
        technologies: [],
        tasks: [],
        user_defined_fields: [],
        criteria_ids: [],
        criterias: [],
        job_start_times: [],
      },
      immediate: 'Yes',
      start_date_config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'm-d-Y',
        altInput: true,
        dateFormat: 'm-d-Y',
        minDate: null,
        maxDate: null,
      },
      end_date_config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'm-d-Y',
        altInput: true,
        dateFormat: 'm-d-Y',
        minDate: null,
        maxDate: null,
      },
      shiftTimeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'h:i K',
      },
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      technologies: [],
      visaTypes: [],
      types: [],
      selectedSkills: null,
      selectedVisaTypes: null,
      start_date: null,
      end_date: null,
      shift_start_time: null,
      facilities: [],
      status: [],
      endClients: [],
      durations: [],
      skills: [],
      companies: [],
      processes: [],
      criterias: [],
      roleUsers: [],
      locations: [],
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getFacilitiesFullList(this)
        LIST.getPositionTypeFullList(this)
        LIST.getSkillsFullList(this)
        LIST.getVisaFullList(this)
        LIST.getDurationFullList(this)
        LIST.getEndClientsFullList(this)
        LIST.getCriterias(this)
        LIST.getRollUsers(this)
      },
    },
    '$store.state.lists.hrLists': {
      immediate: true,
      handler() {
        LIST.getJobStatusFullList(this)
      },
    },
  },
  mounted() {
  },
  created() {
    LIST.getFacilitiesFullList(this)
    LIST.getPositionTypeFullList(this)
    LIST.getSkillsFullList(this)
    LIST.getVisaFullList(this)
    LIST.getDurationFullList(this)
    LIST.getJobStatusFullList(this)
    LIST.getEndClientsFullList(this)
    LIST.getCriterias(this)
    LIST.getRollUsers(this)
    Job.getJobById(this, this.$route.params.id)
    Process.getProcessFullList(this)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          if (this.start_date != null) {
            this.addJob.start_date = Constants.getEprmMonthDateFromString(this.start_date)
          } else {
            this.addJob.start_date = null
          }
          if (this.end_date != null) {
            this.addJob.end_date = Constants.getEprmMonthDateFromString(this.end_date)
          } else {
            this.addJob.end_date = null
          }
          this.addJob.immediate_need = this.immediate === 'Yes'
          this.showLoading = true
          Job.addJob(this, this.addJob)
        }
      })
    },
    setRollUsers(rollUsers) {
      this.roleUsers = rollUsers
    },
    removeStartDate() {
      this.start_date = null
    },
    removeEndDate() {
      this.end_date = null
    },
    removeJobStartTime() {
      this.addJob.time = null
    },
    setCriterias(criterias) {
      this.criterias = criterias
    },
    criteriaChecked(id) {
      if (this.addJob.criteria_ids.includes(id)) {
        this.addJob.criteria_ids.splice(this.addJob.criteria_ids.indexOf(id), 1)
      } else {
        this.addJob.criteria_ids.push(id)
      }
    },
    criteriaSelected(id) {
      return this.addJob.criteria_ids.includes(id)
    },
    checked(id) {
      if (this.addJob.technologies.includes(id)) {
        this.addJob.technologies.splice(this.addJob.technologies.indexOf(id), 1)
      } else {
        this.addJob.technologies.push(id)
      }
    },
    technologySelected(id) {
      return this.addJob.job_technology_ids.includes(id)
    },
    setTechnologies(technologies) {
      this.technologies = technologies
    },
    setProcess(processes) {
      this.processes = processes
    },
    addCriteria() {
      const criteria = {
        criteria_name: '',
      }
      this.addJob.criterias.push(criteria)
    },
    removeCriteria(index) {
      this.addJob.criterias.splice(index, 1)
    },
    skillChanged() {
      if (this.addJob.skill_ids.length !== 0) {
        Technology.getTechnologiesBySkillIds(this, this.addJob.skill_ids)
        this.addJob.technologies = []
        this.technologies = []
      }
    },
    setEndClients(endClients) {
      this.endClients = endClients
    },
    setFacilities(facilities) {
      this.facilities = facilities
    },
    setStatus(status) {
      this.status = status
    },
    setPositionTypes(types) {
      this.types = types
    },
    setVisaTypes(visaTypes) {
      this.visaTypes = visaTypes
    },
    setSkills(skills) {
      this.skills = skills
    },
    setDuration(duration) {
      this.durations = duration
    },
    setLocations(locations) {
      this.locations = locations
    },
    visaTypeSelected(visaTypes) {
      this.addJob.visa_type_ids = []
      for (let i = 0; i < visaTypes.length; i += 1) {
        this.addJob.visa_type_ids.push(this.visaTypes[i].id)
      }
      this.visaTypeChanges()
    },
    visaTypeChanges() {
      if (this.addJob.visa_type_ids.length === 0) {
        this.addJob.visa_type_ids = null
        this.selectedVisaTypes = null
      }
    },
    onskillSelected(skills) {
      this.addJob.skills = []
      for (let i = 0; i < skills.length; i += 1) {
        this.addJob.skills.push(this.skills[i].id)
      }
      this.skillsChanges()
    },
    skillsChanges() {
      if (this.addJob.skills.length === 0) {
        this.addJob.skills = null
        this.selectedSkills = null
      }
    },
    startDateChanged() {
      this.end_date_config.minDate = new Date(moment(this.start_date, 'MM-DD-YYYY'))
    },
    endDateChanged() {
      this.start_date_config.maxDate = new Date(moment(this.end_date, 'MM-DD-YYYY'))
    },
    jobAdded(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    jobNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    setJob(addJob) {
      this.addJob = addJob
      this.start_date = addJob.start_date
      this.end_date = addJob.end_date
      this.addJob.criterias = []
      this.selectedVisaTypes = addJob.visa_types
      this.selectedSkills = addJob.skills
      this.addJob.skills = addJob.skill_ids
      this.$store.state.taskDetails.title = addJob.job_title
      this.$store.state.taskDetails.vedor_name = addJob.facility
      this.$store.state.taskDetails.type = 'job'
      this.$store.state.taskDetails.task_type = 'job'
      this.addJob.technologies = addJob.job_technology_ids
      Technology.getTechnologiesBySkillIds(this, this.addJob.skill_ids)
      if (addJob.immediate_need) {
        this.immediate = 'Yes'
      } else {
        this.immediate = 'No'
      }
      this.end_date_config.minDate = new Date(moment(this.start_date, 'MM-DD-YYYY'))
      this.start_date_config.maxDate = new Date(moment(this.end_date, 'MM-DD-YYYY'))
      LIST.getLocations(this, this.addJob.state_id)
    },
    addShiftTime() {
      const jobStartTime = {
        time: null,
      }
      this.addJob.job_start_times.push(jobStartTime)
    },
    removeShiftTime(index) {
      this.addJob.job_start_times.splice(index, 1)
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
