import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.hrurl
const quickProspect = `${HOST}quick-prospect`

export default {
  getProspects(context1, skillId) {
    const context = context1
    const params = { params: { query: context.searchQuery, page: context.currentPage - 1, skill_id: skillId } }
    axios.get(quickProspect, params).then(response => {
      context.setProspects(response.data)
    }, () => {
    })
  },
  getProspectByEmail(context1, email) {
    const context = context1
    const params = { params: { email } }
    axios.get(`${quickProspect}/verify`, params).then(response => {
      context.setProspectNotExist(response.data)
    }, error => {
      context.setProspectExist(error.response.data.id)
    })
  },
  getEmailsBySkill(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${quickProspect}/emails`, body).then(response => {
      context.$vs.loading.close()
      context.emailsFetched(response.data)
    }, error => {
      context.$vs.loading.close()
      context.emailsNotFetched(error.response.data.return_message)
    })
  },
  addProspect(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(quickProspect, body).then(response => {
      context.$vs.loading.close()
      context.prospectAdded(response.data.return_message)
    }, error => {
      context.$vs.loading.close()
      context.prospectNotAdded(error.response.data.return_message)
    })
  },
  updateProspect(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${quickProspect}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.prospectUpdated(response.data.return_message)
    }, error => {
      context.$vs.loading.close()
      context.prospectNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${quickProspect}/${id}`).then(response => {
      context.$vs.loading.close()
      context.prospectDeleted(response.data.return_message)
    }, error => {
      context.$vs.loading.close()
      context.prospectNotDeleted(error.response.data.return_message)
    })
  },
  getProspectById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${quickProspect}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setProspect(response.data)
    }, error => {
      context.$vs.loading.close()
      context.setProspectNotFound(error.response.data.return_message)
    })
  },
  importProspects(context1, file, skillIds) {
    const context = context1
    context.$vs.loading()
    const params = { params: { skill_ids: skillIds } }
    const formData = new FormData()
    formData.append('csv', file)
    axios.post(`${quickProspect}/import_prospects`, formData, params).then(response => {
      context.$vs.loading.close()
      context.prospectsAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.prospectsNotAdded(error.response.data.return_message)
    })
  },
  optOutProspect(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.put(`${quickProspect}/${id}/opt-out`).then(response => {
      context.$vs.loading.close()
      context.prospectUpdated(response.data.return_message)
    }, error => {
      context.$vs.loading.close()
      context.prospectNotUpdated(error.response.data.return_message)
    })
  },
}
