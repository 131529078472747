<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Edit Required Document</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer
      ref="documentRules"
      tag="form"
    >
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Parent Details</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <dl class="row">
                  <dt class="col-sm-12 dt-text">
                    Parent Type
                  </dt>
                  <dd class="col-sm-12">
                    <span
                      class="form-control h-auto"
                      v-text="addRequiredDocument.parent_type"
                    />
                  </dd>
                </dl>
              </b-col>
              <!-- <b-col
                cols="12"
                md="6"
                lg="4"
                v-show="addRequiredDocument.workforce !== null"
              >
                <dl class="row">
                  <dt class="col-sm-12 dt-text">
                    Parent
                  </dt>
                  <dd class="col-sm-12">
                    <span
                      class="form-control h-auto"
                      v-text="addRequiredDocument.workforce"
                    />
                  </dd>
                </dl>
              </b-col> -->
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Required Document Details</h6>
            </div>

            <div class="repeat-border">
              <div
                class="d-flex"
              >
                <div class="left w-100">
                  <b-row>
                    <b-col
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <dl class="row">
                        <dt class="col-sm-12 dt-text">
                          Document Type
                        </dt>
                        <dd class="col-sm-12">
                          <span
                            class="form-control h-auto"
                            v-text="addRequiredDocument.document_type"
                          />
                        </dd>
                      </dl>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <b-form-group>
                        <label>Required Document Names</label>
                        <validation-provider
                          v-slot="{ errors }"
                          name="Required Document Names"
                        >
                          <div class="d-flex">
                            <div class="left w-100">
                              <v-select
                                v-model="addRequiredDocument.required_document_name_ids"
                                :options="documentNames"
                                :reduce="item => item.id"
                                :clearable="false"
                                placeholder="Select"
                                multiple
                                @input="requiredDocumentNameChanged()"
                              />
                              <small
                                class="text-danger"
                                v-text="errors[0] !== undefined ? errors[0] : ''"
                              />
                            </div>
                          </div>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <b-form-group>
                        <label>Optional Document Names</label>
                        <validation-provider
                          v-slot="{ errors }"
                          name="'Optional Document Names"
                        >
                          <div class="d-flex">
                            <div class="left w-100">
                              <v-select
                                v-model="addRequiredDocument.optional_document_name_ids"
                                :options="documentNames"
                                :reduce="item => item.id"
                                :clearable="false"
                                placeholder="Select"
                                multiple
                                @input="optionalDocumentNameChanged()"
                              />
                              <small
                                class="text-danger"
                                v-text="errors[0] !== undefined ? errors[0] : ''"
                              />
                            </div>
                          </div>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <b-form-group>
                        <label>Optional Min No.of</label>
                        <validation-provider
                          v-slot="{ errors }"
                          name="Optional Min"
                          rules="required|integer"
                        >
                          <b-form-input
                            v-model="addRequiredDocument.optional_document_count"
                          />
                          <small
                            class="text-danger"
                            v-text="errors[0] !== undefined ? errors[0] : ''"
                          />
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </app-timeline-item>

        </app-timeline>
        <hr>

        <b-row class="mt-1">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Update Required Document
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import {
  BCard, BRow, BCol, VBModal, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import LIST from '@/js/lists'
import ParentDocumentrequired from '@/js/parentDocumentrequired'

export default {
  name: 'DocumentTrackerNew',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    // currency,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      addRequiredDocument: {
        parent_type: null,
        document_type_id: null,
        workforce: null,
        document_types: [],
        optional_document_count: 0,
      },
      documentNames: [],
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getDocumentNames(this, this.addRequiredDocument.document_type_id)
      },
    },
  },
  created() {
    ParentDocumentrequired.getDocumentRequiredById(this, this.$route.params.id)
  },
  methods: {
    submitForm() {
      this.$refs.documentRules.validate().then(result => {
        if (result) {
          ParentDocumentrequired.updateDocumentRequired(this, this.$route.params.id, this.addRequiredDocument)
        }
      })
    },
    documentRequiredUpdated(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    documentRequiredNotUpdated(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    requiredDocumentNameChanged() {
      this.addRequiredDocument.optional_document_name_ids = this.addRequiredDocument.optional_document_name_ids.filter(el => !this.addRequiredDocument.required_document_name_ids.includes(el))
    },
    optionalDocumentNameChanged() {
      this.addRequiredDocument.required_document_name_ids = this.addRequiredDocument.required_document_name_ids.filter(el => !this.addRequiredDocument.optional_document_name_ids.includes(el))
    },
    setDocumentNames(documentNames) {
      this.documentNames = documentNames
    },
    setDocumentRequired(requiredDocument) {
      this.addRequiredDocument = requiredDocument
      LIST.getDocumentNames(this, this.addRequiredDocument.document_type_id)
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
