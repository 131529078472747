import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.faurl
const statement = `${HOST}statement`

export default {
  getStatements(context1) {
    const context = context1
    const params = { params: { query: context.$store.state.query.query, page: context.$store.state.query.page - 1 } }
    axios.get(statement, params).then(response => {
      context.setStatements(response.data)
    }, () => {
    })
  },
}
