import axios from 'axios'
import Lists from '@/js/lists'
import Constants from '@/js/constant'

const HOST = Constants.hosts.adurl
const roleUser = `${HOST}role-user`

export default {
  getRoleusers(context1) {
    const context = context1
    let params = { query: context.searchQuery, page: context.currentPage - 1 }
    if (params !== {}) {
      params = { params: { query: context.searchQuery, page: context.currentPage - 1 } }
    } else {
      params = { params: {} }
    }
    axios.get(roleUser, params).then(response => {
      context.setRoleusers(response.data)
    }, () => {
    })
  },
  addRoleuser(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(roleUser, body).then(response => {
      context.$vs.loading.close()
      context.roleUserAdded(response.data)
      Lists.getAllList(context)
    }, error => {
      context.$vs.loading.close()
      context.roleUserNotAdded(error.response.data.return_message)
    })
  },
  updateRoleuser(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${roleUser}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.roleUserUpdated(response.data)
      Lists.getAllList(context)
    }, error => {
      context.$vs.loading.close()
      context.roleUserNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${roleUser}/${id}`).then(response => {
      context.$vs.loading.close()
      context.roleUserDeleted(response.data.return_message)
      Lists.getAllList(context)
    }, error => {
      context.$vs.loading.close()
      context.roleUserNotDeleted(error.response.data.return_message)
    })
  },
  getRoleuserById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${roleUser}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setRoleuser(response.data)
    }, error => {
      context.$vs.loading.close()
      context.roleUserNotFound(error.response.data.return_message)
    })
  },

}
