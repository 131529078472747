import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.url
const parentDocumentRequired = `${HOST}parent-document-required`

export default {
  getParentDocumentRequireds(context1) {
    const context = context1
    const params = {
      params: {
        query: context.searchQuery,
        page: context.currentPage - 1,
        parent_type_id: context.parent_type_id,
      },
    }
    axios.get(parentDocumentRequired, params).then(response => {
      context.setRequiredDocuments(response.data)
    }, () => {
    })
  },
  addRequiredDocument(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(parentDocumentRequired, body).then(response => {
      context.$vs.loading.close()
      context.documentRequiredAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.documentRequiredNotAdded(error.response.data.return_message)
    })
  },
  updateDocumentRequired(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${parentDocumentRequired}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.documentRequiredUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.documentRequiredNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${parentDocumentRequired}/${id}`).then(response => {
      context.$vs.loading.close()
      context.documentRequiredDeleted(response.data)
    }, error => {
      context.$vs.loading.close()
      context.documentRequiredNotDeleted(error.response.data.return_message)
    })
  },
  getDocumentRequiredById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${parentDocumentRequired}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setDocumentRequired(response.data)
    }, () => {
      context.$vs.loading.close()
      context.back()
    })
  },
}
