<template>
  <b-card
    class="mb-0"
  >
    <div>
      <div class="mb-0">
        <b-row class="mb-1">
          <b-col
            cols="12"
            md="6"
          >
            <h4 class="card-title mb-0 pt-50">
              Jobs By Bill Rate Descending Without Prospects</h4>
          </b-col>
          <b-col
            cols="12"
            md="6 ml-auto"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-group class="w-50 mb-sm-0 mr-2">
                <v-select
                  id="vendor_id"
                  v-model="vendor_id"
                  :options="vendors"
                  :reduce="item => item.id"
                  :clearable="true"
                  placeholder="Vendor"
                  @input="onVendorTypeChanged($event)"
                />
              </b-form-group>
              <b-form-group class="w-50 mb-sm-0 mr-2">
                <v-select
                  id="source_id"
                  v-model="sourceTypeIds"
                  :options="sourceTypes"
                  :reduce="item => item.id"
                  :clearable="false"
                  multiple
                  placeholder="Select Source"
                  @input="onTypeChanged($event)"
                  />
              </b-form-group>
              <b-button
                variant="outline-secondary"
                @click="back"
              >
                Back
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <vue-good-table
        :columns="columns"
        :rows="reports.job_prospects"
        :rtl="direction"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Currency -->
          <span v-if="props.column.field === 'cellRendererCurrency'">
            <span>{{ props.row.rate| currency('$ ', 2, { thousandsSeparator: ',' }) }} </span>
          </span>
          <!-- Column: JOb I -->
          <span v-if="props.column.field === 'job_id'">
            <b-link v-if="props.row.job_path !== null" :href="props.row.job_path" target="_blank">
              <img
                class="mr-25 mb-25 img-fluid"
                width="24"
                height="24"
                :src="require('@/assets/images/icons/stafferlink.png')"
                alt="image slot"
              >
            </b-link>
            <b-link :to="{ name: 'forecasting-reports-jobs-no-prospects-view', params: { id: props.row.id } }">
              {{ props.row.job_id }}
            </b-link>
            <span>
              <img
                v-if="props.row.is_urgent"
                class="ml-50 img-fluid"
                width="24"
                height="24"
                :src="require('@/assets/images/Megaphone.gif')"
                alt="image slot"
              >
              <b-link
                v-b-tooltip.hover.v-primary
                :title="props.row.advertised_description"
                :href="props.row.advertised_link"
                target="blank"
              >
                <img
                  v-show="props.row.is_advertised"
                  class="ml-50 img-fluid"
                  width="24"
                  height="24"
                  :src="require('@/assets/images/icons/advertise.png')"
                  alt="image slot"
                >
              </b-link>
            </span>
          </span>
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
          <!-- pagination -->
          <template
            slot="pagination-bottom"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <div>
                  <img
                    class="mlr50 img-fluid"
                    width="24"
                    height="24"
                    :src="require('@/assets/images/icons/megaphone.png')"
                    alt="image slot"
                  >
                  <span class="ml-50">Urgent Job</span>
                </div>
                <div class="ml-2">
                  <img
                    class="mlr50 img-fluid"
                    width="24"
                    height="24"
                    :src="require('@/assets/images/icons/advertise.png')"
                    alt="image slot"
                  >
                  <span class="ml-50">Added Advertised</span>
                </div>
              </div>
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="reports.count"
                  first-number
                  last-number
                  align="right"
                  :per-page="1"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>

      </vue-good-table>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BFormGroup, BPagination, BLink, VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import store from '@/store/index'
import Reports from '@/js/reports'
import {
  required,
} from '@validations'
import LIST from '@/js/lists'

export default {
  name: 'JobsByPriorityReport',
  components: {
    BCard,
    BRow,
    BFormGroup,
    BCol,
    VueGoodTable,
    BLink,
    BButton,
    vSelect,
    BPagination,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      required,
      report: {},
      reports: {
      },
      pageLength: 10,
      vendor_id: null,
      vendors: [],
      sourceTypeIds: [],
      sourceTypes: [],
      columns: [
        {
          label: 'Job Id',
          field: 'job_id',
          width: '120px',
          sortable: false,
        },
        {
          label: 'Title',
          field: 'title',
          width: '90px',
          sortable: false,
        },
        {
          label: 'State',
          field: 'state',
          sortable: false,
        },
        {
          label: 'City',
          field: 'city',
          sortable: false,
        },
        {
          label: 'Rate',
          field: 'cellRendererCurrency',
          width: '85px',
          sortable: false,
        },
        {
          label: 'Facility',
          field: 'facility',
          sortable: false,
        },
        {
          label: 'Vendor',
          field: 'vendor',
          sortable: false,
        },
        {
          label: 'Entry Date',
          field: 'entry_date',
          width: '120px',
          sortable: false,
        },
        {
          label: 'Duration',
          field: 'duration',
          sortable: false,
        },
        {
          label: 'Guaranteed Hours',
          field: 'guaranteed_hours',
          sortable: false,
        },
      ],
      rows: [],
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
        Reports.getJobNoProspectsReport(this, this.sourceTypeIds, this.vendor_id)
      },
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    '$store.state.lists.commonLists.lists.vendors': {
      immediate: true,
      handler() {
        LIST.getVendors(this)
      },
    },
    '$store.state.lists.commonLists.lists.source_types': {
      immediate: true,
      handler() {
        LIST.getSourceTypes(this)
      },
    },
  },
  created() {
    this.sourceTypeIds = this.$store.state.query.sourceTypeIds
    this.vendor_id = this.$store.state.query.vendor_id
    this.reports.count = this.$store.state.query.page
    Reports.getJobNoProspectsReport(this, this.sourceTypeIds, this.vendor_id)
    LIST.getSourceTypes(this)
    LIST.getVendors(this)
  },
  methods: {
    onVendorTypeChanged() {
      this.$store.state.query.vendor_id = this.vendor_id
      this.currentPage = 1
    },
    onTypeChanged() {
      this.$store.state.query.sourceTypeIds = this.sourceTypeIds
      this.currentPage = 1
    },
    setReport(reports) {
      this.reports = reports
    },
    setVendors(vendors) {
      this.vendors = vendors
    },
    setSourceTypes(sourceTypes) {
      this.sourceTypes = sourceTypes
    },
    back() {
      this.$router.push('/forecasting/reports').catch(() => {})
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
