<template>
  <div class="all-prospects-application">
    <b-card
      no-body
      class="mb-1"
    >
      <prospect-matcher-filter-top ref="loadTop" @copyText="copyText"  @searchUpdated="searchUpdated" @skillChanged="skillChanged" @stateChanged="stateChanged"/>
      <div
        class="position-relative"
        style="height: inherit;"
      >
        <div class="email-app-list email-app-list-custom">
          <vue-good-table
            :columns="columns"
            :rows="prospectsResponse.prospects"
            :rtl="direction"
            ref="vuetable"
            @on-selected-rows-change="selectionChanged"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :select-options="{
              enabled: true,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
              mode: 'records',
              perPageDropdownEnabled: true
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Communication -->
              <span v-if="props.column.field === 'cellRendererCommunication'">
                <b-link
                  v-b-tooltip.hover
                  title="mail"
                  :class="props.row.mails.length === 0 ? 'notes-icon mr-1' : 'mr-1 notes-icon active'"
                  @click="showMails(props.row)"
                >
                  <feather-icon
                    icon="MailIcon"
                    size="16"
                  />
                </b-link>
                <b-link
                  v-b-tooltip.hover
                  title="message"
                  class="mr-1"
                >
                  <feather-icon
                    icon="MessageCircleIcon"
                    size="16"
                  />
                </b-link>
                <b-link
                  v-b-tooltip.hover
                  title="call"
                  class="mr-1"
                >
                  <feather-icon
                    icon="PhoneIcon"
                    size="16"
                  />
                </b-link>
                <b-link
                  v-b-tooltip.hover
                  title="reminder"
                  class="mr-1"
                >
                  <feather-icon
                    icon="MessageSquareIcon"
                    size="16"
                  />
                </b-link>
                <b-link
                  v-b-tooltip.hover
                  title="notes"
                  id="notes"
                  :class="props.row.comments.length === 0 ? 'notes-icon mr-1' : 'notes-icon active mr-1'"
                  @click="showNotes(props.row.id)"
                >
                  <feather-icon
                    icon="FileTextIcon"
                    size="16"
                  />
                </b-link>
                <b-link
                  v-b-tooltip.hover
                  :title="props.row.is_stopped ? `Click to contact activate` : `Click to don't contact`"
                  :class="props.row.is_stopped ? 'text-danger' : 'text-primary'"
                  @click="stopProspect(props.row.id, props.row.is_stopped, props.row.name)"
                >
                  <feather-icon
                    icon="ThumbsDownIcon"
                    size="16"
                  />
                </b-link>
              </span>

              <!-- Column: Name -->
              <span v-else-if="props.column.field === 'cellRendererName'">
                <b-link @click="updateProspectViewData(props.row)">
                  {{ props.row.name }}
                </b-link>
              </span>

              <!-- Column: Email -->
              <span v-else-if="props.column.field === 'cellRendererEmail'">
                <p
                  v-b-tooltip.hover
                  :title='props.row.email'
                  v-b-tooltip.hover.v-primary
                  class="content-ellips"
                >{{ props.row.email }}</p>
              </span>

              <!-- Column: Action -->
              <span
                v-else-if="props.column.field === 'action'"
                class="btn-action"
              >
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item
                      id="edit"
                      :to="{ name: 'human-resources-prospects-admin-prospects-edit', params: { id: props.row.id } }"
                    >
                      <feather-icon icon="Edit2Icon" />
                      <span class="ml-50">Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      id="delete"
                      @click="confirmDeleteRecord(props.row)"
                    >
                      <feather-icon icon="TrashIcon" />
                      <span class="ml-50">Delete</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      id="add_invitation"
                      v-show="props.row.invitation_id === null"
                      @click="sendProfileInvitation(props.row)"
                    >
                      <feather-icon icon="SendIcon" />
                      <span class="ml-50">Send Profile Invitation</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      id="add_invitation"
                      v-show="props.row.invitation_id !== null"
                      @click="resendProfileInvitation(props.row)"
                    >
                      <feather-icon icon="SendIcon" />
                      <span class="ml-50">Re-send Profile Invitation</span>
                    </b-dropdown-item>

                  </b-dropdown>
                  <b-link
                    :to="`/documents/document-tracker/new?parent_id=${props.row.id}&parent_type_id=${props.row.parent_type_id}`"
                    v-b-tooltip.hover
                    title="Documents"
                    class="mr-1"
                  >
                    <feather-icon
                      icon="FileIcon"
                      size="16"
                    />
                  </b-link>
                  <!-- <b-link
                    id="view"
                    :to="{ name: 'human-resources-prospects-view', params: { id: props.row.id } }"
                  >
                    <b-badge
                      pill
                      variant="light-primary"
                    ><feather-icon icon="EyeIcon" />
                    </b-badge>
                  </b-link> -->
                </span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
              >
                <div class="d-flex justify-content-end flex-wrap">
                  <div class="d-flex align-items-center justify-content-end mr-4">
                    <strong>Total Emails: {{ emails_count }} / {{ totalProspects }}</strong>
                    <b-button
                      v-show="emails_count > 0"
                      variant="primary"
                      size="sm"
                      class="ml-2"
                      @click="copyText"
                    >
                      Copy Emails
                    </b-button>
                  </div>
                  <div class="mr-2">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="prospectsResponse.count"
                      first-number
                      last-number
                      align="right"
                      :per-page="1"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-50 mb-50"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
          </vue-good-table>
        </div>
        <b-modal
          ref="add_notes"
          cancel-variant="outline-secondary"
          ok-title="Add"
          cancel-title="Close"
          centered
          title="Add Notes"
          size="lg"
          :no-close-on-backdrop="true"
          @hide="closingNotesPopup"
        >
          <b-form>
            <div class="previous-notes">
              <div class="single-note" v-for="(commentItem, index) in userComments" :id="index" :key="index" >
                <div class="note-author">
                  <p v-html="commentItem.commentUserText"></p>
                </div>
              </div>
            </div>
          </b-form>
          <template #modal-footer>
            <div class="chat-app-window chat-app-window-custom w-100">
              <div class="chat-app-form">
                <b-input-group class="input-group-merge form-send-message mr-1">
                  <b-form-textarea
                    v-model="addNotesRequest.comment"
                    placeholder="Enter your notes"
                  />
                </b-input-group>
                <b-button
                  variant="primary"
                  type="submit"
                  @click="saveNote()"
                >
                  Send
                </b-button>
              </div>
            </div>
          </template>
        </b-modal>

        <b-modal
          ref="sent_mails"
          cancel-variant="outline-secondary"
          ok-title="Ok"
          cancel-title="Close"
          centered
          title="Recent Mails Sent On"
          size="lg"
          :no-close-on-backdrop="true"
          @hide="closeSentMailsPopup"
        >
          <template #modal-header>
            <div class="d-flex justify-content-between align-items-center w-100">
              <h5 class="modal-title">Recent Mails Sent On</h5>
              <b-button
                variant="primary"
                type="submit"
                class="mr-1"
                v-show="!addNewMailSent"
                @click="showNewMailSent"
              >
                Add
              </b-button>
            </div>
            <button type="button" @click="closeSentMailsPopup" aria-label="Close" class="close">×</button>
          </template>
          <validation-observer v-if="addNewMailSent" ref="mailSimpleRules">
            <b-form>
              <b-row>
                <b-col cols="4">
                  <b-form-group>
                    <label>Job Id</label>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Job Id"
                      rules="integer"
                    >
                      <b-form-input
                        id="job_id"
                        v-model="addSentMail.job_id"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group>
                    <label>Sent Date</label>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Sent Date"
                      rules="required"
                    >
                      <flat-pickr
                        v-model="sent_date"
                        class="form-control"
                        :config="startDateConfig"
                        placeholder="MM-DD-YYYY"
                        :clear-button="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group>
                    <label>Sent Time</label>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Sent Time"
                      rules="required"
                    >
                      <flat-pickr
                        v-model="addSentMail.time"
                        class="form-control"
                        :config="startTimeConfig"
                        :placeholder="'00:00'"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group>
                    <label>Decription</label>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Decription"
                      rules="required"
                    >
                      <b-form-textarea
                        v-model="addSentMail.description"
                        rows="2"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>

              <b-row class="mb-1">
                <b-col>
                </b-col>
                <b-col class="text-right">
                  <b-button
                    variant="outline-secondary"
                    type="button"
                    class="mr-1"
                    @click="closeSentMail"
                  >
                    Cancel
                  </b-button>
                  <b-button
                    variant="primary"
                    type="button"
                    @click="mailSentSubmit"
                  >
                    Save
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
          <b-table
            bordered
            hover
            responsive
            class="custom-table-details"
            :items="sentMails"
            :fields="fields"
          />
          <template #modal-footer>
            <b-button
              variant="outline-secondary"
              type="button"
              @click="closeSentMailsPopup"
            >
              Close
            </b-button>
          </template>
        </b-modal>

        <job-prospect-matcher-right-sidebar
          ref="loadRight"
          @prospectDeleted="prospectDeleted"
          :class="{'show': showProspectDetails}"
          @close-prospect-view="showProspectDetails = false"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  ref,
} from '@vue/composition-api'
import {
  BCard, BForm, BRow, BCol, BPagination, BTable, BLink, VBTooltip, BModal, VBModal, BFormGroup, BInputGroup, BFormInput, BFormTextarea, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { VueGoodTable } from 'vue-good-table'
import ProspectMatcher from '@/js/prospectmatcher'
import Prospects from '@/js/prospect'
import ParentComments from '@/js/parentcomments'
import Parentmailsent from '@/js/parentmailsent'
import ProfileInvitations from '@/js/profileinvitations'
import store from '@/store/index'
import Constants from '@/js/constant'
import moment from 'moment'
import JobProspectMatcherRightSidebar from './JobProspectMatcherRightSidebar.vue'
import ProspectMatcherFilterTop from './ProspectMatcherFilterTop.vue'

export default {
  name: 'AppProspectMatcher',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BPagination,
    BTable,
    BLink,
    BModal,
    BDropdown,
    BDropdownItem,
    BButton,
    JobProspectMatcherRightSidebar,
    ProspectMatcherFilterTop,
    VueGoodTable,
    flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  setup() {
    const isTaskHandlerSidebarActive = ref(false)
    return {
      isTaskHandlerSidebarActive,
    }
  },
  data() {
    return {
      showProspectDetails: false,
      skills: [],
      userComments: [],
      pageLength: 500,
      setCurrentPage: 1,
      totalProspects: 0,
      emails_count: 0,
      dir: false,
      showLeft: true,
      prospectId: null,
      sourceType: null,
      sentMails: [],
      addSentMail: {
        job_id: '',
        user_id: null,
        description: '',
        date: null,
        time: null,
      },
      prospectsResponse: {
        prospects: [],
        count: 1,
      },
      searchQuery: '',
      selectedRows: [],
      city: '',
      query: {},
      columns: [
        {
          label: 'Name',
          field: 'cellRendererName',
          thClass: 'th-width-mx-160',
          sortable: false,
        },
        {
          label: 'Email',
          field: 'cellRendererEmail',
          sortable: false,
        },
        {
          label: 'Phone',
          field: 'phone',
          width: '120px',
          sortable: false,
        },
        {
          label: 'Source Type',
          field: 'source_type',
          sortable: false,
        },
        {
          label: 'Applied Date',
          field: 'last_applied_date',
          sortable: false,
        },
        {
          label: 'Communication',
          field: 'cellRendererCommunication',
          thClass: 'th-mw-195',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          width: '130px',
          sortable: false,
        },
      ],
      fields: [
        { key: 'job_id', label: 'Job Id' },
        { key: 'skill', label: 'Skill' },
        { key: 'location', label: 'Location' },
        { key: 'description', label: 'Description' },
        { key: 'type', label: 'Type' },
        { key: 'date', label: 'Sent Date', class: 'thMwid110' },
        { key: 'time', label: 'Sent Time', class: 'thMwid110' },
      ],
      addNotesRequest: {
        comment: '',
        user_id: null,
        date: null,
        time: null,
      },
      startDateConfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'm-d-Y',
        altInput: true,
        dateFormat: 'm-d-Y',
        minDate: null,
        maxDate: null,
      },
      startTimeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'h:i K',
      },
      addNewMailSent: false,
      rows: [],
      searchTerm: '',
      start_date_id: null,
      start_time_id: null,
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
        ProspectMatcher.getProspects(this, this.query, this.$store.state.query.page - 1, this.searchQuery)
      },
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.sourceId = this.$route.query.source_id
    this.sourceType = this.$route.query.source_type
    this.searchQuery = this.$store.state.query.query
    this.skillId = this.$route.query.skill_id
    this.stateId = this.$route.query.state_id
    this.city = this.$route.query.city
    this.query = '?is_all=true'
    if (this.sourceType !== null && this.sourceType !== undefined) {
      this.query = `${this.query}&source_type=${this.sourceType}`
    }
    if (this.sourceId !== null && this.sourceId !== undefined) {
      this.query = `${this.query}&source_id=${this.sourceId}`
    }
    if (this.skillId !== null && this.skillId !== undefined) {
      this.query = `${this.query}&skill_id=${this.skillId}`
    }
    if (this.stateId !== null && this.stateId !== undefined) {
      this.query = `${this.query}&state_id=${this.stateId}`
    }
    if (this.city !== null && this.city !== undefined) {
      this.query = `${this.query}&city=${this.city}`
    }
    this.showLeft = false
    if (this.$store.state.prospects.isProspectsLoaded) {
      this.$nextTick(() => {
        this.setProspectsFromStore(this.$store.state.prospects.prospectsResponse)
      })
    } else {
      this.currentPage = 1
    }
  },
  destroyed() {
  },
  methods: {
    sendProfileInvitation(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to send profile invitation to this ${obj.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, send it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          ProfileInvitations.sendInvitationToProspect(this, obj.id)
        }
      })
    },
    resendProfileInvitation(obj) {
      this.$swal({
        title: 'Invitation already exist',
        text: `do you wan't to re-send it to ${obj.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, re-send it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          ProfileInvitations.resendEmail(this, obj.invitation_id)
        }
      })
    },
    confirmDeleteRecord(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${obj.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          Prospects.delete(this, obj.id, obj.index)
        }
      })
    },
    selectionChanged(params) {
      const prospects = params.selectedRows
      this.selectedprospects = []
      for (let i = 0; i < prospects.length; i += 1) {
        if (prospects[i].email !== 'NA') {
          if (!this.selectedprospects.includes(prospects[i].email)) {
            this.selectedprospects.push(prospects[i].email)
          }
        }
      }
      this.emails_count = this.selectedprospects.length
      this.$refs.loadTop.totalProspects = this.prospectsResponse.prospects.length
      this.totalProspects = this.prospectsResponse.prospects.length
      this.$refs.loadTop.emails_count = this.emails_count
      navigator.clipboard.writeText(this.selectedprospects.toString().replaceAll(',', '; '))
    },
    invitationAdded(data) {
      this.setProspectsFromStore(this.$store.state.prospects.prospectsResponse)
      this.$refs.showLoading = false
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    invitationNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    resendEmailResponse(msg) {
      this.$swal({
        icon: 'success',
        title: 'Resend',
        text: msg,
      })
    },
    resendEmailResponseFail(msg) {
      this.$swal({
        icon: 'warning',
        title: 'Resend',
        text: msg,
      })
    },
    copyText() {
      this.$bvToast.toast(`${this.emails_count} emails copied`, {
        title: 'Copied!',
        variant: 'primary',
        solid: true,
        toaster: 'b-toaster-bottom-right',
      })
      navigator.clipboard.writeText(this.selectedprospects.toString().replaceAll(',', '; '))
    },
    mailSentSubmit() {
      this.$refs.mailSimpleRules.validate().then(result => {
        if (result) {
          this.addSentMail.date = Constants.getEprmMonthDateFromString(this.sent_date)
          Parentmailsent.addMailSent(this, this.addSentMail)
        }
      })
    },
    sentMailNotAdded(message) {
      this.$swal({
        icon: 'warning',
        title: 'Mail Sent Not Added!',
        text: message,
      })
    },
    setMailSents(data, id) {
      this.prospectsResponse.prospects = this.prospectsResponse.prospects.map(prospect => {
        if (prospect.id === id) {
          return { ...prospect, mails: data.mails }
        }
        return prospect
      })
      this.sentMails = data.mails
      this.addSentMail = {
        job_id: '',
        user_id: null,
        description: '',
        date: null,
        time: null,
      }
      this.addNewMailSent = false
      this.addSentMail.user_id = id
      this.$swal({
        icon: 'success',
        title: 'Mail Sent Added!',
        text: data.return_message,
      })
    },
    prospectStopped(body) {
      ProspectMatcher.getProspectsWithoutLoading(this, this.query, this.$store.state.query.page - 1, this.searchQuery)
      this.$swal({
        icon: 'success',
        title: 'Success',
        text: body.return_message,
      })
    },
    showNewMailSent() {
      this.addNewMailSent = true
      this.sent_date = moment().tz('America/Chicago').format('MM-DD-YYYY')
      this.addSentMail.time = moment().tz('America/Chicago').format('hh:mm a')
    },
    prospectDeleted(data, index) {
      this.$swal({
        icon: 'success',
        title: 'Prospect Deleted!',
        text: data.return_message,
      })
      this.$store.state.prospects.prospectsResponse.prospects.splice(index, 1)
      this.prospectsResponse = this.$store.state.prospects.prospectsResponse
      // ProspectMatcher.getProspects(this, this.query, this.$store.state.query.page - 1, this.searchQuery)
    },
    prospectNotDeleted(message) {
      this.$swal({
        icon: 'warning',
        title: 'Prospect Not Deleted!',
        text: message,
      })
    },
    updateProspectViewData(data) {
      this.showProspectDetails = true
      this.$refs.loadRight.setProspectDetails(data)
    },
    loadProspects(query) {
      this.query = query
      this.currentPage = 1
    },
    skillChanged() {
    },
    stateChanged() {
    },
    stopProspect(id, stopped, name) {
      const action = stopped ? 'activate' : 'stop'
      const message = `Are you sure you want to ${action} contact with this prospect, ${name}?`
      const swalConfig = {
        title: 'Are you sure?',
        text: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Yes, ${action}`,
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }
      this.$swal(swalConfig).then(result => {
        if (result.value) {
          Prospects.stopProspect(this, id, stopped)
        }
      })
    },
    searchUpdated(query) {
      this.searchQuery = query
      this.$store.state.query.query = this.searchQuery
      this.currentPage = 1
    },
    showMails(row) {
      this.sentMails = row.mails
      this.addSentMail.user_id = row.id
      this.$refs.sent_mails.show()
    },
    closeSentMailsPopup() {
      this.sentMails = []
      this.$refs.sent_mails.hide()
      this.addSentMail = {
        job_id: '',
        user_id: null,
        description: '',
        date: null,
        time: null,
      }
      this.addNewMailSent = false
    },
    closeSentMail() {
      const id = this.addSentMail.user_id
      this.addSentMail = {
        job_id: '',
        user_id: null,
        description: '',
        date: null,
        time: null,
      }
      this.addNewMailSent = false
      this.addSentMail.user_id = id
    },
    showNotes(id) {
      this.userComments = []
      this.$refs.add_notes.show()
      ParentComments.getComments(this, id)
      this.addNotesRequest.user_id = id
    },
    closingNotesPopup() {
      this.userComments = []
      this.addNotesRequest = {
        comment: '',
        user_id: null,
        date: null,
        time: null,
      }
    },
    saveNote() {
      this.addNotesRequest.comment = this.addNotesRequest.comment.trim()
      if (this.addNotesRequest.comment === '') {
        return
      }
      this.addNotesRequest.date = Constants.getEprmMonthDateFromString(moment())
      this.addNotesRequest.time = moment().format('hh:mm a')
      ParentComments.addComment(this, this.addNotesRequest.user_id, this.addNotesRequest)
    },
    setParentComments(userComments, userId) {
      this.userComments = userComments.comments
      this.addNotesRequest.comment = ''
      this.prospectsResponse.prospects = this.prospectsResponse.prospects.map(prospect => {
        if (prospect.id === userId) {
          return { ...prospect, comments: userComments.comments }
        }
        return prospect
      })
    },
    setProspectsFromStore(prospectsResponse) {
      this.prospectsResponse = { ...prospectsResponse }
      this.totalProspects = this.prospectsResponse.prospects.length
      if (this.$refs.loadTop !== undefined) {
        this.$refs.loadTop.totalProspects = this.prospectsResponse.prospects.length
      }
    },
    setProspects(prospectsResponse) {
      this.prospectsResponse = prospectsResponse
      this.totalProspects = this.prospectsResponse.prospects.length
      this.$store.state.prospects.isProspectsLoaded = true
      this.$store.state.prospects.prospectsResponse = { ...prospectsResponse }
      this.$refs.loadTop.totalProspects = this.prospectsResponse.prospects.length
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
@import "@core/scss/vue/libs/tree.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
