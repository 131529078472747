export default [
  {
    title: 'Administration',
    icon: 'CpuIcon',
    children: [
      {
        title: 'Profile Invitations',
        route: 'administration-profile-invitations',
      },
      {
        title: 'Mass Communication',
        route: 'administration-mass-communication',
      },
      {
        title: 'Skills and Technologies',
        route: 'administration-skills-and-technologies',
      },
      {
        title: 'Documents',
        route: 'administration-documents',
      },
      {
        title: 'Workflow',
        route: 'administration-workflow',
      },
      {
        title: 'Qbo Category',
        route: 'administration-qbo-categories',
      },
      {
        title: 'User Defined Fields',
        route: 'administration-user-defined-fields',
      },
      {
        title: 'Roles',
        route: 'administration-roles',
      },
      {
        title: 'Role Users',
        route: 'administration-role-users',
      },
      {
        title: 'Assign Jobs',
        route: 'administration-assign-jobs',
      },
      {
        title: 'User Login Tracking',
        route: 'administration-user-login-tracking',
      },
    ],
  },
]
