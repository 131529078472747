import axios from 'axios'

const financialStatement = 'financial-statement/dashboard'

export default {
  getProfitRateCount(context1) {
    const context = context1
    context.$vs.loading()
    axios.get(`${financialStatement}/profit_rate`).then(response => {
      context.$vs.loading.close()
      context.setProfitCount(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getProfitAmountCounts(context1) {
    const context = context1
    context.$vs.loading()
    const params = { params: { year: context.year } }
    axios.get(`${financialStatement}/counts`, params).then(response => {
      context.$vs.loading.close()
      context.setCounts(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getStats(context1) {
    const context = context1
    context.$vs.loading()
    const params = { params: { year: context.year } }
    axios.get(`${financialStatement}/stats`, params).then(response => {
      context.$vs.loading.close()
      context.setStats(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getOutStandingProfitAmountCounts(context1) {
    const context = context1
    context.$vs.loading()
    axios.get(`${financialStatement}/outstanding-counts`).then(response => {
      context.$vs.loading.close()
      context.setOutStandingCounts(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
}
