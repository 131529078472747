<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          View Qbo Category</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>

    <app-timeline>
      <app-timeline-item class="pb-1">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Qbo Category Details</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Name</label>
              <span
                class="form-control h-auto"
                v-text="QboCategory.name"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Type</label>
              <span
                class="form-control"
                v-text="QboCategory.type"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="8"
          >
            <b-form-group>
              <label>Details</label>
              <b-form-textarea
                v-model="QboCategory.detail"
                disabled
                rows="2"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </app-timeline-item>
    </app-timeline>
    <!-- <div v-show="QboCategory.user_defined_fields.length !== 0">
      <hr>
      <div class="d-flex mt-2">
        <h4 class="mb-0 ml-50">
          User Defined Fields
        </h4>
      </div>
      <b-row
        v-for="field in QboCategory.user_defined_fields"
        :id="field.id"
        :key="field.id"
        class="mt-1"
      >
        <b-col cols="6">
          <dl class="row">
            <dt class="col-sm-3 text-right mt-50">
              {{ field.name }}
            </dt>
            <dd class="col-sm-9">
              <span
                class="form-control"
                v-text="field.value"
              />
            </dd>
          </dl>
        </b-col>
      </b-row>
    </div> -->
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormTextarea, BButton,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import QboCategory from '@/js/qboCategory'

export default {
  name: 'QboCategoriesView',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    BButton,
  },
  directives: {
  },
  setup() {
  },
  data() {
    return {
      QboCategory: {},
    }
  },
  mounted() {
  },
  created() {
    QboCategory.getQboCategoryById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    setQboCategory(qboCategory) {
      this.QboCategory = qboCategory
      this.$vs.loading.close()
    },
    qboCategoryNotFound(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    back() {
      this.$router.push('/administration/qbo-categories').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.repeater-form {
  transition: .35s height;
}
</style>
