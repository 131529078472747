<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <li>
        <b-button
          variant="outline-primary"
          :to="{ name: 'human-resources-prospects-admin-job-applied-registrants' }"
          class="position-relative px-1"
          size="sm"
        >
          Applied Registrants
          <span class="badge badge-up badge-pill bg-danger">{{ $store.state.top_counts.applied_prospects }}</span>
        </b-button>
      </li>
      <li class="ml-1">
        <b-button
          variant="outline-primary"
          :to="{ name: 'human-resources-prospects-admin-all-prospects-prospect-as-registrant' }"
          class="position-relative px-1"
          size="sm"
        >
          Registrants
          <span class="badge badge-up badge-pill bg-danger">{{ $store.state.top_counts.registrants }}</span>
        </b-button>
      </li>
      <li class="ml-1">
        <b-button
          variant="outline-primary"
          :to="{ name: 'human-resources-prospects-admin-all-prospects-prospect-as-not-registrant' }"
          class="position-relative px-1"
          size="sm"
        >
          Non-Registrants
          <span class="badge badge-up badge-pill bg-danger">{{ $store.state.top_counts.not_registrants }}</span>
        </b-button>
      </li>
      <li class="ml-1">
        <b-button
          variant="outline-primary"
          :to="{ name: 'human-resources-prospects-admin-jobs', query: { job_type: 'urgent_all', position: 'Contract' } }"
          class="position-relative px-1"
          size="sm"
        >
          Urgent Jobs
          <span class="badge badge-up badge-pill bg-danger">{{ $store.state.top_counts.urgent_jobs }}</span>
        </b-button>
      </li>
      <li class="ml-1">
        <b-button
          variant="outline-primary"
          :to="{ name: 'human-resources-prospects-admin-new-job-prospects' }"
          class="position-relative px-1"
          size="sm"
        >
          Eligible Prospects
          <span class="badge badge-up badge-pill bg-danger">{{ $store.state.top_counts.eligible_prospects }}</span>
        </b-button>
      </li>
      <li class="ml-1">
        <b-button
          variant="outline-primary"
          :to="{ name: 'human-resources-prospects-admin-new-job-registrants' }"
          class="position-relative px-1"
          size="sm"
        >
          Eligible Registrants
          <span class="badge badge-up badge-pill bg-danger">{{ $store.state.top_counts.eligible_registrants }}</span>
        </b-button>
      </li>
      <li
        v-if="$store.state.roles.alerts_exist"
        class="nav-item b-nav-dropdown dropdown dropdown-notification ml-1 mr-25 show"
      >
        <b-link
          :to="{ name: 'financial-statements-alerts' }"
          class="nav-link dropdown-toggle"
        >
          <span class="feather-icon position-relative">
            <feather-icon
              size="21"
              icon="BellIcon"
              class="text-body"
            />
            <span class="badge badge-up badge-pill bg-danger">{{ $store.state.top_counts.alerts }}</span>
          </span>
        </b-link>
      </li>
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user ml-2"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ $store.state.AppActiveUser.displayName }}
            </p>
            <span class="user-status">Admin</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="$store.state.AppActiveUser.photoUrl === null ? require('@/assets/images/avatars/13-small.png') : $store.state.AppActiveUser.photoUrl"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BAvatar, BButton,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import QuickProspect from '@/js/quickProspect'
import logout from '@/js/logout'

export default {
  name: 'AppNavBar',
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
    BButton,

    // Navbar Components
    DarkToggler,
  },
  directives: {
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      prospectExist: false,
      prospectId: null,
      addProspect: {
        email_address: '',
      },
    }
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          QuickProspect.getProspectByEmail(this, this.addProspect.email_address)
        }
      })
    },
    optOutProspect() {
      QuickProspect.optOutProspect(this, this.prospectId)
    },
    editProspect() {
      this.$router.push(`/human-resources/quick-prospects/edit/${this.prospectId}`).catch(() => {})
      this.$refs.add_prospect.hide()
    },
    viewProspect() {
      this.$router.push(`/human-resources/quick-prospects/view/${this.prospectId}`).catch(() => {})
      this.$refs.add_prospect.hide()
    },
    setProspectNotExist() {
      this.$store.state.quickProspectEmail = this.addProspect.email_address
      this.$router.push('/human-resources/quick-prospects/new').catch(() => {})
      this.$refs.add_prospect.hide()
    },
    setProspectExist(id) {
      this.prospectId = id
      this.prospectExist = true
    },
    showAddProspectPopup() {
      this.prospectId = null
      this.prospectExist = false
      this.addProspect.email_address = ''
      this.$refs.add_prospect.show()
    },
    closingProspectPopup() {
      this.prospectId = null
      this.prospectExist = false
      this.addProspect.email_address = ''
    },
    prospectUpdated(data) {
      this.prospectId = null
      this.prospectExist = false
      this.addProspect.email_address = ''
      this.$refs.add_prospect.hide()
      this.$refs.showLoading = false
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    prospectNotUpdated(message) {
      this.prospectId = null
      this.prospectExist = false
      this.addProspect.email_address = ''
      this.$refs.add_prospect.hide()
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    logout() {
      logout.logout(this.$store.state.AppActiveUser.access_token)
      window.localStorage.removeItem('access_token')
      window.localStorage.removeItem('userInfo')
      this.$store.state.AppActiveUser = {}
      this.$store.state.AppActiveUser = {}
      this.$store.commit('UPDATE_USER_INFO', {})
      this.$store.commit('HR_LISTS', {})
      this.$store.commit('COMMON_LISTS', {})
      this.$store.commit('AD_LISTS', {})
      this.$store.commit('FA_LISTS', {})
      this.$store.commit('MODULE_LISTS', {})
      this.$store.commit('USER_ROLES', {})
      this.$store.commit('ALERTS_COUNT', {})
      this.$store.commit('UPDATE_COMPANY_NAME', '')
      this.$store.commit('UPDATE_COMPANY_LOGO', '')
      this.$router.push('/login').catch(() => {})
    },
    support() {
      window.open(`http://3.14.82.134/#/pages/generate-token/${this.$store.state.AppActiveUser.support_token}?email=${encodeURIComponent(this.$store.state.AppActiveUser.email)}`, '_blank')
    },
    launchCRM() {
      window.open(`https://crm.Medibril.co/#/launch-crm?token=${this.$store.state.AppActiveUser.access_token}`, '_blank')
    },
  },
}
</script>
