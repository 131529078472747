import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.faurl
const bankTransaction = `${HOST}plaid`

export default {
  getLinkToken(context1) {
    const context = context1
    const params = { params: {} }
    axios.get(`${bankTransaction}/link-token`, params).then(response => {
      context.$vs.loading.close()
      context.isLoading = false
      context.setLinkToken(response.data)
    }, () => {
    })
  },
  saveAccessToken(context1, id, data) {
    const context = context1
    axios.post(`${bankTransaction}/${id}`, data).then(response => {
      context.$vs.loading.close()
      context.isLoading = false
      context.accessTokenSaved(response.data)
    }, error => {
      context.$vs.loading.close()
      context.isLoading = false
      context.accessTokenNotSaved(error.response.data.return_message)
    })
  },
}
