<template>
  <b-card
    class="mb-0"
  >
    <div class="mb-2">
      <b-row>
        <b-col
          cols="12"
          md="3"
        >
          <h4 class="card-title mb-0 pt-50">
            User Login Tracking</h4>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group>
            <v-select
              id="user_id"
              v-model="user_id"
              :options="roleUsers"
              :reduce="item => item.id"
              :clearable="true"
              placeholder="Select User"
              @input="userChanged"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group>
            <b-input-group>
              <flat-pickr
                v-model="login_date"
                class="form-control"
                :config="loginDateConfig"
                placeholder="MM-DD-YYYY"
                clear-button="true"
                @on-change="loginDateChanged"
              />
              <b-input-group-append>
                <b-button
                  class="px-1 outline-grey"
                  variant="outline-secondary"
                  @click="removeLoginate"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                @keyup.enter="updateSearchQuery"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="updateSearchQuery"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="14"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-row>
      <b-col cols="12">
        <vue-good-table
          :columns="columns"
          :rows="userLoginTrackingResponse.trackings"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >

          <!-- pagination -->
          <template
            slot="pagination-bottom"
          >
            <div class="d-flex justify-content-end flex-wrap">
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="userLoginTrackingResponse.count"
                  first-number
                  last-number
                  align="right"
                  :per-page="1"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BPagination, BFormInput, BInputGroup, BInputGroupAppend, BButton, BFormGroup,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import store from '@/store/index'
import LIST from '@/js/lists'
import Tracking from '@/js/userlogintracking'
import moment from 'moment'

export default {
  name: 'UserLoginTracking',
  components: {
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    BPagination,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BInputGroupAppend,
    BButton,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      searchQuery: '',
      user_id: null,
      roleUsers: [],
      login_date: null,
      pageLength: 10,
      dir: false,
      userLoginTrackingResponse: {
        trackings: [],
        count: 1,
      },
      columns: [
        {
          label: '#',
          field: 'index',
          width: '100px',
          sortable: false,
        },
        {
          label: 'User',
          field: 'user',
          sortable: false,
        },
        {
          label: 'Type',
          field: 'type',
          sortable: false,
        },
        {
          label: 'Date',
          field: 'date',
          sortable: false,
        },
        {
          label: 'Time',
          field: 'time',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      loginDateConfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'm-d-Y',
        altInput: true,
        dateFormat: 'm-d-Y',
        maxDate: new Date(),
      },
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
        Tracking.getUserTrackings(this)
      },
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getRollUsers(this)
      },
    },
  },
  created() {
    this.searchQuery = this.$store.state.query.query
    this.userLoginTrackingResponse.count = this.$store.state.query.page
    Tracking.getUserTrackings(this)
    LIST.getRollUsers(this)
  },
  methods: {
    loginDateChanged(config, date) {
      this.login_date = date
      this.$set(new Date(moment(this.login_date, 'MM-DD-YYYY').format('MM-DD-YYYY')))
      this.$store.state.query.logindate = this.login_date
      this.currentPage = 1
    },
    removeLoginate() {
      this.login_date = null
      this.currentPage = 1
    },
    setTrackings(userLoginTrackingResponse) {
      this.userLoginTrackingResponse = userLoginTrackingResponse
    },
    setRollUsers(roleUsers) {
      this.roleUsers = roleUsers
    },
    userChanged() {
      this.$store.state.query.user_id = this.user_id
      this.currentPage = 1
    },
    updateSearchQuery() {
      this.$store.state.query.query = this.searchQuery
      this.currentPage = 1
    },
    setRoleusers(roleusersResponse) {
      this.roleusersResponse = roleusersResponse
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
