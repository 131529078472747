import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.faurl
const documentAlerts = `${HOST}document-alert`

export default {
  getDocumentExpiredAlerts(context1) {
    const context = context1
    const params = { params: { type: 'expired', page: context.$store.state.query.page - 1 } }
    axios.get(documentAlerts, params).then(response => {
      context.setDocumentAlerts(response.data)
    }, () => {
    })
  },
  getDocumentExpireSoonAlerts(context1) {
    const context = context1
    const params = { params: { type: 'expire_soon', page: context.$store.state.query.page - 1 } }
    axios.get(documentAlerts, params).then(response => {
      context.setDocumentAlerts(response.data)
    }, () => {
    })
  },
  ignoreAlert(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${documentAlerts}/${id}/ignore`).then(response => {
      context.$vs.loading.close()
      context.alertIgnored(response.data)
    }, error => {
      context.$vs.loading.close()
      context.alertNotIgnored(error.response.data.return_message)
    })
  },
  getDocumentNotExistAlerts(context1, type) {
    const context = context1
    const params = { params: { type, page: context.$store.state.query.page - 1 } }
    axios.get(`${documentAlerts}/not-exist`, params).then(response => {
      context.setDocumentAlerts(response.data)
    }, () => {
    })
  },
  ignoreNotExistAlert(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${documentAlerts}/not-exist/${id}/ignore`).then(response => {
      context.$vs.loading.close()
      context.alertIgnored(response.data)
    }, error => {
      context.$vs.loading.close()
      context.alertNotIgnored(error.response.data.return_message)
    })
  },
}
