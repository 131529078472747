import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.url
const tracking = `${HOST}tracking`

export default {
  getUserTrackings(context1) {
    const context = context1
    const params = {
      params: {
        query: context.$store.state.query.query, page: context.$store.state.query.page - 1, user_id: context.user_id, date: context.login_date,
      },
    }
    axios.get(tracking, params).then(response => {
      context.setTrackings(response.data)
    }, () => {
    })
  },
}
