<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Edit Qbo Category</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Qbo Category Details</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Name"
                    rules="required|min:2"
                  >
                    <b-form-input
                      id="name"
                      v-model="addQboCategory.name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Type</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Type"
                    rules="required"
                  >
                    <v-select
                      id="type"
                      v-model="addQboCategory.type_id"
                      :options="types"
                      :reduce="type => type.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                lg="8"
              >
                <b-form-group>
                  <label>Details</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Details"
                    rules="required"
                  >
                    <b-form-textarea
                      id="details"
                      v-model="addQboCategory.detail"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <!-- <div v-show="addQboCategory.user_defined_fields.length !== 0">
          <div class="d-flex mt-2">
            <h4 class="mb-0 ml-50">
              User Defined Fields
            </h4>
          </div>
          <b-row
            v-for="field in addQboCategory.user_defined_fields"
            :id="field.id"
            :key="field.id"
            class="mt-1"
          >
            <b-col
              v-if="field.type.toLowerCase() === 'input'"
              cols="6"
            >
              <b-form-group
                :label="field.name"
                label-cols-md="4"
                class="text-right"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="field.name"
                  :rules="field.required ? 'required' : ''"
                >
                  <b-form-input
                    v-model="field.value"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="field.type.toLowerCase() === 'dropdown'"
              cols="6"
            >
              <b-form-group
                :label="field.name"
                label-cols-md="4"
                class="text-right"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="field.name"
                  :rules="field.required ? 'required' : ''"
                >
                  <v-select
                    v-model="field.value"
                    :options="field.options"
                    label="option"
                    :reduce="item => item.option"
                    :clearable="!field.required"
                    placeholder="Select"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="field.type.toLowerCase() === 'textarea'"
              cols="6"
            >
              <b-form-group
                :label="field.name"
                label-cols-md="4"
                class="text-right"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="field.name"
                  :rules="field.required ? 'required' : ''"
                >
                  <b-form-textarea
                    v-model="field.value"
                    rows="2"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <hr>
        </div> -->
        <hr>

        <b-row class="ml-2">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Update Qbo Category
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BButton,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import QboCategory from '@/js/qboCategory'
import LIST from '@/js/lists'

export default {
  name: 'QboCategoriesEdit',
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormTextarea,
    BButton,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      addQboCategory: {
        name: '',
        type_id: null,
        detail: '',
      },
      types: [],
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getQboTypes(this)
      },
    },
  },
  mounted() {
  },
  created() {
    LIST.getQboTypes(this)
    QboCategory.getQboCategoryById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          QboCategory.updateQboCategory(this, this.$route.params.id, this.addQboCategory)
        }
      })
    },
    setQboTypes(types) {
      this.types = types
    },
    setQboCategory(qboCategory) {
      this.addQboCategory.name = qboCategory.name
      this.addQboCategory.type_id = qboCategory.type_id
      this.addQboCategory.detail = qboCategory.detail
    },
    // setUserDefinedFields(userDefinedFields) {
    //   this.addQboCategory.user_defined_fields = userDefinedFields.user_defined_fields
    // },
    qboCategoryUpdated(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    qboCategoryNotUpdated(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    back() {
      this.$router.push('/administration/qbo-categories').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.repeater-form {
  transition: .35s height;
}
</style>
