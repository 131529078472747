<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Employer Letter</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer
      v-show="!showTemplate"
      ref="simpleRules"
    >
      <b-form class="ex-large">
        <app-timeline>
          <app-timeline-item class="pb-1">
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Title</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Title"
                    rules="required|min:2"
                  >
                    <b-form-input
                      id="title"
                      v-model="addEmployerLetter.title"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>First Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="First Name"
                    rules="required|min:2"
                  >
                    <b-form-input
                      id="first_name"
                      v-model="addEmployerLetter.first_name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Last Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Last Name"
                    rules="required|min:2"
                  >
                    <b-form-input
                      id="last_name"
                      v-model="addEmployerLetter.last_name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Job Title</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Job Title"
                    rules="required"
                  >
                    <b-form-input
                      id="job_title"
                      v-model="addEmployerLetter.job_title"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Project</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Project"
                    rules="required"
                  >
                    <b-form-input
                      id="project"
                      v-model="addEmployerLetter.project"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Company User Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Company User Name"
                    rules="required"
                  >
                    <b-form-input
                      id="company_user_name"
                      v-model="addEmployerLetter.company_user_name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Company User Title</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Company User Title"
                    rules="required"
                  >
                    <b-form-input
                      id="company_user_title"
                      v-model="addEmployerLetter.company_user_title"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Company User Phone</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Company User Phone"
                    rules="required|integer|digits:10"
                  >
                    <b-form-input
                      id="company_user_phone"
                      v-model="addEmployerLetter.company_user_phone"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Company User Email</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Company User Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="company_user_email"
                      v-model="addEmployerLetter.company_user_email"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group>
                  <label>Responsibilities</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Responsibilities"
                    rules="required"
                  >
                    <quill-editor
                      v-model="addEmployerLetter.responsibilities"
                      :options="snowOption"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="mt-1">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              class="mx-2"
              @click="updateContent"
            >
              Update Content
            </b-button>
            <b-button
              variant="primary"
              type="button"
              class="mx-2"
              @click="generatePdf"
            >
              Generate Pdf
            </b-button>
            <b-button
              variant="primary"
              type="button"
              @click="sentForSign"
            >
              Send for E-Sign
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
    <b-modal
      ref="validFor"
      cancel-variant="outline-secondary"
      ok-title="Save"
      cancel-title="Close"
      size="sm"
      centered
      title="Valid For"
    >
      <validation-observer
        ref="valid_form"
        tag="form"
      >
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <label>Valid For</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Valid For"
                  rules="required|integer|between:1,11"
                >
                  <b-form-input
                    v-model="addEmployerLetter.valid_for"
                    placeholder="Valid For"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <div class="d-flex justify-content-between w-100">
          <b-button
            variant="outline-primary"
            class="px-1"
            @click="closeValidFor()"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            class="ml-1 px-1"
            @click="sendForSignature()"
          >
            Send
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      ref="updateContentValidFor"
      cancel-variant="outline-secondary"
      ok-title="Save"
      cancel-title="Close"
      size="sm"
      centered
      title="Valid For"
    >
      <validation-observer
        ref="update_valid_form"
        tag="form"
      >
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <label>Valid For</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Valid For"
                  rules="required|integer|between:1,11"
                >
                  <b-form-input
                    v-model="template.valid_for"
                    placeholder="Valid For"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <div class="d-flex justify-content-between w-100">
          <b-button
            variant="outline-primary"
            class="px-1"
            @click="closeUpdateValidFor()"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            class="ml-1 px-1"
            @click="sendForUpdateContentSignature()"
          >
            Send
          </b-button>
        </div>
      </template>
    </b-modal>
    <validation-observer
      v-show="showTemplate"
      ref="templateRules"
    >
      <b-form class="ex-large">
        <b-row>
          <b-col
            cols="12"
            md="10 ml-auto mr-auto"
          >
            <b-form-group>
              <div
                class="msa-form"
              >
                <div class="text-right mb-4">
                  <b-img
                    fluid
                    :src="template.logo"
                    alt="Company Logo"
                  />
                </div>
                <h2 class="text-underline text-center mb-4">
                  To Whomsoever It May Concern</h2>
                <p
                  id="template_content"
                  v-html="template.template_response"
                  class="temp-content"
                />
                <div class="content-footer mt-4">
                  <b-row>
                    <b-col cols="8">
                      <p>Yours truly,</p>
                      <div class="mt-2 mb-50">
                        _________________________________
                        <span class="small d-block">(Signature)</span>
                      </div>
                      <p class="mb-0">Name: {{ template.company_user_name }}</p>
                      <p class="mb-0">Title: {{ template.company_user_title }}</p>
                      <p class="mb-0">Contact Info: {{ template.company_user_phone }}</p>
                      <p class="mb-0">Email: {{ template.company_user_email }}</p>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <hr>

        <b-row class="mt-1">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              class="mx-2"
              @click="back"
            >
              Cancel
            </b-button>
            <b-button
              variant="primary"
              type="button"
              class="mx-2"
              @click="generatePdfWithUpdatedContent"
            >
              Generate Pdf
            </b-button>
            <b-button
              variant="primary"
              type="button"
              class="mx-2"
              @click="sendForESignWithUpdatedContent"
            >
              Send for E-Sign
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BImg,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import EmployerLetter from '@/js/employerletter'

export default {
  name: 'EmployerLetter',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BImg,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    quillEditor,
    // currency,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      addEmployerLetter: {
        first_name: '',
        last_name: '',
        email: '',
        job_title: '',
        content: '',
        project: '',
      },
      template: {
        template_response: '',
        logo: '',
        name: '',
        title: '',
        email: '',
        valid_for: 5,
      },
      date: null,
      formstate: {},
      submitted: false,
    }
  },
  watch: {
  },
  mounted() {
  },
  created() {
    EmployerLetter.getEmployerLetterById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    sendForSignature() {
      this.$refs.valid_form.validate().then(result => {
        if (result) {
          this.showLoading = true
          EmployerLetter.sendEmployerLetterForSign(this, this.$route.params.id, this.addEmployerLetter)
        }
      })
    },
    sentForSign() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          this.showLoading = true
          EmployerLetter.sendEmployerLetterForSign(this, this.$route.params.id, this.addEmployerLetter)
        //  this.$refs.validFor.show()
        }
      })
    },
    closeValidFor() {
      this.$refs.validFor.hide()
    },
    generatePdf() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          this.showLoading = true
          EmployerLetter.getEmployerLetterPdfById(this, this.$route.params.id, this.addEmployerLetter)
        }
      })
    },
    updateContent() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          this.showLoading = true
          EmployerLetter.getEmployerLetterTemplateById(this, this.$route.params.id, this.addEmployerLetter)
        }
      })
    },
    generatePdfWithUpdatedContent() {
      this.$refs.templateRules.validate().then(result => {
        if (result) {
          this.template.template_response = document.getElementById('template_content').innerHTML.replaceAll('\n', '')
          this.showLoading = true
          EmployerLetter.getEmployerLetterPdfByIdAndUpdatedContent(this, this.$route.params.id, this.template)
        }
      })
    },
    closeUpdateValidFor() {
      this.$refs.updateContentValidFor.hide()
    },
    sendForUpdateContentSignature() {
      this.$refs.update_valid_form.validate().then(result => {
        if (result) {
          this.template.template_response = document.getElementById('template_content').innerHTML.replaceAll('\n', '')
          this.showLoading = true
          EmployerLetter.sendEmployerLetterUpdatedContentForSign(this, this.$route.params.id, this.template)
        }
      })
    },
    sendForESignWithUpdatedContent() {
      this.$refs.templateRules.validate().then(result => {
        if (result) {
        //  this.template.template_response = document.getElementById('template_content').innerHTML.replaceAll('\n', '')
          this.template.template_response = document.getElementById('template_content').innerHTML.replaceAll('\n', '')
          this.showLoading = true
          EmployerLetter.sendEmployerLetterUpdatedContentForSign(this, this.$route.params.id, this.template)
        //  this.$refs.updateContentValidFor.show()
        }
      })
    },
    setEmployeeDetails(employeeDetails) {
      this.addEmployerLetter = employeeDetails
      this.addEmployerLetter.valid_for = 5
    },
    pdfPath(data) {
      window.open(data.pdf_path, '_blank')
      this.back()
    },
    employerLetterTemplate(data) {
      this.showTemplate = true
      this.template = data
      this.template.valid_for = 5
    },
    pdfNotFound(message) {
      this.$swal({
        icon: 'warning',
        title: 'Employer Letter!',
        text: message,
      })
    },
    sentSuccess(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    sentFailure(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
