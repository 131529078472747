import axios from 'axios'

const report = 'reports'

export default {
  getActualMonthlyReport(context1, month1, year1) {
    const context = context1
    context.$vs.loading()
    let params = { month: month1, year: year1 }
    if (params !== {}) {
      params = { params: { month: month1, year: year1 } }
    } else {
      params = { params: { month: month1, year: year1 } }
    }
    axios.get(`${report}/actual-monthly`, params).then(response => {
      context.$vs.loading.close()
      context.setReport(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getMissingInvoices(context1, Month, Year, Status, parentId) {
    const context = context1
    const params = {
      params: {
        month: Month, year: Year, status: Status, parent_id: parentId,
      },
    }
    context.$vs.loading()
    axios.get(`${report}/missing-invoices`, params).then(response => {
      context.$vs.loading.close()
      context.setReport(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getJobProspectsReport(context1, sourceTypeIds, prospectType, vendorId) {
    const context = context1
    const params = new URLSearchParams()
    if (sourceTypeIds !== null && sourceTypeIds !== undefined) {
      sourceTypeIds.forEach(id => params.append('source_type_ids', id))
    }
    if (prospectType !== null && prospectType !== undefined) {
      params.append('prospect_type', prospectType)
    }
    if (vendorId !== null && vendorId !== undefined) {
      params.append('vendor_id', vendorId)
    }
    params.append('page', context.$store.state.query.page - 1)
    context.$vs.loading()
    axios.get(`${report}/jobs-prospects`, { params }).then(response => {
      context.$vs.loading.close()
      context.setReport(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getJobProspectsWithoutGuaranteeReport(context1, sourceTypeIds, prospectType, vendorId) {
    const context = context1
    const params = new URLSearchParams()
    if (sourceTypeIds !== null && sourceTypeIds !== undefined) {
      sourceTypeIds.forEach(id => params.append('source_type_ids', id))
    }
    if (prospectType !== null && prospectType !== undefined) {
      params.append('prospect_type', prospectType)
    }
    if (vendorId !== null && vendorId !== undefined) {
      params.append('vendor_id', vendorId)
    }
    params.append('page', context.$store.state.query.page - 1)
    context.$vs.loading()
    axios.get(`${report}/jobs-prospects-without-guarantee`, { params }).then(response => {
      context.$vs.loading.close()
      context.setReport(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getJobProspects(context1, jobId, sourceTypeIds, prospectType, vendorId) {
    const context = context1
    const params = new URLSearchParams()
    if (sourceTypeIds !== null && sourceTypeIds !== undefined) {
      sourceTypeIds.forEach(id => params.append('source_type_ids', id))
    }
    if (prospectType !== null && prospectType !== undefined) {
      params.append('prospect_type', prospectType)
    }
    if (vendorId !== null && vendorId !== undefined) {
      params.append('vendor_id', vendorId)
    }
    params.append('page', context.$store.state.query.page - 1)
    context.$vs.loading()
    axios.get(`${report}/${jobId}/jobs-prospects`, { params }).then(response => {
      context.$vs.loading.close()
      context.setJobProspects(jobId, response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getJobNoProspectsReport(context1, sourceTypeIds, vendorId) {
    const context = context1
    const params = new URLSearchParams()
    if (sourceTypeIds !== null && sourceTypeIds !== undefined) {
      sourceTypeIds.forEach(id => params.append('source_type_ids', id))
    }
    params.append('page', context.$store.state.query.page - 1)
    if (vendorId !== null && vendorId !== undefined) {
      params.append('vendor_id', vendorId)
    }
    context.$vs.loading()
    axios.get(`${report}/job-no-prospects`, { params }).then(response => {
      context.$vs.loading.close()
      context.setReport(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getMissingTimesheets(context1, Month, Year, Status) {
    const context = context1
    const params = {
      params: {
        month: Month, year: Year, status: Status,
      },
    }
    context.$vs.loading()
    axios.get(`${report}/missing-timesheets`, params).then(response => {
      context.$vs.loading.close()
      context.setReport(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getInvoicePaymentDueDateReport(context1, Month, Year, parentId, currentDate, currentMonth, currentYear) {
    const context = context1
    const params = {
      params: {
        period_month: Month, period_year: Year, parent_id: parentId,
      },
    }
    context.$vs.loading()
    axios.get(`${report}/invoices-payment-duedate/${currentDate}/${currentMonth}/${currentYear}`, params).then(response => {
      context.$vs.loading.close()
      context.setReport(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getActualMonthlyIncomeReport(context1, month1, year1) {
    const context = context1
    context.$vs.loading()
    let params = { month: month1, year: year1 }
    if (params !== {}) {
      params = { params: { month: month1, year: year1 } }
    } else {
      params = { params: { month: month1, year: year1 } }
    }
    axios.get(`${report}/actual-monthly-income`, params).then(response => {
      context.$vs.loading.close()
      context.setReport(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getActualMonthlyExpensisReport(context1, month1, year1) {
    const context = context1
    context.$vs.loading()
    let params = { month: month1, year: year1 }
    if (params !== {}) {
      params = { params: { month: month1, year: year1 } }
    } else {
      params = { params: { month: month1, year: year1 } }
    }
    axios.get(`${report}/actual-monthly-expansis`, params).then(response => {
      context.$vs.loading.close()
      context.setReport(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getProjectionMonthlyIncomeReport(context1, month1, year1) {
    const context = context1
    context.$vs.loading()
    let params = { month: month1, year: year1 }
    if (params !== {}) {
      params = { params: { month: month1, year: year1 } }
    } else {
      params = { params: { month: month1, year: year1 } }
    }
    axios.get(`${report}/projection-monthly-income`, params).then(response => {
      context.$vs.loading.close()
      context.setReport(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getProjectionMonthlyExpensisReport(context1, month1, year1) {
    const context = context1
    context.$vs.loading()
    let params = { month: month1, year: year1 }
    if (params !== {}) {
      params = { params: { month: month1, year: year1 } }
    } else {
      params = { params: { month: month1, year: year1 } }
    }
    axios.get(`${report}/projection-monthly-expansis`, params).then(response => {
      context.$vs.loading.close()
      context.setReport(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getProjectionMonthlyReport(context1, month1, year1) {
    const context = context1
    context.$vs.loading()
    let params = { month: month1, year: year1 }
    if (params !== {}) {
      params = { params: { month: month1, year: year1 } }
    } else {
      params = { params: { month: month1, year: year1 } }
    }
    axios.get(`${report}/projection-monthly`, params).then(response => {
      context.$vs.loading.close()
      context.setReport(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getConsultantsByEmployer(context1) {
    const context = context1
    context.$vs.loading()
    axios.get(`${report}/employer-consultants`).then(response => {
      context.$vs.loading.close()
      context.setResponse(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getConsultantsBySubFacility(context1) {
    const context = context1
    context.$vs.loading()
    axios.get(`${report}/subfacility-consultants`).then(response => {
      context.$vs.loading.close()
      context.setResponse(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getConsultantsBySupplier(context1) {
    const context = context1
    context.$vs.loading()
    axios.get(`${report}/supplier-consultants`).then(response => {
      context.$vs.loading.close()
      context.setResponse(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getConsultantsByReferrer(context1) {
    const context = context1
    context.$vs.loading()
    axios.get(`${report}/referrer-consultants`).then(response => {
      context.$vs.loading.close()
      context.setResponse(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getConsultantsByFacility(context1) {
    const context = context1
    context.$vs.loading()
    axios.get(`${report}/facility-consultants`).then(response => {
      context.$vs.loading.close()
      context.setResponse(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
}
