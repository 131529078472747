<template>
  <b-card
    class="mb-0"
  >
    <div class="mb-0">
      <b-row>
        <b-col
          cols="12"
          md="3"
          class="mb-1"
        >
          <div class="d-flex align-items-center">
            <h4 class="card-title mb-0">
              Jobs</h4>
            <b-form-group class="ml-2 mb-0 w-100">
              <v-select
                id="vendor_id"
                v-model="vendor_id"
                :options="vendors"
                :reduce="item => item.id"
                :clearable="true"
                placeholder="Vendor"
                @input="vendorChanged"
              />
            </b-form-group>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="9 ml-auto"
        >
          <div class="d-flex align-items-start justify-content-end">
            <b-input-group>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                @keyup.enter="updateSearchQuery"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="updateSearchQuery"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="14"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <div class="border ml-1 px-1 py-25 min-w-160">
              {{ jobResponse.jobs_uploaded }}
            </div>
            <b-button
              variant="outline-primary"
              class="ml-1 min-w-140"
              :to="{ name: 'human-resources-prospects-admin-jobs-make-urgent'}"
            >
              Make Urgent
            </b-button>
            <b-button
              variant="primary"
              class="ml-2 min-w-120"
              :to="{ name: 'human-resources-prospects-admin-jobs-new'}"
            >
              Add Job
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="3">
          <b-form-group>
            <v-select
              id="facility_id"
              v-model="facility_id"
              :options="facilities"
              :reduce="item => item.id"
              :clearable="true"
              placeholder="By Facility / Client"
              @input="facilityChanged"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <v-select
              id="skill_id"
              v-model="skill_id"
              :options="technologies"
              :reduce="item => item.id"
              :clearable="true"
              placeholder="By Skill"
              @input="technologyChanged"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <v-select
              id="state_id"
              v-model="state_id"
              :options="states"
              :reduce="item => item.id"
              :clearable="true"
              placeholder="By State"
              @input="stateChanged"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <v-select
              id="city_id"
              v-model="city_id"
              :options="locations"
              :reduce="item => item.id"
              :clearable="true"
              placeholder="By City"
              @input="locationChanged"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <v-select
              id="status_id"
              v-model="status_id"
              :options="status"
              :reduce="item => item.id"
              :clearable="true"
              placeholder="Status"
              @input="statusChanged"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <v-select
              id="job_type"
              v-model="job_type"
              :options="jobTypeOptions"
              :reduce="item => item.id"
              :clearable="true"
              placeholder="Job Type"
              @input="jobTypeChanged"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <v-select
              id="position_type"
              v-model="position_type"
              :options="positionTypes"
              :reduce="item => item.id"
              :clearable="true"
              placeholder="Position Type"
              @input="positionTypeChanged"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <div class="d-flex align-items-center">
            <b-form-group class="mr-2 mb-0">
              <b-form-checkbox
                id="advertised_jobs"
                v-model="advertised_jobs"
                name="yes"
              >
                Advertised Jobs
              </b-form-checkbox>
            </b-form-group>
            <b-button
              variant="primary"
              @click="filter"
            >
              Filter
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <vue-good-table
      :columns="columns"
      :rows="jobResponse.jobs"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Job Id -->
        <span
          v-if="props.column.field === 'cellRendererJobId'"
          class="d-inline-block"
        >
          <b-link v-if="props.row.job_path !== null" :href="props.row.job_path" target="_blank">
            <img
              class="mr-25 mb-25 img-fluid"
              width="24"
              height="24"
              :src="require('@/assets/images/icons/stafferlink.png')"
              alt="image slot"
            >
          </b-link>
          <b-link @click="showJobDetails(props.row.job_popup_item)">
            {{ props.row.job_id }}
            <img
              v-show="props.row.is_urgent"
              class="mr-25 img-fluid"
              width="24"
              height="24"
              :src="require('@/assets/images/Megaphone.gif')"
              alt="image slot"
            >
          </b-link>
          <b-link
            v-b-tooltip.hover.v-primary
            :title="props.row.advertised_description"
            :href="props.row.advertised_link"
            target="blank"
          >
            <img
              v-show="props.row.is_advertised"
              class="img-fluid"
              width="24"
              height="24"
              :src="require('@/assets/images/icons/advertise.png')"
              alt="image slot"
            >
          </b-link>
        </span>

        <!-- Column: Currency -->
        <span v-if="props.column.field === 'cellRendererCurrency'">
          <span>{{ props.row.rate| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
        </span>

        <!-- Column: Indeed Matched Prospects -->
        <span
          v-if="props.column.field === 'cellRendererIndeedLink'"
          class="position-relative"
        >
          <b-button
            variant="outline-primary"
            :to="{ name: 'human-resources-prospects-admin-prospect-matcher', query: { job_id: props.row.id, source_type: 'indeed', city: props.row.city, state_id: props.row.state_id } }"
            class="px-50 py-25"
          >
            {{ props.row.indeed_city_matched_prospects }}
          </b-button>
          /
          <b-button
            variant="outline-primary"
            :to="{ name: 'human-resources-prospects-admin-prospect-matcher', query: { job_id: props.row.id, source_type: 'indeed' } }"
            class="px-50 py-25"
          >
            {{ props.row.indeed_matched_prospects }}
          </b-button>
        </span>

        <!-- Column: Button -->
        <span
          v-if="props.column.field === 'cellRendererLink'"
          class="position-relative"
        >
          <b-button
            variant="outline-primary"
            :to="{ name: 'human-resources-prospects-admin-prospect-matcher', query: { job_id: props.row.id, city: props.row.city, state_id: props.row.state_id } }"
            class="px-50 py-25"
          >
            {{ props.row.city_matched_prospects }}
          </b-button>
          /
          <b-button
            variant="outline-primary"
            :to="{ name: 'human-resources-prospects-admin-prospect-matcher', query: { job_id: props.row.id } }"
            class="px-50 py-25"
          >
            {{ props.row.matched_prospects }}
          </b-button>
        </span>

        <!-- Column: Button -->
        <span
          v-if="props.column.field === 'cellRendererApplyButton'"
          class="position-relative"
        >
          <b-button
            variant="outline-primary"
            :to="{ name: 'human-resources-prospects-admin-jobs-existing-candidates', params: { job_id: props.row.id }, query: { name: props.row.job_title, job_id: props.row.job_id } }"
            class="px-1 py-25"
          >
            Apply
          </b-button>
          <span class="badge badge-up badge-pill bg-danger">{{ props.row.applied_prospects }}</span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span
          v-else-if="props.column.field === 'action'"
          class="btn-action"
        >
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none pl-0"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                id="edit"
                :to="{ name: 'human-resources-prospects-admin-jobs-edit', params: { id: props.row.id } }"
              >
                <feather-icon icon="Edit2Icon" />
                <span class="ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item
                id="clone"
                :to="{ name: 'human-resources-prospects-admin-jobs-clone', params: { id: props.row.id } }"
              >
                <feather-icon icon="CopyIcon" />
                <span class="ml-50">Clone</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="!props.row.is_advertised"
                id="addAdvertise"
                @click="addAdvertiseRecord(props.row)"
              >
                <feather-icon icon="MicIcon" />
                <span class="ml-50">Add Advertise</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="props.row.is_advertised"
                id="closeAdvertise"
                @click="closeAdvertiseRecord(props.row)"
              >
                <feather-icon icon="MicOffIcon" />
                <span class="ml-50">Close Advertise</span>
              </b-dropdown-item>
              <b-dropdown-item
                id="delete"
                @click="confirmDeleteRecord(props.row)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
            <b-link
              id="view"
              :to="{ name: 'human-resources-prospects-admin-jobs-view', params: { id: props.row.id } }"
            >
              <b-badge
                pill
                variant="light-primary"
              ><feather-icon icon="EyeIcon" />
              </b-badge>
            </b-link>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <div>{{ jobResponse.pagination_message }}</div>
            <div class="ml-2">
              <img
                class="mlr50 img-fluid"
                width="24"
                height="24"
                :src="require('@/assets/images/icons/megaphone.png')"
                alt="image slot"
              >
              <span class="ml-50">Urgent Job</span>
            </div>
            <div class="ml-2">
              <img
                class="mlr50 img-fluid"
                width="24"
                height="24"
                :src="require('@/assets/images/icons/advertise.png')"
                alt="image slot"
              >
              <span class="ml-50">Added Advertised</span>
            </div>
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="jobResponse.count"
              first-number
              last-number
              align="right"
              :per-page="1"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal
      ref="job_details"
      cancel-variant="outline-secondary"
      hide-footer
      scrollable
      size="lg"
      :title="jobDetails.job_title"
      @hide="closeJobPopup"
      :no-close-on-backdrop="true"
    >
      <b-media
        no-body
        class="align-items-center"
      >
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              icon="UserIcon"
              size="18"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
            {{ jobDetails.client }}
          </h6>
        </b-media-body>
      </b-media>
      <b-media
        no-body
        class="align-items-center mt-2"
      >
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              icon="DollarSignIcon"
              size="18"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
            ${{ jobDetails.bill_rate }}/HR
          </h6>
          <small>Offer Rate: ${{ jobDetails.offer_rate }}/HR (W2)</small>
        </b-media-body>
      </b-media>
      <b-row>
        <b-col cols="12"
          lg="6"
        >
          <b-media
            no-body
            class="align-items-center mt-2"
          >
            <b-media-aside class="mr-1">
              <b-avatar
                rounded
                variant="light-primary"
                size="34"
              >
                <feather-icon
                  icon="UserPlusIcon"
                  size="18"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="mb-0">
                {{ jobDetails.assigned_to }}
              </h6>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col cols="12"
          lg="6"
        >
          <b-media
            no-body
            class="align-items-center mt-2"
          >
            <b-media-aside class="mr-1">
              <b-avatar
                rounded
                variant="light-primary"
                size="34"
              >
                <feather-icon
                  icon="CalendarIcon"
                  size="18"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="mb-0">
                {{ jobDetails.open_date }}
              </h6>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
      <div class="mt-2">
        <h5>Requirements</h5>
      </div>
      <p
        v-html="jobDetails.requirements"
        class="job-details"
      ></p>
    </b-modal>
    <b-modal
      ref="add_advertise"
      cancel-variant="outline-secondary"
      hide-footer
      scrollable
      size="lg"
      title="Add Advertise"
      @hide="closeAddAdvertisePopup"
      :no-close-on-backdrop="true"
    >
      <validation-observer ref="addAdvertiseRules">
        <b-form>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label>Job Board Type</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Job Board"
                  rules="required"
                >
                  <v-select
                    id="job_board"
                    v-model="addAdvertise.board_type_id"
                    :options="jobBoardTyps"
                    :reduce="item => item.id"
                    :clearable="false"
                    placeholder="Select"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                id="start_date"
              >
                <label>Start Date</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Start Date"
                  rules="required"
                >
                  <flat-pickr
                    v-model="start_date"
                    class="form-control"
                    :config="start_date_config"
                    placeholder="MM-DD-YYYY"
                    @input="startDateChanged()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                id="preferred_end_date"
              >
                <label>Proposed Closed Date</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Proposed Closed Date"
                  rules="required"
                >
                  <flat-pickr
                    v-model="preferred_end_date"
                    class="form-control"
                    :config="preferred_end_date_config"
                    placeholder="MM-DD-YYYY"
                    @input="proposedClosedDateChanged()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label>Amount</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Amount"
                  rules="required"
                >
                  <b-input-group
                    prepend="$"
                  >
                    <money
                      id="amount"
                      v-model="addAdvertise.amount"
                      :min="0"
                      :step="0.5"
                      v-bind="money"
                      class="form-control"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label>Job Board Id</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Job Board Id"
                  rules="required"
                >
                  <b-form-input
                    id="job_board_id"
                    v-model="addAdvertise.job_board_id"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <footer class="modal-footer">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="hideModal"
            >
              Close
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="button"
              @click="submitAddAdvertise"
            >
              Save
            </b-button>
          </footer>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
      ref="close_advertise"
      cancel-variant="outline-secondary"
      hide-footer
      scrollable
      size="lg"
      title="Close Advertise"
      @hide="closeAdvertisePopup"
      :no-close-on-backdrop="true"
    >
      <validation-observer ref="closeAdvertiseRules">
        <b-form>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                id="closed_date"
              >
                <label>Closed Date</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Closed Date"
                  rules="required"
                >
                  <flat-pickr
                    v-model="closed_date"
                    class="form-control"
                    :config="closed_date_config"
                    placeholder="MM-DD-YYYY"
                    @input="closedDateChanged()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label>Views</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Views"
                  rules="required"
                >
                  <b-form-input
                    id="views"
                    v-model="closeAdvertise.views"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label>Applied</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Applied"
                  rules="required"
                >
                  <b-form-input
                    id="applied"
                    v-model="closeAdvertise.applied"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label>Bill Amount</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Bill Amount"
                  rules="required"
                >
                  <b-input-group
                    prepend="$"
                  >
                    <money
                      id="amount"
                      v-model="closeAdvertise.billed_amount"
                      :min="0"
                      :step="0.5"
                      v-bind="money"
                      class="form-control"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <footer class="modal-footer">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="hideCloseAdvertiseModal"
            >
              Close
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="button"
              @click="submitCloseAdvertise"
            >
              Save
            </b-button>
          </footer>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BForm, BCol, BFormGroup, BBadge, BPagination, BInputGroup, BFormInput, BFormCheckbox, BInputGroupAppend, BDropdown, BDropdownItem, BLink, BButton, BModal, VBModal, BMedia, BMediaBody, BMediaAside, BAvatar, VBTooltip,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import jobAdvertise from '@/js/jobAdvertise'
import LIST from '@/js/lists'
import Jobs from '@/js/jobs'
import constant from '@/js/constant'
import moment from 'moment'

export default {
  name: 'JobRequests',
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BForm,
    vSelect,
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    BBadge,
    BPagination,
    BInputGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroupAppend,
    BLink,
    BButton,
    BModal,
    BDropdown,
    BDropdownItem,
    BMedia,
    BMediaBody,
    BMediaAside,
    BAvatar,
    flatPickr,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      required,
      addAdvertise: {
        board_type_id: null,
        amount: '',
        job_board_id: '',
      },
      closeAdvertise: {
        end_date: null,
        views: '',
        applied: '',
        actual_amount: '',
      },
      assignUsers: {
        job_id: null,
        assigned_user_ids: [],
      },
      advertised_jobs: false,
      status_id: null,
      facility_id: null,
      vendor_id: null,
      skill_id: null,
      city_id: null,
      state_id: null,
      assigned_to_id: null,
      working_status_id: null,
      job_type: null,
      position_type: null,
      role_users_id: null,
      status: [],
      facilities: [],
      technologies: [],
      locations: [],
      states: [],
      vendors: [],
      assignedTo: [],
      roleUsers: [],
      jobTypeOptions: [],
      positionTypes: [],
      jobBoardTyps: [],
      searchQuery: '',
      pageLength: 10,
      dir: false,
      start_date: null,
      preferred_end_date: null,
      employee: {},
      jobDetails: {},
      job_id: null,
      jobResponse: {
        jobs: [],
        count: 1,
        pagination_message: '',
      },
      columns: [
        {
          label: 'Job Id',
          field: 'cellRendererJobId',
          sortable: false,
          width: '130px',
        },
        {
          label: 'Vendor',
          field: 'vendor',
          sortable: false,
        },
        {
          label: 'Title',
          field: 'job_title',
          sortable: false,
        },
        {
          label: 'State',
          field: 'state',
          minWidth: '100px',
          sortable: false,
        },
        {
          label: 'City',
          field: 'city',
          sortable: false,
        },
        {
          label: 'Rate ($)',
          field: 'cellRendererCurrency',
          width: '100px',
          sortable: false,
        },
        {
          label: 'Exp Years',
          field: 'experience',
          width: '90px',
          sortable: false,
        },
        {
          label: 'Entry Date',
          field: 'entry_date',
          width: '120px',
          sortable: false,
        },
        {
          label: 'Duration',
          field: 'duration',
          type: 'number',
          width: '100px',
          sortable: false,
        },
        {
          label: 'Status',
          field: 'status',
          width: '90px',
          sortable: false,
        },
        {
          label: 'Indeed Matched Prospects City / State',
          field: 'cellRendererIndeedLink',
          width: '160px',
          sortable: false,
        },
        {
          label: 'Matched Prospects City / State',
          field: 'cellRendererLink',
          width: '130px',
          sortable: false,
        },
        {
          label: 'Apply',
          field: 'cellRendererApplyButton',
          width: '90px',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          width: '100px',
          sortable: false,
        },
      ],
      start_date_config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'm-d-Y',
        altInput: true,
        dateFormat: 'm-d-Y',
        minDate: null,
        maxDate: new Date(),
      },
      preferred_end_date_config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'm-d-Y',
        altInput: true,
        dateFormat: 'm-d-Y',
        minDate: new Date(),
        maxDate: null,
      },
      closed_date_config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'm-d-Y',
        altInput: true,
        dateFormat: 'm-d-Y',
        minDate: new Date(moment().add(-1, 'W')),
        maxDate: new Date(),
      },
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
        if (this.status.length === 0) return
        Jobs.getJobs(this)
      },
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Active      : 'light-success',
        Inactive    : 'light-danger',
        Terminated  : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler() {
        let jobType = null
        let positionTypeId = null
        if (this.$route.query.job_type !== null && this.$route.query.job_type !== undefined && (this.$store.state.query.job_type === null || this.$store.state.query.job_type === undefined)) {
          this.$store.state.query.job_type = this.$route.query.job_type
          jobType = this.$store.state.query.job_type
        } else {
          jobType = null
        }
        if (this.positionTypes.length === 0) {
          LIST.getPositionTypeFullList(this)
        }
        const position = this.positionTypes.find(positionType => positionType.label === this.$route.query.position)
        if (position !== null && position !== undefined) positionTypeId = position.id
        if (this.job_type !== jobType || positionTypeId !== this.position_type) {
          if (this.job_type !== jobType) {
            this.job_type = jobType
          }
          if (this.position_type !== positionTypeId) {
            this.position_type = positionTypeId
            this.$store.state.query.position_type = positionTypeId
          }
          this.currentPage = 1
        }
      },
    },
    '$store.state.lists.commonLists.lists.vendors': {
      immediate: true,
      handler() {
        LIST.getVendors(this)
      },
    },
    '$store.state.lists.commonLists.lists.job_facilities': {
      immediate: true,
      handler() {
        LIST.getJobFacilities(this)
      },
    },
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        this.searchQuery = this.$store.state.query.query
        if (this.$route.query.vendor_id !== null && this.$route.query.vendor_id !== undefined && (this.$store.state.query.vendor_id === null || this.$store.state.query.vendor_id === undefined)) {
          this.$store.state.query.vendor_id = this.$route.query.vendor_id
        }
        if (this.$route.query.facility_id !== null && this.$route.query.facility_id !== undefined && (this.$store.state.query.facility_id === null || this.$store.state.query.facility_id === undefined)) {
          this.$store.state.query.facility_id = this.$route.query.facility_id
        }
        if (this.$route.query.skill_id !== null && this.$route.query.skill_id !== undefined && (this.$store.state.query.skill_id === null || this.$store.state.query.skill_id === undefined)) {
          this.$store.state.query.skill_id = this.$route.query.skill_id
        }
        if (this.$route.query.city_id !== null && this.$route.query.city_id !== undefined && (this.$store.state.query.city_id === null || this.$store.state.query.city_id === undefined)) {
          this.$store.state.query.city_id = this.$route.query.city_id
        }
        if (this.$route.query.state_id !== null && this.$route.query.state_id !== undefined && (this.$store.state.query.state_id === null || this.$store.state.query.state_id === undefined)) {
          this.$store.state.query.state_id = this.$route.query.state_id
        }
        if (this.$route.query.assigned_to_id !== null && this.$route.query.assigned_to_id !== undefined && (this.$store.state.query.assigned_to_id === null || this.$store.state.query.assigned_to_id === undefined)) {
          this.$store.state.query.assigned_to_id = this.$route.query.assigned_to_id
        }
        if (this.$route.query.job_type !== null && this.$route.query.job_type !== undefined && (this.$store.state.query.job_type === null || this.$store.state.query.job_type === undefined)) {
          this.$store.state.query.job_type = this.$route.query.job_type
        }
        if (this.$route.query.position_type !== null && this.$route.query.position_type !== undefined && (this.$store.state.query.position_type === null || this.$store.state.query.position_type === undefined)) {
          this.$store.state.query.position_type = this.$route.query.position_type
        }
        if (this.$route.query.advertised_jobs !== null && this.$route.query.advertised_jobs !== undefined && (this.$store.state.query.advertised_jobs === null || this.$store.state.query.advertised_jobs === undefined)) {
          this.$store.state.query.advertised_jobs = this.$route.query.advertised_jobs
        }
        this.vendor_id = Number(this.$store.state.query.vendor_id) || null
        this.city_id = Number(this.$store.state.query.city_id) || null
        this.skill_id = Number(this.$store.state.query.skill_id) || null
        this.state_id = Number(this.$store.state.query.state_id) || null
        this.facility_id = Number(this.$store.state.query.facility_id) || null
        this.working_status_id = Number(this.$store.state.query.working_status_id) || null
        this.assigned_to_id = Number(this.$store.state.query.assigned_to_id) || null
        this.job_type = this.$store.state.query.job_type
        this.position_type = Number(this.$store.state.query.position_type) || null
        if (this.$store.state.query.status_id !== null && this.$store.state.query.status_id !== undefined) {
          this.status_id = this.$store.state.query.status_id
        }
        if (this.$store.state.query.advertised_jobs !== null && this.$store.state.query.advertised_jobs !== undefined) {
          this.advertised_jobs = this.$store.state.query.advertised_jobs
        }
        LIST.getPositionTypeFullList(this)
        LIST.getJobFacilities(this)
        LIST.getVendors(this)
        LIST.getJobMainSkills(this)
        LIST.getJobCities(this)
        LIST.getJobStates(this)
        LIST.getJobStatusFullList(this)
        LIST.getJobRollUsers(this)
        LIST.getJobBoardTypes(this)
      },
    },
  },
  created() {
    this.searchQuery = this.$store.state.query.query
    if (this.$route.query.facility_id !== null && this.$route.query.facility_id !== undefined && (this.$store.state.query.facility_id === null || this.$store.state.query.facility_id === undefined)) {
      this.$store.state.query.facility_id = this.$route.query.facility_id
    }
    if (this.$route.query.skill_id !== null && this.$route.query.skill_id !== undefined && (this.$store.state.query.skill_id === null || this.$store.state.query.skill_id === undefined)) {
      this.$store.state.query.skill_id = this.$route.query.skill_id
    }
    if (this.$route.query.city_id !== null && this.$route.query.city_id !== undefined && (this.$store.state.query.city_id === null || this.$store.state.query.city_id === undefined)) {
      this.$store.state.query.city_id = this.$route.query.city_id
    }
    if (this.$route.query.state_id !== null && this.$route.query.state_id !== undefined && (this.$store.state.query.state_id === null || this.$store.state.query.state_id === undefined)) {
      this.$store.state.query.state_id = this.$route.query.state_id
    }
    if (this.$route.query.assigned_to_id !== null && this.$route.query.assigned_to_id !== undefined && (this.$store.state.query.assigned_to_id === null || this.$store.state.query.assigned_to_id === undefined)) {
      this.$store.state.query.assigned_to_id = this.$route.query.assigned_to_id
    }
    if (this.$route.query.job_type !== null && this.$route.query.job_type !== undefined && (this.$store.state.query.job_type === null || this.$store.state.query.job_type === undefined)) {
      this.$store.state.query.job_type = this.$route.query.job_type
    }
    if (this.$route.query.position_type !== null && this.$route.query.position_type !== undefined && (this.$store.state.query.position_type === null || this.$store.state.query.position_type === undefined)) {
      this.$store.state.query.position_type = this.$route.query.position_type
    }
    this.city_id = Number(this.$store.state.query.city_id) || null
    this.skill_id = Number(this.$store.state.query.skill_id) || null
    this.state_id = Number(this.$store.state.query.state_id) || null
    this.facility_id = Number(this.$store.state.query.facility_id) || null
    this.working_status_id = Number(this.$store.state.query.working_status_id) || null
    this.assigned_to_id = Number(this.$store.state.query.assigned_to_id) || null
    this.position_type = Number(this.$store.state.query.position_type) || null
    this.job_type = this.$store.state.query.job_type
    if (this.$store.state.query.status_id !== null && this.$store.state.query.status_id !== undefined) {
      this.status_id = this.$store.state.query.status_id
    }
    this.jobResponse.count = this.$store.state.query.page
    LIST.getPositionTypeFullList(this)
    LIST.getJobFacilities(this)
    LIST.getVendors(this)
    LIST.getJobMainSkills(this)
    LIST.getJobCities(this)
    LIST.getJobStates(this)
    LIST.getJobRollUsers(this)
    LIST.getJobBoardTypes(this)
    constant.getJobTypeOptions(this)
  },
  methods: {
    submitAddAdvertise() {
      this.$refs.addAdvertiseRules.validate().then(result => {
        if (result) {
          if (this.start_date != null) {
            this.addAdvertise.start_date = constant.getEprmMonthDateFromString(this.start_date)
          }
          if (this.preferred_end_date != null) {
            this.addAdvertise.preferred_end_date = constant.getEprmMonthDateFromString(this.preferred_end_date)
          }
          jobAdvertise.addJobAdvertise(this, this.addAdvertise.job_id, this.addAdvertise)
          this.showLoading = true
        }
      })
    },
    hideModal() {
      this.addAdvertise = {
        job_board: null,
        amount: '',
        job_board_id: '',
      }
      this.closed_date = null
      this.preferred_end_date = null
      this.start_date = null
      this.$refs.add_advertise.hide()
    },
    hideCloseAdvertiseModal() {
      this.closed_date = null
      this.closeAdvertise = {
        end_date: null,
        views: '',
        applied: '',
        actual_amount: '',
      }
      this.$refs.close_advertise.hide()
    },
    submitCloseAdvertise() {
      this.$refs.closeAdvertiseRules.validate().then(result => {
        if (result) {
          if (this.closed_date != null) {
            this.closeAdvertise.end_date = constant.getEprmMonthDateFromString(this.closed_date)
          }
          jobAdvertise.closeJobAdvertise(this, this.closeAdvertise.job_id, this.closeAdvertise)
          this.showLoading = true
        }
      })
    },
    startDateChanged() {
      this.preferred_end_date_config.minDate = new Date(moment(this.start_date, 'MM-DD-YYYY'))
    },
    proposedClosedDateChanged() {
      this.start_date_config.maxDate = new Date(moment(this.preferred_end_date, 'MM-DD-YYYY'))
    },
    addAssignUsers() {
      if (this.assignUsers.assigned_user_ids.length > 0 && this.assignUsers.job_id !== null) {
        Jobs.assignUsersToJob(this, this.assignUsers.job_id, this.assignUsers)
      }
    },
    showJobDetails(jobDetails) {
      this.jobDetails = jobDetails
      this.$refs.job_details.show()
    },
    closeJobPopup() {
      this.jobDetails = {}
    },
    closeAddAdvertisePopup() {
      this.addAdvertise = {
        job_board: null,
        amount: '',
        job_board_id: '',
      }
      this.preferred_end_date = null
      this.start_date = null
    },
    closeAdvertisePopup() {
      this.closed_date = null
      this.closeAdvertise = {
        end_date: null,
      }
      this.$refs.close_advertise.hide()
    },
    addAdvertiseRecord(job) {
      this.addAdvertise.job_id = job.id
      this.$refs.add_advertise.show()
    },
    closeAdvertiseRecord(job) {
      this.closeAdvertise.job_id = job.id
      this.$refs.close_advertise.show()
    },
    setJobAdvertised(response) {
      this.hideModal()
      Jobs.getJobs(this)
      this.$swal({
        icon: 'success',
        title: 'Job Advertised!',
        text: response.return_message,
      })
    },
    setJobAdvertiseClosed(response) {
      this.closeAdvertisePopup()
      Jobs.getJobs(this)
      this.$swal({
        icon: 'success',
        title: 'Job Advertise Closed!',
        text: response.return_message,
      })
    },
    confirmDeleteRecord(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${obj.job_id}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          Jobs.delete(this, obj.id)
        }
      })
    },
    jobDeleted() {
      Jobs.getJobs(this)
      this.$swal({
        icon: 'success',
        title: 'Job Deleted!',
        text: 'Your Job has been deleted',
      })
    },
    jobNotDeleted() {
      this.$swal({
        icon: 'warning',
        title: 'Job Not Deleted!',
        text: 'Your Job has been not deleted',
      })
    },
    usersAssigned(data) {
      this.$refs.job_posted.hide()
      Jobs.getJobs(this)
      this.$swal({
        icon: 'success',
        title: 'Users Assigned!',
        text: data.return_message,
      })
    },
    usersNotAssigned(message) {
      this.$swal({
        icon: 'warning',
        title: 'Job Not Assigned!',
        text: message,
      })
    },
    setRollUsers(rollUsers) {
      this.roleUsers = rollUsers
    },
    filter() {
      if (this.$route.query.facility_id !== null && this.$route.query.facility_id !== undefined && (this.$store.state.query.facility_id === null || this.$store.state.query.facility_id === undefined)) {
        this.$router.replace({ query: { facility_id: this.facility_id } })
      }
      if (this.$route.query.skill_id !== null && this.$route.query.skill_id !== undefined && (this.$store.state.query.skill_id === null || this.$store.state.query.skill_id === undefined)) {
        this.$router.replace({ query: { skill_id: this.skill_id } })
      }
      if (this.$route.query.city_id !== null && this.$route.query.city_id !== undefined && (this.$store.state.query.city_id === null || this.$store.state.query.city_id === undefined)) {
        this.$router.replace({ query: { city_id: this.city_id } })
      }
      if (this.$route.query.state_id !== null && this.$route.query.state_id !== undefined && (this.$store.state.query.state_id === null || this.$store.state.query.state_id === undefined)) {
        this.$router.replace({ query: { state_id: this.state_id } })
      }
      if (this.$route.query.assigned_to_id !== null && this.$route.query.assigned_to_id !== undefined && (this.$store.state.query.assigned_to_id === null || this.$store.state.query.assigned_to_id === undefined)) {
        this.$router.replace({ query: { assigned_to_id: this.assigned_to_id } })
      }
      if (this.$route.query.job_type !== null && this.$route.query.job_type !== undefined && (this.$store.state.query.job_type === null || this.$store.state.query.job_type === undefined)) {
        this.$router.replace({ query: { job_type: this.job_type } })
      }
      if (this.$route.query.position_type !== null && this.$route.query.position_type !== undefined && (this.$store.state.query.position_type === null || this.$store.state.query.position_type === undefined)) {
        this.$router.replace({ query: { position_type: this.position_type } })
      }
      if (this.$route.query.working_status_id !== null && this.$route.query.working_status_id !== undefined && (this.$store.state.query.working_status_id === null || this.$store.state.query.working_status_id === undefined)) {
        this.$router.replace({ query: { working_status_id: this.working_status_id } })
      }
      this.$store.state.query.query = this.searchQuery
      this.currentPage = 1
    },
    vendorChanged() {
      this.$store.state.query.vendor_id = this.vendor_id
    },
    facilityChanged() {
      this.$store.state.query.facility_id = this.facility_id
    },
    technologyChanged() {
      this.$store.state.query.skill_id = this.skill_id
    },
    stateChanged() {
      this.$store.state.query.state_id = this.state_id
    },
    locationChanged() {
      this.$store.state.query.city_id = this.city_id
    },
    statusChanged() {
      this.$store.state.query.status_id = this.status_id
    },
    jobTypeChanged() {
      this.$store.state.query.job_type = this.job_type
    },
    positionTypeChanged() {
      this.$store.state.query.position_type = this.position_type
    },
    assignUserChanged() {
      this.$store.state.query.assigned_to_id = this.assigned_to_id
    },
    updateSearchQuery() {
      this.$store.state.query.query = this.searchQuery
      this.currentPage = 1
    },
    setPositionTypes(types) {
      this.positionTypes = types
      let positionTypeId = null
      const position = this.positionTypes.find(positionType => positionType.label === this.$route.query.position)
      if (position !== null && position !== undefined) positionTypeId = position.id
      if (positionTypeId !== null && positionTypeId !== undefined && positionTypeId !== this.position_type) {
        this.position_type = positionTypeId
        this.$store.state.query.position_type = positionTypeId
      }
    },
    setJobs(jobResponse) {
      this.jobResponse = jobResponse
    },
    setFacilities(facilities) {
      this.facilities = facilities
    },
    setVendors(vendors) {
      this.vendors = vendors
    },
    setMainSkills(technologies) {
      this.technologies = technologies
    },
    setCities(locations) {
      this.locations = locations
    },
    setJobBoardTypes(jobBoardTyps) {
      this.jobBoardTyps = jobBoardTyps
    },
    setStates(states) {
      this.states = states
    },
    setStatus(status) {
      this.status = status
      if (this.$store.state.query.status_id !== null && this.$store.state.query.status_id !== undefined) {
        this.status_id = this.$store.state.query.status_id
        Jobs.getJobs(this)
        return
      }
      if (this.status_id === null || this.status_id === undefined) {
        const item = this.status.find(item1 => item1.label === 'Active')
        if (item != null) {
          this.status_id = item.id
          this.$store.state.query.status_id = this.status_id
          Jobs.getJobs(this)
        }
      } else {
        Jobs.getJobs(this)
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
