<template>
  <b-card
    no-body
    class="shadow-none mb-0"
  >
    <b-card-body class="py-25">
      <b-row>
        <b-col
          cols="12"
        >
          <div class="d-flex align-items-center justify-content-end">
            <div class="d-flex align-items-center justify-content-start min-xl-w-270 min-xxl-w-300 mr-50">
              <strong>Total Emails: {{ emails_count }} / {{ totalProspects }}</strong>
              <b-button
                v-show="emails_count > 0"
                variant="primary"
                size="sm"
                class="ml-2"
                @click="copyText"
              >
                Copy Emails
              </b-button>
            </div>
            <b-input-group>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                @keyup.enter="updateSearchQuery"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="updateSearchQuery"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="14"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button
              variant="outline-secondary"
              class="ml-2"
              @click="back"
            >
              Back
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BRow, BCol, BInputGroup, BFormInput, BInputGroupAppend, BButton,
} from 'bootstrap-vue'
import LIST from '@/js/lists'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  data() {
    return {
      totalProspects: 0,
      searchQuery: '',
      skills: [],
      states: [],
      roleUsers: [],
      emails_count: 0,
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getMainSkills(this)
        LIST.getStatesFullList(this)
        LIST.getRollUsers(this)
      },
    },
  },
  created() {
    LIST.getMainSkills(this)
    LIST.getStatesFullList(this)
    LIST.getRollUsers(this)
    this.jobId = this.$route.query.job_id
    this.stateId = this.$route.query.state_id
    this.skillId = this.$route.query.skill_id
  },
  methods: {
    updateSearchQuery() {
      this.$emit('searchUpdated', this.searchQuery)
    },
    copyText() {
      this.$emit('copyText')
    },
    setMainSkills(skills) {
      this.skills = skills
    },
    setStates(states) {
      this.states = states
    },
    setRollUsers(rollUsers) {
      this.roleUsers = rollUsers
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
</style>
