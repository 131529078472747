<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          View Exception</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>

    <app-timeline>
      <app-timeline-item class="pb-1">
        <b-row>
          <b-col
            cols="12"
            lg="10"
            offset="1"
          >
            <b-form-group>
              <label>Subject</label>
              <span
                class="form-control height-auto min-h-38"
                v-text="exception.subject"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            lg="10"
            offset="1"
          >
            <b-form-group>
              <label>Description</label>
              <span
                class="form-control height-auto min-h-38"
                v-text="exception.detail"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BButton,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Exceptions from '@/js/systemexception'

export default {
  name: 'ExceptionNew',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BFormGroup,
    BButton,
  },
  directives: {
  },
  setup() {
  },
  data() {
    return {
      exception: {},
    }
  },
  mounted() {
  },
  created() {
    Exceptions.getExceptionById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    setException(exception) {
      this.exception = exception
    },
    back() {
      this.$router.push('/exceptions').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.repeater-form {
  transition: .35s height;
}
</style>
