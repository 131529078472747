<template>
  <div class="sidebar-left sidebar-left-custom">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div class="email-app-menu">
          <div class="form-group-compose text-center border-bottom-1 py-1">
            <h5 class="mb-0">Open Jobs</h5>
          </div>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list sidebar-menu-list-custom scroll-area"
          >
            <div class="matcher-tree pl-2 pt-50">
              <v-tree
                ref="tree"
                :data="treeData"
                :multiple="false"
                @node-check="nodeChecked"
                @node-click="nodeclicked"
                @node-expand="nodeExpanded"
                @async-load-nodes="asyncLoad"
              ></v-tree>
            </div>
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
    <b-modal
      ref="job_details"
      cancel-variant="outline-secondary"
      hide-footer
      scrollable
      size="lg"
      :title="jobDetails.job_title"
      @hide="closeJobPopup"
      :no-close-on-backdrop="true"
    >
      <b-media
        no-body
        class="align-items-center"
      >
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              icon="UserIcon"
              size="18"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
            {{ jobDetails.client }}
          </h6>
        </b-media-body>
      </b-media>
      <b-media
        no-body
        class="align-items-center mt-2"
      >
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              icon="DollarSignIcon"
              size="18"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
            ${{ jobDetails.bill_rate }}/HR
          </h6>
          <small>Offer Rate: ${{ jobDetails.offer_rate }}/HR (W2)</small>
        </b-media-body>
      </b-media>
      <b-row>
        <b-col cols="12"
          lg="6"
        >
          <b-media
            no-body
            class="align-items-center mt-2"
          >
            <b-media-aside class="mr-1">
              <b-avatar
                rounded
                variant="light-primary"
                size="34"
              >
                <feather-icon
                  icon="UserPlusIcon"
                  size="18"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="mb-0">
                {{ jobDetails.assigned_to }}
              </h6>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col cols="12"
          lg="6"
        >
          <b-media
            no-body
            class="align-items-center mt-2"
          >
            <b-media-aside class="mr-1">
              <b-avatar
                rounded
                variant="light-primary"
                size="34"
              >
                <feather-icon
                  icon="CalendarIcon"
                  size="18"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="mb-0">
                {{ jobDetails.open_date }}
              </h6>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
      <div class="mt-3">
        <h5>Requirements</h5>
      </div>
      <p
        v-html="jobDetails.requirements"
        class="job-details"
      ></p>
    </b-modal>
  </div>
</template>

<script>
import {
  ref,
} from '@vue/composition-api'
import {
  BModal, BMedia, BMediaBody, BMediaAside, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import { VTree } from 'vue-tree-halower'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import ProspectMatcher from '@/js/prospectmatcher'

export default {
  components: {
    VTree,
    VuePerfectScrollbar,
    BModal,
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      jobId: null,
      stateId: null,
      skillId: null,
      assignedUserId: null,
      jobDetails: {},
      sourceType: null,
      node: {},
      city: '',
      treeData: [],
      selectedNode: null,
    }
  },
  props: {
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const isTaskHandlerSidebarActive = ref(false)

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    return {
      perfectScrollbarSettings,
      isTaskHandlerSidebarActive,
      mqShallShowLeftSidebar,
    }
  },
  created() {
    this.jobId = this.$route.query.job_id
    this.stateId = this.$route.query.state_id
    this.skillId = this.$route.query.skill_id
    this.sourceType = this.$route.query.source_type
    this.city = this.$route.query.city
    if (this.jobId !== null && this.jobId !== undefined) {
      ProspectMatcher.getJobsBySkill(this, this.stateId !== null && this.stateId !== undefined ? `?job_id=${this.jobId}&city=${this.city}&state_id=${this.stateId}&source_type=${this.sourceType}` : `?job_id=${this.jobId}&city=${this.city}&source_type=${this.sourceType}`)
      this.$emit('loadProspects', this.stateId !== null && this.stateId !== undefined ? `?job_id=${this.jobId}&city=${this.city}&state_id=${this.stateId}&source_type=${this.sourceType}` : `?job_id=${this.jobId}&city=${this.city}&source_type=${this.sourceType}`)
      return
    }
    ProspectMatcher.getSkills(this, '')
  },
  methods: {
    reloadData() {
      if (this.jobId !== null && this.jobId !== undefined) {
        ProspectMatcher.getJobsBySkill(this, `?job_id=${this.jobId}`)
        this.$emit('loadProspects', this.stateId !== null && this.stateId !== undefined ? `?job_id=${this.jobId}&city=${this.city}&state_id=${this.stateId}&source_type=${this.sourceType}` : `?job_id=${this.jobId}&city=${this.city}&source_type=${this.sourceType}`)
        return
      }
      ProspectMatcher.getSkills(this, '')
    },
    setSkillsTree(data) {
      this.treeData = data.lists
    },
    closeJobPopup() {
      this.jobDetails = {}
      this.node.checked = false
      this.node = {}
    },
    userChanged(assignedUserId) {
      this.assignedUserId = assignedUserId
      this.loadSkillProspects()
      this.$emit('clearProspects')
    },
    stateChanged(stateId) {
      this.stateId = stateId
      this.loadSkillProspects()
      this.$emit('clearProspects')
    },
    skillChanged(skillId) {
      this.skillId = skillId
      this.loadSkillProspects()
      this.$emit('clearProspects')
    },
    loadSkillProspects() {
      let query = ''
      if (this.skillId !== null && this.skillId !== undefined) {
        query += `skill_id=${this.skillId}`
      }
      if (this.stateId !== null && this.stateId !== undefined) {
        if (query !== '') query += '&'
        query += `state_id=${this.stateId}`
      }
      if (this.assignedUserId !== null && this.assignedUserId !== undefined) {
        if (query !== '') query += '&'
        query += `assigned_to=${this.assignedUserId}`
      }
      if (this.jobId !== null && this.jobId !== undefined) {
        if (query !== '') query += '&'
        query += `job_id=${this.jobId}`
      }
      if (this.sourceType !== null && this.sourceType !== undefined) {
        if (query !== '') query += '&'
        query += `source_type=${this.sourceType}`
      }
      if (this.city !== null && this.city !== undefined) {
        if (query !== '') query += '&'
        query += `city=${this.city}`
      }
      query = `?${query}`
      ProspectMatcher.getSkills(this, query)
    },
    loadStateProspects() {
      let query = ''
      if (this.skillId !== null && this.skillId !== undefined) {
        query += `skill_id=${this.skillId}`
      }
      if (this.assignedUserId !== null && this.assignedUserId !== undefined) {
        if (query !== '') query += '&'
        query += `assigned_to=${this.assignedUserId}`
      }
      if (this.jobId !== null && this.jobId !== undefined) {
        if (query !== '') query += '&'
        query += `job_id=${this.jobId}`
      }
      if (this.sourceType !== null && this.sourceType !== undefined) {
        if (query !== '') query += '&'
        query += `source_type=${this.sourceType}`
      }
      if (this.city !== null && this.city !== undefined) {
        if (query !== '') query += '&'
        query += `city=${this.city}`
      }
      if (this.stateId !== null && this.stateId !== undefined) {
        if (query !== '') query += '&'
        query += `state_id=${this.stateId}`
      } else {
        this.loadSkillProspects()
        return
      }
      query = `?${query}`
      ProspectMatcher.getStatesByJobId(this, query)
    },
    loadProspects() {
      let query = ''
      if (this.skillId !== null && this.skillId !== undefined) {
        query += `skill_id=${this.skillId}`
      } else {
        return
      }
      if (this.assignedUserId !== null && this.assignedUserId !== undefined) {
        if (query !== '') query += '&'
        query += `assigned_to=${this.assignedUserId}`
      }
      if (this.jobId !== null && this.jobId !== undefined) {
        if (query !== '') query += '&'
        query += `job_id=${this.jobId}`
      }
      if (this.stateId !== null && this.stateId !== undefined) {
        if (query !== '') query += '&'
        query += `state_id=${this.stateId}`
      }
      if (this.city !== null && this.city !== undefined) {
        if (query !== '') query += '&'
        query += `city=${this.city}`
      }
      query = `?${query}`
      this.$emit('loadProspects', query)
    },
    setChildTree(data, node) {
      if (node === null || node === undefined) {
        this.treeData = data.lists
        return
      }
      if (!Object.prototype.hasOwnProperty.call(node, 'children')) {
        this.$set(node, 'children', [])
      }
      this.$set(node, 'children', [])
      node.children.push(...data.lists)
    },
    nodeChecked(node) {
      this.jobDetails = node.job_popup_item
      if (this.node) {
        this.node.selected = false
        this.node.expanded = false
      }
      this.node = node
      this.$refs.job_details.show()
    },
    nodeclicked(nodes) {
      const node = nodes
      if (this.node) {
        this.node.selected = false
      }
      this.node = node
      if (node.selected && !node.expanded) node.expanded = true
      if (!node.expanded) return
      node.checked = false
      if (node.query !== undefined && node.query !== null) {
        this.$emit('loadProspects', node.query)
      }
      if (node.next_load === 'jobs') {
        ProspectMatcher.getJobsBySkill(this, node.query, node)
      }
      if (node.next_load === 'states') {
        ProspectMatcher.getStatesByJobId(this, node.query, node)
      }
      if (node.next_load === 'cities') {
        ProspectMatcher.getCitiesByStateId(this, node.query, node)
      }
    },
    nodeExpanded(nodes) {
      const node = nodes
      if (!node.expanded) return
      node.checked = false
      if (node.query !== undefined && node.query !== null) {
        this.$emit('loadProspects', node.query)
      }
      if (node.next_load === 'jobs') {
        ProspectMatcher.getJobsBySkill(this, node.query, node)
      }
      if (node.next_load === 'states') {
        ProspectMatcher.getStatesByJobId(this, node.query, node)
      }
      if (node.next_load === 'cities') {
        ProspectMatcher.getCitiesByStateId(this, node.query, node)
      }
    },
    async asyncLoad(nodes) {
      const node = nodes
      node.checked = false
      if (node.next_load === 'jobs') {
        ProspectMatcher.getJobsBySkill(this, node.query, node)
      }
      if (node.next_load === 'states') {
        ProspectMatcher.getStatesByJobId(this, node.query, node)
      }
      if (node.next_load === 'cities') {
        ProspectMatcher.getCitiesByStateId(this, node.query, node)
      }
    },
  },
}
</script>

<style>
/* Define your icon styles here */
.icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: #007bff; /* Background color for the icon */
  border-radius: 50%; /* Make it circular (adjust as needed) */
  margin-right: 5px; /* Add some spacing to the right of the icon */
  /* You can further customize the icon's appearance here, e.g., color, font-size, etc. */
}
.tree-node {
  display: flex;
  align-items: center;
}

.checkbox-icon {
  cursor: pointer;
  margin-right: 8px; /* Adjust spacing as needed */
  font-size: 16px; /* Adjust icon size as needed */
}

.checkbox-icon i {
  display: inline-block;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  color: #000; /* Adjust the color as needed */
  background-color: #fff; /* Background color when not checked */
  border: 1px solid #ccc; /* Border when not checked */
  border-radius: 4px;
}

.checkbox-icon i.fa-check {
  background-color: #007bff; /* Background color when checked */
  color: #fff; /* Color when checked */
}

</style>
