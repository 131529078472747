var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"mb-0"},[_c('div',{staticClass:"mb-1"},[_c('h4',{staticClass:"card-title mb-0 pt-50"},[_vm._v(" Closed Jobs")])]),_c('div',[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.jobResponse.closed_jobs,"rtl":_vm.direction,"search-options":{
        enabled: true,
        externalQuery: _vm.searchTerm },"pagination-options":{
        perPage:_vm.pageLength,
        enabled: true,
        mode: 'pages',
        setCurrentPage: _vm.currentPage,
      }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.fieldType === 'cellRendererJobClass')?_c('span',[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],attrs:{"title":props.row.skill_description}},[_vm._v(_vm._s(props.row.skill))])]):_vm._e(),(props.column.field === 'cellRendererUpdateBtn')?_c('span',[_c('b-button',{staticClass:"px-50 py-50",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.updateIndeedJob(props)}}},[_vm._v(" Update ")])],1):_vm._e(),(props.column.fieldType === 'cellRendererIndeed')?_c('span',[_c('img',{staticClass:"ml-50 img-fluid",attrs:{"width":"24","height":"24","src":require('@/assets/images/icons/indeed_logo.png'),"alt":"image slot"},on:{"click":function($event){return _vm.openIndeedJob(props.row)}}})]):_c('span',[(props.column.fieldType !== 'cellRendererJobClass' && props.column.fieldType !== 'cellRendererIndeed')?_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")]):_vm._e()])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }