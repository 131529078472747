import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.hrurl
const onboarding = `${HOST}onboarding`

export default {
  getOnboardings(context1) {
    const context = context1
    let params = { query: context.searchQuery, page: context.currentPage - 1 }
    if (params !== {}) {
      params = { params: { query: context.searchQuery, page: context.currentPage - 1 } }
    } else {
      params = { params: {} }
    }
    axios.get(onboarding, params).then(response => {
      context.setOnboardings(response.data)
    }, () => {
    })
  },
  addOnboarding(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(onboarding, body).then(response => {
      context.$vs.loading.close()
      context.onboardingAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.onboardingNotAdded(error.response.data.return_message)
    })
  },
  updateOnboarding(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${onboarding}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.onboardingUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.onboardingNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${onboarding}/${id}`).then(response => {
      context.$vs.loading.close()
      context.onboardingDeleted(response.data)
    }, error => {
      context.$vs.loading.close()
      context.onboardingNotDeleted(error.response.data.return_message)
    })
  },
  getOnboardingById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${onboarding}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setOnboarding(response.data)
    }, error => {
      context.$vs.loading.close()
      context.onboardingNotFound(error.response.data.return_message)
    })
  },

}
