import Constants from '@/js/constant'
import axios from 'axios'

const HOST = Constants.hosts.hrurl
const LIST = `${HOST}prospect-admin-dashboard`

export default {
  getCountByFacility(context) {
    axios.get(`${LIST}/by-facility`).then(response => {
      context.setCountByFacility(response.data)
    })
  },
  getCountBySkill(context) {
    axios.get(`${LIST}/by-skill`).then(response => {
      context.setCountBySkill(response.data)
    })
  },
  getCountByLocation(context) {
    axios.get(`${LIST}/by-location`).then(response => {
      context.setCountByLocation(response.data)
    })
  },
  getCountByLocationState(context) {
    axios.get(`${LIST}/by-location-state`).then(response => {
      context.setCountByLocationState(response.data)
    })
  },
  getDashboardCount(context) {
    axios.get(LIST).then(response => {
      context.setDashboardCount(response.data)
    })
  },
}
