import { render, staticRenderFns } from "./LayoutHorizontal.vue?vue&type=template&id=1d1d6b18&"
import script from "./LayoutHorizontal.vue?vue&type=script&lang=js&"
export * from "./LayoutHorizontal.vue?vue&type=script&lang=js&"
import style0 from "./LayoutHorizontal.vue?vue&type=style&index=0&id=1d1d6b18&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports