import Home from '@/views/Home.vue'
import Companies from '@/views/companies/Companies.vue'
import CompaniesNew from '@/views/companies/actions/New.vue'
import Tasks from '@/views/tasks/Tasks.vue'
import TasksNew from '@/views/tasks/actions/New.vue'
import TasksEdit from '@/views/tasks/actions/Edit.vue'
import TasksView from '@/views/tasks/actions/View.vue'
import ParentTasksView from '@/views/tasks/actions/ParentTasksView.vue'
import NewCreateTask from '@/views/tasks/actions/NewCreateTask.vue'
import Exceptions from '@/views/exceptions/Exceptions.vue'
import ExceptionsView from '@/views/exceptions/actions/View.vue'
import AppLogin from '@/views/Login.vue'
import ForgotPassword from '@/views/ForgotPassword.vue'
import CreatePassword from '@/views/CreatePassword.vue'
import AdminLogin from '@/views/AdminLogin.vue'
import MSA from '@/views/MSA.vue'
import PO from '@/views/PO.vue'
import OL from '@/views/OL.vue'
import ClientLetter from '@/views/ClientLetter.vue'

import Error404 from '@/views/error/Error404.vue'

export default [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      pageTitle: 'Home',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
    },
  },
  // *------------ Companies --------------------*
  {
    path: '/companies',
    name: 'companies',
    component: Companies,
  },
  {
    path: '/companies/new',
    name: 'company-new',
    component: CompaniesNew,
    meta: {
      navActiveLink: 'companies',
    },
  },
  // *------------ Tasks --------------------*
  {
    path: '/tasks',
    name: 'tasks',
    component: Tasks,
  },
  {
    path: '/tasks/new',
    name: 'tasks-new',
    component: TasksNew,
    meta: {
      navActiveLink: 'tasks',
    },
  },
  {
    path: '/tasks/edit/:id',
    name: 'tasks-edit',
    component: TasksEdit,
    meta: {
      navActiveLink: 'tasks',
    },
  },
  {
    path: '/tasks/view/:id',
    name: 'tasks-view',
    component: TasksView,
    meta: {
      navActiveLink: 'tasks',
    },
  },
  {
    path: '/tasks/parent/:parent_type_id/:parent_id',
    name: 'parent-task-view',
    component: ParentTasksView,
    meta: {
      navActiveLink: 'tasks',
    },
  },
  {
    path: '/tasks/:id/create-task',
    name: 'new-create-task',
    component: NewCreateTask,
    meta: {
      navActiveLink: 'tasks',
    },
  },

  // *------------ Exceptions --------------------*
  {
    path: '/exceptions',
    name: 'exceptions',
    component: Exceptions,
  },
  // *------------ MSA --------------------*
  {
    path: '/msa/:token',
    name: 'msa',
    component: MSA,
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/po/:token',
    name: 'po',
    component: PO,
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/ol/:token',
    name: 'offerletter',
    component: OL,
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/cl/:token',
    name: 'clientletter',
    component: ClientLetter,
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/exceptions/view/:id',
    name: 'exceptions-view',
    component: ExceptionsView,
    meta: {
      navActiveLink: 'exceptions',
    },
  },
  // *------------ Login --------------------*
  {
    path: '/login',
    name: 'login',
    component: AppLogin,
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/create-password/:token',
    name: 'create-password',
    component: CreatePassword,
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/admin-login/:token',
    name: 'admin-login',
    component: AdminLogin,
    meta: {
      rule: 'editor',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: Error404,
    meta: {
      layout: 'full',
    },
  },
]
