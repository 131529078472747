import axios from 'axios'
import User from '@/js/user'
import Constant from '@/js/constant'
import LIST from '@/js/lists'

// 'loggedIn' is used in other parts of application. So, Don't forget to change there also
const HOST = Constant.hosts.url
const LOGIN_URL = 'oauth/token'

const instance = axios.create({
  baseURL: HOST,
  timeout: 100000,
  headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
})

export default {
  login(context1, creds) {
    const context = context1
    context.$vs.loading()
    context.$set(context, 'loadings', true)
    localStorage.removeItem('logoutReason')
    context.error = ''
    const data = creds
    data.grant_type = 'password'
    data.client_id = 'web_client'
    data.client_secret = 'onMszOkl9Xh6JiXEyJu1YmQKveCSbuNVoVIRzOWEf3Ll2BYULmmKhVmZ1R3t'
    data.redirect_uri = 'http://eprm.com'
    const rawData = []
    Object.keys(data).forEach(key => rawData.push(`${key}=${data[key]}`))
    const body = rawData.join('&')
    instance.post(LOGIN_URL, body).then(response => {
      context.$vs.loading.close()
      localStorage.setItem('logged_email', creds.username)
      User.getProfile(context, response)
      LIST.getAllListsByToken(context, response.data.access_token)
    }, () => {
      context.$vs.loading.close()
      context.$swal({
        title: 'Login',
        text: 'Invalid login details',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    })
  },
  isAuthenticated() {
    if (localStorage.getItem('access_token') !== null) {
      return localStorage.getItem('access_token')
    }
    return false
  },
}
