import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.hrurl
const workforceMail = `${HOST}workforce-mail`

export default {
  getMails(context1, id) {
    const context = context1
    axios.get(`${workforceMail}/${id}`).then(response => {
      context.setMailSents(response.data)
    }, () => {
    })
  },
  addMailSent(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${workforceMail}/${body.user_id}`, body).then(response => {
      context.$vs.loading.close()
      context.setMailSents(response.data, body.user_id)
    }, error => {
      context.$vs.loading.close()
      context.sentMailNotAdded(error.response.data.return_message)
    })
  },

}
