<template>
  <b-card
    class="mb-0"
  >
    <div class="mb-50">
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-1"
        >
          <h4 class="card-title mb-0 pt-50">
            Jobs</h4>
        </b-col>
        <b-col
          cols="12"
          md="8 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              variant="primary"
              class="ml-2 min-w-120"
              :to="{ name: 'human-resources-prospects-admin-group-jobs-jobs-new'}"
            >
              Add Job
            </b-button>
            <b-button
              variant="outline-secondary"
              class="ml-2"
              @click="back"
            >
              Back
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <vue-good-table
      :columns="columns"
      :rows="jobResponse.jobs"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        perPage:pageLength,
        enabled: true,
        mode: 'pages',
        setCurrentPage: currentPage,
      }"
      @on-page-change="onPageChange"
      @on-per-page-change="onPerPageChange"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Job Id -->
        <span v-if="props.column.fieldType === 'cellRendererJobId'">
          <b-link v-if="props.row.job_path !== null" :href="props.row.job_path" target="_blank">
            <img
              class="mr-25 mb-25 img-fluid"
              width="24"
              height="24"
              :src="require('@/assets/images/icons/stafferlink.png')"
              alt="image slot"
            >
          </b-link>
          <b-link @click="showJobDetails(props.row.job_popup_item)"> {{ props.row.job_id }}</b-link>
          <b-link
            v-b-tooltip.hover.v-primary
            :title="props.row.advertised_description"
            :href="props.row.advertised_link"
            target="blank"
          >
            <img
              v-show="props.row.is_advertised"
              class="ml-25 img-fluid"
              width="24"
              height="24"
              :src="require('@/assets/images/icons/advertise.png')"
              alt="image slot"
            >
          </b-link>
        </span>

        <!-- Column: Currency -->
        <span v-if="props.column.fieldType === 'cellRendererCurrency'">
          <span>{{ props.row.rate| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
        </span>

        <!-- Column: Indeed Matched Prospects -->
        <span
          v-if="props.column.fieldType === 'cellRendererIndeedLink'"
          class="position-relative"
        >
          <b-button
            variant="outline-primary"
            :to="{ name: 'human-resources-prospects-admin-prospect-matcher', query: { job_id: props.row.id, source_type: 'indeed', city: props.row.city, state_id: props.row.state_id } }"
            class="px-50 py-25"
          >
          {{ props.row.indeed_city_matched_prospects }}
          </b-button>
          /
          <b-button
            variant="outline-primary"
            :to="{ name: 'human-resources-prospects-admin-prospect-matcher', query: { job_id: props.row.id, source_type: 'indeed' } }"
            class="px-50 py-25"
          >
          {{ props.row.indeed_matched_prospects }}
          </b-button>
        </span>

        <!-- Column: Button -->
        <span
          v-if="props.column.fieldType === 'cellRendererLink'"
          class="position-relative"
        >
          <b-button
            variant="outline-primary"
            :to="{ name: 'human-resources-prospects-admin-prospect-matcher', query: { job_id: props.row.id, city: props.row.city, state_id: props.row.state_id } }"
            class="px-50 py-25"
          >
          {{ props.row.city_matched_prospects }}
          </b-button>
          /
          <b-button
            variant="outline-primary"
            :to="{ name: 'human-resources-prospects-admin-prospect-matcher', query: { job_id: props.row.id } }"
            class="px-50 py-25"
          >
          {{ props.row.matched_prospects }}
          </b-button>
        </span>

        <!-- Column: Button -->
        <span
          v-if="props.column.field === 'cellRendererApplyButton'"
          class="position-relative"
        >
          <b-button
            variant="outline-primary"
            :to="{ name: 'human-resources-prospects-admin-jobs-existing-candidates', params: { job_id: props.row.id }, query: { name: props.row.job_title, job_id: props.row.job_id } }"
            class="px-1 py-25"
          >
            Apply
          </b-button>
          <span class="badge badge-up badge-pill bg-danger">{{ props.row.applied_prospects }}</span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span
          v-else-if="props.column.field === 'action'"
          class="btn-action"
        >
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none pl-0"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                id="edit"
                :to="{ name: 'human-resources-prospects-admin-group-jobs-jobs-edit', params: { id: props.row.id } }"
              >
                <feather-icon icon="Edit2Icon" />
                <span class="ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item
                id="clone"
                :to="{ name: 'human-resources-prospects-admin-group-jobs-jobs-clone', params: { id: props.row.id } }"
              >
                <feather-icon icon="CopyIcon" />
                <span class="ml-50">Clone</span>
              </b-dropdown-item>
              <b-dropdown-item
                id="delete"
                @click="confirmDeleteRecord(props.row)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
            <b-link
              id="view"
              :to="{ name: 'human-resources-prospects-admin-group-jobs-jobs-view', params: { id: props.row.id } }"
            >
              <b-badge
                pill
                variant="light-primary"
              ><feather-icon icon="EyeIcon" />
              </b-badge>
            </b-link>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          <span v-if="props.column.fieldType !== 'cellRendererIndeedLink' && props.column.fieldType !== 'cellRendererJobId' && props.column.fieldType !== 'cellRendererCurrency' && props.column.fieldType !== 'cellRendererLink'">
            {{ props.formattedRow[props.column.field] }}
          </span>
        </span>
      </template>

    </vue-good-table>
    <b-modal
      ref="job_details"
      cancel-variant="outline-secondary"
      hide-footer
      scrollable
      size="lg"
      :title="jobDetails.job_title"
      @hide="closeJobPopup"
      :no-close-on-backdrop="true"
    >
      <b-media
        no-body
        class="align-items-center"
      >
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              icon="UserIcon"
              size="18"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
            {{ jobDetails.client }}
          </h6>
        </b-media-body>
      </b-media>
      <b-media
        no-body
        class="align-items-center mt-2"
      >
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              icon="DollarSignIcon"
              size="18"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
            ${{ jobDetails.bill_rate }}/HR
          </h6>
          <small>Offer Rate: ${{ jobDetails.offer_rate }}/HR (W2)</small>
        </b-media-body>
      </b-media>
      <b-row>
        <b-col cols="12"
          lg="6"
        >
          <b-media
            no-body
            class="align-items-center mt-2"
          >
            <b-media-aside class="mr-1">
              <b-avatar
                rounded
                variant="light-primary"
                size="34"
              >
                <feather-icon
                  icon="UserPlusIcon"
                  size="18"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="mb-0">
                {{ jobDetails.assigned_to }}
              </h6>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col cols="12"
          lg="6"
        >
          <b-media
            no-body
            class="align-items-center mt-2"
          >
            <b-media-aside class="mr-1">
              <b-avatar
                rounded
                variant="light-primary"
                size="34"
              >
                <feather-icon
                  icon="CalendarIcon"
                  size="18"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="mb-0">
                {{ jobDetails.open_date }}
              </h6>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
      <div class="mt-2">
        <h5>Requirements</h5>
      </div>
      <p
        v-html="jobDetails.requirements"
        class="job-details"
      ></p>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BBadge, BDropdown, BDropdownItem, BLink, BButton, BModal, VBModal, BMedia, BMediaBody, BMediaAside, BAvatar, VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import LIST from '@/js/lists'
import Jobs from '@/js/jobs'

export default {
  name: 'JobRequests',
  components: {
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    BBadge,
    BLink,
    BButton,
    BModal,
    BDropdown,
    BDropdownItem,
    BMedia,
    BMediaBody,
    BMediaAside,
    BAvatar,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      assignUsers: {
        job_id: null,
        assigned_user_ids: [],
      },
      status_id: null,
      facility_id: null,
      skill_id: null,
      state_id: null,
      city: null,
      bill_rate: null,
      assigned_to_id: null,
      working_status_id: null,
      role_users_id: null,
      status: [],
      facilities: [],
      technologies: [],
      locations: [],
      states: [],
      assignedTo: [],
      roleUsers: [],
      searchQuery: '',
      dir: false,
      employee: {},
      jobDetails: {},
      jobResponse: {
        jobs: [],
        count: 1,
      },
      columns: [
        {
          label: 'Job Id',
          field: 'job_id',
          fieldType: 'cellRendererJobId',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Job Id',
          },
          width: '130px',
        },
        {
          label: 'Vendor',
          field: 'vendor',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Vendor',
          },
        },
        {
          label: 'Title',
          field: 'job_title',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Title',
          },
        },
        {
          label: 'State',
          field: 'state',
          minWidth: '100px',
          sortable: false,
        },
        {
          label: 'City',
          field: 'city',
          sortable: false,
        },
        {
          label: 'Rate ($)',
          field: 'rate',
          fieldType: 'cellRendererCurrency',
          width: '110px',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Rate',
          },
        },
        {
          label: 'Exp Years',
          field: 'experience',
          width: '100px',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Exp Years',
          },
        },
        {
          label: 'Entry Date',
          field: 'entry_date',
          width: '120px',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Entry Date',
          },
        },
        {
          label: 'Duration',
          field: 'duration',
          type: 'number',
          width: '100px',
          sortable: false,
        },
        // {
        //   label: 'Assigned To',
        //   field: 'assigned_to',
        //   width: '120px',
        //   sortable: false,
        // },
        {
          label: 'Indeed Matched Prospects City / State',
          field: 'indeed_matched_prospects',
          fieldType: 'cellRendererIndeedLink',
          width: '160px',
          type: 'number',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Indeed Matched Prospects',
          },
        },
        {
          label: 'Matched Prospects City / State',
          field: 'matched_prospects',
          fieldType: 'cellRendererLink',
          width: '130px',
          type: 'number',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Prospects Count',
          },
        },
        {
          label: 'Apply',
          field: 'cellRendererApplyButton',
          width: '100px',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          width: '100px',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    pageLength: {
      get() {
        return this.$store.state.query.jobsPerpage
      },
      set(val) {
        this.$store.state.query.jobsPerpage = val
      },
    },
    currentPage: {
      get() {
        return this.$store.state.query.innerpage
      },
      set(val) {
        this.$store.state.query.innerpage = val
      },
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Active      : 'light-success',
        Inactive    : 'light-danger',
        Terminated  : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getJobMainSkills(this)
        LIST.getJobCities(this)
      },
    },
  },
  created() {
    this.searchQuery = this.$store.state.query.innerquery
    if (this.$route.query.skill_id !== null && this.$route.query.skill_id !== undefined) {
      this.$store.state.query.skill_id = this.$route.query.skill_id
    }
    if (this.$route.query.state_id !== null && this.$route.query.state_id !== undefined) {
      this.$store.state.query.state_id = this.$route.query.state_id
    }
    if (this.$route.query.city !== null && this.$route.query.city !== undefined) {
      this.$store.state.query.city = this.$route.query.city
    }
    if (this.$route.query.facility_id !== null && this.$route.query.facility_id !== undefined) {
      this.$store.state.query.facility_id = this.$route.query.facility_id
    }
    if (this.$route.query.bill_rate !== null && this.$route.query.bill_rate !== undefined) {
      this.$store.state.query.bill_rate = this.$route.query.bill_rate
    }
    if (this.$route.query.type !== null && this.$route.query.type !== undefined) {
      this.$store.state.query.type = this.$route.query.type
    }
    this.type = this.$store.state.query.type
    this.bill_rate = this.$route.query.bill_rate
    this.facility_id = Number(this.$store.state.query.facility_id) || null
    this.city = this.$store.state.query.city
    this.state_id = Number(this.$store.state.query.state_id) || null
    this.skill_id = Number(this.$store.state.query.skill_id) || null
    this.jobResponse.count = this.$store.state.query.innerpage
    LIST.getJobMainSkills(this)
    LIST.getJobCities(this)
    Jobs.getJobsByGroupByCity(this, this.type, this.skill_id, this.city, this.state_id, this.facility_id, this.bill_rate)
  },
  methods: {
    addAssignUsers() {
      if (this.assignUsers.assigned_user_ids.length > 0 && this.assignUsers.job_id !== null) {
        Jobs.assignUsersToJob(this, this.assignUsers.job_id, this.assignUsers)
      }
    },
    onPageChange(page) {
      this.currentPage = page.currentPage
      this.pageLength = page.currentPerPage
    },
    onPerPageChange(perPage) {
      this.currentPage = perPage.currentPage
      this.pageLength = perPage.currentPerPage
    },
    showJobDetails(jobDetails) {
      this.jobDetails = jobDetails
      this.$refs.job_details.show()
    },
    closeJobPopup() {
      this.jobDetails = {}
    },
    confirmDeleteRecord(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${obj.job_id}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          Jobs.delete(this, obj.id)
        }
      })
    },
    jobDeleted() {
      Jobs.getJobs(this)
      this.$swal({
        icon: 'success',
        title: 'Job Deleted!',
        text: 'Your Job has been deleted',
      })
    },
    jobNotDeleted() {
      this.$swal({
        icon: 'warning',
        title: 'Job Not Deleted!',
        text: 'Your Job has been not deleted',
      })
    },
    usersAssigned(data) {
      this.$refs.job_posted.hide()
      Jobs.getJobs(this)
      this.$swal({
        icon: 'success',
        title: 'Users Assigned!',
        text: data.return_message,
      })
    },
    usersNotAssigned(message) {
      this.$swal({
        icon: 'warning',
        title: 'Job Not Assigned!',
        text: message,
      })
    },
    setRollUsers(rollUsers) {
      this.roleUsers = rollUsers
    },
    filter() {
      if (this.$route.query.skill_id !== null && this.$route.query.skill_id !== undefined && (this.$store.state.query.skill_id === null || this.$store.state.query.skill_id === undefined)) {
        this.$router.replace({ query: { skill_id: this.skill_id } })
      }
      if (this.$route.query.city_id !== null && this.$route.query.city_id !== undefined && (this.$store.state.query.city_id === null || this.$store.state.query.city_id === undefined)) {
        this.$router.replace({ query: { city_id: this.city_id } })
      }
      this.$store.state.query.innerquery = this.searchQuery
      this.currentPage = 1
    },
    technologyChanged() {
      this.$store.state.query.skill_id = this.skill_id
    },
    locationChanged() {
      this.$store.state.query.city_id = this.city_id
    },
    updateSearchQuery() {
      this.$store.state.query.innerquery = this.searchQuery
      this.currentPage = 1
    },
    setJobs(jobResponse) {
      this.jobResponse = jobResponse
    },
    setMainSkills(technologies) {
      this.technologies = technologies
    },
    setCities(locations) {
      this.locations = locations
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
