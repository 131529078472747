import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.url
const offerLetter = `${HOST}offer-letter`

export default {
  getEmployeeOfferLetterById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${offerLetter}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setEmployeeDetails(response.data)
    }, error => {
      context.$vs.loading.close()
      context.employeeNotFound(error.response.data.return_message)
    })
  },
  sendEmployeeOfferLetterForSign(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${offerLetter}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.sentSuccess(response.data)
    }, error => {
      context.$vs.loading.close()
      context.sentFailure(error.response.data.return_message)
    })
  },
  getEmployeeOfferLetterPdfById(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${offerLetter}/${id}/pdf`, body).then(response => {
      context.$vs.loading.close()
      context.pdfPath(response.data)
    }, error => {
      context.$vs.loading.close()
      context.pdfNotFound(error.response.data.return_message)
    })
  },
  getEmployeeOfferLetterTemplateById(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${offerLetter}/${id}/update-content`, body).then(response => {
      context.$vs.loading.close()
      context.employeeLetterTemplate(response.data)
    }, error => {
      context.$vs.loading.close()
      context.pdfNotFound(error.response.data.return_message)
    })
  },
  sendEmployeeOfferLetterUpdatedContentForSign(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${offerLetter}/${id}/sign`, body).then(response => {
      context.$vs.loading.close()
      context.sentSuccess(response.data)
    }, error => {
      context.$vs.loading.close()
      context.sentFailure(error.response.data.return_message)
    })
  },
  getEmployeeOfferLetterPdfByIdAndUpdatedContent(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${offerLetter}/${id}/update-content/pdf`, body).then(response => {
      context.$vs.loading.close()
      context.pdfPath(response.data)
    }, error => {
      context.$vs.loading.close()
      context.pdfNotFound(error.response.data.return_message)
    })
  },
}
