<template>
  <div class="sidebar-left sidebar-left-custom">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div class="email-app-menu">
          <div class="form-group-compose text-center border-bottom-1 py-1">
            <h5 class="mb-0 py-25">Prospects by {{ type }}</h5>
          </div>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list sidebar-menu-list-custom scroll-area"
          >
            <div class="matcher-tree pl-2 pt-50">
              <v-tree
                ref="tree"
                :data="treeData"
                :multiple="false"
                @node-click="nodeclicked"
                @node-expand="nodeExpanded"
                @async-load-nodes="asyncLoad"
              ></v-tree>
            </div>
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref,
} from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import { VTree } from 'vue-tree-halower'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ProspectMatcher from '@/js/prospectmatcher'

export default {
  components: {
    VTree,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      skillId: null,
      type: '',
      stateId: null,
      sourceType: null,
      treeData: [],
      city: '',
      selectedNode: null,
    }
  },
  props: {
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const isTaskHandlerSidebarActive = ref(false)

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    return {
      perfectScrollbarSettings,
      isTaskHandlerSidebarActive,
      mqShallShowLeftSidebar,
    }
  },
  created() {
    this.skillId = this.$route.query.skill_id
    this.stateId = this.$route.query.state_id
    this.city = this.$route.query.city
    this.sourceType = this.$route.query.source_type
    if ((this.skillId === null || this.skillId === undefined) && (this.stateId === null || this.stateId === undefined)) {
      return
    }
    if (this.skillId !== null && this.skillId !== undefined && this.stateId !== null && this.stateId !== undefined) {
      return
    }
    if (this.skillId !== null && this.skillId !== undefined) {
      ProspectMatcher.getStatesBySkill(this, this.skillId)
      this.$emit('loadProspects', this.sourceType !== null ? `?skill_id=${this.skillId}&city=${this.city}&source_type=${this.sourceType}` : `?skill_id=${this.skillId}&city=${this.city}`)
    }
    if (this.stateId !== null && this.stateId !== undefined) {
      ProspectMatcher.getSkillsBystateId(this, this.stateId)
      this.$emit('loadProspects', this.sourceType !== null ? `?state_id=${this.stateId}&city=${this.city}&source_type=${this.sourceType}` : `?state_id=${this.stateId}&city=${this.city}`)
    }
  },
  methods: {
    setSkillsTree(data) {
      this.type = data.type
      this.treeData = data.lists
    },
    reloadData() {
      if (this.skillId !== null && this.skillId !== undefined) {
        ProspectMatcher.getStatesBySkill(this, this.skillId)
      }
      if (this.stateId !== null && this.stateId !== undefined) {
        ProspectMatcher.getSkillsBystateId(this, this.stateId)
      }
    },
    setChildTree(data, node) {
      if (node === null || node === undefined) {
        this.treeData = data.lists
        return
      }
      if (!Object.prototype.hasOwnProperty.call(node, 'children')) {
        this.$set(node, 'children', [])
      }
      this.$set(node, 'children', [])
      node.children.push(...data.lists)
    },
    nodeclicked(nodes) {
      const node = nodes
      if (this.selectedNode) {
        this.selectedNode.selected = false
      }
      this.selectedNode = node
      if (node.selected && !node.expanded) node.expanded = true
      if (!node.expanded) return
      if (node.query !== undefined && node.query !== null) {
        this.$emit('loadProspects', node.query)
      }
      if (node.next_load === 'jobs') {
        ProspectMatcher.getJobsBySkill(this, node.query, node)
      }
      if (node.next_load === 'state') {
        ProspectMatcher.getStatesByJobId(this, node.query, node)
      }
      if (node.next_load === 'cities') {
        ProspectMatcher.getProspectsCities(this, node.query, node)
      }
    },
    nodeExpanded(nodes) {
      const node = nodes
      if (this.selectedNode) {
        this.selectedNode.selected = false
      }
      this.selectedNode = node
      if (!node.expanded) return
      if (node.query !== undefined && node.query !== null) {
        this.$emit('loadProspects', node.query)
      }
      if (node.next_load === 'jobs') {
        ProspectMatcher.getJobsBySkill(this, node.query, node)
      }
      if (node.next_load === 'state') {
        ProspectMatcher.getStatesByJobId(this, node.query, node)
      }
      if (node.next_load === 'cities') {
        ProspectMatcher.getProspectsCities(this, node.query, node)
      }
    },
    async asyncLoad(node) {
      if (node.next_load === 'jobs') {
        ProspectMatcher.getJobsBySkill(this, node.query, node)
      }
      if (node.next_load === 'state') {
        ProspectMatcher.getStatesByJobId(this, node.query, node)
      }
      if (node.next_load === 'cities') {
        ProspectMatcher.getProspectsCities(this, node.query, node)
      }
    },
  },
}
</script>

<style>
</style>
