import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.url
const esignatures = `${HOST}esignature`

export default {
  getESignatures(context1, parentTypeId, parentId, statusId) {
    const context = context1
    const params = {
      params: {
        query: context.searchQuery, page: context.currentPage - 1, parent_type_id: parentTypeId, parent_id: parentId, status_id: statusId,
      },
    }
    axios.get(esignatures, params).then(response => {
      context.setESignatures(response.data)
    }, () => {
    })
  },
  getESignatureById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${esignatures}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setESignature(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getESignatureViewById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${esignatures}/${id}/view`).then(response => {
      context.$vs.loading.close()
      context.setESignature(response.data)
    }, () => {
      context.$vs.loading.close()
      context.$router.go(-1)
    })
  },
  getESignatureResendById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${esignatures}/${id}/resend`).then(response => {
      context.$vs.loading.close()
      context.setESignatureResentSuccess(response.data)
    }, error => {
      context.$vs.loading.close()
      context.setESignatureResentFailure(error.response.data.return_message)
    })
  },
  submitEsignConsignment(context1, id, data) {
    const context = context1
    context.$vs.loading()
    axios.post(`${esignatures}/${id}/consign`, data).then(response => {
      context.$vs.loading.close()
      context.setConsignSuccess(response.data)
    }, error => {
      context.$vs.loading.close()
      context.setConsignFailure(error.response.data.return_message)
    })
  },
  submitEsign(context1, id, data) {
    const context = context1
    context.$vs.loading()
    axios.post(`${esignatures}/${id}/sign`, data).then(response => {
      context.$vs.loading.close()
      context.setEsignSuccess(response.data)
    }, error => {
      context.$vs.loading.close()
      context.setEsignFailure(error.response.data.return_message)
    })
  },
  sendDocumentToReceiver(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.post(`${esignatures}/${id}/send-receiver`).then(response => {
      context.$vs.loading.close()
      context.setDcumentSentSuccess(response.data)
    }, error => {
      context.$vs.loading.close()
      context.setDcumentSentFailure(error.response.data.return_message)
    })
  },
  downloadEsignDoc(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${esignatures}/${id}/download`).then(response => {
      context.$vs.loading.close()
      context.setDocumentDownloaded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.setDocumentNotDownloaded(error.response.data.return_message)
    })
  },
  toggleStatus(context1, id, statusId) {
    const context = context1
    context.$vs.loading()
    axios.put(`${esignatures}/update-status/${id}/${statusId}`, {}).then(response => {
      context.$vs.loading.close()
      context.updated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.updateError(error.response.data.return_message)
    })
  },
  getDocumentSentById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${esignatures}/${id}/document-sent`).then(response => {
      context.$vs.loading.close()
      context.setDocumentDetails(response.data)
    }, () => {
      context.$vs.loading.close()
      context.$router.go(-1)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${esignatures}/${id}`).then(response => {
      context.$vs.loading.close()
      context.signatureDeleted(response.data)
    }, error => {
      context.$vs.loading.close()
      context.signatureNotDeleted(error.response.data.return_message)
    })
  },
}
