import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.adurl
const communication = `${HOST}communication`

export default {
  sendMails(context1, data) {
    const context = context1
    context.$vs.loading()
    axios.post(communication, data).then(response => {
      context.$vs.loading.close()
      context.communicationAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.communicationNotAdded(error.response.data.return_message)
    })
  },

}
