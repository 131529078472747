<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          View Company</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>

    <app-timeline>
      <app-timeline-item class="pb-1">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Company Information</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Company Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="company.company_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Company Url id
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="company.company_url_id"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Status
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="company.status"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="info"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Contact Information</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Contact Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="company.contact_info.contact_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Email
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="company.contact_info.email_address"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Phone
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="company.contact_info.phone_number"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Address 1
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="company.contact_info.address.address1"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Address 2
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="company.contact_info.address.address2"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                City
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="company.contact_info.address.city"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                State
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="company.contact_info.address.state"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Zip Code
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="company.contact_info.address.zip_id"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="info"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Select Roles</h6>
        </div>
        <b-row
          v-for="(departmentDevision, index) in Object.keys(company.devisions)"
          :key="index"
          :value="index"
          class="mt-1"
        >
          <b-col cols="12">
            <b-form-checkbox
              v-model="company.devisions[departmentDevision].selected"
              class="custom-control-primary mb-1"
              disabled
              @input="departmentSelected(departmentDevision)"
            >
              {{ departmentDevision }}
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
            class="pl-5"
          >
            <b-row v-show="company.devisions[departmentDevision].selected">
              <b-col
                v-for="(devision, newindex) in company.devisions[departmentDevision]"
                :key="newindex"
                :value="newindex"
                cols="3"
              >
                <b-form-checkbox
                  v-model="devision.selected"
                  class="custom-control-primary mb-1"
                  disabled
                  @input="devisionSelected(departmentDevision, devision.selected)"
                >
                  {{ devision.name }}
                </b-form-checkbox>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </app-timeline-item>

    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Company from '@/js/companies'

export default {
  name: 'CompanyVeiw',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
  },
  directives: {
  },
  setup() {
  },
  data() {
    return {
      company: {},
    }
  },
  created() {
    Company.getCompanyById(this, this.$route.params.id)
  },
  methods: {
    setCompany(company) {
      this.company = company
      Object.keys(this.company.devisions).forEach(key => {
        this.company.devisions[key].selected = false
        Object.keys(this.company.devisions[key]).forEach(devision => {
          if (this.company.devisions[key][devision].selected === true) {
            this.company.devisions[key].selected = true
          }
        })
      })
    },
    back() {
      this.$router.push('/companies').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
