<template>
  <div class="all-prospects-application">
    <b-card
      no-body
      class="mb-1"
    >
    <b-card-body class="py-1">
      <b-row>
        <b-col
          cols="12"
          md="3"
        >
          <h4 class="card-title mb-0 pt-50">
            Applied Registrants</h4>
        </b-col>
        <b-col
          cols="12"
          md="3 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end mt-50">
            <strong>Total Emails: {{ selectedProspectsCount() }} / {{ totalProspects }}</strong>
            <b-button
              v-show="selectedProspectsCount() > 0"
              variant="primary"
              size="sm"
              class="ml-2"
              @click="copyEmails"
            >
              Copy Emails
            </b-button>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="6 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                @keyup.enter="updateSearchQuery"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="updateSearchQuery"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="14"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button
              variant="primary"
              class="ml-md-2 px-1 min-w-130"
              :to="{ name: 'human-resources-prospects-admin-prospects-new'}"
            >
              Add Prospect
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="3">
          <b-form-group>
            <v-select
              id="facility_id"
              v-model="facility_id"
              :options="facilities"
              :reduce="item => item.id"
              :clearable="true"
              placeholder="By Facility / Client"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <v-select
              id="skill_id"
              v-model="skill_id"
              :options="technologies"
              :reduce="item => item.id"
              :clearable="true"
              placeholder="By Skill"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <v-select
              id="state_id"
              v-model="state_id"
              :options="states"
              :reduce="item => item.id"
              :clearable="true"
              placeholder="By State"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <v-select
              id="city_id"
              v-model="city_id"
              :options="locations"
              :reduce="item => item.id"
              :clearable="true"
              placeholder="By City"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <v-select
              id="status_id"
              v-model="status_id"
              :options="status"
              :reduce="item => item.id"
              :clearable="true"
              placeholder="Status"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-button
            variant="primary"
            @click="filter"
          >
            Filter
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
      <div
        class="position-relative"
        style="height: inherit;"
      >
        <div class="email-app-list email-app-list-custom">

          <b-table
            bordered
            hover
            responsive
            class="custom-table-details position-relative"
            :current-page="currentPage"
            :items="prospectsResponse.prospects"
            :fields="fields"
            select-mode="multi"
          >
            <template #head(checkbox)="">
              <b-form-checkbox v-model="selectAll" @change="selectAllRows">
              </b-form-checkbox>
            </template>
            <template #cell(checkbox)="row">
              <b-form-checkbox v-model="row.item.selected" @change="onRowSelected(row)">
              </b-form-checkbox>
            </template>
            <template #cell(show_details)="row">

              <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
              <div>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="btn-icon px-25 py-25"
                  size="sm"
                  @click="() => {row.toggleDetails(); row.detailsShowing = !row.detailsShowing}"
                >
                  <feather-icon :icon="row.detailsShowing ? 'MinusIcon' : 'PlusIcon'" />
                </b-button>
                <span class="ml-1">{{row.item.all_jobs}}</span>
              </div>
            </template>

            <!-- full detail on click -->
            <template #row-details="row">
              <b-table
                :items="row.item.jobs_applied"
                :fields="fields2"
                striped
                responsive
                class="mb-0"
              >
                <template #cell(job_id)="data">
                    {{ data.value }}
                    <img
                      v-show="data.item.is_urgent"
                      class="ml-50 img-fluid"
                      width="24"
                      height="24"
                      :src="require('@/assets/images/Megaphone.gif')"
                      alt="image slot"
                    >
                </template>
                <template #cell(rate)="data">
                  <span>{{ data.value| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
                </template>
              </b-table>

              <!-- <b-button
                size="sm"
                variant="outline-secondary"
                @click="row.toggleDetails"
              >
                Hide Details
              </b-button> -->
            </template>

            <template #cell(name)="data">
              <b-link @click="updateProspectViewData(data.item)">
                {{ data.value }}
              </b-link>
            </template>

            <template #cell(communication)="row">
              <b-link
                v-b-tooltip.hover
                title="mail"
                :class="row.item.mails.length === 0 ? 'notes-icon mr-1' : 'mr-1 notes-icon active'"
                @click="showMails(row.item)"
              >
                <feather-icon
                  icon="MailIcon"
                  size="16"
                />
              </b-link>
              <b-link
                v-b-tooltip.hover
                title="message"
                class="mr-1"
              >
                <feather-icon
                  icon="MessageCircleIcon"
                  size="16"
                />
              </b-link>
              <b-link
                v-b-tooltip.hover
                title="call"
                class="mr-1"
              >
                <feather-icon
                  icon="PhoneIcon"
                  size="16"
                />
              </b-link>
              <b-link
                v-b-tooltip.hover
                title="reminder"
                class="mr-1"
              >
                <feather-icon
                  icon="MessageSquareIcon"
                  size="16"
                />
              </b-link>
              <b-link
                v-b-tooltip.hover
                title="notes"
                id="notes"
                :class="row.item.comments.length === 0 ? 'notes-icon mr-1' : 'notes-icon active mr-1'"
                @click="showNotes(row.item.id)"
              >
                <feather-icon
                  icon="FileTextIcon"
                  size="16"
                />
              </b-link>
              <b-link
                v-b-tooltip.hover
                :title="row.item.is_stopped ? `Click to contact activate` : `Click to don't contact`"
                :class="row.item.is_stopped ? 'text-danger' : 'text-primary'"
                @click="stopProspect(row.item.id, row.item.is_stopped, row.item.name)"
              >
                <feather-icon
                  icon="ThumbsDownIcon"
                  size="16"
                />
              </b-link>
            </template>

            <template #cell(actions)="row">
              <span class="btn-action">
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    id="edit"
                    :to="{ name: 'human-resources-prospects-admin-prospects-edit', params: { id: row.item.id } }"
                  >
                    <feather-icon icon="Edit2Icon" />
                    <span class="ml-50">Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    id="delete"
                    @click="confirmDeleteRecord(row.item)"
                  >
                    <feather-icon icon="TrashIcon" />
                    <span class="ml-50">Delete</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    id="add_invitation"
                    v-show="row.item.invitation_id === null"
                    @click="sendProfileInvitation(row.item)"
                  >
                    <feather-icon icon="SendIcon" />
                    <span class="ml-50">Send Profile Invitation</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    id="add_invitation"
                    v-show="row.item.invitation_id !== null"
                    @click="resendProfileInvitation(row.item)"
                  >
                    <feather-icon icon="SendIcon" />
                    <span class="ml-50">Re-send Profile Invitation</span>
                  </b-dropdown-item>

                </b-dropdown>
                <b-link
                  :to="`/documents/document-tracker/new?parent_id=${row.item.id}&parent_type_id=${row.item.parent_type_id}`"
                  v-b-tooltip.hover
                  title="Documents"
                  class="mr-1"
                >
                  <feather-icon
                    icon="FileIcon"
                    size="16"
                  />
                </b-link>
              </span>
            </template>
          </b-table>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <div class="d-flex align-items-center justify-content-end mr-4">
              <strong>Total Emails: {{ selectedProspectsCount() }} / {{ totalProspects }}</strong>
              <b-button
                v-show="selectedProspectsCount() > 0"
                variant="primary"
                size="sm"
                class="ml-2"
                @click="copyEmails"
              >
                Copy Emails
              </b-button>
            </div>
            <!-- pagination -->
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="prospectsResponse.count"
                prev-class="prev-item"
                next-class="next-item"
                first-number
                last-number
                align="right"
                :per-page="1"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </div>
        <b-modal
          ref="add_notes"
          cancel-variant="outline-secondary"
          ok-title="Add"
          cancel-title="Close"
          centered
          title="Add Notes"
          size="lg"
          :no-close-on-backdrop="true"
          @hide="closingNotesPopup"
        >
          <b-form>
            <div class="previous-notes">
              <div class="single-note" v-for="(commentItem, index) in userComments" :id="index" :key="index" >
                <div class="note-author">
                  <p v-html="commentItem.commentUserText"></p>
                </div>
              </div>
            </div>
          </b-form>
          <template #modal-footer>
            <div class="chat-app-window chat-app-window-custom w-100">
              <div class="chat-app-form">
                <b-input-group class="input-group-merge form-send-message mr-1">
                  <b-form-textarea
                    v-model="addNotesRequest.comment"
                    placeholder="Enter your notes"
                  />
                </b-input-group>
                <b-button
                  variant="primary"
                  type="submit"
                  @click="saveNote()"
                >
                  Send
                </b-button>
              </div>
            </div>
          </template>
        </b-modal>

        <b-modal
          ref="sent_mails"
          cancel-variant="outline-secondary"
          ok-title="Ok"
          cancel-title="Close"
          centered
          title="Recent Mails Sent On"
          size="lg"
          :no-close-on-backdrop="true"
          @hide="closeSentMailsPopup"
        >
          <template #modal-header>
            <div class="d-flex justify-content-between align-items-center w-100">
              <h5 class="modal-title">Recent Mails Sent On</h5>
              <b-button
                variant="primary"
                type="submit"
                class="mr-1"
                v-show="!addNewMailSent"
                @click="showNewMailSent"
              >
                Add
              </b-button>
            </div>
            <button type="button" @click="closeSentMailsPopup" aria-label="Close" class="close">×</button>
          </template>
          <validation-observer v-if="addNewMailSent" ref="mailSimpleRules">
            <b-form>
              <b-row>
                <b-col cols="4">
                  <b-form-group>
                    <label>Job Id</label>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Job Id"
                      rules="integer"
                    >
                      <b-form-input
                        id="job_id"
                        v-model="addSentMail.job_id"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group>
                    <label>Sent Date</label>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Sent Date"
                      rules="required"
                    >
                      <flat-pickr
                        v-model="sent_date"
                        class="form-control"
                        :config="startDateConfig"
                        placeholder="MM-DD-YYYY"
                        :clear-button="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group>
                    <label>Sent Time</label>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Sent Time"
                      rules="required"
                    >
                      <flat-pickr
                        v-model="addSentMail.time"
                        class="form-control"
                        :config="startTimeConfig"
                        :placeholder="'00:00'"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group>
                    <label>Decription</label>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Decription"
                      rules="required"
                    >
                      <b-form-textarea
                        v-model="addSentMail.description"
                        rows="2"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>

              <b-row class="mb-1">
                <b-col>
                </b-col>
                <b-col class="text-right">
                  <b-button
                    variant="outline-secondary"
                    type="button"
                    class="mr-1"
                    @click="closeSentMail"
                  >
                    Cancel
                  </b-button>
                  <b-button
                    variant="primary"
                    type="button"
                    @click="mailSentSubmit"
                  >
                    Save
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
          <b-table
            bordered
            hover
            responsive
            class="custom-table-details"
            :items="sentMails"
            :fields="fields3"
          />
          <template #modal-footer>
            <b-button
              variant="outline-secondary"
              type="button"
              @click="closeSentMailsPopup"
            >
              Close
            </b-button>
          </template>
        </b-modal>

        <job-prospect-matcher-right-sidebar
          ref="loadRight"
          @prospectDeleted="prospectDeleted"
          :class="{'show': showProspectDetails}"
          @close-prospect-view="showProspectDetails = false"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  ref,
} from '@vue/composition-api'
import {
  BCard, BCardBody, BForm, BTable, BPagination, BLink, VBTooltip, BModal, VBModal, BFormGroup, BInputGroup, BFormTextarea, BFormCheckbox, BDropdown, BDropdownItem, BButton, BRow, BCol, BFormInput, BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Prospects from '@/js/prospect'
import ParentComments from '@/js/parentcomments'
import Parentmailsent from '@/js/parentmailsent'
import ProfileInvitations from '@/js/profileinvitations'
import store from '@/store/index'
import Constants from '@/js/constant'
import LIST from '@/js/lists'
import moment from 'moment'
import JobProspectMatcherRightSidebar from './JobProspectMatcherRightSidebar.vue'

export default {
  name: 'AppProspectMatcher',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardBody,
    BForm,
    BTable,
    BPagination,
    BFormGroup,
    BInputGroup,
    BFormTextarea,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BLink,
    BModal,
    BButton,
    BRow,
    BCol,
    BFormInput,
    BInputGroupAppend,
    flatPickr,
    vSelect,
    JobProspectMatcherRightSidebar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  setup() {
    const isTaskHandlerSidebarActive = ref(false)
    return {
      isTaskHandlerSidebarActive,
    }
  },
  data() {
    return {
      showProspectDetails: false,
      skills: [],
      selectAll: false,
      userComments: [],
      perPage: 10,
      totalProspects: 0,
      emails_count: 0,
      dir: false,
      showLeft: true,
      prospectId: null,
      selected: [],
      prospectsResponse: {
        prospects: [],
        count: 1,
      },
      addSentMail: {
        job_id: '',
        user_id: null,
        description: '',
        date: null,
        time: null,
      },
      searchQuery: '',
      selectedRows: [],
      query: {},
      fields: [
        { key: 'checkbox', label: 'checkbox', class: 'wid52' },
        {
          key: 'show_details',
          label: 'Applied',
          class: 'wid132',
          variant: 'primary',
        },
        'name', 'email', 'phone',
        { key: 'communication', label: 'Communication', class: 'thMwid160' },
        { key: 'actions', class: 'thMwid110' },
      ],
      fields2: [
        { key: 'job_id', label: 'Job Id' },
        { key: 'job_type', label: 'Job Type' },
        { key: 'title', label: 'Title' },
        { key: 'client', label: 'Client' },
        { key: 'status', label: 'Status' },
        { key: 'rate', label: 'Bill Rate' },
        { key: 'date', label: 'Date' },
        { key: 'note', label: 'Reason' },
      ],
      fields3: [
        { key: 'job_id', label: 'Job Id' },
        { key: 'skill', label: 'Skill' },
        { key: 'location', label: 'Location' },
        { key: 'description', label: 'Description' },
        { key: 'type', label: 'Type' },
        { key: 'date', label: 'Sent Date', class: 'thMwid110' },
        { key: 'time', label: 'Sent Time', class: 'thMwid110' },
      ],
      /* eslint-disable global-require */
      items: [],
      sentMails: [],
      addNotesRequest: {
        comment: '',
        user_id: null,
        date: null,
        time: null,
      },
      startDateConfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'm-d-Y',
        altInput: true,
        dateFormat: 'm-d-Y',
        minDate: null,
        maxDate: null,
      },
      startTimeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'h:i K',
      },
      addNewMailSent: false,
      rows: [],
      facility_id: null,
      skill_id: null,
      state_id: null,
      city_id: null,
      status_id: null,
      status: [],
      facilities: [],
      technologies: [],
      locations: [],
      states: [],
      searchTerm: '',
      start_date_id: null,
      start_time_id: null,
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
        Prospects.getJobAppliedProspects(this)
      },
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getJobFacilities(this)
        LIST.getJobMainSkills(this)
        LIST.getJobCities(this)
        LIST.getJobStates(this)
        LIST.getSubmittedProspectStatus(this)
      },
    },
  },
  created() {
    this.currentPage = 1
    LIST.getJobFacilities(this)
    LIST.getJobMainSkills(this)
    LIST.getJobCities(this)
    LIST.getJobStates(this)
    LIST.getSubmittedProspectStatus(this)
  },
  destroyed() {
  },
  methods: {
    sendProfileInvitation(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to send profile invitation to this ${obj.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, send it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          ProfileInvitations.sendInvitationToProspect(this, obj.id)
        }
      })
    },
    resendProfileInvitation(obj) {
      this.$swal({
        title: 'Invitation already exist',
        text: `do you wan't to re-send it to ${obj.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, re-send it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          ProfileInvitations.resendEmail(this, obj.invitation_id)
        }
      })
    },
    confirmDeleteRecord(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${obj.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          Prospects.delete(this, obj.id, obj.index)
        }
      })
    },
    copyEmails() {
      this.selectedprospects = []
      for (let i = 0; i < this.prospectsResponse.prospects.length; i += 1) {
        if (this.prospectsResponse.prospects[i].selected) {
          if (this.prospectsResponse.prospects[i].email !== 'NA') {
            if (!this.selectedprospects.includes(this.prospectsResponse.prospects[i].email)) {
              this.selectedprospects.push(this.prospectsResponse.prospects[i].email)
            }
          }
        }
      }
      this.$bvToast.toast(`${this.selectedprospects.length} emails copied`, {
        title: 'Copied!',
        variant: 'primary',
        solid: true,
        toaster: 'b-toaster-bottom-right',
      })
      navigator.clipboard.writeText(this.selectedprospects.toString().replaceAll(',', '; '))
    },
    invitationAdded(data) {
      this.$refs.showLoading = false
      Prospects.getJobAppliedProspects(this)
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    invitationNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    resendEmailResponse(msg) {
      this.$swal({
        icon: 'success',
        title: 'Resend',
        text: msg,
      })
    },
    resendEmailResponseFail(msg) {
      this.$swal({
        icon: 'warning',
        title: 'Resend',
        text: msg,
      })
    },
    prospectStopped(body) {
      Prospects.getJobAppliedProspects(this)
      this.$swal({
        icon: 'success',
        title: 'Success',
        text: body.return_message,
      })
    },
    stopProspect(id, stopped, name) {
      const action = stopped ? 'activate' : 'stop'
      const message = `Are you sure you want to ${action} contact with this prospect, ${name}?`
      const swalConfig = {
        title: 'Are you sure?',
        text: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Yes, ${action}`,
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }
      this.$swal(swalConfig).then(result => {
        if (result.value) {
          Prospects.stopProspect(this, id, stopped)
        }
      })
    },
    filter() {
      this.currentPage = 1
    },
    setFacilities(facilities) {
      this.facilities = facilities
    },
    setMainSkills(technologies) {
      this.technologies = technologies
    },
    setCities(locations) {
      this.locations = locations
    },
    setStates(states) {
      this.states = states
    },
    setStatus(status) {
      this.status = status
    },
    selectAllRows() {
      this.prospectsResponse.prospects = this.prospectsResponse.prospects.map(prospect => ({ ...prospect, selected: this.selectAll }))
    },
    selectedProspectsCount() {
      return this.prospectsResponse.prospects.filter(prospect => prospect.selected).length
    },
    onRowSelected() {
      const selectedCount = this.prospectsResponse.prospects.filter(prospect => prospect.selected).length
      this.$refs.loadTop.emails_count = selectedCount
      if (selectedCount === this.prospectsResponse.prospects.length) {
        this.selectAll = true
      } else if (this.selectAll) {
        this.selectAll = false
      }
    },
    mailSentSubmit() {
      this.$refs.mailSimpleRules.validate().then(result => {
        if (result) {
          this.addSentMail.date = Constants.getEprmMonthDateFromString(this.sent_date)
          Parentmailsent.addMailSent(this, this.addSentMail)
        }
      })
    },
    sentMailNotAdded(message) {
      this.$swal({
        icon: 'warning',
        title: 'Mail Sent Not Added!',
        text: message,
      })
    },
    setMailSents(data, id) {
      this.prospectsResponse.prospects = this.prospectsResponse.prospects.map(prospect => {
        if (prospect.id === id) {
          return { ...prospect, mails: data.mails }
        }
        return prospect
      })
      this.sentMails = data.mails
      this.addSentMail = {
        job_id: '',
        user_id: null,
        description: '',
        date: null,
        time: null,
      }
      this.addNewMailSent = false
      this.addSentMail.user_id = id
      this.$swal({
        icon: 'success',
        title: 'Mail Sent Added!',
        text: data.return_message,
      })
    },
    showNewMailSent() {
      this.addNewMailSent = true
      this.sent_date = moment().tz('America/Chicago').format('MM-DD-YYYY')
      this.addSentMail.time = moment().tz('America/Chicago').format('hh:mm a')
    },
    copyText() {
      this.$bvToast.toast(`${this.emails_count} emails copied`, {
        title: 'Copied!',
        variant: 'primary',
        solid: true,
        toaster: 'b-toaster-bottom-right',
      })
      navigator.clipboard.writeText(this.selectedprospects.toString().replaceAll(',', '; '))
    },
    prospectDeleted(data) {
      this.$swal({
        icon: 'success',
        title: 'Prospect Deleted!',
        text: data.return_message,
      })
      Prospects.getNewJobProspects(this)
    },
    prospectNotDeleted(message) {
      this.$swal({
        icon: 'warning',
        title: 'Prospect Not Deleted!',
        text: message,
      })
    },
    updateProspectViewData(data) {
      this.showProspectDetails = true
      this.$refs.loadRight.setProspectDetails(data)
    },
    updateSearchQuery() {
      this.currentPage = 1
    },
    showNotes(id) {
      this.userComments = []
      this.$refs.add_notes.show()
      ParentComments.getComments(this, id)
      this.addNotesRequest.user_id = id
    },
    closingNotesPopup() {
      this.userComments = []
      this.addNotesRequest = {
        comment: '',
        user_id: null,
        date: null,
        time: null,
      }
    },
    showMails(row) {
      this.sentMails = row.mails
      this.addSentMail.user_id = row.id
      this.$refs.sent_mails.show()
    },
    closeSentMailsPopup() {
      this.sentMails = []
      this.$refs.sent_mails.hide()
      this.addSentMail = {
        job_id: '',
        user_id: null,
        description: '',
        date: null,
        time: null,
      }
      this.addNewMailSent = false
    },
    closeSentMail() {
      const id = this.addSentMail.user_id
      this.addSentMail = {
        job_id: '',
        user_id: null,
        description: '',
        date: null,
        time: null,
      }
      this.addNewMailSent = false
      this.addSentMail.user_id = id
    },
    saveNote() {
      this.addNotesRequest.comment = this.addNotesRequest.comment.trim()
      if (this.addNotesRequest.comment === '') {
        return
      }
      this.addNotesRequest.date = Constants.getEprmMonthDateFromString(moment())
      this.addNotesRequest.time = moment().format('hh:mm a')
      ParentComments.addComment(this, this.addNotesRequest.user_id, this.addNotesRequest)
    },
    setParentComments(userComments, userId) {
      this.userComments = userComments.comments
      this.addNotesRequest.comment = ''
      this.prospectsResponse.prospects = this.prospectsResponse.prospects.map(prospect => {
        if (prospect.id === userId) {
          return { ...prospect, comments: userComments.comments }
        }
        return prospect
      })
    },
    setProspects(prospectsResponse) {
      this.prospectsResponse = prospectsResponse
      this.totalProspects = this.prospectsResponse.prospects.length
      this.selectAll = false
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
@import "@core/scss/vue/libs/tree.scss";
</style>
