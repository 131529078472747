<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Edit Timesheet</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <b-row class="mt-1">
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Name of Employee Consultant</label>
                  <span
                    class="form-control text-left"
                    v-text="addTimesheet.name"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Timesheet Frequency</label>
                  <span
                    class="form-control text-left"
                    v-text="addTimesheet.timesheet_frequency"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Month</label>
                  <span
                    class="form-control text-left"
                    v-text="addTimesheet.month"
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="isShowWeeks"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Week</label>
                  <span
                    class="form-control text-left"
                    v-text="addTimesheet.week"
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="isShowDays"
                cols="12"
              >
                <b-form-group>
                  <label>Day's</label>
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered table-td-pd mb-0">
                      <thead>
                        <tr>
                          <th>Sun {{ sun_day }}</th>
                          <th>Mon {{ mon_day }}</th>
                          <th>Tue {{ tue_day }}</th>
                          <th>Wed {{ wed_day }}</th>
                          <th>Thu {{ thu_day }}</th>
                          <th>Fri {{ fri_day }}</th>
                          <th>Sat {{ sat_day }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div class="">
                              <validation-provider
                                v-slot="{ errors }"
                                name="Week"
                                :rules="isShowWeeks ? 'required' : ''"
                              >
                                <b-form-spinbutton
                                  v-model="addTimesheet.sunday.hours"
                                  :min="0"
                                  :max="24"
                                  :step="0.5"
                                  @input="hoursChanged()"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </div>
                          </td>
                          <td>
                            <div class="">
                              <validation-provider
                                v-slot="{ errors }"
                                name="Week"
                                :rules="isShowWeeks ? 'required' : ''"
                              >
                                <b-form-spinbutton
                                  v-model="addTimesheet.monday.hours"
                                  :min="0"
                                  :max="24"
                                  :step="0.5"
                                  @input="hoursChanged()"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </div>
                          </td>
                          <td>
                            <div class="">
                              <validation-provider
                                v-slot="{ errors }"
                                name="Week"
                                :rules="isShowWeeks ? 'required' : ''"
                              >
                                <b-form-spinbutton
                                  v-model="addTimesheet.tueday.hours"
                                  :min="0"
                                  :max="24"
                                  :step="0.5"
                                  @input="hoursChanged()"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </div>
                          </td>
                          <td>
                            <div class="">
                              <validation-provider
                                v-slot="{ errors }"
                                name="Week"
                                :rules="isShowWeeks ? 'required' : ''"
                              >
                                <b-form-spinbutton
                                  v-model="addTimesheet.wedday.hours"
                                  :min="0"
                                  :max="24"
                                  :step="0.5"
                                  @input="hoursChanged()"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </div>
                          </td>
                          <td>
                            <div class="">
                              <validation-provider
                                v-slot="{ errors }"
                                name="Week"
                                :rules="isShowWeeks ? 'required' : ''"
                              >
                                <b-form-spinbutton
                                  v-model="addTimesheet.thusday.hours"
                                  :min="0"
                                  :max="24"
                                  :step="0.5"
                                  @input="hoursChanged()"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </div>
                          </td>
                          <td>
                            <div class="">
                              <validation-provider
                                v-slot="{ errors }"
                                name="Week"
                                :rules="isShowWeeks ? 'required' : ''"
                              >
                                <b-form-spinbutton
                                  v-model="addTimesheet.friday.hours"
                                  :min="0"
                                  :max="24"
                                  :step="0.5"
                                  @input="hoursChanged()"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </div>
                          </td>
                          <td>
                            <div class="">
                              <validation-provider
                                v-slot="{ errors }"
                                name="Week"
                                :rules="isShowWeeks ? 'required' : ''"
                              >
                                <b-form-spinbutton
                                  v-model="addTimesheet.satday.hours"
                                  :min="0"
                                  :max="24"
                                  :step="0.5"
                                  @input="hoursChanged()"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-form-group>
              </b-col>
              <!-- <b-col
                v-if="isShowDays"
                cols="12"
              >
                <b-form-group
                  label="Day's"
                  label-cols-md="2"
                  class="text-right"
                >
                  <b-row class="week-days">
                    <b-col cols="1">
                      <div class="border text-center">
                        Sun {{ sun_day }}
                      </div>
                      <div class="">
                        <validation-provider
                          #default="{ errors }"
                          name="Week"
                          :rules="isShowWeeks ? 'required' : ''"
                        >
                          <b-form-spinbutton
                            v-model="addTimesheet.sunday.hours"
                            :min="0"
                            :max="24"
                            :step="0.5"
                            @input="hoursChanged()"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </b-col>
                    <b-col cols="1">
                      <div class="border text-center">
                        Mon {{ mon_day }}
                      </div>
                      <div class="">
                        <validation-provider
                          #default="{ errors }"
                          name="Week"
                          :rules="isShowWeeks ? 'required' : ''"
                        >
                          <b-form-spinbutton
                            v-model="addTimesheet.monday.hours"
                            :min="0"
                            :max="24"
                            :step="0.5"
                            @input="hoursChanged()"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </b-col>
                    <b-col cols="1">
                      <div class="border text-center">
                        Tue {{ tue_day }}
                      </div>
                      <div class="">
                        <validation-provider
                          #default="{ errors }"
                          name="Week"
                          :rules="isShowWeeks ? 'required' : ''"
                        >
                          <b-form-spinbutton
                            v-model="addTimesheet.tueday.hours"
                            :min="0"
                            :max="24"
                            :step="0.5"
                            @input="hoursChanged()"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </b-col>
                    <b-col cols="1">
                      <div class="border text-center">
                        Wed {{ wed_day }}
                      </div>
                      <div class="">
                        <validation-provider
                          #default="{ errors }"
                          name="Week"
                          :rules="isShowWeeks ? 'required' : ''"
                        >
                          <b-form-spinbutton
                            v-model="addTimesheet.wedday.hours"
                            :min="0"
                            :max="24"
                            :step="0.5"
                            @input="hoursChanged()"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </b-col>
                    <b-col cols="1">
                      <div class="border text-center">
                        Thu {{ thu_day }}
                      </div>
                      <div class="">
                        <validation-provider
                          #default="{ errors }"
                          name="Week"
                          :rules="isShowWeeks ? 'required' : ''"
                        >
                          <b-form-spinbutton
                            v-model="addTimesheet.thusday.hours"
                            :min="0"
                            :max="24"
                            :step="0.5"
                            @input="hoursChanged()"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </b-col>
                    <b-col cols="1">
                      <div class="border text-center">
                        Fri {{ fri_day }}
                      </div>
                      <div class="">
                        <validation-provider
                          #default="{ errors }"
                          name="Week"
                          :rules="isShowWeeks ? 'required' : ''"
                        >
                          <b-form-spinbutton
                            v-model="addTimesheet.friday.hours"
                            :min="0"
                            :max="24"
                            :step="0.5"
                            @input="hoursChanged()"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </b-col>
                    <b-col cols="1">
                      <div class="border text-center">
                        Sat {{ sat_day }}
                      </div>
                      <div class="">
                        <validation-provider
                          #default="{ errors }"
                          name="Week"
                          :rules="isShowWeeks ? 'required' : ''"
                        >
                          <b-form-spinbutton
                            v-model="addTimesheet.satday.hours"
                            :min="0"
                            :max="24"
                            :step="0.5"
                            @input="hoursChanged()"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col> -->
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Total Hours</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Total Hours"
                    rules="required"
                  >
                    <b-form-input
                      v-model="addTimesheet.total_hours"
                      :disabled="!isShowWeeks ? 'disabled' : ''"
                      type="number"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-show="addTimesheet.previous_hours !== null"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Previous Hours</label>
                  <span
                    class="form-control text-left"
                    v-text="addTimesheet.previous_hours"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Expenses</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Expenses"
                    rules="required"
                  >
                    <b-input-group
                      prepend="$"
                    >
                      <money
                        id="expenses"
                        v-model="addTimesheet.expenses"
                        :min="0"
                        :step="0.5"
                        v-bind="money"
                        class="form-control"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Status</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Status"
                    rules="required"
                  >
                    <v-select
                      v-model="addTimesheet.status_id"
                      :options="status"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                      @input="statusChanged()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-show="isRejected"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Reject Reason</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Reject Reason"
                    :rules="isRejected ? 'required' : ''"
                  >
                    <b-form-textarea
                      v-model="addTimesheet.reject_reason"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Tasks</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6 ml-auto"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-button
                    variant="outline-primary"
                    :to="{ name: 'new-create-task', params: {id: $route.params.id}}"
                    class="mr-1 btn-sm"
                  >
                    Create Task
                  </b-button>
                </div>
              </b-col>
              <b-col
                cols="12"
                class="mt-1"
              >
                <div class="table-responsive-sm">
                  <table class="table b-table">
                    <thead>
                      <tr>
                        <th class="min-w-200">
                          Name</th>
                        <th class="min-w-160">
                          Created Date</th>
                        <th class="min-w-140">
                          Due Date</th>
                        <th>Status</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="task in addTimesheet.tasks"
                        :key="task"
                        :value="task"
                      >
                        <td>{{ task.name }}</td>
                        <td>{{ task.created_date }}</td>
                        <td>{{ task.due_date }}</td>
                        <td>{{ task.status }}</td>
                        <td>{{ task.description }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="success"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Documents</h6>
            </div>
            <b-row class="mt-1">
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Type</label>
                  <v-select
                    v-model="documentNameId"
                    :clearable="true"
                    placeholder="Select"
                    :options="documentNames"
                    :reduce="item => item.id"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Attachment</label>
                  <div class="d-flex">
                    <input
                      ref="myFileInput"
                      type="file"
                      placeholder="Choose a file or drop it here..."
                      class="form-control text-left"
                      @change="attahamentFileSelected"
                    >
                    <b-button
                      variant="outline-primary"
                      class="ml-1 px-1"
                      @click="addFile"
                    >
                      Upload
                    </b-button>
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button-group
                  v-for="(option, index) in addTimesheet.documents"
                  :key="option.id"
                  :value="option.id"
                  class="mr-1 mb-50"
                >
                  <b-badge
                    :href="option.attachment_link"
                    :title="option.name"
                    target="option.attachment_link"
                    variant="light-primary"
                    class="rounded-right-0 line-height-inherit"
                  >
                    <span>{{ option.name }}</span>
                  </b-badge>
                  <b-button
                    variant="danger"
                    class="px-50 py-25"
                    @click="removeFile(option, index)"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="warning"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Comments</h6>
            </div>
            <b-row class="mt-1">
              <b-col
                v-for="(comment, index) in addTimesheet.comments"
                :key="index"
                cols="12"
                md="6"
                class="col-xxl-4"
              >
                <div class="d-flex">
                  <div class="left w-100">
                    <b-form-group>
                      <label>Comment</label>
                      <b-form-textarea
                        v-model="comment.comment"
                        rows="2"
                      />
                    </b-form-group>
                  </div>
                  <div class="right mt-2 ml-1">
                    <b-button
                      v-show="index === 0"
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      @click="addComment"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                    <b-button
                      v-show="index !== 0"
                      variant="outline-danger"
                      class="btn-icon rounded-circle"
                      @click="removeComment(index)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            v-show="addTimesheet.user_defined_fields.length !== 0"
            variant="warning"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>User Defined Fields</h6>
            </div>
            <b-row class="mt-1">
              <b-col
                v-for="field in addTimesheet.user_defined_fields"
                :id="field.id"
                :key="field.id"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group v-show="field.type.toLowerCase() === 'input'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-input
                      v-model="field.value"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-show="field.type.toLowerCase() === 'dropdown'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <v-select
                      v-model="field.value"
                      :options="field.options"
                      label="option"
                      :reduce="item => item.option"
                      :clearable="!field.required"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-show="field.type.toLowerCase() === 'textarea'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-textarea
                      v-model="field.value"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>
        <b-row>
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Update Timesheet
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BButton, BFormInput, BInputGroup, BFormTextarea, BFormSpinbutton, BBadge, BButtonGroup,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import moment from 'moment'
import Timesheet from '@/js/timesheets'
import Constants from '@/js/constant'
import LIST from '@/js/lists'

export default {
  name: 'TimesheetEdit',
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BInputGroup,
    BButton,
    BFormInput,
    BFormTextarea,
    BFormSpinbutton,
    BBadge,
    BButtonGroup,
    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      addTimesheet: {
        name: '',
        status_id: null,
        user_id: null,
        month: '',
        total_hours: '',
        previous_hours: '',
        expenses: '',
        user_defined_fields: [],
        week: '',
        sunday: {
          date: null,
          hours: 0,
        },
        monday: {
          date: null,
          hours: 0,
        },
        tueday: {
          date: null,
          hours: 0,
        },
        wedday: {
          date: null,
          hours: 0,
        },
        thusday: {
          date: null,
          hours: 0,
        },
        friday: {
          date: null,
          hours: 0,
        },
        satday: {
          date: null,
          hours: 0,
        },
        reject_reason: '',
        month_date: null,
        documents: [],
        comments: [],
      },
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      newTimeSheet: {},
      isRejected: false,
      selectedFile: null,
      url: null,
      sun_day: null,
      mon_day: null,
      tue_day: null,
      wed_day: null,
      thu_day: null,
      fri_day: null,
      sat_day: null,
      isShowWeeks: false,
      isShowDays: false,
      document_name: null,
      documentNameId: null,
      status: [],
      timesheet_frequency_id: [],
      employee_consultant_id: [],
      documentNames: [],
    }
  },
  watch: {
    '$store.state.lists.faLists': {
      immediate: true,
      handler() {
        LIST.getTimesheetsStatusFullList(this)
        LIST.getTimesheetsDocumentNameFullList(this)
      },
    },
  },
  mounted() {
  },
  created() {
    this.month = moment().format('MMM-YYYY')
    LIST.getTimesheetsStatusFullList(this)
    LIST.getTimesheetsDocumentNameFullList(this)
    Timesheet.getTimeSheetById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          if (this.documentNameId !== null && this.selectedFile === null) {
            this.$swal({
              title: 'Upload',
              text: 'Please choose file',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return
          }
          if (this.documentNameId === null && this.selectedFile !== null) {
            this.$swal({
              title: 'Upload',
              text: 'Please select document type',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return
          }
          if (this.documentNameId !== null && this.selectedFile !== null) {
            this.addTimesheet.documents.push({
              document_name_id: this.documentNameId,
              attachment_link: this.url,
              document: this.selectedFile,
              file_name: this.documentName,
              name: this.documentName,
            })
            this.selectedFile = null
            this.url = null
            this.documentNameId = null
            this.$refs.myFileInput.value = ''
          }
          this.showLoading = true
          this.newTimeSheet.sunday = this.addTimesheet.sunday
          this.newTimeSheet.monday = this.addTimesheet.monday
          this.newTimeSheet.tueday = this.addTimesheet.tueday
          this.newTimeSheet.wedday = this.addTimesheet.wedday
          this.newTimeSheet.thusday = this.addTimesheet.thusday
          this.newTimeSheet.friday = this.addTimesheet.friday
          this.newTimeSheet.satday = this.addTimesheet.satday
          this.newTimeSheet.status_id = this.addTimesheet.status_id
          this.newTimeSheet.total_hours = this.addTimesheet.total_hours
          this.newTimeSheet.documents = this.addTimesheet.documents
          this.newTimeSheet.comments = this.addTimesheet.comments
          this.newTimeSheet.reject_reason = this.addTimesheet.reject_reason
          Timesheet.updateTimesheet(this, this.$route.params.id, this.addTimesheet)
        }
      })
    },
    attahamentFileSelected(obj) {
      const dis = this
      const fileReader = new FileReader()
      fileReader.onload = fileLoadEvent => {
        dis.selectedFile = fileLoadEvent.target.result
      }
      fileReader.readAsDataURL(obj.target.files[0])
      this.url = URL.createObjectURL(obj.target.files[0])
      this.documentName = obj.target.files[0].name
    },
    removeFile(file, position) {
      if (file.id == null) {
        this.addTimesheet.documents.splice(position, 1)
      } else {
        this.deleteConfirm(file, position)
      }
    },
    deleteConfirm(obj, position) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${obj.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.addTimesheet.documents.splice(position, 1)
        }
      })
    },
    addFile() {
      if (this.documentNameId !== null && this.selectedFile === null) {
        this.$swal({
          title: 'Error!',
          text: ' Please choose file',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId === null && this.selectedFile !== null) {
        this.$swal({
          title: 'Error!',
          text: ' Please select document type',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId !== null && this.selectedFile !== null) {
        this.addTimesheet.documents.push({
          document_name_id: this.documentNameId, attachment_link: this.url, document: this.selectedFile, file_name: this.documentName, name: this.documentName,
        })
        this.selectedFile = null
        this.url = null
        this.documentNameId = null
        this.$refs.myFileInput.value = ''
      }
    },
    hoursChanged() {
      let totlaHours = 0
      if (this.addTimesheet.sunday.hours != null) {
        totlaHours = Number(totlaHours) + Number(this.addTimesheet.sunday.hours)
      }
      if (this.addTimesheet.monday.hours != null) {
        totlaHours = Number(totlaHours) + Number(this.addTimesheet.monday.hours)
      }
      if (this.addTimesheet.tueday.hours != null) {
        totlaHours = Number(totlaHours) + Number(this.addTimesheet.tueday.hours)
      }
      if (this.addTimesheet.wedday.hours != null) {
        totlaHours = Number(totlaHours) + Number(this.addTimesheet.wedday.hours)
      }
      if (this.addTimesheet.thusday.hours != null) {
        totlaHours = Number(totlaHours) + Number(this.addTimesheet.thusday.hours)
      }
      if (this.addTimesheet.friday.hours != null) {
        totlaHours = Number(totlaHours) + Number(this.addTimesheet.friday.hours)
      }
      if (this.addTimesheet.satday.hours != null) {
        totlaHours = Number(totlaHours) + Number(this.addTimesheet.satday.hours)
      }
      this.addTimesheet.total_hours = totlaHours
    },
    statusChanged() {
      this.isRejected = false
      this.status.forEach(status => {
        if (status.id === this.addTimesheet.status_id) {
          if (status.label.toLowerCase() === 'rejected') { this.isRejected = true }
        }
      })
    },
    setStatus(status) {
      this.status = status
    },
    setDocumentNames(documentNames) {
      this.documentNames = documentNames
    },
    setTimesheet(timeSheet) {
      this.addTimesheet = timeSheet
      this.$store.state.taskDetails.type = 'timesheet'
      this.$store.state.taskDetails.task_type = 'timesheet'
      if (this.addTimesheet.comments.length === 0) {
        this.addComment()
      }
      this.addTimesheet.month = moment(timeSheet.month_date, 'MM-DD-YYYY').format('MMM-YYYY')
      if (timeSheet.status.toLowerCase() === 'rejected') {
        this.isRejected = true
      } else {
        this.isRejected = false
      }
      this.$store.state.taskDetails.month = this.addTimesheet.month
      this.$store.state.taskDetails.frequency = timeSheet.timesheet_frequency
      this.$store.state.taskDetails.name = timeSheet.name
      if (timeSheet.timesheet_frequency === 'weekly') {
        this.isShowWeeks = true
        this.isShowDays = true
        this.addTimesheet.week = `${timeSheet.week_start_date} - ${timeSheet.week_end_date}`
        this.$store.state.taskDetails.week = this.addTimesheet.week
        this.sun_day = moment(Constants.getDateStringFromEprmDate(timeSheet.sunday.date), 'MM-DD-YYYY').format('MM/DD')
        this.mon_day = moment(Constants.getDateStringFromEprmDate(timeSheet.monday.date), 'MM-DD-YYYY').format('MM/DD')
        this.tue_day = moment(Constants.getDateStringFromEprmDate(timeSheet.tueday.date), 'MM-DD-YYYY').format('MM/DD')
        this.wed_day = moment(Constants.getDateStringFromEprmDate(timeSheet.wedday.date), 'MM-DD-YYYY').format('MM/DD')
        this.thu_day = moment(Constants.getDateStringFromEprmDate(timeSheet.thusday.date), 'MM-DD-YYYY').format('MM/DD')
        this.fri_day = moment(Constants.getDateStringFromEprmDate(timeSheet.friday.date), 'MM-DD-YYYY').format('MM/DD')
        this.sat_day = moment(Constants.getDateStringFromEprmDate(timeSheet.satday.date), 'MM-DD-YYYY').format('MM/DD')
      } else {
        this.isShowWeeks = false
        this.isShowDays = false
      }
    },
    timesheetUpdated(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    timesheetNotUpdated(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    addComment() {
      const comment = {
        comment: '',
        date: Constants.getEprmMonthDateFromString(moment()),
        time: moment().format('hh:mm a'),
      }
      this.addTimesheet.comments.push(comment)
    },
    removeComment(index) {
      this.addTimesheet.comments.splice(index, 1)
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.repeater-form {
  transition: .35s height;
}
</style>
