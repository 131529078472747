import axios from 'axios'
import moment from 'moment'

import Constants from '@/js/constant'

const HOST = Constants.hosts.faurl
const timesheet = `${HOST}timesheets`

export default {
  getTimesheets(context1) {
    const context = context1
    const queryParams = { query: context.$store.state.query.query, page: context.$store.state.query.page - 1, type: context.$store.state.query.activeTab }
    let params = {}
    if (context.$store.state.query.activeTab === 'Monthly') {
      if (context.$store.state.query.month !== null && context.$store.state.query.month !== '') {
        queryParams.start_date = 1
        queryParams.start_month = moment(context.$store.state.query.month, 'MMM-YYYY').format('MM')
        queryParams.start_year = moment(context.$store.state.query.month, 'MMM-YYYY').format('YYYY')
      }
    }
    if (context.$store.state.query.activeTab === 'Weekly') {
      if (context.$store.state.query.startdate !== null && context.$store.state.query.startdate !== '') {
        queryParams.start_date = moment(context.$store.state.query.startdate, 'MM-DD-YYYY').format('DD')
        queryParams.start_month = moment(context.$store.state.query.startdate, 'MM-DD-YYYY').format('MM')
        queryParams.start_year = moment(context.$store.state.query.startdate, 'MM-DD-YYYY').format('YYYY')
      }
      if (context.$store.state.query.endDate !== null && context.$store.state.query.endDate !== '') {
        queryParams.end_date = moment(context.$store.state.query.endDate, 'MM-DD-YYYY').format('DD')
        queryParams.end_month = moment(context.$store.state.query.endDate, 'MM-DD-YYYY').format('MM')
        queryParams.end_year = moment(context.$store.state.query.endDate, 'MM-DD-YYYY').format('YYYY')
      }
    }
    if (queryParams !== {}) {
      params = { params: queryParams }
    } else {
      params = { params: {} }
    }
    axios.get(timesheet, params).then(response => {
      context.setTimesheets(response.data)
    }, () => {
    })
  },
  addTimesheet(context1, body, isAccepted) {
    const context = context1
    context.$vs.loading()
    const params = { params: { is_accepted: isAccepted } }
    axios.post(timesheet, body, params).then(response => {
      context.$vs.loading.close()
      context.timesheetAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.timesheetNotAdded(error.response.data)
    })
  },
  updateTimesheet(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${timesheet}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.timesheetUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.timesheetNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${timesheet}/${id}`).then(response => {
      context.$vs.loading.close()
      context.timesheetDeleted(response.data)
    }, error => {
      context.$vs.loading.close()
      context.timesheetNotDeleted(error.response.data.return_message)
    })
  },
  toggleStatus(context1, id, statusId) {
    const context = context1
    context.$vs.loading()
    axios.put(`${timesheet}/update-status/${id}/${statusId}`, {}).then(response => {
      context.$vs.loading.close()
      context.updated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.updateError(error.response.data.return_message)
    })
  },
  getTimeSheetById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${timesheet}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setTimesheet(response.data)
    }, error => {
      context.$vs.loading.close()
      context.timesheetNotFound(error.response.data.return_message)
    })
  },

}
