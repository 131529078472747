<template>
  <b-card
    no-body
    class="mb-0"
  >
    <b-card-body class="py-1">
      <b-row>
        <b-col
          cols="12"
          md="2"
        >
          <h4 class="card-title mb-0 pt-50">
            Prospects</h4>
        </b-col>
        <b-col
          cols="12"
          md="4 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end mt-50">
            <strong>Total Emails: {{ emails_count }} / {{ totalProspects }}</strong>
            <b-button
              v-show="emails_count > 0"
              variant="primary"
              size="sm"
              class="ml-2"
              @click="copyText"
            >
              Copy Emails
            </b-button>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="6 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                @keyup.enter="updateSearchQuery"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="updateSearchQuery"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="14"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button
              variant="primary"
              class="ml-md-2 px-1 min-w-130"
              :to="{ name: 'human-resources-prospects-admin-prospects-new'}"
            >
              Add Prospect
            </b-button>
            <b-button
              variant="outline-secondary"
              class="ml-2"
              @click="back"
            >
              Back
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BRow, BCol, BInputGroup, BFormInput, BInputGroupAppend, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  data() {
    return {
      totalProspects: 0,
      searchQuery: '',
      skills: [],
      states: [],
      roleUsers: [],
      emails_count: 0,
    }
  },
  watch: {
  },
  created() {
    this.skillId = this.$route.query.skill_id
    this.searchQuery = this.$store.state.query.query
  },
  methods: {
    updateSearchQuery() {
      this.$emit('searchUpdated', this.searchQuery)
    },
    updateSearchText(text) {
      this.searchQuery = text
    },
    copyText() {
      this.$emit('copyText')
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
</style>
