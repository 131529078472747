import Employees from '@/views/human-resources/employees/Employees.vue'
import EmployeesNew from '@/views/human-resources/employees/actions/New.vue'
import EmployeesEdit from '@/views/human-resources/employees/actions/Edit.vue'
import EmployeesView from '@/views/human-resources/employees/actions/View.vue'
import EmployerLetter from '@/views/human-resources/employees/actions/EmployerLetter.vue'
import ClientLetter from '@/views/human-resources/employees/actions/ClientLetter.vue'
import VerificationLetter from '@/views/human-resources/employees/actions/VerificationLetter.vue'
import OfferLetter from '@/views/human-resources/employees/actions/OfferLetter.vue'
import FacilitiesNew from '@/views/human-resources/facilities/actions/New.vue'
import FacilitiesEdit from '@/views/human-resources/facilities/actions/Edit.vue'
import FacilitiesView from '@/views/human-resources/facilities/actions/View.vue'
import Facilities from '@/views/human-resources/facilities/Facilities.vue'
import Vendors from '@/views/human-resources/vendors/Vendors.vue'
import VendorsNew from '@/views/human-resources/vendors/actions/New.vue'
import VendorsEdit from '@/views/human-resources/vendors/actions/Edit.vue'
import VendorsView from '@/views/human-resources/vendors/actions/View.vue'
import Onboarding from '@/views/human-resources/onboarding/Onboarding.vue'
import OnboardingNew from '@/views/human-resources/onboarding/actions/New.vue'
import OnboardingEdit from '@/views/human-resources/onboarding/actions/Edit.vue'
import OnboardingView from '@/views/human-resources/onboarding/actions/View.vue'
import CandidatesEdit from '@/views/human-resources/candidates/actions/Edit.vue'
import CandidatesView from '@/views/human-resources/candidates/actions/View.vue'
import ExistingCandidates from '@/views/human-resources/candidates/actions/ExistingCandidates.vue'
import ProspectsAdminDashboard from '@/views/human-resources/prospects-admin/Dashboard.vue'
import JobsGroupJobs from '@/views/human-resources/prospects-admin/group-jobs/Jobs.vue'
import JobsGroupJobNew from '@/views/human-resources/prospects-admin/group-jobs/actions/New.vue'
import JobsGroupJobEdit from '@/views/human-resources/prospects-admin/group-jobs/actions/Edit.vue'
import JobsGroupJobView from '@/views/human-resources/prospects-admin/group-jobs/actions/View.vue'
import JobsGroupJobClone from '@/views/human-resources/prospects-admin/group-jobs/actions/Clone.vue'
import ProspectsAdminJobs from '@/views/human-resources/prospects-admin/jobs/Jobs.vue'
import ProspectsAdminJobNew from '@/views/human-resources/prospects-admin/jobs/actions/New.vue'
import ProspectsAdminJobEdit from '@/views/human-resources/prospects-admin/jobs/actions/Edit.vue'
import ProspectsAdminJobView from '@/views/human-resources/prospects-admin/jobs/actions/View.vue'
import ProspectsAdminJobClone from '@/views/human-resources/prospects-admin/jobs/actions/Clone.vue'
import ProspectsAdminProspects from '@/views/human-resources/prospects-admin/prospects/Prospects.vue'
import ProspectsAdminProspectMatcher from '@/views/human-resources/prospects-admin/prospect-matcher/ProspectMatcher.vue'
import ProspectsAdminJobProspectMatcher from '@/views/human-resources/prospects-admin/job-prospect-matcher/JobProspectMatcher.vue'
import ProspectsAdminGroupJobs from '@/views/human-resources/prospects-admin/group-jobs/GroupJobs.vue'
import ProspectsAdminGroupJobsCitySkill from '@/views/human-resources/prospects-admin/group-jobs/GroupJobsCitySkill.vue'
import ProspectsAdminAllProspects from '@/views/human-resources/prospects-admin/all-prospects/AllProspects.vue'
import JobAppliedProspects from '@/views/human-resources/prospects-admin/all-prospects/JobAppliedProspects.vue'
import ProspectsAdminProspectNew from '@/views/human-resources/prospects-admin/all-prospects/actions/New.vue'
import ProspectsAdminProspectEdit from '@/views/human-resources/prospects-admin/all-prospects/actions/Edit.vue'
import ProspectsAdminJobMakeUrgent from '@/views/human-resources/prospects-admin/jobs/actions/MakeUrgent.vue'
import ProspectAsRegistrant from '@/views/human-resources/prospects-admin/all-prospects/ProspectAsRegistrant.vue'
import ProspectAsNotRegistrant from '@/views/human-resources/prospects-admin/all-prospects/ProspectAsNotRegistrant.vue'
import NewJobProspects from '@/views/human-resources/prospects-admin/all-prospects/NewJobProspects.vue'
import NewJobRegistrants from '@/views/human-resources/prospects-admin/all-prospects/NewJobRegistrants.vue'
import IndeedProspectsAdministrationGroupJobs from '@/views/human-resources/indeed-prospects-administration/group-jobs/GroupJobs.vue'
import IndeedProspectsAdministrationClosedJobs from '@/views/human-resources/indeed-prospects-administration/closed-jobs/ClosedJobs.vue'

export default [
  // *===============================================---*
  // *--------- Human Resources -------------------------------*
  // *===============================================---*
  // *------------ Employees --------------------*
  {
    path: '/human-resources/employees',
    name: 'human-resources-employees',
    component: Employees,
  },
  {
    path: '/human-resources/employees/new',
    name: 'human-resources-employees-new',
    component: EmployeesNew,
    meta: {
      navActiveLink: 'human-resources-employees',
    },
  },
  {
    path: '/human-resources/employees/edit/:id',
    name: 'human-resources-employees-edit',
    component: EmployeesEdit,
    meta: {
      navActiveLink: 'human-resources-employees',
    },
  },
  {
    path: '/human-resources/employees/view/:id',
    name: 'human-resources-employees-view',
    component: EmployeesView,
    meta: {
      navActiveLink: 'human-resources-employees',
    },
  },
  {
    path: '/human-resources/employees/view/:id/offer-letter',
    name: 'human-resources-employees-offer-letter',
    component: OfferLetter,
    meta: {
      navActiveLink: 'human-resources-employees',
    },
  },
  {
    path: '/human-resources/employees/view/:id/employer-letter',
    name: 'human-resources-employees-employer-letter',
    component: EmployerLetter,
    meta: {
      navActiveLink: 'human-resources-employees',
    },
  },
  {
    path: '/human-resources/employees/view/:id/client-letter',
    name: 'human-resources-employees-client-letter',
    component: ClientLetter,
    meta: {
      navActiveLink: 'human-resources-employees',
    },
  },
  {
    path: '/human-resources/employees/view/:id/verification-letter',
    name: 'human-resources-employees-verification-letter',
    component: VerificationLetter,
    meta: {
      navActiveLink: 'human-resources-employees',
    },
  },
  // *------------ Facilities --------------------*
  {
    path: '/human-resources/facilities',
    name: 'human-resources-facilities',
    component: Facilities,
  },
  {
    path: '/human-resources/facilities/new',
    name: 'human-resources-facilities-new',
    component: FacilitiesNew,
    meta: {
      navActiveLink: 'human-resources-facilities',
    },
  },
  {
    path: '/human-resources/facilities/edit/:id',
    name: 'human-resources-facilities-edit',
    component: FacilitiesEdit,
    meta: {
      navActiveLink: 'human-resources-facilities',
    },
  },
  {
    path: '/human-resources/facilities/view/:id',
    name: 'human-resources-facilities-view',
    component: FacilitiesView,
    meta: {
      navActiveLink: 'human-resources-facilities',
    },
  },
  // *------------ Vendors --------------------*
  {
    path: '/human-resources/vendors',
    name: 'human-resources-vendors',
    component: Vendors,
  },
  {
    path: '/human-resources/vendors/new',
    name: 'human-resources-vendors-new',
    component: VendorsNew,
    meta: {
      navActiveLink: 'human-resources-vendors',
    },
  },
  {
    path: '/human-resources/vendors/edit/:id',
    name: 'human-resources-vendors-edit',
    component: VendorsEdit,
    meta: {
      navActiveLink: 'human-resources-vendors',
    },
  },
  {
    path: '/human-resources/vendors/view/:id',
    name: 'human-resources-vendors-view',
    component: VendorsView,
    meta: {
      navActiveLink: 'human-resources-vendors',
    },
  },
  // *------------ Onboarding --------------------*
  {
    path: '/human-resources/onboarding',
    name: 'human-resources-onboarding',
    component: Onboarding,
  },
  {
    path: '/human-resources/onboarding/new',
    name: 'human-resources-onboarding-new',
    component: OnboardingNew,
    meta: {
      navActiveLink: 'human-resources-onboarding',
    },
  },
  {
    path: '/human-resources/onboarding/edit/:id',
    name: 'human-resources-onboarding-edit',
    component: OnboardingEdit,
    meta: {
      navActiveLink: 'human-resources-onboarding',
    },
  },
  {
    path: '/human-resources/onboarding/view/:id',
    name: 'human-resources-onboarding-view',
    component: OnboardingView,
    meta: {
      navActiveLink: 'human-resources-onboarding',
    },
  },
  // *===============================================---*
  // *--------- Jobs/Prospects Admin -------------------------------*
  // *===============================================---*
  // *------------ Dashboard --------------------*
  {
    path: '/human-resources/prospects-admin/dashboard',
    name: 'human-resources-prospects-admin-dashboard',
    component: ProspectsAdminDashboard,
    meta: {
      navActiveLink: 'human-resources-prospects-admin-dashboard',
    },
  },
  // *------------ Jobs --------------------*
  {
    path: '/human-resources/prospects-admin/jobs',
    name: 'human-resources-prospects-admin-jobs',
    component: ProspectsAdminJobs,
    meta: {
      navActiveLink: 'human-resources-prospects-admin-jobs',
    },
  },
  {
    path: '/human-resources/prospects-admin/jobs/new',
    name: 'human-resources-prospects-admin-jobs-new',
    component: ProspectsAdminJobNew,
    meta: {
      navActiveLink: 'human-resources-prospects-admin-jobs',
    },
  },
  {
    path: '/human-resources/prospects-admin/jobs/edit/:id',
    name: 'human-resources-prospects-admin-jobs-edit',
    component: ProspectsAdminJobEdit,
    meta: {
      navActiveLink: 'human-resources-prospects-admin-jobs',
    },
  },
  {
    path: '/human-resources/prospects-admin/jobs/view/:id',
    name: 'human-resources-prospects-admin-jobs-view',
    component: ProspectsAdminJobView,
    meta: {
      navActiveLink: 'human-resources-prospects-admin-jobs',
    },
  },
  {
    path: '/human-resources/prospects-admin/jobs/clone/:id',
    name: 'human-resources-prospects-admin-jobs-clone',
    component: ProspectsAdminJobClone,
    meta: {
      navActiveLink: 'human-resources-prospects-admin-jobs',
    },
  },
  {
    path: '/human-resources/prospects-admin/jobs/make-urgent',
    name: 'human-resources-prospects-admin-jobs-make-urgent',
    component: ProspectsAdminJobMakeUrgent,
    meta: {
      navActiveLink: 'human-resources-prospects-admin-jobs',
    },
  },
  // *------------ Candidates --------------------*
  {
    path: '/human-resources/prospects-admin/jobs/:job_id/existing-candidates',
    name: 'human-resources-prospects-admin-jobs-existing-candidates',
    component: ExistingCandidates,
    meta: {
      navActiveLink: 'human-resources-prospects-admin-jobs',
    },
  },
  {
    path: '/human-resources/prospects-admin/jobs/:job_id/candidates/:id/edit',
    name: 'human-resources-candidates-edit',
    component: CandidatesEdit,
    meta: {
      navActiveLink: 'human-resources-jobs',
    },
  },
  {
    path: '/human-resources/prospects-admin/jobs/:job_id/candidates/:id/view',
    name: 'human-resources-candidates-view',
    component: CandidatesView,
    meta: {
      navActiveLink: 'human-resources-jobs',
    },
  },
  // *------------ Prospects --------------------*
  {
    path: '/human-resources/prospects-admin/prospects',
    name: 'human-resources-prospects-admin-prospects',
    component: ProspectsAdminProspects,
    meta: {
      navActiveLink: 'human-resources-prospects-admin-prospects',
    },
  },
  // *------------ All Prospects --------------------*
  {
    path: '/human-resources/prospects-admin/all-prospects',
    name: 'human-resources-prospects-admin-all-prospects',
    component: ProspectsAdminAllProspects,
    meta: {
      navActiveLink: 'human-resources-prospects-admin-prospects',
    },
  },
  {
    path: '/human-resources/prospects-admin/job-applied-registrants',
    name: 'human-resources-prospects-admin-job-applied-registrants',
    component: JobAppliedProspects,
    meta: {
      navActiveLink: 'human-resources-prospects-admin-prospects',
    },
  },
  {
    path: '/human-resources/prospects-admin/new-job-prospects',
    name: 'human-resources-prospects-admin-new-job-prospects',
    component: NewJobProspects,
    meta: {
      navActiveLink: 'human-resources-prospects-admin-prospects',
    },
  },
  {
    path: '/human-resources/prospects-admin/new-job-registrants',
    name: 'human-resources-prospects-admin-new-job-registrants',
    component: NewJobRegistrants,
    meta: {
      navActiveLink: 'human-resources-prospects-admin-prospects',
    },
  },
  {
    path: '/human-resources/prospects-admin/all-prospects/new',
    name: 'human-resources-prospects-admin-prospects-new',
    component: ProspectsAdminProspectNew,
    meta: {
      navActiveLink: 'human-resources-prospects-admin-prospects',
    },
  },
  {
    path: '/human-resources/prospects-admin/all-prospects/edit/:id',
    name: 'human-resources-prospects-admin-prospects-edit',
    component: ProspectsAdminProspectEdit,
    meta: {
      navActiveLink: 'human-resources-prospects-admin-prospects',
    },
  },
  {
    path: '/human-resources/prospects-admin/all-prospects/prospect-as-registrant',
    name: 'human-resources-prospects-admin-all-prospects-prospect-as-registrant',
    component: ProspectAsRegistrant,
    meta: {
      navActiveLink: 'human-resources-prospects-admin-prospects',
    },
  },
  {
    path: '/human-resources/prospects-admin/all-prospects/prospect-as-not-registrant',
    name: 'human-resources-prospects-admin-all-prospects-prospect-as-not-registrant',
    component: ProspectAsNotRegistrant,
    meta: {
      navActiveLink: 'human-resources-prospects-admin-prospects',
    },
  },
  // *------------ Prospect Matcher --------------------*
  {
    path: '/human-resources/prospects-admin/prospect-matcher',
    name: 'human-resources-prospects-admin-prospect-matcher',
    component: ProspectsAdminProspectMatcher,
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
    },
  },
  // *------------ Job Prospect Matcher --------------------*
  {
    path: '/human-resources/prospects-admin/job-prospect-matcher',
    name: 'human-resources-prospects-admin-job-prospect-matcher',
    component: ProspectsAdminJobProspectMatcher,
    meta: {
      navActiveLink: 'human-resources-prospects-admin-prospect-matcher',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
    },
  },
  // *------------ Group Jobs --------------------*
  {
    path: '/human-resources/prospects-admin/group-jobs',
    name: 'human-resources-prospects-admin-group-jobs',
    component: ProspectsAdminGroupJobs,
  },
  {
    path: '/human-resources/prospects-admin/group-jobs-city-skill',
    name: 'human-resources-prospects-admin-group-jobs-city-skill',
    component: ProspectsAdminGroupJobsCitySkill,
    meta: {
      navActiveLink: 'human-resources-prospects-admin-group-jobs',
    },
  },
  {
    path: '/human-resources/prospects-admin/group-jobs/jobs',
    name: 'human-resources-prospects-admin-group-jobs-jobs',
    component: JobsGroupJobs,
    meta: {
      navActiveLink: 'human-resources-prospects-admin-group-jobs',
    },
  },
  {
    path: '/human-resources/prospects-admin/group-jobs/jobs/new',
    name: 'human-resources-prospects-admin-group-jobs-jobs-new',
    component: JobsGroupJobNew,
    meta: {
      navActiveLink: 'human-resources-prospects-admin-group-jobs',
    },
  },
  {
    path: '/human-resources/prospects-admin/group-jobs/jobs/edit/:id',
    name: 'human-resources-prospects-admin-group-jobs-jobs-edit',
    component: JobsGroupJobEdit,
    meta: {
      navActiveLink: 'human-resources-prospects-admin-group-jobs',
    },
  },
  {
    path: '/human-resources/prospects-admin/group-jobs/jobs/view/:id',
    name: 'human-resources-prospects-admin-group-jobs-jobs-view',
    component: JobsGroupJobView,
    meta: {
      navActiveLink: 'human-resources-prospects-admin-group-jobs',
    },
  },
  {
    path: '/human-resources/prospects-admin/group-jobs/jobs/clone/:id',
    name: 'human-resources-prospects-admin-group-jobs-jobs-clone',
    component: JobsGroupJobClone,
    meta: {
      navActiveLink: 'human-resources-prospects-admin-group-jobs',
    },
  },
  // *===============================================---*
  // *--------- Indeed Prospects Administration -------------------------------*
  // *===============================================---*
  // *------------ Group Jobs --------------------*
  {
    path: '/human-resources/indeed-prospects-administration/group-jobs',
    name: 'human-resources-indeed-prospects-administration-group-jobs',
    component: IndeedProspectsAdministrationGroupJobs,
  },
  // *------------ Closed Jobs --------------------*
  {
    path: '/human-resources/indeed-prospects-administration/closed-jobs',
    name: 'human-resources-indeed-prospects-administration-closed-jobs',
    component: IndeedProspectsAdministrationClosedJobs,
  },
]
