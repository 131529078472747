import DashboardEdit from '@/views/companies/actions/Edit.vue'
import DashboardView from '@/views/companies/actions/View.vue'

export default [
  {
    path: '/companies/edit/:id',
    name: 'company-edit',
    component: DashboardEdit,
    meta: {
      navActiveLink: 'companies',
    },
  },
  {
    path: '/companies/view/:id',
    name: 'company-view',
    component: DashboardView,
    meta: {
      navActiveLink: 'companies',
    },
  },
]
