<template>
  <div>
    <b-row class="match-height">
      <b-col
        xl="3"
        sm="4"
        class="col-xxl-2"
      >
        <b-link
          :to="{ name: 'human-resources-prospects-admin-jobs' }"
          class="text-secondary"
        >
          <statistic-card-vertical
            color="primary"
            :statistic="dashboardCountResponse.open_jobs"
            statistic-title="Open Jobs"
          />
        </b-link>
      </b-col>
      <b-col
        xl="3"
        sm="4"
        class="col-xxl-2"
      >
        <statistic-card-vertical
          color="primary"
          :statistic="dashboardCountResponse.assigned_jobs"
          statistic-title="Assigned Jobs"
        />
      </b-col>
      <b-col
        xl="3"
        sm="4"
        class="col-xxl-2"
      >
        <statistic-card-vertical
          color="primary"
          :statistic="dashboardCountResponse.un_assigned_jobs"
          statistic-title="Unassigned Jobs"
        />
      </b-col>
      <b-col
        xl="3"
        sm="4"
        class="col-xxl-2"
      >
        <statistic-card-vertical
          color="primary"
          :statistic="dashboardCountResponse.closing_soon_jobs"
          statistic-title="Closing Soon"
        />
      </b-col>
      <!-- <b-col
        xl="3"
        sm="4"
        class="col-xxl-2"
      >
        <statistic-card-vertical
          color="primary"
          :statistic="dashboardCountResponse.matched_prospects"
          statistic-title="Matching Prospects"
        />
      </b-col> -->
      <b-col
        xl="3"
        sm="4"
        class="col-xxl-2"
      >
        <b-link
          :to="{ name: 'human-resources-prospects-admin-all-prospects' }"
          class="text-secondary"
        >
          <statistic-card-vertical
            color="primary"
            :statistic="dashboardCountResponse.total_prospects"
            statistic-title="Total Prospects"
          />
        </b-link>
      </b-col>
    </b-row>

    <h5 class="mt-1">
      Jobs
    </h5>
    <b-card
      no-body
      class="card-statistics"
    >
      <b-tabs class="custom-tabs mt-50">
        <b-tab>
          <template #title>
            <div class="border border-dashed d-flex flex-column justify-content-center align-items-center cursor-pointer rounded px-2 py-1 me-6">
              <span>By Main Skill</span>
            </div>
          </template>
          <div class="mx-1">
            <b-row class="tab-table-bordered">
              <b-col
                v-for="facility_job in bySkillResponse.facility_jobs"
                :key="facility_job"
                :value="facility_job"
                cols="12"
                md="3"
                lg="2"
                class="table-text cursor-pointer"
                @click="skillSelected(facility_job.id)"
              >
                <div class="table-text-inner">
                  <div class="d-flex align-items-center justify-content-between">
                    <p class="table-text-warp mb-0">
                      {{ facility_job.label }} </p>
                    <span class="badge badge-primary"> {{ facility_job.count }} </span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <div class="border border-dashed d-flex flex-column justify-content-center align-items-center cursor-pointer rounded px-2 py-1 me-6">
              <span>By State</span>
            </div>
          </template>
          <div class="mx-1">
            <b-row class="tab-table-bordered">
              <b-col
                v-for="facility_job in byLocationStateResponse.facility_jobs"
                :key="facility_job"
                :value="facility_job"
                cols="12"
                md="3"
                lg="2"
                class="table-text cursor-pointer"
                @click="stateSelected(facility_job.id)"
              >
                <div class="table-text-inner">
                  <div class="d-flex align-items-center justify-content-between">
                    <p class="table-text-warp mb-0">
                      {{ facility_job.label }} </p>
                    <span class="badge badge-primary"> {{ facility_job.count }} </span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <div class="border border-dashed d-flex flex-column justify-content-center align-items-center cursor-pointer rounded px-2 py-1 me-6">
              <span>By City</span>
            </div>
          </template>
          <div class="mx-1">
            <b-row class="tab-table-bordered">
              <b-col
                v-for="facility_job in byLocationResponse.facility_jobs"
                :key="facility_job"
                :value="facility_job"
                cols="12"
                md="3"
                lg="2"
                class="table-text cursor-pointer"
                @click="locationSelected(facility_job.id)"
              >
                <div class="table-text-inner">
                  <div class="d-flex align-items-center justify-content-between">
                    <p class="table-text-warp mb-0">
                      {{ facility_job.label }} </p>
                    <span class="badge badge-primary"> {{ facility_job.count }} </span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <div class="border border-dashed d-flex flex-column justify-content-center align-items-center cursor-pointer rounded px-2 py-1 me-6">
              <span>By Client</span>
            </div>
          </template>
          <div class="mx-1">
            <b-row class="tab-table-bordered">
              <b-col
                v-for="facility_job in byFacilityResponse.facility_jobs"
                :key="facility_job"
                :value="facility_job"
                cols="12"
                md="3"
                lg="2"
                class="table-text cursor-pointer"
                @click="facilitySelected(facility_job.id)"
              >
                <div class="table-text-inner">
                  <div class="d-flex align-items-center justify-content-between">
                    <p class="table-text-warp mb-0">
                      {{ facility_job.label }} </p>
                    <span class="badge badge-primary"> {{ facility_job.count }} </span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BTabs, BTab, BLink,
} from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical2.vue'
import User from '@/js/user'
import Dashboard from '@/js/prospectAdminDashboard'

export default {
  name: 'AppDashboard',
  components: {
    BRow,
    BCol,
    BCard,
    BTabs,
    BLink,
    BTab,
    StatisticCardVertical,
  },
  data() {
    return {
      user: User,
      byFacilityResponse: {},
      bySkillResponse: {},
      byLocationResponse: {},
      byLocationStateResponse: {},
      dashboardCountResponse: {},
    }
  },
  created() {
    Dashboard.getCountByFacility(this)
    Dashboard.getCountBySkill(this)
    Dashboard.getCountByLocation(this)
    Dashboard.getCountByLocationState(this)
    Dashboard.getDashboardCount(this)
  },
  computed: {
    counts: {
      get() {
        return this.$store.state.counts
      },
      set(val) {
        this.$store.state.counts = val
      },
    },
  },
  methods: {
    facilitySelected(facilityId) {
      this.$router.push(`/human-resources/prospects-admin/jobs?facility_id=${facilityId}`).catch(() => {})
    },
    skillSelected(skillId) {
      this.$router.push(`/human-resources/prospects-admin/jobs?skill_id=${skillId}`).catch(() => {})
    },
    locationSelected(locationId) {
      this.$router.push(`/human-resources/prospects-admin/jobs?city_id=${locationId}`).catch(() => {})
    },
    stateSelected(stateId) {
      this.$router.push(`/human-resources/prospects-admin/jobs?state_id=${stateId}`).catch(() => {})
    },
    setDashboardCount(dashboardCountResponse) {
      this.dashboardCountResponse = dashboardCountResponse
    },
    setCountByFacility(byFacilityResponse) {
      this.byFacilityResponse = byFacilityResponse
    },
    setCountBySkill(bySkillResponse) {
      this.bySkillResponse = bySkillResponse
    },
    setCountByLocation(byLocationResponse) {
      this.byLocationResponse = byLocationResponse
    },
    setCountByLocationState(byLocationStateResponse) {
      this.byLocationStateResponse = byLocationStateResponse
    },
  },
}
</script>
