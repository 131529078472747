<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Make Urgent</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  id="mail_received_date"
                >
                  <label>Mail Received Date</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Mail Received Date"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="mail_received_date"
                      class="form-control"
                      :config="$store.state.notGraterThanToday"
                      placeholder="MM-DD-YYYY"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Mail Received Time</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Mail Received Time"
                    rules="required"
                  >
                    <flat-pickr
                      id="time"
                      v-model="makeUrgent.time"
                      class="form-control"
                      :config="timeConfig"
                      placeholder="00:00"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group class="mb-md-0">
                  <label>Job Id</label>
                  <div class="d-flex">
                    <div class="w-100">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Job Id"
                        :rules="job_id !== '' ? 'required|integer|between:0001,999999999' : ''"
                      >
                        <b-form-input
                          id="job_id"
                          v-model="job_id"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                    <b-button
                      variant="outline-primary"
                      class="ml-1"
                      @click="addJobId"
                    >
                      Add
                    </b-button>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <div class="demo-inline-spacing demo-inline-top-padding">
                  <b-button-group v-for="(job_id, index) in makeUrgent.job_ids" :key="job_id">
                    <b-badge style="'border-top-left-radius=0.358rem; border-bottom-left-radius=0.358rem;'" variant="primary">
                      {{ job_id }}
                    </b-badge>
                    <b-button
                      variant="danger"
                      class="px-50 py-25"
                      @click="removeJobId(index)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </b-button-group>
                </div>
              </b-col>
            </b-row>

            <b-row class="mt-1">
              <b-col class="text-right">
                <b-button
                  variant="primary"
                  type="button"
                  @click="submitForm"
                >
                  Save
                </b-button>
              </b-col>
            </b-row>
            <hr>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <h6>Existing Urgent Jobs</h6>
              </b-col>
              <b-col
                cols="12"
                md="6 ml-auto"
              >
                <div class="d-md-flex align-items-center justify-content-end">
                  <b-input-group class="mb-1">
                    <b-form-input
                      v-model="searchQuery"
                      placeholder="Search"
                      @keyup.enter="updateSearchQuery"
                    />
                    <b-input-group-append>
                      <b-button
                        variant="outline-primary"
                        @click="updateSearchQuery"
                      >
                        <feather-icon
                          icon="SearchIcon"
                          size="14"
                        />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </div>
              </b-col>
            </b-row>
            <b-table
              :items="jobsResponse.jobs"
              :fields="fields"
              :current-page="currentPage"
              bordered
              responsive
              class="position-relative custom-table-details"
            >
              <template #cell(actions)="scope">
                <b-button
                  variant="outline-primary"
                  type="button"
                  size="sm"
                  @click="makeNormal(scope.item)"
                >
                  Make Normal
                </b-button>
              </template>
            </b-table>
            <div v-if="jobsResponse.jobs.length === 0" class="d-flex flex-sm-row flex-column flex-wrap justify-content-center mb-1 mt-1 mb-sm-0">
              <p class="text-center mb-0">Urgent Jobs not exist</p>
            </div>

          <b-card-body class="d-flex justify-content-end flex-wrap pt-0">
            <!-- pagination -->
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="jobsResponse.count"
                prev-class="prev-item"
                next-class="next-item"
                first-number
                last-number
                align="right"
                :per-page="1"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
          </app-timeline-item>
        </app-timeline>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BCardBody, BRow, BCol, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BBadge, BButtonGroup, BButton, BTable, BPagination,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import flatPickr from 'vue-flatpickr-component'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import store from '@/store/index'
import Jobs from '@/js/jobs'
import Constants from '@/js/constant'
import moment from 'moment'
import 'moment-timezone'

export default {
  name: 'JobMakeUrgent',
  components: {
    BCard,
    BCardBody,
    AppTimeline,
    AppTimelineItem,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BBadge,
    BButtonGroup,
    BButton,
    BTable,
    BPagination,
  },
  setup() {
  },
  data() {
    return {
      required,
      job_id: '',
      mail_received_date: null,
      momentRef: null,
      perPage: 10,
      searchQuery: '',
      makeUrgent: {
        time: null,
        job_ids: [],
      },
      timeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'h:i K',
      },
      fields: ['job_id', 'title', 'client', 'state', 'city', { key: 'received_date', label: 'date' }, { key: 'received_time', label: 'time' }, { key: 'actions', class: 'thMwid135' }],
      jobsResponse: {
        jobs: [],
        count: 1,
      },
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.inner_page
      },
      set(val) {
        this.$store.state.query.inner_page = val
        Jobs.getUrgentJobs(this)
      },
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.mail_received_date = moment().tz('America/Chicago').format('MM-DD-YYYY')
    this.makeUrgent.time = moment().tz('America/Chicago').format('hh:mm a')
    this.currentPage = 1
  },
  methods: {
    addJobId() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          if (this.job_id === '') {
            return
          }
          if (!this.makeUrgent.job_ids.includes(this.job_id)) {
            this.makeUrgent.job_ids.push(this.job_id)
          }
          this.job_id = ''
          this.$refs.simpleRules.reset()
        }
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          if (this.job_id !== '') {
            this.makeUrgent.job_ids.push(this.job_id)
          }
          if (this.makeUrgent.job_ids.length === 0) {
            return
          }
          this.job_id = ''
          this.$refs.simpleRules.reset()
          if (this.mail_received_date !== null) {
            this.makeUrgent.mail_received_date = Constants.getEprmMonthDateFromString(this.mail_received_date)
          }
          Jobs.makeJobsUrgent(this, this.makeUrgent)
        }
      })
    },
    makeNormal(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to close as urgent this ${obj.job_id}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, make it normal!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          Jobs.makeJobsNormal(this, obj.id)
        }
      })
    },
    updateSearchQuery() {
      this.currentPage = 1
    },
    jobsUpdated(data) {
      this.$refs.showLoading = false
      this.$store.state.top_counts.urgent_jobs = data.urgent_jobs
      this.makeUrgent.job_ids = []
      this.mail_received_date = moment().tz('America/Chicago').format('MM-DD-YYYY')
      this.makeUrgent.time = moment().tz('America/Chicago').format('hh:mm a')
      Jobs.getUrgentJobs(this)
      this.$swal({
        title: 'Success!',
        html: `${data.return_message}<br><br>${Constants.getFailedJobIdsMessage(data.not_existing_job_ids)}`,
        icon: 'success',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-outline-primary',
          cancelButton: 'btn btn-success ml-1',
        },
        buttonsStyling: false,
      })
    },
    jobUpdated(data) {
      this.$refs.showLoading = false
      this.$store.state.top_counts.urgent_jobs = data.urgent_jobs
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-outline-primary',
          cancelButton: 'btn btn-success ml-1',
        },
        buttonsStyling: false,
      })
    },
    jobsNotUpdated(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    setJobs(jobsResponse) {
      this.jobsResponse = jobsResponse
    },
    removeJobId(index) {
      this.makeUrgent.job_ids.splice(index, 1)
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>
