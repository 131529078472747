<template>
  <b-card
    class="mb-0"
  >
    <div class="mb-2">
      <b-row>
        <b-col
          cols="12"
          md="3"
        >
          <h4 class="card-title mb-0 pt-50">
            Reconciliation History</h4>
        </b-col>
        <b-col
          cols="12"
          md="9 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                @keyup.enter="updateSearchQuery"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="updateSearchQuery"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="14"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button
              variant="primary"
              class="ml-1 min-w-120"
              @click="sync()"
            >
              Sync Now
            </b-button>
            <b-button
              variant="primary"
              class="ml-1 min-w-110"
              :to="{ name: 'finance-and-accounting-bank-reconcile-history-reconcile-an-account', params: { id: $route.params.id }, query: {account_number: $route.query.account_number, bank_name: $route.query.bank_name}}"
            >
              Reconcile
            </b-button>
            <b-button
              variant="outline-primary"
              class="ml-1"
              @click="back()"
            >
              Back
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-row>
      <b-col cols="12">
        <vue-good-table
          :columns="columns"
          :rows="reconcileResponse.reconciles"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >

          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Currency -->
            <span v-if="props.column.field === 'bankBeginBalanceCurrency'">
              <span>{{ props.row.bank_begin_balance| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
            </span>

            <!-- Column: Currency -->
            <span v-if="props.column.field === 'bankEndBalanceCurrency'">
              <span>{{ props.row.bank_end_balance| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
            </span>

            <!-- Column: Currency -->
            <span v-if="props.column.field === 'nonReconcileCreditsCurrency'">
              <span>{{ props.row.non_reconcile_credits| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
            </span>

            <!-- Column: Currency -->
            <span v-if="props.column.field === 'nonReconcileDebitsCurrency'">
              <span>{{ props.row.non_reconcile_debits| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
            </span>

            <!-- Column: Currency -->
            <span v-if="props.column.field === 'unsettledCreditsCurrency'">
              <span>{{ props.row.unsettled_credits| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
            </span>

            <!-- Column: Currency -->
            <span v-if="props.column.field === 'unsettledDebitsCurrency'">
              <span>{{ props.row.unsettled_debits| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
            </span>

            <!-- Column: Details -->
            <span v-if="props.column.field === 'CellRendererReportButton'">
              <b-button
                variant="outline-primary"
                type="button"
                class="py-50 px-1"
                :to="{ name: 'finance-and-accounting-bank-reconcile-history-recon-report', params: { id: props.row.id } }"
              >
                Report
              </b-button>
            </span>

            <!-- Column: Action -->
            <span
              v-if="props.column.field === 'action'"
              class="btn-action"
            >
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item :to="{ name: 'finance-and-accounting-bank-reconcile-history-edit', params: { id: props.row.id } }">
                    <feather-icon icon="Edit2Icon" />
                    <span class="ml-50">Edit</span>
                  </b-dropdown-item>
                </b-dropdown>
                <b-link
                  id="view"
                  :to="{ name: 'finance-and-accounting-bank-reconcile-history-view', params: { id: props.row.id } }"
                >
                  <b-badge
                    pill
                    variant="light-primary"
                  ><feather-icon icon="EyeIcon" />
                  </b-badge>
                </b-link>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
          >
            <div class="d-flex justify-content-end flex-wrap">
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="reconcileResponse.count"
                  first-number
                  last-number
                  align="right"
                  :per-page="1"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BDropdown, BDropdownItem, BButton, BLink, BBadge,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import BankTransactions from '@/js/bankTransactions'
import Reconcile from '@/js/reconcile'

export default {
  name: 'ReconciliationHistory',
  components: {
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      searchQuery: '',
      pageLength: 10,
      dir: false,
      reconcileResponse: {
        reconciles: [],
        count: 1,
      },
      columns: [
        {
          label: '#',
          field: 'index',
          width: '100px',
        },
        {
          label: 'Recon Date',
          field: 'reconcile_date',
          sortable: false,
        },
        {
          label: 'Begining Bank Balance',
          field: 'bankBeginBalanceCurrency',
          sortable: false,
        },
        {
          label: 'Ending Bank Balance',
          field: 'bankEndBalanceCurrency',
          sortable: false,
        },
        {
          label: 'None - Reconciled Credits',
          field: 'nonReconcileCreditsCurrency',
          sortable: false,
        },
        {
          label: 'None - Reconciled Debits',
          field: 'nonReconcileDebitsCurrency',
          sortable: false,
        },
        {
          label: 'Unsettled Trans Credits',
          field: 'unsettledCreditsCurrency',
          sortable: false,
        },
        {
          label: 'Unsettled Trans Debits',
          field: 'unsettledDebitsCurrency',
          sortable: false,
        },
        {
          label: 'Report',
          field: 'CellRendererReportButton',
          width: '120px',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          width: '120px',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
        this.onGridReady()
      },
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.currentPage = 1
  },
  methods: {
    setReconcileHistories(reconcileResponse) {
      this.reconcileResponse = reconcileResponse
    },
    resetColFilters() {
      // Reset Grid Filter
      this.gridApi.setFilterModel(null)
      this.gridApi.onFilterChanged()

      this.$refs.filterCard.removeRefreshAnimation()
    },
    monthChanged() {
      this.currentPage = 1
    },
    updateSearchQuery() {
      this.currentPage = 1
    },
    onGridReady() {
      Reconcile.getReconcileHistoriesBybankId(this, this.$route.params.id)
    },
    sync() {
      this.$vs.loading()
      BankTransactions.getTransactions(this, this.$route.params.id)
    },
    setTrnsactions(data) {
      this.$vs.loading.close()
      this.$vs.notify({
        color: 'success',
        title: 'Transactions Synced',
        text: data.return_message,
      })
    },
    setNotTrnsactions(message) {
      this.$vs.loading.close()
      this.$vs.notify({
        color: 'warning',
        title: 'Transactions not Synced',
        text: message,
      })
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
