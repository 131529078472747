import axios from 'axios'

const company = 'company'

export default {
  getCompanies(context1) {
    const context = context1
    let params = { query: context.searchQuery, page: context.currentPage - 1 }
    if (params !== {}) {
      params = { params: { query: context.searchQuery, page: context.currentPage - 1 } }
    } else {
      params = { params: {} }
    }
    axios.get(company, params).then(response => {
      context.setCompanies(response.data)
    }, () => {
    })
  },
  addCompany(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(company, body).then(response => {
      context.$vs.loading.close()
      context.companyAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.companyNotAdded(error.response.data.return_message)
    })
  },
  updateCompany(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${company}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.companyUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.companyNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${company}/${id}`).then(response => {
      context.$vs.loading.close()
      context.companyDeleted(response.data)
    }, error => {
      context.$vs.loading.close()
      context.companyNotDeleted(error.response.data.return_message)
    })
  },
  getCompanyById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${company}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setCompany(response.data)
    }, error => {
      context.$vs.loading.close()
      context.companyNotFound(error.response.data.return_message)
    })
  },
  getCompanyAccessTokenById(context1, id) {
    const context = context1
    context.$vs.loading.close()
    axios.get(`${company}/${id}/access-token`).then(response => {
      context.$vs.loading.close()
      context.setAccessToken(response.data)
    }, error => {
      context.$vs.loading.close()
      context.companyNotFound(error.response.data.return_message)
    })
  },

}
