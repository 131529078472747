<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Assign Jobs Criteria</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <b-row class="mt-1">
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Role</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Role"
                    rules="required"
                  >
                    <v-select
                      id="role_id"
                      v-model="addAssignJob.role_id"
                      :options="roles"
                      :reduce="item => item.id"
                      :clearable="false"
                      @input="onRoleChanged()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>User</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="User"
                  >
                    <v-select
                      id="user_id"
                      v-model="addAssignJob.user_id"
                      :options="roleUsers"
                      :reduce="item => item.id"
                      :clearable="false"
                      @input="onUserSelected()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Criteria</h6>
            </div>

            <div class="repeat-border">
              <div
                v-for="(jobsCriteria, index) in job_criterias"
                :key="index"
                class="d-flex"
              >
                <div class="left w-100">
                  <b-row>
                    <b-col
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <b-form-group>
                        <label>Facility</label>
                        <v-select
                          v-model="jobsCriteria.facility_id"
                          :options="facilities"
                          :reduce="item => item.id"
                          :clearable="true"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <b-form-group>
                        <label>Main Skill</label>
                        <v-select
                          v-model="jobsCriteria.skill_id"
                          :options="skills"
                          :reduce="item => item.id"
                          :clearable="true"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <b-form-group>
                        <label>State</label>
                        <v-select
                          v-model="jobsCriteria.state_id"
                          :options="states"
                          :reduce="item => item.id"
                          :clearable="true"
                          @input="stateSelected(index)"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <b-form-group>
                        <label>City</label>
                        <v-select
                          v-model="jobsCriteria.city_id"
                          :options="jobsCriteria.cities"
                          :reduce="item => item.id"
                          :clearable="true"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
                <div class="right mt-2 ml-1">
                  <b-button
                    v-show="index === 0"
                    variant="outline-primary"
                    class="btn-icon rounded-circle"
                    @click="addNewJobCriteria"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                  <b-button
                    v-show="index !== 0"
                    variant="outline-danger"
                    class="btn-icon rounded-circle"
                    @click="removeNewJobCriteria(index)"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </div>
              </div>
            </div>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="ml-2">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Add Assign Job
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BButton,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import {
  required,
} from '@validations'
import LIST from '@/js/lists'
import AssignJobCriteria from '@/js/assignjobcriteria'

export default {
  name: 'AssignJobsNew',
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BButton,
    vSelect,
  },
  directives: {
  },
  setup() {
  },
  data() {
    return {
      required,
      addAssignJob: {
        role_id: null,
        user_id: null,
        job_criterias: [],
      },
      roles: [],
      roleUsers: [],
      job_criterias: [],
      facilities: [],
      skills: [],
      states: [],
      citis: [],
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getRolesFullList(this)
      },
    },
  },
  mounted() {
  },
  created() {
    LIST.getRolesFullList(this)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          this.showLoading = true
          this.addAssignJob.job_criterias = this.job_criterias
          AssignJobCriteria.addAssignJobCriteria(this, this.addAssignJob)
        }
      })
    },
    onRoleChanged() {
      this.roleUsers = []
      this.jobs_criteria = []
      this.addAssignJob.user_id = null
      LIST.getUsersByRole(this, this.addAssignJob.role_id)
    },
    onUserSelected() {
      if (this.job_criterias.length === 0) this.addNewJobCriteria()
      LIST.getSkillsByUser(this, this.addAssignJob.user_id)
      LIST.getFacilitiesByUser(this, this.addAssignJob.user_id)
      LIST.getStatesByUser(this, this.addAssignJob.user_id)
    },
    stateSelected(index) {
      this.job_criterias[index].city_id = null
      this.job_criterias[index].cities = []
      if (this.job_criterias[index].state_id !== null) {
        LIST.getCitiesByUserAndState(this, this.addAssignJob.user_id, this.job_criterias[index].state_id, index)
      }
    },
    setRoleUsers(roleUsers) {
      this.roleUsers = roleUsers
    },
    setRoles(roles) {
      this.roles = roles
    },
    setStates(states) {
      this.states = states
    },
    setCities(cities, index) {
      this.job_criterias[index].cities = cities
    },
    setSkills(skills) {
      this.skills = skills
    },
    setFacilities(facilities) {
      this.facilities = facilities
    },
    addNewJobCriteria() {
      const jobsCriteria = {
        facility_id: null,
        skill_id: null,
        state_id: null,
        city_id: null,
        cities: [],
      }
      this.job_criterias.push(jobsCriteria)
    },
    removeNewJobCriteria(index) {
      this.job_criterias.splice(index, 1)
    },
    assignJobCriteriaAdded(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    assignJobCriteriaNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    back() {
      this.$router.push('/administration/assign-jobs').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
