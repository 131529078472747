<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          View Document Tracker</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>

    <app-timeline>
      <app-timeline-item class="pb-1">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Parent Details</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Parent Type
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="document.parent_type"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Parent
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="document.parent"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="info"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Document Details</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Document Type
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="document.document_type"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Document Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="document.document_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Issuer
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="document.issuer"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                No Expire
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="document.no_expire"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            v-show="!document.no_expire"
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Exp Date
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="document.exp_date"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            v-show="!document.no_expire"
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Reminder
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="document.reminder"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Version
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="document.version"
                />
              </dd>
            </dl>
          </b-col>
          <b-col cols="12">
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Document
              </dt>
              <dd class="col-sm-12">
                <b-link
                  :href="document.document_path"
                  :title="document.file_name"
                  target="_blank"
                >
                  <b-badge
                    variant="primary"
                    class="mr-1 mb-50"
                  >
                    <span>{{ document.file_name }}</span>
                  </b-badge>
                </b-link>
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="success"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Last Sent Dates</h6>
        </div>
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-badge
              v-for="(last_sent_date, index) in document.last_sent_dates"
              :key="index"
              variant="primary"
              class="mr-1 mb-50"
            >
              <span
                v-text="last_sent_date"
              />
            </b-badge>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="success"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Previous Versions</h6>
        </div>
        <b-row class="mt-1">
          <b-col cols="12">
            <div class="table-responsive">
              <table class="table b-table table-th-pd table-td-pd">
                <thead>
                  <tr>
                    <th>Version</th>
                    <th class="th-w-155">Upload Date</th>
                    <th class="th-w-140">Exp Date</th>
                    <th>Uploaded By</th>
                    <th>Issuer</th>
                    <th>Reminder</th>
                    <th>Document</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(priviousVersion, index) in document.previous_versions"
                    :key="index"
                  >
                    <td>{{ priviousVersion.version }}</td>
                    <td>{{ priviousVersion.uploaded_date }}</td>
                    <td>{{ priviousVersion.exp_date }}</td>
                    <td>{{ priviousVersion.uploaded_by }}</td>
                    <td>{{ priviousVersion.issuer }}</td>
                    <td>{{ priviousVersion.reminder }}</td>
                    <td>
                      <b-link
                        target="_blank"
                        :href="priviousVersion.document_path"
                      >
                        <b-badge
                          variant="primary"
                          class="mr-1 mb-50"
                        >
                          <span>{{ priviousVersion.document_name }}</span>
                        </b-badge>
                      </b-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="success"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Notes</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Notes
              </dt>
              <dd class="col-sm-12">
                <b-form-textarea
                  v-model="document.notes"
                  rows="2"
                  disabled
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BBadge, BFormTextarea, BLink,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ParentDocumentType from '@/js/parentDocument'

export default {
  name: 'DocumentTrackerView',
  components: {
    BCard,
    BBadge,
    BLink,
    BFormTextarea,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BButton,
  },
  directives: {
  },
  setup() {
  },
  data() {
    return {
      document: {
        no_expire: false,
      },
    }
  },
  created() {
    ParentDocumentType.getDocumentById(this, this.$route.params.id)
  },
  methods: {
    setDocument(document) {
      this.document = document
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
</style>
