import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.url
const employerLetter = `${HOST}employer-letter`

export default {
  getEmployerLetterById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${employerLetter}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setEmployeeDetails(response.data)
    }, error => {
      context.$vs.loading.close()
      context.employeeNotFound(error.response.data.return_message)
    })
  },
  sendEmployerLetterForSign(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${employerLetter}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.sentSuccess(response.data)
    }, error => {
      context.$vs.loading.close()
      context.sentFailure(error.response.data.return_message)
    })
  },
  getEmployerLetterPdfById(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${employerLetter}/${id}/pdf`, body).then(response => {
      context.$vs.loading.close()
      context.pdfPath(response.data)
    }, error => {
      context.$vs.loading.close()
      context.pdfNotFound(error.response.data.return_message)
    })
  },
  getEmployerLetterTemplateById(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${employerLetter}/${id}/update-content`, body).then(response => {
      context.$vs.loading.close()
      context.employerLetterTemplate(response.data)
    }, error => {
      context.$vs.loading.close()
      context.pdfNotFound(error.response.data.return_message)
    })
  },
  sendEmployerLetterUpdatedContentForSign(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${employerLetter}/${id}/sign`, body).then(response => {
      context.$vs.loading.close()
      context.sentSuccess(response.data)
    }, error => {
      context.$vs.loading.close()
      context.sentFailure(error.response.data.return_message)
    })
  },
  getEmployerLetterPdfByIdAndUpdatedContent(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${employerLetter}/${id}/update-content/pdf`, body).then(response => {
      context.$vs.loading.close()
      context.pdfPath(response.data)
    }, error => {
      context.$vs.loading.close()
      context.pdfNotFound(error.response.data.return_message)
    })
  },
}
