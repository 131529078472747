import axios from 'axios'
import Lists from '@/js/lists'
import Constants from '@/js/constant'

const HOST = Constants.hosts.adurl
const role = `${HOST}role`

export default {
  getRoles(context1) {
    const context = context1
    const params = { params: { query: context.searchQuery, page: context.currentPage - 1 } }
    axios.get(role, params).then(response => {
      context.setRoles(response.data)
    }, () => {
    })
  },
  addRole(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(role, body).then(response => {
      context.$vs.loading.close()
      context.roleAdded(response.data)
      Lists.getAllList(context)
    }, error => {
      context.$vs.loading.close()
      context.roleNotAdded(error.response.data.return_message)
    })
  },
  updateRole(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${role}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.roleUpdated(response.data)
      Lists.getAllList(context)
    }, error => {
      context.$vs.loading.close()
      context.roleNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${role}/${id}`).then(response => {
      context.$vs.loading.close()
      context.roleDeleted(response.data.return_message)
      Lists.getAllList(context)
    }, error => {
      context.$vs.loading.close()
      context.roleNotDeleted(error.response.data.return_message)
    })
  },
  getRoleById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${role}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setRole(response.data)
    }, error => {
      context.$vs.loading.close()
      context.roleNotFound(error.response.data.return_message)
    })
  },

}
