import axios from 'axios'
import Constants from '@/js/constant'
import Lists from '@/js/lists'

const HOST = Constants.hosts.adurl
const userDefinedField = `${HOST}user_defined_field`

export default {
  getUdfs(context1) {
    const context = context1
    let params = { query: context.searchQuery, page: context.currentPage - 1 }
    if (params !== {}) {
      params = { params: { query: context.searchQuery, page: context.currentPage - 1 } }
    } else {
      params = { params: {} }
    }
    axios.get(userDefinedField, params).then(response => {
      context.setUdfs(response.data)
    }, () => {
    })
  },
  addUdf(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(userDefinedField, body).then(response => {
      context.$vs.loading.close()
      context.udfAdded(response.data)
      Lists.getAllList(context)
    }, error => {
      context.$vs.loading.close()
      context.udfNotAdded(error.response.data.return_message)
    })
  },
  updateUdfById(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${userDefinedField}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.udfUpdated(response.data)
      Lists.getAllList(context)
    }, error => {
      context.$vs.loading.close()
      context.udfNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${userDefinedField}/${id}`).then(response => {
      context.$vs.loading.close()
      context.udfDeleted(response.data.return_message)
      Lists.getAllList(context)
    }, error => {
      context.$vs.loading.close()
      context.udfNotDeleted(error.response.data.return_message)
    })
  },
  getUdfById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${userDefinedField}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setUdfEdif(response.data)
    }, error => {
      context.$vs.loading.close()
      context.udfNotFound(error.response.data.return_message)
    })
  },
  getUdfViewById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${userDefinedField}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setUdfView(response.data)
    }, error => {
      context.$vs.loading.close()
      context.udfNotFound(error.response.data.return_message)
    })
  },
}
