<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Edit Daily Update</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Update Details</h6>
            </div>
            <b-row class="mt-1">
              <b-col cols="12">
                <b-form-group>
                  <label>Description</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Description"
                    rules="required"
                  >
                    <quill-editor
                      v-model="addDailyUpdate.description"
                      :options="snowOption"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                class="col-xxl-4"
              >
                <b-form-group>
                  <label>Attachment</label>
                  <div class="d-flex">
                    <input
                      ref="myFileInput"
                      type="file"
                      placeholder="Choose a file or drop it here..."
                      class="form-control text-left"
                      @change="attahamentFileSelected"
                    >
                    <b-button
                      variant="outline-primary"
                      class="ml-1 px-1"
                      @click="addFile"
                    >
                      Upload
                    </b-button>
                  </div>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="col-xxl-8"
              >
                <b-button-group
                  v-for="(option, index) in addDailyUpdate.documents"
                  :key="option.id"
                  :value="option.id"
                  class="mt-xxl-2 mr-1 mb-50"
                >
                  <b-badge
                    :href="option.document_location"
                    :title="option.file_name"
                    target="option.document_location"
                    variant="light-primary"
                    class="rounded-right-0 line-height-inherit"
                  >
                    <span>{{ option.file_name }}</span>
                  </b-badge>
                  <b-button
                    variant="danger"
                    class="px-50 py-25"
                    @click="removeFile(option, index)"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Comments</h6>
            </div>
            <b-row>
              <b-col
                v-for="(comment, index) in addDailyUpdate.comments"
                :key="index"
                cols="12"
                md="6"
                class="col-xxl-4"
              >
                <div class="d-flex">
                  <div class="left w-100">
                    <b-form-group>
                      <label>Comment</label>
                      <b-form-textarea
                        v-model="comment.comment"
                        rows="2"
                      />
                    </b-form-group>
                  </div>
                  <div class="right mt-2 ml-1">
                    <b-button
                      v-show="index === 0"
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      @click="addComment"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                    <b-button
                      v-show="index !== 0"
                      variant="outline-danger"
                      class="btn-icon rounded-circle"
                      @click="removeComment(index)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="mt-1">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Update Daily Update
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormTextarea, BButton, BButtonGroup, BBadge,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import DailyUpdate from '@/js/dailyupdate'
import moment from 'moment'
import Constants from '@/js/constant'

export default {
  name: 'DailyUpdateEdit',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormTextarea,
    BButton,
    BButtonGroup,
    BBadge,
    quillEditor,
    // currency,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      selectedFile: null,
      addDailyUpdate: {
        description: '',
        comments: [],
        documents: [],
      },
    }
  },
  created() {
    DailyUpdate.getDailyUpdateById(this, this.$route.params.id)
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          if (this.selectedFile !== null) {
            this.addDailyUpdate.documents.push({
              attachment_link: this.url, document: this.selectedFile, file_name: this.documentName, name: this.documentName,
            })
            this.selectedFile = null
            this.url = null
            this.documentNameId = null
            this.$refs.myFileInput.value = ''
          }
          this.showLoading = true
          DailyUpdate.updateDailyUpdate(this, this.$route.params.id, this.addDailyUpdate)
        }
      })
    },
    attahamentFileSelected(obj) {
      const dis = this
      const fileReader = new FileReader()
      fileReader.onload = fileLoadEvent => {
        dis.selectedFile = fileLoadEvent.target.result
      }
      fileReader.readAsDataURL(obj.target.files[0])
      this.url = URL.createObjectURL(obj.target.files[0])
      this.documentName = obj.target.files[0].name
    },
    removeFile(file, position) {
      const dis = this
      dis.addDailyUpdate.documents.splice(position, 1)
    },
    addFile() {
      if (this.selectedFile === null) {
        this.$swal({
          title: 'Error!',
          text: ' Please choose file',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.selectedFile !== null) {
        this.addDailyUpdate.documents.push({
          attachment_link: this.url, document: this.selectedFile, file_name: this.documentName, name: this.documentName,
        })
        this.selectedFile = null
        this.url = null
        this.documentNameId = null
        this.$refs.myFileInput.value = ''
      }
    },
    dailyUpdateUpdated(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    dailyUpdateNotUpdated(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    setDailyUpdate(dailyUpdate) {
      this.addDailyUpdate = dailyUpdate
      if (this.addDailyUpdate.comments.length === 0) {
        this.addComment()
      }
    },
    addComment() {
      const comment = {
        comment: '',
        date: Constants.getEprmMonthDateFromString(moment()),
        time: moment().format('hh:mm a'),
      }
      this.addDailyUpdate.comments.push(comment)
    },
    removeComment(index) {
      this.addDailyUpdate.comments.splice(index, 1)
    },
    back() {
      this.$router.push('/employee/daily-updates').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
</style>
