<template>
  <b-card
    class="mb-0"
  >
    <div class="mb-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <h4 class="card-title w-50 mb-0 mr-md-1 pt-50">
              Bank Transactions</h4>
            <b-form-group class="w-50 mb-md-0">
              <v-select
                id="status_id"
                v-model="status_id"
                :options="status"
                :reduce="item => item.id"
                :clearable="true"
                placeholder="Select Status"
                @input="updateStatus"
              />
            </b-form-group>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="6 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group>
              <b-form-input
                v-model="searchQuery"
                type="search"
                placeholder="Search"
                @keyup.enter="updateSearchQuery"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="updateSearchQuery"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="14"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button
              v-show="bank_transaction_ids.length !== 0"
              variant="primary"
              class="ml-1 px-1"
              @click="mergeTransactions()"
            >
              Merge
            </b-button>
            <b-button
              v-show="bank_transaction_ids.length !== 0"
              variant="primary"
              class="ml-1 px-1 btn-minw-96"
              @click="showAllQboPopup()"
            >
              All QBO
            </b-button>
            <b-modal
              ref="allQBOCategory"
              modal-class="modal-primary"
              centered
              size="lg"
              title="Assign QBO Category and Reconcile"
              ok-only
              ok-title="Accept"
              :no-close-on-backdrop="true"
            >
              <vue-good-table
                :columns="columns2"
                :rows="bankTransaction"
                :rtl="direction"
                :pagination-options="{
                  enabled: false,
                }"
              >
                <template
                  slot="table-row"
                  slot-scope="props"
                >

                  <!-- Column: Currency -->
                  <span v-if="props.column.field === 'Currency'">
                    <span>{{ props.row.amount| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
                  </span>

                  <!-- Column: Common -->
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </vue-good-table>
              <b-form>
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="QBO Category">
                      <v-select
                        v-model="allQboCategoryId"
                        :reduce="item => item.id"
                        :options="qboCategories"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
              <template #modal-footer>
                <div class="d-flex justify-content-between w-100">
                  <b-alert
                    variant="primary"
                    show
                    class="mb-0"
                  >
                    <div class="alert-body">
                      <span>By assigning the QBO category id automatically reconcile the transaction.</span>
                    </div>
                  </b-alert>
                  <b-button
                    variant="primary"
                    class="ml-1 px-1"
                    @click="showAllQboPopupConfirm()"
                  >
                    Save
                  </b-button>
                </div>
              </template>
            </b-modal>
            <b-button
              variant="outline-secondary"
              class="ml-1"
              @click="back"
            >
              Back
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="8">
          <div class="d-flex">
            <b-form-group
              label="Start Date"
              label-class="pb-0"
              class="w-50 mr-md-1"
            >
              <b-input-group>
                <flat-pickr
                  id="start_date"
                  v-model="start_date"
                  class="form-control"
                  :config="$store.state.config"
                  placeholder="MM-DD-YYYY"
                  :clear-button="true"
                  @on-change="dateChanged"
                />
                <b-input-group-append>
                  <b-button
                    class="px-1 outline-grey"
                    variant="outline-secondary"
                    @click="removeMonthDate('start')"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-form-group
              label="End Date"
              label-class="pb-0"
              class="w-50 mr-md-1"
            >
              <b-input-group>
                <flat-pickr
                  id="end_date"
                  v-model="end_date"
                  class="form-control"
                  :config="$store.state.config"
                  placeholder="MM-DD-YYYY"
                  :clear-button="true"
                  @on-change="dateChanged"
                />
                <b-input-group-append>
                  <b-button
                    class="px-1 outline-grey"
                    variant="outline-secondary"
                    @click="removeMonthDate('end')"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-form-group
              label="Cr/Dr"
              label-class="pb-0"
              class="w-50"
            >
              <v-select
                id="cr_dr"
                v-model="cr_dr"
                :options="creditDebit"
                :reduce="item => item.id"
                :clearable="true"
                placeholder="Select"
                @input="updateCrDr"
              />
            </b-form-group>
          </div>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="QBO Category"
            label-class="pb-0"
          >
            <v-select
              id="qbo_category"
              v-model="all_qbo_category_id"
              :options="qboCategories"
              :reduce="item => item.id"
              :clearable="true"
              placeholder="Select"
              @input="updateQboCategory"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <b-row v-if="selectedTransaction !== null">
      <b-modal
        ref="singleQbo"
        modal-class="modal-primary"
        centered
        size="lg"
        title="Assign QBO Category and Reconcile"
        ok-only
        ok-title="Accept"
        :no-close-on-backdrop="true"
      >
        <b-form>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Transaction Amount">
                <span class="form-control">{{ selectedTransaction.amount| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Transaction Date">
                <span
                  class="form-control"
                  v-text="selectedTransaction.date"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Transaction Name">
                <span
                  class="form-control height-auto min-h-38"
                  v-text="selectedTransaction.name"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="CR/DB">
                <span
                  class="form-control"
                  v-text="selectedTransaction.type"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="QBO Category">
                <v-select
                  v-model="qbo_category_id"
                  :options="qboCategories"
                  :reduce="item => item.id"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-alert
            variant="primary"
            show
            class="mb-0"
          >
            <div class="alert-body">
              <span>By assigning the QBO category id automatically reconcile the transaction.</span>
            </div>
          </b-alert>
        </b-form>
        <template #modal-footer>
          <div class="d-flex justify-content-between w-100">
            <div />
            <b-button
              variant="primary"
              class="ml-1 px-1"
              @click="openConfirm()"
            >
              Save
            </b-button>
          </div>
        </template>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal
        ref="split_transaction"
        modal-class="modal-primary"
        centered
        size="lg"
        title="Split Transaction"
        ok-only
        ok-title="Accept"
        :no-close-on-backdrop="true"
      >
        <b-form>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Split Amount">
                <money
                  v-model="splitAmount"
                  class="vs-inputx vs-input--input normal hasIcon"
                  :min="0"
                  :step="0.5"
                  name="split amount"
                  v-bind="money"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <div class="demo-inline-spacing">
                <b-form-checkbox
                  v-model="credit"
                  @input="creditDebitChanged('Credit')"
                >
                  Credit
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="debit"
                  @input="creditDebitChanged('debit')"
                >
                  Debit
                </b-form-checkbox>
              </div>
            </b-col>
            <b-col cols="2">
              <b-button
                variant="primary"
                class="mt-1 ml-1 px-1 btn-minw-96"
                @click="split"
              >
                Add Split
              </b-button>
            </b-col>
          </b-row>
          <vue-good-table
            :columns="columns3"
            :rows="transactions"
            :rtl="direction"
            :pagination-options="{
              enabled: false,
            }"
          >

            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Currency -->
              <span v-if="props.column.field === 'Currency'">
                <span>{{ props.row.amount| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
              </span>
            </template>
          </vue-good-table>
        </b-form>
        <template #modal-footer>
          <div class="d-flex justify-content-end w-100">
            <div />
            <b-button
              variant="primary"
              class="ml-1 px-1"
              @click="splitTransactions"
            >
              Save
            </b-button>
          </div>
        </template>
      </b-modal>
    </b-row>
    <b-row>
      <b-col cols="12">
        <vue-good-table
          :columns="columns"
          :rows="transactionsResponse.bank_transactions"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >

          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Checkbox -->
            <span v-if="props.column.field === 'CellRendererCheckbox'">
              <b-form-checkbox
                v-model="props.row.selected"
                checked="props.row.selected"
                @change="checkChanged(props.row.id)"
              />
            </span>
            <!-- Column: Details -->
            <span v-if="props.column.field === 'cellRendererSplitButton'">
              <b-button
                v-if="!props.row.reconciled"
                variant="outline-primary"
                type="button"
                class="py-50 px-1"
                @click="showSplit(props.row)"
              >
                Split
              </b-button>
            </span>

            <!-- Column: Sync -->
            <span v-if="props.column.field === 'cellRendererQBOButton'">
              <b-button
                v-b-tooltip.hover.v-primary
                :variant="props.row.reconciled ? 'success' : 'outline-primary'"
                type="button"
                :title="props.row.reconciled ? props.row.qbo_category : ''"
                class="py-50 px-1"
                @click.prevent="showQbo( props.row)"
              >
                QBO
              </b-button>
            </span>

            <!-- Column: Currency -->
            <span v-if="props.column.field === 'Currency'">
              <span>{{ props.row.amount| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
            </span>

            <!-- Column: Action -->
            <span
              v-if="props.column.field === 'action'"
              class="btn-action"
            >
              <b-link
                id="view"
                :to="{ name: 'finance-and-accounting-bank-transactions-view', params: { bankId: $route.params.id, id: props.row.id } }"
              >
                <b-badge
                  pill
                  variant="light-primary"
                ><feather-icon icon="EyeIcon" />
                </b-badge>
              </b-link>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
          >
            <div class="d-flex justify-content-end flex-wrap">
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="transactionsResponse.count"
                  first-number
                  last-number
                  align="right"
                  :per-page="1"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BForm, BCol, BPagination, BFormGroup, BInputGroup, BFormInput, BInputGroupAppend, BFormCheckbox, BButton, BLink, BBadge, BAlert, BModal, VBModal, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import moment from 'moment'
import Constants from '@/js/constant'
import LIST from '@/js/lists'
import BankTransactions from '@/js/bankTransactions'

export default {
  name: 'BankTransctions',
  components: {
    BForm,
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    // ToastificationContent,
    BPagination,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormCheckbox,
    BButton,
    BLink,
    BBadge,
    BAlert,
    vSelect,
    flatPickr,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      debit: false,
      credit: false,
      splitAmount: 0.00,
      type: '',
      transactions: [],
      selectedTransaction: {},
      bank_transaction_ids: [],
      qboRefId: null,
      qboBankTransactionId: null,
      status_id: null,
      cr_dr: null,
      all_qbo_category_id: null,
      qbo_category_id: null,
      searchQuery: '',
      pageLength: 10,
      dir: false,
      disabledEndDates: {},
      disabledStartDates: {},
      transactionsResponse: {
        bank_transactions: [],
        count: 1,
      },
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      allQboCategoryId: null,
      status: [],
      creditDebit: [],
      qboCategories: [],
      start_date: null,
      end_date: moment().format('MM-DD-YYYY'),
      columns: [
        {
          label: '#',
          field: 'CellRendererCheckbox',
          width: '50px',
          sortable: false,
        },
        // {
        //   label: 'Transaction Id',
        //   field: 'transaction_id',
        // },
        {
          label: 'Transaction Date',
          field: 'date',
          sortable: false,
          width: '160px',
        },
        {
          label: 'Transaction Name',
          field: 'name',
          sortable: false,
          width: '400px',
        },
        {
          label: 'Transaction Amount',
          field: 'Currency',
          sortable: false,
          width: '200px',
        },
        {
          label: 'CR/DB',
          field: 'type',
          sortable: false,
          width: '120px',
        },
        // {
        //   label: 'Payment Method',
        //   field: 'payment_method',
        //   sortable: false,
        // },
        {
          label: 'Ref #',
          field: 'ref',
          sortable: false,
          width: '140px',
        },
        {
          label: 'Split',
          field: 'cellRendererSplitButton',
          sortable: false,
          width: '100px',
        },
        {
          label: 'QBO Category',
          field: 'cellRendererQBOButton',
          sortable: false,
          width: '100px',
        },
        {
          label: 'Action',
          field: 'action',
          width: '90px',
          sortable: false,
        },
      ],
      bankTransaction: [],
      columns2: [
        {
          label: '#',
          field: 'index',
          width: '100px',
        },
        {
          label: 'Transaction Date',
          field: 'date',
          sortable: false,
        },
        {
          label: 'Transaction Amount',
          field: 'Currency',
          sortable: false,
        },
        {
          label: 'Credit',
          field: 'type',
          sortable: false,
        },
      ],
      columns3: [
        {
          label: '#',
          field: 'index',
        },
        {
          label: 'Transaction Name',
          field: 'date',
          sortable: false,
        },
        {
          label: 'Transaction Date',
          field: 'date',
          sortable: false,
        },
        {
          label: 'Transaction Name',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Transaction Amount',
          field: 'Currency',
          sortable: false,
        },
        {
          label: 'Type',
          field: 'type',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
        this.onGridReady()
      },
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getQboCategories(this)
      },
    },
  },
  created() {
    if (this.$store.state.query.start_date !== null && this.$store.state.query.start_date !== undefined) {
      this.start_date = new Date(moment(this.$store.state.query.start_date))
    }
    if (this.$store.state.query.start_date === undefined && this.start_date === null) {
      this.start_date = new Date(moment().add(-1, 'M'))
    }
    if (this.$store.state.query.end_date !== null && this.$store.state.query.end_date !== undefined) {
      this.end_date = new Date(moment(this.$store.state.query.end_date))
    }
    if (this.$store.state.query.end_date === undefined && this.end_date === null) {
      this.end_date = new Date(moment())
    }
    this.status_id = this.$store.state.query.status_id
    this.cr_dr = this.$store.state.query.cr_dr
    this.searchQuery = this.$store.state.query.query
    this.company_id = this.$store.state.query.company_id
    this.all_qbo_category_id = this.$store.state.query.qbo_category_id
    LIST.getQboCategories(this)
    Constants.getBankTransactionTypes(this)
    Constants.getCreditDebitTypes(this)
    this.onGridReady()
  },
  methods: {
    close() {
      this.bankTransaction = []
      this.qbo_category_id = null
      this.qboCategory = false
      this.company_id = null
      this.pms_company_id = null
      this.group_id = null
      this.building_id = null
      this.property_id = null
    },
    showQbo(data) {
      this.selectedTransaction = data
      this.qboBankTransactionId = data.id
      this.qbo_category_id = data.qbo_category_id
      this.$refs.singleQbo.show()
    },
    showSplit(data) {
      this.splitSelectedData = data
      this.credit = this.splitSelectedData.type === 'Credit'
      this.debit = this.splitSelectedData.type === 'Debit'
      this.type = this.splitSelectedData.type
      this.transactions = []
      const transaction = {
        date: this.splitSelectedData.date,
        name: this.splitSelectedData.name,
        amount: this.splitSelectedData.amount,
        type: this.splitSelectedData.type,
      }
      this.transactions.push(transaction)
      this.$refs.split_transaction.show()
    },
    creditDebitChanged(type) {
      if (type === 'Credit') {
        this.debit = !this.credit
      } else {
        this.credit = !this.debit
      }
      if (this.credit) {
        this.type = 'Credit'
      } else {
        this.type = 'Debit'
      }
    },
    split() {
      if (this.transactions[0].amount > this.splitAmount || this.transactions[0].type !== this.type) {
        const transaction = {
          date: this.splitSelectedData.date,
          name: this.splitSelectedData.name,
          amount: this.splitAmount.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).replaceAll(',', ''),
          type: this.type,
        }
        this.transactions.push(transaction)
        if (this.splitSelectedData.type === this.type) {
          this.transactions[0].amount = parseFloat(parseFloat(this.transactions[0].amount) - parseFloat(this.splitAmount))
        } else {
          this.transactions[0].amount = parseFloat(parseFloat(this.transactions[0].amount) + parseFloat(this.splitAmount))
        }
        this.transactions[0].amount = this.transactions[0].amount.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).replaceAll(',', '')
        this.splitAmount = 0
        this.type = this.splitSelectedData.type
        if (this.splitSelectedData.type === 'Credit') {
          this.credit = true
          this.debit = false
        } else {
          this.credit = false
          this.debit = true
        }
      } else {
        this.$swal({
          title: 'Error!',
          text: 'Please enter amount less than total amount',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    },
    splitTransactions() {
      if (this.splitAmount !== 0) {
        this.split()
      }
      if (this.transactions.length > 1) {
        const amountsList = []
        this.transactions.forEach(transaction => {
          amountsList.push({ amount: transaction.amount, type: transaction.type })
        })
        const data = {
          amounts: amountsList,
        }
        BankTransactions.splitTransactionById(this, this.splitSelectedData.id, data)
      }
    },
    checkChanged(transactionId) {
      if (this.bank_transaction_ids.length !== 0) {
        if (this.bank_transaction_ids.includes(transactionId)) {
          this.bank_transaction_ids.splice(this.bank_transaction_ids.indexOf(transactionId), 1)
        } else {
          this.bank_transaction_ids.push(transactionId)
        }
      } else {
        this.bank_transaction_ids.push(transactionId)
      }
    },
    showAllQboPopup() {
      this.bankTransaction = []
      this.transactionsResponse.bank_transactions.forEach(transaction => {
        if (this.bank_transaction_ids.includes(transaction.id)) {
          this.bankTransaction.push(transaction)
        }
      })
      this.$refs.allQBOCategory.show()
    },
    openConfirm() {
      if (this.qbo_category_id === null) {
        this.$swal({
          title: 'Confirm',
          text: 'Do you really want to remove QBO category id?',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.removeAlert()
          }
        })
      } else {
        this.$swal({
          title: 'Confirm',
          text: 'Do you really want to update the QBO category id?',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.acceptAlert()
          }
        })
      }
    },
    removeAlert() {
      BankTransactions.removeQboIdFromBankTransaction(this, this.qboBankTransactionId)
    },
    acceptAlert() {
      const request = {
        bankTransaction_id: this.qboBankTransactionId,
      }
      BankTransactions.applyQboCategoryIdTobankTransaction(this, this.qboBankTransactionId, this.qbo_category_id, request)
    },
    showAllQboPopupConfirm() {
      if (this.allQboCategoryId === null) {
        this.$toast({
          title: 'Update Qbo',
          text: 'Please select qbo category',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.bank_transaction_ids.length === 0) {
        this.$swal({
          title: 'Update Qbo',
          text: 'Please select transactions',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.bank_transaction_ids.length > 0) {
        this.$swal({
          title: 'Confirm',
          text: 'Do you really want to update the QBO category id?',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.acceptAllQboAlert()
          }
        })
      }
    },
    back() {
      this.$router.go(-1)
    },
    acceptAllQboAlert() {
      const request = {
        bankTransaction_ids: this.bank_transaction_ids,
      }
      BankTransactions.applyQboCategoryIdToAllbankTransaction(this, this.allQboCategoryId, request)
    },
    setQboCategoryIdUpdatedSuccessfully(message) {
      this.bank_transaction_ids = []
      this.qboBankTransactionId = null
      this.selectedTransaction = null
      this.$refs.allQBOCategory.hide()
      this.$refs.singleQbo.hide()
      this.$vs.loading.close()
      this.onGridReady()
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Qbo',
          icon: 'InfoIcon',
          text: message,
          variant: 'success',
        },
      })
    },
    setQboCategoryIdUpdatedFailure(message) {
      this.close()
      this.$vs.loading.close()
      this.$vs.notify({
        color: 'warning',
        title: 'Qbo',
        text: message,
      })
    },
    setColumnFilter(column, val) {
      const filter = this.gridApi.getFilterInstance(column)
      let modelObj = null

      if (val !== 'all') {
        modelObj = { type: 'equals', filter: val }
      }

      filter.setModel(modelObj)
      this.gridApi.onFilterChanged()
    },
    mergeTransactions() {
      if (this.bank_transaction_ids.length < 2) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '!Merged',
            icon: 'InfoIcon',
            text: 'Please select atleast two transactions',
            variant: 'warning',
          },
        })
        return
      }
      let transactionId = ''
      this.transactionsResponse.bank_transactions.forEach(transaction => {
        if (this.bank_transaction_ids.includes(this.transactionsResponse.bank_transactions[transaction].id)) {
          if (transactionId === '') {
            transactionId = this.transactionsResponse.bank_transactions[transaction].transaction_id
          }
          if (transactionId !== this.transactionsResponse.bank_transactions[transaction].transaction_id) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: '!Merged',
                icon: 'InfoIcon',
                text: 'Please select splited transactions only',
                variant: 'warning',
              },
            })
          }
        }
      })
      const data = {
        bank_transaction_ids: this.bank_transaction_ids,
      }
      this.$vs.loading()
      BankTransactions.mergeTransactionById(this, data)
    },
    setBankTransactions(transactionsResponse) {
      this.bank_transaction_ids = []
      this.transactionsResponse = transactionsResponse
    },
    resetColFilters() {
      // Reset Grid Filter
      this.gridApi.setFilterModel(null)
      this.gridApi.onFilterChanged()
    },
    setQboCategories(qboCategories) {
      this.qboCategories = qboCategories
    },
    updateCrDr() {
      this.$store.commit('UPDATE_CRDR_ID', this.cr_dr)
      this.currentPage = 1
    },
    setPaymentStatus(status) {
      this.status = status
    },
    setCompanies(companies) {
      this.companies = companies
    },
    removeMonthDate(from) {
      if (from === 'start') {
        this.start_date = null
      } else {
        this.end_date = null
      }
      this.dateChanged()
    },
    dateChanged() {
      if (this.start_date !== null) {
        this.disabledEndDates.to = new Date(this.start_date)
      }
      if (this.end_date !== null) {
        this.disabledStartDates.from = new Date(this.end_date)
      }
      this.$store.commit('UPDATE_FROM_DATE', this.start_date)
      this.$store.commit('UPDATE_TO_DATE', this.end_date)
      this.currentPage = 1
      this.onGridReady()
    },
    setMergedSuccessfully(message) {
      this.onGridReady()
      this.$vs.loading.close()
      this.bank_transaction_ids = []
      this.$vs.notify({
        color: 'success',
        title: 'Merged',
        text: message,
      })
      this.onGridReady()
    },
    setMergedFailure(message) {
      this.$vs.loading.close()
      this.transactionId = ''
      this.$vs.notify({
        color: 'warning',
        title: '!Merged',
        text: message,
      })
    },
    setSplitedSuccessfully(message) {
      this.$refs.split_transaction.hide()
      this.splitSelectedData = null
      this.transactions = []
      this.$vs.loading.close()
      this.onGridReady()
      this.$vs.notify({
        color: 'success',
        title: 'Split',
        text: message,
      })
    },
    setSplitedFailure(message) {
      this.close()
      this.$vs.loading.close()
      this.$vs.notify({
        color: 'warning',
        title: 'Split',
        text: message,
      })
    },
    updateSearchQuery() {
      this.$store.commit('UPDATE_QUERY', this.searchQuery)
      this.currentPage = 1
    },
    updateStatus() {
      this.$store.commit('UPDATE_STATUS_ID', this.status_id)
      this.currentPage = 1
    },
    updateQboCategory() {
      this.$store.commit('UPDATE_QBO_CATEGORY', this.all_qbo_category_id)
      this.currentPage = 1
    },
    onGridReady() {
      if (this.firstLoad) {
        this.firstLoad = false
        if (this.$store.state.query.page !== undefined) {
          this.currentPage = this.$store.state.query.page
        }
      }
      this.bank_transaction_ids = []
      BankTransactions.getAllBankTransactionsByBankIdAndEndDate(this, this.$route.params.id)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
