<template>
  <b-card
    class="mb-0"
  >

    <div class="mb-2">
      <b-row>
        <b-col
          cols="12"
          md="3"
          class="mb-1"
        >
          <h4 class="card-title mb-0 pt-50">
            Required Documents</h4>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group>
            <v-select
              id="parent_type_id"
              v-model="parent_type_id"
              :options="parentTypes"
              :reduce="item => item.parent_type_id"
              :clearable="true"
              placeholder="Select Parent Type"
              @input="onTypeChanged"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                @keyup.enter="updateSearchQuery"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="updateSearchQuery"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="14"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button
              variant="primary"
              class="ml-2 px-50 min-w-210"
              :to="{ name: 'documents-required-documents-new'}"
            >
              Add Required Document
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <vue-good-table
      :columns="columns"
      :rows="requiredDocumentsResponse.parent_required_documents"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Action -->
        <span
          v-if="props.column.field === 'action'"
          class="btn-action"
        >
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none pl-50"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item :to="{ name: 'documents-required-documents-edit', params: { id: props.row.id } }">
                <feather-icon icon="Edit2Icon" />
                <span class="ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="confirmDeleteRecord(props.row)">
                <feather-icon icon="TrashIcon" />
                <span class="ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
            <b-link
              id="view"
              :to="{ name: 'documents-required-documents-view', params: { id: props.row.id } }"
            >
              <b-badge
                pill
                variant="light-primary"
              ><feather-icon icon="EyeIcon" />
              </b-badge>
            </b-link>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
      >
        <div class="d-flex justify-content-end flex-wrap">
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="requiredDocumentsResponse.count"
              first-number
              last-number
              align="right"
              :per-page="1"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BBadge, BPagination, BFormGroup, BInputGroup, BFormInput, BInputGroupAppend, BDropdown, BDropdownItem, BButton, BLink,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import store from '@/store/index'
import LIST from '@/js/lists'
import ParentDocumentrequired from '@/js/parentDocumentrequired'

export default {
  name: 'DocumentRequired',
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    VueGoodTable,
    BBadge,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BLink,
    BDropdown,
    BDropdownItem,
    vSelect,
  },
  data() {
    return {
      searchQuery: '',
      pageLength: 10,
      parent_type_id: null,
      parentTypes: [],
      requiredDocumentsResponse: {
        parent_required_documents: [],
        count: 1,
      },
      dir: false,
      columns: [
        {
          label: '#',
          field: 'index',
          sortable: false,
          width: '100px',
        },
        {
          label: 'Parent Type',
          field: 'parent_type',
          sortable: false,
          width: '200px',
        },
        // {
        //   label: 'Workforce',
        //   field: 'workforce',
        //   sortable: false,
        //   width: '200px',
        // },
        {
          label: 'Document Type',
          field: 'document_type',
          sortable: false,
          width: '300px',
        },
        {
          label: 'Required',
          field: 'required_document_type_names',
          sortable: false,
        },
        {
          label: 'Optional',
          field: 'optional_document_type_names',
          sortable: false,
        },
        {
          label: 'Optional Min',
          field: 'optional_document_count',
          sortable: false,
          width: '140px',
        },
        {
          label: 'Action',
          field: 'action',
          width: '140px',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Active',
        2: 'Inactive',
      },
      {
        1: 'light-success',
        2: 'light-danger',
      }],
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
        ParentDocumentrequired.getParentDocumentRequireds(this)
      },
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Active       : 'light-success',
        Inactive     : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getHRDocumentParentTypes(this)
      },
    },
  },
  created() {
    this.searchQuery = this.$store.state.query.query
    this.parent_type_id = this.$store.state.query.parent_type_id
    this.requiredDocumentsResponse.count = this.$store.state.query.page
    LIST.getHRDocumentParentTypes(this)
    ParentDocumentrequired.getParentDocumentRequireds(this)
  },
  methods: {
    confirmDeleteRecord(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${obj.parent_type} document`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          ParentDocumentrequired.delete(this, obj.id)
        }
      })
    },
    onTypeChanged() {
      this.$store.state.query.parent_id = null
      this.$store.state.query.parent_type_id = this.parent_type_id
      this.currentPage = 1
    },
    documentRequiredDeleted(data) {
      ParentDocumentrequired.getParentDocumentRequireds(this)
      this.$swal({
        icon: 'success',
        title: 'Required Document Deleted!',
        text: data.return_message,
      })
    },
    documentRequiredNotDeleted(message) {
      this.$swal({
        icon: 'warning',
        title: 'Required Document Not Deleted!',
        text: message,
      })
    },
    setPartners(parentTypes) {
      this.parentTypes = parentTypes
    },
    updateSearchQuery() {
      this.$store.state.query.query = this.searchQuery
      this.currentPage = 1
    },
    setRequiredDocuments(requiredDocumentsResponse) {
      this.requiredDocumentsResponse = requiredDocumentsResponse
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
