<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Add Employee Salary</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <b-row class="mt-1">
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Employee</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Employee"
                    rules="required"
                  >
                    <v-select
                      id="employee_id"
                      v-model="addSalary.employee_id"
                      :clearable="false"
                      placeholder="Select"
                      :options="employees"
                      :reduce="item => item.id"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  id="start_date"
                >
                  <label>Start Date</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Start Date"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="start_date"
                      class="form-control"
                      :config="start_date_config"
                      placeholder="MM-DD-YYYY"
                      @input="startDateChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  id="end_date"
                >
                  <label>End Date</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="End Date"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="end_date"
                      class="form-control"
                      :config="end_date_config"
                      placeholder="MM-DD-YYYY"
                      @input="endDateChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
                class="mt-50"
              >
                <b-form-checkbox
                  id="1099"
                  v-model="addSalary.is_1099"
                  :disabled="addSalary.is_india"
                  @input="checkChanged"
                >
                  1099
                </b-form-checkbox>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
                class="mt-50"
              >
                <b-form-checkbox
                  id="india"
                  v-model="addSalary.is_india"
                  :disabled="addSalary.is_1099"
                  @input="indiaCheckChanged"
                >
                  India
                </b-form-checkbox>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>PayRoll Details</h6>
            </div>
            <div
              v-for="(pay_roll, index) in addSalary.pay_rolls"
              :id="index"
              :key="index"
            >
              <h5
                class="mt-50"
              >{{ pay_roll.pay_roll_type }}</h5>
              <div class="mt-1 ml-2">
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>Amount</label>
                      <validation-provider
                        v-slot="{ errors }"
                        :name="'Amount' + index"
                        rules="required"
                      >
                        <b-input-group
                          prepend="$"
                        >
                          <money
                            id="Amount"
                            v-model="pay_roll.amount"
                            :min="0"
                            :step="0.5"
                            v-bind="money"
                            class="form-control"
                          />
                        </b-input-group>
                        <small
                          class="text-danger"
                          v-text="errors[0] !== undefined ? errors[0].replace('Amount' + index, 'Amount') : ''"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>Status</label>
                      <validation-provider
                        v-slot="{ errors }"
                        :name="'Status' + index"
                        rules="required"
                      >
                        <v-select
                          :id="'salary_status_id' + index"
                          v-model="pay_roll.status_id"
                          :clearable="false"
                          placeholder="Select"
                          :options="status"
                          :reduce="item => item.id"
                          @input="statusChanged"
                        />
                        <small
                          class="text-danger"
                          v-text="errors[0] !== undefined ? errors[0].replace('Status' + index, 'Status') : ''"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>Paid Date</label>
                      <validation-provider
                        v-slot="{ errors }"
                        :name="'Paid Date' + index"
                        :rules="pay_roll.is_required ? 'required' : ''"
                      >
                        <b-input-group>
                          <flat-pickr
                            v-model="pay_roll.salary_paid_date"
                            class="form-control"
                            :config="$store.state.notGraterThanToday"
                            placeholder="MM-DD-YYYY"
                            clearable="true"
                          />
                          <b-input-group-append>
                            <b-button
                              class="px-1 outline-grey"
                              variant="outline-secondary"
                              @click="removeSalaryDate(index)"
                            >
                              <feather-icon icon="XIcon" />
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                        <small
                          class="text-danger"
                          v-text="errors[0] !== undefined ? errors[0].replace('Paid Date' + index, 'Paid Date') : ''"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </div>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row>
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Add Salary
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BInputGroup, BButton, BFormCheckbox, BInputGroupAppend,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import moment from 'moment'
import LIST from '@/js/lists'
import Salaries from '@/js/employeesalaries'
import Constants from '@/js/constant'

export default {
  name: 'EmployeeSalaryNew',
  components: {
    BCard,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BInputGroup,
    BButton,
    flatPickr,
    vSelect,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      addSalary: {
        employee_id: null,
        start_date: null,
        end_date: null,
        is_1099: false,
        is_india: false,
        pay_rolls: [{
          pay_roll_type: '',
          amount: 0.00,
          status_id: null,
          is_required: false,
          salary_paid_date: null,
          paid_date: null,
        }],
      },
      payRollList: [],
      end_date: null,
      start_date: null,
      status: [],
      employees: [],
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      start_date_config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'm-d-Y',
        altInput: true,
        dateFormat: 'm-d-Y',
        minDate: null,
        maxDate: null,
      },
      end_date_config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'm-d-Y',
        altInput: true,
        dateFormat: 'm-d-Y',
        minDate: null,
        maxDate: null,
      },
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getEmployees(this)
        LIST.getEmployeePayrollStatus(this)
        LIST.getPayrollTypes(this)
      },
    },
  },
  mounted() {
  },
  created() {
    LIST.getEmployees(this)
    LIST.getEmployeePayrollStatus(this)
    LIST.getPayrollTypes(this)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          this.showLoading = true
          if (this.start_date !== null) {
            this.addSalary.start_date = Constants.getEprmMonthDateFromString(this.start_date)
          }
          if (this.end_date !== null) {
            this.addSalary.end_date = Constants.getEprmMonthDateFromString(this.end_date)
          }
          for (let i = 0; i < this.addSalary.pay_rolls.length; i += 1) {
            if (this.addSalary.pay_rolls[i].salary_paid_date !== null) {
              this.addSalary.pay_rolls[i].paid_date = Constants.getEprmMonthDateFromString(this.addSalary.pay_rolls[i].salary_paid_date)
            } else {
              this.addSalary.pay_rolls[i].paid_date = null
            }
          }
          Salaries.addSalary(this, this.addSalary)
        }
      })
    },
    setPayrollTypes(payRollList) {
      this.payRollList = [...payRollList]
      for (let i = 0; i < payRollList.length; i += 1) {
        this.payRollList[i].is_required = false
        this.payRollList[i].salary_paid_date = null
        this.payRollList[i].status_id = null
        this.payRollList[i].amount = 0
      }
      this.addSalary.pay_rolls = [...this.payRollList]
    },
    setEmployees(employees) {
      this.employees = employees
    },
    setEmployeePayrollStatus(status) {
      this.status = status
    },
    startDateChanged() {
      this.end_date_config.minDate = new Date(moment(this.start_date, 'MM-DD-YYYY'))
    },
    endDateChanged() {
      this.start_date_config.maxDate = new Date(moment(this.end_date, 'MM-DD-YYYY'))
    },
    indiaCheckChanged() {
      if (this.addSalary.is_india) {
        this.addSalary.is_1099 = false
        const salary = this.payRollList.find(element => element.pay_roll_type === 'Salary')
        this.addSalary.pay_rolls = []
        this.addSalary.pay_rolls.push(salary)
      } else {
        this.addSalary.pay_rolls = this.payRollList
      }
    },
    checkChanged() {
      if (this.addSalary.is_1099) {
        this.addSalary.is_india = false
        const salary = this.payRollList.find(element => element.pay_roll_type === 'Salary')
        this.addSalary.pay_rolls = []
        this.addSalary.pay_rolls.push(salary)
      } else {
        this.addSalary.pay_rolls = this.payRollList
      }
    },
    statusChanged() {
      const payrolls = [...this.addSalary.pay_rolls]
      for (let i = 0; i < this.addSalary.pay_rolls.length; i += 1) {
        const statusSelected = this.status.filter(value => value.id === payrolls[i].status_id)
        if (statusSelected.length > 0) {
          if (statusSelected[0].label === 'Paid') {
            payrolls[i].is_required = true
          } else {
            payrolls[i].is_required = false
          }
        }
      }
      this.addSalary.pay_rolls = [...payrolls]
    },
    removeSalaryDate(index) {
      const payrolls = [...this.addSalary.pay_rolls]
      payrolls[index].salary_paid_date = null
      payrolls[index].paid_date = null
      this.addSalary.pay_rolls = [...payrolls]
    },
    salaryAdded(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    salaryNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    back() {
      this.$router.push('/finance-and-accounting/salaries').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.repeater-form {
  transition: .35s height;
}
</style>
