<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Add Document</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <b-row class="mt-1">
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group v-if="showList">
                  <label>Type</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Type"
                    :rules="showList ? 'required' : ''"
                  >
                    <div class="d-flex">
                      <div class="left w-100">
                        <v-select
                          id="document_name_id"
                          v-model="addDocument.document_name_id"
                          :clearable="true"
                          placeholder="Select"
                          :options="documentNames"
                          :reduce="item => item.id"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                      <div class="right ml-50">
                        <b-button
                          variant="outline-primary"
                          class="btn-icon rounded-circle"
                          @click="addNewName"
                        >
                          <feather-icon icon="PlusIcon" />
                        </b-button>
                      </div>
                    </div>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="showName">
                  <label>Document Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Document Name"
                    :rules="showName ? 'required' : ''"
                  >
                    <div class="d-flex">
                      <div class="left w-100">
                        <b-form-input
                          id="document_name"
                          v-model="addDocument.document_name"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                      <div class="right ml-50">
                        <b-button
                          variant="outline-danger"
                          class="btn-icon rounded-circle"
                          @click="showListOption"
                        >
                          <feather-icon icon="MinusIcon" />
                        </b-button>
                      </div>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Attachment</label>
                  <input
                    ref="myFileInput"
                    type="file"
                    placeholder="Choose a file or drop it here..."
                    class="form-control text-left"
                    @change="attahamentFileSelected"
                  >
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="8"
              >
                <b-form-group>
                  <label>Description</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Description"
                    rules="required"
                  >
                    <b-form-textarea
                      id="description"
                      v-model="addDocument.description"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            v-show="addDocument.user_defined_fields.length !== 0"
            variant="success"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>User Defined Fields</h6>
            </div>
            <b-row class="mt-1">
              <b-col
                v-for="field in addDocument.user_defined_fields"
                :id="field.id"
                :key="field.id"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group v-if="field.type.toLowerCase() === 'input'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-input
                      v-model="field.value"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'dropdown'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <v-select
                      v-model="field.value"
                      :options="field.options"
                      label="option"
                      :reduce="item => item.option"
                      :clearable="!field.required"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'textarea'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-textarea
                      v-model="field.value"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="ml-2">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Add Document
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BButton,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import Document from '@/js/admindocuments'
import LIST from '@/js/lists'

export default {
  name: 'DocumentNew',
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      addDocument: {
        description: '',
        document_name_id: null,
        document_name: '',
        file_name: '',
        document: null,
        user_defined_fields: [],
      },
      documentNames: [],
      showList: true,
      showName: false,
    }
  },
  watch: {
    '$store.state.lists.adLists': {
      immediate: true,
      handler() {
        LIST.getDoucumentDocumentNameFullList(this)
        LIST.getUserDefinedFieldsDocuments(this)
      },
    },
  },
  mounted() {
  },
  created() {
    LIST.getDoucumentDocumentNameFullList(this)
    LIST.getUserDefinedFieldsDocuments(this)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          if (this.addDocument.document == null) {
            this.$swal({
              title: 'Document',
              text: 'Please select document',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return
          }
          Document.addDocument(this, this.addDocument)
        }
      })
    },
    setUserDefinedFields(userDefinedFields) {
      this.addDocument.user_defined_fields = userDefinedFields
    },
    attahamentFileSelected(obj) {
      const dis = this
      const fileReader = new FileReader()
      fileReader.onload = fileLoadEvent => {
        dis.addDocument.document = fileLoadEvent.target.result
      }
      fileReader.readAsDataURL(obj.target.files[0])
      this.url = URL.createObjectURL(obj.target.files[0])
      this.documentName = obj.target.files[0].name
      this.addDocument.file_name = obj.target.files[0].name
    },
    setDocumentNames(documentNames) {
      this.documentNames = documentNames
    },
    addNewName() {
      this.addDocument.document_name_id = null
      this.showList = false
      this.showName = true
    },
    showListOption() {
      this.showList = true
      this.showName = false
    },
    documentAdded(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    documentNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    back() {
      this.$router.push('/documents/templates').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.repeater-form {
  transition: .35s height;
}
</style>
