var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('h4',{staticClass:"card-title mb-0 pt-50"},[_vm._v(" Job Posting")])]),_c('b-col',{attrs:{"cols":"12","md":"6 ml-auto"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.back}},[_vm._v(" Back ")])],1)])],1),_c('hr'),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('app-timeline',[_c('app-timeline-item',{staticClass:"pb-1"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Job Title")]),_c('validation-provider',{attrs:{"name":"Job Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"job_title","state":errors.length > 0 ? false:null},model:{value:(_vm.addJob.job_title),callback:function ($$v) {_vm.$set(_vm.addJob, "job_title", $$v)},expression:"addJob.job_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Job-Id / Ref-No")]),_c('validation-provider',{attrs:{"name":"Job-Id / Ref-No","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"job_id"},model:{value:(_vm.addJob.job_id),callback:function ($$v) {_vm.$set(_vm.addJob, "job_id", $$v)},expression:"addJob.job_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Position Type")]),_c('validation-provider',{attrs:{"name":"Position Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"position_type","options":_vm.types,"reduce":function (item) { return item.id; },"clearable":false,"placeholder":"Select"},model:{value:(_vm.addJob.position_type_id),callback:function ($$v) {_vm.$set(_vm.addJob, "position_type_id", $$v)},expression:"addJob.position_type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("States")]),_c('validation-provider',{attrs:{"name":"States","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"states","options":_vm.states,"reduce":function (item) { return item.id; },"clearable":true,"placeholder":"Select"},on:{"input":_vm.stateChanged},model:{value:(_vm.addJob.state_id),callback:function ($$v) {_vm.$set(_vm.addJob, "state_id", $$v)},expression:"addJob.state_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("City, Zip")]),_c('validation-provider',{attrs:{"name":"City, Zip","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"location","options":_vm.locations,"reduce":function (item) { return item.id; },"clearable":true,"placeholder":"Select"},model:{value:(_vm.addJob.city_id),callback:function ($$v) {_vm.$set(_vm.addJob, "city_id", $$v)},expression:"addJob.city_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Visa Required")]),_c('validation-provider',{attrs:{"name":"Visa Required","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"visa_required","options":_vm.visaTypes,"reduce":function (item) { return item.id; },"multiple":"","clearable":false,"placeholder":"Select"},model:{value:(_vm.addJob.visa_type_ids),callback:function ($$v) {_vm.$set(_vm.addJob, "visa_type_ids", $$v)},expression:"addJob.visa_type_ids"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Facility / Client")]),_c('validation-provider',{attrs:{"name":"Facility / Client","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"facility","options":_vm.facilities,"reduce":function (item) { return item.id; },"clearable":false,"placeholder":"Select"},model:{value:(_vm.addJob.facility_id),callback:function ($$v) {_vm.$set(_vm.addJob, "facility_id", $$v)},expression:"addJob.facility_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Facility Rate")]),_c('validation-provider',{attrs:{"name":"Facility Rate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"facility_rate","min":0,"step":0.5},model:{value:(_vm.addJob.facility_bill_rate),callback:function ($$v) {_vm.$set(_vm.addJob, "facility_bill_rate", $$v)},expression:"addJob.facility_bill_rate"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Status")]),_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"status","options":_vm.status,"reduce":function (item) { return item.id; },"clearable":false,"placeholder":"Select"},model:{value:(_vm.addJob.status_id),callback:function ($$v) {_vm.$set(_vm.addJob, "status_id", $$v)},expression:"addJob.status_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Bill Rate")]),_c('validation-provider',{attrs:{"name":"Bill Rate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"bill_rate","min":0,"step":0.5},model:{value:(_vm.addJob.contract_bill_rate),callback:function ($$v) {_vm.$set(_vm.addJob, "contract_bill_rate", $$v)},expression:"addJob.contract_bill_rate"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Main Skill")]),_c('validation-provider',{attrs:{"name":"Main Skill","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"skills","options":_vm.skills,"reduce":function (item) { return item.id; },"clearable":false,"multiple":"","placeholder":"Select"},on:{"input":function($event){return _vm.skillChanged(_vm.addJob.skill_ids)}},model:{value:(_vm.addJob.skill_ids),callback:function ($$v) {_vm.$set(_vm.addJob, "skill_ids", $$v)},expression:"addJob.skill_ids"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"id":"start_date"}},[_c('label',[_vm._v("Start Date")]),_c('b-input-group',[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.start_date_config,"placeholder":"MM-DD-YYYY"},on:{"input":function($event){return _vm.startDateChanged()}},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}}),_c('b-input-group-append',[_c('b-button',{staticClass:"px-1 outline-grey",attrs:{"variant":"outline-secondary"},on:{"click":_vm.removeStartDate}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"id":"end_date"}},[_c('label',[_vm._v("End Date")]),_c('b-input-group',[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.end_date_config,"placeholder":"MM-DD-YYYY"},on:{"input":function($event){return _vm.endDateChanged()}},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}}),_c('b-input-group-append',[_c('b-button',{staticClass:"px-1 outline-grey",attrs:{"variant":"outline-secondary"},on:{"click":_vm.removeEndDate}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Experience")]),_c('validation-provider',{attrs:{"name":"Experience","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"Years"}},[_c('b-form-input',{attrs:{"id":"experience","type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.addJob.experience_years),callback:function ($$v) {_vm.$set(_vm.addJob, "experience_years", $$v)},expression:"addJob.experience_years"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Duration")]),_c('validation-provider',{attrs:{"name":"Duration","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"duration","options":_vm.durations,"reduce":function (item) { return item.id; },"clearable":false,"placeholder":"Select"},model:{value:(_vm.addJob.duration_id),callback:function ($$v) {_vm.$set(_vm.addJob, "duration_id", $$v)},expression:"addJob.duration_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Immediate")]),_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-radio',{staticClass:"mt-50",attrs:{"id":"yes","plain":"","name":"some-radios3","value":"Yes"},model:{value:(_vm.immediate),callback:function ($$v) {_vm.immediate=$$v},expression:"immediate"}},[_vm._v(" Yes ")]),_c('b-form-radio',{staticClass:"mt-50",attrs:{"id":"no","plain":"","name":"some-radios3","value":"No"},model:{value:(_vm.immediate),callback:function ($$v) {_vm.immediate=$$v},expression:"immediate"}},[_vm._v(" No ")])],1)])],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Assigned Users")]),_c('v-select',{attrs:{"id":"assigned_users","multiple":"","options":_vm.roleUsers,"reduce":function (item) { return item.id; },"clearable":false,"placeholder":"Select"},model:{value:(_vm.addJob.assigned_user_ids),callback:function ($$v) {_vm.$set(_vm.addJob, "assigned_user_ids", $$v)},expression:"addJob.assigned_user_ids"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Co-Ordinator")]),_c('b-form-input',{attrs:{"id":"coordinator"},model:{value:(_vm.addJob.coordinator),callback:function ($$v) {_vm.$set(_vm.addJob, "coordinator", $$v)},expression:"addJob.coordinator"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Shift Days")]),_c('validation-provider',{attrs:{"name":"Shift Days","rules":"required|integer|between:0,7"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"Days"}},[_c('b-form-input',{attrs:{"id":"shift_days","type":"number"},model:{value:(_vm.addJob.shift_days),callback:function ($$v) {_vm.$set(_vm.addJob, "shift_days", $$v)},expression:"addJob.shift_days"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Shift Hours")]),_c('validation-provider',{attrs:{"name":"Shift Hours","rules":"required|integer|between:0,24"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"Hours"}},[_c('b-form-input',{attrs:{"id":"shift_hours","type":"number"},model:{value:(_vm.addJob.shift_hours),callback:function ($$v) {_vm.$set(_vm.addJob, "shift_hours", $$v)},expression:"addJob.shift_hours"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_vm._l((_vm.addJob.job_start_times),function(job_start_time,index){return _c('b-col',{key:index,attrs:{"cols":"12","md":"6","lg":"4"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"left w-100"},[_c('b-form-group',[_c('label',[_vm._v("Shift Start Time ")]),_c('validation-provider',{attrs:{"rules":'',"name":'Shift Start Time' + index},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.shiftTimeConfig,"placeholder":'00:00'},model:{value:(job_start_time.time),callback:function ($$v) {_vm.$set(job_start_time, "time", $$v)},expression:"job_start_time.time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s((errors[0] !== undefined && errors[0] !== null) ? errors[0].replace('Shift Start Time' + index, 'Shift Start Time') : errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"right mt-2 ml-1"},[_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeShiftTime(index)}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)],1)])])}),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-button',{staticClass:"mt-2 mb-1",attrs:{"variant":"outline-primary","type":"button"},on:{"click":_vm.addShiftTime}},[_vm._v(" Add Shift Time ")])],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Description")]),_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{staticClass:"job-description-view",attrs:{"id":"description","options":_vm.snowOption},model:{value:(_vm.addJob.description),callback:function ($$v) {_vm.$set(_vm.addJob, "description", $$v)},expression:"addJob.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],2)],1),_c('app-timeline-item',{staticClass:"pb-1",attrs:{"variant":"info"}},[_c('div',{staticClass:"d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"},[_c('h6',[_vm._v("Secondary Skills")])]),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',_vm._l((_vm.technologies),function(option){return _c('b-col',{key:option,staticClass:"mb-1",attrs:{"value":option,"cols":"2"}},[_c('b-form-checkbox',{attrs:{"checked":_vm.technologySelected(option.id)},on:{"input":function($event){return _vm.checked(option.id)}}},[_vm._v(" "+_vm._s(option.label)+" ")])],1)}),1)],1)],1)],1),_c('app-timeline-item',{staticClass:"pb-1",attrs:{"variant":"success"}},[_c('div',{staticClass:"d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"},[_c('h6',[_vm._v("Workflow")])]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Follow Workflow")]),_c('validation-provider',{attrs:{"name":"Follow Workflow","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"custom-word-select",attrs:{"id":"follow_process","options":_vm.processes,"reduce":function (item) { return item.id; },"clearable":false,"placeholder":"Select"},model:{value:(_vm.addJob.process_id),callback:function ($$v) {_vm.$set(_vm.addJob, "process_id", $$v)},expression:"addJob.process_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('app-timeline-item',{staticClass:"pb-1",attrs:{"variant":"warning"}},[_c('div',{staticClass:"d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"},[_c('h6',[_vm._v("Required Criteria")])]),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('div',{staticClass:"demo-inline-spacing"},_vm._l((_vm.criterias),function(criteria){return _c('div',{key:criteria.id,attrs:{"id":criteria.id}},[_c('b-form-checkbox',{attrs:{"checked":_vm.criteriaSelected(criteria.id)},on:{"change":function($event){return _vm.criteriaChecked(criteria.id)}}},[_vm._v(" "+_vm._s(criteria.label)+" ")])],1)}),0)])],1)],1),_c('b-row',[_vm._l((_vm.addJob.criterias),function(criteria,index){return _c('b-col',{key:criteria.id,attrs:{"id":criteria.id,"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":'Criteria' + index,"rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"left w-100"},[_c('b-form-input',{attrs:{"id":'criteria_name' + index,"state":errors.length > 0 ? false:null},model:{value:(criteria.name),callback:function ($$v) {_vm.$set(criteria, "name", $$v)},expression:"criteria.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s((errors[0] !== null && errors[0] !== undefined) ? errors[0].replace('Criteria' + index, 'Criteria') : errors[0]))])],1),_c('div',{staticClass:"right ml-50"},[_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeCriteria(index)}}},[_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],1)],1)])]}}],null,true)})],1)],1)}),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mt-50",attrs:{"variant":"outline-primary","type":"button"},on:{"click":_vm.addCriteria}},[_vm._v(" Add ")])],1)],2)],1),_c('app-timeline-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.addJob.user_defined_fields.length !== 0),expression:"addJob.user_defined_fields.length !== 0"}],staticClass:"pb-1",attrs:{"variant":"danger"}},[_c('div',{staticClass:"d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"},[_c('h6',[_vm._v("User Defined Fields")])]),_c('b-row',_vm._l((_vm.addJob.user_defined_fields),function(field){return _c('b-col',{key:field.id,attrs:{"id":field.id,"cols":"12","md":"6","lg":"4"}},[(field.type.toLowerCase() === 'input')?_c('b-form-group',[_c('label',[_vm._v(_vm._s(field.name))]),_c('validation-provider',{attrs:{"name":field.name,"rules":field.required ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(field.type.toLowerCase() === 'dropdown')?_c('b-form-group',[_c('label',[_vm._v(_vm._s(field.name))]),_c('validation-provider',{attrs:{"name":field.name,"rules":field.required ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":field.options,"label":"option","reduce":function (item) { return item.option; },"clearable":!field.required,"placeholder":"Select"},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(field.type.toLowerCase() === 'textarea')?_c('b-form-group',[_c('label',[_vm._v(_vm._s(field.name))]),_c('validation-provider',{attrs:{"name":field.name,"rules":field.required ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"rows":"2"},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1)}),1)],1)],1),_c('hr'),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"primary","type":"button"},on:{"click":_vm.submitForm}},[_vm._v(" Save Post ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }