<template>
  <div>
    <div class="mt-1 ml-2">
      <h6>INCOME FROM SALES OR SERVICES</h6>
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Gross Income From Sales or Service</label>
            <validation-provider
              v-slot="{ errors }"
              name="Gross Income"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="gross_sales"
                  v-model="addStatement.gross_sales"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="netSalesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Less Returns and Allowances</label>
            <validation-provider
              v-slot="{ errors }"
              name="Less Returns"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="sales_returns"
                  v-model="addStatement.sales_returns"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="netSalesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label class="font-weight-bolder">Net Sales</label>
            <validation-provider
              v-slot="{ errors }"
              name="Net Sales"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="net_sales"
                  v-model="addStatement.net_sales"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control font-weight-bolder"
                  @input="soldChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <hr class="mt-0 ml-2">

    <div class="mt-1 ml-2">
      <h6>COST OF SALES</h6>
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Inventory – Beginning of Period</label>
            <validation-provider
              v-slot="{ errors }"
              name="Beginning Inventory"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="beginning_inventory"
                  v-model="addStatement.beginning_inventory"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="soldChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Purchases</label>
            <validation-provider
              v-slot="{ errors }"
              name="Purchases"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="purchases"
                  v-model="addStatement.purchases"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="soldChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Labor</label>
            <validation-provider
              v-slot="{ errors }"
              name="Labor"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="labor"
                  v-model="addStatement.labor"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="soldChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Materials and Supplies</label>
            <validation-provider
              v-slot="{ errors }"
              name="Materials and Supplies"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="materials_and_supplies"
                  v-model="addStatement.materials_and_supplies"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="soldChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Freight-in</label>
            <validation-provider
              v-slot="{ errors }"
              name="Freight-in"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="freight_in"
                  v-model="addStatement.freight_in"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="soldChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <hr class="mt-0 ml-2">

    <div class="mt-1 ml-2">
      <h6>OTHER COSTS OF SALES</h6>
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Facility Expenses (Sub-Contractor)</label>
            <validation-provider
              v-slot="{ errors }"
              name="Facility Expenses"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="vendor_expenses"
                  v-model="addStatement.vendor_expenses"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="vendorExpensesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Sub Total</label>
            <validation-provider
              v-slot="{ errors }"
              name="Sub Total"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="sub_total"
                  v-model="addStatement.sub_total"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="grossProfitChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Deduct Inventory – End of Period</label>
            <validation-provider
              v-slot="{ errors }"
              name="Deduct Inventory"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="deduct_inventory"
                  v-model="addStatement.deduct_inventory"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="soldChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label class="font-weight-bolder">Total Cost of Sales</label>
            <validation-provider
              v-slot="{ errors }"
              name="Total Cost of Sales"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="cost_of_sales"
                  v-model="addStatement.cost_of_sales"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control font-weight-bolder"
                  @input="grossProfitChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label class="font-weight-bolder">Gross Profit</label>
            <validation-provider
              v-slot="{ errors }"
              name="Gross Profit"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="gross_profit"
                  v-model="addStatement.gross_profit"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control font-weight-bolder"
                  @input="operatingProfitChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <hr class="mt-0 ml-2">

    <div class="mt-1 ml-2">
      <h6>Operating Expenses</h6>
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Advertising & Marketing</label>
            <validation-provider
              v-slot="{ errors }"
              name="Advertising"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="advertising"
                  v-model="addStatement.advertising"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="expensesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Amortization</label>
            <validation-provider
              v-slot="{ errors }"
              name="Amortization"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="amortization"
                  v-model="addStatement.amortization"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="expensesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Bad Debts</label>
            <validation-provider
              v-slot="{ errors }"
              name="Bad Debts"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="bad_debts"
                  v-model="addStatement.bad_debts"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="expensesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Bank Charges & Fees</label>
            <validation-provider
              v-slot="{ errors }"
              name="Bank Charges"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="bank_charges"
                  v-model="addStatement.bank_charges"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="expensesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Charitable Contributions</label>
            <validation-provider
              v-slot="{ errors }"
              name="Charitable Contributions"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="charitable_contributions"
                  v-model="addStatement.charitable_contributions"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="expensesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Commissions</label>
            <validation-provider
              v-slot="{ errors }"
              name="Commissions"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="commissions"
                  v-model="addStatement.commissions"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="expensesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Contract Labor</label>
            <validation-provider
              v-slot="{ errors }"
              name="Contract Labor"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="contract_labor"
                  v-model="addStatement.contract_labor"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="expensesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Expenses</label>
            <validation-provider
              v-slot="{ errors }"
              name="Expenses"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="expenses"
                  v-model="addStatement.expenses"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="expensesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Depreciation</label>
            <validation-provider
              v-slot="{ errors }"
              name="Depreciation"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="depreciation"
                  v-model="addStatement.depreciation"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="expensesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Dues and Subscriptions</label>
            <validation-provider
              v-slot="{ errors }"
              name="Dues and Subscriptions"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="dues_and_subscriptions"
                  v-model="addStatement.dues_and_subscriptions"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="expensesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Employee Benefit Programs</label>
            <validation-provider
              v-slot="{ errors }"
              name="Employee Benefit Programs"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="employee_benefit_programs"
                  v-model="addStatement.employee_benefit_programs"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="expensesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Insurance</label>
            <validation-provider
              v-slot="{ errors }"
              name="Insurance"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="insurance"
                  v-model="addStatement.insurance"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="expensesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Interest</label>
            <validation-provider
              v-slot="{ errors }"
              name="Interest"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="interest"
                  v-model="addStatement.interest"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="expensesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Legal and Professional Fees</label>
            <validation-provider
              v-slot="{ errors }"
              name="Legal and Professional Fees"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="legal_and_professional_fees"
                  v-model="addStatement.legal_and_professional_fees"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="expensesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Licenses and Fees</label>
            <validation-provider
              v-slot="{ errors }"
              name="Licenses and Fees"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="licenses_and_fees"
                  v-model="addStatement.licenses_and_fees"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="expensesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Miscellaneous</label>
            <validation-provider
              v-slot="{ errors }"
              name="Miscellaneous"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="miscellaneous"
                  v-model="addStatement.miscellaneous"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="expensesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Meals & Entertainment</label>
            <validation-provider
              v-slot="{ errors }"
              name="Meals & Entertainment"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="office_expense"
                  v-model="addStatement.office_expense"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="expensesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Payroll Taxes</label>
            <validation-provider
              v-slot="{ errors }"
              name="Payroll Taxes"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="payroll_taxes"
                  v-model="addStatement.payroll_taxes"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="expensesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Mailing & Postage</label>
            <validation-provider
              v-slot="{ errors }"
              name="Postage"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="postage"
                  v-model="addStatement.postage"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="expensesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Rent & Lease</label>
            <validation-provider
              v-slot="{ errors }"
              name="Rent"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="rent"
                  v-model="addStatement.rent"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="expensesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Repairs and Maintenance</label>
            <validation-provider
              v-slot="{ errors }"
              name="Repairs and Maintenance"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="repairs_and_maintenance"
                  v-model="addStatement.repairs_and_maintenance"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="expensesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Office Supplies & Software</label>
            <validation-provider
              v-slot="{ errors }"
              name="Supplies"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="supplies"
                  v-model="addStatement.supplies"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="expensesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Telephone</label>
            <validation-provider
              v-slot="{ errors }"
              name="Telephone"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="telephone"
                  v-model="addStatement.telephone"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="expensesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Travel, Parking & Tolls</label>
            <validation-provider
              v-slot="{ errors }"
              name="Travel"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="travel"
                  v-model="addStatement.travel"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="expensesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Utilities</label>
            <validation-provider
              v-slot="{ errors }"
              name="Utilities"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="utilities"
                  v-model="addStatement.utilities"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="expensesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Vehicle Expenses</label>
            <validation-provider
              v-slot="{ errors }"
              name="Vehicle Expenses"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="vehicle_expenses"
                  v-model="addStatement.vehicle_expenses"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="expensesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Wages</label>
            <validation-provider
              v-slot="{ errors }"
              name="Wages"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="wages"
                  v-model="addStatement.wages"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="expensesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Software Development</label>
            <validation-provider
              v-slot="{ errors }"
              name="Software Development"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="software_development"
                  v-model="addStatement.software_development"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="expensesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label class="font-weight-bolder">Total Operating Expenses</label>
            <validation-provider
              v-slot="{ errors }"
              name="Total Operating Expenses"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="total_operating_expenses"
                  v-model="addStatement.total_operating_expenses"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control font-weight-bolder"
                  @input="operatingProfitChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <hr class="mt-0 ml-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Operating Profit or Loss</label>
            <validation-provider
              v-slot="{ errors }"
              name="Operating Profit or Loss"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="operating_profit"
                  v-model="addStatement.operating_profit"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="netIncomeChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Provision for Income Tax</label>
            <validation-provider
              v-slot="{ errors }"
              name="Provision for Income Tax"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="income_tax"
                  v-model="addStatement.income_tax"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="netIncomeChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label class="font-weight-bolder">Net Income</label>
            <validation-provider
              v-slot="{ errors }"
              name="Net Income"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="net_income"
                  v-model="addStatement.net_income"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control font-weight-bolder"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <hr>
    <b-row>
      <b-col class="text-right">
        <b-button
          variant="primary"
          type="button"
          @click="create"
        >
          Create
        </b-button>
        <b-button
          variant="primary"
          type="button"
          class="ml-2"
          @click="saveCreate"
        >
          Save & Create
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BRow, BCol, BFormGroup, BInputGroup, BButton,
} from 'bootstrap-vue'
import PLStatement from '@/js/plStatement'

export default {
  name: 'ProfitAndLoss',
  components: {
    ValidationProvider,
    BRow,
    BButton,
    BCol,
    BFormGroup,
    BInputGroup,
  },
  props: {
    addPLStatementParent: {},
  },
  data() {
    return {
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      addStatement: {
        gross_sales: '',
        sales_returns: '',
        net_sales: '',
        beginning_inventory: '',
        purchases: '',
        labor: '',
        materials_and_supplies: '',
        freight_in: '',
        vendor_expenses: '',
        sub_total: '',
        deduct_inventory: '',
        cost_of_sales: '',
        gross_profit: '',
        advertising: '',
        amortization: '',
        bad_debts: '',
        bank_charges: '',
        charitable_contributions: '',
        commissions: '',
        contract_labor: '',
        expenses: '',
        depreciation: '',
        dues_and_subscriptions: '',
        employee_benefit_programs: '',
        insurance: '',
        interest: '',
        legal_and_professional_fees: '',
        licenses_and_fees: '',
        miscellaneous: '',
        office_expense: '',
        payroll_taxes: '',
        postage: '',
        rent: '',
        repairs_and_maintenance: '',
        supplies: '',
        telephone: '',
        travel: '',
        utilities: '',
        vehicle_expenses: '',
        wages: '',
        software_development: '',
        total_operating_expenses: '',
        operating_profit: '',
        income_tax: '',
        net_income: '',
        start: null,
        end: null,
      },
    }
  },
  watch: {
    addPLStatementParent() {
      this.addStatement = this.$props.addPLStatementParent
    },
  },
  methods: {
    saveCreate() {
      if (this.addStatement.start !== null && this.addStatement.end !== null) {
        PLStatement.addPLStatement(this, this.addStatement, true)
      }
    },
    create() {
      if (this.addStatement.start !== null && this.addStatement.end !== null) {
        PLStatement.addPLStatement(this, this.addStatement, false)
      }
    },
    plStatementSaved(data) {
      this.back()
      window.open(data.pdf_path, '_blank')
      this.$refs.showLoading = false
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    plStatementNotSaved(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    netSalesChanged() {
      this.addStatement.net_sales = this.addStatement.gross_sales - this.addStatement.sales_returns
    },
    soldChanged() {
      this.addStatement.cost_of_sales = this.addStatement.beginning_inventory + this.addStatement.purchases + this.addStatement.freight_in + this.addStatement.vendor_expenses + this.addStatement.materials_and_supplies + this.addStatement.deduct_inventory
    },
    vendorExpensesChanged() {
      this.addStatement.sub_total = this.addStatement.vendor_expenses
    },
    expensesChanged() {
      this.addStatement.total_operating_expenses = this.addStatement.advertising + this.addStatement.amortization + this.addStatement.bad_debts + this.addStatement.bank_charges + this.addStatement.charitable_contributions + this.addStatement.commissions + this.addStatement.contract_labor + this.addStatement.expenses + this.addStatement.depreciation + this.addStatement.dues_and_subscriptions + this.addStatement.employee_benefit_programs + this.addStatement.insurance + this.addStatement.interest + this.addStatement.legal_and_professional_fees + this.addStatement.licenses_and_fees + this.addStatement.miscellaneous + this.addStatement.office_expense + this.addStatement.payroll_taxes + this.addStatement.postage + this.addStatement.rent + this.addStatement.repairs_and_maintenance + this.addStatement.supplies + this.addStatement.telephone + this.addStatement.travel + this.addStatement.utilities + this.addStatement.vehicle_expenses + this.addStatement.wages + this.addStatement.software_development
    },
    grossProfitChanged() {
      this.addStatement.gross_profit = this.addStatement.net_sales - this.addStatement.cost_of_sales
    },
    operatingProfitChanged() {
      this.addStatement.operating_profit = this.addStatement.gross_profit - this.addStatement.total_operating_expenses
    },
    netIncomeChanged() {
      this.addStatement.net_income = this.addStatement.operating_profit - this.addStatement.income_tax
    },
    back() {
      this.$router.push('/financial-statements/statements').catch(() => {})
    },
  },
}
</Script>
