import Timesheets from '@/views/finance-accounting/timesheets/Timesheets.vue'
import TimesheetNew from '@/views/finance-accounting/timesheets/actions/New.vue'
import TimesheetEdit from '@/views/finance-accounting/timesheets/actions/Edit.vue'
import TimesheetView from '@/views/finance-accounting/timesheets/actions/View.vue'
import InvoicesVendorsView from '@/views/finance-accounting/invoices-vendors/actions/View.vue'
import InvoicesVendorsEdit from '@/views/finance-accounting/invoices-vendors/actions/Edit.vue'
import InvoicesVendors from '@/views/finance-accounting/invoices-vendors/InvoicesVendors.vue'
import Expenses from '@/views/finance-accounting/expenses/Expenses.vue'
import ExpensesNew from '@/views/finance-accounting/expenses/actions/New.vue'
import ExpensesEdit from '@/views/finance-accounting/expenses/actions/Edit.vue'
import ExpensesView from '@/views/finance-accounting/expenses/actions/View.vue'
import BankReconcileHistory from '@/views/finance-accounting/bank-reconcile-history/BankReconcileHistory.vue'
import BankReconcileHistoryNew from '@/views/finance-accounting/bank-reconcile-history/actions/New.vue'
import BankReconcileHistoryEdit from '@/views/finance-accounting/bank-reconcile-history/actions/Edit.vue'
import BankReconcileHistoryView from '@/views/finance-accounting/bank-reconcile-history/actions/View.vue'
import ReconciliatonHistory from '@/views/finance-accounting/bank-reconcile-history/actions/ReconciliatonHistory.vue'
import ReconcileAnAccount from '@/views/finance-accounting/bank-reconcile-history/actions/ReconcileAnAccount.vue'
import ReconReport from '@/views/finance-accounting/bank-reconcile-history/actions/ReconReport.vue'
import ReconciliatonEdit from '@/views/finance-accounting/bank-reconcile-history/actions/ReconciliatonEdit.vue'
import ReconciliatonView from '@/views/finance-accounting/bank-reconcile-history/actions/ReconciliatonView.vue'
import BankTransactions from '@/views/finance-accounting/bank-transactions/BankTransactions.vue'
import BankTransactionsView from '@/views/finance-accounting/bank-transactions/actions/View.vue'
import Salaries from '@/views/finance-accounting/employeesalaries/Salaries.vue'
import SalariesNew from '@/views/finance-accounting/employeesalaries/actions/New.vue'
import SalariesEdit from '@/views/finance-accounting/employeesalaries/actions/Edit.vue'
import SalariesView from '@/views/finance-accounting/employeesalaries/actions/View.vue'

export default [
  // *===============================================---*
  // *--------- Finanace & Accounting -------------------------------*
  // *===============================================---*
  // *------------ Timesheets --------------------*
  {
    path: '/finance-and-accounting/timesheets',
    name: 'finance-and-accounting-timesheets',
    component: Timesheets,
  },
  {
    path: '/finance-and-accounting/timesheets/new',
    name: 'finance-and-accounting-timesheets-new',
    component: TimesheetNew,
    meta: {
      navActiveLink: 'finance-and-accounting-timesheets',
    },
  },
  {
    path: '/finance-and-accounting/timesheets/edit/:id',
    name: 'finance-and-accounting-timesheets-edit',
    component: TimesheetEdit,
    meta: {
      navActiveLink: 'finance-and-accounting-timesheets',
    },
  },
  {
    path: '/finance-and-accounting/timesheets/view/:id',
    name: 'finance-and-accounting-timesheets-view',
    component: TimesheetView,
    meta: {
      navActiveLink: 'finance-and-accounting-timesheets',
    },
  },
  // *------------ Invoices to Facilities --------------------*
  {
    path: '/finance-and-accounting/invoices-to-vendors',
    name: 'finance-and-accounting-invoices-to-vendors',
    component: InvoicesVendors,
  },
  {
    path: '/finance-and-accounting/invoices-to-vendors/edit/:id',
    name: 'finance-and-accounting-invoices-to-vendors-edit',
    component: InvoicesVendorsEdit,
    meta: {
      navActiveLink: 'finance-and-accounting-invoices-to-vendors',
    },
  },
  {
    path: '/finance-and-accounting/invoices-to-vendors/view/:id',
    name: 'finance-and-accounting-invoices-to-vendors-view',
    component: InvoicesVendorsView,
    meta: {
      navActiveLink: 'finance-and-accounting-invoices-to-vendors',
    },
  },
  // *------------ Expenses --------------------*
  {
    path: '/finance-and-accounting/expenses',
    name: 'finance-and-accounting-expenses',
    component: Expenses,
  },
  {
    path: '/finance-and-accounting/expenses/new',
    name: 'finance-and-accounting-expenses-new',
    component: ExpensesNew,
    meta: {
      navActiveLink: 'finance-and-accounting-expenses',
    },
  },
  {
    path: '/finance-and-accounting/expenses/edit/:id',
    name: 'finance-and-accounting-expenses-edit',
    component: ExpensesEdit,
    meta: {
      navActiveLink: 'finance-and-accounting-expenses',
    },
  },
  {
    path: '/finance-and-accounting/expenses/view/:id',
    name: 'finance-and-accounting-expenses-view',
    component: ExpensesView,
    meta: {
      navActiveLink: 'finance-and-accounting-expenses',
    },
  },
  // *------------ Reconciliaton History --------------------*
  {
    path: '/finance-and-accounting/bank-reconcile-history',
    name: 'finance-and-accounting-bank-reconcile-history',
    component: BankReconcileHistory,
  },
  {
    path: '/finance-and-accounting/banks/new',
    name: 'finance-and-accounting-banks-new',
    component: BankReconcileHistoryNew,
    meta: {
      navActiveLink: 'finance-and-accounting-bank-reconcile-history',
    },
  },
  {
    path: '/finance-and-accounting/banks/edit/:id',
    name: 'finance-and-accounting-banks-edit',
    component: BankReconcileHistoryEdit,
    meta: {
      navActiveLink: 'finance-and-accounting-bank-reconcile-history',
    },
  },
  {
    path: '/finance-and-accounting/banks/view/:id',
    name: 'finance-and-accounting-banks-view',
    component: BankReconcileHistoryView,
    meta: {
      navActiveLink: 'finance-and-accounting-bank-reconcile-history',
    },
  },
  {
    path: '/finance-and-accounting/bank-reconcile-history/reconcilation-history/:id',
    name: 'finance-and-accounting-bank-reconcile-history-reconcilation-history',
    component: ReconciliatonHistory,
    meta: {
      navActiveLink: 'finance-and-accounting-bank-reconcile-history',
    },
  },
  {
    path: '/finance-and-accounting/bank-reconcile-history/reconcile-an-account/:id',
    name: 'finance-and-accounting-bank-reconcile-history-reconcile-an-account',
    component: ReconcileAnAccount,
    meta: {
      navActiveLink: 'finance-and-accounting-bank-reconcile-history',
    },
  },
  {
    path: '/finance-and-accounting/bank-reconcile-history/recon-report/:id',
    name: 'finance-and-accounting-bank-reconcile-history-recon-report',
    component: ReconReport,
    meta: {
      navActiveLink: 'finance-and-accounting-bank-reconcile-history',
    },
  },
  {
    path: '/finance-and-accounting/bank-reconcile-history/edit/:id',
    name: 'finance-and-accounting-bank-reconcile-history-edit',
    component: ReconciliatonEdit,
    meta: {
      navActiveLink: 'finance-and-accounting-bank-reconcile-history',
    },
  },
  {
    path: '/finance-and-accounting/bank-reconcile-history/view/:id',
    name: 'finance-and-accounting-bank-reconcile-history-view',
    component: ReconciliatonView,
    meta: {
      navActiveLink: 'finance-and-accounting-bank-reconcile-history',
    },
  },
  // *------------ Bank Transactions --------------------*
  {
    path: '/finance-and-accounting/bank-transactions/:id',
    name: 'finance-and-accounting-bank-transactions',
    component: BankTransactions,
  },
  {
    path: '/finance-and-accounting/bank-transactions/:bankId/view/:id',
    name: 'finance-and-accounting-bank-transactions-view',
    component: BankTransactionsView,
    meta: {
      navActiveLink: 'finance-and-accounting-bank-reconcile-history',
    },
  },
  // *------------ Employee Salaries --------------------*
  {
    path: '/finance-and-accounting/salaries',
    name: 'finance-and-accounting-salaries',
    component: Salaries,
    meta: {
      navActiveLink: 'finance-and-accounting-salaries',
    },
  },
  {
    path: '/finance-and-accounting/salaries/new',
    name: 'finance-and-accounting-salaries-new',
    component: SalariesNew,
    meta: {
      navActiveLink: 'finance-and-accounting-salaries',
    },
  },
  {
    path: '/finance-and-accounting/salaries/view/:id',
    name: 'finance-and-accounting-salaries-view',
    component: SalariesView,
    meta: {
      navActiveLink: 'finance-and-accounting-salaries',
    },
  },
  {
    path: '/finance-and-accounting/salaries/edit/:id',
    name: 'finance-and-accounting-salaries-edit',
    component: SalariesEdit,
    meta: {
      navActiveLink: 'finance-and-accounting-salaries',
    },
  },
]
