export default [
  {
    title: 'Forecasting',
    icon: 'TrendingUpIcon',
    children: [
      {
        title: 'Reports',
        route: 'forecasting-reports',
      },
    ],
  },
]
