import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.faurl
const bankTransaction = `${HOST}reconcile`

export default {
  updateReconcileByBankId(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${bankTransaction}/bank/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.setReconcileCreated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.setReconcileNOtCreated(error.response.data.return_message)
    })
  },
  getReconcileHistoriesBybankId(context1, id) {
    const context = context1
    context.$vs.loading()
    let params = { query: context.searchQuery, page: context.$store.state.query.page - 1 }
    if (params !== {}) {
      params = { params: { query: context.searchQuery, page: context.$store.state.query.page - 1 } }
    } else {
      params = { params: {} }
    }
    axios.get(`${bankTransaction}/bank/${id}`, params).then(response => {
      context.$vs.loading.close()
      context.setReconcileHistories(response.data)
    }, error => {
      context.$vs.loading.close()
      context.reconcileHistoriesNotFound(error.response.data.return_message)
    })
  },
  getReconcileHistoriesById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${bankTransaction}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setReconcileHistories(response.data)
    }, error => {
      context.$vs.loading.close()
      context.reconcileHistoriesNotFound(error.response.data.return_message)
    })
  },
  getReconcileReportById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${bankTransaction}/${id}/report`).then(response => {
      context.$vs.loading.close()
      context.setReconcileReport(response.data)
    }, error => {
      context.$vs.loading.close()
      context.reconcileReportNotFound(error.response.data.return_message)
    })
  },
  updateReconcileById(context1, id, data) {
    const context = context1
    context.$vs.loading()
    axios.put(`${bankTransaction}/${id}`, data).then(response => {
      context.$vs.loading.close()
      context.setReconcileUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.setReconcileNotUpdated(error.response.data.return_message)
    })
  },
}
