<template>
  <b-card
    class="mb-0"
  >
    <div class="mb-2">
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <h4 class="card-title mb-0 pt-50">
            Templates</h4>
        </b-col>
        <b-col
          cols="12"
          md="8 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                @keyup.enter="updateSearchQuery"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="updateSearchQuery"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="14"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button
              variant="primary"
              class="ml-2 min-w-160"
              :to="{ name: 'e-signature-templates-new' }"
            >
              Add Template
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-row>
      <b-col cols="12">
        <vue-good-table
          :columns="columns"
          :rows="signatrueResponse.esignature_template"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >

          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Action -->
            <span
              v-if="props.column.field === 'action'"
              class="btn-action"
            >
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item :to="{ name: 'e-signature-templates-edit', params: { id: props.row.id } }">
                    <feather-icon icon="Edit2Icon" />
                    <span class="ml-50">Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="confirmDeleteRecord(props.row)">
                    <feather-icon icon="TrashIcon" />
                    <span class="ml-50">Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
                <b-link
                  id="view"
                  :to="{ name: 'e-signature-templates-view', params: { id: props.row.id } }"
                >
                  <b-badge
                    pill
                    variant="light-primary"
                  ><feather-icon icon="EyeIcon" />
                  </b-badge>
                </b-link>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
          >
            <div class="d-flex justify-content-end flex-wrap">
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="signatrueResponse.count"
                  first-number
                  last-number
                  align="right"
                  :per-page="1"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BBadge, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BDropdown, BDropdownItem, BButton, BLink,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import EsignatureTemplate from '@/js/esignaturetemplate'

export default {
  name: 'E-SignatureTemplates',
  components: {
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      searchQuery: '',
      pageLength: 10,
      dir: false,
      signatrueResponse: {
        e_signatures: [],
        count: 1,
      },
      columns: [
        {
          label: '#',
          field: 'index',
          width: '100px',
          sortable: false,
        },
        {
          label: 'Template Name',
          field: 'template_type',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          width: '120px',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
        EsignatureTemplate.getTemplates(this)
      },
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.searchQuery = this.$store.state.query.query
    this.signatrueResponse.count = this.$store.state.query.page
    EsignatureTemplate.getTemplates(this)
  },
  methods: {
    confirmDeleteRecord(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${obj.template_type}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          EsignatureTemplate.delete(this, obj.id)
        }
      })
    },
    templateDeleted(data) {
      EsignatureTemplate.getTemplates(this)
      this.$swal({
        icon: 'success',
        title: 'Template Deleted!',
        text: data.return_message,
      })
    },
    templateNotDeleted(message) {
      this.$swal({
        icon: 'warning',
        title: 'Template Not Deleted!',
        text: message,
      })
    },
    updateSearchQuery() {
      this.$store.state.query.query = this.searchQuery
      this.currentPage = 1
    },
    setTemplates(signatrueResponse) {
      this.signatrueResponse = signatrueResponse
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
