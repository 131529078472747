<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Clone Workflow Task</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Task Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Task Name"
                    rules="required"
                  >
                    <b-form-input
                      id="task_name"
                      v-model="addTask.name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Allowed User Type</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Allowed User Type"
                    rules="required"
                  >
                    <v-select
                      id="allowed_user_type_ids"
                      v-model="addTask.allowed_user_type_ids"
                      :options="userTypes"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                      multiple
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="success"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Task Fields</h6>
            </div>
            <draggable
              v-model="myList"
            >
              <div
                v-for="(field, fieldIndex) in addTask.fields"
                :id="fieldIndex"
                :key="fieldIndex"
                class="d-flex cursor-move"
              >
                <div class="left w-100">
                  <b-row>
                    <b-col
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <b-form-group>
                        <label>Field Name</label>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="'Field Name' + fieldIndex"
                          rules="required"
                        >
                          <b-form-input
                            v-model="field.name"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-danger">{{ (errors[0] !== undefined && errors[0] !== null) ? errors[0].replace('Field Name' + fieldIndex, 'Field Name') : errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <b-form-group>
                        <label>Field Type</label>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="'Field Type' + fieldIndex"
                          rules="required"
                        >
                          <v-select
                            id="field_type"
                            v-model="field.type_id"
                            :options="fieldTypes"
                            :reduce="item => item.id"
                            :clearable="false"
                            placeholder="Select"
                            @input="fieldTypeChanged(fieldIndex)"
                          />
                          <small class="text-danger">{{ (errors[0] !== undefined && errors[0] !== null) ? errors[0].replace('Field Type' + fieldIndex, 'Field Type') : errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <b-form-group class="mt-2">
                        <b-form-checkbox
                          v-model="field.is_required"
                          value="true"
                        >
                          Required
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-row>
                        <b-col
                          v-for="(option, index) in field.options"
                          :id="option.id"
                          :key="option.id"
                          cols="4"
                        >
                          <div class="d-flex">
                            <div class="w-100">
                              <b-form-group>
                                <label>Option</label>
                                <validation-provider
                                  v-slot="{ errors }"
                                  :name="'Option' + (index + 1)"
                                  rules="required"
                                >
                                  <b-form-input
                                    v-model="option.option"
                                    type="text"
                                    :placeholder="'Option' + (index + 1)"
                                    :state="errors.length > 0 ? false:null"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                            </div>
                            <div>
                              <b-button
                                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                variant="outline-danger"
                                class="mt-2 ml-1 px-1"
                                @click="removeOption(index, fieldIndex)"
                              >
                                <feather-icon
                                  icon="XIcon"
                                />
                              </b-button>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                      <b-button
                        v-if="field.type === 'DropDown'"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="outline-primary"
                        class="mb-2 px-1"
                        @click="addOption(fieldIndex)"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          class="mr-25"
                        />
                        <span>Add Option</span>
                      </b-button>
                    </b-col>
                  </b-row>
                  <hr class="mt-0">
                </div>
                <div class="right mt-1">
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="btn-icon rounded-circle"
                    @click="removeField(fieldIndex)"
                  >
                    <feather-icon
                      icon="XIcon"
                    />
                  </b-button>
                </div>
              </div>
            </draggable>
            <b-row>
              <b-col cols="12">
                <b-button
                  id="add_contact_info"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  class="mb-1 px-1"
                  @click="addField()"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                  />
                  <span>Add Field</span>
                </b-button>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="mt-1">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Clone Workflow Task
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import {
  required,
} from '@validations'
import ProcessTask from '@/js/processtask'
import LIST from '@/js/lists'

export default {
  name: 'WorkflowTaskClone',
  components: {
    BCard,
    BFormCheckbox,
    AppTimeline,
    AppTimelineItem,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    BButton,
    draggable,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      addTask: {
        name: '',
        allowed_user_type_ids: [],
        fields: [
          {
            options: [],
            type: '',
          },
        ],
      },
      userTypes: [],
      fieldTypes: [],
    }
  },
  computed: {
    myList: {
      get() {
        return this.addTask.fields
      },
      set(value) {
        this.addTask.fields = value
      },
    },
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getUserTypes(this)
        LIST.getPTaskFieldTypes(this)
      },
    },
  },
  created() {
    LIST.getUserTypes(this)
    LIST.getPTaskFieldTypes(this)
    ProcessTask.getProcessTaskById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          ProcessTask.addProcessTask(this, this.addTask)
        }
      })
    },
    fieldTypeChanged(index) {
      const selectedType = this.fieldTypes.find(type => type.id === this.addTask.fields[index].type_id)
      if (selectedType === null) return
      this.addTask.fields[index].type = selectedType.label
      if (selectedType.label !== 'DropDown') {
        this.addTask.fields[index].options = []
      }
    },
    removeOption(index, fieldIndex) {
      this.addTask.fields[fieldIndex].options.splice(index, 1)
    },
    addOption(fieldIndex) {
      this.addTask.fields[fieldIndex].options.push({
        id: null, option: '',
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    addField() {
      this.addTask.fields.push({
        id: null, options: [], type_id: null, name: '', is_required: false, type: '',
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeField(index) {
      this.addTask.fields.splice(index, 1)
    },
    setUserTypes(userTypes) {
      this.userTypes = userTypes
    },
    setPTaskFieldTypes(fieldTypes) {
      this.fieldTypes = fieldTypes
    },
    setProcessTask(task) {
      this.addTask = task
      for (let index = 0; index < this.addTask.fields.length; index += 1) {
        this.fieldTypeChanged(index)
      }
    },
    processTaskAdded(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    processTaskNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    back() {
      this.$router.push('/administration/workflow-tasks').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.repeater-form {
  transition: .35s height;
}
</style>
