<template>
  <div style="height: inherit;">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />
    <div class="email-app-list email-app-list-custom">

      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center border-bottom-0">

        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>
        <div class="w-100">
          <prospect-matcher-filter-top ref="loadTop" @copyText="copyText" @searchUpdated="searchUpdated" @skillChanged="skillChanged" @stateChanged="stateChanged"/>
        </div>
      </div>
      <vue-good-table
        :columns="columns"
        :rows="prospectsResponse.prospects"
        :rtl="direction"
        @on-selected-rows-change="selectionChanged"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Communication -->
          <span v-if="props.column.field === 'cellRendererCommunication'">
            <b-link
              v-b-tooltip.hover
              title="mail"
              :class="props.row.mails.length === 0 ? 'notes-icon mr-1' : 'mr-1 notes-icon active'"
              @click="showMails(props.row)"
            >
              <feather-icon
                icon="MailIcon"
                size="16"
              />
            </b-link>
            <b-link
              v-b-tooltip.hover
              title="message"
              class="mr-1"
            >
              <feather-icon
                icon="MessageCircleIcon"
                size="16"
              />
            </b-link>
            <b-link
              v-b-tooltip.hover
              title="call"
              class="mr-1"
            >
              <feather-icon
                icon="PhoneIcon"
                size="16"
              />
            </b-link>
            <b-link
              v-b-tooltip.hover
              title="reminder"
              class="mr-1"
            >
              <feather-icon
                icon="MessageSquareIcon"
                size="16"
              />
            </b-link>
            <b-link
              v-b-tooltip.hover
              title="notes"
              id="notes"
              :class="props.row.comments.length === 0 ? 'notes-icon mr-1' : 'notes-icon active mr-1'"
              @click="showNotes(props.row.id)"
            >
              <feather-icon
                icon="FileTextIcon"
                size="16"
              />
            </b-link>
            <b-link
              v-b-tooltip.hover
              :title="props.row.is_stopped ? `Click to contact activate` : `Click to don't contact`"
              :class="props.row.is_stopped ? 'text-danger' : 'text-primary'"
              @click="stopProspect(props.row.id, props.row.is_stopped, props.row.name)"
            >
              <feather-icon
                icon="ThumbsDownIcon"
                size="16"
              />
            </b-link>
          </span>

          <!-- Column: Name -->
          <span v-else-if="props.column.field === 'cellRendererName'">
            <b-link @click="updateProspectViewData(props.row)">
              {{ props.row.name }}
            </b-link>
            <img
              v-show="!props.row.is_registrant"
              class="ml-50 img-fluid"
              width="24"
              height="24"
              :src="require('@/assets/images/icons/not-submitted.png')"
              alt="image slot"
            >
          </span>

          <!-- Column: Email -->
          <span v-else-if="props.column.field === 'cellRendererEmail'">
            <p
              v-b-tooltip.hover
              :title='props.row.email'
              v-b-tooltip.hover.v-primary
              class="content-ellips"
            >{{ props.row.email }}</p>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
          >
            <div class="d-flex justify-content-end flex-wrap">
              <div class="d-flex align-items-center justify-content-end mr-4">
                <strong>Total Emails: {{ emails_count }} / {{ totalProspects }}</strong>
                <b-button
                  v-show="emails_count > 0"
                  variant="primary"
                  size="sm"
                  class="ml-2"
                  @click="copyText"
                >
                  Copy Emails
                </b-button>
              </div>
              <div class="mr-2">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="prospectsResponse.count"
                  first-number
                  last-number
                  align="right"
                  :per-page="1"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-50 mb-50"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
      </vue-good-table>
    </div>
    <b-modal
      ref="add_notes"
      cancel-variant="outline-secondary"
      ok-title="Add"
      cancel-title="Close"
      centered
      title="Add Notes"
      size="lg"
      :no-close-on-backdrop="true"
      @hide="closingNotesPopup"
    >
      <b-form>
        <div class="previous-notes">
          <div class="single-note" v-for="(commentItem, index) in userComments" :id="index" :key="index" >
            <div class="note-author">
              <p v-html="commentItem.commentUserText"></p>
            </div>
          </div>
        </div>
      </b-form>
      <template #modal-footer>
        <div class="chat-app-window chat-app-window-custom w-100">
          <div class="chat-app-form">
            <b-input-group class="input-group-merge form-send-message mr-1">
              <b-form-textarea
                v-model="addNotesRequest.comment"
                placeholder="Enter your notes"
              />
            </b-input-group>
            <b-button
              variant="primary"
              type="submit"
              @click="saveNote()"
            >
              Send
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

    <b-modal
      ref="sent_mails"
      cancel-variant="outline-secondary"
      ok-title="Ok"
      cancel-title="Close"
      centered
      title="Recent Mails Sent On"
      size="lg"
      :no-close-on-backdrop="true"
      @hide="closeSentMailsPopup"
    >
      <template #modal-header>
        <div class="d-flex justify-content-between align-items-center w-100">
          <h5 class="modal-title">Recent Mails Sent On</h5>
          <b-button
            variant="primary"
            type="submit"
            class="mr-1"
            v-show="!addNewMailSent"
            @click="showNewMailSent"
          >
            Add
          </b-button>
        </div>
        <button type="button" @click="closeSentMailsPopup" aria-label="Close" class="close">×</button>
      </template>
      <validation-observer v-if="addNewMailSent" ref="mailSimpleRules">
        <b-form>
          <b-row>
            <b-col cols="4">
              <b-form-group>
                <label>Job Id</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Job Id"
                  rules="integer"
                >
                  <b-form-input
                    id="job_id"
                    v-model="addSentMail.job_id"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group>
                <label>Sent Date</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Sent Date"
                  rules="required"
                >
                  <flat-pickr
                    v-model="sent_date"
                    class="form-control"
                    :config="startDateConfig"
                    placeholder="MM-DD-YYYY"
                    :clear-button="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group>
                <label>Sent Time</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Sent Time"
                  rules="required"
                >
                  <flat-pickr
                    v-model="addSentMail.time"
                    class="form-control"
                    :config="startTimeConfig"
                    :placeholder="'00:00'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <label>Decription</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Decription"
                  rules="required"
                >
                  <b-form-textarea
                    v-model="addSentMail.description"
                    rows="2"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <hr>

          <b-row class="mb-1">
            <b-col>
            </b-col>
            <b-col class="text-right">
              <b-button
                variant="outline-secondary"
                type="button"
                class="mr-1"
                @click="closeSentMail"
              >
                Cancel
              </b-button>
              <b-button
                variant="primary"
                type="button"
                @click="mailSentSubmit"
              >
                Save
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <b-table
        bordered
        hover
        responsive
        class="custom-table-details"
        :items="sentMails"
        :fields="fields"
      />
      <template #modal-footer>
        <b-button
          variant="outline-secondary"
          type="button"
          @click="closeSentMailsPopup"
        >
          Close
        </b-button>
      </template>
    </b-modal>

    <!-- Filter -->
    <!-- <portal to="content-renderer-filter-top">
      <prospect-matcher-filter-top ref="loadTop" @copyText="copyText" @searchUpdated="searchUpdated" @skillChanged="skillChanged" @stateChanged="stateChanged"/>
    </portal> -->

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <prospect-matcher-left-sidebar
        v-show="showLeft"
        ref="loadLeft" @loadProspects="loadProspects"
        :class="{'show': mqShallShowLeftSidebar}"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>

    <job-prospect-matcher-right-sidebar
      ref="loadRight"
      @prospectDeleted="prospectDeleted"
      :class="{'show': showProspectDetails}"
      @close-prospect-view="showProspectDetails = false"
    />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  ref,
} from '@vue/composition-api'
import {
  BForm, BRow, BCol, BPagination, BLink, VBTooltip, BTable, BModal, VBModal, BFormGroup, BInputGroup, BFormInput, BFormTextarea, BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { VueGoodTable } from 'vue-good-table'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import Prospect from '@/js/prospect'
import ProspectMatcher from '@/js/prospectmatcher'
import ParentComments from '@/js/parentcomments'
import Parentmailsent from '@/js/parentmailsent'
import store from '@/store/index'
import Constants from '@/js/constant'
import moment from 'moment'
import ProspectMatcherLeftSidebar from './ProspectMatcherLeftSidebar.vue'
import JobProspectMatcherRightSidebar from './JobProspectMatcherRightSidebar.vue'
import ProspectMatcherFilterTop from './ProspectMatcherFilterTop.vue'

export default {
  name: 'AppProspectMatcher',
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BPagination,
    BTable,
    BLink,
    BModal,
    BButton,
    ProspectMatcherLeftSidebar,
    JobProspectMatcherRightSidebar,
    ProspectMatcherFilterTop,
    VueGoodTable,
    flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  setup() {
    const isTaskHandlerSidebarActive = ref(false)

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    return {
      isTaskHandlerSidebarActive,
      mqShallShowLeftSidebar,
    }
  },
  data() {
    return {
      showProspectDetails: false,
      skills: [],
      userComments: [],
      isVerticalMenuCollapsed: this.$store.state.verticalMenu.isVerticalMenuCollapsed,
      pageLength: 500,
      totalProspects: 0,
      emails_count: 0,
      dir: false,
      showLeft: true,
      prospectId: null,
      prospectsResponse: {
        prospects: [],
        count: 1,
      },
      sentMails: [],
      searchQuery: '',
      city: '',
      selectedRows: [],
      addSentMail: {
        job_id: '',
        user_id: null,
        description: '',
        date: null,
        time: null,
      },
      query: '?',
      columns: [
        {
          label: 'Name',
          field: 'cellRendererName',
          thClass: 'th-width-mx-160',
          sortable: false,
        },
        {
          label: 'Email',
          field: 'cellRendererEmail',
          sortable: false,
        },
        {
          label: 'Phone',
          field: 'phone',
          width: '120px',
          sortable: false,
        },
        {
          label: 'Source Type',
          field: 'source_type',
          sortable: false,
        },
        {
          label: 'Communication',
          field: 'cellRendererCommunication',
          thClass: 'th-mw-195',
          sortable: false,
        },
      ],
      fields: [
        { key: 'job_id', label: 'Job Id' },
        { key: 'skill', label: 'Skill' },
        { key: 'location', label: 'Location' },
        { key: 'description', label: 'Description' },
        { key: 'type', label: 'Type' },
        { key: 'date', label: 'Sent Date', class: 'thMwid110' },
        { key: 'time', label: 'Sent Time', class: 'thMwid110' },
      ],
      addNotesRequest: {
        comment: '',
        user_id: null,
        date: null,
        time: null,
      },
      startDateConfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'm-d-Y',
        altInput: true,
        dateFormat: 'm-d-Y',
        minDate: null,
        maxDate: null,
      },
      startTimeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'h:i K',
      },
      addNewMailSent: false,
      rows: [],
      items: [],
      searchTerm: '',
      start_date_id: null,
      start_time_id: null,
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
        ProspectMatcher.getProspects(this, this.query, this.$store.state.query.page - 1, this.searchQuery)
      },
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.skillId = this.$route.query.skill_id
    this.stateId = this.$route.query.state_id
    this.city = this.$route.query.city
    if ((this.skillId === null || this.skillId === undefined) && (this.stateId === null || this.stateId === undefined)) {
      this.query = '?is_all=true'
      this.showLeft = false
      this.currentPage = 1
    } else if (this.skillId !== null && this.skillId !== undefined && this.stateId !== null && this.stateId !== undefined) {
      this.query = `?skill_id=${this.skillId}&state_id=${this.stateId}&city=${this.city}&group_jobs=true`
      this.showLeft = false
      this.currentPage = 1
    } else {
      this.showLeft = true
    }
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)
  },
  destroyed() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', this.isVerticalMenuCollapsed)
  },
  methods: {
    selectionChanged(params) {
      const prospects = params.selectedRows
      this.selectedprospects = []
      for (let i = 0; i < prospects.length; i += 1) {
        if (prospects[i].email !== 'NA') {
          if (!this.selectedprospects.includes(prospects[i].email)) {
            this.selectedprospects.push(prospects[i].email)
          }
        }
      }
      this.emails_count = this.selectedprospects.length
      this.$refs.loadTop.totalProspects = this.prospectsResponse.prospects.length
      this.totalProspects = this.prospectsResponse.prospects.length
      this.$refs.loadTop.emails_count = this.emails_count
      navigator.clipboard.writeText(this.selectedprospects.toString().replaceAll(',', '; '))
    },
    mailSentSubmit() {
      this.$refs.mailSimpleRules.validate().then(result => {
        if (result) {
          this.addSentMail.date = Constants.getEprmMonthDateFromString(this.sent_date)
          Parentmailsent.addMailSent(this, this.addSentMail)
        }
      })
    },
    sentMailNotAdded(message) {
      this.$swal({
        icon: 'warning',
        title: 'Mail Sent Not Added!',
        text: message,
      })
    },
    prospectStopped(body) {
      ProspectMatcher.getProspectsWithoutLoading(this, this.query, this.$store.state.query.page - 1, this.searchQuery)
      this.$swal({
        icon: 'success',
        title: 'Success',
        text: body.return_message,
      })
    },
    stopProspect(id, stopped, name) {
      const action = stopped ? 'activate' : 'stop'
      const message = `Are you sure you want to ${action} contact with this prospect, ${name}?`
      const swalConfig = {
        title: 'Are you sure?',
        text: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Yes, ${action}`,
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }

      this.$swal(swalConfig).then(result => {
        if (result.value) {
          Prospect.stopProspect(this, id, stopped)
        }
      })
    },
    setMailSents(data, id) {
      this.prospectsResponse.prospects = this.prospectsResponse.prospects.map(prospect => {
        if (prospect.id === id) {
          return { ...prospect, mails: data.mails }
        }
        return prospect
      })
      this.sentMails = data.mails
      this.addSentMail = {
        job_id: '',
        user_id: null,
        description: '',
        date: null,
        time: null,
      }
      this.addNewMailSent = false
      this.addSentMail.user_id = id
      this.$swal({
        icon: 'success',
        title: 'Mail Sent Added!',
        text: data.return_message,
      })
    },
    showNewMailSent() {
      this.addNewMailSent = true
      this.sent_date = moment().tz('America/Chicago').format('MM-DD-YYYY')
      this.addSentMail.time = moment().tz('America/Chicago').format('hh:mm a')
    },
    copyText() {
      this.$bvToast.toast(`${this.emails_count} emails copied`, {
        title: 'Copied!',
        variant: 'primary',
        solid: true,
        toaster: 'b-toaster-bottom-right',
      })
      navigator.clipboard.writeText(this.selectedprospects.toString().replaceAll(',', '; '))
    },
    prospectDeleted() {
      this.$refs.loadLeft.reloadData()
      ProspectMatcher.getProspects(this, this.query, this.$store.state.query.page - 1, this.searchQuery)
    },
    updateProspectViewData(data) {
      this.showProspectDetails = true
      this.$refs.loadRight.setProspectDetails(data)
    },
    loadProspects(query) {
      this.query = query
      this.currentPage = 1
    },
    skillChanged(skillId) {
      this.$refs.loadLeft.skillChanged(skillId)
    },
    stateChanged(stateId) {
      this.$refs.loadLeft.stateChanged(stateId)
    },
    searchUpdated(query) {
      this.searchQuery = query
      this.currentPage = 1
    },
    showMails(row) {
      this.sentMails = row.mails
      this.addSentMail.user_id = row.id
      this.$refs.sent_mails.show()
    },
    closeSentMailsPopup() {
      this.sentMails = []
      this.$refs.sent_mails.hide()
      this.addSentMail = {
        job_id: '',
        user_id: null,
        description: '',
        date: null,
        time: null,
      }
      this.addNewMailSent = false
    },
    closeSentMail() {
      const id = this.addSentMail.user_id
      this.addSentMail = {
        job_id: '',
        user_id: null,
        description: '',
        date: null,
        time: null,
      }
      this.addNewMailSent = false
      this.addSentMail.user_id = id
    },
    showNotes(id) {
      this.userComments = []
      this.$refs.add_notes.show()
      ParentComments.getComments(this, id)
      this.addNotesRequest.user_id = id
    },
    closingNotesPopup() {
      this.userComments = []
      this.addNotesRequest = {
        comment: '',
        user_id: null,
        date: null,
        time: null,
      }
    },
    saveNote() {
      this.addNotesRequest.comment = this.addNotesRequest.comment.trim()
      if (this.addNotesRequest.comment === '') {
        return
      }
      this.addNotesRequest.date = Constants.getEprmMonthDateFromString(moment())
      this.addNotesRequest.time = moment().format('hh:mm a')
      ParentComments.addComment(this, this.addNotesRequest.user_id, this.addNotesRequest)
    },
    setParentComments(userComments, userId) {
      this.userComments = userComments.comments
      this.addNotesRequest.comment = ''
      this.prospectsResponse.prospects = this.prospectsResponse.prospects.map(prospect => {
        if (prospect.id === userId) {
          return { ...prospect, comments: userComments.comments }
        }
        return prospect
      })
    },
    setProspects(prospectsResponse) {
      this.prospectsResponse = prospectsResponse
      this.$refs.loadTop.totalProspects = this.prospectsResponse.prospects.length
      this.totalProspects = this.prospectsResponse.prospects.length
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
@import "@core/scss/vue/libs/tree.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
