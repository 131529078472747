<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Offer Letter</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <b-form >
      <b-row class="mt-1">
        <b-col
          cols="12"
          md="10 ml-auto mr-auto"
        >
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <div class="msa-form">
                  <div class="text-right mb-4">
                    <b-img
                      fluid
                      :src="signatureData.logo_path"
                      alt="Company Logo"
                    />
                  </div>
                  <span v-html="signatureData.content"></span>
                  <div class="content-footer">
                    <b-row>
                      <b-col cols="6">
                        <p>Sincerely,</p>
                        <div class="mt-2 mb-50">
                          <span class="d-block signature-line">____________________________________</span>
                          <span class="small d-block">(Signature)</span>
                        </div>
                        <p>{{ signatureData.company_user_name }}</p>
                        <p>{{ signatureData.company_user_title }}</p>
                        <div class="address-width-250">
                          <p>{{ signatureData.company_address }}</p>
                        </div>
                        <p class="mt-2">Employee Signature:</p>
                        <b-button v-show="signatureData.receiver_signature === null" class="btn-outline-warning mb-2" variant="flat-primary">
                          Signature
                        </b-button>
                        <div class="mt-2 mb-50">
                          <span class="d-block signature-text" :style="'font-family:' + signatureData.receiver_signature_font ">{{ signatureData.receiver_signature }}</span>
                          <span class="d-block signature-line">____________________________________</span>
                          <span class="small d-block">(Signature)</span>
                        </div>
                        <p>Printed Name: {{ signatureData.data.first_name }} {{  signatureData.data.last_name }}</p>
                        <p v-show="signatureData.receiver_sign_date !== null">Date: {{ signatureData.receiver_sign_date }}</p>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BButton, BImg,
} from 'bootstrap-vue'
import Esignatures from '@/js/esignatures'

export default {
  name: 'OfferLetterDocument',
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BImg,
    BButton,
  },
  setup() {
  },
  data() {
    return {
      accept_consignment: false,
      signatureData: {
        data: {},
      },
      fontTypes: [{ label: 'Bogoritmaa', id: 'Bogoritmaa' }, { label: 'Tyloos', id: 'Tyloos' }, { label: 'Shanghai', id: 'Shanghai' }],
    }
  },
  watch: {
  },
  mounted() {
  },
  created() {
    Esignatures.getDocumentSentById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    setDocumentDetails(data) {
      this.signatureData = data
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@font-face {
    font-family: 'Bogoritmaa';
    src: url('../../../../../fonts/Bogoritmaa.ttf') format('truetype'),
}
@font-face {
    font-family: 'Tyloos';
    src: url('../../../../../fonts/Tyloos.ttf') format('truetype'),
}
@font-face {
    font-family: 'Shanghai';
    src: url('../../../../../fonts/Shanghai.ttf') format('truetype'),
}
focused {
  filter: brightness(1.5);
}
</style>
