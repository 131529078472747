import Reports from '@/views/forecasting/reports/Reports.vue'
import MonthlyProjectionReport from '@/views/forecasting/reports/actions/MonthlyProjectionReport.vue'
import MonthlyIncomeProjectionReport from '@/views/forecasting/reports/actions/MonthlyIncomeProjectionReport.vue'
import MonthlyExpensesProjectionReport from '@/views/forecasting/reports/actions/MonthlyExpensesProjectionReport.vue'
import ActualMonthlyReport from '@/views/forecasting/reports/actions/ActualMonthlyReport.vue'
import ActualMonthlyIncomeReport from '@/views/forecasting/reports/actions/ActualMonthlyIncomeReport.vue'
import ActualMonthlyExpensesReport from '@/views/forecasting/reports/actions/ActualMonthlyExpensesReport.vue'
import MissingTimesheetsReport from '@/views/forecasting/reports/actions/MissingTimesheetsReport.vue'
import MissingInvoicesReport from '@/views/forecasting/reports/actions/MissingInvoicesReport.vue'
import InvoicePaymentDueDateReport from '@/views/forecasting/reports/actions/InvoicePaymentDueDateReport.vue'
import JobsByPriorityReport from '@/views/forecasting/reports/actions/JobsByPriorityReport.vue'
import JobsByPriorityNoGuaranteeReport from '@/views/forecasting/reports/actions/JobsByPriorityNoGuaranteeReport.vue'
import JobsByNoProspectsReport from '@/views/forecasting/reports/actions/JobsByNoProspectsReport.vue'
import ConsultantsByFacility from '@/views/forecasting/reports/actions/ConsultantsByFacility.vue'
import ProspectsAdminJobView from '@/views/human-resources/prospects-admin/jobs/actions/View.vue'

export default [
  // *===============================================---*
  // *--------- Forecasting -------------------------------*
  // *===============================================---*
  // *------------ Reports --------------------*
  {
    path: '/forecasting/reports',
    name: 'forecasting-reports',
    component: Reports,
    meta: {
      pageTitle: 'Reports',
    },
  },
  // *------------ Monthly Projection Report --------------------*
  {
    path: '/forecasting/reports/monthly-projection-report',
    name: 'forecasting-reports-monthly-projection-report',
    component: MonthlyProjectionReport,
    meta: {
      navActiveLink: 'forecasting-reports',
    },
  },
  // *------------ Monthly Income Projection Report --------------------*
  {
    path: '/forecasting/reports/monthly-income-projection-report',
    name: 'forecasting-reports-monthly-income-projection-report',
    component: MonthlyIncomeProjectionReport,
    meta: {
      navActiveLink: 'forecasting-reports',
    },
  },
  // *------------ Monthly Expenses Projection Report --------------------*
  {
    path: '/forecasting/reports/monthly-expenses-projection-report',
    name: 'forecasting-reports-monthly-expenses-projection-report',
    component: MonthlyExpensesProjectionReport,
    meta: {
      navActiveLink: 'forecasting-reports',
    },
  },
  // *------------ Actual Monthly Report --------------------*
  {
    path: '/forecasting/reports/actual-monthly-report',
    name: 'forecasting-reports-actual-monthly-report',
    component: ActualMonthlyReport,
    meta: {
      navActiveLink: 'forecasting-reports',
    },
  },
  // *------------ Actual Monthly Income Report --------------------*
  {
    path: '/forecasting/reports/actual-monthly-income-report',
    name: 'forecasting-reports-actual-monthly-income-report',
    component: ActualMonthlyIncomeReport,
    meta: {
      navActiveLink: 'forecasting-reports',
    },
  },
  // *------------ Actual Monthly Expenses Report --------------------*
  {
    path: '/forecasting/reports/actual-monthly-expenses-report',
    name: 'forecasting-reports-actual-monthly-expenses-report',
    component: ActualMonthlyExpensesReport,
    meta: {
      navActiveLink: 'forecasting-reports',
    },
  },
  // *------------ Consultants By Facility --------------------*
  {
    path: '/forecasting/reports/facility-consultants',
    name: 'forecasting-reports-facility-consultants',
    component: ConsultantsByFacility,
    meta: {
      navActiveLink: 'forecasting-reports',
    },
  },
  // *------------ Missing Timesheets Report --------------------*
  {
    path: '/forecasting/reports/missing-timesheets',
    name: 'forecasting-reports-missing-timesheets',
    component: MissingTimesheetsReport,
    meta: {
      navActiveLink: 'forecasting-reports',
    },
  },
  // *------------ Missing Invoices Report --------------------*
  {
    path: '/forecasting/reports/missing-invoices',
    name: 'forecasting-reports-missing-invoices',
    component: MissingInvoicesReport,
    meta: {
      navActiveLink: 'forecasting-reports',
    },
  },
  // *------------ Invoice Payment Due Date Report --------------------*
  {
    path: '/forecasting/reports/invoice-payment-due-date',
    name: 'forecasting-reports-invoice-payment-due-date',
    component: InvoicePaymentDueDateReport,
    meta: {
      navActiveLink: 'forecasting-reports',
    },
  },
  // *------------ Jobs by Priority Report --------------------*
  {
    path: '/forecasting/reports/jobs-by-priority',
    name: 'forecasting-reports-jobs-by-priority',
    component: JobsByPriorityReport,
    meta: {
      navActiveLink: 'forecasting-reports',
      contentClass: 'email-application',
    },
  },
  {
    path: '/forecasting/reports/jobs-by-priority-no-guarantee',
    name: 'forecasting-reports-jobs-by-priority-no-guarantee',
    component: JobsByPriorityNoGuaranteeReport,
    meta: {
      navActiveLink: 'forecasting-reports',
    },
  },
  // *------------ Jobs by No Prospects Report --------------------*
  {
    path: '/forecasting/reports/jobs-by-no-prospects',
    name: 'forecasting-reports-jobs-by-no-prospects',
    component: JobsByNoProspectsReport,
    meta: {
      navActiveLink: 'forecasting-reports',
    },
  },
  {
    path: '/forecasting/reports/jobs-by-no-prospects/jobs/view/:id',
    name: 'forecasting-reports-jobs-no-prospects-view',
    component: ProspectsAdminJobView,
    meta: {
      navActiveLink: 'forecasting-reports-jobs-no-prospects-view',
    },
  },
  {
    path: '/forecasting/reports/jobs-by-priority-no-guarantee/jobs/view/:id',
    name: 'forecasting-reports-jobs-no-guarantee-view',
    component: ProspectsAdminJobView,
    meta: {
      navActiveLink: 'forecasting-reports-jobs-no-guarantee-view',
    },
  },
  {
    path: '/forecasting/reports/jobs-by-priority/jobs/view/:id',
    name: 'forecasting-reports-jobs-view',
    component: ProspectsAdminJobView,
    meta: {
      navActiveLink: 'forecasting-reports-jobs-view',
    },
  },
]
