import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.faurl
const balanceSheet = `${HOST}balance-sheet`

export default {
  getBalanceSheetDetails(context1, date) {
    const context = context1
    const params = { params: { date } }
    axios.get(balanceSheet, params).then(response => {
      context.setStatement(response.data)
    }, () => {
    })
  },
  addBalanceSheet(context1, body, save) {
    const context = context1
    context.$vs.loading()
    const params = { params: { save } }
    axios.post(balanceSheet, body, params).then(response => {
      context.$vs.loading.close()
      context.balanceSheetSaved(response.data)
    }, error => {
      context.$vs.loading.close()
      context.balanceSheetNotSaved(error.response.data.return_message)
    })
  },
}
