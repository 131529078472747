import axios from 'axios'
import moment from 'moment'
import Constants from '@/js/constant'

const HOST = Constants.hosts.faurl
const invoiceVendor = `${HOST}invoice-vendor`

export default {
  getInvoices(context1, vendorId) {
    const context = context1
    const queryParams = { query: context.$store.state.query.query, page: context.$store.state.query.page - 1, vendor_id: vendorId }
    if (context.$store.state.query.month !== null && context.$store.state.query.month !== '') {
      queryParams.start_date = 1
      queryParams.start_month = moment(context.$store.state.query.month, 'MMM-YYYY').format('MM')
      queryParams.start_year = moment(context.$store.state.query.month, 'MMM-YYYY').format('YYYY')
    }
    const params = { params: queryParams }
    axios.get(invoiceVendor, params).then(response => {
      context.setInvoices(response.data)
    }, () => {
    })
  },

  getVendorInvoices(context1) {
    const context = context1
    context.$vs.loading()
    let params = { query: context.searchQuery, page: context.currentPage - 1 }
    if (params !== {}) {
      params = { params: { query: context.searchQuery, page: context.currentPage - 1 } }
    } else {
      params = { params: {} }
    }
    axios.get(invoiceVendor, params).then(response => {
      context.$vs.loading.close()
      context.setVendorInvoices(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  updateInvoice(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${invoiceVendor}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.invoiceUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.invoiceNotUpdated(error.response.data.return_message)
    })
  },
  addVendorInvoice(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(invoiceVendor, body).then(response => {
      context.$vs.loading.close()
      context.vendorInvoiceAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.vendorInvoiceNotAdded(error.response.data.return_message)
    })
  },
  updateVendorInvoice(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${invoiceVendor}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.vendorInvoiceUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.vendorInvoiceNotUpdated(error.response.data.return_message)
    })
  },
  sendInvoices(context1, data) {
    const context = context1
    context.$vs.loading()
    axios.post(`${invoiceVendor}/mass_communication`, data).then(response => {
      context.$vs.loading.close()
      context.created(response.data.return_message)
    }, error => {
      context.$vs.loading.close()
      context.creatError(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${invoiceVendor}/${id}`).then(response => {
      context.$vs.loading.close()
      context.invoiceDeleted(response.data)
    }, error => {
      context.$vs.loading.close()
      context.invoiceNotDeleted(error.response.data.return_message)
    })
  },
  getVendorInvoiceById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${invoiceVendor}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setVendorInvoice(response.data)
    }, error => {
      context.$vs.loading.close()
      context.vendorInvoiceNotFound(error.response.data.return_message)
    })
  },

}
