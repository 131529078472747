<template>
  <b-card
    class="mb-0"
  >
    <div class="mb-2">
      <b-row>
        <b-col
          cols="12"
          md="2"
        >
          <h4 class="card-title mb-0 pt-50">
            Expenses</h4>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <b-input-group>
              <flat-pickr
                v-model="month"
                class="form-control"
                :config="$store.state.monthConfig"
                placeholder="Select Month"
                :clear-button="true"
                @on-change="monthDateChanged"
              />
              <b-input-group-append>
                <b-button
                  class="px-1 outline-grey"
                  variant="outline-secondary"
                  @click="removeMonthDate"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="7 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                @keyup.enter="updateSearchQuery"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="updateSearchQuery"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="14"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button
              variant="primary"
              class="ml-2 min-w-140"
              :to="{ name: 'finance-and-accounting-expenses-new' }"
            >
              Add Expense
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-row>
      <b-col cols="12">
        <vue-good-table
          :columns="columns"
          :rows="expensesResponse.expenses"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >

          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Currency -->
            <span v-if="props.column.field === 'cellRendererCurrency'">
              <span>{{ props.row.amount| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'status'">
              <b-badge :variant="statusVariant(props.row.status)">
                {{ props.row.status }}
              </b-badge>
            </span>

            <!-- Column: Action -->
            <span
              v-else-if="props.column.field === 'action'"
              class="btn-action"
            >
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item :to="{ name: 'finance-and-accounting-expenses-edit', params: { id: props.row.id } }">
                    <feather-icon icon="Edit2Icon" />
                    <span class="ml-50">Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="confirmDeleteRecord(props.row)">
                    <feather-icon icon="TrashIcon" />
                    <span class="ml-50">Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
                <b-link
                  id="view"
                  :to="{ name: 'finance-and-accounting-expenses-view', params: { id: props.row.id } }"
                >
                  <b-badge
                    pill
                    variant="light-primary"
                  ><feather-icon icon="EyeIcon" />
                  </b-badge>
                </b-link>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
          >
            <div class="d-flex justify-content-end flex-wrap">
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="expensesResponse.count"
                  first-number
                  last-number
                  align="right"
                  :per-page="1"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BBadge, BPagination, BInputGroup, BFormInput, BFormGroup, BInputGroupAppend, BDropdown, BDropdownItem, BButton, BLink,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import flatPickr from 'vue-flatpickr-component'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import store from '@/store/index'
import moment from 'moment'
import Expenses from '@/js/expenses'

export default {
  name: 'ExpensesList',
  components: {
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    BBadge,
    BPagination,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BInputGroupAppend,
    BButton,
    BLink,
    BDropdown,
    BDropdownItem,
    flatPickr,
  },
  data() {
    return {
      searchQuery: '',
      pageLength: 10,
      dir: false,
      month: null,
      expensesResponse: {
        expenses: [],
        count: 1,
      },
      columns: [
        {
          label: '#',
          field: 'index',
          width: '100px',
        },
        {
          label: 'Name',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Payment Type',
          field: 'payment_type',
          width: '150px',
          sortable: false,
        },
        {
          label: 'Amount',
          field: 'cellRendererCurrency',
          width: '160px',
          sortable: false,
        },
        {
          label: 'Paid Date',
          field: 'date',
          width: '140px',
          sortable: false,
        },
        {
          label: 'Recurring',
          field: 'recurrring',
          width: '120px',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          width: '120px',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      configs: {
        monthSelect: {
          plugins: [
            monthSelectPlugin({
              shorthand: true,
              dateFormat: 'M-Y',
              altFormat: 'F, Y',
            }),
          ],
        },
        start: {
          format: 'MMM-YYYY',
          useCurrent: false,
          showClear: false,
          showClose: false,
          minDate: moment('01-01-2000', 'MM-DD-YYYY'),
        },
      },
      status: [{
        1: 'Active',
        2: 'Inactive',
        3: 'Terminated',
      },
      {
        1: 'light-success',
        2: 'light-danger',
        3: 'light-info',
      }],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Active      : 'light-success',
        Inactive    : 'light-danger',
        Terminated  : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
        Expenses.getExpenses(this)
      },
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.searchQuery = this.$store.state.query.query
    this.month = this.$store.state.query.month
    this.expensesResponse.count = this.$store.state.query.page
    Expenses.getExpenses(this)
  },
  methods: {
    confirmDeleteRecord(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${obj.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          Expenses.delete(this, obj.id)
        }
      })
    },
    expenseDeleted() {
      Expenses.getExpenses(this)
      this.$swal({
        icon: 'success',
        title: 'Expense Deleted!',
        text: 'Your Expense has been deleted',
      })
    },
    expenseNotDeleted() {
      this.$swal({
        icon: 'warning',
        title: 'Expense Not Deleted!',
        text: 'Your Expense has been not deleted',
      })
    },
    monthDateChanged(config, date) {
      this.month = date
      this.$store.state.query.month = this.month
      this.currentPage = 1
    },
    removeMonthDate() {
      this.month = null
      this.$store.state.query.month = this.month
      this.currentPage = 1
    },
    updateSearchQuery() {
      this.$store.state.query.query = this.searchQuery
      this.currentPage = 1
    },
    setExpenses(expensesResponse) {
      this.expensesResponse = expensesResponse
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
