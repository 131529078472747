import Constants from '@/js/constant'
import axios from 'axios'

const HOST = Constants.hosts.url
const employeeDailyUpdate = `${HOST}employee-daily-update`

export default {
  getDailyUpdates(context) {
    const params = { params: { employee_id: context.employee_id, query: context.searchQuery, page: context.currentPage - 1 } }
    axios.get(employeeDailyUpdate, params).then(response => {
      context.$vs.loading.close()
      context.setDailyUpdates(response.data)
    }, () => {
    })
  },
  addDailyUpdate(context, body) {
    axios.post(employeeDailyUpdate, body).then(response => {
      context.$vs.loading.close()
      context.dailyUpdateAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.dailyUpdateNotAdded(error.response.data.return_message)
    })
  },
  updateDailyUpdate(context, id, body) {
    axios.put(`${employeeDailyUpdate}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.dailyUpdateUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.dailyUpdateNotUpdated(error.response.data.return_message)
    })
  },
  delete(context, id) {
    axios.delete(`${employeeDailyUpdate}/${id}`).then(response => {
      context.$vs.loading.close()
      context.dailyUpdateDeleted(response.data)
    }, error => {
      context.$vs.loading.close()
      context.dailyUpdateNotDeleted(error.response.data.return_message)
    })
  },
  getDailyUpdateById(context, id) {
    axios.get(`${employeeDailyUpdate}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setDailyUpdate(response.data)
    }, error => {
      context.$vs.loading.close()
      context.dailyUpdateNotFound(error.response.data.return_message)
    })
  },
}
