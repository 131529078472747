<template>
  <b-card>
    <b-row class="mb-1">
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          PayRoll Report</h4>
      </b-col>
      <b-col
        cols="12"
        md="2 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="4"
        class="col-xxl-3"
      >
        <b-form-group
          label="From"
          label-cols-md="3"
          class="text-right"
        >
          <flat-pickr
            v-model="from_date"
            class="form-control"
            :config="startDateConfig"
            placeholder="MM-DD-YYYY"
            clear-button="true"
            @on-change="startDateChanged"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
        class="col-xxl-3"
      >
        <b-form-group
          label="To"
          label-cols-md="3"
          class="text-right"
        >
          <flat-pickr
            v-model="to_date"
            class="form-control"
            :config="endDateConfig"
            placeholder="MM-DD-YYYY"
            clear-button="true"
            @on-change="endDateChanged"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="2 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="primary"
            class="mr-1"
            @click="getReport"
          >
            Get
          </b-button>
          <b-button
            variant="primary"
            @click="getPdfReport"
          >
            PDF
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <b-row class="invoice-preview">
      <b-col
        cols="12"
        class="col-xxl-8 offset-xxl-2"
      >
        <b-card-body class="px-0 pt-0">
          <table class="table b-table table-bordered">
            <thead>
              <tr>
                <th>Name</th>
                <th class="th-w-230">
                  Period</th>
                <th class="th-w-140">
                  Paid Date</th>
                <th class="th-w-160 text-right">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(report, index) in reportResponse.account_reports"
                :key="index"
              >
                <td>{{ report.name }}</td>
                <td>{{ report.period }}</td>
                <td>{{ report.paid_date }}</td>
                <td class="text-right">
                  {{ report.amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BFormGroup, BCardBody,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import Reports from '@/js/financialstatementreports'
import moment from 'moment'

export default {
  name: 'PayRollReport',
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    flatPickr,
  },
  directives: {
  },
  setup() {
  },
  data() {
    return {
      from_date: null,
      to_date: null,
      reportResponse: {},
      startDateConfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'm-d-Y',
        altInput: true,
        dateFormat: 'm-d-Y',
        minDate: new Date(2000, 1, 1),
      },
      endDateConfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'm-d-Y',
        altInput: true,
        dateFormat: 'm-d-Y',
        minDate: new Date(2000, 1, 1),
      },
    }
  },
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
  methods: {
    startDateChanged() {
      this.$set(this.endDateConfig, 'minDate', new Date(moment(this.from_date).format('MM-DD-YYYY')))
    },
    endDateChanged() {
      this.$set(this.startDateConfig, 'maxDate', new Date(moment(this.to_date).format('MM-DD-YYYY')))
    },
    getReport() {
      if (this.from_date !== null && this.to_date !== null) {
        Reports.getAccountPayrollReport(this, this.from_date, this.to_date)
      }
    },
    getPdfReport() {
      if (this.from_date !== null && this.to_date !== null) {
        Reports.getAccountPayrollReportPdf(this, this.from_date, this.to_date)
      }
    },
    setReport(reportResponse) {
      this.reportResponse = reportResponse
    },
    pdfPath(data) {
      window.open(data.pdf_path, '_blank')
    },
    pdfNotFound(message) {
      this.$swal({
        icon: 'warning',
        title: 'Resend!',
        text: message,
      })
    },
    back() {
      this.$router.push('/financial-statements/reports').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
