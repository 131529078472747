var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('h4',{staticClass:"card-title mb-0 pt-50"},[_vm._v(" Add Document")])]),_c('b-col',{attrs:{"cols":"12","md":"6 ml-auto"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.back}},[_vm._v(" Back ")])],1)])],1),_c('hr'),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('app-timeline',[_c('app-timeline-item',{staticClass:"pb-1"},[_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[(_vm.showList)?_c('b-form-group',[_c('label',[_vm._v("Type")]),_c('validation-provider',{attrs:{"name":"Type","rules":_vm.showList ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"left w-100"},[_c('v-select',{attrs:{"id":"document_name_id","clearable":true,"placeholder":"Select","options":_vm.documentNames,"reduce":function (item) { return item.id; }},model:{value:(_vm.addDocument.document_name_id),callback:function ($$v) {_vm.$set(_vm.addDocument, "document_name_id", $$v)},expression:"addDocument.document_name_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1),_c('div',{staticClass:"right ml-50"},[_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"variant":"outline-primary"},on:{"click":_vm.addNewName}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1)])]}}],null,false,216630381)})],1):_vm._e(),(_vm.showName)?_c('b-form-group',[_c('label',[_vm._v("Document Name")]),_c('validation-provider',{attrs:{"name":"Document Name","rules":_vm.showName ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"left w-100"},[_c('b-form-input',{attrs:{"id":"document_name","state":errors.length > 0 ? false:null},model:{value:(_vm.addDocument.document_name),callback:function ($$v) {_vm.$set(_vm.addDocument, "document_name", $$v)},expression:"addDocument.document_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1),_c('div',{staticClass:"right ml-50"},[_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"variant":"outline-danger"},on:{"click":_vm.showListOption}},[_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],1)],1)])]}}],null,false,2845108725)})],1):_vm._e()],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Attachment")]),_c('input',{ref:"myFileInput",staticClass:"form-control text-left",attrs:{"type":"file","placeholder":"Choose a file or drop it here..."},on:{"change":_vm.attahamentFileSelected}})])],1),_c('b-col',{attrs:{"cols":"12","md":"8"}},[_c('b-form-group',[_c('label',[_vm._v("Description")]),_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","rows":"2"},model:{value:(_vm.addDocument.description),callback:function ($$v) {_vm.$set(_vm.addDocument, "description", $$v)},expression:"addDocument.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('app-timeline-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.addDocument.user_defined_fields.length !== 0),expression:"addDocument.user_defined_fields.length !== 0"}],staticClass:"pb-1",attrs:{"variant":"success"}},[_c('div',{staticClass:"d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"},[_c('h6',[_vm._v("User Defined Fields")])]),_c('b-row',{staticClass:"mt-1"},_vm._l((_vm.addDocument.user_defined_fields),function(field){return _c('b-col',{key:field.id,attrs:{"id":field.id,"cols":"12","md":"6","lg":"4"}},[(field.type.toLowerCase() === 'input')?_c('b-form-group',[_c('label',[_vm._v(_vm._s(field.name))]),_c('validation-provider',{attrs:{"name":field.name,"rules":field.required ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(field.type.toLowerCase() === 'dropdown')?_c('b-form-group',[_c('label',[_vm._v(_vm._s(field.name))]),_c('validation-provider',{attrs:{"name":field.name,"rules":field.required ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":field.options,"label":"option","reduce":function (item) { return item.option; },"clearable":!field.required,"placeholder":"Select"},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(field.type.toLowerCase() === 'textarea')?_c('b-form-group',[_c('label',[_vm._v(_vm._s(field.name))]),_c('validation-provider',{attrs:{"name":field.name,"rules":field.required ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"rows":"2"},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1)}),1)],1)],1),_c('hr'),_c('b-row',{staticClass:"ml-2"},[_c('b-col',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"primary","type":"button"},on:{"click":_vm.submitForm}},[_vm._v(" Add Document ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }