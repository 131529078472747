<template>
  <div style="height: inherit;">
    <div class="email-app-list email-app-list-custom email-app-view-custom">
      <b-card
       no-shadow
       no-body
       v-show="assignJob.name !== undefined"
      >
        <b-card-header>
          <h4 class="card-title mb-0 pt-50">
            {{ assignJob.name }} ({{ assignJob.role }})</h4>
          <div class="d-flex align-items-center justify-content-end">
            <b-link
              id="delete"
              class="mr-2"
              @click="confirmDeleteRecord(assignJob)"
            >
              <feather-icon
                icon="Trash2Icon"
                size="18"
                class="text-danger"
              />
            </b-link>
            <b-link
              id="edit"
              @click="editCriteria(assignJob.id)"
            >
              <feather-icon
                icon="EditIcon"
                size="18"
                class="text-primary"
              />
            </b-link>
          </div>
        </b-card-header>
        <hr class="mt-0">
        <b-card-body class="pt-0 pr-0">
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="email-scroll-area email-scroll-area-custom scroll-area p-0 pr-2"
          >
            <app-timeline>
              <app-timeline-item
                variant="primary"
                class="pb-1"
              >
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h6>Criteria</h6>
                </div>
                <b-row
                  v-for="jobsCriteria in assignJob.job_criterias"
                  :key="jobsCriteria"
                >
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>Facility</label>
                      <span
                        class="form-control h-auto"
                        v-text="jobsCriteria.facility"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>Main Skill</label>
                      <span
                        class="form-control h-auto"
                        v-text="jobsCriteria.skill"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>State</label>
                      <span
                        class="form-control h-auto"
                        v-text="jobsCriteria.state"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>City</label>
                      <span
                        class="form-control h-auto"
                        v-text="jobsCriteria.city"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-timeline-item>
            </app-timeline>
          </vue-perfect-scrollbar>
        </b-card-body>
      </b-card>
      <b-card
       no-shadow
       no-body
       v-show="jobsNotAssigned.name !== undefined"
      >
        <b-card-header>
          <h4 class="card-title mb-0 pt-50">
            {{ jobsNotAssigned.name }} ({{ jobsNotAssigned.role }})</h4>
        </b-card-header>
        <hr class="mt-0">
        <b-card-body class="pt-0">
          <app-timeline>
            <app-timeline-item
              variant="primary"
              class="pb-1"
            >
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6>Criteria</h6>
              </div>
              <b-row>
                <b-col cols="12">
                  <b-form-group>
                    <label>No assign jobs available</label>
                  </b-form-group>
                </b-col>
              </b-row>
            </app-timeline-item>
          </app-timeline>
        </b-card-body>
      </b-card>
    </div>

    <!-- Filter -->
    <portal to="content-renderer-filter-top">
      <assign-jobs-filter-top ref="loadTop"/>
    </portal>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <assign-jobs-left-sidebar
        v-show="showLeft"
        ref="loadLeft" @loadCriterias="loadCriterias"
        :class="{'show': mqShallShowLeftSidebar}"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormGroup, BLink,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import AssignJobCriteria from '@/js/assignjobcriteria'
import store from '@/store/index'
import AssignJobsLeftSidebar from './AssignJobsLeftSidebar.vue'
import AssignJobsFilterTop from './AssignJobsFilterTop.vue'

export default {
  name: 'AssignJobsList',
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BFormGroup,
    BLink,
    AssignJobsLeftSidebar,
    AssignJobsFilterTop,
    VuePerfectScrollbar,
  },
  data() {
    return {
      showLeft: true,
      searchTerm: '',
      mqShallShowLeftSidebar: true,
      assignJob: {
        job_criterias: [],
      },
      jobsNotAssigned: {
        job_criterias: [],
      },
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      columns: [
        {
          label: 'User Name',
          field: 'userName',
          sortable: false,
        },
        {
          label: 'Criteria',
          field: 'criteria',
          sortable: false,
        },
        {
          label: 'Actions',
          field: 'action',
          sortable: false,
          width: '120px',
        },
      ],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
  },
  methods: {
    loadCriterias(id) {
      AssignJobCriteria.getUserJobCriteriaByUserId(this, id)
    },
    setCriteria(assignJob) {
      this.assignJob = assignJob
      this.jobsNotAssigned = {}
    },
    setCriteriaNotFound(jobsNotAssigned) {
      this.assignJob = {}
      this.jobsNotAssigned = jobsNotAssigned
    },
    editCriteria(id) {
      this.$router.push(`/administration/assign-jobs/edit/${id}`).catch(() => {})
    },
    confirmDeleteRecord(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete Assign jobs criteria for ${obj.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          AssignJobCriteria.delete(this, obj.id)
        }
      })
    },
    criteriaDeleted(message) {
      this.jobsNotAssigned.name = this.assignJob.name
      this.jobsNotAssigned.role = this.assignJob.role
      this.jobsNotAssigned.job_criterias = []
      this.assignJob = {}
      this.$swal({
        icon: 'success',
        title: 'Success!',
        text: message,
      })
    },
    criteriaNotDeleted(message) {
      this.$swal({
        icon: 'warning',
        title: 'Failure!',
        text: message,
      })
    },
  },
}
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
@import "@core/scss/vue/libs/tree.scss";
</style>
