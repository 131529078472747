var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('h4',{staticClass:"card-title mb-0 pt-50"},[_vm._v(" Add Document Tracker")])]),_c('b-col',{attrs:{"cols":"12","md":"6 ml-auto"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.back}},[_vm._v(" Back ")])],1)])],1),_c('hr'),_c('validation-observer',{ref:"documentRules",attrs:{"tag":"form"}},[_c('b-form',[_c('app-timeline',[_c('app-timeline-item',{staticClass:"pb-1"},[_c('div',{staticClass:"d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"},[_c('h6',[_vm._v("Parent Details")])]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Parent Type")]),_c('validation-provider',{attrs:{"name":"Parent Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"parent_type_id","options":_vm.types,"reduce":function (item) { return item.parent_type_id; },"clearable":false,"placeholder":"Select"},on:{"input":_vm.onTypeChanged},model:{value:(_vm.addDocument.parent_type_id),callback:function ($$v) {_vm.$set(_vm.addDocument, "parent_type_id", $$v)},expression:"addDocument.parent_type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Parent")]),_c('validation-provider',{attrs:{"name":"Parent","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"parent_id","options":_vm.parents,"reduce":function (item) { return item.id; },"clearable":false,"label":"name","placeholder":"Select"},model:{value:(_vm.addDocument.parent_id),callback:function ($$v) {_vm.$set(_vm.addDocument, "parent_id", $$v)},expression:"addDocument.parent_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('app-timeline-item',{staticClass:"pb-1",attrs:{"variant":"info"}},[_c('div',{staticClass:"d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"},[_c('h6',[_vm._v("Document Details")])]),_c('b-modal',{ref:"add_document_type",attrs:{"cancel-variant":"outline-secondary","ok-title":"Add","cancel-title":"Close","centered":"","title":"Add Document Type","no-close-on-backdrop":true},on:{"hide":_vm.closingDocumentTypePopup},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-end w-100"},[_c('div'),_c('b-button',{staticClass:"ml-1 px-1",attrs:{"variant":"primary"},on:{"click":_vm.submitDocumentType}},[_vm._v(" Add ")])],1)]},proxy:true}])},[_c('validation-observer',{ref:"newDocumentType",attrs:{"tag":"form"}},[_c('b-form',[_c('b-form-group',[_c('label',[_vm._v("Document Type")]),_c('validation-provider',{attrs:{"name":"Document Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"new-document-type"},model:{value:(_vm.addDocumentTypeRequest.document_type),callback:function ($$v) {_vm.$set(_vm.addDocumentTypeRequest, "document_type", $$v)},expression:"addDocumentTypeRequest.document_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-modal',{ref:"add_document_name",attrs:{"cancel-variant":"outline-secondary","ok-title":"Add","cancel-title":"Close","centered":"","title":"Add Document Name","no-close-on-backdrop":true},on:{"hide":_vm.closingDocumentNamePopup},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-end w-100"},[_c('div'),_c('b-button',{staticClass:"ml-1 px-1",attrs:{"variant":"primary"},on:{"click":_vm.submitDocumentName}},[_vm._v(" Add ")])],1)]},proxy:true}])},[_c('validation-observer',{ref:"newDocumentName",attrs:{"tag":"form"}},[_c('b-form',[_c('b-form-group',[_c('label',[_vm._v("Document Name")]),_c('validation-provider',{attrs:{"name":"Document Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"new-document-name"},model:{value:(_vm.addDocumentNameRequest.document_name),callback:function ($$v) {_vm.$set(_vm.addDocumentNameRequest, "document_name", $$v)},expression:"addDocumentNameRequest.document_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-modal',{ref:"add_issuer",attrs:{"cancel-variant":"outline-secondary","ok-title":"Add","cancel-title":"Close","centered":"","title":"Add Issuer","no-close-on-backdrop":true},on:{"hide":_vm.closingIssuerPopup},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-end w-100"},[_c('div'),_c('b-button',{staticClass:"ml-1 px-1",attrs:{"variant":"primary"},on:{"click":_vm.submitIsssuer}},[_vm._v(" Add ")])],1)]},proxy:true}])},[_c('validation-observer',{ref:"newIssuer",attrs:{"tag":"form"}},[_c('b-form',[_c('b-form-group',[_c('label',[_vm._v("Issuer")]),_c('validation-provider',{attrs:{"name":"Issuer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"new-issuer"},model:{value:(_vm.addIssuerRequest.issuer),callback:function ($$v) {_vm.$set(_vm.addIssuerRequest, "issuer", $$v)},expression:"addIssuerRequest.issuer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('div',{staticClass:"repeat-border"},_vm._l((_vm.documents),function(document,index){return _c('div',{key:index,staticClass:"d-flex"},[_c('div',{staticClass:"left w-100"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Document Type")]),_c('validation-provider',{attrs:{"name":'Document Type' + index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"left w-100"},[_c('v-select',{attrs:{"options":_vm.documentTypes,"reduce":function (item) { return item.id; },"clearable":false,"placeholder":"Select"},on:{"input":function($event){return _vm.documentTypeChanged(index)}},model:{value:(document.document_type_id),callback:function ($$v) {_vm.$set(document, "document_type_id", $$v)},expression:"document.document_type_id"}}),_c('small',{staticClass:"text-danger",domProps:{"textContent":_vm._s(errors[0] !== undefined ? errors[0].replace('Document Type' + index, 'Document Type') : '')}})],1),_c('div',{staticClass:"right ml-50"},[_c('b-button',{staticClass:"px-1",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.showDocumentType(index)}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1)])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Document Name")]),_c('validation-provider',{attrs:{"name":'Document Name' + index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"left w-100"},[_c('v-select',{attrs:{"options":document.documentNames,"reduce":function (item) { return item.id; },"clearable":false,"placeholder":"Select"},on:{"input":function($event){return _vm.documentNameChanged(index)}},model:{value:(document.document_name_id),callback:function ($$v) {_vm.$set(document, "document_name_id", $$v)},expression:"document.document_name_id"}}),_c('small',{staticClass:"text-danger",domProps:{"textContent":_vm._s(errors[0] !== undefined ? errors[0].replace('Document Name' + index, 'Document Name') : '')}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(document.document_type_id !== null),expression:"document.document_type_id !== null"}],staticClass:"right ml-50"},[_c('b-button',{staticClass:"px-1",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.showDocumentName(index)}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1)])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Issuer")]),_c('validation-provider',{attrs:{"name":'Issuer' + index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"left w-100"},[_c('v-select',{attrs:{"options":document.issuers,"reduce":function (item) { return item.id; },"clearable":false,"placeholder":"Select"},model:{value:(document.issuer_id),callback:function ($$v) {_vm.$set(document, "issuer_id", $$v)},expression:"document.issuer_id"}}),_c('small',{staticClass:"text-danger",domProps:{"textContent":_vm._s(errors[0] !== undefined ? errors[0].replace('Issuer' + index, 'Issuer') : '')}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(document.document_name_id !== null),expression:"document.document_name_id !== null"}],staticClass:"right ml-50"},[_c('b-button',{staticClass:"px-1",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.showIssuer(index)}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1)])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-checkbox',{on:{"change":function($event){return _vm.checkChanged(index)}},model:{value:(document.no_expire),callback:function ($$v) {_vm.$set(document, "no_expire", $$v)},expression:"document.no_expire"}},[_vm._v(" No Expire ")])],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(!document.no_expire),expression:"!document.no_expire"}],attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Exp Date")]),_c('validation-provider',{attrs:{"name":'Exp Date' + index,"rules":document.no_expire ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.exp_date_config,"placeholder":"MM-DD-YYYY"},model:{value:(document.exp_date),callback:function ($$v) {_vm.$set(document, "exp_date", $$v)},expression:"document.exp_date"}}),_c('small',{staticClass:"text-danger",domProps:{"textContent":_vm._s(errors[0] !== undefined ? errors[0].replace('Exp Date' + index, 'Exp Date') : '')}})]}}],null,true)})],1)],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(!document.no_expire),expression:"!document.no_expire"}],attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Reminder")]),_c('validation-provider',{attrs:{"name":'Reminder' + index,"rules":document.no_expire ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.reminders,"reduce":function (item) { return item.id; },"clearable":false,"placeholder":"Select"},model:{value:(document.reminder_id),callback:function ($$v) {_vm.$set(document, "reminder_id", $$v)},expression:"document.reminder_id"}}),_c('small',{staticClass:"text-danger",domProps:{"textContent":_vm._s(errors[0] !== undefined ? errors[0].replace('Reminder' + index, 'Reminder') : '')}})]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Version")]),_c('validation-provider',{attrs:{"name":'Version' + index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.versions,"reduce":function (item) { return item.id; },"clearable":false,"placeholder":"Select"},model:{value:(document.version_id),callback:function ($$v) {_vm.$set(document, "version_id", $$v)},expression:"document.version_id"}}),_c('small',{staticClass:"text-danger",domProps:{"textContent":_vm._s(errors[0] !== undefined ? errors[0].replace('Version' + index, 'Version') : '')}})]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Document")]),_c('validation-provider',{attrs:{"name":'Document' + index,"rules":document.document === null ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{ref:'documentFileInput' + index,refInFor:true,staticClass:"form-control text-left",attrs:{"type":"file","placeholder":"Choose a file or drop it here..."},on:{"change":function($event){return _vm.attahamentFileSelected($event, index)}}}),_c('small',{staticClass:"text-danger",domProps:{"textContent":_vm._s(errors[0] !== undefined && document.document === null ? errors[0].replace('Document' + index, 'Document') : '')}})]}}],null,true)})],1)],1)],1)],1),_c('div',{staticClass:"right mt-2 ml-1"},[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(index === 0),expression:"index === 0"}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"outline-primary"},on:{"click":_vm.addNewDocument}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(index !== 0),expression:"index !== 0"}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeNewDocument(index)}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)],1)])}),0)],1),_c('app-timeline-item',{staticClass:"pb-1",attrs:{"variant":"success"}},[_c('div',{staticClass:"d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"},[_c('h6',[_vm._v("Notes")])]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Notes")]),_c('validation-provider',{attrs:{"name":"Notes"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"note","rows":"2"},model:{value:(_vm.addDocument.notes),callback:function ($$v) {_vm.$set(_vm.addDocument, "notes", $$v)},expression:"addDocument.notes"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('hr'),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"primary","type":"button"},on:{"click":_vm.submitForm}},[_vm._v(" Save Document Tracker ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }