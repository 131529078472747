import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.hrurl
const facility = `${HOST}facility`

export default {
  getFacilities(context1) {
    const context = context1
    const params = { params: { query: context.$store.state.query.query, page: context.$store.state.query.page - 1 } }
    axios.get(facility, params).then(response => {
      context.setFacilities(response.data)
    }, () => {
    })
  },
  addFacility(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(facility, body).then(response => {
      context.$vs.loading.close()
      context.facilityAdded(response.data)
      context.$store.state.lists.commonLists.lists['facilities-all'] = response.data.full_list
    }, error => {
      context.$vs.loading.close()
      context.facilityNotAdded(error.response.data.return_message)
    })
  },
  updateFacility(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${facility}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.facilityUpdated(response.data)
      context.$store.state.lists.commonLists.lists['facilities-all'] = response.data.full_list
    }, error => {
      context.$vs.loading.close()
      context.facilityNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    axios.delete(`${facility}/${id}`).then(response => {
      context.parentDeleted(response.data)
      context.$store.state.lists.commonLists.lists['facilities-all'] = response.data.full_list
    }, error => {
      context.parentNotDeleted(error.response.data.return_message)
    })
  },
  getFacilityById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${facility}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setFacility(response.data)
    }, error => {
      context.$vs.loading.close()
      context.facilityNotFound(error.response.data.return_message)
    })
  },

}
