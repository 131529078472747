import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.hrurl
const jobAdvertise = `${HOST}job-advertise`

export default {
  addJobAdvertise(context1, jobId, request) {
    const context = context1
    axios.post(`${jobAdvertise}/${jobId}`, request).then(response => {
      context.setJobAdvertised(response.data)
    }, () => {
    })
  },
  closeJobAdvertise(context1, jobId, request) {
    const context = context1
    axios.put(`${jobAdvertise}/${jobId}`, request).then(response => {
      context.setJobAdvertiseClosed(response.data)
    }, () => {
    })
  },
}
