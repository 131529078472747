<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Edit Workflow</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <b-row class="mt-1">
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Workflow Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Workflow Name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="addProcess.name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Workflow Tasks</h6>
            </div>
            <div
              v-for="(processTask, index) in addProcess.tasks"
              :id="processTask.id"
              :key="processTask.id"
              class="d-flex mt-1"
            >
              <div class="left w-100">
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>Task Name</label>
                      <validation-provider
                        v-slot="{ errors }"
                        :name="'Task Name' + index"
                        rules="required"
                      >
                        <b-form-input
                          v-model="processTask.name"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ (errors[0] !== undefined && errors[0] !== null) ? errors[0].replace('Task Name' + index, 'Task Name') : errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <b-form-group>
                      <label>Task Type</label>
                      <validation-provider
                        v-slot="{ errors }"
                        :name="'Task Type' + index"
                        rules="required"
                      >
                        <v-select
                          v-model="processTask.task_id"
                          :options="tasks"
                          :reduce="item => item.id"
                          :clearable="false"
                          placeholder="Select"
                          @input="taskSelected(index)"
                        />
                        <small class="text-danger">{{ (errors[0] !== undefined && errors[0] !== null) ? errors[0].replace('Task Type' + index, 'Task Type') : errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group>
                      <label>Task Fields</label>
                      <span class="form-control height-auto min-h-38">
                        <b-badge
                          v-for="(field, fieldIndex) in processTask.fields"
                          :key="fieldIndex"
                          :value="fieldIndex"
                          variant="light-primary"
                          class="mr-50 my-25"
                        >
                          {{ field }}
                        </b-badge>
                      </span>
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr class="my-0">
              </div>
              <div class="right mt-1">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="btn-icon rounded-circle"
                  @click="removeProcessTask(index)"
                >
                  <feather-icon
                    icon="XIcon"
                  />
                </b-button>
              </div>
            </div>
            <!-- <div v-if="taskTypes.length !== addProcess.processTasks.length"> -->
            <div>
              <div class="right mt-1">
                <b-button
                  id="add_process_task"
                  variant="outline-primary"
                  class="btn-icon mr-1"
                  @click="addProcessTask"
                >
                  <feather-icon icon="PlusIcon" /> Add Workflow Task
                </b-button>
              </div>
            </div>
            <hr>
            <div class="vue-form-wizard vue-custom-form-wizard">
              <div class="wizard-navigation">
                <draggable
                  v-model="myList"
                  class="wizard-nav wizard-nav-pills cursor-move"
                  tag="ul"
                >
                  <li
                    v-for="processTask in myList"
                    :id="processTask"
                    :key="processTask"
                    class="cursor-move translucent-wrap bg-light-primary rounded mb-1 p-1"
                  >
                    <span class="stepTitle">
                      {{ processTask.name }}
                    </span>
                  </li>
                </draggable>
              </div>
            </div>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="mt-1">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Update Workflow
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BBadge,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import {
  required,
} from '@validations'
import ProcessTask from '@/js/processtask'
import Process from '@/js/process'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  name: 'WorkflowEdit',
  components: {
    BCard,
    BBadge,
    ValidationProvider,
    ValidationObserver,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    BButton,
    draggable,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      addProcess: {
        name: '',
        tasks: [],
      },
      tasks: [],
      mainProps: {
        blank: true,
        width: 75,
        height: 75,
        class: 'm1',
      },
    }
  },
  computed: {
    myList: {
      get() {
        return this.addProcess.tasks
      },
      set(value) {
        this.addProcess.tasks = value
      },
    },
  },
  mounted() {
  },
  created() {
    ProcessTask.getProcessTaskFullList(this)
    Process.getProcessById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          Process.updateProcess(this, this.$route.params.id, this.addProcess)
        }
      })
    },
    addProcessTask() {
      const task = {
        name: '',
        task_id: null,
        fields: [],
      }
      this.addProcess.tasks.push(task)
    },
    taskSelected(index) {
      for (let i = 0; i < this.tasks.length; i += 1) {
        if (this.tasks[i].id === this.addProcess.tasks[index].task_id) {
          this.addProcess.tasks[index].fields = this.tasks[i].fields
        }
      }
    },
    removeProcessTask(index) {
      this.addProcess.tasks.splice(index, 1)
    },
    setProcessTask(tasks) {
      this.tasks = tasks.tasks
    },
    setProcess(process) {
      this.addProcess = process
    },
    processUpdated(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    processNotUpdated(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    back() {
      this.$router.push('/administration/workflow').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
  @import '@core/scss/vue/libs/vue-wizard.scss';
.repeater-form {
  transition: .35s height;
}
.list-group-item {
  transition: all 1s
}
</style>
