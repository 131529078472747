export default [
  {
    title: 'Finance & Accounting',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'Timesheets',
        route: 'finance-and-accounting-timesheets',
      },
      {
        title: 'Invoices To Vendors',
        route: 'finance-and-accounting-invoices-to-vendors',
      },
      {
        title: 'Reconcilation',
        children: [
          {
            title: 'Bank Reconcile History',
            route: 'finance-and-accounting-bank-reconcile-history',
          },
        ],
      },
      {
        title: 'Expenses',
        route: 'finance-and-accounting-expenses',
      },
      {
        title: 'Employee Salaries',
        route: 'finance-and-accounting-salaries',
      },
    ],
  },
]
