<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          View Job Posting</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <app-timeline>
      <app-timeline-item class="pb-1">
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Job Title
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="job.job_title"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Job-Id / Ref-No
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="job.job_id"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Position Type
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="job.position_type"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                City, Zip
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="job.city"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Visa Required
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="job.visa_types"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Facility / Client
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="job.facility"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Facility Rate
              </dt>
              <dd class="col-sm-12">
                <span class="form-control">{{ job.facility_bill_rate | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Status
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="job.status"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Bill Rate
              </dt>
              <dd class="col-sm-12">
                <span class="form-control">{{ job.contract_bill_rate | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Main Skills
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="job.skill_names"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              id="start_date"
            >
              <label>Start Date</label>
              <span
                class="form-control"
                v-text="job.start_date"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              id="end_date"
            >
              <label>End Date</label>
              <span
                class="form-control"
                v-text="job.end_date"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Experience
              </dt>
              <dd class="col-sm-12">
                <b-input-group append="Years">
                  <span
                    class="form-control"
                    v-text="job.experience_years"
                  />
                </b-input-group>
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Duration
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="job.duration"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Immediate
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="job.immediate"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Assigned Users
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                >
                  <b-badge
                    v-for="(assigned_user, index) in job.assigned_users"
                    :key="index"
                    variant="light-primary"
                    class="m-25"
                  >
                    {{ assigned_user.label }}
                  </b-badge>
                </span>
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Co-Ordinator
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="job.coordinator"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Shift Days</label>
              <b-input-group append="Days">
                <span
                  class="form-control"
                  v-text="job.shift_days"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Shift Hours</label>
              <b-input-group append="Hours">
                <span
                  class="form-control"
                  v-text="job.shift_hours"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            v-for="job_start_time in job.job_start_times"
            :key="job_start_time"
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Shift Start Time</label>
              <span
                class="form-control"
                v-text="job_start_time.time"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <label>Description</label>
              <div
                class="form-control height-auto quill-view job-description-view"
                v-html="job.description"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="info"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Secondary Skills</h6>
        </div>
        <b-row>
          <b-col cols="12">
            <div class="demo-inline-spacing">
              <b-badge
                v-for="technology in job.job_technologys"
                :key="technology"
                :value="technology"
                variant="light-primary"
                class="mt-1"
              >
                {{ technology.label }}
              </b-badge>
            </div>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="success"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Workflow</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Follow Workflow
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="job.process_name"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="warning"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Required Criteria</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group>
              <div class="demo-inline-spacing">
                <div
                  v-for="criteria in job.criterias"
                  :id="criteria.id"
                  :key="criteria.id"
                >
                  <b-form-checkbox
                    :checked="true"
                    disabled
                  >
                    {{ criteria }}
                  </b-form-checkbox>
                </div>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </app-timeline-item>
      <app-timeline-item
        variant="danger"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Tasks</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            class="mt-1"
          >
            <div class="table-responsive-sm">
              <table class="table b-table">
                <thead>
                  <tr>
                    <th
                      class="th-minw-250"
                    >Name</th>
                    <th
                      class="th-w-170"
                    >Created Date</th>
                    <th
                      class="th-w-140"
                    >Due Date</th>
                    <th
                      class="th-w-140"
                    >Status</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="task in job.tasks"
                    :key="task"
                    :value="task"
                  >
                    <td>{{ task.name }}</td>
                    <td>{{ task.created_date }}</td>
                    <td>{{ task.due_date }}</td>
                    <td>{{ task.status }}</td>
                    <td>{{ task.description }}</td>
                  </tr>
                  <tr v-if="job.tasks.length == 0">
                    <td
                      colspan="5"
                      class="text-center"
                    >
                      No Tasks Available
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        v-show="job.user_defined_fields.length !== 0"
        variant="primary"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>User Defined Fields</h6>
        </div>
        <b-row>
          <b-col
            v-for="field in job.user_defined_fields"
            :id="field.id"
            :key="field.id"
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                {{ field.name }}
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="field.value"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import Job from '@/js/jobs'

import {
  BCard, BRow, BCol, BFormGroup, BInputGroup, BButton, BFormCheckbox, BBadge,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  name: 'JobView',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormCheckbox,
    BButton,
    BBadge,
  },
  directives: {
  },
  setup() {
  },
  data() {
    return {
      job: {},
    }
  },
  mounted() {
  },
  created() {
    Job.getJobById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    setJob(job) {
      this.job = job
      this.job.skill_names = job.skill_names.join(', ')
      this.job.visa_types = job.visa_type_names.join(', ')
      if (job.immediate_need) {
        this.job.immediate = 'Yes'
      } else {
        this.job.immediate = 'No'
      }
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
