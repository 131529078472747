<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          View Bank</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <app-timeline>
      <app-timeline-item class="pb-1">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Bank Details</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Bank Name</label>
              <span
                class="form-control"
                v-text="bank.name"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Bank Acc#</label>
              <span
                class="form-control"
                v-text="bank.acct_no"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Swift Code</label>
              <span
                class="form-control"
                v-text="bank.swift_code"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Routing</label>
              <span
                class="form-control"
                v-text="bank.routing_no"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Address</label>
              <span
                class="form-control"
                v-text="bank.address.address1"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Address 2</label>
              <span
                class="form-control"
                v-text="bank.address.address2"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>City</label>
              <span
                class="form-control"
                v-text="bank.address.city"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>State</label>
              <span
                class="form-control"
                v-text="bank.address.state.name"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Zip Code</label>
              <span
                class="form-control"
                v-text="bank.address.zip_id"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Type</label>
              <span
                class="form-control"
                v-text="bank.type"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr class="mt-0">

        <b-row class="mb-1">
          <b-col class="text-right">
            <b-button
              v-if="!bank.access_token_exist"
              variant="outline-primary"
              type="button"
              @click="getLinkToken"
            >
              Link Bank
            </b-button>
          </b-col>
        </b-row>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BButton,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import Bank from '@/js/bank'
import PlaidService from '@/js/plaid'

export default {
  name: 'BankReconcileHistoryView',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BFormGroup,
    BButton,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      bank: {
        access_token_exist: '',
      },
    }
  },
  mounted() {
  },
  created() {
    Bank.getBankById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    setBank(bank) {
      this.bank = bank
    },
    getLinkToken() {
      PlaidService.getLinkToken(this)
    },
    setLinkToken(data) {
      const dis = this
      const handler = window.Plaid.create({
        token: data.link_token, // Replace with your public_key to test with live credentials
        onLoad() {
          // Optional, called when Link loads
        },
        onSuccess(publicToken) {
          const body = {
            public_token: publicToken,
          }
          PlaidService.saveAccessToken(dis, dis.$route.params.id, body)
        },
        onExit(err) {
          // The user exited the Link flow.
          if (err !== null) {
            // The user encountered a Plaid API error prior to exiting.
          }
          // metadata contains information about the institution
          // that the user selected and the most recent API request IDs.
          // Storing, this information can be helpful for support.
        },
      })
      handler.open()
    },
    accessTokenSaved(data) {
      this.bank.access_token_exist = true
      this.$vs.dialog({
        type: 'alert',
        color: 'success',
        title: 'Bank',
        text: data.return_message,
        accept: null,
      })
    },
    accessTokenNotSaved(message) {
      this.$vs.loading.close()
      this.$vs.dialog({
        type: 'alert',
        color: 'danger',
        title: 'Bank',
        text: message,
        accept: null,
      })
    },
    back() {
      this.$router.push('/finance-and-accounting/bank-reconcile-history').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
