<template>
  <div>
    <b-row class="mt_35">
      <b-col
        cols="12"
        md="6 ml-auto"
        class="col-xxl-4 mb-1"
      >
        <b-form-group
          label="Select Year"
          label-cols-md="4"
          class="text-right"
        >
          <v-select
            id="parent_type"
            v-model="year"
            :options="years"
            :clearable="false"
            placeholder="Select Year"
            @input="dateChanged"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        md="8"
        class="col-xxl-6 mb-2 mb-md-0"
      >
        <b-card
          no-body
          class="card-statistics card-custome-statistics"
        >
          <b-card-header>
            <b-card-title>Profit Rate</b-card-title>
          </b-card-header>
          <b-card-body class="statistics-body">
            <b-row>
              <b-col
                md="6"
                class="mb-2 mb-md-0"
              >
                <b-media no-body>
                  <b-media-aside
                    class="mr-2"
                  >
                    <b-avatar
                      size="38"
                      variant="light-primary"
                    >
                      <feather-icon
                        size="18"
                        icon="DollarSignIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ profitRateResponse.profit_rate | currency('$ ', 2, { thousandsSeparator: ',' }) }}/HR
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Average
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col
                md="6"
                class="mb-2 mb-md-0"
              >
                <b-media no-body>
                  <b-media-aside
                    class="mr-2"
                  >
                    <b-avatar
                      size="38"
                      variant="light-primary"
                    >
                      <feather-icon
                        size="18"
                        icon="DollarSignIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ profitRateResponse.total_profit_rate | currency('$ ', 2, { thousandsSeparator: ',' }) }}/HR
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Total
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        md="4"
        sm="6"
        class="col-xxl-3 mb-2 mb-md-0"
      >
        <b-card
          no-body
          class="card-statistics card-custome-statistics"
        >
          <b-link :to="{ name: 'financial-statements-account-receivables', query: { year: year } }">
            <b-card-header>
              <b-card-title>Account Receivables</b-card-title>
            </b-card-header>
            <b-card-body class="statistics-body">
              <b-media no-body>
                <b-media-aside

                  class="mr-2"
                >
                  <b-avatar
                    size="38"
                    variant="light-success"
                  >
                    <feather-icon
                      size="18"
                      icon="DollarSignIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ countsResponse.account_receivables | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                  </h4>
                </b-media-body>
              </b-media>
            </b-card-body>
          </b-link>
        </b-card>
      </b-col>
      <b-col
        md="4"
        sm="6"
        class="col-xxl-3 mb-2 mb-md-0"
      >
        <b-card
          no-body
          class="card-statistics card-custome-statistics"
        >
          <b-link :to="{ name: 'financial-statements-account-payables', query: { year: year } }">
            <b-card-header>
              <b-card-title>Account Payables</b-card-title>
            </b-card-header>
            <b-card-body class="statistics-body">
              <b-media no-body>
                <b-media-aside

                  class="mr-2"
                >
                  <b-avatar
                    size="38"
                    variant="light-info"
                  >
                    <feather-icon
                      size="18"
                      icon="DollarSignIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ countsResponse.account_payables | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                  </h4>
                </b-media-body>
              </b-media>
            </b-card-body>
          </b-link>
        </b-card>
      </b-col>
      <b-col
        md="4"
        sm="6"
        class="col-xxl-3 mb-2 mb-md-0"
      >
        <b-card
          no-body
          class="card-statistics card-custome-statistics"
        >
          <b-card-header>
            <b-card-title>Profit Amount</b-card-title>
          </b-card-header>
          <b-card-body class="statistics-body">
            <b-media no-body>
              <b-media-aside

                class="mr-2"
              >
                <b-avatar
                  size="38"
                  variant="light-primary"
                >
                  <feather-icon
                    size="18"
                    icon="DollarSignIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ countsResponse.profit | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                </h4>
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        md="4"
        sm="6"
        class="col-xxl-3 mb-2 mb-md-0"
      >
        <b-card
          no-body
          class="card-statistics card-custome-statistics"
        >
          <b-link :to="{ name: 'financial-statements-outstanding-account-receivables' }">
            <b-card-header>
              <b-card-title>Outstanding Account Receivables</b-card-title>
            </b-card-header>
            <b-card-body class="statistics-body">
              <b-media no-body>
                <b-media-aside

                  class="mr-2"
                >
                  <b-avatar
                    size="38"
                    variant="light-success"
                  >
                    <feather-icon
                      size="18"
                      icon="DollarSignIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ outStandingCountResponse.outstanding_receivables | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                  </h4>
                </b-media-body>
              </b-media>
            </b-card-body>
          </b-link>
        </b-card>
      </b-col>
      <b-col
        md="4"
        sm="6"
        class="col-xxl-3 mb-2 mb-md-0"
      >
        <b-card
          no-body
          class="card-statistics card-custome-statistics"
        >
          <b-card-header>
            <b-card-title>Outstanding Profit</b-card-title>
          </b-card-header>
          <b-card-body class="statistics-body">
            <b-media no-body>
              <b-media-aside

                class="mr-2"
              >
                <b-avatar
                  size="38"
                  variant="light-info"
                >
                  <feather-icon
                    size="18"
                    icon="DollarSignIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ outStandingCountResponse.outstanding_profit | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                </h4>
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <div class="mt-1 mb-1">
      <h3>Financial Planning Stats</h3>
    </div>
    <b-row class="match-height">
      <b-col
        md="4"
        sm="6"
        class="col-xxl-3 mb-2 mb-md-0"
      >
        <b-card
          no-body
          class="card-statistics card-custome-statistics"
        >
          <b-card-header>
            <b-card-title>Employee Salary Payables</b-card-title>
          </b-card-header>
          <b-card-body class="statistics-body">
            <b-media no-body>
              <b-media-aside

                class="mr-2"
              >
                <b-avatar
                  size="38"
                  variant="light-primary"
                >
                  <feather-icon
                    size="18"
                    icon="DollarSignIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ statsResponse.salary_payables | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                </h4>
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        md="4"
        sm="6"
        class="col-xxl-3 mb-2 mb-md-0"
      >
        <b-card
          no-body
          class="card-statistics card-custome-statistics"
        >
          <b-card-header>
            <b-card-title>Outstanding Taxes</b-card-title>
          </b-card-header>
          <b-card-body class="statistics-body">
            <b-media no-body>
              <b-media-aside

                class="mr-2"
              >
                <b-avatar
                  size="38"
                  variant="light-info"
                >
                  <feather-icon
                    size="18"
                    icon="DollarSignIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ statsResponse.outstanding_taxes | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                </h4>
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        md="4"
        sm="6"
        class="col-xxl-3 mb-2 mb-md-0"
      >
        <b-card
          no-body
          class="card-statistics card-custome-statistics"
        >
          <b-card-header>
            <b-card-title>Operating Expenses</b-card-title>
          </b-card-header>
          <b-card-body class="statistics-body">
            <b-media no-body>
              <b-media-aside

                class="mr-2"
              >
                <b-avatar
                  size="38"
                  variant="light-primary"
                >
                  <feather-icon
                    size="18"
                    icon="DollarSignIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ statsResponse.operating_expenses | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                </h4>
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        md="4"
        sm="6"
        class="col-xxl-3 mb-2 mb-md-0"
      >
        <b-card
          no-body
          class="card-statistics card-custome-statistics"
        >
          <b-card-header>
            <b-card-title>Gross Profit</b-card-title>
          </b-card-header>
          <b-card-body class="statistics-body">
            <b-media no-body>
              <b-media-aside

                class="mr-2"
              >
                <b-avatar
                  size="38"
                  variant="light-success"
                >
                  <feather-icon
                    size="18"
                    icon="PercentIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ statsResponse.gross_ratio | currency('% ', 2, { thousandsSeparator: ',' }) }}
                </h4>
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        md="4"
        sm="6"
        class="col-xxl-3 mb-2 mb-md-0"
      >
        <b-card
          no-body
          class="card-statistics card-custome-statistics"
        >
          <b-card-header>
            <b-card-title>Net Profit</b-card-title>
          </b-card-header>
          <b-card-body class="statistics-body">
            <b-media no-body>
              <b-media-aside

                class="mr-2"
              >
                <b-avatar
                  size="38"
                  variant="light-success"
                >
                  <feather-icon
                    size="18"
                    icon="PercentIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ statsResponse.net_ration | currency('% ', 2, { thousandsSeparator: ',' }) }}
                </h4>
              </b-media-body>
            </b-media>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BCardText, BRow, BCol, BMedia, BMediaAside, BMediaBody, BAvatar, BFormGroup, BLink,
} from 'bootstrap-vue'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index'
import { avatarText } from '@core/utils/filter'
import Financialdashboard from '@/js/financialdashboard'
import vSelect from 'vue-select'
import moment from 'moment'

export default {
  name: 'FinancialStatementsDashboard',
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BFormGroup,
    BLink,
    vSelect,
  },
  data() {
    return {
      avatarText,
      year: moment().format('YYYY'),
      selectedYear: moment().format('YYYY'),
      profitRateResponse: {
        profit_rate: 0,
      },
      years: [],
      statsResponse: {
        salary_payables: 0.00,
        outstanding_taxes: 0.00,
        operating_expenses: 0.00,
        gross_ratio: 0.00,
        net_ration: 0.00,
      },
      outStandingCountResponse: {
        outstanding_receivables: 0,
        outstanding_payables: 0,
        outstanding_profit: 0,
      },
      countsResponse: {
        account_receivables: 0,
        account_payables: 0,
        profit: 0,
      },
      configs: {
        yearSelect: {
          plugins: [
            monthSelectPlugin({
              shorthand: true,
              dateFormat: 'Y',
              altFormat: 'Y',
            }),
          ],
        },
      },
    }
  },
  created() {
    Financialdashboard.getProfitRateCount(this)
    Financialdashboard.getProfitAmountCounts(this)
    Financialdashboard.getStats(this)
    Financialdashboard.getOutStandingProfitAmountCounts(this)
  },
  mounted() {
    this.getAvailableYears()
  },
  methods: {
    setCounts(countsResponse) {
      this.countsResponse = countsResponse
    },
    setOutStandingCounts(outStandingCountResponse) {
      this.outStandingCountResponse = outStandingCountResponse
    },
    getAvailableYears() {
      const currentYear = new Date().getFullYear()
      const startYear = currentYear - 5 // Adjust the range as per your requirement
      const endYear = currentYear + 5 // Adjust the range as per your requirement

      for (let year = startYear; year <= endYear; year += 1) {
        this.years.push(year)
      }
    },
    setStats(statsResponse) {
      this.statsResponse = statsResponse
    },
    setProfitCount(profitRateResponse) {
      this.profitRateResponse = profitRateResponse
    },
    dateChanged() {
      Financialdashboard.getProfitAmountCounts(this)
      Financialdashboard.getStats(this)
    },
    removeDate() {
      this.year = null
      Financialdashboard.getProfitAmountCounts(this)
    },
  },
}
</script>
<style>
  .card-statistics .card-header {
    padding-left: 1.5rem;
  }
  .card-transaction .transaction-item:not(:last-child) {
      margin-bottom: 1.5rem;
  }
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.custom-color.badge-secondary {
    background-color: rgba(40, 199, 111, 0.12);
    color: #28c76f !important;
}
</style>
