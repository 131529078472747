<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      v-show="isCanShow(item)"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
  methods: {
    isCanShow(item) {
      if (item.title === 'Dashboard') {
        return !this.$store.state.roles.main_administrator
      }
      if (item.title === 'Companies') {
        return this.$store.state.roles.main_administrator
      }
      if (item.title === 'Exceptions') {
        return this.$store.state.roles.main_administrator
      }
      // if (this.$store.state.roles.administrator) {
      //   return this.$store.state.roles.administrator
      // }
      const devisions = Object.keys(this.$store.state.roles.devisions)
      return devisions.includes(item.title)
    },
  },
}
</script>
