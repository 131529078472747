<template>
  <b-card
    class="mb-0"
  >
    <div class="mb-2">
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <h4 class="card-title mb-0 pt-50">
            User Defined Fields</h4>
        </b-col>
        <b-col
          cols="12"
          md="8 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                @keyup.enter="updateSearchQuery"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="updateSearchQuery"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="14"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              class="ml-2 px-1 min-w-200"
              @click="showNew"
            >
              Add User Defined Field
            </b-button>
            <b-sidebar
              id="sidebar-user-defined-field"
              ref="sidebarNew"
              sidebar-class="sidebar-lg"
              bg-variant="white"
              right
              backdrop
              shadow
              no-header
            >
              <template #default="{ hide }">
                <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                  <h5 class="mb-0">
                    Add User Defined Field
                  </h5>
                  <div>
                    <feather-icon
                      class="ml-1 cursor-pointer"
                      icon="XIcon"
                      size="16"
                      @click="hide"
                    />
                  </div>
                </div>
                <sidebar-content />
              </template>
            </b-sidebar>

            <b-sidebar
              id="sidebar-user-defined-field-edit"
              ref="sidebarEdit"
              sidebar-class="sidebar-lg"
              bg-variant="white"
              right
              backdrop
              shadow
              no-header
            >
              <template #default="{ hide }">
                <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                  <h5 class="mb-0">
                    Edit User Defined Field
                  </h5>
                  <div>
                    <feather-icon
                      class="ml-1 cursor-pointer"
                      icon="XIcon"
                      size="16"
                      @click="hide"
                    />
                  </div>
                </div>
                <sidebar-content-edit />
              </template>
            </b-sidebar>

            <b-sidebar
              id="sidebar-user-defined-field-view"
              ref="sidebarView"
              sidebar-class="sidebar-lg"
              bg-variant="white"
              right
              backdrop
              shadow
              no-header
            >
              <template #default="{ hide }">
                <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                  <h5 class="mb-0">
                    View User Defined Field
                  </h5>
                  <div>
                    <feather-icon
                      class="ml-1 cursor-pointer"
                      icon="XIcon"
                      size="16"
                      @click="hide"
                    />
                  </div>
                </div>
                <sidebar-content-view />
              </template>
            </b-sidebar>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-row>
      <b-col cols="12">
        <vue-good-table
          :columns="columns"
          :rows="udfResponse.user_defined_fields"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >

          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'parent_types'">
              <b-badge
                v-for="parent_type in props.row.parent_types"
                :id="parent_type"
                :key="parent_type"
                variant="light-primary"
                pill
                class="my-25 mr-50"
              >
                {{ parent_type }}
              </b-badge>
            </span>
            <!-- Column: Action -->
            <span
              v-else-if="props.column.field === 'action'"
              class="btn-action"
            >
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="showEdit(props.row)">
                    <feather-icon icon="Edit2Icon" />
                    <span class="ml-50">Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="confirmDeleteRecord(props.row)">
                    <feather-icon icon="TrashIcon" />
                    <span class="ml-50">Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
                <b-link
                  id="view"
                  @click="showView(props.row)"
                >
                  <b-badge
                    pill
                    variant="light-primary"
                  ><feather-icon icon="EyeIcon" />
                  </b-badge>
                </b-link>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
          >
            <div class="d-flex justify-content-end flex-wrap">
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="udfResponse.count"
                  first-number
                  last-number
                  align="right"
                  :per-page="1"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BPagination, BFormInput, BInputGroup, BInputGroupAppend, BDropdown, BDropdownItem, BButton, BSidebar, VBToggle, BBadge, BLink,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import UserDefinedFields from '@/js/userDefinedFields'
import store from '@/store/index'
import SidebarContent from './SidebarContent.vue'
import SidebarContentEdit from './SidebarContentEdit.vue'
import SidebarContentView from './SidebarContentView.vue'

export default {
  name: 'UserDefinedFields',
  components: {
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    BFormInput,
    BPagination,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BLink,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BBadge,

    SidebarContent,
    SidebarContentEdit,
    SidebarContentView,

  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      searchQuery: '',
      pageLength: 10,
      dir: false,
      showEditSidebar: false,
      udfResponse: {
        user_defined_fields: [],
        count: 1,
      },
      columns: [
        {
          label: '#',
          field: 'index',
          width: '100px',
        },
        {
          label: 'Field Name',
          field: 'name',
          width: '200px',
          sortable: false,
        },
        {
          label: 'Field Type',
          field: 'type',
          sortable: false,
        },
        {
          label: 'Field Value',
          field: 'parent_types',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          width: '120px',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
        UserDefinedFields.getUdfs(this)
      },
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.searchQuery = this.$store.state.query.query
    this.udfResponse.count = this.$store.state.query.page
    UserDefinedFields.getUdfs(this)
  },
  methods: {
    confirmDeleteRecord(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${obj.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          UserDefinedFields.delete(this, obj.id)
        }
      })
    },
    setUdfEdif(udfResponse) {
      this.$refs.sidebarEdit.$children[0].addField = udfResponse
      this.$refs.sidebarEdit.$children[0].typeName = udfResponse.type.toLowerCase()
      this.$root.$emit('bv::toggle::collapse', 'sidebar-user-defined-field-edit')
    },
    setUdfView(udfResponse) {
      this.$refs.sidebarView.$children[0].field = udfResponse
      this.$root.$emit('bv::toggle::collapse', 'sidebar-user-defined-field-view')
    },
    showNew() {
      this.$refs.sidebarNew.$children[0].addField = {
        name: '',
        parent_type_ids: [],
        type_id: null,
        value_id: null,
        description: '',
        required: false,
        options: [],
      }
      this.$root.$emit('bv::toggle::collapse', 'sidebar-user-defined-field')
    },
    showEdit(obj) {
      this.$refs.sidebarEdit.$children[0].id = obj.id
      UserDefinedFields.getUdfById(this, obj.id)
    },
    showView(obj) {
      UserDefinedFields.getUdfViewById(this, obj.id)
    },
    udfDeleted(message) {
      UserDefinedFields.getUdfs(this)
      this.$swal({
        icon: 'success',
        title: 'Deleted!',
        text: message,
      })
    },
    udfNotDeleted(message) {
      this.$swal({
        icon: 'warning',
        title: 'Not Deleted!',
        text: message,
      })
    },
    updateSearchQuery() {
      this.$store.state.query.query = this.searchQuery
      this.currentPage = 1
    },
    setUdfs(udfResponse) {
      this.udfResponse = udfResponse
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
