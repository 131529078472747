<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="8"
      >
        <h4 class="card-title mb-0 pt-50">
          Candidate Details for {{ candidate.title }}</h4>
      </b-col>
      <b-col
        cols="12"
        md="4 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>

    <app-timeline>
      <app-timeline-item class="pb-1">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Candidate Details</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                First Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="candidate.first_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Last Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="candidate.last_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Email
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="candidate.email_address"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Phone
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="candidate.phone_number"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="info"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Workflow Tasks</h6>
        </div>
        <b-tabs class="wizard-tabs">
          <b-tab
            v-for="(task, index) in candidate.tasks"
            :key="index"
            :value="index"
            :active="task.active"
            :disabled="task.disabled"
            :before-change="validationForm"
            @click="tabSelected(index)"
          >
            <template #title>
              <span :class="(!task.disabled && !task.active) || task.status === 'Completed' ? 'wizard-icon-circle md checked square_shape' : 'wizard-icon-circle md square_shape'">
                <span class="wizard-icon-container square_shape">
                  <span class="wizard-icon">{{ index + 1 }}</span>
                </span>
              </span>
              <span :class="(!task.disabled && !task.active) || task.status === 'Completed' ? 'stepTitle checked' : 'stepTitle'">{{ task.name }}</span>
            </template>
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  {{ task.name }}
                </h5>
              </b-col>
              <b-col
                v-for="(field, fieldIndex) in task.fields"
                :key="fieldIndex"
                :value="fieldIndex"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>{{ field.name }}</label>
                  <span
                    class="form-control"
                    v-text="field.value"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
        <hr class="mt-0">
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Workflow Status
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="candidate.process_status"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="success"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Documents</h6>
        </div>
        <b-row class="mt-1">
          <b-col cols="12">
            <div class="table-responsive">
              <table class="table b-table table-th-pd table-td-pd">
                <thead>
                  <tr>
                    <th>Document Type</th>
                    <th>Issuer</th>
                    <th>Name</th>
                    <th>Version</th>
                    <th class="th-minw-125">Upload Date</th>
                    <th class="th-minw-110">Exp Date</th>
                    <th>Uploaded By</th>
                    <th>Reminder</th>
                    <th>Document</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(priviousVersion, index) in candidate.documents"
                    :key="index"
                  >
                    <td>{{ priviousVersion.document_type }}</td>
                    <td>{{ priviousVersion.issuer }}</td>
                    <td>{{ priviousVersion.document_name }}</td>
                    <td>{{ priviousVersion.version }}</td>
                    <td>{{ priviousVersion.uploaded_date }}</td>
                    <td>{{ priviousVersion.exp_date }}</td>
                    <td>{{ priviousVersion.uploaded_by }}</td>
                    <td>{{ priviousVersion.reminder }}</td>
                    <td>
                      <b-link
                        target="_blank"
                        :href="priviousVersion.document_path"
                      >
                        <b-badge
                          variant="primary"
                          class="mr-1 mb-50"
                        >
                          <span>{{ priviousVersion.document }}</span>
                        </b-badge>
                      </b-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
        <div
          v-if="candidate.documents.length == 0"
          class="text-center"
        >
          <p> No Documents available </p>
        </div>
      </app-timeline-item>

      <app-timeline-item
        v-show="candidate.user_defined_fields.length !== 0"
        variant="warning"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>User Defined Fields</h6>
        </div>
        <b-row>
          <b-col
            v-for="field in candidate.user_defined_fields"
            :id="field.id"
            :key="field.id"
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                {{ field.name }}
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="field.value"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import Candidate from '@/js/candidates'
import {
  BCard, BRow, BCol, BButton, BBadge, BLink, BTabs, BTab, BFormGroup,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  name: 'CandidateView',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BButton,
    BBadge,
    BLink,
    BTabs,
    BTab,
    BFormGroup,
  },
  directives: {
  },
  setup() {
  },
  data() {
    return {
      candidate: {},
    }
  },
  mounted() {
  },
  created() {
    Candidate.getCandidateById(this, this.$route.params.id)
  },
  methods: {
    setCandidate(candidate) {
      this.candidate = candidate
      if (candidate.visa == null) {
        this.candidate.visa = {
          type_id: null,
          expiration_date: null,
          validated: false,
          visa_type: {},
        }
      }
      if (candidate.address == null) {
        this.candidate.address = {
          address1: '',
          address2: '',
          city: '',
          zip_id: '',
          state_id: '',
        }
      }
    },
    tabSelected(index) {
      for (let i = 0; i < this.candidate.tasks.length; i += 1) {
        if (!this.candidate.tasks[i].disabled) {
          this.candidate.tasks[i].active = false
        }
      }
      this.candidate.tasks[index].active = true
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>
