<template>
  <b-card
    class="mb-0"
  >
    <div class="mb-2">
      <b-row>
        <b-col
          cols="12"
          md="3"
        >
          <h4 class="card-title mb-0 pt-50">
            Profile Invitations</h4>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group>
            <v-select
              id="status_id"
              v-model="status_id"
              :options="statusList"
              :reduce="item => item.id"
              :clearable="true"
              placeholder="Select Status"
              @input="statusChanged"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                @keyup.enter="updateSearchQuery"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="updateSearchQuery"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="14"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button
              variant="primary"
              class="ml-2 min-w-150"
              :to="{ name: 'administration-profile-invitations-new'}"
            >
              Add Invitation
            </b-button>
          </div>
        </b-col>
      </b-row>

    </div>

    <vue-good-table
      :columns="columns"
      :rows="invitationsResponse.invitions"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'name'">
          {{ props.row.name }}
          <img
            v-if="props.row.percentage_completed !== 0"
            class="ml-50 img-fluid"
            width="24"
            height="24"
            :src="require(`@/assets/images/icons/profile-${props.row.percentage_completed}.png`)"
            alt="image slot"
          >
        </span>

        <!-- Column: Resend -->
        <span v-else-if="props.column.field === 'cellRendererResendButton'">
          <b-button
            v-show="props.row.show_resend"
            variant="primary"
            type="button"
            class="py-50 px-1"
            @click="resendEmail(props.row)"
          >
            Resend
          </b-button>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span
          v-else-if="props.column.field === 'action'"
          class="btn-action"
        >
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item :to="{ name: 'administration-profile-invitations-edit', params: { id: props.row.id } }">
                <feather-icon icon="Edit2Icon" />
                <span class="ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="confirmDeleteRecord(props.row)">
                <feather-icon icon="TrashIcon" />
                <span class="ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
            <b-link
              id="view"
              :to="{ name: 'administration-profile-invitations-view', params: { id: props.row.id } }"
            >
              <b-badge
                pill
                variant="light-primary"
              ><feather-icon icon="EyeIcon" />
              </b-badge>
            </b-link>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="mt-1">
            <img
              class="mlr50 img-fluid"
              width="24"
              height="24"
              :src="require('@/assets/images/icons/profile-100.png')"
              alt="image slot"
            >
            <span class="ml-1">Profile completed</span>
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="invitationsResponse.count"
              first-number
              last-number
              align="right"
              :per-page="1"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BBadge, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BDropdown, BDropdownItem, BButton, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Invitations from '@/js/profileinvitations'
import LIST from '@/js/lists'

export default {
  name: 'TimesheetInvitations',
  components: {
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    BBadge,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BLink,
    BDropdown,
    BDropdownItem,
    vSelect,

    // vSelect,
  },
  data() {
    return {
      status_id: null,
      statusList: [],
      searchQuery: '',
      pageLength: 10,
      dir: false,
      invitationsResponse: {
        invitions: [],
        count: 1,
      },
      columns: [
        {
          label: '#',
          field: 'index',
          width: '100px',
        },
        {
          label: 'Name',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Sent On',
          field: 'invitation_date',
          width: '130px',
          sortable: false,
        },
        {
          label: 'Activated On',
          field: 'activation_date',
          width: '130px',
          sortable: false,
        },
        {
          label: 'Status',
          field: 'status',
          width: '140px',
          sortable: false,
        },
        {
          label: 'Admin Approved',
          field: 'admin_approved',
          width: '160px',
          sortable: false,
        },
        {
          label: 'Resend',
          field: 'cellRendererResendButton',
          width: '110px',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          width: '130px',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Sent',
        2: 'Activated',
        3: 'Filled',
        4: 'Terminated',
      },
      {
        1: 'light-warning',
        2: 'light-primary',
        3: 'light-success',
        4: 'light-danger',
      }],
    }
  },
  watch: {
    '$store.state.lists.adLists': {
      immediate: true,
      handler() {
        LIST.getProfileInvitationStatusFullList(this)
      },
    },
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
        Invitations.getInvitations(this)
      },
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Sent        : 'light-primary',
        Activated   : 'light-success',
        Paused      : 'light-warning',
        Terminated  : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    LIST.getProfileInvitationStatusFullList(this)
    this.searchQuery = this.$store.state.query.query
    this.invitationsResponse.count = this.$store.state.query.page
    Invitations.getInvitations(this)
  },
  methods: {
    confirmDeleteRecord(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${obj.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          Invitations.delete(this, obj.id)
        }
      })
    },
    invitationDeleted() {
      Invitations.getInvitations(this)
      this.$swal({
        icon: 'success',
        title: 'Invitation Deleted!',
        text: 'Your Invitation has been deleted',
      })
    },
    invitationNotDeleted() {
      this.$swal({
        icon: 'warning',
        title: 'Invitation Not Deleted!',
        text: 'Your Invitation has been not deleted',
      })
    },
    statusChanged() {
      this.currentPage = 1
    },
    resendEmail(obj) {
      Invitations.resendEmail(this, obj.id)
    },
    resendEmailResponse(msg) {
      this.$swal({
        icon: 'success',
        title: 'Resend',
        text: msg,
      })
    },
    resendEmailResponseFail(msg) {
      this.$swal({
        icon: 'warning',
        title: 'Resend',
        text: msg,
      })
    },
    setStatus(statusList) {
      this.statusList = statusList
    },
    updateSearchQuery() {
      this.$store.state.query.query = this.searchQuery
      this.currentPage = 1
    },
    setInvitations(invitationsResponse) {
      this.invitationsResponse = invitationsResponse
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
