import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.hrurl
const prospect = `${HOST}prospect`

export default {
  getProspectBySkill(context1) {
    const context = context1
    axios.get(`${prospect}/by-skill`).then(response => {
      context.setProspectBySkillCount(response.data)
    }, error => {
      context.setProspectBySkillCountNotRender(error.response.data.id)
    })
  },
  addProspect(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(prospect, body).then(response => {
      context.$vs.loading.close()
      context.$store.state.prospects.prospectsResponse.prospects = [response.data.prospect, ...context.$store.state.prospects.prospectsResponse.prospects]
      context.$store.state.lists.commonLists.lists['employees-all'] = response.data.full_list
      context.$store.state.lists.commonLists.lists.source_types = response.data.source_types
      context.$store.state.top_counts.not_registrants = response.data.prospects_not_registrant
      context.prospectAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.prospectNotAdded(error.response.data.return_message)
    })
  },
  updateProspect(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${prospect}/${id}`, body).then(response => {
      context.$vs.loading.close()
      const index = context.$store.state.prospects.prospectsResponse.prospects.findIndex(item => item.id === id)
      if (index !== -1) {
        context.$store.state.prospects.prospectsResponse.prospects[index] = response.data.prospect
      }
      context.$store.state.lists.commonLists.lists['employees-all'] = response.data.full_list
      context.prospectUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.prospectNotUpdated(error.response.data.return_message)
    })
  },
  stopProspect(context1, id, stopped) {
    const context = context1
    context.$vs.loading()
    const type = stopped ? 'active' : 'stopped'
    axios.get(`${prospect}/${id}/${type}`).then(response => {
      context.$vs.loading.close()
      context.prospectStopped(response.data)
    }, error => {
      context.$vs.loading.close()
      context.prospectNotFound(error.response.data.return_message)
    })
  },
  getProspectById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${prospect}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setProspect(response.data)
    }, error => {
      context.$vs.loading.close()
      context.prospectNotFound(error.response.data.return_message)
    })
  },
  getJobAppliedProspects(context1) {
    const context = context1
    context.$vs.loading()
    const params = {
      params: {
        query: context.searchQuery, page: context.currentPage - 1, facility_id: context.facility_id, skill_id: context.skill_id, state_id: context.state_id, city_id: context.city_id, status_id: context.status_id,
      },
    }
    axios.get(`${prospect}/job-applied`, params).then(response => {
      context.$vs.loading.close()
      context.$store.state.top_counts.applied_prospects = response.data.prospects_applied_jobs
      context.setProspects(response.data)
    }, error => {
      context.$vs.loading.close()
      context.setProspectNotFound(error.response.data.id)
    })
  },
  getNewJobProspects(context1) {
    const context = context1
    context.$vs.loading()
    const params = {
      params: {
        query: context.searchQuery, page: context.currentPage - 1,
      },
    }
    axios.get(`${prospect}/new-job`, params).then(response => {
      context.$vs.loading.close()
      context.$store.state.top_counts.eligible_prospects = response.data.prospects_new_jobs
      context.setProspects(response.data)
    }, error => {
      context.$vs.loading.close()
      context.setProspectNotFound(error.response.data.id)
    })
  },
  getNewJobRegistrants(context1) {
    const context = context1
    context.$vs.loading()
    const params = {
      params: {
        query: context.searchQuery, page: context.currentPage - 1,
      },
    }
    axios.get(`${prospect}/registrant-eligible`, params).then(response => {
      context.$vs.loading.close()
      if (context.searchQuery === '' || context.searchQuery === null || context.searchQuery === undefined) {
        context.$store.state.top_counts.eligible_registrants = response.data.prospects_new_jobs
      }
      context.setProspects(response.data)
    }, error => {
      context.$vs.loading.close()
      context.setProspectNotFound(error.response.data.id)
    })
  },
  getProspectRegistrants(context1) {
    const context = context1
    context.$vs.loading()
    const params = { params: { query: context.searchQuery, page: context.currentPage - 1 } }
    axios.get(`${prospect}/registrant`, params).then(response => {
      context.$vs.loading.close()
      context.$store.state.top_counts.registrants = response.data.prospects_registrant
      context.setProspects(response.data)
    }, error => {
      context.$vs.loading.close()
      context.setProspectNotFound(error.response.data.id)
    })
  },
  getProspectNonRegistrants(context1) {
    const context = context1
    context.$vs.loading()
    const params = { params: { query: context.searchQuery, page: context.currentPage - 1 } }
    axios.get(`${prospect}/not-registrant`, params).then(response => {
      context.$vs.loading.close()
      context.$store.state.top_counts.not_registrants = response.data.prospects_not_registrant
      context.setProspects(response.data)
    }, error => {
      context.$vs.loading.close()
      context.setProspectNotFound(error.response.data.id)
    })
  },
  getProspectByState(context1) {
    const context = context1
    axios.get(`${prospect}/by-state`).then(response => {
      context.setProspectByStateCount(response.data)
    }, error => {
      context.setProspectByStateCountNotRender(error.response.data.id)
    })
  },
  getProspectBySource(context1) {
    const context = context1
    axios.get(`${prospect}/by-source`).then(response => {
      context.setProspectBySourceCount(response.data)
    }, error => {
      context.setProspectBySourceCountNotRender(error.response.data.id)
    })
  },
  delete(context1, id, index) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${prospect}/${id}`).then(response => {
      context.$vs.loading.close()
      context.prospectDeleted(response.data, index)
      context.$store.state.lists.commonLists.lists['employees-all'] = response.data.full_list
    }, error => {
      context.$vs.loading.close()
      context.prospectNotDeleted(error.response.data.return_message)
    })
  },
}
