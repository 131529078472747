import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index'

const userDefaults = {
  uid: 0,
  displayName: '',
  about: '',
  email: '',
  photoURL: '@/assets/images/portrait/small/avatar-s-11.jpg',
  status: '',
  userRole: '',
}

const getAlerts = () => window.localStorage.getItem('alerts', 0)
const userInfoLocalStorage = JSON.parse(window.localStorage.getItem('userInfo')) || {}
const userRoles = JSON.parse(window.localStorage.getItem('user_roles')) || { devisions: {} }
const topCounts = JSON.parse(window.localStorage.getItem('top_counts')) || {
  registrants: 0, not_registrants: 0, applied_prospects: 0, eligible_prospects: 0, urgent_jobs: 0, alerts: 0,
}

const beforeList = JSON.parse(window.localStorage.getItem('lists')) || {
  commonLists: {}, hrLists: {}, faLists: {}, adLists: {}, moduleLists: {}, cities: {},
}

const getCommonLists = () => {
  const allModulesLists = {
    commonLists: {}, hrLists: {}, faLists: {}, adLists: {}, moduleLists: {}, cities: {},
  }
  Object.keys(beforeList).forEach(key => {
    if (allModulesLists[key] === undefined && beforeList[key] !== null) allModulesLists[key] = beforeList[key]
  })

  return allModulesLists
}

const getRoles = () => {
  const roles = {}
  Object.keys(userRoles).forEach(key => {
    if (roles[key] === undefined && userRoles[key] !== null) roles[key] = userRoles[key]
  })

  return roles
}

const getTopCounts = () => {
  const counts = {}
  Object.keys(topCounts).forEach(key => {
    if (counts[key] === undefined && topCounts[key] !== null) counts[key] = topCounts[key]
  })

  return counts
}

const getUserInfo = () => {
  const userInfo = {}

  Object.keys(userDefaults).forEach(key => {
    userInfo[key] = userInfoLocalStorage[key] ? userInfoLocalStorage[key] : userDefaults[key]
  })

  Object.keys(userInfoLocalStorage).forEach(key => {
    if (userInfo[key] === undefined && userInfoLocalStorage[key] !== null) userInfo[key] = userInfoLocalStorage[key]
  })

  return userInfo
}

const getCounts = () => {
  const counts = {
    employees_count: 0,
    consultants_count: 0,
    prospects_count: 0,
    facilities_count: 0,
    jobs_count: 0,
    endclients_count: 0,
    employers_count: 0,
    suppliers_count: 0,
    referrers_count: 0,
    subfacilities_count: 0,
    payable_pending_count: 0,
    payable_pending_amount: 0,
    receivable_pending_count: 0,
    receivable_pending_amount: 0,
    timesheets_waiting_count: 0,
    timesheets_received_count: 0,
    upcomming_interviews_count: 0,
    pending_tasks_count: 0,
  }
  return counts
}

const getMonthConfig = () => {
  const configs = {
    plugins: [
      monthSelectPlugin({
        shorthand: true,
        dateFormat: 'M-Y',
        altFormat: 'F, Y',
      }),
    ],
  }
  return configs
}

const getTillNowMonthConfig = () => {
  const configs = {
    plugins: [
      monthSelectPlugin({
        shorthand: true,
        dateFormat: 'M-Y',
        altFormat: 'F, Y',
      }),
    ],
    minDate: new Date(2010, 1, 1),
    maxDate: new Date(),
  }
  return configs
}

const getDateConfig = () => {
  const configs = {
    wrap: true, // set wrap to true only when using 'input-group'
    altFormat: 'm-d-Y',
    altInput: true,
    dateFormat: 'm-d-Y',
    minDate: new Date(2000, 1, 1),
  }
  return configs
}

const getMaxDateConfig = () => {
  const configs = {
    wrap: true, // set wrap to true only when using 'input-group'
    altFormat: 'm-d-Y',
    altInput: true,
    dateFormat: 'm-d-Y',
    maxDate: new Date(),
    minDate: new Date(1900, 1, 1),
  }
  return configs
}

// Check if device is touch device
// This is used to remove perfect scrollbar from touch devices
// Using Dynamic components
const isTouchDevice = () => {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
  const mq = query => window.matchMedia(query).matches

  if ('ontouchstart' in window || window.DocumentTouch) {
    return true
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('')
  return mq(query)
}

const state = {
  AppActiveUser: getUserInfo(),
  roles: getRoles(),
  alerts: getAlerts(),
  lists: getCommonLists(),
  company_name: '',
  company_logo: '',
  bodyOverlay: false,
  isVerticalNavMenuActive: true,
  isTouchDevice: isTouchDevice(),
  verticalNavMenuWidth: 'default',
  verticalNavMenuItemsMin: false,
  scrollY: 0,
  monthConfig: getMonthConfig(),
  maxMonthConfig: getTillNowMonthConfig(),
  config: getDateConfig(),
  notGraterThanToday: getMaxDateConfig(),
  quickProspectEmail: '',
  prospects: {
    isProspectsLoaded: false,
    prospectsResponse: {
      prospects: [],
      count: 1,
    },
  },
  top_counts: getTopCounts(),
  groupJObs: {
    isContractJobsLoaded: false,
    contractJobs: {},
    isPermanentJobsLoaded: false,
    permanentJobs: {},
    isGroupJobsLoaded: false,
    groupJobs: {},
    isPrnJobsLoaded: false,
    prnJobs: {},
  },
  closedJobs: {
    isJobsLoaded: false,
    jobs: {},
  },
  indeedgroupJObs: {
    isContractJobsLoaded: false,
    contractJobs: {},
    isPermanentJobsLoaded: false,
    permanentJobs: {},
    isGroupJobsLoaded: false,
    groupJobs: {},
    isPrnJobsLoaded: false,
    prnJobs: {},
  },
  query: {
    prnPerPage: 10, groupjobsprnpage: 1, activeTab: 'Monthly', status_id: null, jobType: 'contract', jobsActiveTab: 0, groupjobspermpage: 1, jobsPerpage: 10, permPerPage: 10, perPage: 10, groupjobspage: 1, page: 1, month: null, startdate: null, endDate: null, query: '', innerpage: 1, innerQuery: '', parent_type: null, parent_type_id: null, parent_id: null, type: null, group_job_per_page: 10, group_job_page: 1, group_job_skill_id: null, group_job_city_id: null, group_job_Type: 'contract', indeedperPage: 10, indeedgroupjobspage: 1, indeedpermPerPage: 10, indeedgroupjobspermpage: 1, indeedprnPerPage: 10, indeedgroupjobsprnpage: 1, indeedjobsActiveTab: 'contract', indeedjobType: 'contract', closedperPage: 10,
  },
  padvreq: {
    query: '',
    page: 0,
    skill_id: null,
    secondary_skill_ids: [],
    resident_state_ids: [],
    preferred_state_ids: [],
    min_experience: 0,
    max_experience: 0,
  },
  qpadvreq: {
    page: 0,
    skills: [],
    visa_type_ids: [],
    source_ids: [],
    status_ids: [],
    experience: 0,
    resume: false,
    linkedin: false,
    email: false,
    phone: false,
    verified: false,
    relocate: false,
    w2: false,
    c2c: false,
  },
  parentQuery: {
  },
  counts: getCounts(),
  scheduleCandidateInteview: {
    prospectId: null,
  },
  taskDetails: {},
  windowWidth: null,
}

export default state
