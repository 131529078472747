<template>
  <b-card>
    <b-row class="mb-1">
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Operating Expenses</h4>
      </b-col>
      <b-col
        cols="12"
        md="2 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="4"
        class="col-xxl-3"
      >
        <b-form-group
          label="From"
          label-cols-md="3"
          class="text-right"
        >
          <flat-pickr
            v-model="from_date"
            class="form-control"
            :config="startDateConfig"
            placeholder="MM-DD-YYYY"
            clear-button="true"
            @on-change="startDateChanged"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
        class="col-xxl-3"
      >
        <b-form-group
          label="To"
          label-cols-md="3"
          class="text-right"
        >
          <flat-pickr
            v-model="to_date"
            class="form-control"
            :config="endDateConfig"
            placeholder="MM-DD-YYYY"
            clear-button="true"
            @on-change="endDateChanged"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="2 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="primary"
            class="mr-1"
            @click="getReport"
          >
            Get
          </b-button>
          <b-button
            variant="primary"
            @click="getPdfReport"
          >
            PDF
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <b-row class="invoice-preview">
      <b-col
        cols="12"
        class="col-xxl-10 offset-xxl-1"
      >
        <b-card-body class="px-0 pt-0">
          <b-card
            v-for="(expenseItem, index1) in reportResponse.expenses"
            :key="index1"
            class="border"
            no-body
          >
            <b-card-body
              v-if="expenseItem.expenses_list.length !== 1"
              class="p-1"
            >
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-toggle="'collapse-1-inner' + index1"
                size="sm"
                variant="flat-secondary"
                class="btn-collapse font-medium-2 d-block w-100 text-left"
                @click="singleIndex = index1"
              >
                <div class="d-flex justify-content-between">
                  <span>{{ expenseItem.name }}</span>
                  <span>{{ expenseItem.amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
                </div>
              </b-button>
              <b-collapse
                v-if="singleIndex === index1"
                :id="'collapse-1-inner' + index1"
                class="mt-2"
              >
                <b-card
                  v-for="(expense, index) in expenseItem.expenses_list"
                  :key="index"
                  class="border mb-1"
                  no-body
                >
                  <b-card-body class="p-1">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-toggle="'collapse-1-inner' + index"
                      size="sm"
                      variant="flat-secondary"
                      class="btn-collapse font-medium-2 d-block w-100 text-left"
                      @click="setSelection(index1, index)"
                    >
                      <div class="d-flex justify-content-between">
                        <span>{{ expense.name }}</span>
                        <span>{{ expense.amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
                      </div>
                    </b-button>
                    <b-collapse
                      v-if="parentIndex === index1 && childIndex === index"
                      :id="'collapse-1-inner' + index"
                      class="mt-2"
                    >
                      <b-card class="custom-body-padding mb-0">
                        <div class="table-responsive mb-0">
                          <table class="table b-table table-bordered custom-reports-table">
                            <thead>
                              <tr>
                                <th class="th-w-100">
                                  #</th>
                                <th>Date</th>
                                <th class="text-right">
                                  <span>Amount</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="expenseListItem in expense.expenses"
                                :key="expenseListItem"
                              >
                                <th>{{ expenseListItem.index }}</th>
                                <td>{{ expenseListItem.date }}</td>
                                <td class="text-right">
                                  {{ expenseListItem.amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-card>
                    </b-collapse>
                  </b-card-body>
                </b-card>
              </b-collapse>
            </b-card-body>
            <b-card-body
              v-if="expenseItem.expenses_list.length === 1"
              class="p-1"
            >
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-toggle="'collapse-1-inner' + index1"
                size="sm"
                variant="flat-secondary"
                class="btn-collapse font-medium-2 d-block w-100 text-left"
                @click="singleIndex = index1"
              >
                <div class="d-flex justify-content-between">
                  <span>{{ expenseItem.expenses_list[0].name }}</span>
                  <span>{{ expenseItem.expenses_list[0].amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
                </div>
              </b-button>
              <b-collapse
                v-if="singleIndex === index1"
                :id="'collapse-1-inner' + index1"
                class="mt-2"
              >
                <b-card class="custom-body-padding mb-0">
                  <div class="table-responsive mb-0">
                    <table class="table b-table table-bordered custom-reports-table">
                      <thead>
                        <tr>
                          <th class="th-w-100">
                            #</th>
                          <th>Date</th>
                          <th class="text-right">
                            <span>Amount</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(expenseItem2, index2) in expenseItem.expenses_list[0].expenses"
                          :key="index2"
                        >
                          <th>{{ expenseItem2.index }}</th>
                          <td>{{ expenseItem2.date }}</td>
                          <td class="text-right">
                            {{ expenseItem2.amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-card>
              </b-collapse>
            </b-card-body>
          </b-card>
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BFormGroup, BCardBody, BCollapse, VBToggle,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import Reports from '@/js/financialstatementreports'
import moment from 'moment'

export default {
  name: 'OperatingExpenses',
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BCollapse,
    BButton,
    flatPickr,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      parentIndex: '',
      childIndex: '',
      singleIndex: '',
      from_date: null,
      to_date: null,
      reportResponse: {
        expenses: {},
      },
      startDateConfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'm-d-Y',
        altInput: true,
        dateFormat: 'm-d-Y',
        minDate: new Date(2000, 1, 1),
      },
      endDateConfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'm-d-Y',
        altInput: true,
        dateFormat: 'm-d-Y',
        minDate: new Date(2000, 1, 1),
      },
    }
  },
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
  methods: {
    setSelection(parentIndex, childIndex) {
      this.parentIndex = parentIndex
      this.childIndex = childIndex
    },
    startDateChanged() {
      this.$set(this.endDateConfig, 'minDate', new Date(moment(this.from_date).format('MM-DD-YYYY')))
    },
    endDateChanged() {
      this.$set(this.startDateConfig, 'maxDate', new Date(moment(this.to_date).format('MM-DD-YYYY')))
    },
    getReport() {
      if (this.from_date !== null && this.to_date !== null) {
        Reports.getAccountOperationalReport(this, this.from_date, this.to_date)
      }
    },
    getPdfReport() {
      if (this.from_date !== null && this.to_date !== null) {
        Reports.getAccountOperationalReportPdf(this, this.from_date, this.to_date)
      }
    },
    setReport(reportResponse) {
      this.reportResponse = reportResponse
    },
    pdfPath(data) {
      window.open(data.pdf_path, '_blank')
    },
    pdfNotFound(message) {
      this.$swal({
        icon: 'warning',
        title: 'Resend!',
        text: message,
      })
    },
    back() {
      this.$router.push('/financial-statements/reports').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
