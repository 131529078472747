<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          View Role</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>

    <app-timeline>
      <app-timeline-item class="pb-1">
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Role</label>
              <span
                class="form-control"
                v-text="addRole.role"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Report to Role</label>
              <span
                class="form-control h-auto"
                v-text="addRole.reporting_user_type"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Assign Jobs</label>
              <span
                class="form-control"
                v-text="addRole.is_assign_jobs"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Reporting To Admin</label>
              <span
                class="form-control"
                v-text="addRole.is_reporting_admin"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="info"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Select Modules & Sub - Modules</h6>
        </div>
        <b-row
          v-for="(departmentDevision, index) in Object.keys(addRole.divisions)"
          :key="index"
          :value="index"
          class="mt-1"
        >
          <b-col cols="12">
            <b-form-checkbox
              v-model="addRole.divisions[departmentDevision].selected"
              class="custom-control-primary mb-1"
              disabled
              @input="departmentSelected(departmentDevision)"
            >
              {{ departmentDevision }}
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
            class="pl-5"
          >
            <b-row v-show="addRole.divisions[departmentDevision].selected">
              <b-col
                v-for="(devision, newindex) in addRole.divisions[departmentDevision]"
                :key="newindex"
                :value="newindex"
                cols="3"
              >
                <b-form-checkbox
                  v-model="devision.selected"
                  class="custom-control-primary mb-1"
                  disabled
                  @input="divisionselected(departmentDevision, devision.selected)"
                >
                  {{ devision.name }}
                </b-form-checkbox>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Roles from '@/js/roles'

export default {
  name: 'RoleView',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BButton,
  },
  directives: {
  },
  setup() {
  },
  data() {
    return {
      addRole: {},
    }
  },
  mounted() {
  },
  created() {
    Roles.getRoleById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    setRole(data) {
      this.addRole = data
      Object.keys(this.addRole.divisions).forEach(key => {
        Object.keys(this.addRole.divisions[key]).forEach(devision => {
          if (this.addRole.divisions[key][devision].selected === true) {
            this.addRole.divisions[key].selected = true
          }
        })
      })
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
