<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Client Letter</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer
      v-show="!showTemplate"
      ref="simpleRules"
    >
      <b-form class="ex-large">
        <app-timeline>
          <app-timeline-item class="pb-1">
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Client Details</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Client Company Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Client Company Name"
                    rules="required|min:2"
                  >
                    <b-form-input
                      id="client_company_name"
                      v-model="addClientLetter.client_company_name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Client Company Address</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Client Company Address"
                    rules="required|min:2"
                  >
                    <b-form-input
                      id="client_company_address"
                      v-model="addClientLetter.client_company_address"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Client Logo</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Client Logo"
                  >
                    <input
                      ref="myFileInput"
                      type="file"
                      class="form-control text-left"
                      @change="attahamentFileSelected"
                    >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Client Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Client Name"
                    rules="required|min:2"
                  >
                    <b-form-input
                      id="client_name"
                      v-model="addClientLetter.client_name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Client Title</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Client Title"
                    rules="required|min:2"
                  >
                    <b-form-input
                      id="client_title"
                      v-model="addClientLetter.client_title"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Client Email</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Client Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="client_address"
                      v-model="addClientLetter.client_email"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Client Phone</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Client Phone"
                    rules="required|integer|digits:10"
                  >
                    <b-form-input
                      id="client_address"
                      v-model="addClientLetter.client_phone"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Title</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Title"
                    rules="required|min:2"
                  >
                    <b-form-input
                      id="title"
                      v-model="addClientLetter.title"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>First Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="First Name"
                    rules="required|min:2"
                  >
                    <b-form-input
                      id="first_name"
                      v-model="addClientLetter.first_name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Last Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Last Name"
                    rules="required|min:2"
                  >
                    <b-form-input
                      id="last_name"
                      v-model="addClientLetter.last_name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Job Title</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Job Title"
                    rules="required"
                  >
                    <b-form-input
                      id="job_title"
                      v-model="addClientLetter.job_title"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Project</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Project"
                    rules="required"
                  >
                    <b-form-input
                      id="project"
                      v-model="addClientLetter.project"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Hours/Week</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Hours/Week"
                    rules="required|integer"
                  >
                    <b-form-input
                      id="hours"
                      v-model="addClientLetter.hours"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>End Date</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="End Date"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="end_date"
                      class="form-control"
                      :config="$store.state.config"
                      placeholder="MM-DD-YYYY"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group>
                  <label>Resposibilities</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Resposibilities"
                    rules="required"
                  >
                    <quill-editor
                      v-model="addClientLetter.responsibilities"
                      :options="snowOption"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="mt-1">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              class="mx-2"
              @click="generatePdf"
            >
              Generate Pdf
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import {
  required,
} from '@validations'
import ClientLetter from '@/js/clientletter'
import Constants from '@/js/constant'

export default {
  name: 'ClientLetter',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    quillEditor,
    flatPickr,
    // currency,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      addClientLetter: {
        client_name: '',
        client_address: '',
        client_email: '',
        client_phone: '',
        first_name: '',
        last_name: '',
        email: '',
        job_title: '',
        project: '',
        hours_week: '',
        end_date: null,
        client_company_logo: null,
        content: '',
      },
      date: null,
      end_date: null,
      formstate: {},
      submitted: false,
    }
  },
  watch: {
  },
  mounted() {
  },
  created() {
    ClientLetter.getClientLetterById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    attahamentFileSelected(obj) {
      const dis = this
      const fileReader = new FileReader()
      fileReader.onload = fileLoadEvent => {
        dis.addClientLetter.client_company_logo = fileLoadEvent.target.result
      }
      fileReader.readAsDataURL(obj.target.files[0])
    },
    generatePdf() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          this.showLoading = true
          if (this.addClientLetter.client_company_logo === null || this.addClientLetter.client_company_logo === undefined || this.addClientLetter.client_company_logo === '') {
            this.$swal({
              icon: 'warning',
              title: 'Logo',
              text: 'Please select client logo',
            })
            return
          }
          if (this.end_date != null) {
            this.addClientLetter.end_date = Constants.getEprmMonthDateFromString(
              this.end_date,
            )
          }
          ClientLetter.getClientLetterPdfById(this, this.$route.params.id, this.addClientLetter)
        }
      })
    },
    setEmployeeDetails(employeeDetails) {
      this.addClientLetter = employeeDetails
      this.addClientLetter.valid_for = 5
    },
    pdfPath(data) {
      window.open(data.pdf_path, '_blank')
      this.back()
    },
    pdfNotFound(message) {
      this.$swal({
        icon: 'warning',
        title: 'Employer Letter!',
        text: message,
      })
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
