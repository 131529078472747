import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.hrurl
const job = `${HOST}jobs`

export default {
  getJobs(context1) {
    const context = context1
    context.$vs.loading()
    if (!context.advertised_jobs) {
      context.advertised_jobs = undefined
    }
    const params = {
      params: {
        position_type_id: context.$store.state.query.position_type, is_advertised: context.advertised_jobs, type: context.$store.state.query.job_type, status_id: context.$store.state.query.status_id, assigned_user_id: context.$store.state.query.assigned_to_id, facility_id: context.$store.state.query.facility_id, vendor_id: context.$store.state.query.vendor_id, skill_id: context.$store.state.query.skill_id, state_id: context.$store.state.query.state_id, city_id: context.$store.state.query.city_id, query: context.$store.state.query.query, page: context.$store.state.query.page - 1,
      },
    }
    axios.get(job, params).then(response => {
      context.$vs.loading.close()
      context.setJobs(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getJobsByGroupByCitySkill(context1, type, city, stateId, skillId) {
    const context = context1
    context.$vs.loading()
    const params = {
      params: {
        skill_id: skillId, type, city, state_id: stateId, query: context.$store.state.query.innerquery, page: context.$store.state.query.innerpage - 1,
      },
    }
    axios.get(`${job}/group-by-city-skill`, params).then(response => {
      context.$vs.loading.close()
      context.setJobs(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getJobsByGroupByCity(context1, type, skillId, city, stateId, facilityId, billRate) {
    const context = context1
    context.$vs.loading()
    const params = {
      params: {
        skill_id: skillId, type, city, state_id: stateId, facility_id: facilityId, bill_rate: billRate, query: context.$store.state.query.innerquery, page: context.$store.state.query.innerpage - 1,
      },
    }
    axios.get(`${job}/group-by-city/jobs`, params).then(response => {
      context.$vs.loading.close()
      context.setJobs(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getJobsGroupByCity(context1, type) {
    const context = context1
    context.$vs.loading()
    const params = {
      params: {
        page: context.$store.state.query.groupjobspage - 1, type,
      },
    }
    axios.get(`${job}/group-by-city`, params).then(response => {
      context.$vs.loading.close()
      if (type === 'permanent') {
        context.setJobs(response.data)
      } else if (type === 'prn') {
        context.setPrnJobs(response.data)
      } else {
        context.setContractJobs(response.data)
      }
    }, () => {
      context.$vs.loading.close()
    })
  },
  getClosedIndeedJobs(context1) {
    const context = context1
    context.$vs.loading()
    axios.get(`${job}/closed-jobs`).then(response => {
      context.$vs.loading.close()
      context.setJobs(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getIndeedJobsGroupByCity(context1, type) {
    const context = context1
    context.$vs.loading()
    const params = {
      params: {
        page: context.$store.state.query.groupjobspage - 1, type,
      },
    }
    axios.get(`${job}/indeed/group-by-city`, params).then(response => {
      context.$vs.loading.close()
      if (type === 'permanent') {
        context.setJobs(response.data)
      } else if (type === 'prn') {
        context.setPrnJobs(response.data)
      } else {
        context.setContractJobs(response.data)
      }
    }, () => {
      context.$vs.loading.close()
    })
  },
  getUrgentJobs(context1) {
    const context = context1
    const params = {
      params: {
        query: context.searchQuery, page: context.$store.state.query.inner_page - 1,
      },
    }
    context.$vs.loading()
    axios.get(`${job}/urgent-jobs`, params).then(response => {
      context.$vs.loading.close()
      context.setJobs(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  makeJobsUrgent(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${job}/make-urgent`, body).then(response => {
      context.$vs.loading.close()
      context.jobsUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.jobsNotUpdated(error.response.data.return_message)
    })
  },
  makeJobsNormal(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${job}/${id}/make-normal`).then(response => {
      context.$vs.loading.close()
      context.jobUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.jobsNotUpdated(error.response.data.return_message)
    })
  },
  addJob(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(job, body).then(response => {
      context.$vs.loading.close()
      context.jobAdded(response.data)
      context.$store.state.lists.commonLists.lists.job_facilities = response.data.facilities
      context.$store.state.lists.commonLists.lists.job_skills = response.data.skills
      context.$store.state.lists.commonLists.lists.job_cities = response.data.cities
      context.$store.state.lists.commonLists.lists.job_states = response.data.states
      context.$store.state.lists.commonLists.lists['job-users'] = response.data.users
    }, error => {
      context.$vs.loading.close()
      context.jobNotAdded(error.response.data.return_message)
    })
  },
  updateJob(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${job}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.jobUpdated(response.data)
      context.$store.state.lists.commonLists.lists.job_facilities = response.data.facilities
      context.$store.state.lists.commonLists.lists.job_skills = response.data.skills
      context.$store.state.lists.commonLists.lists.job_cities = response.data.cities
      context.$store.state.lists.commonLists.lists.job_states = response.data.states
      context.$store.state.lists.commonLists.lists['job-users'] = response.data.users
    }, error => {
      context.$vs.loading.close()
      context.jobNotUpdated(error.response.data.return_message)
    })
  },
  assignUsersToJob(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${job}/${id}/assign`, body).then(response => {
      context.$vs.loading.close()
      context.$store.state.lists.commonLists.lists.job_facilities = response.data.facilities
      context.$store.state.lists.commonLists.lists.job_skills = response.data.skills
      context.$store.state.lists.commonLists.lists.job_cities = response.data.cities
      context.$store.state.lists.commonLists.lists.job_states = response.data.states
      context.$store.state.lists.commonLists.lists['job-users'] = response.data.users
      context.usersAssigned(response.data)
    }, error => {
      context.$vs.loading.close()
      context.usersNotAssigned(error.response.data.return_message)
    })
  },
  closeIndeedJob(context1, id, index) {
    const context = context1
    context.$vs.loading()
    axios.get(`${job}/${id}/closed`).then(response => {
      context.$vs.loading.close()
      context.jobClosed(response.data, index)
    }, error => {
      context.$vs.loading.close()
      context.jobNotClosed(error.response.data.return_message)
    })
  },
  updateIndeedJob(context1, id, index) {
    const context = context1
    context.$vs.loading()
    axios.get(`${job}/${id}/updated`).then(response => {
      context.$vs.loading.close()
      context.jobUpdated(response.data, index)
    }, error => {
      context.$vs.loading.close()
      context.jobNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${job}/${id}`).then(response => {
      context.$vs.loading.close()
      context.$store.state.lists.commonLists.lists.job_facilities = response.data.facilities
      context.$store.state.lists.commonLists.lists.job_skills = response.data.skills
      context.$store.state.lists.commonLists.lists.job_cities = response.data.cities
      context.$store.state.lists.commonLists.lists.job_states = response.data.states
      context.$store.state.lists.commonLists.lists['job-users'] = response.data.users
      context.jobDeleted(response.data)
    }, error => {
      context.$vs.loading.close()
      context.jobNotDeleted(error.response.data.return_message)
    })
  },
  getJobsFullList(context1) {
    const context = context1
    context.$vs.loading()
    axios.get(`${job}/external/fulllist`).then(response => {
      context.$vs.loading.close()
      context.setJobs(response.data)
    }, error => {
      context.$vs.loading.close()
      context.jobNotFound(error.response.data.return_message)
    })
  },
  getJobsFullListByProspectId(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${job}/prospect/${id}/fulllist`).then(response => {
      context.$vs.loading.close()
      context.setJobs(response.data)
    }, error => {
      context.$vs.loading.close()
      context.jobNotFound(error.response.data.return_message)
    })
  },
  getJobsFullListByCandidateId(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${job}/${id}/fulllist`).then(response => {
      context.$vs.loading.close()
      context.setJobs(response.data)
    }, error => {
      context.$vs.loading.close()
      context.jobNotFound(error.response.data.return_message)
    })
  },
  getJobById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${job}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setJob(response.data)
    }, error => {
      context.$vs.loading.close()
      context.jobNotFound(error.response.data.return_message)
    })
  },

}
