<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Create Task</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>

    <app-timeline>
      <app-timeline-item
        v-if="type === 'employerinvoice'"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Invoice Details</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Employer
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="$store.state.taskDetails.user_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Consultant
              </dt>
              <dd class="col-sm-12">
                <div
                  v-for="(option, index) in $store.state.taskDetails.employee_names"
                  :key="index"
                >
                  <span
                    :key="index"
                    class="form-control"
                    v-text="option"
                  />
                </div>
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Invoice #
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="$store.state.taskDetails.name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Received Date
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="$store.state.taskDetails.received_date"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Invoice Period
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="$store.state.taskDetails.period"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        v-if="type === 'subfacilityinvoice'"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Invoice Details</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                SubFacility
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="$store.state.taskDetails.user_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Consultant
              </dt>
              <dd class="col-sm-12">
                <div
                  v-for="(option, index) in $store.state.taskDetails.employee_names"
                  :key="index"
                >
                  <span
                    :key="index"
                    class="form-control"
                    v-text="option"
                  />
                </div>
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Invoice #
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="$store.state.taskDetails.name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Received Date
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="$store.state.taskDetails.received_date"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Invoice Period
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="$store.state.taskDetails.period"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        v-if="type === 'facilityinvoice'"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Invoice Details</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Facility
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="$store.state.taskDetails.user_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Consultant
              </dt>
              <dd class="col-sm-12">
                <div
                  v-for="(option, index) in $store.state.taskDetails.employee_names"
                  :key="index"
                >
                  <span
                    :key="index"
                    class="form-control"
                    v-text="option"
                  />
                </div>
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Invoice #
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="$store.state.taskDetails.name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Received Date
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="$store.state.taskDetails.received_date"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Invoice Period
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="$store.state.taskDetails.period"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        v-if="type === 'supplierinvoice'"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Invoice Details</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Supplier
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="$store.state.taskDetails.user_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Consultant
              </dt>
              <dd class="col-sm-12">
                <div
                  v-for="(option, index) in $store.state.taskDetails.employee_names"
                  :key="index"
                >
                  <span
                    :key="index"
                    class="form-control"
                    v-text="option"
                  />
                </div>
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Invoice #
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="$store.state.taskDetails.name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Received Date
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="$store.state.taskDetails.received_date"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Invoice Period
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="$store.state.taskDetails.period"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        v-if="type === 'referrerinvoice'"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Invoice Details</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Referrer
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="$store.state.taskDetails.user_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Consultant
              </dt>
              <dd class="col-sm-12">
                <div
                  v-for="(option, index) in $store.state.taskDetails.employee_names"
                  :key="index"
                >
                  <span
                    :key="index"
                    class="form-control"
                    v-text="option"
                  />
                </div>
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Invoice #
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="$store.state.taskDetails.name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Received Date
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="$store.state.taskDetails.received_date"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Invoice Period
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="$store.state.taskDetails.period"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        v-if="type === 'timesheet'"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Timesheet Details</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Name of Employee Consultant
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="$store.state.taskDetails.name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Timesheet Frequency
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="$store.state.taskDetails.frequency"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Month
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="$store.state.taskDetails.month"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            v-if="$store.state.taskDetails.frequency === 'weekly'"
            cols="6"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Week
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="$store.state.taskDetails.week"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        v-if="type === 'job'"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Job Details</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Job Title
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="$store.state.taskDetails.title"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Facility
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="$store.state.taskDetails.vedor_name"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        v-if="type === 'parent'"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Parent Details</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="$store.state.taskDetails.name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Email
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="$store.state.taskDetails.email"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Phone
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="$store.state.taskDetails.phone"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        v-if="type === 'interview'"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Interview Details</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Employer Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="$store.state.taskDetails.employer_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Candidate Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="$store.state.taskDetails.employee_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Interview Date
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="$store.state.taskDetails.date"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Interview Time
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="$store.state.taskDetails.time"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Interviewer Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="$store.state.taskDetails.name"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <validation-observer ref="simpleRules">
        <b-form>
          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Task Details</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Task Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Task Name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="addTask.name"
                      name="task-name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Due Date</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Due Date"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="due_date"
                      class="form-control"
                      :config="$store.state.config"
                      placeholder="MM-DD-YYYY"
                      @on-change="dueDateChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="8"
              >
                <b-form-group>
                  <label>Task Description</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Task Description"
                    rules="required"
                  >
                    <b-form-textarea
                      v-model="addTask.description"
                      name="task-description"
                      rows="2"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Status</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Status"
                    rules="required"
                  >
                    <v-select
                      v-model="addTask.status_id"
                      name="task-status"
                      :options="status"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="success"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Comments</h6>
            </div>
            <b-row>
              <b-col
                v-for="(comment, index) in addTask.comments"
                :key="index"
                cols="12"
                md="6"
                class="col-xxl-4"
              >
                <div class="d-flex">
                  <div class="left w-100">
                    <b-form-group>
                      <label>Comment</label>
                      <b-form-textarea
                        v-model="comment.comment"
                        rows="2"
                      />
                    </b-form-group>
                  </div>
                  <div class="right mt-2 ml-1">
                    <b-button
                      v-show="index === 0"
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      @click="addComment"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                    <b-button
                      v-show="index !== 0"
                      variant="outline-danger"
                      class="btn-icon rounded-circle"
                      @click="removeComment(index)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </app-timeline-item>
          <hr>

          <b-row class="ml-2">
            <b-col class="text-right">
              <b-button
                variant="primary"
                type="button"
                @click="submitForm"
              >
                Add Task
              </b-button>
            </b-col>
          </b-row>

        </b-form>
      </validation-observer>
    </app-timeline>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BButton,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import Task from '@/js/tasks'
import Constants from '@/js/constant'
import LIST from '@/js/lists'

export default {
  name: 'NewCreateTask',
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    vSelect,
    BButton,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      addTask: {
        type: '',
        parent_type: '',
        type_id: null,
        due_date: null,
        description: '',
        task_name: '',
        status_id: null,
        created_date: null,
        comments: [],
      },
      type: '',
      due_date: null,
      status: [],
      data: {},
    }
  },
  watch: {
    '$store.state.lists.moduleLists': {
      immediate: true,
      handler() {
        LIST.getTaskStatusFullList(this)
      },
    },
  },
  mounted() {
  },
  created() {
    if (Object.entries(this.$store.state.taskDetails).length === 0 && this.$store.state.taskDetails.constructor === Object) {
      this.back()
    }
    this.addComment()
    this.type = this.$store.state.taskDetails.type
    this.addTask.type = this.$store.state.taskDetails.task_type
    this.addTask.parent_type = this.$store.state.taskDetails.parent_type
    this.addTask.type_id = this.$route.params.id
    this.due_date = moment().format('MM-DD-YYYY')
    this.addTask.created_date = Constants.getEprmMonthDateFromString(moment().format('MM-DD-YYYY'))
    LIST.getTaskStatusFullList(this)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          if (this.due_date != null) {
            this.addTask.due_date = Constants.getEprmMonthDateFromString(this.due_date)
          }
          Task.addTask(this, this.addTask)
        }
      })
    },
    dueDateChanged(config, date) {
      this.due_date = date
    },
    setEmployers(facilities) {
      this.employers = facilities
    },
    setSubFacilities(facilities) {
      this.employers = facilities
    },
    setFacilities(facilities) {
      this.employers = facilities
    },
    setStatus(status) {
      this.status = status
    },
    taskAdded(data) {
      this.$store.state.taskDetails = {}
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    taskNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    addComment() {
      const comment = {
        comment: '',
        date: Constants.getEprmMonthDateFromString(moment()),
        time: moment().format('hh:mm a'),
      }
      this.addTask.comments.push(comment)
    },
    removeComment(index) {
      this.addTask.comments.splice(index, 1)
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.repeater-form {
  transition: .35s height;
}
</style>
