export default [
  {
    title: 'Documents',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Document Tracker',
        route: 'documents-document-tracker',
      },
      {
        title: 'Required Documents',
        route: 'documents-required-documents',
      },
      // {
      //   title: 'Templates',
      //   route: 'documents-templates',
      // },
    ],
  },
]
