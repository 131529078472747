<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Edit Prospect</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form class="ex-large">
        <app-timeline>
          <app-timeline-item class="pb-1">
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Personal Details</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>First Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="First Name"
                    rules="required|min:2"
                  >
                    <b-form-input
                      id="first_name"
                      v-model="addProspect.first_name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Last Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Last Name"
                    rules="required|min:2"
                  >
                    <b-form-input
                      id="last_name"
                      v-model="addProspect.last_name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Email</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    :rules="addProspect.email !== '' || addProspect.phone === '' ? 'required|email' : ''"
                  >
                    <b-form-input
                      id="email"
                      v-model="addProspect.email"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Phone</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Phone"
                    :rules="addProspect.phone !== '' ? 'required|integer|digits:10' : ''"
                  >
                    <b-form-input
                      id="phone"
                      v-model="addProspect.phone"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Gender</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Gender"
                    rules="required"
                  >
                    <v-select
                      id="gender"
                      v-model="addProspect.gender_id"
                      :options="genders"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Source</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Source"
                  >
                    <v-select
                      id="source"
                      v-model="addProspect.source_type_id"
                      :options="sourceTypes"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                      rules="required"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Main Skill</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Main Skill"
                    rules="required"
                  >
                    <v-select
                      id="role"
                      v-model="addProspect.skill_ids"
                      :options="skills"
                      :reduce="item => item.id"
                      multiple
                      :clearable="false"
                      placeholder="Select"
                      @input="skillSelected"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>States</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="States"
                    rules="required"
                  >
                    <v-select
                      id="states"
                      v-model="addProspect.state_id"
                      :options="states"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                      @input="stateChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>City, Zip</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="City, Zip"
                    rules="required"
                  >
                    <v-select
                      id="location"
                      v-model="addProspect.city_id"
                      :options="locations"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Modify Type</label>
                  <v-select
                    v-model="addProspect.type_id"
                    :options="addProspect.other_types"
                    :reduce="item => item.id"
                    :clearable="false"
                    placeholder="Select"
                    @input="onTypeChanged()"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Linkedin URL</label>
                  <b-form-input
                    id="linkedin"
                    v-model="addProspect.linkedin_url"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
                class="mt-2"
              >
                <b-form-group>
                  <b-form-checkbox
                    v-model="addProspect.is_linkedin_checked"
                  >
                    Is Linkedin Checked
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Applied Date</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Applied Date"
                  >
                    <flat-pickr
                      v-model="applied_date"
                      class="form-control"
                      :config="$store.state.config"
                      placeholder="MM-DD-YYYY"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
                v-show="addProspect.attachment !== null"
              >
                <b-form-group>
                  <label>Previous Resume</label>
                  <span class="form-control">
                    <b-link
                      :href="addProspect.attachment_link"
                      :title="addProspect.attachment"
                      target="_blank"
                    >
                      <b-badge
                        variant="primary"
                        class="mr-1 mb-50"
                      >
                        <span>{{ addProspect.attachment }}</span>
                      </b-badge>
                    </b-link>
                  </span>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Resume</label>
                  <input
                    ref="myFileInput"
                    type="file"
                    placeholder="Choose a file or drop it here..."
                    class="form-control text-left"
                    @change="attahamentFileSelected"
                  >
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1">
              <h6>Secondary Skills</h6>
            </div>
            <b-row>
              <b-col
                v-for="technology in newTechnologies"
                :key="technology.id"
                :value="technology.id"
                cols="2"
                class="mb-1"
              >
                <b-form-checkbox
                  :checked="technologySelected(technology.technology_id)"
                  @change="checked(technology.technology_id)"
                >
                  {{ technology.label }}
                </b-form-checkbox>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="mt-1">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Update Prospect
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormCheckbox, BLink, BBadge, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import {
  required,
} from '@validations'
import flatPickr from 'vue-flatpickr-component'
import LIST from '@/js/lists'
import Prospect from '@/js/prospect'
import Technology from '@/js/skillstechnologies'
import Constants from '@/js/constant'

export default {
  name: 'ProspectEdit',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BLink,
    BBadge,
    BButton,
    vSelect,
    flatPickr,
  },
  directives: {
  },
  data() {
    return {
      required,
      addProspect: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        gender_id: null,
        source_id: null,
        skill_ids: null,
        state_id: null,
        city_id: null,
        linkedin_url: '',
        applied_date: null,
        is_linkedin_checked: false,
        file_name: '',
        document: null,
        attachment: '',
        technologies: [],
      },
      previous_type: null,
      previous_type_id: null,
      prospect_type_id: null,
      documentNameId: null,
      applied_date: null,
      genders: [],
      sourceTypes: [],
      skills: [],
      states: [],
      locations: [],
      technologies: [],
      newTechnologies: [],
      documentNames: [],
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getGenderFullList(this)
        LIST.getSkillsFullList(this)
        LIST.getTechnologies(this)
        LIST.getSourceTypes(this)
        LIST.getStatesFullList(this)
      },
    },
  },
  mounted() {
  },
  created() {
    LIST.getGenderFullList(this)
    LIST.getSkillsFullList(this)
    LIST.getTechnologies(this)
    LIST.getSourceTypes(this)
    LIST.getStatesFullList(this)
    Prospect.getProspectById(this, this.$route.params.id)
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          this.showLoading = true
          if (this.applied_date !== null) {
            this.addProspect.applied_date = Constants.getEprmMonthDateFromString(this.applied_date)
          }
          Prospect.updateProspect(this, this.$route.params.id, this.addProspect)
        }
      })
    },
    attahamentFileSelected(obj) {
      const dis = this
      const fileReader = new FileReader()
      fileReader.onload = fileLoadEvent => {
        dis.addProspect.document = fileLoadEvent.target.result
      }
      fileReader.readAsDataURL(obj.target.files[0])
      this.url = URL.createObjectURL(obj.target.files[0])
      this.documentName = obj.target.files[0].name
      this.addProspect.file_name = obj.target.files[0].name
    },
    skillSelected() {
      Technology.getTechnologiesBySkillIds(this, this.addProspect.skill_ids)
    },
    setSkillTechnologies(technologies) {
      const existingTechnologyIds = []
      const newTechnologyIds = []
      let updatedTechnologies = []
      for (let i = 0; i < this.newTechnologies.length; i += 1) {
        existingTechnologyIds.push(this.newTechnologies[i].technology_id)
      }
      for (let i = 0; i < technologies.length; i += 1) {
        newTechnologyIds.push(technologies[i].id)
      }
      updatedTechnologies = this.newTechnologies.filter(technology => technology.experience !== 0 || technology.rating !== 0 || newTechnologyIds.includes(technology.technology_id))
      this.addProspect.technologies = this.addProspect.technologies.filter(id => newTechnologyIds.includes(id))
      technologies.forEach(technology => {
        if (!existingTechnologyIds.includes(technology.id)) {
          const newTechnology = {
            label: technology.label,
            technology_id: technology.id,
            rating: 0,
            experience: 0,
          }
          updatedTechnologies.push(newTechnology)
        }
      })
      this.newTechnologies = [...updatedTechnologies]
    },
    technologySelected(id) {
      return this.addProspect.technologies.includes(id)
    },
    onTypeChanged() {
      for (let i = 0; i < this.addProspect.other_types.length; i += 1) {
        if (this.addProspect.type_id === this.addProspect.other_types[i].id) {
          setTimeout(() => { this.onTypeChangedDialog(this.addProspect.other_types[i]) }, 100)
        }
      }
    },
    onTypeChangedDialog(employeeType) {
      const dis = this
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to change this user type from ${this.previous_type} to ${employeeType.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Proceed',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (!result.value) {
          dis.addProspect.type_id = dis.previous_type_id
        }
        dis.previous_type = employeeType.name
        dis.previous_type_id = dis.addProspect.type_id
      })
    },
    checked(id) {
      if (this.addProspect.technologies.includes(id)) {
        this.addProspect.technologies.splice(this.addProspect.technologies.indexOf(id), 1)
      } else {
        this.addProspect.technologies.push(id)
      }
    },
    setTechnologies(technologies) {
      this.technologies = technologies
    },
    setSkills(skills) {
      this.skills = skills
    },
    setGenders(genders) {
      this.genders = genders
    },
    setSourceTypes(sourceTypes) {
      this.sourceTypes = sourceTypes
    },
    setStates(states) {
      this.states = states
    },
    setLocations(locations) {
      this.locations = locations
    },
    stateChanged() {
      this.addProspect.city_id = null
      this.locations = []
      LIST.getLocations(this, this.addProspect.state_id)
    },
    setProspect(prospect) {
      this.addProspect = prospect
      this.previous_type = prospect.type
      this.previous_type_id = prospect.type_id
      this.prospect_type_id = prospect.type_id
      this.applied_date = prospect.applied_date
      LIST.getLocations(this, this.addProspect.state_id)
      Technology.getTechnologiesBySkillIds(this, this.addProspect.skill_ids)
    },
    prospectUpdated(data) {
      this.$refs.showLoading = false
      this.back()
      if (this.prospect_type_id !== this.addProspect.type_id) {
        const index = this.$store.state.prospects.prospectsResponse.prospects.findIndex(item => item.id === this.$route.params.id)
        if (index !== -1) {
          this.$store.state.prospects.prospectsResponse.prospects.splice(index, 1)
        }
      }
      this.$swal({
        title: 'Success!',
        html: data.return_message,
        icon: 'success',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      })
      // .then(result => {
      //   if (result.isConfirmed) {
      //     this.$router.push(`/documents/document-tracker/new?parent_id=${data.id}&parent_type_id=${data.parent_type_id}`).catch(() => {})
      //   }
      // })
    },
    prospectNotUpdated(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
