export default [
  {
    title: 'E-Signature',
    icon: 'UsersIcon',
    children: [
      {
        title: 'E-Signature',
        route: 'e-signature',
      },
      {
        title: 'Templates',
        route: 'e-signature-templates',
      },
    ],
  },
]
