<template>
  <b-card
    class="mb-0"
  >
    <div v-show="!isNextClicked">
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-2"
        >
          <h4 class="card-title mb-0 pt-50">
            Mass Communication</h4>
        </b-col>
        <b-col
          cols="6"
          md="4"
        >
          <b-form-group
            label="Parent Type"
            label-cols-md="4"
            class="text-right"
          >
            <v-select
              id="parent_type_id"
              v-model="parent_type_id"
              :options="types"
              :reduce="item => item.id"
              :clearable="false"
              placeholder="Select"
              @input="onTypeChanged($event)"
            />
          </b-form-group>
        </b-col>
        <!-- <b-col
          cols="12"
          md="5 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group>
              <b-form-input
                id="searchQuery"
                v-model="searchQuery"
                placeholder="Search"
                @keyup.enter="updateSearchQuery"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="updateSearchQuery"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="14"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-col> -->
      </b-row>

      <vue-good-table
        :columns="columns"
        :rows="employees"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: false,
        }"
        class="table-custome-height"
        @on-selected-rows-change="selectionChanged"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Select Email -->
          <span v-if="props.column.field === 'cellRendererEmailSelect'">
            <span v-if="props.row.emails.length === 1">{{ props.row.email }}</span>
            <span v-if="props.row.emails.length > 1">
              <v-select
                id="email"
                v-model="props.row.email"
                :options="props.row.emails"
                :clearable="false"
                placeholder="Select"
                @input="emailChanged(props.row)"
              />
            </span>
          </span>

          <!-- Column: Select Phone -->
          <span v-if="props.column.field === 'cellRendererPhoneSelect'">
            <span v-if="props.row.phone_numbers.length === 1">{{ props.row.phone_number }}</span>
            <span v-if="props.row.phone_numbers.length > 1">
              <v-select
                id="phone_number"
                v-model="props.row.phone_number"
                :options="props.row.phone_numbers"
                :clearable="false"
                placeholder="Select"
                @input="phoneChanged(props.row)"
              />
            </span>
          </span>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ props.row.status }}
            </b-badge>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>

      <b-row
        v-if="selectedEmployees.length > 0"
        class="mt-1"
      >
        <b-col class="text-right">
          <b-button
            variant="primary"
            type="button"
            class="mr-1"
            @click="sendMessage"
          >
            Send Message
          </b-button>
          <b-button
            variant="primary"
            type="button"
            @click="sendEmail"
          >
            Send Email
          </b-button>
        </b-col>
      </b-row>
    </div>

    <div v-if="isNextClicked && isEmail">
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <h4 class="card-title mb-0 pt-50">
            Send Email</h4>
        </b-col>
        <b-col
          cols="12"
          md="6 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              variant="outline-secondary"
              @click="back"
            >
              Back
            </b-button>
          </div>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col
          cols="8"
          offset="2"
        >
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row class="mt-1">
                <b-col cols="12">
                  <b-form-group
                    label="To"
                    label-cols-md="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="To"
                      rules="required"
                    >
                      <v-select
                        id="emails"
                        v-model="mass_communication.emails"
                        :options="selectedEmployees"
                        multiple
                        :clearable="false"
                        placeholder="Select"
                        @input="onEmployeeSelected()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Subject"
                    label-cols-md="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Subject"
                      rules="required"
                    >
                      <b-form-input
                        id="subject"
                        v-model="mass_communication.subject"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Body"
                    label-cols-md="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Body"
                      rules="required"
                    >
                      <b-form-textarea
                        id="message"
                        v-model="mass_communication.message"
                        rows="10"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Signature"
                    class="text-left"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Signature"
                      rules="required"
                    >
                      <b-form-textarea
                        id="signature"
                        v-model="mass_communication.signature"
                        rows="2"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>

              <b-row class="ml-2">
                <b-col class="text-right">
                  <b-button
                    variant="primary"
                    type="button"
                    @click="submitForm"
                  >
                    Send
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </div>

    <div v-if="isNextClicked && isMessage">
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <h4 class="card-title mb-0 pt-50">
            Send Message</h4>
        </b-col>
        <b-col
          cols="12"
          md="6 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              variant="outline-secondary"
              @click="back"
            >
              Back
            </b-button>
          </div>
        </b-col>
      </b-row>
      <hr>

      <b-row>
        <b-col
          cols="8"
          offset="2"
        >
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row class="mt-1">
                <b-col cols="12">
                  <b-form-group
                    label="To"
                    label-cols-md="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="To"
                      rules="required"
                    >
                      <v-select
                        id="emails"
                        v-model="mass_communication.emails"
                        :options="selectedPhoneNumbers"
                        multiple
                        :clearable="false"
                        placeholder="Select"
                        @input="onEmployeeSelected()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Body"
                    label-cols-md="12"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Body"
                      rules="required"
                    >
                      <b-form-textarea
                        id="message"
                        v-model="mass_communication.message"
                        rows="8"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>

              <b-row class="ml-2">
                <b-col class="text-right">
                  <b-button
                    variant="primary"
                    type="button"
                    @click="submitForm"
                  >
                    Send
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BBadge, BForm, BFormInput, BFormTextarea, BFormGroup, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import store from '@/store/index'
import LIST from '@/js/lists'
import MCommunication from '@/js/masscommunication'
import {
  required,
} from '@validations'

export default {
  name: 'MassCommunications',
  components: {
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    ValidationProvider,
    ValidationObserver,
    BBadge,
    BForm,
    BFormTextarea,
    BFormInput,
    BFormGroup,
    BButton,

    vSelect,
  },
  data() {
    return {
      required,
      searchQuery: '',
      dir: false,
      selectedEmployees: [],
      selectedPhoneNumbers: [],
      types: [],
      parent_type_id: null,
      mass_communication: {
        parent_type_id: null,
        subject: '',
        type: null,
        message: '',
        signature: `Thanks,\n ${this.$store.state.AppActiveUser.signature} Team`,
        emails: [],
      },
      isNextClicked: false,
      maxCount: 150,
      remainingCount: 150,
      isMessage: false,
      isEmail: false,
      selectAll: false,
      employees: [],
      columns: [
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Email',
          field: 'cellRendererEmailSelect',
        },
        {
          label: 'Phone',
          field: 'cellRendererPhoneSelect',
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Sent',
        2: 'Activated',
        3: 'Paused',
        4: 'Terminated',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-warning',
        4: 'light-danger',
      }],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Sent        : 'light-primary',
        Activated   : 'light-success',
        Paused      : 'light-warning',
        Terminated  : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getMassCommunicationParentTypes(this)
      },
    },
    '$store.state.lists.hrLists.response': {
      immediate: true,
      handler() {
        if (this.parent_type_id !== null) {
          const employerType = this.types.find(type => type.id === this.parent_type_id)
          if (employerType === null) return
          this.mass_communication.parent_type_id = employerType.parent_type_id
          switch (this.parent_type_id) {
            case 'employer':
              LIST.getMassCommunicationEmployers(this)
              break
            case 'facilities':
              LIST.getMassCommunicationVendors(this)
              break
            case 'suppliers':
              LIST.getMassCommunicationSuppliers(this)
              break
            case 'referrer':
              LIST.getMassCommunicationReferrers(this)
              break
            case 'subfacility':
              LIST.getMassCommunicationSubVendors(this)
              break
            case 'endclient':
              LIST.getMassCommunicationEndClients(this)
              break
            case 'employee':
              LIST.getMassCommunicationEmployees(this)
              break
            case 'consultant':
              LIST.getMassCommunicationConsultants(this)
              break
            case 'prospect':
              LIST.getMassCommunicationProspects(this)
              break
            default:
              break
          }
        }
      },
    },
  },
  created() {
    LIST.getMassCommunicationParentTypes(this)
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          MCommunication.sendMails(this, this.mass_communication)
        }
      })
    },
    onTypeChanged() {
      this.employees = []
      this.selectAll = false
      this.selectedEmployees = []
      if (this.parent_type_id !== null) {
        const employerType = this.types.find(type => type.id === this.parent_type_id)
        this.mass_communication.parent_type_id = employerType.parent_type_id
        switch (this.parent_type_id) {
          case 'employer':
            LIST.getMassCommunicationEmployers(this)
            break
          case 'facilities':
            LIST.getMassCommunicationVendors(this)
            break
          case 'suppliers':
            LIST.getMassCommunicationSuppliers(this)
            break
          case 'referrer':
            LIST.getMassCommunicationReferrers(this)
            break
          case 'subfacility':
            LIST.getMassCommunicationSubVendors(this)
            break
          case 'endclient':
            LIST.getMassCommunicationEndClients(this)
            break
          case 'employee':
            LIST.getMassCommunicationEmployees(this)
            break
          case 'consultant':
            LIST.getMassCommunicationConsultants(this)
            break
          case 'prospect':
            LIST.getMassCommunicationProspects(this)
            break
          default:
            break
        }
      }
    },
    emailChanged(row) {
      for (let i = 0; i < this.selectedEmployees.length; i += 1) {
        if (this.selectedEmployees[i].id === row.id) {
          this.selectedEmployees[i].label = row.email
        }
      }
    },
    phoneChanged(row) {
      for (let i = 0; i < this.selectedPhoneNumbers.length; i += 1) {
        if (this.selectedPhoneNumbers[i].id === row.id) {
          this.selectedPhoneNumbers[i].label = row.phone_number
        }
      }
    },
    selectionChanged(params) {
      const employees = params.selectedRows
      this.selectedEmployees = []
      this.selectedPhoneNumbers = []
      for (let i = 0; i < employees.length; i += 1) {
        this.selectedEmployees.push({ id: employees[i].id, label: employees[i].email })
        this.selectedPhoneNumbers.push({ id: employees[i].id, label: employees[i].phone_number })
      }
    },
    setParentTypes(parentTypes) {
      this.types = parentTypes
    },
    onEmployeeSelected() {
      if (this.mass_communication.emails.length === 0) {
        this.mass_communication.emails = null
      }
    },
    sendMessage() {
      if (!this.submitted) {
        this.mass_communication.type = 'message'
        this.mass_communication.subject = ' '
        this.mass_communication.emails = this.selectedPhoneNumbers
        this.isNextClicked = true
        this.isMessage = true
        this.isEmail = false
        this.submitted = true
      }
    },
    sendEmail() {
      if (!this.submitted) {
        this.mass_communication.type = 'email'
        this.mass_communication.subject = ''
        this.mass_communication.emails = this.selectedEmployees
        this.isNextClicked = true
        this.isMessage = false
        this.isEmail = true
        this.submitted = true
      }
    },
    updateSearchQuery() {
      this.onTypeChanged()
    },
    setEmployees(employees) {
      this.employees = employees
    },
    communicationAdded(data) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
      this.selectedEmployees = []
      this.selectedPhoneNumbers = []
      this.back()
      this.onTypeChanged()
    },
    communicationNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    back() {
      this.isNextClicked = false
      this.submitted = false
      this.mass_communication.subject = ''
      this.mass_communication.message = ''
      this.mass_communication.signature = `Thanks,\n ${this.$store.state.AppActiveUser.signature} Team`
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
