<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Edit Onboarding Request</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form class="ex-large">
        <app-timeline>
          <app-timeline-item class="pb-1">
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Employee</label>
                  <span
                    class="form-control text-left"
                    v-text="addOnboarding.employee_name"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  id="due_date"
                >
                  <label>Due Date</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Due Date"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="due_date"
                      class="form-control"
                      :config="$store.state.config"
                      placeholder="MM-DD-YYYY"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Onboarding Items</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Onboarding Items"
                    rules="required"
                  >
                    <v-select
                      v-model="selectedItems"
                      :options="items"
                      :reduce="item => item"
                      multiple
                      :clearable="false"
                      placeholder="Select"
                      @input="itemSelected()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="8"
              >
                <div class="d-none d-lg-flex">
                  <b-row class="flex-grow-1 px-1">
                    <b-col
                      cols="12"
                      lg="5"
                    >
                      Item Name
                    </b-col>
                    <b-col
                      cols="12"
                      lg="3"
                    >
                      Complete Date
                    </b-col>
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      Status
                    </b-col>
                  </b-row>
                </div>
                <div class="border rounded mb-1 p-50">
                  <b-row
                    v-for="(item, index) in addOnboarding.items"
                    :key="index"
                    class="flex-grow-1 px-1 py-50"
                  >
                    <b-col
                      cols="12"
                      lg="5"
                    >
                      <span
                        class="form-control"
                        v-text="addOnboarding.items[index].item_name"
                      />
                    </b-col>
                    <b-col
                      cols="12"
                      lg="3"
                    >
                      <flat-pickr
                        v-model="addOnboarding.items[index].complete_date"
                        class="form-control"
                        :config="$store.state.config"
                        placeholder="MM-DD-YYYY"
                      />
                    </b-col>
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Status"
                        rules="required"
                      >
                        <v-select
                          v-model="addOnboarding.items[index].status_id"
                          :options="status"
                          :reduce="item => item.id"
                          :clearable="false"
                          placeholder="Select"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Status</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Status"
                    rules="required"
                  >
                    <v-select
                      v-model="addOnboarding.status_id"
                      :options="status"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Documents</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Type</label>
                  <v-select
                    v-model="documentNameId"
                    :clearable="true"
                    placeholder="Select"
                    :options="documentNames"
                    :reduce="item => item.id"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Attachment</label>
                  <div class="d-flex">
                    <input
                      ref="myFileInput"
                      type="file"
                      placeholder="Choose a file or drop it here..."
                      class="form-control text-left"
                      @change="attahamentFileSelected"
                    >
                    <b-button
                      variant="outline-primary"
                      class="ml-1 px-1"
                      @click="addFile"
                    >
                      Upload
                    </b-button>
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button-group
                  v-for="(option, index) in addOnboarding.documents"
                  :key="option.id"
                  :value="option.id"
                  class="mr-1 mb-50"
                >
                  <b-badge
                    :href="option.attachment_link"
                    :title="option.name"
                    target="option.attachment_link"
                    variant="light-primary"
                    class="rounded-right-0 line-height-inherit"
                  >
                    <span>{{ option.name }}</span>
                  </b-badge>
                  <b-button
                    variant="danger"
                    class="px-50 py-25"
                    @click="removeFile(option, index)"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="success"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Comments</h6>
            </div>
            <b-row>
              <b-col
                v-for="(comment, index) in addOnboarding.comments"
                :key="index"
                cols="12"
                md="6"
                lg="6"
                xl="4"
              >
                <div class="d-flex">
                  <div class="left w-100">
                    <b-form-group>
                      <label>Comment</label>
                      <b-form-textarea
                        v-model="comment.comment"
                        rows="2"
                      />
                    </b-form-group>
                  </div>
                  <div class="right mt-1 ml-1">
                    <b-button
                      v-show="index === 0"
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      @click="addComment"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                    <b-button
                      v-show="index !== 0"
                      variant="outline-danger"
                      class="btn-icon rounded-circle"
                      @click="removeComment(index)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            v-show="addOnboarding.user_defined_fields.length !== 0"
            variant="warning"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>User Defined Fields</h6>
            </div>
            <b-row>
              <b-col
                v-for="field in addOnboarding.user_defined_fields"
                :id="field.id"
                :key="field.id"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group v-if="field.type.toLowerCase() === 'input'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-input
                      v-model="field.value"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'dropdown'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <v-select
                      v-model="field.value"
                      :options="field.options"
                      label="option"
                      :reduce="item => item.option"
                      :clearable="!field.required"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'textarea'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-textarea
                      v-model="field.value"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="mt-1">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Update Onboarding
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormTextarea, BButtonGroup, BButton, BBadge, BFormInput,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import flatPickr from 'vue-flatpickr-component'
import Onboarding from '@/js/onboarding'
import moment from 'moment'
import Constants from '@/js/constant'
import LIST from '@/js/lists'

export default {
  name: 'OnBoardingEdit',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormTextarea,
    vSelect,
    BButton,
    BButtonGroup,
    BBadge,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      addOnboarding: {
        employee_id: null,
        due_date: null,
        item_ids: null,
        status_id: null,
        items: [],
        comments: [],
        documents: [],
        user_defined_fields: [],
      },
      newRequest: {
        status_id: null,
        due_date: null,
        items: [],
        comments: [],
        documents: [],
        user_defined_fields: [],
      },
      url: null,
      due_date: null,
      documentName: null,
      documentNameId: null,
      document_name: '',
      selectedFile: null,
      selectedItems: null,
      companies: [],
      status: [],
      employees: [],
      items: [],
      documentNames: [],
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getEmployeeConsultantFullList(this)
        LIST.getOnboardingItemsFullList(this)
      },
    },
    '$store.state.lists.hrLists': {
      immediate: true,
      handler() {
        LIST.getOnboardingStatusFullList(this)
        LIST.getOnboardingDocumentNameFullList(this)
      },
    },
  },
  mounted() {
  },
  created() {
    LIST.getEmployeeConsultantFullList(this)
    LIST.getOnboardingItemsFullList(this)
    LIST.getOnboardingStatusFullList(this)
    LIST.getOnboardingDocumentNameFullList(this)
    Onboarding.getOnboardingById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          if (this.documentNameId !== null && this.selectedFile === null) {
            this.$swal({
              title: 'Upload',
              text: 'Please choose file',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return
          }
          if (this.documentNameId === null && this.selectedFile !== null) {
            this.$swal({
              title: 'Upload',
              text: 'Please select document type',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return
          }
          if (this.documentNameId !== null && this.selectedFile !== null) {
            this.addOnboarding.documents.push({
              document_name_id: this.documentNameId,
              attachment_link: this.url,
              document: this.selectedFile,
              file_name: this.documentName,
              name: this.documentName,
            })
            this.selectedFile = null
            this.url = null
            this.documentNameId = null
            this.$refs.myFileInput.value = ''
          }
          this.newRequest.status_id = this.addOnboarding.status_id
          this.newRequest.user_defined_fields = this.addOnboarding.user_defined_fields
          this.newRequest.items = this.addOnboarding.items
          this.newRequest.comments = this.addOnboarding.comments
          this.newRequest.documents = this.addOnboarding.documents
          for (let i = 0; i < this.newRequest.items.length; i += 1) {
            if (this.newRequest.items[i].complete_date != null) {
              this.newRequest.items[i].completion_date = Constants.getEprmMonthDateFromString(this.newRequest.items[i].complete_date)
            }
          }
          if (this.due_date != null) {
            this.newRequest.due_date = Constants.getEprmMonthDateFromString(this.due_date)
          }
          this.showLoading = true
          Onboarding.updateOnboarding(this, this.$route.params.id, this.newRequest)
        }
      })
    },
    attahamentFileSelected(obj) {
      const dis = this
      const fileReader = new FileReader()
      fileReader.onload = fileLoadEvent => {
        dis.selectedFile = fileLoadEvent.target.result
      }
      fileReader.readAsDataURL(obj.target.files[0])
      this.url = URL.createObjectURL(obj.target.files[0])
      this.documentName = obj.target.files[0].name
    },
    removeFile(file, position) {
      if (file.id == null) {
        this.addOnboarding.documents.splice(position, 1)
      } else {
        this.deleteConfirm(file, position)
      }
    },
    deleteConfirm(obj, position) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${obj.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.addOnboarding.documents.splice(position, 1)
        }
      })
    },
    addFile() {
      if (this.documentNameId !== null && this.selectedFile === null) {
        this.$swal({
          title: 'Error!',
          text: ' Please choose file',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId === null && this.selectedFile !== null) {
        this.$swal({
          title: 'Error!',
          text: ' Please select document type',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId !== null && this.selectedFile !== null) {
        this.addOnboarding.documents.push({
          document_name_id: this.documentNameId, attachment_link: this.url, document: this.selectedFile, file_name: this.documentName, name: this.documentName,
        })
        this.selectedFile = null
        this.url = null
        this.documentNameId = null
        this.$refs.myFileInput.value = ''
      }
    },
    setCompanies(companies) {
      this.companies = companies
    },
    setStatus(status) {
      this.status = status
    },
    setEmployeeConsultants(employees) {
      this.employees = employees
    },
    setOnboardingItems(items) {
      this.items = []
      for (let i = 0; i < items.length; i += 1) {
        this.items.push({
          id: items[i].id, item_id: items[i].id, completion_date: null, status_id: null, item_name: items[i].label, label: items[i].label,
        })
      }
    },
    setDocumentNames(documentNames) {
      this.documentNames = documentNames
    },
    itemSelected() {
      this.item_ids = []
      for (let i = 0; i < this.selectedItems.length; i += 1) {
        this.item_ids.push(this.selectedItems[i])
      }
      this.itemsChanges()
    },
    itemsChanges() {
      if (this.item_ids.length === 0) {
        this.item_ids = null
      }
      this.addOnboarding.items = this.item_ids
    },
    onboardingUpdated(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    onboardingNotUpdated(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    addComment() {
      const comment = {
        comment: '',
        date: Constants.getEprmMonthDateFromString(moment()),
        time: moment().format('hh:mm a'),
      }
      this.addOnboarding.comments.push(comment)
    },
    removeComment(index) {
      this.addOnboarding.comments.splice(index, 1)
    },
    setOnboarding(onboarding) {
      this.addOnboarding = onboarding
      this.selectedItems = onboarding.items
      if (this.addOnboarding.comments.length === 0) {
        this.addComment()
      }
      this.item_ids = onboarding.items
      this.due_date = onboarding.due_date
    },
    back() {
      this.$router.push('/human-resources/onboarding').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.repeater-form {
  transition: .35s height;
}
</style>
