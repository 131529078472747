<template>
  <b-card class="mb-0">
    <div class="mb-2">
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <h4
            class="card-title mb-0 pt-50"
          >
            Timesheets
          </h4>
        </b-col>
        <b-col
          cols="12"
          md="8 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                @keyup.enter="updateSearchQuery"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="updateSearchQuery"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="14"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button
              variant="primary"
              class="ml-2 min-w-160"
              :to="{ name: 'finance-and-accounting-timesheets-new' }"
            >
              Add Timesheet
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-tabs>
      <b-tab
        title="Monthly"
        :active="$store.state.query.activeTab === 'Monthly'"
        @click="tabChanged('Monthly')"
      >
        <b-card-text>
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="col-xxl-4"
            >
              <b-form-group
                label="Select Month"
                label-cols-md="4"
                class="text-right"
              >
                <b-input-group>
                  <flat-pickr
                    v-model="month"
                    class="form-control"
                    :config="configs.monthSelect"
                    placeholder="MM-YYYY"
                    :clear-button="true"
                    @on-change="monthDateChanged"
                  />
                  <b-input-group-append>
                    <b-button
                      class="px-1 outline-grey"
                      variant="outline-secondary"
                      @click="removeMonthDate"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <vue-good-table
            :columns="columns"
            :rows="timesheetsResponse.time_sheets"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm,
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <!-- Column: Name -->
              <span
                v-if="props.column.field === 'fullName'"
                class="text-nowrap"
              >
                <b-avatar
                  :src="props.row.avatar"
                  class="mx-1"
                />
                <span class="text-nowrap">{{ props.row.fullName }}</span>
              </span>

              <!-- Column: Currency -->
              <span v-if="props.column.field === 'cellRendererCurrency'">
                <span>{{ props.row.expenses| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
              </span>

              <!-- Column: Status -->
              <span v-else-if="props.column.field === 'status'">
                <b-badge :variant="statusVariant(props.row.status)">
                  {{ props.row.status }}
                </b-badge>
              </span>

              <!-- Column: Status -->
              <span
                v-else-if="props.column.field === 'cellRendererSelectStatus'"
              >
                <b-form-select
                  v-model="props.row.status"
                  :options="status"
                  placeholder="Select"
                  value-field="label"
                  text-field="label"
                  @change="onStatusChanged(props.row)"
                />
                <!-- <v-select
                  v-model="props.row.status"
                  :options="status"
                  :reduce="(item) => item.id"
                  :clearable="false"
                  placeholder="Select"
                  @input="onStatusChanged(props.row)"
                /> -->
              </span>

              <!-- Column: Action -->
              <span
                v-else-if="props.column.field === 'action'"
                class="btn-action"
              >
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item
                      :to="{
                        name: 'finance-and-accounting-timesheets-edit',
                        params: { id: props.row.id },
                      }"
                    >
                      <feather-icon icon="Edit2Icon" />
                      <span class="ml-50">Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="confirmDeleteRecord(props.row)">
                      <feather-icon icon="TrashIcon" />
                      <span class="ml-50">Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                  <b-link
                    id="view"
                    :to="{ name: 'finance-and-accounting-timesheets-view', params: { id: props.row.id } }"
                  >
                    <b-badge
                      pill
                      variant="light-primary"
                    ><feather-icon icon="EyeIcon" />
                    </b-badge>
                  </b-link>
                </span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
            >
              <div class="d-flex justify-content-end flex-wrap">
                <div>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="timesheetsResponse.count"
                    first-number
                    last-number
                    align="right"
                    :per-page="1"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card-text>
      </b-tab>
      <b-tab
        title="Weekly"
        :active="$store.state.query.activeTab === 'Weekly'"
        @click="tabChanged('Weekly')"
      >
        <b-card-text>
          <b-row>
            <b-col cols="4">
              <b-form-group
                label="From"
                label-cols-md="4"
                class="text-right"
              >
                <b-input-group>
                  <flat-pickr
                    v-model="from_date"
                    class="form-control"
                    :config="$store.state.config"
                    placeholder="MM-DD-YYYY"
                    :clear-button="true"
                    @on-change="weekStartDateChanged"
                  />
                  <b-input-group-append>
                    <b-button
                      class="px-1 outline-grey"
                      variant="outline-secondary"
                      @click="remonWeekStartDate"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                label="To"
                label-cols-md="4"
                class="text-right"
              >
                <b-input-group>
                  <flat-pickr
                    v-model="to_date"
                    class="form-control"
                    :config="$store.state.config"
                    placeholder="MM-DD-YYYY"
                    :clear-button="true"
                    @on-change="weekEndDateChanged"
                  />
                  <b-input-group-append>
                    <b-button
                      class="px-1 outline-grey"
                      variant="outline-secondary"
                      @click="remonWeekEndDate"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <vue-good-table
            :columns="columns1"
            :rows="timesheetsResponse.time_sheets"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm,
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <!-- Column: Name -->
              <span
                v-if="props.column.field === 'cellRendererWeekStartToEndDate'"
                class="text-nowrap"
              >
                <span class="text-nowrap">{{ props.row.week_start_date + ' - ' + props.row.week_end_date }}</span>
              </span>

              <!-- Column: Currency -->
              <span v-if="props.column.field === 'cellRendererCurrency'">
                <span>{{ props.row.expenses| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
              </span>

              <!-- Column: Status -->
              <span v-else-if="props.column.field === 'status'">
                <b-badge :variant="statusVariant(props.row.status)">
                  {{ props.row.status }}
                </b-badge>
              </span>

              <!-- Column: Status -->
              <span
                v-else-if="props.column.field === 'cellRendererSelectStatus'"
              >
                <b-form-select
                  v-model="props.row.status"
                  :options="status"
                  value-field="label"
                  text-field="label"
                  placeholder="Select"
                  @change="onStatusChanged(props.row)"
                />
                <!-- <v-select
                  v-model="props.row.status"
                  :options="status"
                  :reduce="(item) => item.id"
                  :clearable="false"
                  placeholder="Select"
                  @input="onStatusChanged(props.row)"
                /> -->
              </span>

              <!-- Column: Action -->
              <span
                v-else-if="props.column.field === 'action'"
                class="btn-action"
              >
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item
                      :to="{
                        name: 'finance-and-accounting-timesheets-edit',
                        params: { id: props.row.id },
                      }"
                    >
                      <feather-icon icon="Edit2Icon" />
                      <span class="ml-50">Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="confirmDeleteRecord(props.row)">
                      <feather-icon icon="TrashIcon" />
                      <span class="ml-50">Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                  <b-link
                    id="view"
                    :to="{ name: 'finance-and-accounting-timesheets-view', params: { id: props.row.id } }"
                  >
                    <b-badge
                      pill
                      variant="light-primary"
                    ><feather-icon icon="EyeIcon" />
                    </b-badge>
                  </b-link>
                </span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
            >
              <div class="d-flex justify-content-end flex-wrap">
                <div>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="timesheetsResponse.count"
                    first-number
                    last-number
                    align="right"
                    :per-page="1"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card-text>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BFormSelect,
  BInputGroupAppend,
  BButton,
  BLink,
  BFormGroup,
  BTab,
  BTabs,
  BAvatar,
  BBadge,
  BPagination,
  BDropdown,
  BDropdownItem,
  BCardText,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import flatPickr from 'vue-flatpickr-component'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import store from '@/store/index'
import Constant from '@/js/constant'
import moment from 'moment'
import Timesheets from '@/js/timesheets'
import LIST from '@/js/lists'

export default {
  name: 'TimesheetsList',
  components: {
    BCard,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BFormSelect,
    BInputGroupAppend,
    BButton,
    BLink,
    BTab,
    BTabs,
    BAvatar,
    BBadge,
    BPagination,
    BDropdown,
    BDropdownItem,
    BCardText,
    VueGoodTable,
    flatPickr,
    BFormGroup,
  },
  data() {
    return {
      searchQuery: '',
      pageLength: 10,
      dir: false,
      status: null,
      month: null,
      from_date: null,
      to_date: null,
      timesheetsResponse: {
        time_sheets: [],
        count: 1,
      },
      columns: [
        {
          label: '#',
          field: 'index',
          width: '100px',
        },
        {
          label: 'Name',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Month',
          field: 'week_start_date',
          width: '140px',
          sortable: false,
        },
        {
          label: 'Hours',
          field: 'total_hours',
          width: '120px',
          sortable: false,
        },
        {
          label: 'Expenses',
          field: 'cellRendererCurrency',
          width: '140px',
          sortable: false,
        },
        {
          label: 'Status',
          field: 'cellRendererSelectStatus',
          width: '200px',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          width: '130px',
          sortable: false,
        },
      ],
      columns1: [
        {
          label: '#',
          field: 'index',
          width: '100px',
        },
        {
          label: 'Name',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Week',
          field: 'cellRendererWeekStartToEndDate',
          width: '210px',
          sortable: false,
        },
        {
          label: 'Hours',
          field: 'total_hours',
          width: '120px',
          sortable: false,
        },
        {
          label: 'Expenses',
          field: 'cellRendererCurrency',
          width: '140px',
          sortable: false,
        },
        {
          label: 'Status',
          field: 'cellRendererSelectStatus',
          width: '200px',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          width: '130px',
          sortable: false,
        },
      ],
      rows: [],
      rows1: [],
      searchTerm: '',
      configs: {
        monthSelect: {
          plugins: [
            monthSelectPlugin({
              shorthand: true,
              dateFormat: 'M-Y',
              altFormat: 'F, Y',
            }),
          ],
        },
      },
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
        Timesheets.getTimesheets(this)
      },
    },
  },
  watch: {
    '$store.state.lists.faLists': {
      immediate: true,
      handler() {
        LIST.getTimesheetsStatusFullList(this)
      },
    },
  },
  created() {
    this.searchQuery = this.$store.state.query.query
    if (this.$store.state.query.month === null) {
      this.month = moment().subtract(1, 'months').format('MMM-YYYY')
      this.$store.state.query.month = this.month
    } else {
      this.month = this.$store.state.query.month
    }
    this.from_date = this.$store.state.query.startdate
    this.to_date = this.$store.state.query.endDate
    this.timesheetsResponse.count = this.$store.state.query.page
    this.month_date = Constant.getEprmMonthDateFromString(`${moment(this.month, 'MMM-YYYY').format('MM')}-01-${moment(this.month, 'MMM-YYYY').format('YYYY')}`)
    LIST.getTimesheetsStatusFullList(this)
    Timesheets.getTimesheets(this)
  },
  methods: {
    confirmDeleteRecord(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${obj.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          Timesheets.delete(this, obj.id)
        }
      })
    },
    tabChanged(tab) {
      this.$store.state.query.activeTab = tab
      this.timesheetsResponse.time_sheets = []
      this.$store.state.query.query = ''
      this.searchQuery = ''
      this.currentPage = 1
    },
    timesheetDeleted() {
      Timesheets.getTimesheets(this)
      this.$swal({
        icon: 'success',
        title: 'Timesheet Deleted!',
        text: 'Your Timesheet has been deleted',
      })
    },
    timesheetNotDeleted() {
      this.$swal({
        icon: 'warning',
        title: 'Timesheet Not Deleted!',
        text: 'Your Timesheet has been not deleted',
      })
    },
    updateSearchQuery() {
      this.$store.state.query.query = this.searchQuery
      this.timesheetsResponse.time_sheets = []
      this.currentPage = 1
    },
    monthDateChanged(selectedDate, dateStr) {
      this.$store.state.query.month = dateStr
      this.timesheetsResponse.time_sheets = []
      this.currentPage = 1
    },
    setStatus(status) {
      this.status = status
    },
    removeMonthDate() {
      this.month_date = null
      this.month = null
      this.$store.state.query.month = this.month
      this.timesheetsResponse.time_sheets = []
      Timesheets.getTimesheets(this)
    },
    weekStartDateChanged() {
      this.$store.state.query.startdate = this.from_date
      this.timesheetsResponse.time_sheets = []
      Timesheets.getTimesheets(this)
    },
    weekEndDateChanged() {
      this.$store.state.query.endDate = this.to_date
      this.timesheetsResponse.time_sheets = []
      Timesheets.getTimesheets(this)
    },
    remonWeekStartDate() {
      this.from_date = null
      this.$store.state.query.startdate = this.from_date
      this.timesheetsResponse.time_sheets = []
      Timesheets.getTimesheets(this, this.$route.params.id)
    },
    remonWeekEndDate() {
      this.to_date = null
      this.$store.state.query.endDate = this.to_date
      this.timesheetsResponse.time_sheets = []
      Timesheets.getTimesheets(this, this.$route.params.id)
    },
    updateError(message) {
      this.$swal({
        icon: 'warning',
        title: 'Timesheet Not Updated!',
        text: message,
      })
    },
    updated(message) {
      Timesheets.getTimesheets(this)
      this.$swal({
        icon: 'success',
        title: 'Timesheet Updated!',
        text: message.return_message,
      })
    },
    onStatusChanged(timesheet) {
      const updateStatus = this.status.find(status => status.label === timesheet.status)
      this.$swal({
        title: 'Are you sure?',
        text: `Are you sure want to change this Status to ${updateStatus.label}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          Timesheets.toggleStatus(this, timesheet.id, updateStatus.id)
        }
      })
    },
    setTimesheets(timesheetsResponse) {
      this.timesheetsResponse = timesheetsResponse
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.form-control.flatpickr-input {
    background-color: #fff !important;
}
</style>
