<template>
  <b-card
    class="mb-0"
  >
    <h4 class="card-title card-title-tabs mb-0 pt-50">
      Group Jobs
    </h4>
    <b-tabs
      v-model="activeTab "
      @input="handleTabChange"
      align="right"
      class="custom-tabs custom-group-tabs mt-50"
    >
    <b-tab title="Contract Jobs" @click="contractTabClicked()" :active="$store.state.query.jobsActiveTab  === 'contract'">
        <template #title>
          <div class="border border-dashed d-flex flex-column justify-content-center align-items-center cursor-pointer rounded px-2 py-1 me-6">
            <span>Contract Jobs</span>
          </div>
        </template>
        <div>
          <vue-good-table
            :columns="columns"
            :rows="contractJobResponse.jobs"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :pagination-options="{
              perPage:pageLength,
              enabled: true,
              mode: 'pages',
              setCurrentPage: currentPage,
            }"
            @on-page-change="onPageChange"
            @on-per-page-change="onPerPageChange"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Job Id -->
              <span v-if="props.column.fieldType === 'cellRendererJobs'">
                <b-link
                  class="count-circle"
                  @click="showGroupJobIds(props.row)"
                >{{ props.row.count }}</b-link>
                  <p
                    v-b-tooltip.hover
                    :title='props.row.indeed_job_tool_tip'
                    v-b-tooltip.hover.v-primary
                  >
                </p>
              </span>

              <!-- Column: Job Class -->
              <span v-if="props.column.fieldType === 'cellRendererJobClass'">
                <p
                  v-b-tooltip.hover
                  :title='props.row.skill_description'
                  v-b-tooltip.hover.v-primary
                >{{ props.row.skill }}</p>
              </span>

              <!-- Column: Client -->
              <span v-if="props.column.fieldType === 'cellRendererClient'">
                <p :id="'contract_facility' + props.row.index">
                  {{ props.row.facility }}
                </p>
                <b-tooltip
                  :target="'contract_facility' + props.row.index"
                  triggers="hover"
                  no-fade
                >
                  <span class="d-block text-left">{{props.row.facility}}</span>
                  <span class="d-block text-left">{{props.row.location}}</span>
                </b-tooltip>
              </span>

              <!-- Column: Indeed Matched Prospects -->
              <span v-if="props.column.fieldType === 'cellRendererIndeedMatchedProspects'">
                <b-button
                  variant="outline-primary"
                  :to="{ name: 'human-resources-prospects-admin-all-prospects', query: { skill_id: props.row.skill_id, state_id: props.row.state_id, city: props.row.city, source_type: 'indeed' } }"
                  class="px-50 py-25"
                >
                  {{ props.row.indeed_city_matched_prospects }}
                </b-button>
                /
                <b-button
                  variant="outline-primary"
                  :to="{ name: 'human-resources-prospects-admin-all-prospects', query: { skill_id: props.row.skill_id, state_id: props.row.state_id, source_type: 'indeed' } }"
                  class="px-50 py-25"
                >
                  {{ props.row.indeed_matched_prospects }}
                </b-button>
              </span>

              <!-- Column: Matched Prospects -->
              <span v-if="props.column.fieldType === 'cellRendererMatchedProspects'">
                <b-button
                  variant="outline-primary"
                  :to="{ name: 'human-resources-prospects-admin-all-prospects', query: { skill_id: props.row.skill_id, state_id: props.row.state_id, city: props.row.city } }"
                  class="px-50 py-25"
                >
                {{ props.row.city_matched_prospects }}
                </b-button>
                /
                <b-button
                  variant="outline-primary"
                  :to="{ name: 'human-resources-prospects-admin-all-prospects', query: { skill_id: props.row.skill_id, state_id: props.row.state_id } }"
                  class="px-50 py-25"
                >
                  {{ props.row.matched_prospects }}
                </b-button>
              </span>

              <!-- Column: Bill Rate Currency -->
              <span v-if="props.column.fieldType === 'cellRendererBillRateCurrency'">
                <span>{{ props.row.bill_rate_max | currency('$ ', 2, { thousandsSeparator: ',' }) }} - {{ props.row.bill_rate_min | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
              </span>

              <!-- Column: Pay Rate Currency -->
              <span v-if="props.column.fieldType === 'cellRendererPayRateCurrency'">
                <span class="min-w-100">{{ props.row.pay_rate_max_max | currency('$ ', 2, { thousandsSeparator: ',' }) }} - {{ props.row.pay_rate_min_min | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                <span v-if="props.column.fieldType !== 'cellRendererIndeedMatchedProspects' && props.column.fieldType !== 'cellRendererMatchedProspects' && props.column.fieldType !== 'cellRendererJobClass' && props.column.fieldType !== 'cellRendererClient' && props.column.fieldType !== 'cellRendererJobs' && props.column.fieldType !== 'cellRendererBillRateCurrency'">
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </span>
            </template>

          </vue-good-table>
        </div>
      </b-tab>
      <b-tab @click="permanentTabClicked()" :active="$store.state.query.jobsActiveTab  === 'permanent'">
        <template #title>
          <div class="border border-dashed d-flex flex-column justify-content-center align-items-center cursor-pointer rounded px-2 py-1 me-6">
            <span>Permanent Jobs</span>
          </div>
        </template>
        <div>
          <vue-good-table
            :columns="columns"
            :rows="jobResponse.jobs"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :pagination-options="{
              perPage: permPageLength,
              enabled: true,
              mode: 'pages',
              setCurrentPage: permCurrentPage,
            }"
            @on-page-change="onPermPageChange"
            @on-per-page-change="onPermPerPageChange"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Job Id -->
              <span v-if="props.column.fieldType === 'cellRendererJobs'">
                <b-link
                  class="count-circle"
                  @click="showGroupJobIds(props.row)"
                >{{ props.row.count }}</b-link>
              </span>

              <!-- Column: Job Class -->
              <span v-if="props.column.fieldType === 'cellRendererJobClass'">
                <p
                  v-b-tooltip.hover
                  :title='props.row.skill_description'
                  v-b-tooltip.hover.v-primary
                >{{ props.row.skill }}</p>
              </span>

              <!-- Column: Client -->
              <span v-if="props.column.fieldType === 'cellRendererClient'">
                <p :id="'permanent_facility' + props.row.index">
                  {{ props.row.facility }}
                </p>
                <b-tooltip
                  :target="'permanent_facility' + props.row.index"
                  triggers="hover"
                  no-fade
                >
                  <span class="d-block text-left">{{props.row.facility}}</span>
                  <span class="d-block text-left">{{props.row.location}}</span>
                </b-tooltip>
              </span>

              <!-- Column: Indeed Matched Prospects -->
              <span v-if="props.column.fieldType === 'cellRendererIndeedMatchedProspects'">
                <b-button
                  variant="outline-primary"
                  :to="{ name: 'human-resources-prospects-admin-all-prospects', query: { skill_id: props.row.skill_id, state_id: props.row.state_id, city: props.row.city, source_type: 'indeed' } }"
                  class="px-50 py-25"
                >
                  {{ props.row.indeed_city_matched_prospects }}
                </b-button>
                /
                <b-button
                  variant="outline-primary"
                  :to="{ name: 'human-resources-prospects-admin-all-prospects', query: { skill_id: props.row.skill_id, state_id: props.row.state_id, source_type: 'indeed' } }"
                  class="px-50 py-25"
                >
                  {{ props.row.indeed_matched_prospects }}
                </b-button>
              </span>

              <!-- Column: Matched Prospects -->
              <span v-if="props.column.fieldType === 'cellRendererMatchedProspects'">
                <b-button
                  variant="outline-primary"
                  :to="{ name: 'human-resources-prospects-admin-all-prospects', query: { skill_id: props.row.skill_id, state_id: props.row.state_id, city: props.row.city } }"
                  class="px-50 py-25"
                >
                  {{ props.row.city_matched_prospects }}
                </b-button>
                /
                <b-button
                  variant="outline-primary"
                  :to="{ name: 'human-resources-prospects-admin-all-prospects', query: { skill_id: props.row.skill_id, state_id: props.row.state_id } }"
                  class="px-50 py-25"
                >
                  {{ props.row.matched_prospects }}
                </b-button>
              </span>

              <!-- Column: Bill Rate Currency -->
              <span v-if="props.column.fieldType === 'cellRendererBillRateCurrency'">
                <span>{{ props.row.bill_rate_max | currency('$ ', 2, { thousandsSeparator: ',' }) }} - {{ props.row.bill_rate_min | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
              </span>

              <!-- Column: Pay Rate Currency -->
              <span v-if="props.column.fieldType === 'cellRendererPayRateCurrency'">
                <span>NA</span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                <span v-if="props.column.fieldType !== 'cellRendererIndeedMatchedProspects' && props.column.fieldType !== 'cellRendererMatchedProspects' && props.column.fieldType !== 'cellRendererJobClass' && props.column.fieldType !== 'cellRendererClient' && props.column.fieldType !== 'cellRendererJobs' && props.column.fieldType !== 'cellRendererBillRateCurrency'">
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </span>
            </template>

          </vue-good-table>
        </div>
      </b-tab>

      <b-tab @click="prnTabClicked()" :active="$store.state.query.jobsActiveTab  === 'prn'">
        <template #title>
          <div class="border border-dashed d-flex flex-column justify-content-center align-items-center cursor-pointer rounded px-2 py-1 me-6">
            <span>PRN Jobs</span>
          </div>
        </template>
        <div>
          <vue-good-table
            :columns="columns"
            :rows="prnJobResponse.jobs"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :pagination-options="{
              perPage: prnPageLength,
              enabled: true,
              mode: 'pages',
              setCurrentPage: prnCurrentPage,
            }"
            @on-page-change="onPrnPageChange"
            @on-per-page-change="onPrnPerPageChange"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Job Id -->
              <span v-if="props.column.fieldType === 'cellRendererJobs'">
                <b-link
                  class="count-circle"
                  @click="showGroupJobIds(props.row)"
                >{{ props.row.count }}</b-link>
              </span>

              <!-- Column: Job Class -->
              <span v-if="props.column.fieldType === 'cellRendererJobClass'">
                <p
                  v-b-tooltip.hover
                  :title='props.row.skill_description'
                  v-b-tooltip.hover.v-primary
                >{{ props.row.skill }}</p>
              </span>

              <!-- Column: Client -->
              <span v-if="props.column.fieldType === 'cellRendererClient'">
                <p :id="'prn_facility' + props.row.index">
                  {{ props.row.facility }}
                </p>
                <b-tooltip
                  :target="'prn_facility' + props.row.index"
                  triggers="hover"
                  no-fade
                >
                  <span class="d-block text-left">{{props.row.facility}}</span>
                  <span class="d-block text-left">{{props.row.location}}</span>
                </b-tooltip>
              </span>

              <!-- Column: Indeed Matched Prospects -->
              <span v-if="props.column.fieldType === 'cellRendererIndeedMatchedProspects'">
                <b-button
                  variant="outline-primary"
                  :to="{ name: 'human-resources-prospects-admin-all-prospects', query: { skill_id: props.row.skill_id, state_id: props.row.state_id, city: props.row.city, source_type: 'indeed' } }"
                  class="px-50 py-25"
                >
                  {{ props.row.indeed_city_matched_prospects }}
                </b-button>
                /
                <b-button
                  variant="outline-primary"
                  :to="{ name: 'human-resources-prospects-admin-all-prospects', query: { skill_id: props.row.skill_id, state_id: props.row.state_id, source_type: 'indeed' } }"
                  class="px-50 py-25"
                >
                  {{ props.row.indeed_matched_prospects }}
                </b-button>
              </span>

              <!-- Column: Matched Prospects -->
              <span v-if="props.column.fieldType === 'cellRendererMatchedProspects'">
                <b-button
                  variant="outline-primary"
                  :to="{ name: 'human-resources-prospects-admin-all-prospects', query: { skill_id: props.row.skill_id, state_id: props.row.state_id, city: props.row.city } }"
                  class="px-50 py-25"
                >
                  {{ props.row.city_matched_prospects }}
                </b-button>
                /
                <b-button
                  variant="outline-primary"
                  :to="{ name: 'human-resources-prospects-admin-all-prospects', query: { skill_id: props.row.skill_id, state_id: props.row.state_id } }"
                  class="px-50 py-25"
                >
                  {{ props.row.matched_prospects }}
                </b-button>
              </span>

              <!-- Column: Bill Rate Currency -->
              <span v-if="props.column.fieldType === 'cellRendererBillRateCurrency'">
                <span>{{ props.row.bill_rate_max | currency('$ ', 2, { thousandsSeparator: ',' }) }} - {{ props.row.bill_rate_min | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
              </span>

              <!-- Column: Pay Rate Currency -->
              <span v-if="props.column.fieldType === 'cellRendererPayRateCurrency'">
                <span class="min-w-100">{{ props.row.pay_rate_max_max | currency('$ ', 2, { thousandsSeparator: ',' }) }} - {{ props.row.pay_rate_min_min | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                <span v-if="props.column.fieldType !== 'cellRendererIndeedMatchedProspects' && props.column.fieldType !== 'cellRendererMatchedProspects' && props.column.fieldType !== 'cellRendererJobClass' && props.column.fieldType !== 'cellRendererClient' && props.column.fieldType !== 'cellRendererJobs' && props.column.fieldType !== 'cellRendererBillRateCurrency'">
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </span>
            </template>

          </vue-good-table>
        </div>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BCard, BLink, VBModal, VBTooltip, BTooltip, BTabs, BTab, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Jobs from '@/js/jobs'

export default {
  name: 'JobRequests',
  components: {
    BCard,
    VueGoodTable,
    BLink,
    BTabs,
    BTab,
    BTooltip,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      searchQuery: '',
      dir: false,
      groupJobIds: {},
      activeTab: 'contract',
      type: 'contract',
      sortingHistory: [],
      jobResponse: {
        jobs: [],
        count: 1,
      },
      contractJobResponse: {
        jobs: [],
        count: 1,
      },
      prnJobResponse: {
        jobs: [],
        count: 1,
      },
      columns: [
        {
          label: 'Jobs',
          field: 'count',
          type: 'number',
          fieldType: 'cellRendererJobs',
          thClass: 'text-left',
          tdClass: 'text-center',
        },
        {
          label: 'Job Class',
          field: 'skill',
          fieldType: 'cellRendererJobClass',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Job Class',
          },
        },
        {
          label: 'Location',
          field: 'location',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Location',
          },
        },
        {
          label: 'Bill Rate ($)',
          field: 'bill_rate_max',
          type: 'number',
          fieldType: 'cellRendererBillRateCurrency',
          sortable: true,
          filterOptions: {
            enabled: false,
            placeholder: 'Search Bill Rate',
          },
        },
        {
          label: 'Pay Rate ($)',
          field: 'pay_rate_max_max',
          fieldType: 'cellRendererPayRateCurrency',
          type: 'number',
          sortable: true,
        },
        {
          label: 'Duration',
          field: 'duration',
          type: 'number',
          width: '100px',
          sortable: false,
        },
        {
          label: 'Applied',
          field: 'applied_prospects',
          type: 'number',
          width: '90px',
        },
        {
          label: 'Indeed Matched Prospects City / State',
          field: 'indeed_matched_prospects',
          type: 'number',
          fieldType: 'cellRendererIndeedMatchedProspects',
          sortType: 'number',
          width: '160px',
          thClass: 'text-left',
          tdClass: 'text-center',
        },
        {
          label: 'Matched Prospects City / State',
          field: 'matched_prospects',
          type: 'number',
          fieldType: 'cellRendererMatchedProspects',
          sortType: 'number',
          width: '130px',
          thClass: 'text-left',
          tdClass: 'text-center',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    pageLength: {
      get() {
        return this.$store.state.query.perPage
      },
      set(val) {
        this.$store.state.query.perPage = val
      },
    },
    currentPage: {
      get() {
        return this.$store.state.query.groupjobspage
      },
      set(val) {
        this.$store.state.query.groupjobspage = val
      },
    },
    permPageLength: {
      get() {
        return this.$store.state.query.permPerPage
      },
      set(val) {
        this.$store.state.query.permPerPage = val
      },
    },
    permCurrentPage: {
      get() {
        return this.$store.state.query.groupjobspermpage
      },
      set(val) {
        this.$store.state.query.groupjobspermpage = val
      },
    },
    prnPageLength: {
      get() {
        return this.$store.state.query.prnPerPage
      },
      set(val) {
        this.$store.state.query.prnPerPage = val
      },
    },
    prnCurrentPage: {
      get() {
        return this.$store.state.query.groupjobsprnpage
      },
      set(val) {
        this.$store.state.query.groupjobsprnpage = val
      },
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
  },
  created() {
    this.type = this.$store.state.query.jobType
    this.activeTab = this.$store.state.query.jobsActiveTab
    this.contractJobResponse.count = this.$store.state.query.groupjobspage
    this.prnJobResponse.count = this.$store.state.query.groupjobsprnpage
    this.jobResponse.count = this.$store.state.query.groupjobspermpage
    if (this.type === 'contract') {
      if (this.$store.state.groupJObs.isContractJobsLoaded) {
        this.contractJobResponse = this.$store.state.groupJObs.contractJobs
      } else {
        Jobs.getJobsGroupByCity(this, this.type)
      }
    }
    if (this.type === 'permanent') {
      if (this.$store.state.groupJObs.isPermanentJobsLoaded) {
        this.jobResponse = this.$store.state.groupJObs.permanentJobs
      } else {
        Jobs.getJobsGroupByCity(this, this.type)
      }
    }
    if (this.type === 'prn') {
      if (this.$store.state.groupJObs.isPrnJobsLoaded) {
        this.prnJobResponse = this.$store.state.groupJObs.prnJobs
      } else {
        Jobs.getJobsGroupByCity(this, this.type)
      }
    }
  },
  methods: {
    prnTabClicked() {
      this.type = 'prn'
      this.$store.state.query.jobType = this.type
      this.$store.state.query.jobsActiveTab = 'prn'
      if (this.$store.state.groupJObs.isPrnJobsLoaded) {
        this.prnJobResponse = this.$store.state.groupJObs.prnJobs
      } else {
        Jobs.getJobsGroupByCity(this, this.type)
      }
    },
    permanentTabClicked() {
      this.type = 'permanent'
      this.$store.state.query.jobType = this.type
      this.$store.state.query.jobsActiveTab = 'permanent'
      if (this.$store.state.groupJObs.isPermanentJobsLoaded) {
        this.jobResponse = this.$store.state.groupJObs.permanentJobs
      } else {
        Jobs.getJobsGroupByCity(this, this.type)
      }
    },
    contractTabClicked() {
      this.type = 'contract'
      this.$store.state.query.jobsActiveTab = 'contract'
      this.$store.state.query.jobType = this.type
      if (this.$store.state.groupJObs.isContractJobsLoaded) {
        this.contractJobResponse = this.$store.state.groupJObs.contractJobs
      } else {
        Jobs.getJobsGroupByCity(this, this.type)
      }
    },
    handleTabChange() {
    },
    showGroupJobIds(props) {
      this.$store.state.groupJObs.isGroupJobsLoaded = false
      this.$store.state.query.group_job_per_page = 10
      this.$store.state.query.group_job_page = 1
      this.$router.push(`/human-resources/prospects-admin/group-jobs-city-skill?skill_id=${props.skill_id}&type=${this.type}&city=${props.city}&state_id=${props.state_id}`).catch(() => {})
    },
    onPageChange(page) {
      this.currentPage = page.currentPage
      this.pageLength = page.currentPerPage
    },
    onPerPageChange(perPage) {
      this.currentPage = perPage.currentPage
      this.pageLength = perPage.currentPerPage
    },
    onPermPageChange(page) {
      this.permCurrentPage = page.currentPage
      this.permPageLength = page.currentPerPage
    },
    onPermPerPageChange(perPage) {
      this.permCurrentPage = perPage.currentPage
      this.permPageLength = perPage.currentPerPage
    },
    onPrnPageChange(page) {
      this.prnCurrentPage = page.currentPage
      this.prnPageLength = page.currentPerPage
    },
    onPrnPerPageChange(perPage) {
      this.prnCurrentPage = perPage.currentPage
      this.prnPageLength = perPage.currentPerPage
    },
    openIndeedJob(groupJob) {
      window.open(groupJob.indeed_job, '_blank')
    },
    captureSortHistory(sortEvent) {
      this.sortingHistory.push({
        field: sortEvent[0].field,
        type: sortEvent[0].type,
      })
    },
    closeJobPopup() {
      this.groupJobIds = {}
    },
    updateSearchQuery() {
      this.$store.state.query.query = this.searchQuery
    },
    setPrnJobs(jobResponse) {
      this.prnJobResponse = jobResponse
      this.$store.state.groupJObs.isPrnJobsLoaded = true
      this.$store.state.groupJObs.prnJobs = jobResponse
    },
    setJobs(jobResponse) {
      this.jobResponse = jobResponse
      this.$store.state.groupJObs.isPermanentJobsLoaded = true
      this.$store.state.groupJObs.permanentJobs = jobResponse
    },
    setContractJobs(jobResponse) {
      this.contractJobResponse = jobResponse
      this.$store.state.groupJObs.isContractJobsLoaded = true
      this.$store.state.groupJObs.contractJobs = jobResponse
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
