import axios from 'axios'

const report = 'financial-statement/reports'

export default {
  getAccountReceivableReport(context1, startDate, enddate) {
    const context = context1
    context.$vs.loading()
    const params = { params: { start_date: startDate, end_date: enddate } }
    axios.get(`${report}/receivable`, params).then(response => {
      context.$vs.loading.close()
      context.setReport(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getAccountPayableReport(context1, startDate, enddate) {
    const context = context1
    context.$vs.loading()
    const params = { params: { start_date: startDate, end_date: enddate } }
    axios.get(`${report}/payable`, params).then(response => {
      context.$vs.loading.close()
      context.setReport(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getAccountPayrollReport(context1, startDate, enddate) {
    const context = context1
    context.$vs.loading()
    const params = { params: { start_date: startDate, end_date: enddate } }
    axios.get(`${report}/payroll`, params).then(response => {
      context.$vs.loading.close()
      context.setReport(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getAccountOperationalReport(context1, startDate, enddate) {
    const context = context1
    context.$vs.loading()
    const params = { params: { start_date: startDate, end_date: enddate } }
    axios.get(`${report}/operational`, params).then(response => {
      context.$vs.loading.close()
      context.setReport(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  getAccountReceivableReportPdf(context1, startDate, enddate) {
    const context = context1
    context.$vs.loading()
    const params = { params: { start_date: startDate, end_date: enddate } }
    axios.get(`${report}/receivable/pdf`, params).then(response => {
      context.$vs.loading.close()
      context.pdfPath(response.data)
    }, error => {
      context.$vs.loading.close()
      context.pdfNotFound(error.response.data.return_message)
    })
  },
  getAccountPayableReportPdf(context1, startDate, enddate) {
    const context = context1
    context.$vs.loading()
    const params = { params: { start_date: startDate, end_date: enddate } }
    axios.get(`${report}/payable/pdf`, params).then(response => {
      context.$vs.loading.close()
      context.pdfPath(response.data)
    }, error => {
      context.$vs.loading.close()
      context.pdfNotFound(error.response.data.return_message)
    })
  },
  getAccountPayrollReportPdf(context1, startDate, enddate) {
    const context = context1
    context.$vs.loading()
    const params = { params: { start_date: startDate, end_date: enddate } }
    axios.get(`${report}/payroll/pdf`, params).then(response => {
      context.$vs.loading.close()
      context.pdfPath(response.data)
    }, error => {
      context.$vs.loading.close()
      context.pdfNotFound(error.response.data.return_message)
    })
  },
  getAccountOperationalReportPdf(context1, startDate, enddate) {
    const context = context1
    context.$vs.loading()
    const params = { params: { start_date: startDate, end_date: enddate } }
    axios.get(`${report}/operational/pdf`, params).then(response => {
      context.$vs.loading.close()
      context.pdfPath(response.data)
    }, error => {
      context.$vs.loading.close()
      context.pdfNotFound(error.response.data.return_message)
    })
  },
}
