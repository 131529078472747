import Templates from '@/views/e-signature/templates/Templates.vue'
import TemplateNew from '@/views/e-signature/templates/actions/New.vue'
import TemplateEdit from '@/views/e-signature/templates/actions/Edit.vue'
import TemplateView from '@/views/e-signature/templates/actions/View.vue'
import ESignature from '@/views/e-signature/e-signature/ESignature.vue'
import MSAESignatureSign from '@/views/e-signature/e-signature/actions/MSA.vue'
import POESignatureSign from '@/views/e-signature/e-signature/actions/PO.vue'
import OLESignatureSign from '@/views/e-signature/e-signature/actions/OL.vue'
import EmployerLetterESignatureSign from '@/views/e-signature/e-signature/actions/EmployerLetter.vue'
import VerificationLetterESignatureSign from '@/views/e-signature/e-signature/actions/VerificationLetter.vue'
import ESignatureView from '@/views/e-signature/e-signature/actions/View.vue'
import ESignatureEmployerLetterView from '@/views/e-signature/e-signature/actions/EmployerLetterView.vue'
import ESignatureVerificationLetterView from '@/views/e-signature/e-signature/actions/VerificationLetterView.vue'
import ESignatureClientLetterView from '@/views/e-signature/e-signature/actions/ClientLetterView.vue'
import ESignatureOfferLetterView from '@/views/e-signature/e-signature/actions/OfferLetterView.vue'
import ESignatureMSAView from '@/views/e-signature/e-signature/actions/MSAView.vue'
import ESignaturePOView from '@/views/e-signature/e-signature/actions/POView.vue'
import MSAESignatureDocumentSent from '@/views/e-signature/e-signature/actions/MSADocumentSent.vue'
import POESignatureDocumentSent from '@/views/e-signature/e-signature/actions/PODocumentSent.vue'
import OLESignatureDocumentSent from '@/views/e-signature/e-signature/actions/OLDocumentSent.vue'

export default [
  // *===============================================---*
  // *--------- E-Signatue -------------------------------*
  // *===============================================---*
  // *------------ e-signatrue --------------------*
  {
    path: '/e-signature/e-signature',
    name: 'e-signature',
    component: ESignature,
  },
  {
    path: '/e-signature/e-signature/msa/sign/:id',
    name: 'e-signature-msa-sign',
    component: MSAESignatureSign,
    meta: {
      navActiveLink: 'e-signature',
    },
  },
  {
    path: '/e-signature/e-signature/po/sign/:id',
    name: 'e-signature-po-sign',
    component: POESignatureSign,
    meta: {
      navActiveLink: 'e-signature',
    },
  },
  {
    path: '/e-signature/e-signature/ol/sign/:id',
    name: 'e-signature-ol-sign',
    component: OLESignatureSign,
    meta: {
      navActiveLink: 'e-signature',
    },
  },
  {
    path: '/e-signature/e-signature/employer-letter/sign/:id',
    name: 'e-signature-employer-letter-sign',
    component: EmployerLetterESignatureSign,
    meta: {
      navActiveLink: 'e-signature',
    },
  },
  {
    path: '/e-signature/e-signature/verification-letter/sign/:id',
    name: 'e-signature-verification-letter-sign',
    component: VerificationLetterESignatureSign,
    meta: {
      navActiveLink: 'e-signature',
    },
  },
  {
    path: '/e-signature/e-signature/view/:id',
    name: 'e-signature-view',
    component: ESignatureView,
    meta: {
      navActiveLink: 'e-signature',
    },
  },
  {
    path: '/e-signature/e-signature/employer-letter-view/:id',
    name: 'e-signature-employer-letter-view',
    component: ESignatureEmployerLetterView,
    meta: {
      navActiveLink: 'e-signature',
    },
  },
  {
    path: '/e-signature/e-signature/verification-letter-view/:id',
    name: 'e-signature-verification-letter-view',
    component: ESignatureVerificationLetterView,
    meta: {
      navActiveLink: 'e-signature',
    },
  },
  {
    path: '/e-signature/e-signature/client-letter-view/:id',
    name: 'e-signature-client-letter-view',
    component: ESignatureClientLetterView,
    meta: {
      navActiveLink: 'e-signature',
    },
  },
  {
    path: '/e-signature/e-signature/offer-letter-view/:id',
    name: 'e-signature-offer-letter-view',
    component: ESignatureOfferLetterView,
    meta: {
      navActiveLink: 'e-signature',
    },
  },
  {
    path: '/e-signature/e-signature/msa-view/:id',
    name: 'e-signature-msa-view',
    component: ESignatureMSAView,
    meta: {
      navActiveLink: 'e-signature',
    },
  },
  {
    path: '/e-signature/e-signature/po-view/:id',
    name: 'e-signature-po-view',
    component: ESignaturePOView,
    meta: {
      navActiveLink: 'e-signature',
    },
  },
  {
    path: '/e-signature/e-signature/view/:id/msa/document-sent',
    name: 'e-signature-msa-document-sent',
    component: MSAESignatureDocumentSent,
    meta: {
      navActiveLink: 'e-signature',
    },
  },
  {
    path: '/e-signature/e-signature/view/:id/po/document-sent',
    name: 'e-signature-po-document-sent',
    component: POESignatureDocumentSent,
    meta: {
      navActiveLink: 'e-signature',
    },
  },
  {
    path: '/e-signature/e-signature/view/:id/ol/document-sent',
    name: 'e-signature-ol-document-sent',
    component: OLESignatureDocumentSent,
    meta: {
      navActiveLink: 'e-signature',
    },
  },
  // *------------ Templates --------------------*
  {
    path: '/e-signature/templates',
    name: 'e-signature-templates',
    component: Templates,
  },
  {
    path: '/e-signature/templates/new',
    name: 'e-signature-templates-new',
    component: TemplateNew,
    meta: {
      navActiveLink: 'e-signature-templates',
    },
  },
  {
    path: '/e-signature/templates/edit/:id',
    name: 'e-signature-templates-edit',
    component: TemplateEdit,
    meta: {
      navActiveLink: 'e-signature-templates',
    },
  },
  {
    path: '/e-signature/templates/view/:id',
    name: 'e-signature-templates-view',
    component: TemplateView,
    meta: {
      navActiveLink: 'e-signature-templates',
    },
  },
]
