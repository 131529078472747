import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.adurl
const adminDocument = `${HOST}admin-document`

export default {
  getDocuments(context1) {
    const context = context1
    let params = { query: context.searchQuery, page: context.currentPage - 1 }
    if (params !== {}) {
      params = { params: { query: context.searchQuery, page: context.currentPage - 1 } }
    } else {
      params = { params: {} }
    }
    axios.get(adminDocument, params).then(response => {
      context.setDocuments(response.data)
    }, () => {
    })
  },
  addDocument(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(adminDocument, body).then(response => {
      context.$vs.loading.close()
      context.$store.state.lists.adLists.response.document.lists['document-names'] = response.data.document_names
      context.documentAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.documentNotAdded(error.response.data.return_message)
    })
  },
  updateDocument(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${adminDocument}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.documentUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.documentNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${adminDocument}/${id}`).then(response => {
      context.$vs.loading.close()
      context.documentDeleted(response.data)
    }, error => {
      context.$vs.loading.close()
      context.documentNotDeleted(error.response.data.return_message)
    })
  },
  getDocumentById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${adminDocument}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setDocument(response.data)
    }, error => {
      context.$vs.loading.close()
      context.documentNotFound(error.response.data.return_message)
    })
  },

}
