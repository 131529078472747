<template>
  <div>
    <b-card
      no-body
      class="card-statistics"
    >
      <b-tabs class="custom-tabs mt-50">
        <b-tab>
          <template #title>
            <div class="border border-dashed d-flex flex-column justify-content-center align-items-center cursor-pointer rounded px-2 py-1 me-6">
              <span>By Skill</span>
            </div>
          </template>
          <div class="mx-1">
            <b-row class="tab-table-bordered">
              <b-col
                v-for="count in bySkillResponse.counts"
                :key="count"
                :value="count"
                cols="12"
                md="3"
                lg="2"
                class="table-text cursor-pointer"
                @click="skillSelected(count.id, count.count)"
              >
                <div class="table-text-inner">
                  <div class="d-flex align-items-center justify-content-between">
                    <p class="table-text-warp mb-0">
                      {{ count.label }} </p>
                    <span class="badge badge-primary"> {{ count.count }} </span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <div class="border border-dashed d-flex flex-column justify-content-center align-items-center cursor-pointer rounded px-2 py-1 me-6">
              <span>By State</span>
            </div>
          </template>
          <div class="mx-1">
            <b-row class="tab-table-bordered">
              <b-col
                v-for="count in byStateResponse.counts"
                :key="count"
                :value="facility_job"
                cols="12"
                md="3"
                lg="2"
                class="table-text cursor-pointer"
                @click="stateSelected(count.id, count.count)"
              >
                <div class="table-text-inner">
                  <div class="d-flex align-items-center justify-content-between">
                    <p class="table-text-warp mb-0">
                      {{ count.label }} </p>
                    <span class="badge badge-primary"> {{ count.count }} </span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <div class="border border-dashed d-flex flex-column justify-content-center align-items-center cursor-pointer rounded px-2 py-1 me-6">
              <span>By Source</span>
            </div>
          </template>
          <div class="mx-1">
            <b-row class="tab-table-bordered">
              <b-col
                v-for="count in bySourceResponse.counts"
                :key="count"
                :value="count"
                cols="12"
                md="3"
                lg="2"
                class="table-text cursor-pointer"
                @click="sourceSelected(count.id, count.count)"
              >
                <div class="table-text-inner">
                  <div class="d-flex align-items-center justify-content-between">
                    <p class="table-text-warp mb-0">
                      {{ count.label }} </p>
                    <span class="badge badge-primary"> {{ count.count }} </span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab @click="showAllProspects">
          <template #title>
            <div class="border border-dashed d-flex flex-column justify-content-center align-items-center cursor-pointer rounded px-2 py-1 me-6">
              <span>All Prospects</span>
            </div>
          </template>
        </b-tab>
        <b-tab @click="addProspect">
          <template #title>
            <div class="border border-dashed d-flex flex-column justify-content-center align-items-center cursor-pointer rounded px-2 py-1 me-6">
              <span>Add Prospect</span>
            </div>
          </template>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BTabs, BTab,
} from 'bootstrap-vue'
import Prospects from '@/js/prospect'

export default {
  name: 'AppDashboard',
  components: {
    BRow,
    BCol,
    BCard,
    BTabs,
    BTab,
  },
  data() {
    return {
      bySkillResponse: {},
      byStateResponse: {},
      bySourceResponse: {},
    }
  },
  created() {
    Prospects.getProspectBySkill(this)
    Prospects.getProspectByState(this)
    Prospects.getProspectBySource(this)
  },
  methods: {
    skillSelected(skillId, count) {
      if (count === 0) return
      this.$router.push(`/human-resources/prospects-admin/job-prospect-matcher?skill_id=${skillId}`).catch(() => {})
    },
    stateSelected(stateId, count) {
      if (count === 0) return
      this.$router.push(`/human-resources/prospects-admin/job-prospect-matcher?state_id=${stateId}`).catch(() => {})
    },
    sourceSelected(sourceId, count) {
      if (count === 0) return
      this.$router.push(`/human-resources/prospects-admin/all-prospects?source_id=${sourceId}`).catch(() => {})
    },
    showAllProspects() {
      this.$router.push('/human-resources/prospects-admin/all-prospects').catch(() => {})
    },
    addProspect() {
      this.$router.push('/human-resources/prospects-admin/all-prospects/new').catch(() => {})
    },
    setProspectBySourceCount(bySourceResponse) {
      this.bySourceResponse = bySourceResponse
    },
    setProspectBySkillCount(bySkillResponse) {
      this.bySkillResponse = bySkillResponse
    },
    setProspectByStateCount(byStateResponse) {
      this.byStateResponse = byStateResponse
    },
  },
}
</script>
