import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.url
const clientLetter = `${HOST}client-letter`

export default {
  getClientLetterById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${clientLetter}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setEmployeeDetails(response.data)
    }, error => {
      context.$vs.loading.close()
      context.employeeNotFound(error.response.data.return_message)
    })
  },
  getClientLetterPdfById(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${clientLetter}/${id}/pdf`, body).then(response => {
      context.$vs.loading.close()
      context.pdfPath(response.data)
    }, error => {
      context.$vs.loading.close()
      context.pdfNotFound(error.response.data.return_message)
    })
  },
  // sendClientLetterForSign(context1, id, body) {
  //   const context = context1
  //   context.$vs.loading()
  //   axios.post(`${clientLetter}/${id}`, body).then(response => {
  //     context.$vs.loading.close()
  //     context.sentSuccess(response.data)
  //   }, error => {
  //     context.$vs.loading.close()
  //     context.sentFailure(error.response.data.return_message)
  //   })
  // },
  // getClientLetterTemplateById(context1, id, body) {
  //   const context = context1
  //   context.$vs.loading()
  //   axios.post(`${clientLetter}/${id}/update-content`, body).then(response => {
  //     context.$vs.loading.close()
  //     context.employerLetterTemplate(response.data)
  //   }, error => {
  //     context.$vs.loading.close()
  //     context.pdfNotFound(error.response.data.return_message)
  //   })
  // },
  // sendClientLetterUpdatedContentForSign(context1, id, body) {
  //   const context = context1
  //   context.$vs.loading()
  //   axios.post(`${clientLetter}/${id}/sign`, body).then(response => {
  //     context.$vs.loading.close()
  //     context.sentSuccess(response.data)
  //   }, error => {
  //     context.$vs.loading.close()
  //     context.sentFailure(error.response.data.return_message)
  //   })
  // },
  // getClientLetterPdfByIdAndUpdatedContent(context1, id, body) {
  //   const context = context1
  //   context.$vs.loading()
  //   axios.post(`${clientLetter}/${id}/update-content/pdf`, body).then(response => {
  //     context.$vs.loading.close()
  //     context.pdfPath(response.data)
  //   }, error => {
  //     context.$vs.loading.close()
  //     context.pdfNotFound(error.response.data.return_message)
  //   })
  // },
}
