import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.adurl
const assignJob = `${HOST}assign-job`

export default {
  getUserByUserId(context1, userId, node) {
    const context = context1
    const params = { params: { user_id: userId } }
    axios.get(`${assignJob}/users`, params).then(response => {
      context.setUsers(response.data, node)
    }, () => {
    })
  },
  getUserJobCriteriaByUserId(context1, userId, node) {
    const context = context1
    axios.get(`${assignJob}/${userId}/criteria`).then(response => {
      context.setCriteria(response.data, node)
    }, error => {
      context.setCriteriaNotFound(error.response.data)
    })
  },
  getAssignedUserJobCriteriaByUserId(context1, userId) {
    const context = context1
    axios.get(`${assignJob}/${userId}`).then(response => {
      context.setCriteria(response.data)
    }, error => {
      context.setCriteriaNotFound(error.response.data.id)
    })
  },
  addAssignJobCriteria(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(assignJob, body).then(response => {
      context.$vs.loading.close()
      context.assignJobCriteriaAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.assignJobCriteriaNotAdded(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${assignJob}/${id}`).then(response => {
      context.$vs.loading.close()
      context.criteriaDeleted(response.data.return_message)
    }, error => {
      context.$vs.loading.close()
      context.criteriaNotDeleted(error.response.data.return_message)
    })
  },
  updateAssignJobCriteria(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${assignJob}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.assignJobCriteriaUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.assignJobCriteriaNotUpdated(error.response.data.return_message)
    })
  },
}
