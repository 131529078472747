<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          View Workflow</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>

    <app-timeline>
      <app-timeline-item class="pb-1">
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Workflow Name</label>
              <span
                class="form-control h-auto"
                v-text="process.name"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="info"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Workflow Tasks</h6>
        </div>
        <div
          v-for="(processTask, index) in process.tasks"
          :key="index"
          :value="index"
          class="mt-1"
        >
          <b-row>
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group>
                <label>Task Name</label>
                <span
                  class="form-control h-auto"
                  v-text="processTask.name"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group>
                <label>Task Type</label>
                <span
                  class="form-control h-auto"
                  v-text="processTask.task_name"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <label>Task Fields</label>
                <span class="form-control height-auto min-h-38">
                  <b-badge
                    v-for="(field, fieldIndex) in processTask.fields"
                    :key="fieldIndex"
                    :value="fieldIndex"
                    variant="light-primary"
                    class="mr-50 my-25"
                  >
                    {{ field }}
                  </b-badge>
                </span>
              </b-form-group>
            </b-col>
          </b-row>
          <hr class="my-0">
        </div>
        <div
          v-if="process.tasks.length == 0"
          class="text-center"
        >
          <p class="mb-50">
            No Workflow tasks available
          </p>
        </div>
        <div class="vue-form-wizard vue-custom-form-wizard mt-1">
          <div class="wizard-navigation">
            <div
              class="wizard-nav wizard-nav-pills cursor-move"
            >
              <li
                v-for="processTask in myList"
                :id="processTask"
                :key="processTask"
                class="cursor-move translucent-wrap bg-light-primary rounded mb-1 p-1"
              >
                <span class="stepTitle">
                  {{ processTask.name }}
                </span>
              </li>
            </div>
          </div>
        </div>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BButton, BBadge,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import Process from '@/js/process'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  name: 'WorkflowVeiw',
  components: {
    BCard,
    BBadge,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BFormGroup,
    BButton,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      process: {},
      mainProps: {
        blank: true,
        width: 75,
        height: 75,
        class: 'm1',
      },
    }
  },
  computed: {
    myList: {
      get() {
        return this.process.tasks
      },
      set(value) {
        this.process.tasks = value
      },
    },
  },
  mounted() {
  },
  created() {
    Process.getProcessById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    setProcess(process) {
      this.process = process
    },
    back() {
      this.$router.push('/administration/workflow').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
.repeater-form {
  transition: .35s height;
}
.list-group-item {
  transition: all 1s
}
.vue-form-wizard {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.vue-form-wizard .wizard-navigation .wizard-nav {
    padding: 0;
    border-bottom: none;
}
.vue-form-wizard .wizard-nav {
    list-style: none;
}
.vue-form-wizard .wizard-navigation .wizard-nav li:not(:first-child) a::before {
  content: '';
}
</style>
