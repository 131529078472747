import Constants from '@/js/constant'
import axios from 'axios'

const HOST = Constants.hosts.vjmsUrl
const LIST = `${HOST}facility-job/list`
const ALLLIST = `${Constants.hosts.url}list`

export default {
  getEmployees(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setEmployees([])
      return
    }
    context.setEmployees(context.$store.state.lists.commonLists.lists['roll-users'])
  },
  getDurations(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setDurations([])
      return
    }
    context.setDurations(context.$store.state.lists.commonLists.lists['position-durations'])
  },
  getFacilitiesAll(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setFacilities([])
      return
    }
    context.setFacilities(context.$store.state.lists.commonLists.lists['facilities-all'])
  },
  getFacilities(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setFacilities([])
      return
    }
    context.setFacilities(context.$store.state.lists.commonLists.lists.facilities)
  },
  getFacilityJobStatus(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setFacilityJobStatus([])
      return
    }
    context.setFacilityJobStatus(context.$store.state.lists.commonLists.lists['facility-job-status'])
  },
  getFacilityJobLocations(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setLocations([])
      return
    }
    context.setLocations(context.$store.state.lists.commonLists.lists['facility-job-locations'])
  },
  getFacilityJobStates(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setStates([])
      return
    }
    context.setStates(context.$store.state.lists.commonLists.lists['facility-job-states'])
  },
  getVisaTypes(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setVisaTypes([])
      return
    }
    context.setVisaTypes(context.$store.state.lists.commonLists.lists['visa-types'])
  },
  getEndClients(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setEndClients([])
      return
    }
    context.setEndClients(context.$store.state.lists.commonLists.lists['end-clients'])
  },
  getWorkingStatus(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setWorkingStatus([])
      return
    }
    context.setWorkingStatus(context.$store.state.lists.commonLists.lists['working-status'])
  },
  getRecruiterStatus(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setRecruiterStatus([])
      return
    }
    context.setRecruiterStatus(context.$store.state.lists.commonLists.lists['recruiter-status'])
  },
  getRecruiters(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setRecruiters([])
      return
    }
    context.setRecruiters(context.$store.state.lists.commonLists.lists.recruiters)
  },
  getJobTitles(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setJobTitles([])
      return
    }
    context.setJobTitles(context.$store.state.lists.commonLists.lists['job-titles'])
  },
  getMainSkills(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setMainSkills([])
      return
    }
    context.setMainSkills(context.$store.state.lists.commonLists.lists.skills)
  },
  getSecondarySkills(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setSecondarySkills([])
      return
    }
    context.setSecondarySkills(context.$store.state.lists.commonLists.lists.technologies)
  },
  getEducations(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setEducations([])
      return
    }
    context.setEducations(context.$store.state.lists.commonLists.lists.educations)
  },
  getLocations(context, stateId) {
    axios.get(`${ALLLIST}/${stateId}/cities`).then(response => {
      context.setLocations(response.data)
    }, () => {
    })
  },
  getContactTypes(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setContactTypes([])
      return
    }
    context.setContactTypes(context.$store.state.lists.commonLists.lists['recruiter-contact-types'])
  },
  getMainSkillsAll(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setMainSkills([])
      return
    }
    context.setMainSkills(context.$store.state.lists.commonLists.lists.skills)
  },
  getRecruitersByFacilityId(context, id) {
    axios.get(`${LIST}/${id}/recruiters`).then(response => {
      context.setRecruiters(response.data)
    })
  },
  getRecruitersByFacilityIdAll(context, id) {
    const params = { params: { is_all: true } }
    axios.get(`${LIST}/${id}/recruiters`, params).then(response => {
      context.setRecruiters(response.data)
    })
  },
  getSecondarySkillsByMainSkillId(context, id) {
    if (context.$store.state.lists.commonLists === undefined) {
      context.setSecondarySkills([])
      return
    }
    context.setSecondarySkills(context.$store.state.lists.commonLists.secondary_skills[id])
  },
  getStatesFullList(context) {
    if (context.$store.state.lists.commonLists.lists === undefined) {
      context.setStates([])
      return
    }
    context.setStates(context.$store.state.lists.commonLists.lists.states)
  },
  addLocations(context, body) {
    axios.post(`${LIST}/location`, body).then(response => {
      const { locations } = context.$store.state.lists.commonLists.lists
      locations.push({ id: response.data.id, label: body.city, name: body.city })
      const { commonLists } = context.$store.state.lists
      commonLists.lists.locations = locations
      context.$store.commit('COMMON_LISTS', commonLists)
      context.locationAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.locationNotAdded(error.response.data.return_message)
    })
  },
  addJobTitle(context, body) {
    context.$vs.loading()
    axios.post(`${LIST}/title`, body).then(response => {
      context.$vs.loading.close()
      const titles = context.$store.state.lists.commonLists.lists['job-titles']
      titles.push({ id: response.data.id, label: body.title, name: body.title })
      const { commonLists } = context.$store.state.lists
      commonLists.lists['job-titles'] = titles
      context.$store.commit('COMMON_LISTS', commonLists)
      context.titleAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.titleNotAdded(error.response.data.return_message)
    })
  },
  addEducation(context, body) {
    axios.post(`${LIST}/educations`, body).then(response => {
      const { educations } = context.$store.state.lists.commonLists.lists
      educations.push({ id: response.data.id, label: body.qualification, name: body.qualification })
      const { commonLists } = context.$store.state.lists
      commonLists.lists.educations = educations
      context.educationAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.educationNotAdded(error.response.data.return_message)
    })
  },
  addDuration(context, body) {
    axios.post(`${LIST}/duration`, body).then(response => {
      const durations = context.$store.state.lists.commonLists.lists['position-durations']
      durations.push({ id: response.data.id, label: body.duration, name: body.duration })
      const { commonLists } = context.$store.state.lists
      commonLists.lists['position-durations'] = durations
      context.durationAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.durationNotAdded(error.response.data.return_message)
    })
  },

}
