export default [
  {
    title: 'Employee Administration',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Daily Updates',
        route: 'employee-daily-updates',
      },
    ],
  },
]
