import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.faurl
const plStatement = `${HOST}pl-statement`

export default {
  getPLStatementDetails(context1, startDate, endDate) {
    const context = context1
    const params = { params: { start_date: startDate, end_date: endDate } }
    axios.get(plStatement, params).then(response => {
      context.setStatement(response.data)
    }, () => {
    })
  },
  addPLStatement(context1, body, isSave) {
    const context = context1
    context.$vs.loading()
    const params = { params: { save: isSave } }
    axios.post(plStatement, body, params).then(response => {
      context.$vs.loading.close()
      context.plStatementSaved(response.data)
    }, error => {
      context.$vs.loading.close()
      context.plStatementNotSaved(error.response.data.return_message)
    })
  },
}
