import axios from 'axios'
import Constants from '@/js/constant'
import Lists from '@/js/lists'

const HOST = Constants.hosts.adurl
const technology = `${HOST}skill-technology`

export default {
  getTechnologies(context1) {
    const context = context1
    let params = { query: context.searchQuery, page: context.currentPage - 1 }
    if (params !== {}) {
      params = { params: { query: context.searchQuery, page: context.currentPage - 1 } }
    } else {
      params = { params: {} }
    }
    axios.get(technology, params).then(response => {
      context.setTechnologies(response.data)
    }, () => {
    })
  },
  getTechnologiesBySkillId(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${technology}/${id}/list`).then(response => {
      context.$vs.loading.close()
      context.setTechnologies(response.data)
    }, error => {
      context.$vs.loading.close()
      context.technologiesNotFound(error.response.data.return_message)
    })
  },
  getTechnologiesBySkillIds(context1, ids) {
    const request = {
      skill_ids: ids,
    }
    const context = context1
    axios.post(`${technology}/technologies`, request).then(response => {
      context.setSkillTechnologies(response.data)
    }, error => {
      context.technologiesNotFound(error.response.data.return_message)
    })
  },
  resendEmail(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${technology}/email/resend/${id}`).then(response => {
      context.$vs.loading.close()
      context.resendEmailResponse(response.data)
    }, error => {
      context.$vs.loading.close()
      context.resendEmailResponse(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${technology}/${id}`).then(response => {
      context.$vs.loading.close()
      context.technologyDeleted(response.data)
      Lists.getAllList(context)
    }, error => {
      context.$vs.loading.close()
      context.technologyNotDeleted(error.response.data.return_message)
    })
  },
  getTechnologyById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${technology}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setTechnology(response.data)
    }, error => {
      context.$vs.loading.close()
      context.technologyNotFound(error.response.data.return_message)
    })
  },
  addTechnology(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(technology, body).then(response => {
      context.$vs.loading.close()
      context.technologyAdded(response.data)
      Lists.getAllList(context)
    }, error => {
      context.$vs.loading.close()
      context.technologyNotAdded(error.response.data.return_message)
    })
  },
  updateTechnology(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${technology}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.technologyUpdated(response.data)
      Lists.getAllList(context)
    }, error => {
      context.$vs.loading.close()
      context.technologyNotUpdated(error.response.data.return_message)
    })
  },

}
