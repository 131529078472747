import DocumentTracker from '@/views/documents/document-tracker/DocumentTracker.vue'
import DocumentTrackerNew from '@/views/documents/document-tracker/actions/New.vue'
import DocumentTrackerEdit from '@/views/documents/document-tracker/actions/Edit.vue'
import DocumentTrackerVeiw from '@/views/documents/document-tracker/actions/View.vue'

import RequiredDocuments from '@/views/documents/required-documents/RequiredDocuments.vue'
import RequiredDocumentsNew from '@/views/documents/required-documents/actions/New.vue'
import RequiredDocumentsEdit from '@/views/documents/required-documents/actions/Edit.vue'
import RequiredDocumentsVeiw from '@/views/documents/required-documents/actions/View.vue'

import Templates from '@/views/documents/templates/Templates.vue'
import TemplatesNew from '@/views/documents/templates/actions/New.vue'
import TemplatesEdit from '@/views/documents/templates/actions/Edit.vue'
import TemplatesVeiw from '@/views/documents/templates/actions/View.vue'

export default [
  // *===============================================---*
  // *--------- Document Tracker -------------------------------*
  {
    path: '/documents/document-tracker',
    name: 'documents-document-tracker',
    component: DocumentTracker,
  },
  {
    path: '/documents/document-tracker/new',
    name: 'documents-document-tracker-new',
    component: DocumentTrackerNew,
    meta: {
      navActiveLink: 'documents-document-tracker',
    },
  },
  {
    path: '/documents/document-tracker/edit/:id',
    name: 'documents-document-tracker-edit',
    component: DocumentTrackerEdit,
    meta: {
      navActiveLink: 'documents-document-tracker',
    },
  },
  {
    path: '/documents/document-tracker/view/:id',
    name: 'documents-document-tracker-view',
    component: DocumentTrackerVeiw,
    meta: {
      navActiveLink: 'documents-document-tracker',
    },
  },
  // *--------- Required Documents -------------------------------*
  {
    path: '/documents/required-documents',
    name: 'documents-required-documents',
    component: RequiredDocuments,
  },
  {
    path: '/documents/required-documents/new',
    name: 'documents-required-documents-new',
    component: RequiredDocumentsNew,
    meta: {
      navActiveLink: 'documents-required-documents',
    },
  },
  {
    path: '/documents/required-documents/edit/:id',
    name: 'documents-required-documents-edit',
    component: RequiredDocumentsEdit,
    meta: {
      navActiveLink: 'documents-required-documents',
    },
  },
  {
    path: '/documents/required-documents/view/:id',
    name: 'documents-required-documents-view',
    component: RequiredDocumentsVeiw,
    meta: {
      navActiveLink: 'documents-required-documents',
    },
  },
  // *--------- Templates -------------------------------*
  {
    path: '/documents/templates',
    name: 'documents-templates',
    component: Templates,
  },
  {
    path: '/documents/templates/new',
    name: 'documents-templates-new',
    component: TemplatesNew,
    meta: {
      navActiveLink: 'documents-templates',
    },
  },
  {
    path: '/documents/templates/edit/:id',
    name: 'documents-templates-edit',
    component: TemplatesEdit,
    meta: {
      navActiveLink: 'documents-templates',
    },
  },
  {
    path: '/documents/templates/view/:id',
    name: 'documents-templates-view',
    component: TemplatesVeiw,
    meta: {
      navActiveLink: 'documents-templates',
    },
  },
]
