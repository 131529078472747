export default [
  {
    title: 'Human Resources',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Employees',
        route: 'human-resources-employees',
      },
      {
        title: 'Facilities',
        route: 'human-resources-facilities',
      },
      {
        title: 'Vendors',
        route: 'human-resources-vendors',
      },
      {
        title: 'Jobs / Prospect Admin',
        route: 'human-resources-prospects-admin-jobs',
        children: [
          {
            title: 'Dashboard',
            route: 'human-resources-prospects-admin-dashboard',
          },
          {
            title: 'Jobs',
            route: 'human-resources-prospects-admin-jobs',
          },
          {
            title: 'Group Jobs',
            route: 'human-resources-prospects-admin-group-jobs',
          },
          {
            title: 'Prospects',
            route: 'human-resources-prospects-admin-prospects',
          },
          {
            title: 'Prospect Matcher',
            route: 'human-resources-prospects-admin-prospect-matcher',
          },
        ],
      },
      {
        title: 'Indeed Job Administration',
        children: [
          {
            title: 'Group Jobs',
            route: 'human-resources-indeed-prospects-administration-group-jobs',
          },
          {
            title: 'Closed Jobs',
            route: 'human-resources-indeed-prospects-administration-closed-jobs',
          },
        ],
      },
      {
        title: 'Onboarding',
        route: 'human-resources-onboarding',
      },
    ],
  },
]
