<template>
  <b-card
    class="mb-0"
  >
    <div>
      <div class="mb-0">
        <b-row class="mb-1">
          <b-col
            cols="12"
            md="4"
          >
            <h4 class="card-title mb-0 pt-50">
              Jobs By Bill Rate Descending With Prospects</h4>
          </b-col>
          <b-col
            cols="12"
            md="8 ml-auto"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-group class="w-50 mb-sm-0 mr-2">
                <v-select
                  id="vendor_id"
                  v-model="vendor_id"
                  :options="vendors"
                  :reduce="item => item.id"
                  :clearable="true"
                  placeholder="Vendor"
                  @input="onVendorTypeChanged($event)"
                />
              </b-form-group>
              <b-form-group class="w-50 mb-sm-0 mr-2">
                <v-select
                  id="source_id"
                  v-model="prospect_type"
                  :options="prospectTypes"
                  :reduce="item => item.id"
                  :clearable="true"
                  placeholder="Select Prospect Type"
                  @input="onProspectTypeChanged($event)"
                  />
              </b-form-group>
              <b-form-group class="w-50 mb-sm-0 mr-2">
                <v-select
                  id="source_id"
                  v-model="sourceTypeIds"
                  :options="sourceTypes"
                  :reduce="item => item.id"
                  :clearable="false"
                  multiple
                  placeholder="Select Source"
                  @input="onTypeChanged($event)"
                  />
              </b-form-group>
              <b-button
                variant="outline-secondary"
                @click="back"
              >
                Back
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table
        bordered
        hover
        responsive
        class="custom-table-details custom-table-details2 position-relative"
        :current-page="currentPage"
        :items="reportResponse.job_prospects"
        :fields="fields"
        select-mode="multi"
      >
        <template #cell(cellRendererCurrency)="rate">
          <!-- Column: Currency -->
          <span>{{ rate.item.rate| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
        </template>
        <template #cell(job_id)="data">
          <b-link v-if="data.item.job_path !== null" :href="data.item.job_path" target="_blank">
            <img
              class="mr-25 mb-25 img-fluid"
              width="24"
              height="24"
              :src="require('@/assets/images/icons/stafferlink.png')"
              alt="image slot"
            >
          </b-link>
          <b-link :to="{ name: 'forecasting-reports-jobs-view', params: { id: data.item.id } }">
             {{ data.value }}
          </b-link>
          <img
            v-if="data.item.is_urgent"
            class="ml-50 img-fluid"
            width="24"
            height="24"
            :src="require('@/assets/images/Megaphone.gif')"
            alt="image slot"
          >
          <img
            v-if="data.item.applied_prospects"
            class="ml-50 img-fluid"
            width="24"
            height="24"
            :src="require('@/assets/images/icons/verified-candidate.png')"
            alt="image slot"
          >
          <b-link
            v-b-tooltip.hover.v-primary
            :title="data.item.advertised_description"
            :href="data.item.advertised_link"
            target="blank"
          >
            <img
              v-show="data.item.is_advertised"
              class="ml-50 img-fluid"
              width="24"
              height="24"
              :src="require('@/assets/images/icons/advertise.png')"
              alt="image slot"
            >
          </b-link>
        </template>
        <template #cell(available_prospects)="row">

          <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
          <div>
            <span class="mr-25">{{row.item.available_prospects}}</span>/<span class="ml-25 mr-50">{{row.item.registrants}}</span>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon rounded-circle px-25 py-25"
              size="sm"
              @click="() => {if (!row.item.prospects_loaded) { loadJobProspects(row.item)} row.toggleDetails(); row.detailsShowing = !row.detailsShowing}"
            >
              <feather-icon :icon="row.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
            </b-button>
          </div>
        </template>

        <!-- full detail on click -->
        <template #row-details="row">
          <b-table
            :items="row.item.prospects"
            :fields="fields2"
            striped
            responsive
            class="mb-0"
          >
            <template #cell(name)="data">
              <b-link @click="updateProspectViewData(data.item)">
                {{ data.item.name }}
              </b-link>
              <img
                v-if="data.item.is_as_registrant"
                class="ml-50 img-fluid"
                width="24"
                height="24"
                :src="require('@/assets/images/icons/registrant.png')"
                alt="image slot"
              >
            </template>
            <template #cell(communication)="data">
              <b-link
                v-b-tooltip.hover
                title="mail"
                :class="data.item.mails.length === 0 ? 'notes-icon mr-1' : 'mr-1 notes-icon active'"
                @click="showMails(data.item)"
              >
                <feather-icon
                  icon="MailIcon"
                  size="16"
                />
              </b-link>
              <b-link
                v-b-tooltip.hover
                title="message"
                class="mr-1"
              >
                <feather-icon
                  icon="MessageCircleIcon"
                  size="16"
                />
              </b-link>
              <b-link
                v-b-tooltip.hover
                title="call"
                class="mr-1"
              >
                <feather-icon
                  icon="PhoneIcon"
                  size="16"
                />
              </b-link>
              <b-link
                v-b-tooltip.hover
                title="reminder"
                class="mr-1"
              >
                <feather-icon
                  icon="MessageSquareIcon"
                  size="16"
                />
              </b-link>
              <b-link
                v-b-tooltip.hover
                title="notes"
                id="notes"
                :class="data.item.comments.length === 0 ? 'notes-icon mr-1' : 'notes-icon active mr-1'"
                @click="showNotes(data.item)"
              >
                <feather-icon
                  icon="FileTextIcon"
                  size="16"
                />
              </b-link>
              <b-link
                v-b-tooltip.hover
                :title="data.item.is_stopped ? `Click to contact activate` : `Click to don't contact`"
                :class="data.item.is_stopped ? 'text-danger' : 'text-primary'"
                @click="stopProspect(data.item.id, data.item.is_stopped, data.item.name)"
              >
                <feather-icon
                  icon="ThumbsDownIcon"
                  size="16"
                />
              </b-link>
            </template>
          </b-table>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <div class="d-flex align-items-center mb-0 mt-1">
          <div>
            <img
              class="mlr50 img-fluid"
              width="24"
              height="24"
              :src="require('@/assets/images/icons/megaphone.png')"
              alt="image slot"
            >
            <span class="ml-50">Urgent Job</span>
          </div>
          <div class="ml-2">
            <img
              class="mlr50 img-fluid"
              width="24"
              height="24"
              :src="require('@/assets/images/icons/advertise.png')"
              alt="image slot"
            >
            <span class="ml-50">Added Advertised</span>
          </div>
          <div class="ml-2">
            <img
              class="mlr50 img-fluid"
              width="24"
              height="24"
              :src="require('@/assets/images/icons/verified-candidate.png')"
              alt="image slot"
            >
            <span class="ml-50">Applied Prospects</span>
          </div>
        </div>
        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="reportResponse.count"
            prev-class="prev-item"
            next-class="next-item"
            first-number
            last-number
            align="right"
            :per-page="1"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
      <!-- <prospect-matcher-right-sidebar
        ref="loadRight"
        @prospectDeleted="prospectDeleted"
        :class="{'show': showProspectDetails}"
        @close-prospect-view="showProspectDetails = false"
      /> -->
      <b-modal
        ref="prospect_details"
        cancel-variant="outline-secondary"
        ok-title="Add"
        cancel-title="Close"
        centered
        title="Prospect Details"
        size="xl"
        :no-close-on-backdrop="true"
        @hide="closingProspctDetailsPopup"
      >
        <div
          v-if="selectedProspect"
          class="email-app-details email-app-details-custom3"
        >

          <!-- Email Header -->
          <div class="email-detail-header">

            <!-- Header: Left -->
            <div class="email-header-left d-flex align-items-center">
              <div class="user-details d-flex justify-content-between align-items-center flex-wrap">
                <b-avatar
                  size="48"
                  :src="selectedProspect.avatar"
                  class="mr-75"
                />
                <div class="mail-items">
                  <h5 class="mb-0">
                    {{ selectedProspect.name }}
                    <div class="font-small-5 text-muted mr-25">{{ selectedProspect.email }}</div>
                  </h5>
                </div>
              </div>
            </div>
            <div class="email-header-right ml-2 pl-1">
              <b-link
                target="_blank"
                :href="selectedProspect.resume"
                class="btn btn-outline-primary"
              >
                Resume
              </b-link>
            </div>
          </div>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="email-scroll-area email-scroll-area-custom scroll-area p-0"
          >
            <b-card
              no-body
              class="box-shadow-0"
            >
              <b-card-body class="mail-message-wrapper pt-2">
                <div class="mail-message">
                  <b-row>
                    <b-col
                      cols="12"
                      md="4"
                      class="col-xxl-2"
                    >
                      <div class="d-flex align-items-center mb-1">
                        <feather-icon
                          icon="PhoneIcon"
                          size="16"
                        />
                        <h5 class="text-body mb-0 ml-50">
                          {{ selectedProspect.phone_number }}
                        </h5>
                      </div>
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                      class="col-xxl-3"
                    >
                      <div class="d-flex mb-1">
                        <div>
                          <feather-icon
                            icon="SettingsIcon"
                            size="16"
                          />
                        </div>
                        <div>
                          <h5 class="text-body mb-0 ml-50">
                            <span
                              v-for="skill in selectedProspect.skills" :key="skill"
                              class="mr-1"
                            >
                              {{ skill }}
                            </span>
                          </h5>
                        </div>
                      </div>
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                      class="col-xxl-3"
                    >
                      <div class="d-flex mb-1">
                        <div>
                          <feather-icon
                            icon="ListIcon"
                            size="16"
                          />
                        </div>
                        <div>
                          <h5 class="text-body mb-0 ml-50">
                            <span
                              v-for="secondarySkill in selectedProspect.secondary_skills" :key="secondarySkill"
                              class="mr-1"
                            >
                              {{ secondarySkill }}
                            </span>
                          </h5>
                        </div>
                      </div>
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                      class="col-xxl-2"
                    >
                      <div class="d-flex mb-1">
                        <div>
                          <feather-icon
                            icon="BookOpenIcon"
                            size="16"
                          />
                        </div>
                        <div>
                          <h5 class="text-body mb-0 ml-50">
                            <span
                              v-for="education in selectedProspect.educations" :key="education"
                              class="mr-1"
                            >
                              {{ education }}
                            </span>
                          </h5>
                        </div>
                      </div>
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                      class="col-xxl-2"
                    >
                      <div class="d-flex mb-1">
                        <div>
                          <feather-icon
                            icon="AwardIcon"
                            size="16"
                          />
                        </div>
                        <div>
                          <h5 class="text-body mb-0 ml-50">
                            <span
                              v-for="license in selectedProspect.licenses" :key="license"
                              class="mr-1"
                            >
                              {{ license }}
                            </span>
                          </h5>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="12">
                      <div class="d-flex mt-1">
                        <feather-icon
                          icon="FileTextIcon"
                          size="16"
                        />
                        <h5 class="ml-50">Notes</h5>
                      </div>
                      <div v-for="comment in selectedProspect.comments" :key="comment">
                        <p v-html="comment.commentUserText"></p>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-card-body>
            </b-card>
          </vue-perfect-scrollbar>
        </div>
        <template #modal-footer>
          <div class="chat-app-window chat-app-window-custom w-100">
          </div>
        </template>
      </b-modal>
      <b-modal
        ref="add_notes"
        cancel-variant="outline-secondary"
        ok-title="Add"
        cancel-title="Close"
        centered
        title="Add Notes"
        size="lg"
        :no-close-on-backdrop="true"
        @hide="closingNotesPopup"
      >
        <b-form>
          <div class="previous-notes">
            <div class="single-note" v-for="(commentItem, index) in userComments" :id="index" :key="index" >
              <div class="note-author">
                <p v-html="commentItem.commentUserText"></p>
              </div>
            </div>
          </div>
        </b-form>
        <template #modal-footer>
          <div class="chat-app-window chat-app-window-custom w-100">
            <div class="chat-app-form">
              <b-input-group class="input-group-merge form-send-message mr-1">
                <b-form-textarea
                  v-model="addNotesRequest.comment"
                  placeholder="Enter your notes"
                />
              </b-input-group>
              <b-button
                variant="primary"
                type="submit"
                @click="saveNote()"
              >
                Send
              </b-button>
            </div>
          </div>
        </template>
      </b-modal>
      <b-modal
        ref="sent_mails"
        cancel-variant="outline-secondary"
        ok-title="Ok"
        cancel-title="Close"
        centered
        title="Recent Mails Sent On"
        size="lg"
        :no-close-on-backdrop="true"
        @hide="closeSentMailsPopup"
      >
        <template #modal-header>
          <div class="d-flex justify-content-between align-items-center w-100">
            <h5 class="modal-title">Recent Mails Sent On</h5>
            <b-button
              variant="primary"
              type="submit"
              class="mr-1"
              v-show="!addNewMailSent"
              @click="showNewMailSent"
            >
              Add
            </b-button>
          </div>
          <button type="button" @click="closeSentMailsPopup" aria-label="Close" class="close">×</button>
        </template>
        <validation-observer v-if="addNewMailSent" ref="mailSimpleRules">
          <b-form>
            <b-row>
              <b-col cols="4">
                <b-form-group>
                  <label>Job Id</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Job Id"
                    rules="integer"
                  >
                    <b-form-input
                      id="job_id"
                      v-model="addSentMail.job_id"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group>
                  <label>Sent Date</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Sent Date"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="sent_date"
                      class="form-control"
                      :config="startDateConfig"
                      placeholder="MM-DD-YYYY"
                      :clear-button="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group>
                  <label>Sent Time</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Sent Time"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="addSentMail.time"
                      class="form-control"
                      :config="startTimeConfig"
                      :placeholder="'00:00'"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group>
                  <label>Decription</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Decription"
                    rules="required"
                  >
                    <b-form-textarea
                      v-model="addSentMail.description"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <hr>

            <b-row class="mb-1">
              <b-col>
              </b-col>
              <b-col class="text-right">
                <b-button
                  variant="outline-secondary"
                  type="button"
                  class="mr-1"
                  @click="closeSentMail"
                >
                  Cancel
                </b-button>
                <b-button
                  variant="primary"
                  type="button"
                  @click="mailSentSubmit"
                >
                  Save
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <b-table
          bordered
          hover
          responsive
          class="custom-table-details"
          :items="sentMails"
          :fields="fields"
        />
        <template #modal-footer>
          <b-button
            variant="outline-secondary"
            type="button"
            @click="closeSentMailsPopup"
          >
            Close
          </b-button>
        </template>
      </b-modal>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BLink, BButton, BFormGroup, BCardBody, BTable, BPagination, VBTooltip, BModal, VBModal, BFormTextarea, BInputGroup, BForm, BFormInput, BAvatar,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store/index'
import Reports from '@/js/reports'
import {
  required,
} from '@validations'
import LIST from '@/js/lists'
import Prospects from '@/js/prospect'
import ParentComments from '@/js/parentcomments'
import Parentmailsent from '@/js/parentmailsent'
import ProspectMatcher from '@/js/prospectmatcher'
import Constants from '@/js/constant'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  name: 'JobsByPriorityReport',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardBody,
    BInputGroup,
    BLink,
    BTable,
    BPagination,
    BRow,
    BFormGroup,
    BForm,
    BCol,
    BButton,
    BFormInput,
    BFormTextarea,
    BModal,
    BAvatar,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      required,
      perPage: 10,
      showProspectDetails: false,
      report: {},
      reportResponse: {
        job_prospects: [],
        count: 1,
      },
      vendor_id: null,
      vendors: [],
      selectedProspect: null,
      sentMails: [],
      addNewMailSent: false,
      userComments: [],
      prospect_type: null,
      prospectTypes: [{
        id: 'registrants',
        label: 'Registrants',
      },
      {
        id: 'non_registrants',
        label: 'Non Registrants',
      }],
      sourceTypeIds: [],
      sourceTypes: [],
      addNotesRequest: {
        comment: '',
        user_id: null,
        date: null,
        time: null,
      },
      startDateConfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'm-d-Y',
        altInput: true,
        dateFormat: 'm-d-Y',
        minDate: null,
        maxDate: null,
      },
      startTimeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'h:i K',
      },
      addSentMail: {
        job_id: '',
        user_id: null,
        description: '',
        date: null,
        time: null,
      },
      fields: [
        {
          label: 'Job Id',
          key: 'job_id',
          class: 'thMwid110',
          sortable: false,
        },
        {
          label: 'Title',
          key: 'title',
          class: 'thMwid80',
          sortable: false,
        },
        {
          label: 'State',
          key: 'state',
          sortable: false,
        },
        {
          label: 'City',
          key: 'city',
          sortable: false,
        },
        {
          label: 'Rate',
          key: 'cellRendererCurrency',
          class: 'thMwid90',
          sortable: false,
        },
        {
          label: 'Facility',
          key: 'facility',
          sortable: false,
        },
        {
          label: 'Vendor',
          key: 'vendor',
          sortable: false,
        },
        {
          label: 'Entry Date',
          key: 'entry_date',
          class: 'thMwid120',
          sortable: false,
        },
        {
          label: 'Duration',
          key: 'duration',
          sortable: false,
        },
        {
          label: 'Guaranteed Hours',
          key: 'guaranteed_hours',
          sortable: false,
        },
        {
          label: 'Prospects / Registrants',
          key: 'available_prospects',
          class: 'thMwid100',
          sortable: false,
        },
      ],
      fields2: [
        { key: 'name', label: 'Name' },
        { key: 'email', label: 'Email' },
        { key: 'phone_number', label: 'Phone' },
        { key: 'source', label: 'Source' },
        { key: 'distance', label: 'Distance' },
        { key: 'location', label: 'Location' },
        { key: 'communication', class: 'thMwid185', label: 'Communication' },
      ],
      rows: [],
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
        Reports.getJobProspectsReport(this, this.sourceTypeIds, this.prospect_type, this.vendor_id)
      },
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    '$store.state.lists.commonLists.lists.vendors': {
      immediate: true,
      handler() {
        LIST.getVendors(this)
      },
    },
    '$store.state.lists.commonLists.lists.source_types': {
      immediate: true,
      handler() {
        LIST.getSourceTypes(this)
      },
    },
  },
  created() {
    this.sourceTypeIds = this.$store.state.query.sourceTypeIds
    this.vendor_id = this.$store.state.query.vendor_id
    this.prospect_type = this.$store.state.query.prospect_type
    this.reportResponse.count = this.$store.state.query.page
    Reports.getJobProspectsReport(this, this.sourceTypeIds, this.prospect_type, this.vendor_id)
    LIST.getVendors(this)
    LIST.getSourceTypes(this)
  },
  methods: {
    onProspectTypeChanged() {
      this.$store.state.query.prospect_type = this.prospect_type
      this.currentPage = 1
    },
    onVendorTypeChanged() {
      this.$store.state.query.vendor_id = this.vendor_id
      this.currentPage = 1
    },
    loadJobProspects(row) {
      Reports.getJobProspects(this, row.id, this.sourceTypeIds, this.prospect_type, this.vendor_id)
    },
    onTypeChanged() {
      this.$store.state.query.sourceTypeIds = this.sourceTypeIds
      this.currentPage = 1
    },
    setJobProspects(jobId, prospects) {
      const selectedJob = this.reportResponse.job_prospects.find(job => job.id === jobId)
      if (selectedJob) {
        selectedJob.prospects = prospects.job_prospects
        selectedJob.prospects_loaded = true
      }
    },
    setReport(reportResponse) {
      this.reportResponse = reportResponse
    },
    setSourceTypes(sourceTypes) {
      this.sourceTypes = sourceTypes
    },
    updateProspectViewData(prospect) {
      this.selectedProspect = prospect
      this.$refs.prospect_details.show()
    },
    closingProspctDetailsPopup() {
      this.selectedProspect = null
    },
    prospectDeleted() {
      ProspectMatcher.getJobProspects(this, this.query, this.$store.state.query.page - 1, this.searchQuery)
      Reports.getJobProspectsReport(this, this.sourceTypeIds, this.prospect_type)
    },
    mailSentSubmit() {
      this.$refs.mailSimpleRules.validate().then(result => {
        if (result) {
          this.addSentMail.date = Constants.getEprmMonthDateFromString(this.sent_date)
          Parentmailsent.addMailSent(this, this.addSentMail)
        }
      })
    },
    showMails(row) {
      this.sentMails = row.mails
      this.addSentMail.user_id = row.id
      this.$refs.sent_mails.show()
    },
    showNewMailSent() {
      this.addNewMailSent = true
      this.sent_date = moment().tz('America/Chicago').format('MM-DD-YYYY')
      this.addSentMail.time = moment().tz('America/Chicago').format('hh:mm a')
    },
    showNotes(data) {
      this.userComments = []
      this.$refs.add_notes.show()
      ParentComments.getCommentsForJobUser(this, data.id)
      this.addNotesRequest.user_id = data.id
    },
    saveNote() {
      this.addNotesRequest.comment = this.addNotesRequest.comment.trim()
      if (this.addNotesRequest.comment === '') {
        return
      }
      this.addNotesRequest.date = Constants.getEprmMonthDateFromString(moment())
      this.addNotesRequest.time = moment().format('hh:mm a')
      ParentComments.addComment(this, this.addNotesRequest.user_id, this.addNotesRequest)
    },
    setVendors(vendors) {
      this.vendors = vendors
    },
    prospectStopped(body) {
      Reports.getJobProspectsReport(this, this.sourceTypeIds, this.prospect_type, this.vendor_id)
      this.$swal({
        icon: 'success',
        title: 'Success',
        text: body.return_message,
      })
    },
    stopProspect(id, stopped, name) {
      const action = stopped ? 'activate' : 'stop'
      const message = `Are you sure you want to ${action} contact with this prospect, ${name}?`
      const swalConfig = {
        title: 'Are you sure?',
        text: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Yes, ${action}`,
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }
      this.$swal(swalConfig).then(result => {
        if (result.value) {
          Prospects.stopProspect(this, id, stopped)
        }
      })
    },
    setParentComments(userComments, userId) {
      this.userComments = userComments.comments
      this.reportResponse.job_prospects.forEach(jobProspect => {
        jobProspect.prospects.map(prospect => {
          if (prospect.id === userId) {
            return { ...prospect, comments: userComments.comments }
          }
          return prospect
        })
      })
      this.addNotesRequest.comment = ''
    },
    closeSentMailsPopup() {
      this.sentMails = []
      this.$refs.sent_mails.hide()
      this.addSentMail = {
        job_id: '',
        user_id: null,
        description: '',
        date: null,
        time: null,
      }
      this.addNewMailSent = false
    },
    closeSentMail() {
      const id = this.addSentMail.user_id
      this.addSentMail = {
        job_id: '',
        user_id: null,
        description: '',
        date: null,
        time: null,
      }
      this.addNewMailSent = false
      this.addSentMail.user_id = id
    },
    closingNotesPopup() {
      this.userComments = []
      this.addNotesRequest = {
        comment: '',
        user_id: null,
        date: null,
        time: null,
      }
    },
    back() {
      this.$router.push('/forecasting/reports').catch(() => {})
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
