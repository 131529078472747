var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('h4',{staticClass:"card-title mb-0 pt-50"},[_vm._v(" Edit Prospect")])]),_c('b-col',{attrs:{"cols":"12","md":"6 ml-auto"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.back}},[_vm._v(" Back ")])],1)])],1),_c('hr'),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{staticClass:"ex-large"},[_c('app-timeline',[_c('app-timeline-item',{staticClass:"pb-1"},[_c('div',{staticClass:"d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"},[_c('h6',[_vm._v("Personal Details")])]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("First Name")]),_c('validation-provider',{attrs:{"name":"First Name","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first_name","state":errors.length > 0 ? false:null},model:{value:(_vm.addProspect.first_name),callback:function ($$v) {_vm.$set(_vm.addProspect, "first_name", $$v)},expression:"addProspect.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Last Name")]),_c('validation-provider',{attrs:{"name":"Last Name","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last_name","state":errors.length > 0 ? false:null},model:{value:(_vm.addProspect.last_name),callback:function ($$v) {_vm.$set(_vm.addProspect, "last_name", $$v)},expression:"addProspect.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Email")]),_c('validation-provider',{attrs:{"name":"Email","rules":_vm.addProspect.email !== '' || _vm.addProspect.phone === '' ? 'required|email' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null},model:{value:(_vm.addProspect.email),callback:function ($$v) {_vm.$set(_vm.addProspect, "email", $$v)},expression:"addProspect.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Phone")]),_c('validation-provider',{attrs:{"name":"Phone","rules":_vm.addProspect.phone !== '' ? 'required|integer|digits:10' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","state":errors.length > 0 ? false:null},model:{value:(_vm.addProspect.phone),callback:function ($$v) {_vm.$set(_vm.addProspect, "phone", $$v)},expression:"addProspect.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Gender")]),_c('validation-provider',{attrs:{"name":"Gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"gender","options":_vm.genders,"reduce":function (item) { return item.id; },"clearable":false,"placeholder":"Select"},model:{value:(_vm.addProspect.gender_id),callback:function ($$v) {_vm.$set(_vm.addProspect, "gender_id", $$v)},expression:"addProspect.gender_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Source")]),_c('validation-provider',{attrs:{"name":"Source"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"source","options":_vm.sourceTypes,"reduce":function (item) { return item.id; },"clearable":false,"placeholder":"Select","rules":"required"},model:{value:(_vm.addProspect.source_type_id),callback:function ($$v) {_vm.$set(_vm.addProspect, "source_type_id", $$v)},expression:"addProspect.source_type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Main Skill")]),_c('validation-provider',{attrs:{"name":"Main Skill","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"role","options":_vm.skills,"reduce":function (item) { return item.id; },"multiple":"","clearable":false,"placeholder":"Select"},on:{"input":_vm.skillSelected},model:{value:(_vm.addProspect.skill_ids),callback:function ($$v) {_vm.$set(_vm.addProspect, "skill_ids", $$v)},expression:"addProspect.skill_ids"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("States")]),_c('validation-provider',{attrs:{"name":"States","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"states","options":_vm.states,"reduce":function (item) { return item.id; },"clearable":false,"placeholder":"Select"},on:{"input":_vm.stateChanged},model:{value:(_vm.addProspect.state_id),callback:function ($$v) {_vm.$set(_vm.addProspect, "state_id", $$v)},expression:"addProspect.state_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("City, Zip")]),_c('validation-provider',{attrs:{"name":"City, Zip","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"location","options":_vm.locations,"reduce":function (item) { return item.id; },"clearable":false,"placeholder":"Select"},model:{value:(_vm.addProspect.city_id),callback:function ($$v) {_vm.$set(_vm.addProspect, "city_id", $$v)},expression:"addProspect.city_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Modify Type")]),_c('v-select',{attrs:{"options":_vm.addProspect.other_types,"reduce":function (item) { return item.id; },"clearable":false,"placeholder":"Select"},on:{"input":function($event){return _vm.onTypeChanged()}},model:{value:(_vm.addProspect.type_id),callback:function ($$v) {_vm.$set(_vm.addProspect, "type_id", $$v)},expression:"addProspect.type_id"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Linkedin URL")]),_c('b-form-input',{attrs:{"id":"linkedin"},model:{value:(_vm.addProspect.linkedin_url),callback:function ($$v) {_vm.$set(_vm.addProspect, "linkedin_url", $$v)},expression:"addProspect.linkedin_url"}})],1)],1),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('b-form-checkbox',{model:{value:(_vm.addProspect.is_linkedin_checked),callback:function ($$v) {_vm.$set(_vm.addProspect, "is_linkedin_checked", $$v)},expression:"addProspect.is_linkedin_checked"}},[_vm._v(" Is Linkedin Checked ")])],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Applied Date")]),_c('validation-provider',{attrs:{"name":"Applied Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.$store.state.config,"placeholder":"MM-DD-YYYY"},model:{value:(_vm.applied_date),callback:function ($$v) {_vm.applied_date=$$v},expression:"applied_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.addProspect.attachment !== null),expression:"addProspect.attachment !== null"}],attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Previous Resume")]),_c('span',{staticClass:"form-control"},[_c('b-link',{attrs:{"href":_vm.addProspect.attachment_link,"title":_vm.addProspect.attachment,"target":"_blank"}},[_c('b-badge',{staticClass:"mr-1 mb-50",attrs:{"variant":"primary"}},[_c('span',[_vm._v(_vm._s(_vm.addProspect.attachment))])])],1)],1)])],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Resume")]),_c('input',{ref:"myFileInput",staticClass:"form-control text-left",attrs:{"type":"file","placeholder":"Choose a file or drop it here..."},on:{"change":_vm.attahamentFileSelected}})])],1)],1)],1),_c('app-timeline-item',{staticClass:"pb-1",attrs:{"variant":"info"}},[_c('div',{staticClass:"d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1"},[_c('h6',[_vm._v("Secondary Skills")])]),_c('b-row',_vm._l((_vm.newTechnologies),function(technology){return _c('b-col',{key:technology.id,staticClass:"mb-1",attrs:{"value":technology.id,"cols":"2"}},[_c('b-form-checkbox',{attrs:{"checked":_vm.technologySelected(technology.technology_id)},on:{"change":function($event){return _vm.checked(technology.technology_id)}}},[_vm._v(" "+_vm._s(technology.label)+" ")])],1)}),1)],1)],1),_c('hr'),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"primary","type":"button"},on:{"click":_vm.submitForm}},[_vm._v(" Update Prospect ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }