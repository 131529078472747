import axios from 'axios'

const task = 'task'

export default {
  getTasks(context1) {
    const context = context1
    let params = { query: context.searchQuery, page: context.currentPage - 1 }
    if (params !== {}) {
      params = { params: { query: context.searchQuery, page: context.currentPage - 1 } }
    } else {
      params = { params: {} }
    }
    axios.get(task, params).then(response => {
      context.setTasks(response.data)
    }, () => {
    })
  },
  toggleStatus(context1, id, statusId) {
    const context = context1
    context.$vs.loading()
    axios.put(`${task}/update-status/${id}/${statusId}`, {}).then(response => {
      context.$vs.loading.close()
      context.updated(response.data.return_message)
    }, error => {
      context.$vs.loading.close()
      context.updateError(error.response.data.return_message)
    })
  },
  addTask(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(task, body).then(response => {
      context.$vs.loading.close()
      context.taskAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.taskNotAdded(error.response.data.return_message)
    })
  },
  updateTask(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${task}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.taskUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.taskNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${task}/${id}`).then(response => {
      context.$vs.loading.close()
      context.taskDeleted(response.data)
    }, error => {
      context.$vs.loading.close()
      context.taskNotDeleted(error.response.data.return_message)
    })
  },
  getTaskById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${task}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setTask(response.data)
    }, error => {
      context.$vs.loading.close()
      context.taskNotFound(error.response.data.return_message)
    })
  },
  getparentTasks(context1, parentTypeId, parentId) {
    const context = context1
    context.$vs.loading()
    let params = { query: context.searchQuery, page: context.currentPage - 1 }
    if (params !== {}) {
      params = { params: { query: context.searchQuery, page: context.currentPage - 1 } }
    } else {
      params = { params: {} }
    }
    axios.get(`${task}/${parentTypeId}/${parentId}`).then(response => {
      context.$vs.loading.close()
      context.setParentTask(response.data)
    }, () => {
      context.$vs.loading.close()
    })
  },
  addParentTask(context1) {
    const context = context1
    context.$vs.loading()
    axios.post(`${task}/parent`, context.addTask).then(response => {
      context.$vs.loading.close()
      context.taskAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.taskNotAdded(error.response.data.return_message)
    })
  },

}
