import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.faurl
const incomeStatement = `${HOST}income-statement`

export default {
  getIncomeStatementDetails(context1, startDate, endDate) {
    const context = context1
    const params = { params: { start_date: startDate, end_date: endDate } }
    axios.get(incomeStatement, params).then(response => {
      context.setStatement(response.data)
    }, () => {
    })
  },
  addIncomeStatement(context1, body, isSave) {
    const context = context1
    context.$vs.loading()
    const params = { params: { save: isSave } }
    axios.post(incomeStatement, body, params).then(response => {
      context.$vs.loading.close()
      context.incomeStatementSaved(response.data)
    }, error => {
      context.$vs.loading.close()
      context.incomeStatementNotSaved(error.response.data.return_message)
    })
  },
}
