import axios from 'axios'

const salaries = 'salaries'

export default {
  getSalaries(context1) {
    const context = context1
    const params = { params: { query: context.$store.state.query.query, page: context.$store.state.query.page - 1 } }
    axios.get(salaries, params).then(response => {
      context.setSalaries(response.data)
    }, () => {
    })
  },
  addSalary(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(salaries, body).then(response => {
      context.$vs.loading.close()
      context.salaryAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.salaryNotAdded(error.response.data.return_message)
    })
  },
  updateSalary(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${salaries}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.salaryUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.salaryNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${salaries}/${id}`).then(response => {
      context.$vs.loading.close()
      context.salaryDeleted(response.data)
    }, error => {
      context.$vs.loading.close()
      context.salaryNotDeleted(error.response.data.return_message)
    })
  },
  getSalaryById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${salaries}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setSalary(response.data)
    }, error => {
      context.$vs.loading.close()
      context.salaryNotFound(error.response.data.return_message)
    })
  },

}
