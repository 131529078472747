var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{staticClass:"p-2"},[_c('validation-provider',{attrs:{"name":"Parent Types","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Parent Types","label-for":"field-type"}},[_c('v-select',{attrs:{"options":_vm.parentTypes,"multiple":"","reduce":function (item) { return item.id; },"placeholder":"Select"},model:{value:(_vm.addField.parent_type_ids),callback:function ($$v) {_vm.$set(_vm.addField, "parent_type_ids", $$v)},expression:"addField.parent_type_ids"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Field Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Field Name","label-for":"field-name"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.addField.name),callback:function ($$v) {_vm.$set(_vm.addField, "name", $$v)},expression:"addField.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Field Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Field Type","label-for":"field-type"}},[_c('v-select',{attrs:{"options":_vm.types,"reduce":function (item) { return item.id; },"placeholder":"Select"},on:{"input":_vm.typeChanged},model:{value:(_vm.addField.type_id),callback:function ($$v) {_vm.$set(_vm.addField, "type_id", $$v)},expression:"addField.type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_vm._l((_vm.addField.options),function(item,index){return _c('div',{key:item.id,staticClass:"d-flex ",attrs:{"id":item.id}},[_c('div',{staticClass:"w-100"},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":'Option' + (index + 1),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":'Option' + (index + 1),"state":errors.length > 0 ? false:null},model:{value:(item.option),callback:function ($$v) {_vm.$set(item, "option", $$v)},expression:"item.option"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0 ml-1",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)],1)])}),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.typeName.toLowerCase() === 'dropdown'),expression:"typeName.toLowerCase() === 'dropdown'"},{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-2",attrs:{"variant":"outline-primary"},on:{"click":_vm.repeateAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Add Option")])],1),_c('b-form-group',{attrs:{"label":"Description","label-for":"task-description"}},[_c('b-form-textarea',{model:{value:(_vm.addField.description),callback:function ($$v) {_vm.$set(_vm.addField, "description", $$v)},expression:"addField.description"}})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"value":"true"},model:{value:(_vm.addField.required),callback:function ($$v) {_vm.$set(_vm.addField, "required", $$v)},expression:"addField.required"}},[_vm._v(" Required ")])],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"button"},on:{"click":_vm.submitForm}},[_vm._v(" Add Field ")])],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }