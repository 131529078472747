import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.adurl
const process = `${HOST}job-process`

export default {
  getProcessFullList(context1) {
    const context = context1
    axios.get(`${process}/fulllist`).then(response => {
      context.setProcess(response.data)
    }, () => {
    })
  },
  getProcess(context1) {
    const context = context1
    let params = { query: context.searchQuery, page: context.currentPage - 1 }
    if (params !== {}) {
      params = { params: { query: context.searchQuery, page: context.currentPage - 1 } }
    } else {
      params = { params: {} }
    }
    axios.get(process, params).then(response => {
      context.setProcess(response.data)
    }, () => {
    })
  },
  addProcess(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(process, body).then(response => {
      context.$vs.loading.close()
      context.processAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.processNotAdded(error.response.data.return_message)
    })
  },
  updateProcess(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${process}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.processUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.processNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${process}/${id}`).then(response => {
      context.$vs.loading.close()
      context.processDeleted(response.data)
    }, error => {
      context.$vs.loading.close()
      context.processNotDeleted(error.response.data.return_message)
    })
  },
  getProcessById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${process}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setProcess(response.data)
    }, error => {
      context.$vs.loading.close()
      context.processNotFound(error.response.data.return_message)
    })
  },
}
