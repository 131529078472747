<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          View Workflow Task</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>

    <app-timeline>
      <app-timeline-item class="pb-1">
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Task Name</label>
              <span
                class="form-control h-auto"
                v-text="task.name"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Allowed User Type</label>
              <span class="form-control h-auto">
                <b-badge
                  v-for="(allowed_user_type, index) in task.allowed_user_types"
                  :key="index"
                  :value="index"
                  variant="light-primary"
                  class="mb-25 mr-50"
                >
                  <span v-text="allowed_user_type" />
                </b-badge>
              </span>
            </b-form-group>
          </b-col>
        </b-row>
      </app-timeline-item>
      <app-timeline-item
        variant="info"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Task Fields</h6>
        </div>
        <b-row
          v-for="(field, index) in task.fields"
          :key="index"
          :value="index"
        >
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Field Name</label>
              <span
                class="form-control"
                v-text="field.name"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Field Type</label>
              <span
                class="form-control"
                v-text="field.type"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-show="field.options.length !== 0"
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Options</label>
              <span
                class="form-control h-auto"
              >
                <b-badge
                  v-for="item in field.options"
                  :id="item.id"
                  :key="item.id"
                  variant="light-primary"
                  class="mr-50"
                >
                  {{ item.option }}
                </b-badge>
              </span>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Required</label>
              <span
                class="form-control"
                v-text="field.is_required ? 'Yes' : 'No'"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <hr class="mt-0 mb-50">
          </b-col>
        </b-row>
        <div
          v-if="task.fields.length == 0"
          class="text-center"
        >
          <p class="mb-0">
            No Task fields available
          </p>
        </div>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BButton, BBadge,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import ProcessTask from '@/js/processtask'

export default {
  name: 'WorkflowTaskView',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BBadge,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      task: {},
    }
  },
  computed: {
    myList: {
      get() {
        return this.process.tasks
      },
      set(value) {
        this.process.tasks = value
      },
    },
  },
  mounted() {
  },
  created() {
    ProcessTask.getProcessTaskById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    setProcessTask(task) {
      this.task = task
    },
    back() {
      this.$router.push('/administration/workflow-tasks').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
</style>
