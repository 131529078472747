import humanResources from './human-resources'
import dashboard from './dashboard'
import financeAndAccounting from './finance-and-accounting'
import financialStatements from './financial-statements'
import forecasting from './forecasting'
import administration from './administration'
import documents from './documents'
import eSignature from './e-signature'
import tasks from './tasks'
import employeeAdministration from './employee-administration'
import companies from './companies'

// Array of sections
export default [...companies, ...dashboard, ...humanResources, ...financeAndAccounting, ...financialStatements, ...forecasting, ...administration, ...employeeAdministration, ...documents, ...eSignature, ...tasks]
