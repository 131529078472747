export default [
  {
    title: 'Companies',
    route: 'companies',
    icon: 'LayersIcon',
  },
  {
    title: 'Exceptions',
    route: 'exceptions',
    icon: 'AlertTriangleIcon',
  },
]
