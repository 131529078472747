import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.url
const employementVerificationLetter = `${HOST}employement-verification-letter`

export default {
  getEmployeeVerificationLetterById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${employementVerificationLetter}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setEmployeeDetails(response.data)
    }, error => {
      context.$vs.loading.close()
      context.employeeNotFound(error.response.data.return_message)
    })
  },
  sendEmployeeVerificationLetterForSign(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${employementVerificationLetter}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.sentSuccess(response.data)
    }, error => {
      context.$vs.loading.close()
      context.sentFailure(error.response.data.return_message)
    })
  },
  getEmployeeVerificationLetterPdfById(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${employementVerificationLetter}/${id}/pdf`, body).then(response => {
      context.$vs.loading.close()
      context.pdfPath(response.data)
    }, error => {
      context.$vs.loading.close()
      context.pdfNotFound(error.response.data.return_message)
    })
  },
  getEmployeeVerificationLetterTemplateById(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${employementVerificationLetter}/${id}/update-content`, body).then(response => {
      context.$vs.loading.close()
      context.employerLetterTemplate(response.data)
    }, error => {
      context.$vs.loading.close()
      context.pdfNotFound(error.response.data.return_message)
    })
  },
  sendEmployeeVerificationLetterUpdatedContentForSign(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${employementVerificationLetter}/${id}/sign`, body).then(response => {
      context.$vs.loading.close()
      context.sentSuccess(response.data)
    }, error => {
      context.$vs.loading.close()
      context.sentFailure(error.response.data.return_message)
    })
  },
  getEmployeeVerificationLetterPdfByIdAndUpdatedContent(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${employementVerificationLetter}/${id}/update-content/pdf`, body).then(response => {
      context.$vs.loading.close()
      context.pdfPath(response.data)
    }, error => {
      context.$vs.loading.close()
      context.pdfNotFound(error.response.data.return_message)
    })
  },
}
