var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('h4',{staticClass:"card-title mb-0 pt-50"},[_vm._v(" Add Employee Salary")])]),_c('b-col',{attrs:{"cols":"12","md":"6 ml-auto"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.back}},[_vm._v(" Back ")])],1)])],1),_c('hr'),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('app-timeline',[_c('app-timeline-item',{staticClass:"pb-1"},[_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Employee")]),_c('validation-provider',{attrs:{"name":"Employee","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"employee_id","clearable":false,"placeholder":"Select","options":_vm.employees,"reduce":function (item) { return item.id; }},model:{value:(_vm.addSalary.employee_id),callback:function ($$v) {_vm.$set(_vm.addSalary, "employee_id", $$v)},expression:"addSalary.employee_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"id":"start_date"}},[_c('label',[_vm._v("Start Date")]),_c('validation-provider',{attrs:{"name":"Start Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.start_date_config,"placeholder":"MM-DD-YYYY"},on:{"input":_vm.startDateChanged},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"id":"end_date"}},[_c('label',[_vm._v("End Date")]),_c('validation-provider',{attrs:{"name":"End Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.end_date_config,"placeholder":"MM-DD-YYYY"},on:{"input":_vm.endDateChanged},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-50",attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-checkbox',{attrs:{"id":"1099","disabled":_vm.addSalary.is_india},on:{"input":_vm.checkChanged},model:{value:(_vm.addSalary.is_1099),callback:function ($$v) {_vm.$set(_vm.addSalary, "is_1099", $$v)},expression:"addSalary.is_1099"}},[_vm._v(" 1099 ")])],1),_c('b-col',{staticClass:"mt-50",attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-checkbox',{attrs:{"id":"india","disabled":_vm.addSalary.is_1099},on:{"input":_vm.indiaCheckChanged},model:{value:(_vm.addSalary.is_india),callback:function ($$v) {_vm.$set(_vm.addSalary, "is_india", $$v)},expression:"addSalary.is_india"}},[_vm._v(" India ")])],1)],1)],1),_c('app-timeline-item',{staticClass:"pb-1",attrs:{"variant":"info"}},[_c('div',{staticClass:"d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"},[_c('h6',[_vm._v("PayRoll Details")])]),_vm._l((_vm.addSalary.pay_rolls),function(pay_roll,index){return _c('div',{key:index,attrs:{"id":index}},[_c('h5',{staticClass:"mt-50"},[_vm._v(_vm._s(pay_roll.pay_roll_type))]),_c('div',{staticClass:"mt-1 ml-2"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Amount")]),_c('validation-provider',{attrs:{"name":'Amount' + index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"Amount","min":0,"step":0.5},model:{value:(pay_roll.amount),callback:function ($$v) {_vm.$set(pay_roll, "amount", $$v)},expression:"pay_roll.amount"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger",domProps:{"textContent":_vm._s(errors[0] !== undefined ? errors[0].replace('Amount' + index, 'Amount') : '')}})]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Status")]),_c('validation-provider',{attrs:{"name":'Status' + index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":'salary_status_id' + index,"clearable":false,"placeholder":"Select","options":_vm.status,"reduce":function (item) { return item.id; }},on:{"input":_vm.statusChanged},model:{value:(pay_roll.status_id),callback:function ($$v) {_vm.$set(pay_roll, "status_id", $$v)},expression:"pay_roll.status_id"}}),_c('small',{staticClass:"text-danger",domProps:{"textContent":_vm._s(errors[0] !== undefined ? errors[0].replace('Status' + index, 'Status') : '')}})]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Paid Date")]),_c('validation-provider',{attrs:{"name":'Paid Date' + index,"rules":pay_roll.is_required ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.$store.state.notGraterThanToday,"placeholder":"MM-DD-YYYY","clearable":"true"},model:{value:(pay_roll.salary_paid_date),callback:function ($$v) {_vm.$set(pay_roll, "salary_paid_date", $$v)},expression:"pay_roll.salary_paid_date"}}),_c('b-input-group-append',[_c('b-button',{staticClass:"px-1 outline-grey",attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.removeSalaryDate(index)}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)],1)],1),_c('small',{staticClass:"text-danger",domProps:{"textContent":_vm._s(errors[0] !== undefined ? errors[0].replace('Paid Date' + index, 'Paid Date') : '')}})]}}],null,true)})],1)],1)],1)],1)])})],2)],1),_c('hr'),_c('b-row',[_c('b-col',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"primary","type":"button"},on:{"click":_vm.submitForm}},[_vm._v(" Add Salary ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }