import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.url
const esignReceiver = `${HOST}esign-receiver`

export default {
  getESignatureByToken(context1, token) {
    const context = context1
    context.$vs.loading()
    axios.get(`${esignReceiver}/${token}`).then(response => {
      context.$vs.loading.close()
      context.setESignature(response.data)
    }, () => {
      context.$vs.loading.close()
      context.$router.push('/login').catch(() => {})
    })
  },
  submitEsignConsignment(context1, token, data) {
    const context = context1
    context.$vs.loading()
    axios.post(`${esignReceiver}/${token}/consign`, data).then(response => {
      context.$vs.loading.close()
      context.setConsignSuccess(response.data)
    }, error => {
      context.$vs.loading.close()
      context.setConsignFailure(error.response.data.return_message)
    })
  },
  submitEsign(context1, token, data) {
    const context = context1
    context.$vs.loading()
    axios.post(`${esignReceiver}/${token}/sign`, data).then(response => {
      context.$vs.loading.close()
      context.setEsignSuccess(response.data)
    }, error => {
      context.$vs.loading.close()
      context.setEsignFailure(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${esignReceiver}/${id}`).then(response => {
      context.$vs.loading.close()
      context.signatureDeleted(response.data)
    }, error => {
      context.$vs.loading.close()
      context.signatureNotDeleted(error.response.data.return_message)
    })
  },
}
