<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          View Task</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>

    <app-timeline>
      <app-timeline-item
        v-if="task.task_type === 'invoice'"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Invoice Details</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                {{ task.invoice_item.parent }}
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="task.invoice_item.parent_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Consultant
              </dt>
              <dd class="col-sm-12">
                <div
                  v-for="(option, index) in task.invoice_item.employee_names"
                  :key="index"
                >
                  <span
                    :key="index"
                    class="form-control"
                    v-text="option"
                  />
                </div>
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Invoice #
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="task.invoice_item.number"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Received Date
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="task.invoice_item.date"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Invoice Period
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="task.invoice_item.period"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        v-if="task.task_type === 'timesheet'"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Timesheet Details</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Name of Employee Consultant
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="task.timesheet_item.employee_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Timesheet Frequency
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="task.timesheet_item.frequency"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Period
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="task.timesheet_item.period"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        v-if="task.task_type === 'job'"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Job Details</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Job Title
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="task.job_item.job_title"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Facility
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="task.job_item.facility"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        v-if="task.task_type === 'parent'"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Parent Details</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                {{ task.parent_item.parent_type }} Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="task.parent_item.parent_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Email
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="task.parent_item.parent_email"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Phone
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="task.parent_item.parent_phone"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        v-if="task.task_type === 'interview'"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Interview Details</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Employer Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="task.interview_item.employer_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Candidate Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="task.interview_item.candidate_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Interview Date
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="task.interview_item.date"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Interview Time
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="task.interview_item.time"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Interviewer Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="task.interview_item.interviewer_name"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="info"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Task Details</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Task Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="task.task.name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Due Date
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="task.task.due_date"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="8"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Task Description
              </dt>
              <dd class="col-sm-12">
                <b-form-textarea
                  v-model="task.task.description"
                  rows="2"
                  disabled
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Status
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="task.task.status"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="success"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Comments</h6>
        </div>
        <b-row class="mt-1">
          <b-col
            v-for="comment in task.comments"
            :key="comment"
            cols="12"
            md="6"
            class="col-xxl-4"
          >
            <div class="d-flex align-items-start mb-2">
              <div class="chat-avatar mr-2">
                <b-avatar
                  size="36"
                  class="avatar-border-2 box-shadow-1"
                  variant="transparent"
                  src="@/assets/images/avatar-s.jpg"
                />
              </div>
              <div class="chat-body w-100">
                <h6><span class="mr-1">{{ comment.user_name }}</span> <small>Commented on {{ comment.date_text }} {{ comment.time }}</small></h6>
                <b-form-textarea
                  v-model="comment.comment"
                  rows="2"
                  disabled
                />
              </div>
            </div>
          </b-col>
        </b-row>
        <div
          v-if="task.comments.length == 0"
          class="text-center"
        >
          <p> No Comments available </p>
        </div>
      </app-timeline-item>

      <app-timeline-item
        v-show="task.user_defined_fields.length !== 0"
        variant="warning"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>User Defined Fields</h6>
        </div>
        <b-row>
          <b-col
            v-for="field in task.user_defined_fields"
            :id="field.id"
            :key="field.id"
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                {{ field.name }}
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="field.value"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import Task from '@/js/tasks'

import {
  BCard, BRow, BCol, BButton, BFormTextarea, BAvatar,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  name: 'TaskView',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BAvatar,
  },
  directives: {
  },
  setup() {
  },
  data() {
    return {
      task: {
        user_defined_fields: [],
      },
    }
  },
  mounted() {
  },
  created() {
    Task.getTaskById(this, this.$route.params.id)
  },
  methods: {
    setTask(task) {
      this.task = task
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>
