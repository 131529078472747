<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Add Company</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Company Information</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Company Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Company Name"
                    rules="required"
                  >
                    <b-form-input
                      id="company_name"
                      v-model="addCompany.company_name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Company Url id</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Company Url Id"
                    rules="required"
                  >
                    <b-form-input
                      id="company_url_id"
                      v-model="addCompany.company_url_id"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Status</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Status"
                    rules="required"
                  >
                    <v-select
                      id="status_id"
                      v-model="addCompany.status_id"
                      :options="status"
                      :reduce="item => item.id"
                      :clearable="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Company Logo</label>
                  <validation-provider
                    name="Company Logo"
                  >
                    <input
                      ref="myFileInput"
                      type="file"
                      class="form-control text-left"
                      @change="attahamentFileSelected"
                    >
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Contact Information</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Contact Name</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Contact Name"
                    rules="required|min:2"
                  >
                    <b-form-input
                      id="contact_name"
                      v-model="addCompany.contact_info.contact_name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Email</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email_address"
                      v-model="addCompany.contact_info.email_address"
                      :state="errors.length > 0 ? false:null"
                      type="email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Phone</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Phone"
                    rules="required|integer|digits:10"
                  >
                    <b-form-input
                      id="phone_number"
                      v-model="addCompany.contact_info.phone_number"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Address 1</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Address 1"
                    rules="required"
                  >
                    <b-form-input
                      id="address1"
                      v-model="addCompany.contact_info.address.address1"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Address 2</label>
                  <b-form-input
                    id="address2"
                    v-model="addCompany.contact_info.address.address2"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>City</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="City"
                    rules="required"
                  >
                    <b-form-input
                      id="city"
                      v-model="addCompany.contact_info.address.city"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>State</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="State"
                    rules="required"
                  >
                    <v-select
                      id="state_id"
                      v-model="addCompany.contact_info.address.state_id"
                      :options="states"
                      :reduce="item => item.id"
                      :clearable="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Zip Code</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Zip Code"
                    rules="required|integer|digits:5"
                  >
                    <b-form-input
                      id="zip_id"
                      v-model="addCompany.contact_info.address.zip_id"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Select Roles</h6>
            </div>
            <b-row
              v-for="(departmentDevision, index) in Object.keys(addCompany.devisions)"
              :key="index"
              :value="index"
              class="mt-1"
            >
              <b-col cols="12">
                <b-form-checkbox
                  v-model="addCompany.devisions[departmentDevision].selected"
                  class="custom-control-primary mb-1"
                  @input="departmentSelected(departmentDevision)"
                >
                  {{ departmentDevision }}
                </b-form-checkbox>
              </b-col>
              <b-col
                cols="12"
                class="pl-5"
              >
                <b-row v-show="addCompany.devisions[departmentDevision].selected">
                  <b-col
                    v-for="(devision, devisionIndex) in addCompany.devisions[departmentDevision]"
                    :key="devisionIndex"
                    :value="devisionIndex"
                    cols="3"
                  >
                    <b-form-checkbox
                      v-model="devision.selected"
                      class="custom-control-primary mb-1"
                      @input="devisionSelected(departmentDevision, devision.selected)"
                    >
                      {{ devision.name }}
                    </b-form-checkbox>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </app-timeline-item>

        </app-timeline>
        <hr>

        <b-row class="mt-2">
          <!-- submit and reset -->
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Save Company
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import {
  required,
} from '@validations'
import Company from '@/js/companies'
import LIST from '@/js/lists'

export default {
  name: 'CompanyNew',
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    AppTimeline,
    AppTimelineItem,
    BFormCheckbox,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    vSelect,
  },
  directives: {
  },
  setup() {
  },
  data() {
    return {
      required,
      addCompany: {
        company_name: '',
        company_url_id: '',
        status_id: null,
        devisions: {},
        contact_info: {
          contact_name: '',
          email_address: '',
          phone_number: '',
          address: {
            address1: '',
            address2: '',
            city: '',
            state_id: null,
            zip_id: '',
          },
        },
      },
      status: [],
      states: [],
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getStatesFullList(this)
        LIST.getCompanyStatusFullList(this)
        LIST.getAllRolesNyUserTypeId(this)
      },
    },
  },
  created() {
    LIST.getStatesFullList(this)
    LIST.getCompanyStatusFullList(this)
    LIST.getAllRolesNyUserTypeId(this)
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          this.showLoading = true
          Company.addCompany(this, this.addCompany)
        }
      })
    },
    attahamentFileSelected(obj) {
      const dis = this
      const fileReader = new FileReader()
      fileReader.onload = fileLoadEvent => {
        dis.addCompany.logo = fileLoadEvent.target.result
      }
      fileReader.readAsDataURL(obj.target.files[0])
    },
    setStatus(status) {
      this.status = status
    },
    setStates(states) {
      this.states = states
    },
    setAllRoles(departmentDevisions) {
      this.addCompany.devisions = departmentDevisions.devisions
      Object.keys(this.addCompany.devisions).forEach(key => {
        this.addCompany.devisions[key].selected = false
        Object.keys(this.addCompany.devisions[key]).forEach(devision => {
          this.addCompany.devisions[key][devision].selected = false
        })
      })
    },
    devisionSelected(departmentDevision, selected) {
      if (selected) {
        this.addCompany.devisions[departmentDevision].selected = true
      } else {
        this.addCompany.devisions[departmentDevision].selected = false
        Object.keys(this.addCompany.devisions[departmentDevision]).forEach(index => {
          if (this.addCompany.devisions[departmentDevision][index].selected) {
            this.addCompany.devisions[departmentDevision].selected = true
          }
        })
      }
    },
    departmentSelected(departmentDevision) {
      Object.keys(this.addCompany.devisions[departmentDevision]).forEach(index => {
        if (this.addCompany.devisions[departmentDevision].selected) {
          this.addCompany.devisions[departmentDevision][index].selected = true
        } else {
          this.addCompany.devisions[departmentDevision][index].selected = false
        }
      })
    },
    companyAdded(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    companyNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    back() {
      this.$router.push('/companies').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
