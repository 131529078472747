<template>
  <div class="sidebar-left sidebar-left-custom">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div class="email-app-menu">
          <div class="form-group-compose text-center border-bottom-1 py-1">
            <h5 class="mb-0">Users</h5>
          </div>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list sidebar-menu-list-custom scroll-area"
          >
            <div class="matcher-tree pl-2 pt-50">
              <v-tree
                ref="tree"
                :data="treeData"
                :multiple="false"
                @node-click="nodeclicked"
                @node-expand="nodeExpanded"
                @async-load-nodes="asyncLoad"
              ></v-tree>
            </div>
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref,
} from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import { VTree } from 'vue-tree-halower'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AssignJobCriteria from '@/js/assignjobcriteria'

export default {
  components: {
    VTree,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      initSelected: ['node-1'],
      treeData: [],
      selectedNode: null,
    }
  },
  props: {
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const isTaskHandlerSidebarActive = ref(false)

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    return {
      perfectScrollbarSettings,
      isTaskHandlerSidebarActive,
      mqShallShowLeftSidebar,
    }
  },
  created() {
    AssignJobCriteria.getUserByUserId(this)
  },
  methods: {
    nodechecked(node, v) {
      alert(`that a node-check envent ...${node.title}${v}`)
    },
    setUsers(data, node) {
      if (node === null || node === undefined) {
        this.treeData = data.lists
        return
      }
      if (!Object.prototype.hasOwnProperty.call(node, 'children')) {
        this.$set(node, 'children', [])
      }
      this.$set(node, 'children', [])
      node.children.push(...data.lists)
    },
    nodeclicked(nodes) {
      const node = nodes
      if (this.selectedNode) {
        this.selectedNode.selected = false
      }
      this.selectedNode = node
      if (node.selected && !node.expanded) node.expanded = true
      if (!node.expanded) return
      if (node.id !== undefined && node.id !== null) {
        this.$emit('loadCriterias', node.id)
      }
      AssignJobCriteria.getUserByUserId(this, node.id, node)
    },
    nodeExpanded(nodes) {
      const node = nodes
      if (!node.expanded) return
      if (node.id !== undefined && node.id !== null) {
        this.$emit('loadCriterias', node.id)
      }
      AssignJobCriteria.getUserByUserId(this, node.id, node)
    },
    async asyncLoad(node) {
      AssignJobCriteria.getUserByUserId(this, node.id, node)
    },
  },
}
</script>

<style>
</style>
