<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          View Employee Salary</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <b-form>
      <app-timeline>
        <app-timeline-item class="pb-1">
          <b-row class="mt-1">
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group>
                <label>Employee</label>
                <span
                  class="form-control text-left"
                  v-text="salaryResponse.employee"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group>
                <label>Start Date</label>
                <span
                  class="form-control text-left"
                  v-text="salaryResponse.start_date"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group>
                <label>End Date</label>
                <span
                  class="form-control text-left"
                  v-text="salaryResponse.end_date"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </app-timeline-item>

        <app-timeline-item
          variant="info"
          class="pb-1"
        >
          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6>PayRoll Details</h6>
          </div>
          <h5 class="mt-50">
            {{ salaryResponse.payroll_type }}
          </h5>
          <div class="mt-1 ml-2">
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <dl class="row">
                  <dt class="col-sm-12 dt-text">
                    Amount
                  </dt>
                  <dd class="col-sm-12">
                    <span class="form-control">{{ salaryResponse.amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
                  </dd>
                </dl>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <dl class="row">
                  <dt class="col-sm-12 dt-text">
                    Status
                  </dt>
                  <dd class="col-sm-12">
                    <span
                      class="form-control"
                      v-text="salaryResponse.status"
                    />
                  </dd>
                </dl>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <dl class="row">
                  <dt class="col-sm-12 dt-text">
                    Paid Date
                  </dt>
                  <dd class="col-sm-12">
                    <span
                      class="form-control"
                      v-text="salaryResponse.paid_date"
                    />
                  </dd>
                </dl>
              </b-col>
            </b-row>
          </div>
        </app-timeline-item>
      </app-timeline>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BButton,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import Salaries from '@/js/employeesalaries'

export default {
  name: 'EmployeeSalaryView',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      salaryResponse: {},
    }
  },
  mounted() {
  },
  created() {
    Salaries.getSalaryById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    setSalary(salaryResponse) {
      this.salaryResponse = salaryResponse
    },
    back() {
      this.$router.push('/finance-and-accounting/salaries').catch(() => {})
    },
  },
}
</script>
