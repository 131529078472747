<template>
  <b-card
    class="mb-0"
  >
    <div class="mb-2">
      <b-row>
        <b-col
          cols="12"
          md="3"
        >
          <h4 class="card-title mb-0 pt-50">
            Recon Report</h4>
        </b-col>
        <b-col
          cols="12"
          md="8 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              variant="outline-primary"
              class="ml-1"
              @click="back()"
            >
              Back
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <hr>

    <app-timeline>
      <app-timeline-item class="pb-1">
        <b-row>
          <b-col cols="3">
            <b-form-group>
              <label>Recon Date</label>
              <span
                class="form-control"
                v-text="report.reconcile_date"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group>
              <label>Reconciled By</label>
              <span
                class="form-control"
                v-text="report.user_name"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group>
              <label>Statement Start Date</label>
              <span
                class="form-control"
                v-text="report.transaction_start_date"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group>
              <label>Statement Ending Date</label>
              <span
                class="form-control"
                v-text="report.transaction_end_date"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="info"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Summary (Bank)</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="col-xxl-5"
          >
            <b-card
              class="card-transaction"
              no-body
            >
              <b-card-body class="pl-0">
                <div class="transaction-item">
                  <b-media
                    no-body
                    class="align-items-center"
                  >
                    <b-media-aside>
                      <b-avatar
                        rounded
                        size="32"
                        variant="light-primary"
                      >
                        <feather-icon
                          size="14"
                          icon="DollarSignIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="transaction-title">
                        Statement Begining Balance
                      </h6>
                    </b-media-body>
                  </b-media>
                  <div class="font-weight-bolder text-primary">
                    {{ report.bank_beginning_balance | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                  </div>
                </div>
                <div class="transaction-item">
                  <b-media
                    no-body
                    class="align-items-center"
                  >
                    <b-media-aside>
                      <b-avatar
                        rounded
                        size="32"
                        variant="light-success"
                      >
                        <feather-icon
                          size="14"
                          icon="CheckSquareIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="transaction-title">
                        Checks and Payments Cleared
                      </h6>
                    </b-media-body>
                  </b-media>
                  <div class="font-weight-bolder text-success">
                    {{ report.bank_check_payments_cleared }}
                  </div>
                </div>
                <div class="transaction-item">
                  <b-media
                    no-body
                    class="align-items-center w-75"
                  >
                    <b-media-aside>
                      <b-avatar
                        rounded
                        size="32"
                        variant="light-success"
                      >
                        <feather-icon
                          size="14"
                          icon="DollarSignIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="transaction-title">
                        Checks and Payments Cleared Amount
                      </h6>
                    </b-media-body>
                  </b-media>
                  <div class="font-weight-bolder text-success">
                    {{ report.bank_check_payments_cleared_amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                  </div>
                </div>
                <div class="transaction-item">
                  <b-media
                    no-body
                    class="align-items-center"
                  >
                    <b-media-aside>
                      <b-avatar
                        rounded
                        size="32"
                        variant="light-warning"
                      >
                        <feather-icon
                          size="14"
                          icon="CheckSquareIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="transaction-title">
                        Checks and Payments UnCleared
                      </h6>
                    </b-media-body>
                  </b-media>
                  <div class="font-weight-bolder text-warning">
                    {{ report.bank_check_payments_uncleared }}
                  </div>
                </div>
                <div class="transaction-item">
                  <b-media
                    no-body
                    class="align-items-center w-75"
                  >
                    <b-media-aside>
                      <b-avatar
                        rounded
                        size="32"
                        variant="light-warning"
                      >
                        <feather-icon
                          size="14"
                          icon="DollarSignIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="transaction-title">
                        Checks and Payments UnCleared Amount
                      </h6>
                    </b-media-body>
                  </b-media>
                  <div class="font-weight-bolder text-warning">
                    {{ report.bank_check_payments_uncleared_amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="col-xxl-5 offset-xxl-1"
          >
            <b-card
              class="card-transaction"
              no-body
            >
              <b-card-body class="pl-0">
                <div class="transaction-item">
                  <b-media
                    no-body
                    class="align-items-center"
                  >
                    <b-media-aside>
                      <b-avatar
                        rounded
                        size="32"
                        variant="light-primary"
                      >
                        <feather-icon
                          size="14"
                          icon="DollarSignIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="transaction-title">
                        Statement Ending Balance
                      </h6>
                    </b-media-body>
                  </b-media>
                  <div class="font-weight-bolder text-primary">
                    {{ report.bank_ending_balance | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                  </div>
                </div>
                <div class="transaction-item">
                  <b-media
                    no-body
                    class="align-items-center"
                  >
                    <b-media-aside>
                      <b-avatar
                        rounded
                        size="32"
                        variant="light-success"
                      >
                        <feather-icon
                          size="14"
                          icon="CheckSquareIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="transaction-title">
                        Deposits and Credits Cleared
                      </h6>
                    </b-media-body>
                  </b-media>
                  <div class="font-weight-bolder text-success">
                    {{ report.bank_deposits_uncleared }}
                  </div>
                </div>
                <div class="transaction-item">
                  <b-media
                    no-body
                    class="align-items-center w-75"
                  >
                    <b-media-aside>
                      <b-avatar
                        rounded
                        size="32"
                        variant="light-success"
                      >
                        <feather-icon
                          size="14"
                          icon="DollarSignIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="transaction-title">
                        Deposits and Credits Cleared Amount
                      </h6>
                    </b-media-body>
                  </b-media>
                  <div class="font-weight-bolder text-success">
                    {{ report.bank_deposits_cleared_amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                  </div>
                </div>
                <div class="transaction-item">
                  <b-media
                    no-body
                    class="align-items-center"
                  >
                    <b-media-aside>
                      <b-avatar
                        rounded
                        size="32"
                        variant="light-warning"
                      >
                        <feather-icon
                          size="14"
                          icon="CheckSquareIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="transaction-title">
                        Deposits and Credits UnCleared
                      </h6>
                    </b-media-body>
                  </b-media>
                  <div class="font-weight-bolder text-warning">
                    {{ report.bank_check_payments_uncleared }}
                  </div>
                </div>
                <div class="transaction-item">
                  <b-media
                    no-body
                    class="align-items-center w-75"
                  >
                    <b-media-aside>
                      <b-avatar
                        rounded
                        size="32"
                        variant="light-warning"
                      >
                        <feather-icon
                          size="14"
                          icon="DollarSignIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="transaction-title">
                        Deposits and Credits UnCleared Amount
                      </h6>
                    </b-media-body>
                  </b-media>
                  <div class="font-weight-bolder text-warning">
                    {{ report.bank_deposits_uncleared_amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="success"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Summary (Register)</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="col-xxl-5"
          >
            <b-card
              class="card-transaction"
              no-body
            >
              <b-card-body class="pl-0">
                <div class="transaction-item">
                  <b-media
                    no-body
                    class="align-items-center"
                  >
                    <b-media-aside>
                      <b-avatar
                        rounded
                        size="32"
                        variant="light-warning"
                      >
                        <feather-icon
                          size="14"
                          icon="CheckSquareIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="transaction-title">
                        Checks and Payments Uncleared
                      </h6>
                    </b-media-body>
                  </b-media>
                  <div class="font-weight-bolder text-warning">
                    {{ report.register_check_payments_uncleared }}
                  </div>
                </div>
                <div class="transaction-item">
                  <b-media
                    no-body
                    class="align-items-center w-75"
                  >
                    <b-media-aside>
                      <b-avatar
                        rounded
                        size="32"
                        variant="light-warning"
                      >
                        <feather-icon
                          size="14"
                          icon="DollarSignIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="transaction-title">
                        Checks and Payments Uncleared Amount
                      </h6>
                    </b-media-body>
                  </b-media>
                  <div class="font-weight-bolder text-warning">
                    {{ report.register_check_payments_uncleared_amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                  </div>
                </div>
                <div class="transaction-item">
                  <b-media
                    no-body
                    class="align-items-center"
                  >
                    <b-media-aside>
                      <b-avatar
                        rounded
                        size="32"
                        variant="light-success"
                      >
                        <feather-icon
                          size="14"
                          icon="CheckSquareIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="transaction-title">
                        Checks and Payments cleared
                      </h6>
                    </b-media-body>
                  </b-media>
                  <div class="font-weight-bolder text-success">
                    {{ report.register_check_payments_cleared }}
                  </div>
                </div>
                <div class="transaction-item">
                  <b-media
                    no-body
                    class="align-items-center w-75"
                  >
                    <b-media-aside>
                      <b-avatar
                        rounded
                        size="32"
                        variant="light-success"
                      >
                        <feather-icon
                          size="14"
                          icon="DollarSignIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="transaction-title">
                        Checks and Payments cleared Amount
                      </h6>
                    </b-media-body>
                  </b-media>
                  <div class="font-weight-bolder text-success">
                    {{ report.register_check_payments_cleared_amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="col-xxl-5 offset-xxl-1"
          >
            <b-card
              class="card-transaction"
              no-body
            >
              <b-card-body class="pl-0">
                <div class="transaction-item">
                  <b-media
                    no-body
                    class="align-items-center"
                  >
                    <b-media-aside>
                      <b-avatar
                        rounded
                        size="32"
                        variant="light-warning"
                      >
                        <feather-icon
                          size="14"
                          icon="CheckSquareIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="transaction-title">
                        Deposits and Credits Uncleared
                      </h6>
                    </b-media-body>
                  </b-media>
                  <div class="font-weight-bolder text-warning">
                    {{ report.register_deposits_uncleared }}
                  </div>
                </div>
                <div class="transaction-item">
                  <b-media
                    no-body
                    class="align-items-center w-75"
                  >
                    <b-media-aside>
                      <b-avatar
                        rounded
                        size="32"
                        variant="light-warning"
                      >
                        <feather-icon
                          size="14"
                          icon="DollarSignIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="transaction-title">
                        Deposits and Credits Uncleared Amount
                      </h6>
                    </b-media-body>
                  </b-media>
                  <div class="font-weight-bolder text-warning">
                    {{ report.register_deposits_uncleared_amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                  </div>
                </div>
                <div class="transaction-item">
                  <b-media
                    no-body
                    class="align-items-center"
                  >
                    <b-media-aside>
                      <b-avatar
                        rounded
                        size="32"
                        variant="light-success"
                      >
                        <feather-icon
                          size="14"
                          icon="CheckSquareIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="transaction-title">
                        Deposits and Credits cleared
                      </h6>
                    </b-media-body>
                  </b-media>
                  <div class="font-weight-bolder text-success">
                    {{ report.register_deposits_cleared }}
                  </div>
                </div>
                <div class="transaction-item">
                  <b-media
                    no-body
                    class="align-items-center w-75"
                  >
                    <b-media-aside>
                      <b-avatar
                        rounded
                        size="32"
                        variant="light-success"
                      >
                        <feather-icon
                          size="14"
                          icon="DollarSignIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="transaction-title">
                        Deposits and Credits cleared Amount
                      </h6>
                    </b-media-body>
                  </b-media>
                  <div class="font-weight-bolder text-success">
                    {{ report.register_deposits_cleared_amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BMedia, BMediaBody, BMediaAside, BAvatar, BRow, BCol, BFormGroup, BButton,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Reconcile from '@/js/reconcile'

export default {
  name: 'ReconReport',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    BCardBody,
    BMedia,
    BMediaBody,
    BMediaAside,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    // currency,
  },
  data() {
    return {
      report: {},
    }
  },
  computed: {
  },
  created() {
    Reconcile.getReconcileReportById(this, this.$route.params.id)
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    setReconcileReport(report) {
      this.report = report
    },
  },
}
</script>
<style lang="scss">
</style>
