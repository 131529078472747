<template>
  <b-card
    class="mb-0"
  >
  <div class="mb-1">
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
        <h4 class="card-title mb-0 pt-50">
          Jobs By {{ jobResponse.city_skill }}
        </h4>
        </b-col>
        <b-col
          cols="12"
          md="8 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              variant="outline-secondary"
              class="ml-2"
              @click="back"
            >
              Back
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
      <div>
        <vue-good-table
          :columns="columns"
          :rows="jobResponse.jobs"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :pagination-options="{
            perPage:pageLength,
            enabled: true,
            mode: 'pages',
            setCurrentPage: currentPage,
          }"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Job Id -->
            <span v-if="props.column.fieldType === 'cellRendererJobs'">
              <b-link
                class="count-circle"
                @click="showGroupJobIds(props.row)"
              >{{ props.row.count }}</b-link>
            </span>

            <!-- Column: Job Class -->
            <span v-if="props.column.fieldType === 'cellRendererJobClass'">
              <p
                v-b-tooltip.hover
                :title='props.row.skill_description'
                v-b-tooltip.hover.v-primary
              >{{ props.row.skill }}</p>
            </span>

            <!-- Column: Client -->
            <span v-if="props.column.fieldType === 'cellRendererClient'">
              <p :id="'contract_facility' + props.row.index">
                {{ props.row.facility }}
              </p>
              <b-tooltip
                :target="'contract_facility' + props.row.index"
                triggers="hover"
                no-fade
              >
                <span class="d-block text-left">{{props.row.facility}}</span>
                <span class="d-block text-left">{{props.row.location}}</span>
              </b-tooltip>
            </span>

            <!-- Column: Indeed Matched Prospects -->
            <span v-if="props.column.fieldType === 'cellRendererIndeedMatchedProspects'">
              <b-button
                variant="outline-primary"
                :to="{ name: 'human-resources-prospects-admin-all-prospects', query: { skill_id: props.row.skill_id, state_id: props.row.state_id, city: props.row.city, source_type: 'indeed' } }"
                class="px-50 py-25"
              >
                {{ props.row.indeed_city_matched_prospects }}
              </b-button>
              /
              <b-button
                variant="outline-primary"
                :to="{ name: 'human-resources-prospects-admin-all-prospects', query: { skill_id: props.row.skill_id, state_id: props.row.state_id, source_type: 'indeed' } }"
                class="px-50 py-25"
              >
                {{ props.row.indeed_matched_prospects }}
              </b-button>
            </span>

            <!-- Column: Matched Prospects -->
            <span v-if="props.column.fieldType === 'cellRendererMatchedProspects'">
              <b-button
                variant="outline-primary"
                :to="{ name: 'human-resources-prospects-admin-all-prospects', query: { skill_id: props.row.skill_id, state_id: props.row.state_id, city: props.row.city } }"
                class="px-50 py-25"
              >
              {{ props.row.city_matched_prospects }}
              </b-button>
              /
              <b-button
                variant="outline-primary"
                :to="{ name: 'human-resources-prospects-admin-all-prospects', query: { skill_id: props.row.skill_id, state_id: props.row.state_id } }"
                class="px-50 py-25"
              >
              {{ props.row.matched_prospects }}
              </b-button>
            </span>

            <!-- Column: Bill Rate Currency -->
            <span v-if="props.column.fieldType === 'cellRendererBillRateCurrency'">
              <span>{{ props.row.bill_rate_max| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
            </span>

            <!-- Column: Pay Rate Currency -->
            <span v-if="props.column.fieldType === 'cellRendererPayRateCurrency'">
              <span v-show="type !== 'permanent'" class="min-w-100">{{ props.row.pay_rate_max_max | currency('$ ', 2, { thousandsSeparator: ',' }) }} - {{ props.row.pay_rate_max_min | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
              <span v-show="type === 'permanent'" class="min-w-100">NA</span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              <span v-if="props.column.fieldType !== 'cellRendererMatchedProspects' && props.column.fieldType !== 'cellRendererIndeedMatchedProspects' && props.column.fieldType !== 'cellRendererJobClass' && props.column.fieldType !== 'cellRendererClient' && props.column.fieldType !== 'cellRendererJobs' && props.column.fieldType !== 'cellRendererBillRateCurrency'">
                {{ props.formattedRow[props.column.field] }}
              </span>
            </span>
          </template>

        </vue-good-table>
      </div>
  </b-card>
</template>

<script>
import {
  BCard, BLink, VBModal, VBTooltip, BTooltip, BButton, BRow, BCol,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Jobs from '@/js/jobs'

export default {
  name: 'JobRequests',
  components: {
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    BLink,
    BTooltip,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      searchQuery: '',
      dir: false,
      groupJobIds: {},
      city: null,
      state_id: null,
      skill_id: null,
      activeTab: 'contract',
      type: 'contract',
      sortingHistory: [],
      jobResponse: {
        jobs: [],
        city_skill: '',
        count: 1,
      },
      columns: [
        {
          label: 'Jobs',
          field: 'count',
          type: 'number',
          fieldType: 'cellRendererJobs',
          thClass: 'text-left',
          tdClass: 'text-center',
        },
        {
          label: 'Facility / Client',
          field: 'facility',
          fieldType: 'cellRendererClient',
          sortType: 'desc',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Facility / Client',
          },
        },
        {
          label: 'Job Class',
          field: 'skill',
          fieldType: 'cellRendererJobClass',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Job Class',
          },
        },
        {
          label: 'Location',
          field: 'location',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Location',
          },
        },
        {
          label: 'Bill Rate ($)',
          field: 'bill_rate_max',
          type: 'number',
          fieldType: 'cellRendererBillRateCurrency',
          sortable: true,
          filterOptions: {
            enabled: false,
            placeholder: 'Search Bill Rate',
          },
          thClass: 'thMwid75',
        },
        {
          label: 'Pay Rate ($)',
          field: 'pay_rate_max_max',
          sortable: true,
          type: 'number',
          fieldType: 'cellRendererPayRateCurrency',
          thClass: 'thMwid75',
        },
        {
          label: 'Duration',
          field: 'duration',
          type: 'number',
          width: '100px',
          sortable: false,
        },
        {
          label: 'Applied',
          field: 'applied_prospects',
          type: 'number',
          width: '90px',
        },
        {
          label: 'Indeed Matched Prospects City / State',
          field: 'indeed_matched_prospects',
          fieldType: 'cellRendererIndeedMatchedProspects',
          type: 'number',
          width: '160px',
          thClass: 'text-left',
          tdClass: 'text-center',
        },
        {
          label: 'Matched Prospects City / State',
          field: 'matched_prospects',
          type: 'number',
          fieldType: 'cellRendererMatchedProspects',
          sortType: 'number',
          width: '130px',
          thClass: 'text-left',
          tdClass: 'text-center',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.group_job_page
      },
      set(val) {
        this.$store.state.query.group_job_page = val
      },
    },
    pageLength: {
      get() {
        return this.$store.state.query.group_job_per_page
      },
      set(val) {
        this.$store.state.query.group_job_per_page = val
      },
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
  },
  created() {
    this.jobResponse.count = this.$store.state.query.group_job_page
    if (this.$route.query.skill_id !== null && this.$route.query.skill_id !== undefined) {
      this.$store.state.query.group_job_skill_id = this.$route.query.skill_id
    }
    if (this.$route.query.state_id !== null && this.$route.query.state_id !== undefined) {
      this.$store.state.query.group_job_state_id = this.$route.query.state_id
    }
    if (this.$route.query.city !== null && this.$route.query.city !== undefined) {
      this.$store.state.query.group_job_city = this.$route.query.city
    }
    if (this.$route.query.type !== null && this.$route.query.type !== undefined) {
      this.$store.state.query.group_job_Type = this.$route.query.type
    }
    this.type = this.$store.state.query.group_job_Type
    this.city = this.$store.state.query.group_job_city
    this.skill_id = Number(this.$store.state.query.group_job_skill_id) || null
    this.state_id = Number(this.$store.state.query.group_job_state_id) || null
    if (this.$store.state.groupJObs.isGroupJobsLoaded) {
      this.jobResponse = this.$store.state.groupJObs.groupJobs
    } else {
      Jobs.getJobsByGroupByCitySkill(this, this.type, this.city, this.state_id, this.skill_id)
    }
  },
  methods: {
    showGroupJobIds(props) {
      this.$router.push(`/human-resources/prospects-admin/group-jobs/jobs?skill_id=${props.skill_id}&type=${this.type}&city=${props.city}&state_id=${props.state_id}&facility_id=${props.facility_id}&bill_rate=${props.bill_rate_max}`).catch(() => {})
    },
    onPageChange(page) {
      this.currentPage = page.currentPage
      this.pageLength = page.currentPerPage
    },
    onPerPageChange(perPage) {
      this.currentPage = perPage.currentPage
      this.pageLength = perPage.currentPerPage
    },
    closeJobPopup() {
      this.groupJobIds = {}
    },
    updateSearchQuery() {
      this.$store.state.query.query = this.searchQuery
    },
    setJobs(jobResponse) {
      this.jobResponse = jobResponse
      this.$store.state.groupJObs.isGroupJobsLoaded = true
      this.$store.state.groupJObs.groupJobs = jobResponse
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
