import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.url
const parentDocument = `${HOST}parent-document`

export default {
  addDocumentType(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${parentDocument}/document-type`, body).then(response => {
      context.$vs.loading.close()
      context.$store.state.lists.commonLists.lists.document_types = response.data.document_types
      context.$store.state.lists.commonLists = { ...context.$store.state.lists.commonLists }
      context.documentTypeAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.documentTypeNotAdded(error.response.data.return_message)
    })
  },
  addDocumentName(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${parentDocument}/document-name`, body).then(response => {
      context.$store.state.lists.commonLists.document_names[body.document_type_id] = response.data.document_names
      context.$store.state.lists.commonLists = { ...context.$store.state.lists.commonLists }
      context.$vs.loading.close()
      context.documentNameAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.documentNameNotAdded(error.response.data.return_message)
    })
  },
  addIssuer(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${parentDocument}/issuer`, body).then(response => {
      context.$store.state.lists.commonLists.issuers[body.document_name_type_id] = response.data.issuers
      context.$store.state.lists.commonLists = { ...context.$store.state.lists.commonLists }
      context.$vs.loading.close()
      context.issuerAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.issuerNotAdded(error.response.data.return_message)
    })
  },
  getDocuments(context1) {
    const context = context1
    const params = {
      params: {
        query: context.searchQuery,
        page: context.currentPage - 1,
        parent_type_id: context.parent_type_id,
        parent_id: context.parent_id,
        type: context.type,
      },
    }
    axios.get(parentDocument, params).then(response => {
      context.setDocuments(response.data)
    }, () => {
    })
  },
  addDocument(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(parentDocument, body).then(response => {
      context.$vs.loading.close()
      context.documentAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.documentNotAdded(error.response.data.return_message)
    })
  },
  updateDocument(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${parentDocument}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.documentUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.documentNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${parentDocument}/${id}`).then(response => {
      context.$vs.loading.close()
      context.documentDeleted(response.data)
    }, error => {
      context.$vs.loading.close()
      context.documentNotDeleted(error.response.data.return_message)
    })
  },
  getDocumentById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${parentDocument}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setDocument(response.data)
    }, () => {
      context.$vs.loading.close()
      context.back()
    })
  },
  sendMail(context1, id, data) {
    const context = context1
    context.$vs.loading()
    axios.post(`${parentDocument}/${id}/send-email`, data).then(response => {
      context.$vs.loading.close()
      context.mailSent(response.data)
    }, error => {
      context.$vs.loading.close()
      context.mailNotSent(error.response.data.return_message)
    })
  },

}
