import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.faurl
const bank = `${HOST}bank`

export default {
  getBanks(context1) {
    const context = context1
    const params = { params: { query: context.$store.state.query.query, page: context.$store.state.query.page - 1 } }
    axios.get(bank, params).then(response => {
      context.setBanks(response.data)
    }, () => {
    })
  },
  addBank(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(bank, body).then(response => {
      context.$vs.loading.close()
      context.bankAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.bankNotAdded(error.response.data.return_message)
    })
  },
  updateBank(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${bank}/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.bankUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.bankNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${bank}/${id}`).then(response => {
      context.$vs.loading.close()
      context.bankDeleted(response.data)
    }, error => {
      context.$vs.loading.close()
      context.bankNotDeleted(error.response.data.return_message)
    })
  },
  getBankById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${bank}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setBank(response.data)
    }, error => {
      context.$vs.loading.close()
      context.bankNotFound(error.response.data.return_message)
    })
  },

}
