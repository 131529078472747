<template>
  <div>
    <b-row class="match-height">
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          icon="LayersIcon"
          statistic="1"
          statistic-title="Companies"
          color="info"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

export default {
  name: 'AppHome',
  components: {
    BRow,
    BCol,
    StatisticCardVertical,
  },
}
</script>

<style>

</style>
