import axios from 'axios'
import moment from 'moment'
import Constants from '@/js/constant'

const HOST = Constants.hosts.faurl
const bankTransaction = `${HOST}bank-transactions`

export default {
  getTransactions(context1, id) {
    const context = context1
    axios.get(`${bankTransaction}/${id}/transactions`).then(response => {
      context.setTrnsactions(response.data)
    }, error => {
      context.setNotTrnsactions(error.response.data.return_message)
    })
  },
  getBankTransactionById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${bankTransaction}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setBankTransaction(response.data)
    }, error => {
      context.$vs.loading.close()
      context.bankTransactionNotFound(error.response.data.return_message)
    })
  },
  getReconcileHistoriesBybankId(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${bankTransaction}/${id}/reconcile-history`).then(response => {
      context.$vs.loading.close()
      context.setReconcileHistories(response.data)
    }, error => {
      context.$vs.loading.close()
      context.reconcileHistoriesNotFound(error.response.data.return_message)
    })
  },
  getAllBankTransactionsByTransactionName(context1, id, dis) {
    const context = context1
    context.$vs.loading()
    const params = { params: {} }
    if (dis.start_date !== null) {
      params.params.start_date = moment(dis.start_date, 'MM-DD-yyyy').format('MM-DD-yyyy')
    }
    if (dis.end_date !== null) {
      params.params.end_date = moment(dis.end_date, 'MM-DD-yyyy').format('MM-DD-yyyy')
    }
    axios.get(`${bankTransaction}/transaction-name/${id}/all-bank-transactions`, params).then(response => {
      context.$vs.loading.close()
      context.setBankTransactions(response.data)
    }, error => {
      context.$vs.loading.close()
      context.bankTransactionsNotFound(error.response.data.return_message)
    })
  },
  getAllBankTransactionsByBankIdAndEndDate(context1, id) {
    const context = context1
    const params = {
      params: {
        query: context.searchQuery, page: context.$store.state.query.page - 1, cr_dr: context.cr_dr, status_id: context.status_id, qbo_id: context.all_qbo_category_id,
      },
    }
    if (context.start_date !== null) {
      params.params.start_date = moment(context.start_date, 'MM-DD-yyyy').format('MM-DD-yyyy')
    }
    if (context.end_date !== null) {
      params.params.end_date = moment(context.end_date, 'MM-DD-yyyy').format('MM-DD-yyyy')
    }
    axios.get(`${bankTransaction}/${id}/all-bank-transactions`, params).then(response => {
      context.setBankTransactions(response.data)
    }, error => {
      context.bankTransactionsNotFound(error.response.data.return_message)
    })
  },
  applyQboCategoryIdTobankTransaction(context1, id, qbcId, request) {
    const context = context1
    context.$vs.loading()
    axios.put(`${bankTransaction}/${id}/qbo/${qbcId}`, request).then(response => {
      context.$vs.loading.close()
      context.setQboCategoryIdUpdatedSuccessfully(response.data.return_message)
    }, error => {
      context.$vs.loading.close()
      context.setQboCategoryIdUpdatedFailure(error.response.data.return_message)
    })
  },
  removeQboIdFromBankTransaction(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${bankTransaction}/${id}/qbo`).then(response => {
      context.$vs.loading.close()
      context.setQboCategoryIdUpdatedSuccessfully(response.data.return_message)
    }, error => {
      context.$vs.loading.close()
      context.setQboCategoryIdUpdatedFailure(error.response.data.return_message)
    })
  },
  applyQboCategoryIdToAllbankTransaction(context1, qbcId, request) {
    const context = context1
    context.$vs.loading()
    axios.put(`${bankTransaction}/qbo/${qbcId}`, request).then(response => {
      context.$vs.loading.close()
      context.setQboCategoryIdUpdatedSuccessfully(response.data.return_message)
    }, error => {
      context.$vs.loading.close()
      context.setQboCategoryIdUpdatedFailure(error.response.data.return_message)
    })
  },
  splitTransactionById(context1, id, data) {
    const context = context1
    context.$vs.loading()
    axios.put(`${bankTransaction}/${id}/split`, data).then(response => {
      context.$vs.loading.close()
      context.setSplitedSuccessfully(response.data.return_message)
    }, error => {
      context.$vs.loading.close()
      context.setSplitedFailure(error.response.data.return_message)
    })
  },
  mergeTransactionById(context1, data) {
    const context = context1
    context.$vs.loading()
    axios.put(`${bankTransaction}/merge`, data).then(response => {
      context.$vs.loading.close()
      context.setMergedSuccessfully(response.data.return_message)
    }, error => {
      context.$vs.loading.close()
      context.setMergedFailure(error.response.data.return_message)
    })
  },
  getBankTransactionsByBankIdAndEndDate(context1, id, startDate, endDate) {
    const context = context1
    context.$vs.loading()
    const params = { params: { start_date: moment(startDate, 'MM-DD-yyyy').format('MM-DD-yyyy'), end_date: moment(endDate, 'MM-DD-yyyy').format('MM-DD-yyyy') } }
    axios.get(`${bankTransaction}/${id}/bank-transactions`, params).then(response => {
      context.$vs.loading.close()
      context.setBankTransactions(response.data)
    }, error => {
      context.$vs.loading.close()
      context.bankTransactionsNotFound(error.response.data.return_message)
    })
  },
  getPaymentDetailsBYEndDate(context1, startDate, endDate, bankId, registerPaymentTypeId) {
    const context = context1
    const params = { params: { start_date: moment(startDate, 'MM-DD-yyyy').format('MM-DD-yyyy'), end_date: moment(endDate, 'MM-DD-yyyy').format('MM-DD-yyyy'), register_payment_type_id: registerPaymentTypeId } }
    axios.get(`${bankTransaction}/${bankId}/payment-details`, params).then(response => {
      context.setPaymentDetails(response.data)
    }, error => {
      context.paymentDetailsNotFound(error.response.data.return_message)
    })
  },
  updateReconcileByBankId(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.post(`${bankTransaction}/${id}/reconcile`, body).then(response => {
      context.$vs.loading.close()
      context.setReconcoleCreated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.setReconcoleNotCreated(error.response.data.return_message)
    })
  },
}
