<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          View Template</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>

    <app-timeline>
      <app-timeline-item class="pb-1">
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <label>Template Type</label>
              <span
                class="form-control text-left"
                v-text="template.template_type"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <label>Content</label>
              <span
                class="form-control h-auto height-auto quill-view"
                v-html="template.content"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BButton,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import EsignatureTemplate from '@/js/esignaturetemplate'

export default {
  name: 'E-SignatureTemplateView',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BFormGroup,
    BButton,
  },
  directives: {
  },
  setup() {
  },
  data() {
    return {
      template: {},
    }
  },
  mounted() {
  },
  created() {
    EsignatureTemplate.getTemplateById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    setTemplate(template) {
      this.template = template
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
