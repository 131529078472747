<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Edit Role</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <b-row class="mt-1">
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Role</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Role"
                    rules="required"
                  >
                    <b-form-input
                      id="role"
                      v-model="addRole.role"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Report to Role</label>
                  <span
                    class="form-control h-auto"
                    v-text="addRole.reporting_user_type"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <div class="demo-inline-spacing">
                  <b-form-checkbox
                    id="is_assign_jobs"
                    v-model="addRole.is_assign_jobs"
                  >
                    Assign Jobs
                  </b-form-checkbox>
                </div>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Reporting To Admin</label>
                    <span
                      class="form-control"
                      v-text="addRole.is_reporting_admin"
                    />
              </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Select Modules & Sub - Modules</h6>
            </div>
            <b-row
              v-for="(departmentDevision, index) in Object.keys(addRole.divisions)"
              :key="index"
              :value="index"
              class="mt-1"
            >
              <b-col cols="12">
                <b-form-checkbox
                  v-model="addRole.divisions[departmentDevision].selected"
                  class="custom-control-primary mb-1"
                  @input="departmentSelected(departmentDevision)"
                >
                  {{ departmentDevision }}
                </b-form-checkbox>
              </b-col>
              <b-col
                cols="12"
                class="pl-5"
              >
                <b-row v-show="addRole.divisions[departmentDevision].selected">
                  <b-col
                    v-for="(devision, devisionIndex) in addRole.divisions[departmentDevision]"
                    :key="devisionIndex"
                    :value="devisionIndex"
                    cols="3"
                  >
                    <b-form-checkbox
                      v-model="devision.selected"
                      class="custom-control-primary mb-1"
                      @input="divisionselected(departmentDevision, devisionIndex, devision.selected)"
                    >
                      {{ devision.name }}
                    </b-form-checkbox>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="ml-2">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Update Role
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormCheckbox, BButton,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import {
  required,
} from '@validations'
import Roles from '@/js/roles'

export default {
  name: 'RoleEdit',
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
  },
  directives: {
  },
  setup() {
  },
  data() {
    return {
      required,
      addRole: {
        name: '',
        reporting_user_type_id: null,
        is_assign_jobs: false,
        is_reporting_admin: false,
        divisions: {},
      },
      reportRoles: [],
    }
  },
  watch: {
  },
  mounted() {
  },
  created() {
    Roles.getRoleById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          this.showLoading = true
          Roles.updateRole(this, this.$route.params.id, this.addRole)
        }
      })
    },
    reportingRoleSelected() {
      this.addRole.is_reporting_admin = false
    },
    setAllRoles(departmentdivisions) {
      this.addRole.divisions = departmentdivisions.divisions
      if (departmentdivisions.divisions === null || departmentdivisions.divisions === undefined) {
        this.addRole.divisions = {}
      }
      Object.keys(this.addRole.divisions).forEach(key => {
        Object.keys(this.addRole.divisions[key]).forEach(devision => {
          this.addRole.divisions[key][devision].selected = false
        })
      })
    },
    divisionselected(departmentDevision, devision, selected) {
      if (selected) {
        this.addRole.divisions[departmentDevision][devision].selected = true
      } else {
        this.addRole.divisions[departmentDevision][devision].selected = false
        this.addRole.divisions[departmentDevision].selected = false
        Object.keys(this.addRole.divisions[departmentDevision]).forEach(key => {
          if (key !== 'selected') {
            if (this.addRole.divisions[departmentDevision][key].selected) {
              this.addRole.divisions[departmentDevision].selected = true
            }
          }
        })
      }
    },
    departmentSelected(departmentDevision) {
      Object.keys(this.addRole.divisions[departmentDevision]).forEach(devision => {
        if (devision !== 'selected') {
          if (this.addRole.divisions[departmentDevision].selected) {
            this.addRole.divisions[departmentDevision][devision].selected = true
          } else {
            this.addRole.divisions[departmentDevision][devision].selected = false
          }
        }
      })
    },
    setReportingRoles(reportRoles) {
      this.reportRoles = reportRoles
    },
    setRole(data) {
      this.addRole = data
      Object.keys(this.addRole.divisions).forEach(key => {
        Object.keys(this.addRole.divisions[key]).forEach(devision => {
          if (this.addRole.divisions[key][devision].selected === true) {
            this.addRole.divisions[key].selected = true
          }
        })
      })
    },
    roleUpdated(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    roleNotUpdated(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    back() {
      this.$router.push('/administration/roles').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
