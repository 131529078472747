<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form class="p-2">
        <validation-provider
          v-slot="{ errors }"
          name="Parent Types"
          rules="required"
        >
          <b-form-group
            label="Parent Types"
            label-for="field-type"
          >
            <v-select
              v-model="addField.parent_type_ids"
              :options="parentTypes"
              multiple
              :reduce="item => item.id"
              placeholder="Select"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider
          v-slot="{ errors }"
          name="Field Name"
          rules="required"
        >
          <b-form-group
            label="Field Name"
            label-for="field-name"
          >
            <b-form-input
              v-model="addField.name"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider
          v-slot="{ errors }"
          name="Field Type"
          rules="required"
        >
          <b-form-group
            label="Field Type"
            label-for="field-type"
          >
            <v-select
              v-model="addField.type_id"
              :options="types"
              :reduce="item => item.id"
              placeholder="Select"
              @input="typeChanged"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <div
          v-for="(item, index) in addField.options"
          :id="item.id"
          :key="item.id"
          class="d-flex "
        >

          <!-- Item Name -->
          <div class="w-100">
            <b-form-group>
              <validation-provider
                v-slot="{ errors }"
                :name="'Option' + (index + 1)"
                rules="required"
              >
                <b-form-input
                  v-model="item.option"
                  type="text"
                  :placeholder="'Option' + (index + 1)"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div>
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 ml-1"
              @click="removeItem(index)"
            >
              <feather-icon
                icon="XIcon"
              />
            </b-button>
          </div>
        </div>
        <b-button
          v-show="typeName.toLowerCase() === 'dropdown'"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          class="mb-2"
          @click="repeateAgain"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>Add Option</span>
        </b-button>
        <b-form-group
          label="Description"
          label-for="task-description"
        >
          <b-form-textarea
            v-model="addField.description"
          />
        </b-form-group>

        <b-form-group>
          <b-form-checkbox
            v-model="addField.required"
            value="true"
          >
            Required
          </b-form-checkbox>
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="button"
            @click="submitForm"
          >
            Add Field
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BForm, BFormGroup, BFormInput, BFormCheckbox, BFormTextarea, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import LIST from '@/js/lists'
import UserDefinedFields from '@/js/userDefinedFields'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BButton,

    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      addField: {
        name: '',
        parent_type_ids: [],
        type_id: null,
        value_id: null,
        description: '',
        required: false,
        options: [],
      },
      types: [],
      parentTypes: [],
      typeName: '',
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getUserDefinedFieldTypes(this)
        LIST.getParentTypes(this)
      },
    },
  },
  created() {
    LIST.getUserDefinedFieldTypes(this)
    LIST.getParentTypes(this)
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          UserDefinedFields.addUdf(this, this.addField)
        }
      })
    },
    typeChanged() {
      const selectedType = this.types.find(type => type.id === this.addField.type_id)
      if (selectedType === undefined) {
        this.addField.options = []
        return
      }
      this.typeName = selectedType.label
      if (this.typeName.toLowerCase() !== 'dropdown') {
        this.addField.options = []
      }
    },
    repeateAgain() {
      this.addField.options.push({
        id: null, option: '',
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    setParentTypes(parentTypes) {
      this.parentTypes = parentTypes
    },
    setUserDefinedFieldTypes(types) {
      this.types = types
    },
    udfAdded(data) {
      this.addField = {
        name: '',
        parent_type_ids: [],
        type_id: null,
        value_id: null,
        description: '',
        required: false,
        options: [],
      }
      this.$parent.hide()
      UserDefinedFields.getUdfs(this.$parent.$parent)
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    udfNotAdded(message) {
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    removeItem(index) {
      this.addField.options.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
