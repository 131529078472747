<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          View Daily Update</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>

    <app-timeline>
      <app-timeline-item class="pb-1">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Update Details</h6>
        </div>
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <label>Description</label>
              <span
                class="form-control height-auto quill-view"
                v-html="dailyUpdate.description"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="dailyUpdate.documents!==null">
          <b-col cols="12">
            <b-form-group>
              <label>Attachments</label>
              <div class="w-100">
                <b-link
                  v-for="option in dailyUpdate.documents"
                  :key="option.id"
                  :value="option.id"
                  :href="option.document_location"
                  :title="option.file_name"
                  target="_blank"
                  :class="{'mt-75': option}"
                >
                  <b-badge
                    variant="primary"
                    class="mr-1 my-25"
                  >
                    <span>{{ option.file_name }}</span>
                  </b-badge>
                </b-link>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <div
          v-if="dailyUpdate.documents.length == 0"
          class="text-center"
        >
          <p class="mb-0">
            No attachments available
          </p>
        </div>
      </app-timeline-item>

      <app-timeline-item
        variant="success"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Comments</h6>
        </div>
        <b-row>
          <b-col
            v-for="comment in dailyUpdate.comments"
            :key="comment"
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <span class="d-block"><small>Commented on {{ comment.date_text }} {{ comment.time }}</small></span>
              <b-form-textarea
                v-model="comment.comment"
                rows="2"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div
          v-if="dailyUpdate.comments.length == 0"
          class="text-center"
        >
          <p class="mb-0">
            No Comments available
          </p>
        </div>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import DailyUpdate from '@/js/dailyupdate'

import {
  BCard, BRow, BCol, BButton, BFormGroup, BFormTextarea, BBadge, BLink,
} from 'bootstrap-vue'

export default {
  name: 'DailyUpdateView',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormTextarea,
    BBadge,
    BLink,
  },
  directives: {
  },
  setup() {
  },
  data() {
    return {
      dailyUpdate: {},
    }
  },
  mounted() {
  },
  created() {
    DailyUpdate.getDailyUpdateById(this, this.$route.params.id)
  },
  methods: {
    setDailyUpdate(dailyUpdate) {
      this.dailyUpdate = dailyUpdate
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>
