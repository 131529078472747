var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('h4',{staticClass:"card-title mb-0 pt-50"},[_vm._v(" Client Letter")])]),_c('b-col',{attrs:{"cols":"12","md":"6 ml-auto"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.back}},[_vm._v(" Back ")])],1)])],1),_c('hr'),_c('validation-observer',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showTemplate),expression:"!showTemplate"}],ref:"simpleRules"},[_c('b-form',{staticClass:"ex-large"},[_c('app-timeline',[_c('app-timeline-item',{staticClass:"pb-1"},[_c('div',{staticClass:"d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"},[_c('h6',[_vm._v("Client Details")])]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Client Company Name")]),_c('validation-provider',{attrs:{"name":"Client Company Name","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"client_company_name","state":errors.length > 0 ? false:null},model:{value:(_vm.addClientLetter.client_company_name),callback:function ($$v) {_vm.$set(_vm.addClientLetter, "client_company_name", $$v)},expression:"addClientLetter.client_company_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Client Company Address")]),_c('validation-provider',{attrs:{"name":"Client Company Address","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"client_company_address","state":errors.length > 0 ? false:null},model:{value:(_vm.addClientLetter.client_company_address),callback:function ($$v) {_vm.$set(_vm.addClientLetter, "client_company_address", $$v)},expression:"addClientLetter.client_company_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Client Logo")]),_c('validation-provider',{attrs:{"name":"Client Logo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{ref:"myFileInput",staticClass:"form-control text-left",attrs:{"type":"file"},on:{"change":_vm.attahamentFileSelected}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Client Name")]),_c('validation-provider',{attrs:{"name":"Client Name","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"client_name","state":errors.length > 0 ? false:null},model:{value:(_vm.addClientLetter.client_name),callback:function ($$v) {_vm.$set(_vm.addClientLetter, "client_name", $$v)},expression:"addClientLetter.client_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Client Title")]),_c('validation-provider',{attrs:{"name":"Client Title","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"client_title","state":errors.length > 0 ? false:null},model:{value:(_vm.addClientLetter.client_title),callback:function ($$v) {_vm.$set(_vm.addClientLetter, "client_title", $$v)},expression:"addClientLetter.client_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Client Email")]),_c('validation-provider',{attrs:{"name":"Client Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"client_address","state":errors.length > 0 ? false:null},model:{value:(_vm.addClientLetter.client_email),callback:function ($$v) {_vm.$set(_vm.addClientLetter, "client_email", $$v)},expression:"addClientLetter.client_email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Client Phone")]),_c('validation-provider',{attrs:{"name":"Client Phone","rules":"required|integer|digits:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"client_address","state":errors.length > 0 ? false:null},model:{value:(_vm.addClientLetter.client_phone),callback:function ($$v) {_vm.$set(_vm.addClientLetter, "client_phone", $$v)},expression:"addClientLetter.client_phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('app-timeline-item',{staticClass:"pb-1",attrs:{"variant":"info"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Title")]),_c('validation-provider',{attrs:{"name":"Title","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","state":errors.length > 0 ? false:null},model:{value:(_vm.addClientLetter.title),callback:function ($$v) {_vm.$set(_vm.addClientLetter, "title", $$v)},expression:"addClientLetter.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("First Name")]),_c('validation-provider',{attrs:{"name":"First Name","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first_name","state":errors.length > 0 ? false:null},model:{value:(_vm.addClientLetter.first_name),callback:function ($$v) {_vm.$set(_vm.addClientLetter, "first_name", $$v)},expression:"addClientLetter.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Last Name")]),_c('validation-provider',{attrs:{"name":"Last Name","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last_name","state":errors.length > 0 ? false:null},model:{value:(_vm.addClientLetter.last_name),callback:function ($$v) {_vm.$set(_vm.addClientLetter, "last_name", $$v)},expression:"addClientLetter.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Job Title")]),_c('validation-provider',{attrs:{"name":"Job Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"job_title","state":errors.length > 0 ? false:null},model:{value:(_vm.addClientLetter.job_title),callback:function ($$v) {_vm.$set(_vm.addClientLetter, "job_title", $$v)},expression:"addClientLetter.job_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Project")]),_c('validation-provider',{attrs:{"name":"Project","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"project","state":errors.length > 0 ? false:null},model:{value:(_vm.addClientLetter.project),callback:function ($$v) {_vm.$set(_vm.addClientLetter, "project", $$v)},expression:"addClientLetter.project"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Hours/Week")]),_c('validation-provider',{attrs:{"name":"Hours/Week","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"hours","state":errors.length > 0 ? false:null},model:{value:(_vm.addClientLetter.hours),callback:function ($$v) {_vm.$set(_vm.addClientLetter, "hours", $$v)},expression:"addClientLetter.hours"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("End Date")]),_c('validation-provider',{attrs:{"name":"End Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.$store.state.config,"placeholder":"MM-DD-YYYY"},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Resposibilities")]),_c('validation-provider',{attrs:{"name":"Resposibilities","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{attrs:{"options":_vm.snowOption},model:{value:(_vm.addClientLetter.responsibilities),callback:function ($$v) {_vm.$set(_vm.addClientLetter, "responsibilities", $$v)},expression:"addClientLetter.responsibilities"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('hr'),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{staticClass:"text-right"},[_c('b-button',{staticClass:"mx-2",attrs:{"variant":"primary","type":"button"},on:{"click":_vm.generatePdf}},[_vm._v(" Generate Pdf ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }