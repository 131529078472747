import store from '@/store'

export default [
  {
    title: 'Financial Statements',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'Dashboard',
        route: 'financial-statements-dashboard',
      },
      {
        title: 'Profit Rate Per Resource',
        route: 'financial-statements-profit-rate-per-resource',
      },
      {
        title: 'Profit Amounts Per Resource',
        route: 'financial-statements-profit-amounts-per-resource',
      },
      {
        title: 'Statements',
        route: 'financial-statements-statements',
      },
      {
        title: 'Alerts',
        tag: store.state.alerts,
        tagVariant: 'light-danger',
        route: 'financial-statements-alerts',
      },
      {
        title: 'Reports',
        route: 'financial-statements-reports',
      },
    ],
  },
]
