import { render, staticRenderFns } from "./VerificationLetterView.vue?vue&type=template&id=d57a53a2&"
import script from "./VerificationLetterView.vue?vue&type=script&lang=js&"
export * from "./VerificationLetterView.vue?vue&type=script&lang=js&"
import style0 from "./VerificationLetterView.vue?vue&type=style&index=0&id=d57a53a2&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports