import Constants from '@/js/constant'
import store from '@/store/store'
import axios from 'axios'

const HOST = Constants.hosts.url
const ROLE_LIST = `${HOST}dashboard/roles`

export default {
  getProfile(context1, data) {
    const context = context1
    context.$vs.loading()
    context.$http.get(ROLE_LIST, { headers: { Authorization: `Bearer ${data.data.access_token}` } }).then(response => {
      context.$vs.loading.close()
      window.localStorage.setItem('access_token', data.data.access_token)
      store.commit('access_token', data.data.access_token)
      window.localStorage.setItem('user_roles', Constants.convertObjectToString(response.data))
      store.commit('USER_ROLES', response.data)
      store.commit('UPDATE_USER_INFO', {
        support_token: response.data.support_token, displayName: response.data.name, email: response.data.email, access_token: data.data.access_token, photoURL: response.data.profile_pic, signature: response.data.signature,
      })
      axios.defaults.headers.common.Authorization = `Bearer ${data.data.access_token}`
      if (response.data.main_administrator) {
        window.location.hash = '/companies'
      } else {
        window.location.hash = '/'
      }
    }, error => {
      context.$vs.loading.close()
      context.$swal({
        title: 'Login',
        text: error.response.data.return_message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    })
  },
  getProfileFromToken(context1, token) {
    const context = context1
    context.$vs.loading()
    context.$http.get(ROLE_LIST, { headers: { Authorization: `Bearer ${token}` } }).then(response => {
      context.$vs.loading.close()
      window.localStorage.setItem('access_token', token)
      store.commit('access_token', token)
      window.localStorage.setItem('user_roles', Constants.convertObjectToString(response.data))
      store.commit('UPDATE_USER_INFO', {
        support_token: response.data.support_token, displayName: response.data.name, email: response.data.email, access_token: token, photoURL: null, signature: response.data.signature,
      })
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
      if (response.data.main_administrator) {
        window.location.hash = '/companies'
      } else {
        window.location.hash = '/'
      }
      context.$router.go(0)
    }, () => {
      context.$vs.loading.close()
    })
  },
  profile() {
    const profile = JSON.parse(localStorage.getItem('profile'))
    return profile
  },
  getCounts() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile
  },
  isMainAdmin() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    if (profile === null) {
      return false
    }
    return profile.main_administrator
  },
  isAdmin() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.administrator
  },
  isHrDeptAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.hr_department_available || this.isAdmin()
  },
  isFADeptAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.fa_department_available || this.isAdmin()
  },
  isForecastingDeptAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.forecasting_department_available || this.isAdmin()
  },
  isAdministrationDeptAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.administration_department_available || this.isAdmin()
  },
  isTasksDeptAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.tasks_department_available || this.isAdmin()
  },
  isEmployeesAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.employees_available || this.isAdmin()
  },
  isConsultantAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.consultants_available || this.isAdmin()
  },
  isProspectsAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.prospects_available || this.isAdmin()
  },
  isEmployersAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.employers_available || this.isAdmin()
  },
  isFacilitiesAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.facilities_available || this.isAdmin()
  },
  isSubFacilitiesAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.subfacilities_available || this.isAdmin()
  },
  isEndClientsAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.endclients_available || this.isAdmin()
  },
  isSuppliersAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.suppliers_available || this.isAdmin()
  },
  isReferrerAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.referrer_available || this.isAdmin()
  },
  isOnboardingAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.onboarding_available || this.isAdmin()
  },
  isInterviewsAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.interviews_available || this.isAdmin()
  },
  isJobsAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.jobs_available || this.isAdmin()
  },
  isTimesheetsAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.timeshees_available || this.isAdmin()
  },
  isFacilityInvoicesAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.facility_invoices_available || this.isAdmin()
  },
  isEmployerInvoicesAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.employer_invoices_available || this.isAdmin()
  },
  isSubfacilityInvoicesAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.subfacility_invoices_available || this.isAdmin()
  },
  isSupplierInvoicesAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.supplier_invoices_available || this.isAdmin()
  },
  isReferrerInvoicesAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.referrer_invoices_available || this.isAdmin()
  },
  isForecastingAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.forecasting_available || this.isAdmin()
  },
  isReportsAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.reports_available || this.isAdmin()
  },
  isReportingConfigurationAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.reporting_configuration_available || this.isAdmin()
  },
  isCommunicationConfigurationAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.communication_configuration_available || this.isAdmin()
  },
  isMassCommunicationAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.mass_communication_available || this.isAdmin()
  },
  isInvoiceInvitationAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.invoice_invitation_available || this.isAdmin()
  },
  isJobInvitationAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.job_invitation_available || this.isAdmin()
  },
  isTimesheetInvitationAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.timesheet_invitation_available || this.isAdmin()
  },
  isMonthlyProjectionsReportAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.monthly_projections_report_available || this.isAdmin()
  },
  isMonthlyIncomeProjectionsReportAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.monthly_income_projections_report_available || this.isAdmin()
  },
  isMonthlyExpensesProjectionsReportAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.monthly_expenses_projections_report_available || this.isAdmin()
  },
  isActualMonthlyIncomeReportAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.actual_monthly_income_report_available || this.isAdmin()
  },
  isActualMonthlyExpensesReportAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.actual_monthly_expenses_report_available || this.isAdmin()
  },
  isActualMonthlyReportAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.actual_monthly_report_available || this.isAdmin()
  },
  isConsultantsByEmployerAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.consultants_employer_availabl
  },
  isConsultantsBySubFacilityAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.consultants_subFacility_available
  },
  isConsultantsBySupplierAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.consultants_supplier_available
  },
  isConsultantsByReferrerAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.consultants_referrer_available
  },
  isConsultantsByFacilityAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.consultants_facility_available
  },
  isMissingTimesheetsReportAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.missing_timesheets_report_available
  },
  isMissingInvoicesReportAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.missing_invoices_report_available
  },
  isInvoicePaymentDueDateReportAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.invoice_payment_due_date_report_available
  },
  isExpensesAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.expenses_available
  },
  isDocumentAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.documents_available
  },
  isBatchProcessingAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.batch_processing_available
  },
  isExceptionsAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.exception_available
  },
  isSkillAndTechnologyAvailable() {
    const profile = JSON.parse(localStorage.getItem('user_roles'))
    return profile.skill_technology_available
  },
}
