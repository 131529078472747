import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.faurl
const invoiceAlerts = `${HOST}invoice-alert`

export default {
  getInvoiceAlerts(context1, type) {
    const context = context1
    const params = { params: { type, page: context.$store.state.query.page - 1 } }
    axios.get(invoiceAlerts, params).then(response => {
      context.setInvoiceAlerts(response.data)
    }, () => {
    })
  },
  ignoreAlert(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${invoiceAlerts}/${id}/ignore`).then(response => {
      context.$vs.loading.close()
      context.alertIgnored(response.data)
    }, error => {
      context.$vs.loading.close()
      context.alertNotIgnored(error.response.data.return_message)
    })
  },
}
