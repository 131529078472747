<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Add Timesheet</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <b-row class="mt-1">
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Name of Employee Consultant</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Employee Consultant"
                    rules="required"
                  >
                    <v-select
                      id="user_id"
                      v-model="addTimesheet.user_id"
                      :options="employees"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Timesheet Frequency</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Timesheet Frequency"
                    rules="required"
                  >
                    <v-select
                      id="timesheet_frequency_id"
                      v-model="addTimesheet.timesheet_frequency_id"
                      :options="frequencies"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                      @input="onFrequencyChange()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Month</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Select Month/Year"
                    rules="required"
                  >
                    <flat-pickr
                      id="month"
                      v-model="month"
                      class="form-control"
                      :config="$store.state.maxMonthConfig"
                      placeholder="MMM-yyyy"
                      @on-change="monthChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-show="isShowWeeks"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Select Week</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Week"
                    :rules="isShowWeeks ? 'required' : ''"
                  >
                    <v-select
                      id="week_id"
                      v-model="week_id"
                      :options="showWeeks"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                      @input="getSaysBasedOnWeek($event)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-show="isShowDays && isShowWeeks">
              <b-col cols="12">
                <b-form-group>
                  <label>Day's</label>
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered table-td-pd mb-0">
                      <thead>
                        <tr>
                          <th>Sun {{ sun_day }}</th>
                          <th>Mon {{ mon_day }}</th>
                          <th>Tue {{ tue_day }}</th>
                          <th>Wed {{ wed_day }}</th>
                          <th>Thu {{ thu_day }}</th>
                          <th>Fri {{ fri_day }}</th>
                          <th>Sat {{ sat_day }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div class="">
                              <validation-provider
                                v-slot="{ errors }"
                                name="Week"
                                :rules="isShowWeeks ? 'required' : ''"
                              >
                                <b-form-spinbutton
                                  id="sunday_hours"
                                  v-model="addTimesheet.sunday.hours"
                                  :min="0"
                                  :max="24"
                                  :step="0.5"
                                  @input="hoursChanged()"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </div>
                          </td>
                          <td>
                            <div class="">
                              <validation-provider
                                v-slot="{ errors }"
                                name="Week"
                                :rules="isShowWeeks ? 'required' : ''"
                              >
                                <b-form-spinbutton
                                  id="monday_hours"
                                  v-model="addTimesheet.monday.hours"
                                  :min="0"
                                  :max="24"
                                  :step="0.5"
                                  @input="hoursChanged()"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </div>
                          </td>
                          <td>
                            <div class="">
                              <validation-provider
                                v-slot="{ errors }"
                                name="Week"
                                :rules="isShowWeeks ? 'required' : ''"
                              >
                                <b-form-spinbutton
                                  id="tueday_hours"
                                  v-model="addTimesheet.tueday.hours"
                                  :min="0"
                                  :max="24"
                                  :step="0.5"
                                  @input="hoursChanged()"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </div>
                          </td>
                          <td>
                            <div class="">
                              <validation-provider
                                v-slot="{ errors }"
                                name="Week"
                                :rules="isShowWeeks ? 'required' : ''"
                              >
                                <b-form-spinbutton
                                  id="wedday_hours"
                                  v-model="addTimesheet.wedday.hours"
                                  :min="0"
                                  :max="24"
                                  :step="0.5"
                                  @input="hoursChanged()"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </div>
                          </td>
                          <td>
                            <div class="">
                              <validation-provider
                                v-slot="{ errors }"
                                name="Week"
                                :rules="isShowWeeks ? 'required' : ''"
                              >
                                <b-form-spinbutton
                                  id="thusday_hours"
                                  v-model="addTimesheet.thusday.hours"
                                  :min="0"
                                  :max="24"
                                  :step="0.5"
                                  @input="hoursChanged()"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </div>
                          </td>
                          <td>
                            <div class="">
                              <validation-provider
                                v-slot="{ errors }"
                                name="Week"
                                :rules="isShowWeeks ? 'required' : ''"
                              >
                                <b-form-spinbutton
                                  id="friday_hours"
                                  v-model="addTimesheet.friday.hours"
                                  :min="0"
                                  :max="24"
                                  :step="0.5"
                                  @input="hoursChanged()"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </div>
                          </td>
                          <td>
                            <div class="">
                              <validation-provider
                                v-slot="{ errors }"
                                name="Week"
                                :rules="isShowWeeks ? 'required' : ''"
                              >
                                <b-form-spinbutton
                                  id="satday_hours"
                                  v-model="addTimesheet.satday.hours"
                                  :min="0"
                                  :max="24"
                                  :step="0.5"
                                  @input="hoursChanged()"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- <b-row v-show="isShowDays && isShowWeeks">
              <b-col cols="12">
                <b-form-group
                  label="Days"
                  label-cols-md="2"
                  class="text-right"
                >
                  <b-row class="week-days">
                    <b-col cols="1">
                      <div class="border text-center">
                        Sun {{ sun_day }}
                      </div>
                      <div class="">
                        <validation-provider
                          #default="{ errors }"
                          name="Week"
                          :rules="isShowWeeks ? 'required' : ''"
                        >
                          <b-form-spinbutton
                            v-model="addTimesheet.sunday.hours"
                            :min="0"
                            :max="24"
                            :step="0.5"
                            @input="hoursChanged()"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </b-col>
                    <b-col cols="1">
                      <div class="border text-center">
                        Mon {{ mon_day }}
                      </div>
                      <div class="">
                        <validation-provider
                          #default="{ errors }"
                          name="Week"
                          :rules="isShowWeeks ? 'required' : ''"
                        >
                          <b-form-spinbutton
                            v-model="addTimesheet.monday.hours"
                            :min="0"
                            :max="24"
                            :step="0.5"
                            @input="hoursChanged()"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </b-col>
                    <b-col cols="1">
                      <div class="border text-center">
                        Tue {{ tue_day }}
                      </div>
                      <div class="">
                        <validation-provider
                          #default="{ errors }"
                          name="Week"
                          :rules="isShowWeeks ? 'required' : ''"
                        >
                          <b-form-spinbutton
                            v-model="addTimesheet.tueday.hours"
                            :min="0"
                            :max="24"
                            :step="0.5"
                            @input="hoursChanged()"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </b-col>
                    <b-col cols="1">
                      <div class="border text-center">
                        Wed {{ wed_day }}
                      </div>
                      <div class="">
                        <validation-provider
                          #default="{ errors }"
                          name="Week"
                          :rules="isShowWeeks ? 'required' : ''"
                        >
                          <b-form-spinbutton
                            v-model="addTimesheet.wedday.hours"
                            :min="0"
                            :max="24"
                            :step="0.5"
                            @input="hoursChanged()"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </b-col>
                    <b-col cols="1">
                      <div class="border text-center">
                        Thu {{ thu_day }}
                      </div>
                      <div class="">
                        <validation-provider
                          #default="{ errors }"
                          name="Week"
                          :rules="isShowWeeks ? 'required' : ''"
                        >
                          <b-form-spinbutton
                            v-model="addTimesheet.thusday.hours"
                            :min="0"
                            :max="24"
                            :step="0.5"
                            @input="hoursChanged()"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </b-col>
                    <b-col cols="1">
                      <div class="border text-center">
                        Fri {{ fri_day }}
                      </div>
                      <div class="">
                        <validation-provider
                          #default="{ errors }"
                          name="Week"
                          :rules="isShowWeeks ? 'required' : ''"
                        >
                          <b-form-spinbutton
                            v-model="addTimesheet.friday.hours"
                            :min="0"
                            :max="24"
                            :step="0.5"
                            @input="hoursChanged()"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </b-col>
                    <b-col cols="1">
                      <div class="border text-center">
                        Sat {{ sat_day }}
                      </div>
                      <div class="">
                        <validation-provider
                          #default="{ errors }"
                          name="Week"
                          :rules="isShowWeeks ? 'required' : ''"
                        >
                          <b-form-spinbutton
                            v-model="addTimesheet.satday.hours"
                            :min="0"
                            :max="24"
                            :step="0.5"
                            @input="hoursChanged()"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row> -->
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Total Hours</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Total Hours"
                    rules="required"
                  >
                    <b-form-input
                      id="total_hours"
                      v-model="addTimesheet.total_hours"
                      type="number"
                      :disabled="!isShowWeeks ? disabled : ''"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Expenses</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Invoice Amount"
                    rules="required"
                  >
                    <b-input-group
                      prepend="$"
                    >
                      <money
                        id="expenses"
                        v-model="addTimesheet.expenses"
                        :min="0"
                        :step="0.5"
                        v-bind="money"
                        class="form-control"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Status</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Status"
                    rules="required"
                  >
                    <v-select
                      id="status_id"
                      v-model="addTimesheet.status_id"
                      :options="status"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Documents</h6>
            </div>
            <b-row class="mt-1">
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Type</label>
                  <v-select
                    id="documentNameId"
                    v-model="documentNameId"
                    :clearable="true"
                    placeholder="Select"
                    :options="documentNames"
                    :reduce="item => item.id"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Attachment</label>
                  <div class="d-flex">
                    <input
                      ref="myFileInput"
                      type="file"
                      placeholder="Choose a file or drop it here..."
                      class="form-control text-left"
                      @change="attahamentFileSelected"
                    >
                    <b-button
                      variant="outline-primary"
                      class="ml-1 px-1"
                      @click="addFile"
                    >
                      Upload
                    </b-button>
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button-group
                  v-for="(option, index) in addTimesheet.documents"
                  :key="option.id"
                  :value="option.id"
                  class="mr-1 mb-50"
                >
                  <b-badge
                    :href="option.attachment_link"
                    :title="option.name"
                    target="option.attachment_link"
                    variant="light-primary"
                    class="rounded-right-0 line-height-inherit"
                  >
                    <span>{{ option.name }}</span>
                  </b-badge>
                  <b-button
                    variant="danger"
                    class="px-50 py-25"
                    @click="removeFile(option, index)"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="success"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Comments</h6>
            </div>
            <b-row class="mt-1">
              <b-col
                v-for="(comment, index) in addTimesheet.comments"
                :key="index"
                cols="12"
                md="6"
                class="col-xxl-4"
              >
                <div class="d-flex">
                  <div class="left w-100">
                    <b-form-group>
                      <label>Comment</label>
                      <b-form-textarea
                        id="comment"
                        v-model="comment.comment"
                        rows="2"
                      />
                    </b-form-group>
                  </div>
                  <div class="right mt-2 ml-1">
                    <b-button
                      v-show="index === 0"
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      @click="addComment"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                    <b-button
                      v-show="index !== 0"
                      variant="outline-danger"
                      class="btn-icon rounded-circle"
                      @click="removeComment(index)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            v-show="addTimesheet.user_defined_fields.length !== 0"
            variant="warning"
            class="pb-1 user-defind-fields"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>User Defined Fields</h6>
            </div>
            <b-row class="mt-1">
              <b-col
                v-for="field in addTimesheet.user_defined_fields"
                :id="field.id"
                :key="field.id"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group v-if="field.type.toLowerCase() === 'input'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-input
                      v-model="field.value"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'dropdown'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <v-select
                      v-model="field.value"
                      :options="field.options"
                      label="option"
                      :reduce="item => item.option"
                      :clearable="!field.required"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'textarea'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-textarea
                      v-model="field.value"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>
        <b-row>
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Add Timesheet
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BButton, BFormInput, BInputGroup, BFormTextarea, BFormSpinbutton, BButtonGroup, BBadge,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import Timesheet from '@/js/timesheets'
import Constants from '@/js/constant'
import LIST from '@/js/lists'

export default {
  name: 'TimesheetNew',
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BInputGroup,
    BButton,
    BFormInput,
    BFormTextarea,
    BFormSpinbutton,
    BButtonGroup,
    BBadge,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      addTimesheet: {
        user_id: null,
        status_id: null,
        timesheet_frequency_id: null,
        total_hours: '',
        expenses: '',
        sunday: {
          date: null,
          hours: 0.0,
        },
        monday: {
          date: null,
          hours: 0,
        },
        tueday: {
          date: null,
          hours: 0,
        },
        wedday: {
          date: null,
          hours: 0,
        },
        thusday: {
          date: null,
          hours: 0,
        },
        friday: {
          date: null,
          hours: 0,
        },
        satday: {
          date: null,
          hours: 0,
        },
        month_date: null,
        week_start_date: null,
        week_end_date: null,
        documents: [],
        comments: [],
        user_defined_fields: [],
      },
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      month: null,
      sun_day: null,
      mon_day: null,
      tue_day: null,
      wed_day: null,
      thu_day: null,
      fri_day: null,
      sat_day: null,
      frequencies: [],
      selectedFile: null,
      employees: [],
      status: [],
      document_name: null,
      documentNameId: null,
      documentNames: [],
      weekStarts: 0,
      weeks: [],
      url: null,
      week_id: null,
      showWeeks: [],
      endDay: null,
      firstDay: null,
      isShowWeeks: false,
      isShowMonths: false,
      isShowDays: false,
    }
  },
  watch: {
    '$store.state.lists.commonLists': {
      immediate: true,
      handler() {
        LIST.getTimeSheetFrequencyFullList(this)
        LIST.getEmployeeConsultantFullList(this)
      },
    },
    '$store.state.lists.faLists': {
      immediate: true,
      handler() {
        LIST.getTimesheetsStatusFullList(this)
        LIST.getTimesheetsDocumentNameFullList(this)
        LIST.getUserDefinedFieldsTimesheets(this)
      },
    },
  },
  mounted() {
  },
  created() {
    this.addComment()
    this.month = moment().format('MMM-YYYY')
    LIST.getTimeSheetFrequencyFullList(this)
    LIST.getEmployeeConsultantFullList(this)
    LIST.getTimesheetsStatusFullList(this)
    LIST.getTimesheetsDocumentNameFullList(this)
    LIST.getUserDefinedFieldsTimesheets(this)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          if (this.documentNameId !== null && this.selectedFile === null) {
            this.$swal({
              title: 'Upload',
              text: 'Please choose file',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return
          }
          if (this.documentNameId === null && this.selectedFile !== null) {
            this.$swal({
              title: 'Upload',
              text: 'Please select document type',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return
          }
          if (this.documentNameId !== null && this.selectedFile !== null) {
            this.addTimesheet.documents.push({
              document_name_id: this.documentNameId,
              attachment_link: this.url,
              document: this.selectedFile,
              file_name: this.documentName,
              name: this.documentName,
            })
            this.selectedFile = null
            this.url = null
            this.documentNameId = null
            this.$refs.myFileInput.value = ''
          }
          this.showLoading = true
          Timesheet.addTimesheet(this, this.addTimesheet, false)
        }
      })
    },
    setUserDefinedFields(userDefinedFields) {
      this.addTimesheet.user_defined_fields = userDefinedFields
    },
    attahamentFileSelected(obj) {
      const dis = this
      const fileReader = new FileReader()
      fileReader.onload = fileLoadEvent => {
        dis.selectedFile = fileLoadEvent.target.result
      }
      fileReader.readAsDataURL(obj.target.files[0])
      this.url = URL.createObjectURL(obj.target.files[0])
      this.documentName = obj.target.files[0].name
    },
    removeFile(file, position) {
      const dis = this
      dis.addTimesheet.documents.splice(position, 1)
    },
    addFile() {
      if (this.documentNameId !== null && this.selectedFile === null) {
        this.$swal({
          title: 'Error!',
          text: ' Please choose file',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId === null && this.selectedFile !== null) {
        this.$swal({
          title: 'Error!',
          text: ' Please select document type',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId !== null && this.selectedFile !== null) {
        this.addTimesheet.documents.push({
          document_name_id: this.documentNameId, attachment_link: this.url, document: this.selectedFile, file_name: this.documentName, name: this.documentName,
        })
        this.selectedFile = null
        this.url = null
        this.documentNameId = null
        this.$refs.myFileInput.value = ''
      }
    },
    hoursChanged() {
      let totlaHours = 0
      if (this.addTimesheet.sunday.hours != null) {
        totlaHours = Number(totlaHours) + Number(this.addTimesheet.sunday.hours)
      }
      if (this.addTimesheet.monday.hours != null) {
        totlaHours = Number(totlaHours) + Number(this.addTimesheet.monday.hours)
      }
      if (this.addTimesheet.tueday.hours != null) {
        totlaHours = Number(totlaHours) + Number(this.addTimesheet.tueday.hours)
      }
      if (this.addTimesheet.wedday.hours != null) {
        totlaHours = Number(totlaHours) + Number(this.addTimesheet.wedday.hours)
      }
      if (this.addTimesheet.thusday.hours != null) {
        totlaHours = Number(totlaHours) + Number(this.addTimesheet.thusday.hours)
      }
      if (this.addTimesheet.friday.hours != null) {
        totlaHours = Number(totlaHours) + Number(this.addTimesheet.friday.hours)
      }
      if (this.addTimesheet.satday.hours != null) {
        totlaHours = Number(totlaHours) + Number(this.addTimesheet.satday.hours)
      }
      this.addTimesheet.total_hours = totlaHours
    },
    setEmployeeConsultants(employees) {
      this.employees = employees
    },
    setStatus(status) {
      this.status = status
    },
    setTimeSheetFrequencies(frequencies) {
      this.frequencies = frequencies
    },
    setDocumentNames(documentNames) {
      this.documentNames = documentNames
    },
    onFrequencyChange() {
      const i = this.frequencies.find(item => item.id === this.addTimesheet.timesheet_frequency_id)
      if (i.label.toLowerCase() === 'monthly') {
        this.isShowWeeks = false
        this.isShowMonths = true
        this.addTimesheet.month_date = Constants.getEprmMonthDateFromString(`${moment(this.month, 'MMM-YYYY').format('MM')}-01-${moment(this.month, 'MMM-YYYY').format('YYYY')}`)
      } else {
        this.getWeekCount(this.addTimesheet.month)
        this.isShowWeeks = true
        this.isShowMonths = false
      }
    },
    getWeekCount() {
      this.weeks = []
      let startDate = this.month
      startDate = moment(startDate, 'MMM-YYYY').format('YYYY-MM')
      this.firstDay = moment(startDate).startOf('month').day(0)
      this.endDay = moment(startDate).endOf('month').day(0)
      if (this.endDay.format('dddd') === 'Sunday') { this.endDay = this.endDay.day(6) }
      if (this.endDay.format('dddd') === 'Monday') { this.endDay = this.endDay.day(5) }
      if (this.endDay.format('dddd') === 'Tuesday') { this.endDay = this.endDay.day(4) }
      if (this.endDay.format('dddd') === 'Wednesday') { this.endDay = this.endDay.day(3) }
      if (this.endDay.format('dddd') === 'Thursday') { this.endDay = this.endDay.day(2) }
      if (this.endDay.format('dddd') === 'Friday') { this.endDay = this.endDay.day(1) }
      const monthRange = moment.range(this.firstDay, this.endDay)
      const array = Array.from(monthRange.by('days'))
      array.forEach(mday => {
        if (this.weeks.indexOf(mday.week()) === -1) {
          this.weeks.push(mday.week())
        }
      })
      this.weekStarts = this.weeks[0] - 1
      this.showWeeks = []
      array.forEach(mday => {
        if (moment(this.firstDay).isAfter(moment(this.firstDay).week((mday.week())).day(6), 'day')) {
          if (this.showWeeks.find(item => item.label === `${moment(this.firstDay).week((mday.week())).add('years', 1).day(0)
            .format('MM-DD-YYYY')} - ${moment(this.firstDay).week((mday.week())).add('years', 1).day(6)
            .format('MM-DD-YYYY')}`) === null) {
            this.showWeeks.push({
              id: mday.week() - this.weekStarts,
              label: `${moment(this.firstDay).week((mday.week())).add('years', 1).day(0)
                .format('MM-DD-YYYY')} - ${moment(this.firstDay).week((mday.week())).add('years', 1).day(6)
                .format('MM-DD-YYYY')}`,
            })
          }
        } else if (!this.showWeeks.find(item => item.label === `${moment(this.firstDay).week((mday.week())).day(0).format('MM-DD-YYYY')} - ${moment(this.firstDay).week((mday.week())).day(6).format('MM-DD-YYYY')}`)) {
          this.showWeeks.push({
            id: mday.week() - this.weekStarts,
            label: `${moment(this.firstDay).week((mday.week())).day(0)
              .format('MM-DD-YYYY')} - ${moment(this.firstDay).week((mday.week())).day(6)
              .format('MM-DD-YYYY')}`,
          })
        }
      })
    },
    getSaysBasedOnWeek(event) {
      this.isShowDays = true
      let firstWeekDay = moment(this.firstDay).week(Number(event) + this.weekStarts).day(0)
      if (firstWeekDay.isBefore(this.firstDay)) {
        firstWeekDay = this.firstDay
      }
      this.addTimesheet.week_start_date = Constants.getEprmMonthDateFromString(moment(firstWeekDay))
      this.addTimesheet.week_end_date = Constants.getEprmMonthDateFromString(moment(firstWeekDay).add(6, 'days'))
      this.sun_day = moment(firstWeekDay).format('MM/DD')
      this.mon_day = moment(firstWeekDay).add(1, 'days').format('MM/DD')
      this.tue_day = moment(firstWeekDay).add(2, 'days').format('MM/DD')
      this.wed_day = moment(firstWeekDay).add(3, 'days').format('MM/DD')
      this.thu_day = moment(firstWeekDay).add(4, 'days').format('MM/DD')
      this.fri_day = moment(firstWeekDay).add(5, 'days').format('MM/DD')
      this.sat_day = moment(firstWeekDay).add(6, 'days').format('MM/DD')
      this.addTimesheet.sunday.date = Constants.getEprmMonthDateFromString(moment(firstWeekDay))
      this.addTimesheet.monday.date = Constants.getEprmMonthDateFromString(moment(firstWeekDay).add(1, 'days'))
      this.addTimesheet.tueday.date = Constants.getEprmMonthDateFromString(moment(firstWeekDay).add(2, 'days'))
      this.addTimesheet.wedday.date = Constants.getEprmMonthDateFromString(moment(firstWeekDay).add(3, 'days'))
      this.addTimesheet.thusday.date = Constants.getEprmMonthDateFromString(moment(firstWeekDay).add(4, 'days'))
      this.addTimesheet.friday.date = Constants.getEprmMonthDateFromString(moment(firstWeekDay).add(5, 'days'))
      this.addTimesheet.satday.date = Constants.getEprmMonthDateFromString(moment(firstWeekDay).add(6, 'days'))
    },
    monthChanged(selectedDate, dateStr) {
      this.isShowDays = false
      this.week_id = null
      this.month = dateStr
      this.addTimesheet.month_date = Constants.getEprmMonthDateFromString(`${moment(dateStr, 'MMM-YYYY').format('MM')}-01-${moment(dateStr, 'MMM-YYYY').format('YYYY')}`)
      if (this.isShowWeeks) {
        this.getWeekCount(dateStr)
        this.isShowWeeks = true
        this.isShowMonths = false
      }
    },
    timesheetAdded(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    timesheetNotAdded(data) {
      this.$refs.showLoading = false
      if (data.return_code === 4) {
        this.$swal({
          title: 'Warning!',
          text: data.return_message,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, create it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-primary ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.showLoading = true
            Timesheet.addTimesheet(this, this.addTimesheet, true)
          }
        })
      } else {
        this.$swal({
          title: 'Error!',
          text: data.return_message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    },
    addComment() {
      const comment = {
        comment: '',
        date: Constants.getEprmMonthDateFromString(moment()),
        time: moment().format('hh:mm a'),
      }
      this.addTimesheet.comments.push(comment)
    },
    removeComment(index) {
      this.addTimesheet.comments.splice(index, 1)
    },
    back() {
      this.$router.push('/finance-and-accounting/timesheets').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.repeater-form {
  transition: .35s height;
}
</style>
