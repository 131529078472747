import ProfileInvitations from '@/views/administration/profile-invitations/ProfileInvitations.vue'
import ProfileInvitationNew from '@/views/administration/profile-invitations/actions/New.vue'
import ProfileInvitationEdit from '@/views/administration/profile-invitations/actions/Edit.vue'
import ProfileInvitationView from '@/views/administration/profile-invitations/actions/View.vue'
import MassCommunication from '@/views/administration/mass-communication/MassCommunication.vue'
import SkillsTechnologies from '@/views/administration/skills-technologies/SkillsTechnologies.vue'
import SkillsTechnologiesNew from '@/views/administration/skills-technologies/actions/New.vue'
import SkillsTechnologiesEdit from '@/views/administration/skills-technologies/actions/Edit.vue'
import SkillsTechnologiesView from '@/views/administration/skills-technologies/actions/View.vue'
import Documents from '@/views/administration/documents/Documents.vue'
import DocumentsNew from '@/views/administration/documents/actions/New.vue'
import DocumentsEdit from '@/views/administration/documents/actions/Edit.vue'
import DocumentsView from '@/views/administration/documents/actions/View.vue'
import Workflow from '@/views/administration/workflow/Workflow.vue'
import WorkflowNew from '@/views/administration/workflow/actions/New.vue'
import WorkflowEdit from '@/views/administration/workflow/actions/Edit.vue'
import WorkflowView from '@/views/administration/workflow/actions/View.vue'
import WorkflowTasks from '@/views/administration/workflow-tasks/WorkflowTasks.vue'
import WorkflowTaskNew from '@/views/administration/workflow-tasks/actions/New.vue'
import WorkflowTaskEdit from '@/views/administration/workflow-tasks/actions/Edit.vue'
import WorkflowTaskClone from '@/views/administration/workflow-tasks/actions/Clone.vue'
import WorkflowTaskView from '@/views/administration/workflow-tasks/actions/View.vue'
import QboCategories from '@/views/administration/qbo-categories/qbo-categories.vue'
import QboCategoriesNew from '@/views/administration/qbo-categories/actions/New.vue'
import QboCategoriesEdit from '@/views/administration/qbo-categories/actions/Edit.vue'
import QboCategoriesView from '@/views/administration/qbo-categories/actions/View.vue'
import UserDefinedFields from '@/views/administration/user-defined-fields/UserDefinedFields.vue'
import RoleUsers from '@/views/administration/role-users/RoleUsers.vue'
import RoleUsersNew from '@/views/administration/role-users/actions/New.vue'
import RoleUsersEdit from '@/views/administration/role-users/actions/Edit.vue'
import RoleUsersView from '@/views/administration/role-users/actions/View.vue'
import Roles from '@/views/administration/roles/Roles.vue'
import RolesNew from '@/views/administration/roles/actions/New.vue'
import RolesEdit from '@/views/administration/roles/actions/Edit.vue'
import RolesView from '@/views/administration/roles/actions/View.vue'
import AssignJobs from '@/views/administration/assign-jobs-admin/AssignJobs.vue'
import AssignJobsNew from '@/views/administration/assign-jobs-admin/actions/New.vue'
import AssignJobsEdit from '@/views/administration/assign-jobs-admin/actions/Edit.vue'
import AssignJobsView from '@/views/administration/assign-jobs-admin/actions/View.vue'
import UserLoginTrackiong from '@/views/administration/user-login-tracking/UserLoginTracking.vue'

export default [
  // *===============================================---*
  // *--------- Administration -------------------------------*
  // *===============================================---*
  // *------------ Profile Invitations --------------------*
  {
    path: '/administration/profile-invitations',
    name: 'administration-profile-invitations',
    component: ProfileInvitations,
  },
  {
    path: '/administration/profile-invitations/new',
    name: 'administration-profile-invitations-new',
    component: ProfileInvitationNew,
    meta: {
      navActiveLink: 'administration-profile-invitations',
    },
  },
  {
    path: '/administration/profile-invitations/edit/:id',
    name: 'administration-profile-invitations-edit',
    component: ProfileInvitationEdit,
    meta: {
      navActiveLink: 'administration-profile-invitations',
    },
  },
  {
    path: '/administration/profile-invitations/view/:id',
    name: 'administration-profile-invitations-view',
    component: ProfileInvitationView,
    meta: {
      navActiveLink: 'administration-profile-invitations',
    },
  },
  // *------------ Mass Communication --------------------*
  {
    path: '/administration/mass-communication',
    name: 'administration-mass-communication',
    component: MassCommunication,
  },
  // *------------ Skills and Technologies --------------------*
  {
    path: '/administration/skills-and-technologies',
    name: 'administration-skills-and-technologies',
    component: SkillsTechnologies,
  },
  {
    path: '/administration/skills-and-technologies/new',
    name: 'administration-skills-and-technologies-new',
    component: SkillsTechnologiesNew,
    meta: {
      navActiveLink: 'administration-skills-and-technologies',
    },
  },
  {
    path: '/administration/skills-and-technologies/edit/:id',
    name: 'administration-skills-and-technologies-edit',
    component: SkillsTechnologiesEdit,
    meta: {
      navActiveLink: 'administration-skills-and-technologies',
    },
  },
  {
    path: '/administration/skills-and-technologies/view/:id',
    name: 'administration-skills-and-technologies-view',
    component: SkillsTechnologiesView,
    meta: {
      navActiveLink: 'administration-skills-and-technologies',
    },
  },
  // *------------ Documents --------------------*
  {
    path: '/administration/documents',
    name: 'administration-documents',
    component: Documents,
  },
  {
    path: '/administration/documents/new',
    name: 'administration-documents-new',
    component: DocumentsNew,
    meta: {
      navActiveLink: 'administration-documents',
    },
  },
  {
    path: '/administration/documents/edit/:id',
    name: 'administration-documents-edit',
    component: DocumentsEdit,
    meta: {
      navActiveLink: 'administration-documents',
    },
  },
  {
    path: '/administration/documents/view/:id',
    name: 'administration-documents-view',
    component: DocumentsView,
    meta: {
      navActiveLink: 'administration-documents',
    },
  },
  // *------------ Workflow --------------------*
  {
    path: '/administration/workflow',
    name: 'administration-workflow',
    component: Workflow,
  },
  {
    path: '/administration/workflow/new',
    name: 'administration-workflow-new',
    component: WorkflowNew,
    meta: {
      navActiveLink: 'administration-workflow',
    },
  },
  {
    path: '/administration/workflow/edit/:id',
    name: 'administration-workflow-edit',
    component: WorkflowEdit,
    meta: {
      navActiveLink: 'administration-workflow',
    },
  },
  {
    path: '/administration/workflow/view/:id',
    name: 'administration-workflow-view',
    component: WorkflowView,
    meta: {
      navActiveLink: 'administration-workflow',
    },
  },
  // *------------ Workflow Tasks --------------------*
  {
    path: '/administration/workflow-tasks',
    name: 'administration-workflow-tasks',
    component: WorkflowTasks,
    meta: {
      navActiveLink: 'administration-workflow',
    },
  },
  {
    path: '/administration/workflow-tasks/new',
    name: 'administration-workflow-tasks-new',
    component: WorkflowTaskNew,
    meta: {
      navActiveLink: 'administration-workflow',
    },
  },
  {
    path: '/administration/workflow-tasks/edit/:id',
    name: 'administration-workflow-tasks-edit',
    component: WorkflowTaskEdit,
    meta: {
      navActiveLink: 'administration-workflow',
    },
  },
  {
    path: '/administration/workflow-tasks/clone/:id',
    name: 'administration-workflow-tasks-clone',
    component: WorkflowTaskClone,
    meta: {
      navActiveLink: 'administration-workflow',
    },
  },
  {
    path: '/administration/workflow-tasks/view/:id',
    name: 'administration-workflow-tasks-view',
    component: WorkflowTaskView,
    meta: {
      navActiveLink: 'administration-workflow',
    },
  },
  // *------------ Qbo Category --------------------*
  {
    path: '/administration/qbo-categories',
    name: 'administration-qbo-categories',
    component: QboCategories,
    meta: {
      navActiveLink: 'administration-qbo-categories',
    },
  },
  {
    path: '/administration/qbo-categories/new',
    name: 'administration-qbo-categories-new',
    component: QboCategoriesNew,
    meta: {
      navActiveLink: 'administration-qbo-categories',
    },
  },
  {
    path: '/administration/qbo-categories/edit/:id',
    name: 'administration-qbo-categories-edit',
    component: QboCategoriesEdit,
    meta: {
      navActiveLink: 'administration-qbo-categories',
    },
  },
  {
    path: '/administration/qbo-categories/view/:id',
    name: 'administration-qbo-categories-view',
    component: QboCategoriesView,
    meta: {
      navActiveLink: 'administration-qbo-categories',
    },
  },
  // *------------ User Defined Fields --------------------*
  {
    path: '/administration/user-defined-fields',
    name: 'administration-user-defined-fields',
    component: UserDefinedFields,
  },
  // *------------ Role Users --------------------*
  {
    path: '/administration/role-users',
    name: 'administration-role-users',
    component: RoleUsers,
    meta: {
      navActiveLink: 'administration-role-users',
    },
  },
  {
    path: '/administration/role-users/new',
    name: 'administration-role-users-new',
    component: RoleUsersNew,
    meta: {
      navActiveLink: 'administration-role-users',
    },
  },
  {
    path: '/administration/role-users/edit/:id',
    name: 'administration-role-users-edit',
    component: RoleUsersEdit,
    meta: {
      navActiveLink: 'administration-role-users',
    },
  },
  {
    path: '/administration/role-users/view/:id',
    name: 'administration-role-users-view',
    component: RoleUsersView,
    meta: {
      navActiveLink: 'administration-role-users',
    },
  },
  // *------------ Roles --------------------*
  {
    path: '/administration/roles',
    name: 'administration-roles',
    component: Roles,
  },
  {
    path: '/administration/roles/new',
    name: 'administration-roles-new',
    component: RolesNew,
    meta: {
      navActiveLink: 'administration-roles',
    },
  },
  {
    path: '/administration/roles/edit/:id',
    name: 'administration-roles-edit',
    component: RolesEdit,
    meta: {
      navActiveLink: 'administration-roles',
    },
  },
  {
    path: '/administration/roles/view/:id',
    name: 'administration-roles-view',
    component: RolesView,
    meta: {
      navActiveLink: 'administration-roles',
    },
  },
  // *------------ Assign Jobs Admin --------------------*
  {
    path: '/administration/assign-jobs',
    name: 'administration-assign-jobs',
    component: AssignJobs,
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
    },
  },
  {
    path: '/administration/assign-jobs/new',
    name: 'administration-assign-jobs-new',
    component: AssignJobsNew,
    meta: {
      navActiveLink: 'administration-assign-jobs',
    },
  },
  {
    path: '/administration/assign-jobs/edit/:id',
    name: 'administration-assign-jobs-edit',
    component: AssignJobsEdit,
    meta: {
      navActiveLink: 'administration-assign-jobs',
    },
  },
  {
    path: '/administration/assign-jobs/view/:id',
    name: 'administration-assign-jobs-view',
    component: AssignJobsView,
    meta: {
      navActiveLink: 'administration-assign-jobs',
    },
  },
  // *------------ User Login Tracking --------------------*
  {
    path: '/administration/user-login-tracking',
    name: 'administration-user-login-tracking',
    component: UserLoginTrackiong,
    meta: {
      navActiveLink: 'administration-user-login-tracking',
    },
  },
]
