import DailyUpdates from '@/views/employee-administration/daily-updates/DailyUpdates.vue'
import DailyUpdatesNew from '@/views/employee-administration/daily-updates/actions/New.vue'
import DailyUpdatesEdit from '@/views/employee-administration/daily-updates/actions/Edit.vue'
import DailyUpdatesView from '@/views/employee-administration/daily-updates/actions/View.vue'

export default [
  // *===============================================---*
  // *--------- Employee Administration -------------------------------*
  // *===============================================---*
  // *------------ Daily Updates --------------------*
  {
    path: '/employee/daily-updates',
    name: 'employee-daily-updates',
    component: DailyUpdates,
  },
  {
    path: '/employee/daily-updates/new',
    name: 'employee-daily-updates-new',
    component: DailyUpdatesNew,
    meta: {
      navActiveLink: 'employee-daily-updates',
    },
  },
  {
    path: '/employee/daily-updates/edit/:id',
    name: 'employee-daily-updates-edit',
    component: DailyUpdatesEdit,
    meta: {
      navActiveLink: 'employee-daily-updates',
    },
  },
  {
    path: '/employee/daily-updates/view/:id',
    name: 'employee-daily-updates-view',
    component: DailyUpdatesView,
    meta: {
      navActiveLink: 'employee-daily-updates',
    },
  },
]
