<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          View Timesheet</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>

    <app-timeline>
      <app-timeline-item class="pb-1">
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Name of Employee Consultant
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="timesheet.name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Timesheet Frequency
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="timesheet.timesheet_frequency"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Month
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="month"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            v-if="isShowWeeks"
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Week
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="week"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            v-if="isShowWeeks"
            cols="12"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Day's
              </dt>
              <dd class="col-sm-12">
                <table class="table table-striped table-bordered table-td-pd mb-0">
                  <thead>
                    <tr>
                      <th>Sun {{ sun_day }}</th>
                      <th>Mon {{ mon_day }}</th>
                      <th>Tue {{ tue_day }}</th>
                      <th>Wed {{ wed_day }}</th>
                      <th>Thu {{ thu_day }}</th>
                      <th>Fri {{ fri_day }}</th>
                      <th>Sat {{ sat_day }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <label
                          class="form-control"
                          v-text="timesheet.sunday.hours"
                        />
                      </td>
                      <td>
                        <label
                          class="form-control"
                          v-text="timesheet.monday.hours"
                        />
                      </td>
                      <td>
                        <label
                          class="form-control"
                          v-text="timesheet.tueday.hours"
                        />
                      </td>
                      <td>
                        <label
                          class="form-control"
                          v-text="timesheet.wedday.hours"
                        />
                      </td>
                      <td>
                        <label
                          class="form-control"
                          v-text="timesheet.thusday.hours"
                        />
                      </td>
                      <td>
                        <label
                          class="form-control"
                          v-text="timesheet.friday.hours"
                        />
                      </td>
                      <td>
                        <label
                          class="form-control"
                          v-text="timesheet.satday.hours"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Total Hours
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="timesheet.total_hours"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            v-if="timesheet.previous_hours !== null"
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Previous Hours
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="timesheet.previous_hours"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Expenses
              </dt>
              <dd class="col-sm-12">
                <span class="form-control">{{ timesheet.expenses | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Status
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="timesheet.status"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            v-if="timesheet.reject_reason !== null"
            cols="6"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Reject Reason
              </dt>
              <dd class="col-sm-12">
                <b-form-textarea
                  v-model="timesheet.reject_reason"
                  disabled
                  rows="2"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="info"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Tasks</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            class="mt-1"
          >
            <div class="table-responsive-sm">
              <table class="table b-table">
                <thead>
                  <tr>
                    <th class="min-w-200">
                      Name
                    </th>
                    <th class="min-w-160">
                      Created Date
                    </th>
                    <th class="min-w-140">
                      Due Date
                    </th>
                    <th>Status</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="task in timesheet.tasks"
                    :key="task"
                    :value="task"
                  >
                    <td>{{ task.name }}</td>
                    <td>{{ task.created_date }}</td>
                    <td>{{ task.due_date }}</td>
                    <td>{{ task.status }}</td>
                    <td>{{ task.description }}</td>
                  </tr>
                  <tr v-if="timesheet.tasks.length == 0">
                    <td
                      colspan="7"
                      class="text-center"
                    >
                      No Tasks Available
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="success"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Documents</h6>
        </div>
        <b-row
          v-if="timesheet.documents!==null"
          class="mt-1"
        >
          <b-col cols="12">
            <b-link
              v-for="option in timesheet.documents"
              :key="option.id"
              :value="option.id"
              :href="option.attachment_link"
              :title="option.name"
              target="option.attachment_link"
              :class="{'mt-75': option}"
            >
              <b-badge
                variant="primary"
                class="mr-1 mb-50"
              >
                <span>{{ option.name }}</span>
              </b-badge>
            </b-link>
          </b-col>
        </b-row>
        <div
          v-if="timesheet.documents.length == 0"
          class="text-center"
        >
          <p> No Documents available </p>
        </div>
      </app-timeline-item>

      <app-timeline-item
        variant="warning"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Comments</h6>
        </div>
        <b-row class="mt-1">
          <b-col
            v-for="comment in timesheet.comments"
            :key="comment"
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <span class="d-block"><small>Commented on {{ comment.date_text }} {{ comment.time }}</small></span>
              <b-form-textarea
                v-model="comment.comment"
                rows="2"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div
          v-if="timesheet.comments.length == 0"
          class="text-center"
        >
          <p class="mb-0">
            No Comments available
          </p>
        </div>
      </app-timeline-item>

      <app-timeline-item
        v-show="timesheet.user_defined_fields.length !== 0"
        variant="danger"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>User Defined Fields</h6>
        </div>
        <b-row class="mt-1">
          <b-col
            v-for="field in timesheet.user_defined_fields"
            :id="field.id"
            :key="field.id"
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                {{ field.name }}
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="field.value"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BButton, BFormTextarea, BLink, BBadge,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Timesheet from '@/js/timesheets'
import Constants from '@/js/constant'
import moment from 'moment'

export default {
  name: 'TimesheetView',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormTextarea,
    BLink,
    BBadge,
  },
  directives: {
  },
  setup() {
  },
  data() {
    return {
      timesheet: {},
      sun_day: null,
      mon_day: null,
      tue_day: null,
      wed_day: null,
      thu_day: null,
      fri_day: null,
      sat_day: null,
      week: '',
      month: '',
      isShowWeeks: true,
      isShowDays: true,
    }
  },
  mounted() {
  },
  created() {
    Timesheet.getTimeSheetById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    setTimesheet(timesheet) {
      this.timesheet = timesheet
      this.month = moment(timesheet.month_date, 'MM-DD-YYYY').format('MMM-YYYY')
      if (timesheet.timesheet_frequency === 'weekly') {
        this.isShowWeeks = true
        this.isShowDays = true
        this.week = `${timesheet.week_start_date} - ${timesheet.week_end_date}`
        this.sun_day = moment(Constants.getDateStringFromEprmDate(timesheet.sunday.date), 'MM-DD-YYYY').format('MM/DD')
        this.mon_day = moment(Constants.getDateStringFromEprmDate(timesheet.monday.date), 'MM-DD-YYYY').format('MM/DD')
        this.tue_day = moment(Constants.getDateStringFromEprmDate(timesheet.tueday.date), 'MM-DD-YYYY').format('MM/DD')
        this.wed_day = moment(Constants.getDateStringFromEprmDate(timesheet.wedday.date), 'MM-DD-YYYY').format('MM/DD')
        this.thu_day = moment(Constants.getDateStringFromEprmDate(timesheet.thusday.date), 'MM-DD-YYYY').format('MM/DD')
        this.fri_day = moment(Constants.getDateStringFromEprmDate(timesheet.friday.date), 'MM-DD-YYYY').format('MM/DD')
        this.sat_day = moment(Constants.getDateStringFromEprmDate(timesheet.satday.date), 'MM-DD-YYYY').format('MM/DD')
      } else {
        this.isShowWeeks = false
        this.isShowDays = false
      }
    },
    back() {
      this.$router.push('/finance-and-accounting/timesheets').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
