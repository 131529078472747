<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Edit Invoice To Vendor</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <b-row class="mt-1">
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Vendor</label>
                  <span
                    class="form-control h-auto"
                    v-text="invoice.user_name"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Consultant</label>
                  <div
                    v-for="(option, index) in invoice.employee_names"
                    :key="index"
                  >
                    <span
                      :key="index"
                      class="form-control"
                      v-text="option"
                    />
                  </div>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Invoice #</label>
                  <span
                    class="form-control"
                    v-text="invoice.name"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Submitted Date</label>
                  <span
                    class="form-control"
                    v-text="invoice.received_date"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Invoice Period</label>
                  <span
                    class="form-control"
                    v-text="invoice.period"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Invoice Amount</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Invoice Amount"
                    rules="required"
                  >
                    <b-input-group
                      prepend="$"
                    >
                      <money
                        v-model="addInvoice.amount"
                        :min="0"
                        :step="0.5"
                        v-bind="money"
                        class="form-control"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Expenses</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Invoice Amount"
                    rules="required"
                  >
                    <b-input-group
                      prepend="$"
                    >
                      <money
                        id="expenses"
                        v-model="addInvoice.expenses"
                        :min="0"
                        :step="0.5"
                        v-bind="money"
                        class="form-control"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Status</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Status"
                    rules="required"
                  >
                    <v-select
                      v-model="addInvoice.status_id"
                      :options="status"
                      :reduce="item => item.id"
                      :clearable="false"
                      placeholder="Select"
                      @input="statusChanged()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            v-if="isPaid || isPartiallyPaid"
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Check Details</h6>
            </div>
            <div
              v-for="(cheque, index) in addInvoice.cheque_details"
              :key="index"
            >
              <div class="d-flex mt-1">
                <div class="left w-100">
                  <b-row>
                    <b-col
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <b-form-group>
                        <label>Number</label>
                        <validation-provider
                          v-slot="{ errors }"
                          name="Number"
                          :rules="isPaid || isPartiallyPaid ? 'required' : ''"
                        >
                          <b-form-input
                            v-model="addInvoice.cheque_details[index].number"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <b-form-group>
                        <label>Amount</label>
                        <validation-provider
                          v-slot="{ errors }"
                          name="Amount"
                          :rules="isPaid || isPartiallyPaid ? 'required' : ''"
                        >
                          <b-input-group
                            prepend="$"
                          >
                            <money
                              v-model="addInvoice.cheque_details[index].amount"
                              :min="0"
                              :step="0.5"
                              v-bind="money"
                              class="form-control"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <b-form-checkbox
                        v-model="addInvoice.cheque_details[index].deposited"
                        class="mt-md-2"
                        @change="check(index)"
                      >
                        Deposited
                      </b-form-checkbox>
                    </b-col>
                    <b-col
                      v-show="addInvoice.cheque_details[index].deposited"
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <b-form-group>
                        <label>Deposited Date</label>
                        <validation-provider
                          name="Deposited Date"
                          :rules="addInvoice.cheque_details[index].deposited ? 'required' : ''"
                        >
                          <flat-pickr
                            v-model="addInvoice.cheque_details[index].deposit_date_text"
                            class="form-control"
                            :config="{ dateFormat: 'm-d-Y' }"
                            placeholder="MM-DD-YYYY"
                          />
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <b-form-group>
                        <label>Date</label>
                        <validation-provider
                          name="Date"
                          :rules="isPaid || isPartiallyPaid ? 'required' : ''"
                        >
                          <flat-pickr
                            v-model="addInvoice.cheque_details[index].date_text"
                            class="form-control"
                            :config="{ dateFormat: 'm-d-Y' }"
                            placeholder="MM-DD-YYYY"
                          />
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
                <div class="right ml-1">
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="btn-icon rounded-circle"
                    @click="removeChequeDetails(index)"
                  >
                    <feather-icon
                      icon="XIcon"
                    />
                  </b-button>
                </div>
              </div>
              <hr class="mt-0 mb-0">
            </div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="mt-1"
              @click="addChequeDetails"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>Add Check Details</span>
            </b-button>
          </app-timeline-item>

          <app-timeline-item
            v-if="invoice.corrected_invoice !== null"
            variant="success"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Corrected Invoice</h6>
            </div>
            <b-row class="mt-1">
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <dl class="row">
                  <dt class="col-sm-12 dt-text">
                    Invoice Number
                  </dt>
                  <dd class="col-sm-12">
                    <span
                      class="form-control"
                      v-text="invoice.corrected_invoice.number"
                    />
                  </dd>
                </dl>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <dl class="row">
                  <dt class="col-sm-12 dt-text">
                    Invoice Amount
                  </dt>
                  <dd class="col-sm-12">
                    <span class="form-control">{{ invoice.corrected_invoice.amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
                  </dd>
                </dl>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="warning"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Tasks</h6>
            </div>
            <b-row>
              <b-col
                cols="12"
                md="6 ml-auto"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-button
                    variant="outline-primary"
                    :to="{ name: 'new-create-task', params: {id: $route.params.id}}"
                  >
                    Create Task
                  </b-button>
                </div>
              </b-col>
              <b-col
                cols="12"
                class="mt-1"
              >
                <div class="table-responsive-sm">
                  <table class="table b-table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Created Date</th>
                        <th>Due Date</th>
                        <th>Status</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="task in invoice.tasks"
                        :key="task"
                        :value="task"
                      >
                        <td>{{ task.name }}</td>
                        <td>{{ task.created_date }}</td>
                        <td>{{ task.due_date }}</td>
                        <td>{{ task.status }}</td>
                        <td>{{ task.description }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="danger"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Invoices</h6>
            </div>
            <b-row class="mt-1">
              <b-col cols="12">
                <span
                  v-for="option in invoice.invoices"
                  :key="option.id"
                  :value="option.id"
                  class="mr-50"
                >
                  <b-badge
                    :href="option.path"
                    :title="option.name"
                    target="_blank"
                    variant="light-primary"
                    class="line-height-inherit"
                  >
                    <span>{{ option.name }}</span>
                  </b-badge>
                </span>
              </b-col>
            </b-row>
            <div
              v-if="invoice.invoices.length == 0"
              class="text-center"
            >
              <p> No invoices available </p>
            </div>
          </app-timeline-item>

          <app-timeline-item
            variant="primary"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Documents</h6>
            </div>
            <b-row class="mt-1">
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Type</label>
                  <v-select
                    v-model="documentNameId"
                    :clearable="true"
                    placeholder="Select"
                    :options="documentNames"
                    :reduce="item => item.id"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Attachment</label>
                  <div class="d-flex">
                    <input
                      ref="myFileInput"
                      type="file"
                      placeholder="Choose a file or drop it here..."
                      class="form-control"
                      @change="attahamentFileSelected"
                    >
                    <b-button
                      variant="outline-primary"
                      class="ml-1 px-1"
                      @click="addFile"
                    >
                      Upload
                    </b-button>
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button-group
                  v-for="(option, index) in addInvoice.documents"
                  :key="option.id"
                  :value="option.id"
                  class="mr-1 mb-50"
                >
                  <b-badge
                    :href="option.attachment_link"
                    :title="option.name"
                    target="_blank"
                    variant="light-primary"
                    class="rounded-right-0 line-height-inherit"
                  >
                    <span>{{ option.name }}</span>
                  </b-badge>
                  <b-button
                    variant="danger"
                    class="px-50 py-25"
                    @click="removeFile(option, index)"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Comments</h6>
            </div>
            <b-row class="mt-1">
              <b-col
                v-for="(comment, index) in addInvoice.comments"
                :key="index"
                cols="12"
                md="6"
                class="col-xxl-4"
              >
                <div class="d-flex">
                  <div class="left w-100">
                    <b-form-group>
                      <label>Comment</label>
                      <b-form-textarea
                        v-model="comment.comment"
                        rows="2"
                      />
                    </b-form-group>
                  </div>
                  <div class="right mt-2 ml-1">
                    <b-button
                      v-show="index === 0"
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      @click="addComment"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                    <b-button
                      v-show="index !== 0"
                      variant="outline-danger"
                      class="btn-icon rounded-circle"
                      @click="removeComment(index)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </app-timeline-item>

          <app-timeline-item
            v-show="invoice.user_defined_fields.length !== 0"
            variant="success"
            class="pb-1"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>User Defined Fields</h6>
            </div>
            <b-row class="mt-1">
              <b-col
                v-for="field in invoice.user_defined_fields"
                :id="field.id"
                :key="field.id"
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group v-if="field.type.toLowerCase() === 'input'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-input
                      v-model="field.value"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'dropdown'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <v-select
                      v-model="field.value"
                      :options="field.options"
                      label="option"
                      :reduce="item => item.option"
                      :clearable="!field.required"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="field.type.toLowerCase() === 'textarea'">
                  <label>{{ field.name }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="field.name"
                    :rules="field.required ? 'required' : ''"
                  >
                    <b-form-textarea
                      v-model="field.value"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <hr>

        <b-row class="ml-2">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Update Invoice
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BInputGroup, BButton, BFormCheckbox, BBadge,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import flatPickr from 'vue-flatpickr-component'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import InvoicesVendor from '@/js/invoicesvendor'
import LIST from '@/js/lists'
import Constants from '@/js/constant'
import moment from 'moment'

export default {
  name: 'InvoicesVendorEdit',
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    vSelect,
    BButton,
    BBadge,
    BFormCheckbox,
    BInputGroup,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      task_data: {
        user_name: '',
        employee_names: [],
        name: '',
        received_date: '',
        period: '',
      },
      invoice: {
        name: '',
        period: '',
        user_id: '',
        user_name: '',
        employee_ids: null,
        timesheet_ids: null,
        employee_names: null,
        timesheet_periods: null,
        total_amount: null,
        received_date: null,
        status: {},
        documents: [],
        comments: [],
        user_defined_fields: [],
      },
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      configs: {
        monthSelect: {
          plugins: [
            monthSelectPlugin({
              shorthand: true,
              dateFormat: 'M-Y',
              altFormat: 'F, Y',
            }),
          ],
        },
        start: {
          format: 'MM-DD-YYYY',
          useCurrent: false,
          showClear: false,
          showClose: false,
          minDate: moment('01-01-2000', 'MM-DD-YYYY'),
        },
      },
      addInvoice: {
        amount: '',
        is_corrected: false,
        corrected_invoice_id: null,
        expenses: '',
        status_id: null,
        documents: [],
        comments: [],
        cheque_details: [],
        user_defined_fields: [],
      },
      url: null,
      isPartiallyPaid: false,
      isPaid: false,
      documentNameId: null,
      selectedFile: null,
      companies: [],
      correctedInvoices: [],
      status: [],
      documentNames: [],
    }
  },
  watch: {
    '$store.state.lists.faLists': {
      immediate: true,
      handler() {
        LIST.getInvoiceVendorStatusFullList(this)
        LIST.getInvoiceVendorDocumentNameFullList(this)
      },
    },
  },
  mounted() {
  },
  created() {
    // if(!User.isAdmin() && !User.isVendorInvoicesAvailable()) {
    //   window.location.hash = '/'
    //   return
    // }
    LIST.getInvoiceVendorStatusFullList(this)
    LIST.getInvoiceVendorDocumentNameFullList(this)
    InvoicesVendor.getVendorInvoiceById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          if (this.documentNameId !== null && this.selectedFile === null) {
            this.$swal({
              title: 'Upload',
              text: 'Please choose file',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return
          }
          if (this.documentNameId === null && this.selectedFile !== null) {
            this.$swal({
              title: 'Upload',
              text: 'Please select document type',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return
          }
          if (this.documentNameId !== null && this.selectedFile !== null) {
            this.addInvoice.documents.push({
              document_name_id: this.documentNameId,
              attachment_link: this.url,
              document: this.selectedFile,
              file_name: this.documentName,
              name: this.documentName,
            })
            this.selectedFile = null
            this.url = null
            this.documentNameId = null
            this.$refs.myFileInput.value = ''
          }
          for (let position = 0; position < this.addInvoice.cheque_details.length; position += 1) {
            this.addInvoice.cheque_details[position].deposit_date = Constants.getEprmMonthDateFromString(this.addInvoice.cheque_details[position].deposit_date_text)
            this.addInvoice.cheque_details[position].date = Constants.getEprmMonthDateFromString(this.addInvoice.cheque_details[position].date_text)
          }
          this.addInvoice.user_defined_fields = this.invoice.user_defined_fields
          this.addInvoice.time = moment().format('hh:mm a')
          this.addInvoice.date = Constants.getEprmMonthDateFromString(moment().format('MM-DD-YYYY'))
          InvoicesVendor.updateInvoice(this, this.$route.params.id, this.addInvoice)
        }
      })
    },
    attahamentFileSelected(obj) {
      const dis = this
      const fileReader = new FileReader()
      fileReader.onload = fileLoadEvent => {
        dis.selectedFile = fileLoadEvent.target.result
      }
      fileReader.readAsDataURL(obj.target.files[0])
      this.url = URL.createObjectURL(obj.target.files[0])
      this.documentName = obj.target.files[0].name
    },
    removeFile(file, position) {
      if (file.id == null) {
        this.addInvoice.documents.splice(position, 1)
      } else {
        this.deleteConfirm(file, position)
      }
    },
    deleteConfirm(obj, position) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${obj.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.invoice.documents.splice(position, 1)
        }
      })
    },
    addFile() {
      if (this.documentNameId !== null && this.selectedFile === null) {
        this.$swal({
          title: 'Error!',
          text: ' Please choose file',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId === null && this.selectedFile !== null) {
        this.$swal({
          title: 'Error!',
          text: ' Please select document type',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId !== null && this.selectedFile !== null) {
        this.addInvoice.documents.push({
          document_name_id: this.documentNameId, attachment_link: this.url, document: this.selectedFile, file_name: this.documentName, name: this.documentName,
        })
        this.selectedFile = null
        this.url = null
        this.documentNameId = null
        this.$refs.myFileInput.value = ''
      }
    },
    check(position) {
      if (this.addInvoice.cheque_details[position].deposited) {
        this.addInvoice.cheque_details[position].deposit_date_text = moment().format('MM-DD-YYYY')
        this.addInvoice.cheque_details[position].deposit_date = Constants.getEprmMonthDateFromString(moment())
      } else {
        this.addInvoice.cheque_details[position].deposit_date_text = ''
        this.addInvoice.cheque_details[position].deposit_date = null
      }
    },
    addChequeDetails() {
      const cheque = {
        amount: '',
        id: null,
        number: null,
        deposited: false,
        deposit_date: null,
        deposit_date_text: '',
        date_text: moment().format('MM-DD-YYYY'),
        date: Constants.getEprmMonthDateFromString(moment()),
        time: moment().format('hh:mm a'),
      }
      this.addInvoice.cheque_details.push(cheque)
    },
    removeChequeDetails(position) {
      this.addInvoice.cheque_details.splice(position, 1)
    },
    statusChanged() {
      this.isRejected = false
      this.isPaid = false
      this.isPartiallyPaid = false
      this.status.forEach(status => {
        if (status.id === this.addInvoice.status_id) {
          if (status.label.toLowerCase() === 'rejected') { this.isRejected = true }
          if (status.label.toLowerCase() === 'paid' || status.label.toLowerCase() === 'partially paid') {
            const cheque = {
              amount: '',
              id: null,
              number: null,
              deposited: false,
              deposit_date: null,
              deposit_date_text: '',
              date_text: moment().format('MM-DD-YYYY'),
              date: Constants.getEprmMonthDateFromString(moment()),
              time: moment().format('hh:mm a'),
            }
            if (this.addInvoice.cheque_details.length === 0) {
              this.addInvoice.cheque_details.push(cheque)
            }
            if (status.label.toLowerCase() === 'paid') {
              this.isPaid = true
            }
            if (status.label.toLowerCase() === 'partially paid') {
              this.isPartiallyPaid = true
            }
          }
        }
      })
    },
    depositDateChanged(config, date, position) {
      this.addInvoice.cheque_details[position].deposit_date = Constants.getEprmMonthDateFromString(this.addInvoice.cheque_details[position].deposit_date_text)
    },
    dateChanged(config, date, position) {
      this.addInvoice.cheque_details[position].date = Constants.getEprmMonthDateFromString(this.addInvoice.cheque_details[position].date_text)
    },
    setStatus(status) {
      this.status = status
    },
    setDocumentNames(documentNames) {
      this.documentNames = documentNames
    },
    setVendorInvoice(invoice) {
      this.invoice = invoice
      this.$store.state.taskDetails.user_name = invoice.user_name
      this.$store.state.taskDetails.employee_names = invoice.employee_names
      this.$store.state.taskDetails.name = invoice.name
      this.$store.state.taskDetails.received_date = invoice.received_date
      this.$store.state.taskDetails.period = invoice.period
      this.$store.state.taskDetails.type = 'vendorinvoice'
      this.$store.state.taskDetails.task_type = 'invoice'

      this.correctedInvoices = invoice.corrected_invoices
      this.addInvoice.is_corrected = invoice.is_corrected
      this.addInvoice.corrected_invoice_id = invoice.corrected_invoice_id
      this.addInvoice.amount = invoice.total_amount
      this.addInvoice.expenses = invoice.expenses

      this.addInvoice.comments = invoice.comments
      if (invoice.status.toLowerCase() === 'partially paid') {
        this.isPartiallyPaid = true
      }
      if (invoice.status.toLowerCase() === 'paid') {
        this.isPaid = true
      }
      this.addInvoice.cheque_details = invoice.cheque_responses
      this.invoice.cheques = invoice.cheque_responses
      if (this.addInvoice.comments.length === 0) {
        this.addComment()
      }
      this.addInvoice.status_id = invoice.status_id
      this.invoice.period = invoice.period
      this.addInvoice.documents = invoice.documents
    },
    invoiceUpdated(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    invoiceNotUpdated(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    addComment() {
      const comment = {
        comment: '',
        date: Constants.getEprmMonthDateFromString(moment()),
        time: moment().format('hh:mm a'),
      }
      this.addInvoice.comments.push(comment)
    },
    removeComment(index) {
      this.addInvoice.comments.splice(index, 1)
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
