<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Edit Transaction</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <app-timeline>
          <app-timeline-item class="pb-1">
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Recon Date</label>
                  <span
                    class="form-control"
                    v-text="reconcileHistory.reconcile_date"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Account</label>
                  <span
                    class="form-control"
                    v-text="reconcileHistory.account_number"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Statement Start Date</label>
                  <span
                    class="form-control"
                    v-text="reconcileHistory.transaction_start_date"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Statement End Date</label>
                  <span
                    class="form-control"
                    v-text="reconcileHistory.transaction_end_date"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Beginging Bank Balance</label>
                  <span
                    class="form-control"
                  >
                    {{ reconcileHistory.bank_starting_balance| currency('$ ', 2, { thousandsSeparator: ',' }) }}
                  </span>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Ending Bank Balance</label>
                  <span
                    class="form-control"
                  >
                    {{ reconcileHistory.bank_ending_balance| currency('$ ', 2, { thousandsSeparator: ',' }) }}
                  </span>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>None - Reconciled Credits</label>
                  <span
                    class="form-control"
                  >
                    {{ reconcileHistory.bank_non_reconcile_credit| currency('$ ', 2, { thousandsSeparator: ',' }) }}
                  </span>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>None - Reconciled Debits</label>
                  <span
                    class="form-control"
                  >
                    {{ reconcileHistory.bank_non_reconcile_debit| currency('$ ', 2, { thousandsSeparator: ',' }) }}
                  </span>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Unsettled Trans Credits</label>
                  <span
                    class="form-control"
                  >
                    {{ reconcileHistory.unsettled_credit_amount| currency('$ ', 2, { thousandsSeparator: ',' }) }}
                  </span>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>Unsettled Trans Debits</label>
                  <span
                    class="form-control"
                  >
                    {{ reconcileHistory.unsettled_debit_amount| currency('$ ', 2, { thousandsSeparator: ',' }) }}
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
          </app-timeline-item>
          <app-timeline-item
            variant="info"
            class="pb-1"
          >
            <b-row>
              <b-col cols="12">
                <vue-good-table
                  :columns="columns"
                  :rows="reconcileHistory.reconciles"
                  :rtl="direction"
                  :pagination-options="{
                    enabled: false,
                  }"
                >
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >

                    <!-- Column: Details -->
                    <span v-if="props.column.field === 'transactions'">
                      <vue-good-table
                        :columns="columns2"
                        :rows="props.row.transactions"
                        :rtl="direction"
                        :pagination-options="{
                          enabled: false,
                        }"
                      >

                        <template
                          slot="table-row"
                          slot-scope="props"
                        >

                          <!-- Column: Currency -->
                          <span v-if="props.column.field === 'Currency'">
                            <span>{{ props.row.amount| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
                          </span>
                        </template>
                      </vue-good-table>
                    </span>

                    <span v-if="props.column.field === 'cellRendererUnMatchButton'">
                      <b-button
                        variant="outline-primary"
                        type="button"
                        class="py-50 px-1"
                        @click="unMatchRecords(props.row)"
                      >
                        Unmatch
                      </b-button>
                    </span>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </app-timeline-item>
        </app-timeline>
        <b-row class="mt-1">
          <b-col
            cols="12"
            class="text-right"
          >
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Reconcile
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BButton,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { VueGoodTable } from 'vue-good-table'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import Reconcile from '@/js/reconcile'

export default {
  name: 'ReconciliationEdit',
  components: {
    ValidationObserver,
    BCard,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BButton,
    VueGoodTable,
  },
  directives: {
    Ripple,
  },
  setup() {
  },
  data() {
    return {
      required,
      reconcileHistory: {},
      columns: [
        {
          label: 'Transactions',
          field: 'transactions',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'cellRendererUnMatchButton',
          width: '150px',
          sortable: false,
        },
      ],
      columns2: [
        {
          label: 'Trans Type',
          field: 'transaction_type',
          sortable: false,
        },
        {
          label: 'Date',
          field: 'date',
          sortable: false,
        },
        {
          label: 'Type',
          field: 'type',
          sortable: false,
        },
        {
          label: 'Payee/Payer',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Ref No',
          field: 'ref',
          sortable: false,
        },
        {
          label: 'Amount',
          field: 'Currency',
          sortable: false,
        },
      ],
    }
  },
  mounted() {
  },
  created() {
    Reconcile.getReconcileHistoriesById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          const matchRequest = { reconciles: [] }
          for (let i = 0; i < this.reconcileHistory.reconciles.length; i += 1) {
            if (!matchRequest.reconciles.includes(this.reconcileHistory.reconciles[i].id)) {
              matchRequest.reconciles.push(this.reconcileHistory.reconciles[i].id)
            }
          }
          Reconcile.updateReconcileById(this, this.$route.params.id, matchRequest)
        }
      })
    },
    setReconcileHistories(reconcileHistory) {
      this.reconcileHistory = reconcileHistory
    },
    back() {
      this.$router.go(-1)
    },
    unMatchRecords(data) {
      for (let i = 0; i < this.reconcileHistory.reconciles.length; i += 1) {
        if (this.reconcileHistory.reconciles[i].index === data.index) {
          this.reconcileHistory.reconciles.splice(i, 1)
        }
      }
    },
    setReconcileUpdated(data) {
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        accept: null,
      })
    },
    setReconcileNotUpdated(message) {
      this.$vs.dialog({
        type: 'alert',
        color: 'danger',
        title: 'Reconcile',
        text: message,
        accept: null,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
