var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h5',[_vm._v("Assets")]),_c('div',{staticClass:"mt-1 ml-2"},[_c('h6',[_vm._v("Current Assets")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Cash")]),_c('validation-provider',{attrs:{"name":"Cash","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"cash","min":0,"step":0.5},on:{"input":_vm.currentAssetChanged},model:{value:(_vm.balanceSheetStatement.assets.cash),callback:function ($$v) {_vm.$set(_vm.balanceSheetStatement.assets, "cash", $$v)},expression:"balanceSheetStatement.assets.cash"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Investments")]),_c('validation-provider',{attrs:{"name":"Investments","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"investments","min":0,"step":0.5},on:{"input":_vm.currentAssetChanged},model:{value:(_vm.balanceSheetStatement.assets.investments),callback:function ($$v) {_vm.$set(_vm.balanceSheetStatement.assets, "investments", $$v)},expression:"balanceSheetStatement.assets.investments"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Inventories")]),_c('validation-provider',{attrs:{"name":"Inventories","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"investments","min":0,"step":0.5},on:{"input":_vm.currentAssetChanged},model:{value:(_vm.balanceSheetStatement.assets.inventories),callback:function ($$v) {_vm.$set(_vm.balanceSheetStatement.assets, "inventories", $$v)},expression:"balanceSheetStatement.assets.inventories"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Accounts receivable")]),_c('validation-provider',{attrs:{"name":"Accounts receivable","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"accounts_receivable","min":0,"step":0.5},on:{"input":_vm.currentAssetChanged},model:{value:(_vm.balanceSheetStatement.assets.accounts_receivable),callback:function ($$v) {_vm.$set(_vm.balanceSheetStatement.assets, "accounts_receivable", $$v)},expression:"balanceSheetStatement.assets.accounts_receivable"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Pre-paid expenses")]),_c('validation-provider',{attrs:{"name":"Pre-paid expenses","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"pre_paid_expenses","min":0,"step":0.5},on:{"input":_vm.currentAssetChanged},model:{value:(_vm.balanceSheetStatement.assets.pre_paid_expenses),callback:function ($$v) {_vm.$set(_vm.balanceSheetStatement.assets, "pre_paid_expenses", $$v)},expression:"balanceSheetStatement.assets.pre_paid_expenses"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Other")]),_c('validation-provider',{attrs:{"name":"Other","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"other","min":0,"step":0.5},on:{"input":_vm.currentAssetChanged},model:{value:(_vm.balanceSheetStatement.assets.other),callback:function ($$v) {_vm.$set(_vm.balanceSheetStatement.assets, "other", $$v)},expression:"balanceSheetStatement.assets.other"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bolder"},[_vm._v("Total Current Assest")]),_c('validation-provider',{attrs:{"name":"Total Current Assest","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control font-weight-bolder",attrs:{"id":"total_current_assets","min":0,"step":0.5},on:{"input":_vm.totalAssetsChanged},model:{value:(_vm.balanceSheetStatement.assets.total_current_assets),callback:function ($$v) {_vm.$set(_vm.balanceSheetStatement.assets, "total_current_assets", $$v)},expression:"balanceSheetStatement.assets.total_current_assets"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('hr',{staticClass:"mt-0 ml-2"}),_c('div',{staticClass:"mt-1 ml-2"},[_c('h6',[_vm._v("Fixed Assets")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Property and Equipment")]),_c('validation-provider',{attrs:{"name":"Property and Equipment","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"property_and_equipment","min":0,"step":0.5},on:{"input":_vm.fixedAssetChanged},model:{value:(_vm.balanceSheetStatement.assets.property_and_equipment),callback:function ($$v) {_vm.$set(_vm.balanceSheetStatement.assets, "property_and_equipment", $$v)},expression:"balanceSheetStatement.assets.property_and_equipment"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Leasehold Improvements")]),_c('validation-provider',{attrs:{"name":"Leasehold Improvements","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"leasehold_improvements","min":0,"step":0.5},on:{"input":_vm.fixedAssetChanged},model:{value:(_vm.balanceSheetStatement.assets.leasehold_improvements),callback:function ($$v) {_vm.$set(_vm.balanceSheetStatement.assets, "leasehold_improvements", $$v)},expression:"balanceSheetStatement.assets.leasehold_improvements"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Equity and Other Investments")]),_c('validation-provider',{attrs:{"name":"Equity and Other Investments","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"equity_and_other_investments","min":0,"step":0.5},on:{"input":_vm.fixedAssetChanged},model:{value:(_vm.balanceSheetStatement.assets.equity_and_other_investments),callback:function ($$v) {_vm.$set(_vm.balanceSheetStatement.assets, "equity_and_other_investments", $$v)},expression:"balanceSheetStatement.assets.equity_and_other_investments"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Less Accumulated Depreciation")]),_c('validation-provider',{attrs:{"name":"Less Accumulated Depreciation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"less_accumulated_depreciation","min":0,"step":0.5},on:{"input":_vm.fixedAssetChanged},model:{value:(_vm.balanceSheetStatement.assets.less_accumulated_depreciation),callback:function ($$v) {_vm.$set(_vm.balanceSheetStatement.assets, "less_accumulated_depreciation", $$v)},expression:"balanceSheetStatement.assets.less_accumulated_depreciation"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bolder"},[_vm._v("Total Fixed Assests")]),_c('validation-provider',{attrs:{"name":"Total Fixed Assests","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control font-weight-bolder",attrs:{"id":"total_fixed_assets","min":0,"step":0.5},on:{"input":_vm.totalAssetsChanged},model:{value:(_vm.balanceSheetStatement.assets.total_fixed_assets),callback:function ($$v) {_vm.$set(_vm.balanceSheetStatement.assets, "total_fixed_assets", $$v)},expression:"balanceSheetStatement.assets.total_fixed_assets"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('hr',{staticClass:"mt-0 ml-2"}),_c('div',{staticClass:"mt-1 ml-2"},[_c('h6',[_vm._v("Other Assets")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Goodwill")]),_c('validation-provider',{attrs:{"name":"Goodwill","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"goodwill","min":0,"step":0.5},on:{"input":_vm.otherAssetChanged},model:{value:(_vm.balanceSheetStatement.assets.goodwill),callback:function ($$v) {_vm.$set(_vm.balanceSheetStatement.assets, "goodwill", $$v)},expression:"balanceSheetStatement.assets.goodwill"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Total Other Assets")]),_c('validation-provider',{attrs:{"name":"Total Other Assets","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"total_other_assets","min":0,"step":0.5},on:{"input":_vm.totalAssetsChanged},model:{value:(_vm.balanceSheetStatement.assets.total_other_assets),callback:function ($$v) {_vm.$set(_vm.balanceSheetStatement.assets, "total_other_assets", $$v)},expression:"balanceSheetStatement.assets.total_other_assets"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bolder"},[_vm._v("Total Assets")]),_c('validation-provider',{attrs:{"name":"Total Assets","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control font-weight-bolder",attrs:{"id":"total_assets","min":0,"step":0.5},on:{"input":_vm.totalAccumulatedEarning},model:{value:(_vm.balanceSheetStatement.assets.total_assets),callback:function ($$v) {_vm.$set(_vm.balanceSheetStatement.assets, "total_assets", $$v)},expression:"balanceSheetStatement.assets.total_assets"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('hr'),_c('h5',[_vm._v("Liabilities and Owner's Equity")]),_c('div',{staticClass:"mt-1 ml-2"},[_c('h6',[_vm._v("Current Liabilities")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Accounts payable")]),_c('validation-provider',{attrs:{"name":"Accounts payable","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"accounts_payable","min":0,"step":0.5},on:{"input":_vm.currentLiabilitiesChanged},model:{value:(_vm.balanceSheetStatement.liabilities.accounts_payable),callback:function ($$v) {_vm.$set(_vm.balanceSheetStatement.liabilities, "accounts_payable", $$v)},expression:"balanceSheetStatement.liabilities.accounts_payable"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Accrued Wages")]),_c('validation-provider',{attrs:{"name":"Accrued Wages","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"accrued_wages","min":0,"step":0.5},on:{"input":_vm.currentLiabilitiesChanged},model:{value:(_vm.balanceSheetStatement.liabilities.accrued_wages),callback:function ($$v) {_vm.$set(_vm.balanceSheetStatement.liabilities, "accrued_wages", $$v)},expression:"balanceSheetStatement.liabilities.accrued_wages"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Accrued Compensation")]),_c('validation-provider',{attrs:{"name":"Accrued Compensation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"accrued_compensation","min":0,"step":0.5},on:{"input":_vm.currentLiabilitiesChanged},model:{value:(_vm.balanceSheetStatement.liabilities.accrued_compensation),callback:function ($$v) {_vm.$set(_vm.balanceSheetStatement.liabilities, "accrued_compensation", $$v)},expression:"balanceSheetStatement.liabilities.accrued_compensation"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Income Taxes Payable")]),_c('validation-provider',{attrs:{"name":"Income Taxes Payable","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"income_taxes_payable","min":0,"step":0.5},on:{"input":_vm.currentLiabilitiesChanged},model:{value:(_vm.balanceSheetStatement.liabilities.income_taxes_payable),callback:function ($$v) {_vm.$set(_vm.balanceSheetStatement.liabilities, "income_taxes_payable", $$v)},expression:"balanceSheetStatement.liabilities.income_taxes_payable"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Unearned Revenue")]),_c('validation-provider',{attrs:{"name":"Unearned Revenue","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"unearned_revenue","min":0,"step":0.5},on:{"input":_vm.currentLiabilitiesChanged},model:{value:(_vm.balanceSheetStatement.liabilities.unearned_revenue),callback:function ($$v) {_vm.$set(_vm.balanceSheetStatement.liabilities, "unearned_revenue", $$v)},expression:"balanceSheetStatement.liabilities.unearned_revenue"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Other")]),_c('validation-provider',{attrs:{"name":"Other","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"other","min":0,"step":0.5},on:{"input":_vm.currentLiabilitiesChanged},model:{value:(_vm.balanceSheetStatement.liabilities.other),callback:function ($$v) {_vm.$set(_vm.balanceSheetStatement.liabilities, "other", $$v)},expression:"balanceSheetStatement.liabilities.other"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bolder"},[_vm._v("Total Current Liabilities")]),_c('validation-provider',{attrs:{"name":"Total Current Liabilities","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control font-weight-bolder",attrs:{"id":"total_current_liabilities","min":0,"step":0.5},on:{"input":_vm.totalLiabilitiesChanged},model:{value:(_vm.balanceSheetStatement.liabilities.total_current_liabilities),callback:function ($$v) {_vm.$set(_vm.balanceSheetStatement.liabilities, "total_current_liabilities", $$v)},expression:"balanceSheetStatement.liabilities.total_current_liabilities"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('hr',{staticClass:"mt-0 ml-2"}),_c('div',{staticClass:"mt-1 ml-2"},[_c('h6',[_vm._v("Long-Term Liabilities")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Mortgage Payable")]),_c('validation-provider',{attrs:{"name":"Mortgage Payable","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"mortgage_payable","min":0,"step":0.5},on:{"input":_vm.longTermLiabilities},model:{value:(_vm.balanceSheetStatement.liabilities.mortgage_payable),callback:function ($$v) {_vm.$set(_vm.balanceSheetStatement.liabilities, "mortgage_payable", $$v)},expression:"balanceSheetStatement.liabilities.mortgage_payable"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bolder"},[_vm._v("Total Long-Term Liabilities")]),_c('validation-provider',{attrs:{"name":"Total Long-Term Liabilities","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control font-weight-bolder",attrs:{"id":"total_long_term_liabilities","min":0,"step":0.5},on:{"input":_vm.totalLiabilitiesChanged},model:{value:(_vm.balanceSheetStatement.liabilities.total_long_term_liabilities),callback:function ($$v) {_vm.$set(_vm.balanceSheetStatement.liabilities, "total_long_term_liabilities", $$v)},expression:"balanceSheetStatement.liabilities.total_long_term_liabilities"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('hr',{staticClass:"mt-0 ml-2"}),_c('div',{staticClass:"mt-1 ml-2"},[_c('h6',[_vm._v("Owner's Equity")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Investment Capital")]),_c('validation-provider',{attrs:{"name":"Investment Capital","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"investment_capital","min":0,"step":0.5},on:{"input":_vm.ownersEquity},model:{value:(_vm.balanceSheetStatement.liabilities.investment_capital),callback:function ($$v) {_vm.$set(_vm.balanceSheetStatement.liabilities, "investment_capital", $$v)},expression:"balanceSheetStatement.liabilities.investment_capital"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Accumulated Retained Earnings")]),_c('validation-provider',{attrs:{"name":"Accumulated Retained Earnings","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"accumulated_retained_earnings","min":0,"step":0.5},on:{"input":_vm.ownersEquity},model:{value:(_vm.balanceSheetStatement.liabilities.accumulated_retained_earnings),callback:function ($$v) {_vm.$set(_vm.balanceSheetStatement.liabilities, "accumulated_retained_earnings", $$v)},expression:"balanceSheetStatement.liabilities.accumulated_retained_earnings"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bolder"},[_vm._v("Total Owner's Equity")]),_c('validation-provider',{attrs:{"name":"Total Owner's Equity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control font-weight-bolder",attrs:{"id":"total_owenrs_equity","min":0,"step":0.5},on:{"input":_vm.totalLiabilitiesChanged},model:{value:(_vm.balanceSheetStatement.liabilities.total_owenrs_equity),callback:function ($$v) {_vm.$set(_vm.balanceSheetStatement.liabilities, "total_owenrs_equity", $$v)},expression:"balanceSheetStatement.liabilities.total_owenrs_equity"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bolder"},[_vm._v("Total Liabilities and Owner's Equity")]),_c('validation-provider',{attrs:{"name":"Total Liabilities and Owner's Equity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('money',_vm._b({staticClass:"form-control font-weight-bolder",attrs:{"id":"total_liabilities_and_owners_equity","min":0,"step":0.5},model:{value:(_vm.balanceSheetStatement.liabilities.total_liabilities_and_owners_equity),callback:function ($$v) {_vm.$set(_vm.balanceSheetStatement.liabilities, "total_liabilities_and_owners_equity", $$v)},expression:"balanceSheetStatement.liabilities.total_liabilities_and_owners_equity"}},'money',_vm.money,false))],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('hr'),_c('b-row',[_c('b-col',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"primary","type":"button"},on:{"click":_vm.create}},[_vm._v(" Create ")]),_c('b-button',{staticClass:"ml-2",attrs:{"variant":"primary","type":"button"},on:{"click":_vm.saveCreate}},[_vm._v(" Save & Create ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }