<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          View Task</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>

    <app-timeline>
      <app-timeline-item class="pb-1">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>{{ parentTaskDetails.parent_type }} Details</h6>
        </div>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="parentTaskDetails.parent_name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Email
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="parentTaskDetails.parent_email"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Phone
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="parentTaskDetails.parent_phone"
                />
              </dd>
            </dl>
          </b-col>
        </b-row>
      </app-timeline-item>

      <app-timeline-item
        variant="info"
        class="pb-1"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Task Details</h6>
        </div>
        <b-row
          v-for="task in tasks"
          :key="task"
          class="mt-1"
        >
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Task Name
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control h-auto"
                  v-text="task.name"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Due Date
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="task.due_date"
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="8"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Task Description
              </dt>
              <dd class="col-sm-12">
                <b-form-textarea
                  v-model="task.description"
                  rows="2"
                  disabled
                />
              </dd>
            </dl>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <dl class="row">
              <dt class="col-sm-12 dt-text">
                Status
              </dt>
              <dd class="col-sm-12">
                <span
                  class="form-control"
                  v-text="task.status"
                />
              </dd>
            </dl>
          </b-col>
          <b-col cols="12">
            <hr class="mt-0 mb-0">
          </b-col>
        </b-row>
        <div
          v-if="tasks.length == 0"
          class="text-center"
        >
          <p> No Task available </p>
        </div>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import Task from '@/js/tasks'

import {
  BCard, BRow, BCol, BButton, BFormTextarea,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  name: 'ParentTasksView',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
  },
  directives: {
  },
  setup() {
  },
  data() {
    return {
      parentTaskDetails: {
        parent_name: '',
        parent_email: '',
        parent_phone: null,
        parent_type: null,
      },
      tasks: [],
      pageNum: 1,
      searchField: '',
      recordsCount: 0,
    }
  },
  mounted() {
  },
  created() {
    Task.getparentTasks(this, this.$route.params.parent_type_id, this.$route.params.parent_id)
  },
  methods: {
    search() {
      if (this.searchField === null) {
        return
      }
      this.pageNum = 1
      this.recordsCount = 0
      Task.getparentTasks(this, this.$route.params.parent_type_id, this.$route.params.parent_id)
    },
    pageselected(pageNum1) {
      this.pageNum = pageNum1
      Task.getparentTasks(this, this.$route.params.parent_type_id, this.$route.params.parent_id)
    },
    setParentTask(parentTask) {
      this.parentTaskDetails = parentTask
      this.tasks = parentTask.tasks
      this.recordsCount = parentTask.count
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>
