<template>
  <b-card
    class="mb-0"
  >
    <h4 class="card-title card-title-tabs mb-0 pt-50">
      Group Jobs
    </h4>
    <b-tabs
      v-model="activeTab "
      @input="handleTabChange"
      align="right"
      class="custom-tabs custom-group-tabs mt-50"
    >
    <b-tab title="Contract Jobs" @click="contractTabClicked()" :active="$store.state.query.jobsActiveTab  === 'contract'">
        <template #title>
          <div class="border border-dashed d-flex flex-column justify-content-center align-items-center cursor-pointer rounded px-2 py-1 me-6">
            <span>Contract Jobs</span>
          </div>
        </template>
        <div>
          <vue-good-table
            :columns="columns"
            :rows="contractJobResponse.jobs"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :pagination-options="{
              perPage:pageLength,
              enabled: true,
              mode: 'pages',
              setCurrentPage: currentPage,
            }"
            @on-page-change="onPageChange"
            @on-per-page-change="onPerPageChange"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Job Id -->
              <span v-if="props.column.fieldType === 'cellRendererJobs'">
                <span class="count-circle">{{ props.row.count }}</span>
                <img
                  v-show="props.row.is_indeed_job_exist && props.row.is_indeed_job_pay_rate_duration_matched"
                  class="ml-50 img-fluid"
                  width="24"
                  height="24"
                  @click="openIndeedJob(props.row)"
                  :src="require('@/assets/images/icons/indeed_logo.png')"
                  alt="image slot"
                >
                <span
                  v-b-tooltip.hover
                  :title='props.row.indeed_job_tool_tip'
                  v-b-tooltip.hover.v-primary
                >
                  <img
                    v-show="props.row.is_indeed_job_exist && !props.row.is_indeed_job_pay_rate_duration_matched"
                    class="ml-50 img-fluid"
                    width="24"
                    height="24"
                    @click="openIndeedJob(props.row)"
                    :src="require('@/assets/images/icons/indeed_edit.png')"
                    alt="image slot"
                  >
                  <img
                    v-show="props.row.is_indeed_job_exist && !props.row.is_indeed_job_pay_rate_duration_matched"
                    class="ml-50 img-fluid"
                    width="24"
                    height="24"
                    @click="openViewIndeedJob(props.row)"
                    :src="require('@/assets/images/icons/indeed_view.png')"
                    alt="image slot"
                  >
                </span>
              </span>

              <!-- Column: Job Class -->
              <span v-if="props.column.fieldType === 'cellRendererJobClass'">
                <span
                  v-b-tooltip.hover
                  :title='props.row.skill_description'
                  v-b-tooltip.hover.v-primary
                >{{ props.row.skill }}</span>
              </span>

              <!-- Column: Client -->
              <span v-if="props.column.fieldType === 'cellRendererClient'">
                <p :id="'contract_facility' + props.row.index">
                  {{ props.row.facility }}
                </p>
                <b-tooltip
                  :target="'contract_facility' + props.row.index"
                  triggers="hover"
                  no-fade
                >
                  <span class="d-block text-left">{{props.row.facility}}</span>
                  <span class="d-block text-left">{{props.row.location}}</span>
                </b-tooltip>
              </span>

              <!-- Column: Shifts -->
              <span v-if="props.column.fieldType === 'cellRendererShifts'">
                <span
                  v-b-tooltip.hover
                  :title='props.row.shifts'
                  v-b-tooltip.hover.v-primary
                >{{ props.row.shifts }}</span>
              </span>

              <!-- Column: Update Button -->
              <span v-if="props.column.field === 'cellRendererUpdateBtn'">
                <b-button
                  v-show="props.row.is_indeed_job_exist"
                  variant="outline-primary"
                  class="px-50 py-50"
                  @click="updateIndeedJob(props)"
                >
                  Update
                </b-button>
              </span>

              <!-- Column: Bill Rate Currency -->
              <span v-if="props.column.fieldType === 'cellRendererBillRateCurrency'">
                <span>{{ props.row.bill_rate_max | currency('$ ', 2, { thousandsSeparator: ',' }) }} - {{ props.row.bill_rate_min | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
              </span>

              <!-- Column: Pay Rate Currency -->
              <span v-if="props.column.fieldType === 'cellRendererPayRateCurrency'">
                <span class="min-w-100">{{ props.row.pay_rate_min_min | currency('$ ', 2, { thousandsSeparator: ',' }) }} - {{ props.row.pay_rate_max_max | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                <span v-if="props.column.fieldType !== 'cellRendererShifts' && props.column.fieldType !== 'cellRendererIndeedMatchedProspects' && props.column.fieldType !== 'cellRendererMatchedProspects' && props.column.fieldType !== 'cellRendererJobClass' && props.column.fieldType !== 'cellRendererClient' && props.column.fieldType !== 'cellRendererJobs' && props.column.fieldType !== 'cellRendererBillRateCurrency'">
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </span>
            </template>

          </vue-good-table>
        </div>
      </b-tab>
      <b-tab @click="permanentTabClicked()" :active="$store.state.query.jobsActiveTab  === 'permanent'">
        <template #title>
          <div class="border border-dashed d-flex flex-column justify-content-center align-items-center cursor-pointer rounded px-2 py-1 me-6">
            <span>Permanent Jobs</span>
          </div>
        </template>
        <div>
          <vue-good-table
            :columns="columns"
            :rows="jobResponse.jobs"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :pagination-options="{
              perPage: permPageLength,
              enabled: true,
              mode: 'pages',
              setCurrentPage: permCurrentPage,
            }"
            @on-page-change="onPermPageChange"
            @on-per-page-change="onPermPerPageChange"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Job Id -->
              <span v-if="props.column.fieldType === 'cellRendererJobs'">
                <span class="count-circle"
                >{{ props.row.count }}</span>
              </span>

              <!-- Column: Job Class -->
              <span v-if="props.column.fieldType === 'cellRendererJobClass'">
                <p
                  v-b-tooltip.hover
                  :title='props.row.skill_description'
                  v-b-tooltip.hover.v-primary
                >{{ props.row.skill }}</p>
              </span>

              <!-- Column: Client -->
              <span v-if="props.column.fieldType === 'cellRendererClient'">
                <span :id="'permanent_facility' + props.row.index">
                  {{ props.row.facility }}
                </span>
                <b-tooltip
                  :target="'permanent_facility' + props.row.index"
                  triggers="hover"
                  no-fade
                >
                  <span class="d-block text-left">{{props.row.facility}}</span>
                  <span class="d-block text-left">{{props.row.location}}</span>
                </b-tooltip>
              </span>

              <!-- Column: Shifts -->
              <span v-if="props.column.fieldType === 'cellRendererShifts'">
                <span
                  v-b-tooltip.hover
                  :title='props.row.shifts'
                  v-b-tooltip.hover.v-primary
                >{{ props.row.shifts }}</span>
              </span>

              <!-- Column: Update Button -->
              <span v-if="props.column.field === 'cellRendererUpdateBtn'">
                <b-button
                  variant="outline-primary"
                  class="px-50 py-50"
                >
                  Update
                </b-button>
              </span>

              <!-- Column: Bill Rate Currency -->
              <span v-if="props.column.fieldType === 'cellRendererBillRateCurrency'">
                <span>{{ props.row.bill_rate_max | currency('$ ', 2, { thousandsSeparator: ',' }) }} - {{ props.row.bill_rate_min | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
              </span>

              <!-- Column: Pay Rate Currency -->
              <span v-if="props.column.fieldType === 'cellRendererPayRateCurrency'">
                <span>NA</span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                <span v-if="props.column.fieldType !== 'cellRendererShifts' && props.column.fieldType !== 'cellRendererIndeedMatchedProspects' && props.column.fieldType !== 'cellRendererMatchedProspects' && props.column.fieldType !== 'cellRendererJobClass' && props.column.fieldType !== 'cellRendererClient' && props.column.fieldType !== 'cellRendererJobs' && props.column.fieldType !== 'cellRendererBillRateCurrency'">
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </span>
            </template>

          </vue-good-table>
        </div>
      </b-tab>

      <b-tab @click="prnTabClicked()" :active="$store.state.query.jobsActiveTab  === 'prn'">
        <template #title>
          <div class="border border-dashed d-flex flex-column justify-content-center align-items-center cursor-pointer rounded px-2 py-1 me-6">
            <span>PRN Jobs</span>
          </div>
        </template>
        <div>
          <vue-good-table
            :columns="columns"
            :rows="prnJobResponse.jobs"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :pagination-options="{
              perPage: prnPageLength,
              enabled: true,
              mode: 'pages',
              setCurrentPage: prnCurrentPage,
            }"
            @on-page-change="onPrnPageChange"
            @on-per-page-change="onPrnPerPageChange"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Job Id -->
              <span v-if="props.column.fieldType === 'cellRendererJobs'">
                <span class="count-circle"
                >{{ props.row.count }}</span>
              </span>

              <!-- Column: Job Class -->
              <span v-if="props.column.fieldType === 'cellRendererJobClass'">
                <span
                  v-b-tooltip.hover
                  :title='props.row.skill_description'
                  v-b-tooltip.hover.v-primary
                >{{ props.row.skill }}</span>
              </span>

              <!-- Column: Client -->
              <span v-if="props.column.fieldType === 'cellRendererClient'">
                <p :id="'prn_facility' + props.row.index">
                  {{ props.row.facility }}
                </p>
                <b-tooltip
                  :target="'prn_facility' + props.row.index"
                  triggers="hover"
                  no-fade
                >
                  <span class="d-block text-left">{{props.row.facility}}</span>
                  <span class="d-block text-left">{{props.row.location}}</span>
                </b-tooltip>
              </span>

              <!-- Column: Shifts -->
              <span v-if="props.column.fieldType === 'cellRendererShifts'">
                <span
                  v-b-tooltip.hover
                  :title='props.row.shifts'
                  v-b-tooltip.hover.v-primary
                >{{ props.row.shifts }}</span>
              </span>

              <!-- Column: Update Button -->
              <span v-if="props.column.field === 'cellRendererUpdateBtn'">
                <b-button
                  variant="outline-primary"
                  class="px-50 py-50"
                >
                  Update
                </b-button>
              </span>

              <!-- Column: Bill Rate Currency -->
              <span v-if="props.column.fieldType === 'cellRendererBillRateCurrency'">
                <span>{{ props.row.bill_rate_max | currency('$ ', 2, { thousandsSeparator: ',' }) }} - {{ props.row.bill_rate_min | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
              </span>

              <!-- Column: Pay Rate Currency -->
              <span v-if="props.column.fieldType === 'cellRendererPayRateCurrency'">
                <span class="min-w-100">{{ props.row.pay_rate_max_max | currency('$ ', 2, { thousandsSeparator: ',' }) }} - {{ props.row.pay_rate_min_min | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                <span v-if="props.column.fieldType !== 'cellRendererShifts' && props.column.fieldType !== 'cellRendererIndeedMatchedProspects' && props.column.fieldType !== 'cellRendererMatchedProspects' && props.column.fieldType !== 'cellRendererJobClass' && props.column.fieldType !== 'cellRendererClient' && props.column.fieldType !== 'cellRendererJobs' && props.column.fieldType !== 'cellRendererBillRateCurrency'">
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </span>
            </template>

          </vue-good-table>
        </div>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BCard, VBModal, VBTooltip, BTabs, BTab, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Jobs from '@/js/jobs'

export default {
  name: 'JobRequests',
  components: {
    BCard,
    VueGoodTable,
    BTabs,
    BTab,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      searchQuery: '',
      dir: false,
      groupJobIds: {},
      activeTab: 'contract',
      type: 'contract',
      sortingHistory: [],
      jobResponse: {
        jobs: [],
        count: 1,
      },
      contractJobResponse: {
        jobs: [],
        count: 1,
      },
      prnJobResponse: {
        jobs: [],
        count: 1,
      },
      columns: [
        {
          label: '#',
          field: 'index',
          width: '60px',
          sortable: false,
        },
        {
          label: 'Jobs',
          field: 'count',
          type: 'number',
          width: '120px',
          fieldType: 'cellRendererJobs',
          thClass: 'text-left',
          tdClass: 'text-center',
        },
        {
          label: 'Skills',
          field: 'skill',
          fieldType: 'cellRendererJobClass',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Job Class',
          },
        },
        {
          label: 'Location',
          field: 'location',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Location',
          },
        },
        {
          label: 'Pay Rate ($)',
          field: 'pay_rate_max_max',
          fieldType: 'cellRendererPayRateCurrency',
          type: 'number',
          sortable: true,
        },
        {
          label: 'Duration',
          field: 'duration',
          type: 'number',
          width: '100px',
          sortable: false,
        },
        {
          label: 'Indeed Duration',
          field: 'indeed_duration',
          type: 'number',
          width: '100px',
          sortable: false,
        },
        {
          label: 'Shifts',
          field: 'shifts',
          fieldType: 'cellRendererShifts',
          sortable: false,
        },
        {
          label: 'Update',
          field: 'cellRendererUpdateBtn',
          width: '80px',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    pageLength: {
      get() {
        return this.$store.state.query.indeedperPage
      },
      set(val) {
        this.$store.state.query.indeedperPage = val
      },
    },
    currentPage: {
      get() {
        return this.$store.state.query.indeedgroupjobspage
      },
      set(val) {
        this.$store.state.query.indeedgroupjobspage = val
      },
    },
    permPageLength: {
      get() {
        return this.$store.state.query.indeedpermPerPage
      },
      set(val) {
        this.$store.state.query.indeedpermPerPage = val
      },
    },
    permCurrentPage: {
      get() {
        return this.$store.state.query.indeedgroupjobspermpage
      },
      set(val) {
        this.$store.state.query.indeedgroupjobspermpage = val
      },
    },
    prnPageLength: {
      get() {
        return this.$store.state.query.indeedprnPerPage
      },
      set(val) {
        this.$store.state.query.indeedprnPerPage = val
      },
    },
    prnCurrentPage: {
      get() {
        return this.$store.state.query.indeedgroupjobsprnpage
      },
      set(val) {
        this.$store.state.query.indeedgroupjobsprnpage = val
      },
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
  },
  created() {
    this.type = this.$store.state.query.indeedjobType
    this.activeTab = this.$store.state.query.indeedjobsActiveTab
    this.contractJobResponse.count = this.$store.state.query.indeedgroupjobspage
    this.prnJobResponse.count = this.$store.state.query.indeedgroupjobsprnpage
    this.jobResponse.count = this.$store.state.query.indeedgroupjobspermpage
    if (this.type === 'contract') {
      if (this.$store.state.indeedgroupJObs.isContractJobsLoaded) {
        this.contractJobResponse = this.$store.state.indeedgroupJObs.contractJobs
      } else {
        Jobs.getIndeedJobsGroupByCity(this, this.type)
      }
    }
    if (this.type === 'permanent') {
      if (this.$store.state.indeedgroupJObs.isPermanentJobsLoaded) {
        this.jobResponse = this.$store.state.indeedgroupJObs.permanentJobs
      } else {
        Jobs.getIndeedJobsGroupByCity(this, this.type)
      }
    }
    if (this.type === 'prn') {
      if (this.$store.state.indeedgroupJObs.isPrnJobsLoaded) {
        this.prnJobResponse = this.$store.state.indeedgroupJObs.prnJobs
      } else {
        Jobs.getIndeedJobsGroupByCity(this, this.type)
      }
    }
  },
  methods: {
    prnTabClicked() {
      this.type = 'prn'
      this.$store.state.query.indeedjobType = this.type
      this.$store.state.query.indeedjobsActiveTab = 'prn'
      if (this.$store.state.indeedgroupJObs.isPrnJobsLoaded) {
        this.prnJobResponse = this.$store.state.indeedgroupJObs.prnJobs
      } else {
        Jobs.getIndeedJobsGroupByCity(this, this.type)
      }
    },
    permanentTabClicked() {
      this.type = 'permanent'
      this.$store.state.query.indeedjobType = this.type
      this.$store.state.query.indeedjobsActiveTab = 'permanent'
      if (this.$store.state.indeedgroupJObs.isPermanentJobsLoaded) {
        this.jobResponse = this.$store.state.indeedgroupJObs.permanentJobs
      } else {
        Jobs.getIndeedJobsGroupByCity(this, this.type)
      }
    },
    contractTabClicked() {
      this.type = 'contract'
      this.$store.state.query.indeedjobsActiveTab = 'contract'
      this.$store.state.query.indeedjobType = this.type
      if (this.$store.state.indeedgroupJObs.isContractJobsLoaded) {
        this.contractJobResponse = this.$store.state.indeedgroupJObs.contractJobs
      } else {
        Jobs.getIndeedJobsGroupByCity(this, this.type)
      }
    },
    updateIndeedJob(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to update this ${obj.row.skill} ${obj.row.location}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, update it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          Jobs.updateIndeedJob(this, obj.row.indeed_job_id, obj.index)
        }
      })
    },
    jobUpdated(data, index) {
      this.contractJobResponse.jobs[index].is_indeed_job_pay_rate_duration_matched = true
      this.$store.state.indeedgroupJObs.contractJobs = this.contractJobResponse
      this.$swal({
        icon: 'success',
        title: 'Job Updated!',
        text: data.return_message,
      })
    },
    jobNotUpdated() {
      this.$swal({
        icon: 'warning',
        title: 'Job Not Updated!',
        text: 'Your Job has been not updated',
      })
    },
    handleTabChange() {
    },
    showGroupJobIds(props) {
      this.$store.state.indeedgroupJObs.isGroupJobsLoaded = false
      this.$store.state.query.indeedgroup_job_per_page = 10
      this.$store.state.query.indeedgroup_job_page = 1
      this.$router.push(`/human-resources/prospects-admin/group-jobs-city-skill?skill_id=${props.skill_id}&type=${this.type}&city_id=${props.city_id}`).catch(() => {})
    },
    onPageChange(page) {
      this.currentPage = page.currentPage
      this.pageLength = page.currentPerPage
    },
    onPerPageChange(perPage) {
      this.currentPage = perPage.currentPage
      this.pageLength = perPage.currentPerPage
    },
    onPermPageChange(page) {
      this.permCurrentPage = page.currentPage
      this.permPageLength = page.currentPerPage
    },
    onPermPerPageChange(perPage) {
      this.permCurrentPage = perPage.currentPage
      this.permPageLength = perPage.currentPerPage
    },
    onPrnPageChange(page) {
      this.prnCurrentPage = page.currentPage
      this.prnPageLength = page.currentPerPage
    },
    onPrnPerPageChange(perPage) {
      this.prnCurrentPage = perPage.currentPage
      this.prnPageLength = perPage.currentPerPage
    },
    openViewIndeedJob(groupJob) {
      window.open(groupJob.indeed_job_view, '_blank')
    },
    openIndeedJob(groupJob) {
      window.open(groupJob.indeed_job, '_blank')
    },
    captureSortHistory(sortEvent) {
      this.sortingHistory.push({
        field: sortEvent[0].field,
        type: sortEvent[0].type,
      })
    },
    closeJobPopup() {
      this.groupJobIds = {}
    },
    updateSearchQuery() {
      this.$store.state.query.indeedquery = this.searchQuery
    },
    setPrnJobs(jobResponse) {
      this.prnJobResponse = jobResponse
      this.$store.state.indeedgroupJObs.isPrnJobsLoaded = true
      this.$store.state.indeedgroupJObs.prnJobs = jobResponse
    },
    setJobs(jobResponse) {
      this.jobResponse = jobResponse
      this.$store.state.indeedgroupJObs.isPermanentJobsLoaded = true
      this.$store.state.indeedgroupJObs.permanentJobs = jobResponse
    },
    setContractJobs(jobResponse) {
      this.contractJobResponse = jobResponse
      this.$store.state.indeedgroupJObs.isContractJobsLoaded = true
      this.$store.state.indeedgroupJObs.contractJobs = jobResponse
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
