<template>
  <b-card
    no-body
    class="mb-0"
  >
    <b-card-header>
      <h4 class="card-title mb-0 pt-50">
        Assign Jobs</h4>
      <b-button
        variant="primary"
        class="ml-2 min-w-160"
        :to="{ name: 'administration-assign-jobs-new' }"
      >
        Add Assign Jobs
      </b-button>
    </b-card-header>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BButton,
  },
  data() {
    return {
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
  },
}
</script>

<style lang="scss">
</style>
