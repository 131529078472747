<template>
  <div>
    <h5>Assets</h5>
    <div class="mt-1 ml-2">
      <h6>Current Assets</h6>
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Cash</label>
            <validation-provider
              v-slot="{ errors }"
              name="Cash"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="cash"
                  v-model="balanceSheetStatement.assets.cash"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="currentAssetChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Investments</label>
            <validation-provider
              v-slot="{ errors }"
              name="Investments"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="investments"
                  v-model="balanceSheetStatement.assets.investments"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="currentAssetChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Inventories</label>
            <validation-provider
              v-slot="{ errors }"
              name="Inventories"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="investments"
                  v-model="balanceSheetStatement.assets.inventories"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="currentAssetChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Accounts receivable</label>
            <validation-provider
              v-slot="{ errors }"
              name="Accounts receivable"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="accounts_receivable"
                  v-model="balanceSheetStatement.assets.accounts_receivable"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="currentAssetChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Pre-paid expenses</label>
            <validation-provider
              v-slot="{ errors }"
              name="Pre-paid expenses"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="pre_paid_expenses"
                  v-model="balanceSheetStatement.assets.pre_paid_expenses"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="currentAssetChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Other</label>
            <validation-provider
              v-slot="{ errors }"
              name="Other"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="other"
                  v-model="balanceSheetStatement.assets.other"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="currentAssetChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label class="font-weight-bolder">Total Current Assest</label>
            <validation-provider
              v-slot="{ errors }"
              name="Total Current Assest"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="total_current_assets"
                  v-model="balanceSheetStatement.assets.total_current_assets"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control font-weight-bolder"
                  @input="totalAssetsChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <hr class="mt-0 ml-2">

    <div class="mt-1 ml-2">
      <h6>Fixed Assets</h6>
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Property and Equipment</label>
            <validation-provider
              v-slot="{ errors }"
              name="Property and Equipment"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="property_and_equipment"
                  v-model="balanceSheetStatement.assets.property_and_equipment"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="fixedAssetChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Leasehold Improvements</label>
            <validation-provider
              v-slot="{ errors }"
              name="Leasehold Improvements"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="leasehold_improvements"
                  v-model="balanceSheetStatement.assets.leasehold_improvements"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="fixedAssetChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Equity and Other Investments</label>
            <validation-provider
              v-slot="{ errors }"
              name="Equity and Other Investments"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="equity_and_other_investments"
                  v-model="balanceSheetStatement.assets.equity_and_other_investments"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="fixedAssetChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Less Accumulated Depreciation</label>
            <validation-provider
              v-slot="{ errors }"
              name="Less Accumulated Depreciation"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="less_accumulated_depreciation"
                  v-model="balanceSheetStatement.assets.less_accumulated_depreciation"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="fixedAssetChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label class="font-weight-bolder">Total Fixed Assests</label>
            <validation-provider
              v-slot="{ errors }"
              name="Total Fixed Assests"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="total_fixed_assets"
                  v-model="balanceSheetStatement.assets.total_fixed_assets"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control font-weight-bolder"
                  @input="totalAssetsChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <hr class="mt-0 ml-2">

    <div class="mt-1 ml-2">
      <h6>Other Assets</h6>
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Goodwill</label>
            <validation-provider
              v-slot="{ errors }"
              name="Goodwill"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="goodwill"
                  v-model="balanceSheetStatement.assets.goodwill"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="otherAssetChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Total Other Assets</label>
            <validation-provider
              v-slot="{ errors }"
              name="Total Other Assets"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="total_other_assets"
                  v-model="balanceSheetStatement.assets.total_other_assets"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="totalAssetsChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label class="font-weight-bolder">Total Assets</label>
            <validation-provider
              v-slot="{ errors }"
              name="Total Assets"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="total_assets"
                  v-model="balanceSheetStatement.assets.total_assets"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control font-weight-bolder"
                  @input="totalAccumulatedEarning"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <hr>

    <h5>Liabilities and Owner's Equity</h5>
    <div class="mt-1 ml-2">
      <h6>Current Liabilities</h6>
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Accounts payable</label>
            <validation-provider
              v-slot="{ errors }"
              name="Accounts payable"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="accounts_payable"
                  v-model="balanceSheetStatement.liabilities.accounts_payable"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="currentLiabilitiesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Accrued Wages</label>
            <validation-provider
              v-slot="{ errors }"
              name="Accrued Wages"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="accrued_wages"
                  v-model="balanceSheetStatement.liabilities.accrued_wages"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="currentLiabilitiesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Accrued Compensation</label>
            <validation-provider
              v-slot="{ errors }"
              name="Accrued Compensation"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="accrued_compensation"
                  v-model="balanceSheetStatement.liabilities.accrued_compensation"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="currentLiabilitiesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Income Taxes Payable</label>
            <validation-provider
              v-slot="{ errors }"
              name="Income Taxes Payable"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="income_taxes_payable"
                  v-model="balanceSheetStatement.liabilities.income_taxes_payable"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="currentLiabilitiesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Unearned Revenue</label>
            <validation-provider
              v-slot="{ errors }"
              name="Unearned Revenue"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="unearned_revenue"
                  v-model="balanceSheetStatement.liabilities.unearned_revenue"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="currentLiabilitiesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Other</label>
            <validation-provider
              v-slot="{ errors }"
              name="Other"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="other"
                  v-model="balanceSheetStatement.liabilities.other"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="currentLiabilitiesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label class="font-weight-bolder">Total Current Liabilities</label>
            <validation-provider
              v-slot="{ errors }"
              name="Total Current Liabilities"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="total_current_liabilities"
                  v-model="balanceSheetStatement.liabilities.total_current_liabilities"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control font-weight-bolder"
                  @input="totalLiabilitiesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <hr class="mt-0 ml-2">

    <div class="mt-1 ml-2">
      <h6>Long-Term Liabilities</h6>
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Mortgage Payable</label>
            <validation-provider
              v-slot="{ errors }"
              name="Mortgage Payable"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="mortgage_payable"
                  v-model="balanceSheetStatement.liabilities.mortgage_payable"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="longTermLiabilities"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label class="font-weight-bolder">Total Long-Term Liabilities</label>
            <validation-provider
              v-slot="{ errors }"
              name="Total Long-Term Liabilities"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="total_long_term_liabilities"
                  v-model="balanceSheetStatement.liabilities.total_long_term_liabilities"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control font-weight-bolder"
                  @input="totalLiabilitiesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <hr class="mt-0 ml-2">

    <div class="mt-1 ml-2">
      <h6>Owner's Equity</h6>
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Investment Capital</label>
            <validation-provider
              v-slot="{ errors }"
              name="Investment Capital"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="investment_capital"
                  v-model="balanceSheetStatement.liabilities.investment_capital"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="ownersEquity"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label>Accumulated Retained Earnings</label>
            <validation-provider
              v-slot="{ errors }"
              name="Accumulated Retained Earnings"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="accumulated_retained_earnings"
                  v-model="balanceSheetStatement.liabilities.accumulated_retained_earnings"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control"
                  @input="ownersEquity"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label class="font-weight-bolder">Total Owner's Equity</label>
            <validation-provider
              v-slot="{ errors }"
              name="Total Owner's Equity"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="total_owenrs_equity"
                  v-model="balanceSheetStatement.liabilities.total_owenrs_equity"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control font-weight-bolder"
                  @input="totalLiabilitiesChanged"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group>
            <label class="font-weight-bolder">Total Liabilities and Owner's Equity</label>
            <validation-provider
              v-slot="{ errors }"
              name="Total Liabilities and Owner's Equity"
              rules="required"
            >
              <b-input-group
                prepend="$"
              >
                <money
                  id="total_liabilities_and_owners_equity"
                  v-model="balanceSheetStatement.liabilities.total_liabilities_and_owners_equity"
                  :min="0"
                  :step="0.5"
                  v-bind="money"
                  class="form-control font-weight-bolder"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <hr>
    <b-row>
      <b-col class="text-right">
        <b-button
          variant="primary"
          type="button"
          @click="create"
        >
          Create
        </b-button>
        <b-button
          variant="primary"
          type="button"
          class="ml-2"
          @click="saveCreate"
        >
          Save & Create
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BRow, BCol, BFormGroup, BInputGroup, BButton,
} from 'bootstrap-vue'
import BalancesheetStatementJs from '@/js/balancesheetStatement'

export default {
  name: 'BalanceSheet',
  components: {
    ValidationProvider,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BButton,
  },
  props: {
    addBalanceStatementParent: {},
  },
  data() {
    return {
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      balanceSheetStatement: {
        assets: {
          cash: '',
          investments: '',
          inventories: '',
          accounts_receivable: '',
          pre_paid_expenses: '',
          other: '',
          total_current_assets: '',
          property_and_equipment: '',
          leasehold_improvements: '',
          equity_and_other_investments: '',
          less_accumulated_depreciation: '',
          total_fixed_assets: '',
          goodwill: '',
          total_other_assets: '',
          total_assets: '',
        },
        liabilities: {
          accounts_payable: '',
          accrued_wages: '',
          accrued_compensation: '',
          income_taxes_payable: '',
          unearned_revenue: '',
          other: '',
          total_current_liabilities: '',
          mortgage_payable: '',
          total_long_term_liabilities: '',
          investment_capital: '',
          accumulated_retained_earnings: '',
          total_owenrs_equity: '',
          total_liabilities_and_owners_equity: '',
        },
        date: null,
      },
    }
  },
  watch: {
    addBalanceStatementParent() {
      this.balanceSheetStatement = this.$props.addBalanceStatementParent
    },
  },
  methods: {
    saveCreate() {
      if (this.balanceSheetStatement.date !== null) {
        BalancesheetStatementJs.addBalanceSheet(this, this.balanceSheetStatement, true)
      }
    },
    create() {
      if (this.balanceSheetStatement.date !== null) {
        BalancesheetStatementJs.addBalanceSheet(this, this.balanceSheetStatement, false)
      }
    },
    balanceSheetSaved(data) {
      this.back()
      window.open(data.pdf_path, '_blank')
      this.$refs.showLoading = false
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    balanceSheetNotSaved(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    currentAssetChanged() {
      this.balanceSheetStatement.assets.total_current_assets = this.balanceSheetStatement.assets.cash + this.balanceSheetStatement.assets.investments + this.balanceSheetStatement.assets.inventories + this.balanceSheetStatement.assets.accounts_receivable + this.balanceSheetStatement.assets.pre_paid_expenses + this.balanceSheetStatement.assets.other
    },
    fixedAssetChanged() {
      this.balanceSheetStatement.assets.total_fixed_assets = this.balanceSheetStatement.assets.property_and_equipment + this.balanceSheetStatement.assets.leasehold_improvements + this.balanceSheetStatement.assets.equity_and_other_investments + this.balanceSheetStatement.assets.less_accumulated_depreciation
    },
    otherAssetChanged() {
      this.balanceSheetStatement.assets.total_other_assets = this.balanceSheetStatement.assets.goodwill
    },
    totalAssetsChanged() {
      this.balanceSheetStatement.assets.total_assets = this.balanceSheetStatement.assets.total_other_assets + this.balanceSheetStatement.assets.total_fixed_assets + this.balanceSheetStatement.assets.total_current_assets
    },
    currentLiabilitiesChanged() {
      this.balanceSheetStatement.liabilities.total_current_liabilities = this.balanceSheetStatement.liabilities.accounts_payable + this.balanceSheetStatement.liabilities.accrued_wages + this.balanceSheetStatement.liabilities.accrued_compensation + this.balanceSheetStatement.liabilities.income_taxes_payable + this.balanceSheetStatement.liabilities.unearned_revenue + this.balanceSheetStatement.liabilities.other
    },
    longTermLiabilities() {
      this.balanceSheetStatement.liabilities.total_long_term_liabilities = this.balanceSheetStatement.liabilities.mortgage_payable
    },
    ownersEquity() {
      this.balanceSheetStatement.liabilities.total_owenrs_equity = this.balanceSheetStatement.liabilities.investment_capital + this.balanceSheetStatement.liabilities.accumulated_retained_earnings
    },
    totalLiabilitiesChanged() {
      this.balanceSheetStatement.liabilities.total_liabilities_and_owners_equity = this.balanceSheetStatement.liabilities.total_current_liabilities + this.balanceSheetStatement.liabilities.total_long_term_liabilities + this.balanceSheetStatement.liabilities.total_owenrs_equity
    },
    totalAccumulatedEarning() {
      this.balanceSheetStatement.liabilities.accumulated_retained_earnings = this.balanceSheetStatement.assets.total_assets - (this.balanceSheetStatement.liabilities.total_current_liabilities + this.balanceSheetStatement.liabilities.total_long_term_liabilities + this.balanceSheetStatement.liabilities.investment_capital)
    },
    back() {
      this.$router.push('/financial-statements/statements').catch(() => {})
    },
  },
}
</Script>
