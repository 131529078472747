<template>
  <div class="p-2">
    <b-form-group
      label="Parent Types"
    >
      <span class="form-control h-auto">
        <b-badge
          v-for="parent_type in field.parent_types"
          :id="parent_type"
          :key="parent_type"
          variant="light-primary"
          class="mb-25 mr-50"
        >
          <span v-text="parent_type" />
        </b-badge>
      </span>
    </b-form-group>
    <b-form-group
      label="Field Name"
    >
      <span
        class="form-control text-left"
        v-text="field.name"
      />
    </b-form-group>
    <b-form-group
      label="Field Type"
    >
      <span
        class="form-control text-left"
        v-text="field.type"
      />
    </b-form-group>
    <div
      v-for="item in field.options"
      :id="item.id"
      :key="item.id"
      class="d-flex "
    >

      <!-- Item Name -->
      <div class="w-100">
        <b-form-group>
          <span
            class="form-control text-left"
            v-text="item.option"
          />
        </b-form-group>
      </div>
    </div>
    <b-form-group
      label="Description"
    >
      <b-form-textarea
        v-model="field.description"
        disabled
      />
    </b-form-group>
    <b-form-group>
      <b-form-checkbox
        v-model="field.required"
        disabled
      >
        Required
      </b-form-checkbox>
    </b-form-group>
  </div>
</template>

<script>
import {
  BFormGroup, BFormTextarea, BFormCheckbox, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BFormGroup,
    BFormTextarea,
    BFormCheckbox,
    BBadge,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      field: {},
    }
  },
  methods: {
  },
}
</script>
