<template>
  <div class="email-app-details email-app-details-custom">

    <!-- Email Header -->
    <div class="email-detail-header">

      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
            icon="ChevronLeftIcon"
            size="20"
            class="align-bottom"
            @click="$emit('close-prospect-view')"
          />
        </span>
        <div class="user-details d-flex justify-content-between align-items-center flex-wrap">
          <b-avatar
            size="48"
            :src="prospect.avatar"
            class="mr-75"
          />
          <div class="mail-items">
            <h5 class="mb-0">
              {{ prospect.name }}
              <div class="font-small-5 text-muted mr-25">{{ prospect.email }}</div>
            </h5>
          </div>
        </div>
      </div>
      <div class="email-header-right ml-2 pl-1">
        <b-link
          target="_blank"
          :href="prospect.resume"
          class="btn btn-outline-primary"
        >
          Resume
        </b-link>
      </div>
    </div>
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="email-scroll-area email-scroll-area-custom scroll-area p-0"
    >
      <b-card no-body>
        <b-card-body class="mail-message-wrapper pt-2">
          <div class="mail-message">
            <b-row>
              <b-col
                cols="12"
                md="4"
                class="col-xxl-2"
              >
                <div class="d-flex align-items-center mb-1">
                  <feather-icon
                    icon="PhoneIcon"
                    size="16"
                  />
                  <h5 class="text-body mb-0 ml-50">
                    {{ prospect.phone }}
                  </h5>
                </div>
              </b-col>
              <b-col
                cols="12"
                md="4"
                class="col-xxl-3"
              >
                <div class="d-flex mb-1">
                  <div>
                    <feather-icon
                      icon="SettingsIcon"
                      size="16"
                    />
                  </div>
                  <div>
                    <h5 class="text-body mb-0 ml-50">
                      <span
                        v-for="skill in prospect.skills" :key="skill"
                        class="mr-1"
                      >
                        {{ skill }}
                      </span>
                    </h5>
                  </div>
                </div>
              </b-col>
              <b-col
                cols="12"
                md="4"
                class="col-xxl-3"
              >
                <div class="d-flex mb-1">
                  <div>
                    <feather-icon
                      icon="ListIcon"
                      size="16"
                    />
                  </div>
                  <div>
                    <h5 class="text-body mb-0 ml-50">
                      <span
                        v-for="secondarySkill in prospect.secondary_skills" :key="secondarySkill"
                        class="mr-1"
                      >
                        {{ secondarySkill }}
                      </span>
                    </h5>
                  </div>
                </div>
              </b-col>
              <b-col
                cols="12"
                md="4"
                class="col-xxl-2"
              >
                <div class="d-flex mb-1">
                  <div>
                    <feather-icon
                      icon="BookOpenIcon"
                      size="16"
                    />
                  </div>
                  <div>
                    <h5 class="text-body mb-0 ml-50">
                      <span
                        v-for="education in prospect.educations" :key="education"
                        class="mr-1"
                      >
                        {{ education }}
                      </span>
                    </h5>
                  </div>
                </div>
              </b-col>
              <b-col
                cols="12"
                md="4"
                class="col-xxl-2"
              >
                <div class="d-flex mb-1">
                  <div>
                    <feather-icon
                      icon="AwardIcon"
                      size="16"
                    />
                  </div>
                  <div>
                    <h5 class="text-body mb-0 ml-50">
                      <span
                        v-for="license in prospect.licenses" :key="license"
                        class="mr-1"
                      >
                        {{ license }}
                      </span>
                    </h5>
                  </div>
                </div>
              </b-col>
              <b-col cols="12">
                <div class="d-flex mt-1">
                  <feather-icon
                    icon="FileTextIcon"
                    size="16"
                  />
                  <h5 class="ml-50">Notes</h5>
                </div>
                <div v-for="comment in prospect.comments" :key="comment">
                  <p v-html="comment.commentUserText"></p>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-card-body>
        <b-card-footer>
          <div class="text-right">
            <b-link>
              <feather-icon
                icon="Trash2Icon"
                size="18"
                class="text-danger"
                @click="deleteProspect"
              />
            </b-link>
          </div>
        </b-card-footer>
      </b-card>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Prospects from '@/js/prospect'
import {
  BCard, BCardBody, BCardFooter, BRow, BCol, BAvatar, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardBody,
    BCardFooter,
    BRow,
    BCol,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      prospect: {

      },
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  props: {
  },
  methods: {
    deleteProspect() {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${this.prospect.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          Prospects.delete(this, this.prospect.id)
        }
      })
    },
    prospectDeleted() {
      this.$emit('prospectDeleted')
      this.$emit('close-prospect-view')
      this.$swal({
        icon: 'success',
        title: 'Prospect Deleted!',
        text: 'Your Prospect has been deleted',
      })
    },
    prospectNotDeleted() {
      this.$swal({
        icon: 'warning',
        title: 'Prospect Not Deleted!',
        text: 'Your Prospect has been not deleted',
      })
    },
    setProspectDetails(prospect) {
      this.prospect = prospect
    },
  },
  setup() {
  },
}
</script>

<style>
</style>
